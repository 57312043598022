import { Skeleton, Grid } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import Table from "@mui/material/Table";

const TableRowsLoaderCopy = ({ rowsNum, headNames, columnNum }) => {
    return (
        <Grid
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Table style={{ minWidth: "450px" }}>
                <TableHead>
                    <TableRow>
                        {[...Array(columnNum)].map((row, index)=> (
                            <TableCell align="left" sx={{ fontWeight: 700 }} key={index}>{headNames[index]}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody style={{ cursor: "pointer", position: "relative",}}>
                    {[...Array(rowsNum)].map((row, index) => (
                        <TableRow key={index}>
                            {[...Array(columnNum)].map((row, index2) => (
                                <TableCell key={index2} sx={{ flexGrow: 1 }}>
                                    <Skeleton animation="wave" variant="rectangular" width={"100%"} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
    );
  };

export default TableRowsLoaderCopy;  