import { Grid, Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { getHeaders, myProfileChangePasswordUrl } from "../../../../Constants/ConstantsUrl";
import Modal from "@mui/material/Modal";
import PasswordComplexity from "./PasswordComplexity";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function ChangePasswordModal({ setAlertNotification, open, setOpen, customTheme, outerTheme }) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    const handleClose = () => {
        setOpen(!open);
    };

    const handleToggleOldPasswordVisibility = () => {
        setShowOldPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleToggleNewPasswordVisibility = () => {
        setShowNewPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleSubmitAction = async () => {
        setIsLoading(true);
        const data = {
            current_password: btoa(currentPassword),
            password: btoa(password),
            password_confirmation: btoa(confirmPassword),
        };

        try {
            const headers = getHeaders();
            await axios
                .post(myProfileChangePasswordUrl, data, { headers })
                .then((response) => response)
                .then(async (response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                        setOpen(false);
                    }
                });
        } catch (error) {
            let response = error?.response?.data;
            if (response?.data instanceof Object) {
                let inputs = response?.data;
                setAlertNotification({
                    status: true,
                    type: "error",
                    message: Object.values(inputs)[0],
                });
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal open={open}  onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"  className="RemoveOutLine" sx={{ backdropFilter: "blur(4px)" }}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "none",
                    boxShadow: "0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f",
                    borderRadius: "8px",
                }}
            >
                <Grid
                    sx={{
                        bgcolor: "var(--text-color-Green)",
                        p: 2,
                        borderRadius: "8px 8px 0px 0px",
                    }}
                    container
                    spacing={0}
                >
                    <Grid xs={10}>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "var(--text-color-OffWhite)",
                            }}
                            variant="h6"
                            component="h2"
                        >
                            Change password
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                        xs={2}
                    >
                        <CloseIcon
                            onClick={handleClose}
                            sx={{
                                color: "var(--text-color-OffWhite)",
                                cursor: "pointer",
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid id="modal-modal-description" sx={{ p: 3 }}>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            sx={{
                                mt: 2,
                                width: "100%",
                            }}
                            id="outlined-basic"
                            label="Old password*"
                            variant="outlined"
                            type={showOldPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleOldPasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            sx={{
                                mt: 3,
                                width: "100%",
                            }}
                            id="outlined-basic"
                            label="New password*"
                            variant="outlined"
                            type={showNewPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleNewPasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            sx={{
                                mt: 3,
                                width: "100%",
                            }}
                            id="outlined-basic"
                            label="Confirm password*"
                            variant="outlined"
                            type={showConfirmPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleConfirmPasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </ThemeProvider>
                    <PasswordComplexity valueofNewPassword={password} />

                    <Grid container spacing={0}>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "end",
                                my: 3,
                            }}
                            xs={12}
                            md={12}
                        >
                            <Button
                                onClick={handleClose}
                                sx={{
                                    color: "var(--text-color-black)",
                                    bgcolor: "#ededed",
                                    minWidth: "48px",
                                    minHeight: "48px",
                                    textTransform: "none",
                                    boxShadow: "none",

                                    "&:hover": {
                                        bgcolor: "#e0e0e0",
                                        boxShadow: "none",
                                    },
                                }}
                                variant="contained"
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: "var(--text-color-Green)",
                                    minWidth: "48px",
                                    minHeight: "48px", 
                                    "&:hover": {
                                        bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                    },
                                }}
                                variant="contained"
                                onClick={handleSubmitAction}
                                disabled={isLoading} // Disable the button when loading
                            >
                                {
                                    isLoading ? (
                                        <CircularProgress size={24} style={{ position: 'absolute', color:"black" }} />
                                    ) : (
                                        <Typography
                                        fontWeight={600}
                                        sx={{
                                            fontSize: "var(--btn-fontsize)",
                                            color: "var(--text-color-OffWhite)",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                            Save
                                            </Typography>
                                    )
                                }
                                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default ChangePasswordModal;
