import * as React from "react";
import "../../../App.css";
import { Box, Menu, MenuItem, Fade, ListItemIcon, Collapse } from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../Assests/Images/LogoImg/LOGO.png";
import { Drawer, List, ListItem, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import IconButton from "@mui/material/IconButton";
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import axios from "axios";
import { SendVerificationMail, getHeaders, homeRoutePath } from "../../../Constants/ConstantsUrl";
import { SwalmodelSuccess } from "../../Common/ApiCall";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AllowedAccess } from "react-permission-role";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));

const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",
    border: "1px solid var(--text-color-black)",
    padding: "10px 28px",
};
const LinkStyle = {
    color: "var(--text-color-black)",
    textDecoration: "none",
    fontWeight: 400,
};
const activeLinkStyle = {
    // color: "var( --navbar-color)",
    fontWeight: 700,
};
const activeLinkStyle1 = {
    // color: "var( --navbar-color)",
    fontWeight: 500,
    background: "var(--button-bg-color-green)",
    color: "var(--text-color-OffWhite)",
    padding: "8px",
};

export default function Index() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userName = localStorage.getItem("userName");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function logout() {
        navigate("/logout");
    }
    function profile() {
        navigate("/customer/profile");
    }

    const goToPageTwoWithTab = (tabIndex) => {
        navigate(`/customer/profile?tabIndex=${tabIndex}`);
    };

    const isMobile = useMediaQuery("(max-width:1150px)");
    const isLaptop = useMediaQuery("(max-width:1330px)");
    const [isDrawerOpen, setDrawerOpen] = React.useState(false);
    const location = useLocation();
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };
    async function resendVerification() {
        try {
            const headers = getHeaders();
            const response = await axios.get(SendVerificationMail, {
                headers,
            });
            const res = response.data;
            if (res.success) {
                SwalmodelSuccess("Verification link sent to your mail");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const [isListVisible, setListVisible] = React.useState(false);

    const handleResourceClick = () => {
        setListVisible(!isListVisible);
    };

    const handleMenuToggle = () => {
        setDrawerOpen(false);
        setListVisible(!isListVisible);
    };

    return (
        <React.Fragment>
            <Grid
                sx={{
                    boxShadow: "0 2px 1px -1px #8883, 0 1px 1px #88888824, 0 1px 3px #8888881f",
                    position: "fixed",
                    top: 0,
                    bgcolor: "white",
                    zIndex: "99",
                    width: "100%",
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid style={{ margin: "0px" }} container spacing={2}>
                            <Grid
                                xs={12}
                                md={ isLaptop ? 12 : 2}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "start",
                                }}
                            >
                                <Link to="/customer/assessment" style={{ height: "100%", display: "flex" }}>
                                    <img style={{ margin: "auto" }} src={Logo} alt="logo" />
                                </Link>
                                {isLaptop && (
                                    <IconButton edge="start" color="var(--text-color-black)" aria-label="menu" onClick={toggleDrawer(true)} sx={{ ml: "auto", display: "flex" }}>
                                        <FormatListBulletedOutlinedIcon
                                            sx={{
                                                height: "30px",
                                                width: "35px",
                                                color: "#000",
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Grid>
                            {!isLaptop && (
                                <>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                        xs={4}
                                        md={5}
                                    >
                                        <Item>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "40px",
                                                }}
                                            >
                                                <AllowedAccess permissions="view_my_assessment">
                                                    <Typography sx={{ fontSize: "18px" }}>
                                                        <Link
                                                            to="/customer/assessment"
                                                            style={{
                                                                ...LinkStyle,
                                                                ...((location.pathname === "/customer/assessment"||location.pathname === "/customer/invite"||location.pathname === "/customer/candidate-details") && activeLinkStyle),
                                                            }}
                                                        >
                                                            My assessments
                                                        </Link>
                                                    </Typography>
                                                </AllowedAccess>
                                                <Typography sx={{ fontSize: "18px" }}>
                                                    <Link
                                                        to="/customer/candidates"
                                                        style={{
                                                            ...LinkStyle,
                                                            ...((location.pathname === "/customer/candidates"||location.pathname === "/customer/candidate-all-assessment") && activeLinkStyle),
                                                        }}
                                                    >
                                                        My candidates
                                                    </Link>
                                                </Typography>
                                                <Typography sx={{ fontSize: "18px" }}>
                                                    <Link
                                                        to="/customer/tests"
                                                        style={{
                                                            ...LinkStyle,
                                                            ...(location.pathname === "/customer/tests" && activeLinkStyle),
                                                        }}
                                                    >
                                                        Tests
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4} md={5}>
                                        <Item
                                            sx={{
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Stack gap={"10px"} spacing={0} direction="row">
                                                <Link
                                                    to={homeRoutePath + "/book-a-demo"}
                                                    style={{
                                                        ...LinkStyle,
                                                        ...(location.pathname === "/book-a-demo" && activeLinkStyle),
                                                    }}
                                                >
                                                    <Button
                                                        style={btnStyle}
                                                        sx={{
                                                            textTransform: "none",
                                                            "&:hover": {
                                                                bgcolor: "#f5f5f5",
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <Typography
                                                            fontWeight={600}
                                                            sx={{
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            Book demo
                                                        </Typography>
                                                    </Button>
                                                </Link>
                                                <Link
                                                    to="/pricing"
                                                    style={{
                                                        ...LinkStyle,
                                                        ...(location.pathname === "/pricing" && activeLinkStyle),
                                                    }}
                                                >
                                                    <Button
                                                        style={btnStyle}
                                                        sx={{
                                                            textTransform: "none",
                                                            "&:hover": {
                                                                bgcolor: "#f5f5f5",
                                                            },
                                                        }}
                                                        fontWeight={200}
                                                        variant="outlined"
                                                    >
                                                        <Typography
                                                            fontWeight={600}
                                                            sx={{
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            Upgrade
                                                        </Typography>
                                                    </Button>
                                                </Link>
                                            </Stack>
                                            {/* <Grid>
                                                <HelpOutlineTwoToneIcon />
                                            </Grid> */}
                                            <Grid>
                                                <Button
                                                    id="fade-button"
                                                    aria-controls={open ? "fade-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                    onClick={handleClick}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize:"14px"
                                                        }}
                                                        style={{
                                                            ...LinkStyle,
                                                            ...(location.pathname === "/customer/profile" && activeLinkStyle),
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        color={"var(--text-color-black)"}
                                                    >
                                                        {userName?.length > 15 ? userName.substring(0, 15) + "..." : userName}
                                                        <KeyboardArrowDownOutlinedIcon />
                                                    </Typography>
                                                </Button>

                                                <Menu
                                                    id="fade-menu"
                                                    MenuListProps={{
                                                        "aria-labelledby": "fade-button",
                                                        sx: {
                                                            "& .MuiMenuItem-root": {
                                                                height: "50px", // Set your desired height here
                                                            },
                                                        },
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                    PaperProps={{
                                                        style: {
                                                            width: "230px", // Set your desired width here
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={() => goToPageTwoWithTab(0)}>
                                                        <ListItemIcon>
                                                            <AccountCircleIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography variant="inherit">Profile</Typography>
                                                    </MenuItem>
                                                    <AllowedAccess permissions="my_company">
                                                        <MenuItem onClick={() => goToPageTwoWithTab(1)}>
                                                            <ListItemIcon>
                                                                <PersonIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography variant="inherit">My Company</Typography>
                                                        </MenuItem>
                                                    </AllowedAccess>
                                                    <MenuItem onClick={() => goToPageTwoWithTab(2)}>
                                                        <ListItemIcon>
                                                            <GroupsIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography variant="inherit">Team Management</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => goToPageTwoWithTab(3)}>
                                                        <ListItemIcon>
                                                            <CreditCardIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography variant="inherit">Plan & Billing</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={logout}>
                                                        <ListItemIcon>
                                                            <LogoutIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Logout
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                </Container>
                {isLaptop && (
                    <Drawer
                        anchor="left"
                        open={isDrawerOpen}
                        ModalProps={{ onBackdropClick: toggleDrawer(false) }}
                        sx={{
                            "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper": { bgcolor: "#f6f6f6" },
                        }}
                    >
                        <Grid
                            sx={{
                                background: "white",
                                p: "12px",
                                borderBottom: "2px solid rgba(0,0,0,.12)",
                            }}
                            container
                            spacing={0}
                        >
                            <Grid xs={6}>
                                <Link to="/" style={{ display: "flex" }}>
                                    <img style={{ width: "100%", height: "auto" }} src={Logo} alt="../../../../Assests/Images/LOGO.png" />
                                </Link>
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "end" }} xs={6}>
                                <ArrowBackIcon sx={{ color: "#0000008a" }} onClick={toggleDrawer(false)} />
                            </Grid>
                        </Grid>
                        <Grid sx={{ mt: 2 }} role="presentation">
                            <List>
                                <ListItem
                                    style={{
                                        ...LinkStyle,
                                        ...(location.pathname === "/customer/assessment" && activeLinkStyle1),
                                        marginTop: "8px",
                                    }}
                                >
                                    <Link
                                        //  onClose={toggleDrawer(false)}
                                        to="/customer/assessment"
                                        style={{
                                            ...LinkStyle,
                                            ...(location.pathname === "/customer/assessment" && activeLinkStyle1),
                                        }}
                                    >
                                        My assessments
                                    </Link>
                                </ListItem>
                                <ListItem
                                    style={{
                                        ...LinkStyle,
                                        ...(location.pathname === "/customer/candidates" && activeLinkStyle1),
                                        marginTop: "8px",
                                    }}
                                >
                                    <Link
                                        to="/customer/candidates"
                                        style={{
                                            ...LinkStyle,
                                            ...(location.pathname === "/customer/candidates" && activeLinkStyle1),
                                        }}
                                    >
                                        My candidates
                                    </Link>
                                </ListItem>
                                <ListItem
                                    style={{
                                        ...LinkStyle,
                                        ...(location.pathname === "/customer/tests" && activeLinkStyle1),
                                        marginTop: "8px",
                                    }}
                                >
                                    <Link
                                        to="/customer/tests"
                                        style={{
                                            ...LinkStyle,
                                            ...(location.pathname === "/customer/tests" && activeLinkStyle1),
                                        }}
                                    >
                                        Test
                                    </Link>
                                </ListItem>

                                <Grid>
                                    <Item sx={{ background: "unset", mt: 2 }}>
                                        <Stack spacing={2} direction="row">
                                            <Link
                                                to={homeRoutePath + "/book-a-demo"}
                                                style={{
                                                    ...LinkStyle,
                                                    ...(location.pathname === "/book-a-demo" && activeLinkStyle),
                                                }}
                                            >
                                                <Button style={btnStyle} sx={{ textTransform: "none" }} variant="outlined">
                                                    {" "}
                                                    <Typography
                                                        fontWeight={600}
                                                        sx={{
                                                            fontSize: "var(  --btn-fontsize)",
                                                        }}
                                                    >
                                                        Book demo
                                                    </Typography>
                                                </Button>
                                            </Link>
                                            <Button style={btnStyle} sx={{ textTransform: "none" }} fontWeight={200} variant="outlined">
                                                <Typography
                                                    fontWeight={600}
                                                    sx={{
                                                        fontSize: "var(  --btn-fontsize)",
                                                    }}
                                                >
                                                    Upgrade
                                                </Typography>
                                            </Button>
                                        </Stack>
                                        <Grid sx={{ display: "flex" }}>
                                            <Grid sx={{ mt: 3, mr: 5 }}>
                                                <HelpOutlineTwoToneIcon />
                                            </Grid>
                                            <Grid sx={{ mt: 2 }}>
                                                <Typography
                                                    onClick={handleResourceClick}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    fontWeight={500}
                                                    variant="body1"
                                                >
                                                    {userName?.length > 15 ? userName.substring(0, 15) + "..." : userName}
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? "long-menu" : undefined}
                                                        aria-expanded={open ? "true" : undefined}
                                                        aria-haspopup="true"
                                                    >
                                                        <KeyboardArrowDownOutlinedIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Collapse in={isListVisible} timeout={1000} unmountOnExit>
                                                    <Typography
                                                        sx={{
                                                            textAlign:"left",
                                                            fontSize: "16px",
                                                            mt: 1,
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        onClick={() => goToPageTwoWithTab(0)}
                                                    >
                                                        Profile
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            textAlign:"left",
                                                            fontSize: "16px",
                                                            mt: 1,
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        onClick={() => goToPageTwoWithTab(1)}
                                                    >
                                                        My Company
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            textAlign:"left",
                                                            fontSize: "16px",
                                                            mt: 1,
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        onClick={() => goToPageTwoWithTab(2)}
                                                    >
                                                        Team Management
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                           textAlign:"left",
                                                            fontSize: "16px",
                                                            mt: 1,
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        onClick={() => goToPageTwoWithTab(3)}
                                                    >
                                                        Plan & Billing
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            textAlign:"left",
                                                            fontSize: "16px",
                                                            mt: 1,
                                                        }}
                                                        fontWeight={500}
                                                        variant="body1"
                                                        onClick={logout}
                                                    >
                                                        Logout
                                                    </Typography>
                                                </Collapse>

                                                {/* <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        "aria-labelledby": "long-button",
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    anchorReference="anchorPosition"
                                                    anchorPosition={{ top: 260, left: 200 }}
                                                >
                                                    <MenuItem onClick={profile}>Profile</MenuItem>
                                                    <MenuItem onClick={logout}>Logout</MenuItem>
                                                </Menu> */}
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </List>
                        </Grid>
                    </Drawer>
                )}

                <Box sx={{ flexGrow: 1, bgcolor: "var(--button-bg-color-green)" }}>
                    <Container maxWidth="xl">
                        <Grid style={{ marginTop: "0px" }} container spacing={0}>
                            <Grid sx={{}} xs={12} md={12}>
                                {/* <Item
                                style={{
                                    boxShadow: "unset",
                                    borderRadius: "none",
                                    background: "none",
                                    padding: "5px",
                                    color: "#000",
                                    display: "flex",
                                }}
                            >
                                <Typography variant="body1" sx={{ display: "flex" }}>
                                    {<WarningAmberOutlinedIcon sx={{ height: "15px" }}></WarningAmberOutlinedIcon>}
                                    Please validate your email address by click the link you received in the welcome mail.
                                </Typography>
                                <Typography
                                    onClick={resendVerification}
                                    style={{
                                        textDecoration: "underline",
                                        color: "var(--text-color-black)",
                                        cursor: "pointer",
                                    }}
                                >
                                    <strong>Send me the link again.</strong>
                                </Typography>{" "}
                            </Item> */}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Grid>
        </React.Fragment>
    );
}
