import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography, Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";
import Logo from "../../../../../../Assests/Images/LOGO.png"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { getHeaders, getEssayQuestion } from "../../../../../../Constants/ConstantsUrl";

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PreviewEssayQuestions() {
    const handleCloseTab = () => {
        window.close();
    };
    const { main_question_id } = useParams();
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [resultAnswer, setResultAnswer] = useState("");
    const [questionData, setQuestionData] = useState({
        id: 0,
        question_title: null,
        options: []
    });
    useEffect(() => {
        if (main_question_id > 0) {
            fetchMainCustomQuestion();
        }
    }, []);

    const fetchMainCustomQuestion = async () => {
        const headers = getHeaders();
        const data = {
            'custom_main_question_id': main_question_id
        }
        try {
            await axios.post(getEssayQuestion, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data.data.custom_question;
                    if (response.data.status === 200) {
                        setQuestionData({
                            id: res.id,
                            question_title: res.question_data.question,
                            options: res.question_data.question
                        });
                        setResultAnswer(res.question_data.question);
                    }
                })
        } catch (error) {
            if (error?.response.data.status === 404) {
                setErrorMsg(error?.response.data.data.message);
                setNotificationOpen(true);
                setTimeout(function () {
                    handleCloseTab();
                }, 5000);
            }
        }
    }
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };


    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid>
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="Company logo"
                    />
                </Grid>

                <Grid sx={{ padding: "20px" }}>
                    <Button
                        onClick={handleCloseTab}
                        sx={{
                            ...btnStyle,
                            bgcolor: "var(--button-bg-color-assessment)",
                            minWidth: "48px",
                            minHeight: "48px",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            "&:hover": {
                                bgcolor:
                                    "var(--button-hover-bg-color-LightGreen)",
                            },
                        }}
                        variant="contained"
                    >
                        {'Go back to tests'}
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    height: "90vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Container
                    maxWidth="lg"
                    style={{
                        backgroundColor: "white",
                        padding: "0px",
                        borderRadius: "12px",
                    }}
                >
                    <Box
                        style={{
                            padding: "55px",
                            boxShadow:
                                "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Question
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "18px", fontWeight: 500, mt: 2 }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: questionData.question_title }} />
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: "0px 24px" }} xs={12} md={6}>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Enter your answer here
                                </Typography>
                                <ReactQuill
                                    name="question"
                                    // label=" For example: Kindly introduce yourself."
                                    theme="snow"
                                    placeholder="Kindly write esssay on given topic"
                                    // value={resultAnswer}
                                    style={{ minHeight: "250px" }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <Alert
                        onClose={handleNotificationClose}
                        severity="error"
                        className="error_msg"
                        sx={{
                            width: "500px",
                            justifyContent: "center",
                            background: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                        }}
                    >
                        {errorMsg}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}
