import { useEffect, useState } from "react";
import { Box, LinearProgress, Grid, Typography } from "@mui/material";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import CheckIcon from "@mui/icons-material/Check";

// import zxcvbn from "zxcvbn";
const PasswordComplexity = ({ valueofNewPassword = "" }) => {
    // const testResult = zxcvbn(valueofNewPassword);
    // const num = (testResult.score * 100) / 4;
    // const num = (testResult.score * 100) / 4;

    const [passwordValidity, setPasswordValidity] = useState({
        minlength: null,
        minLowerCase: null,
        minUpperCase: null,
        minNumber: null,
        minSpecSymbol: null,
    });

    const isNumberRegex = /\d/;
    const oneLowerCase = /^(?=.*?[a-z])/;
    const oneUpperCase = /^(?=.*?[A-Z])/;
    const oneLetter = /^(?=.*?[ A-Za-z])/;
    const specialCharacterRegex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    useEffect(() => {
        setPasswordValidity({
            minlength: valueofNewPassword?.length >= 8,
            minletter: !!oneLetter.test(valueofNewPassword),
            minLowerCase: !!oneLowerCase.test(valueofNewPassword),
            minUpperCase: !!oneUpperCase.test(valueofNewPassword),
            minNumber: !!isNumberRegex.test(valueofNewPassword),
            minSpecSymbol: !!specialCharacterRegex.test(valueofNewPassword),
        });
    }, [valueofNewPassword]);

    const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
        if (valueofNewPassword) {
            return (
                // <li className="password-check-list" style={{ color: isValid ? "#37B284" : "#D03520" }}>
                //     <span>
                //         <i className={isValid ? "bi bi-check " : "bi bi-x"}></i>
                //     </span>
                //     <span> {text}</span>
                // </li>

                <Grid
                    sx={{
                        display: "flex",
                        mt: 1.5,
                        ml: 3,
                    }}
                >
                    {isValid ? (
                        <CheckIcon sx={{ color: "var(--text-color-Green)" }} />
                    ) : (
                        <PriorityHighOutlinedIcon
                            sx={{
                                mr: 1,
                                backgroundColor: "#cb7b7a",
                                borderRadius: "20px",
                                color: "var(--text-color-OffWhite)",
                                padding: "3px",
                            }}
                        />
                    )}
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                        }}
                        variant="h6"
                    >
                        {text}
                    </Typography>{" "}
                </Grid>
            );
        } else {
            return (
                <Grid
                    sx={{
                        display: "flex",
                        mt: 1.5,
                        ml: 3,
                    }}
                >
                    <PriorityHighOutlinedIcon
                        sx={{
                            mr: 1,
                            backgroundColor: "#cb7b7a",
                            borderRadius: "20px",
                            color: "var(--text-color-OffWhite)",
                            padding: "3px",
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                        }}
                        variant="h6"
                    >
                        {text}
                    </Typography>{" "}
                </Grid>
            );
        }
    };

    // const funcProgressLabel = () => {
    //   switch (testResult.score) {
    //     case 0:
    //       return "Very Weak";
    //     case 1:
    //       return "Weak";
    //     case 2:
    //       return "Fair";
    //     case 3:
    //       return "Strong";
    //     case 4:
    //       return "Very Strong";
    //     default:
    //       return "none";
    //   }
    // }

    const funcProgressColor = () => {
        switch (num) {
            case 25:
                return "red";
            case 50:
                return "var(--text-color-black)";
            case 75:
                return "orange";
            case 100:
                return "green";
            // case 4:
            //   return "green";
            default:
                return "none";
        }
        // switch (testResult.score) {
        //   case 0:
        //     return "grey";
        //   case 1:
        //     return "red";
        //   case 2:
        //     return "orange";
        //   case 3:
        //     return "darkgreen";
        //   case 4:
        //     return "green";
        //   default:
        //     return "none";
        // }
    };

    const num = passwordValidity.minlength * 25 + passwordValidity.minletter * 25 + passwordValidity.minNumber * 25 + passwordValidity.minSpecSymbol * 25;

    const changeProgressBarColor = () => ({
        width: `${num}%`,
        background: funcProgressColor(),
        height: "10px",
    });

    return (
        <>
            {/* <div>
        Password Strength:
        <span style={{
          color: funcProgressColor()
        }}>
          {funcProgressLabel()}
        </span>
      </div> */}

            <Box sx={{ width: "100%", my: 2 }}>
                <LinearProgress
                    className="LinerProgressbgc"
                    sx={{
                        // backgroundColor:funcProgressColor(),
                        "&.MuiLinearProgress-root": {
                            backgroundColor: "rgb(203 123 122 / 25%)",
                        },
                        "&.LinerProgressbgc span": {
                            backgroundColor: changeProgressBarColor(),
                        },
                        // "&.LinerProgressbgc span": {
                        //     background:  "red" ,
                        //   },
                    }}
                    variant="determinate"
                    value={num}
                />
            </Box>
            {/* <div className="password-progress-bar">
                <ProgressBar style={changeProgressBarColor()} />
            </div> */}
            {/* <div>
        Password must contain:
      </div> */}

            <PasswordStrengthIndicatorItem isValid={passwordValidity.minlength} text="Use at least 8 characters" />
            <PasswordStrengthIndicatorItem isValid={passwordValidity.minLowerCase} text="contains at least one lower character" />
            <PasswordStrengthIndicatorItem isValid={passwordValidity.minUpperCase} text="contains at least one upper character" />
            <PasswordStrengthIndicatorItem isValid={passwordValidity.minNumber} text="Use numbers" />
            <PasswordStrengthIndicatorItem isValid={passwordValidity.minSpecSymbol} text="Use special characters, like @#$%&*" />
        </>
    );
};

export default PasswordComplexity;
