import React, { useEffect, useState, useRef, useMemo } from "react";
import _debounce from "lodash/debounce";
import Navbar from "../../Layouts/Index";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import {
    Button,
    Container,
    Stack,
    Typography,
    Checkbox,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useMediaQuery from "@mui/material/useMediaQuery";
import TestQuestion from "./TestQuestion";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
    GetMainQuestion,
    getHeaders,
    TestTypeCategory,
} from "../../../../Constants/ConstantsUrl";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CardLoaderSkeleton from "../../../Common/CardLoaderSkeleton";
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f",
                        "--TextField-brandBorderHoverColor": "var(--button-bg-color-green)",
                        "--TextField-brandBorderFocusedColor":
                            "var(--button-bg-color-green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                            transition: "0.5s all",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            transition: "0.5s all",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom:
                                "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom:
                                "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Index() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:899px)");
    const isLaptop = useMediaQuery("(max-width:1330px)");
    const widthDesktop = isMobile ? "auto%" : "auto%";
    const FormMargin = isMobile ? " " : "20px";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionList, setMainQuestionList] = useState([]);
    const [questionCount, setMainQuestionCount] = useState([]);
    const [questionLevelCount, setMainQuestionLevelCount] = useState([]);
    const outerTheme = useTheme();
    const [modalData, setModalData] = useState({});
    const [mainqLoader, setMainqLoader] = useState(false);
    const pageNumber = useRef(0);
    const [disableScroll, setDisableScroll] = useState(true);
    const [questionType, setQuestionType] = useState({
        level_types: {},
        question_types: {},
        search: "",
        test_types: {},
        time: {},
    });
    const [levelcount, setLevelCount] = useState({});
    const [questioLOader, setQuestioLOader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPages] = useState(1);
    const [testTypeCategory, setTestTypeCategory] = useState([]);
    const memoizedTestTypeCategory = useMemo(
        () => testTypeCategory,
        [testTypeCategory]
    );
    const [disableLoading, setDisableLoading] = useState(false);
    const [mainqLoaderBlank, setMainqLoaderBlank] = useState(false);

    const [questionTestTypeCount, setMainQuestionTestTypeCount] = useState([]);
    const [questionQuestionTimeCount, setMainQuestionQuestionTimeCount] =
        useState([]);

    const closeModal = () => {
        setIsModalOpen(false);
        // document.body.style.overflow = "visible";
    };
    function openQuestionModal(id) {
        setModalData({ id });
        setIsModalOpen(true);
        // document.body.style.overflow = "hidden";
    }
    const fetchData = async () => {
        if (!disableScroll) {
            return;
        }
        if (disableLoading) {
            return;
        }
        setDisableLoading(true);
        setMainqLoader(true);
        try {
            setQuestioLOader(true);
            const headers = getHeaders();
            const searchData = `search=` + questionType?.search;

            const queryString = Object.entries(questionType?.question_types)
                .map(([key, value]) => `question_types[]=${key}`)
                .join("&");

            const queryLevelString = Object.entries(questionType?.level_types)
                .map(([key, value]) => `level_types[]=${key}`)
                .join("&");

            const queryTestTypes = Object.entries(questionType?.test_types)
                .map(([key, value]) => `question_cat_id[]=${key}`)
                .join("&");
            const queryTime = Object.entries(questionType?.time)
                .map(([key, value]) => `time[]=${key}`)
                .join("&");

            const PageNo = pageNumber.current.value;
            await axios
                .get(
                    GetMainQuestion +
                    "?page=" +
                    PageNo +
                    "&" +
                    queryString +
                    "&" +
                    queryLevelString +
                    "&" +
                    queryTestTypes +
                    "&" +
                    queryTime +
                    "&" +
                    searchData,
                    {
                        headers,
                    }
                )
                .then((response) => response)
                .then((response) => {
                    setQuestioLOader(false);
                    const res = response.data;
                    if (res.status === 200) {
                        setLevelCount(res.data.level);
                        if (res.data.pagination.current_page === 1) {
                            setMainQuestionList(res.data.questions);
                        } else {
                            setMainQuestionList([...questionList, ...res.data.questions]);
                        }
                        setPages(parseInt(res.data.pagination.current_page) + 1);
                        setMainQuestionCount(res.data.counts);
                        setMainQuestionLevelCount(res.data.level);

                        setMainQuestionQuestionTimeCount(res?.data.time);
                        console.log(res.data);
                        setMainQuestionTestTypeCount(res?.data.test_type);
                        setMainqLoader(false);
                        if (res.data.questions?.length === 0) {
                            setDisableScroll(false);
                            setMainqLoaderBlank(true);
                        }
                        if (res?.data?.pagination?.next_page_url == null) {
                            setDisableScroll(false);
                        }
                    }
                    setDisableLoading(false);
                });
        } catch (error) {
            setQuestioLOader(false);
            setMainqLoader(false);
            console.error("Error fetching data:", error);
        }
    };

    const handelFilterAndSearch = (e, index, no) => {
        // window.scrollTo({ top: 0, behavior: "smooth" });
        setMainqLoaderBlank((prevState) => false);
        setDisableScroll(true);
        setMainQuestionList([]);
        setPages(1);
        let filterKey = "";
        if (no === 1) {
            filterKey = "level_types";
        } else if (no === 2) {
            filterKey = "question_types";
        } else if (no === 3) {
            filterKey = "search";
        }

        if (filterKey === "search") {
            const setData = e.target.value;
            setQuestionType({
                ...questionType,
                [filterKey]: setData,
            });
        } else if (e.target.checked) {
            const setData = { ...questionType?.[filterKey] };
            setData[index] = index;
            setQuestionType({
                ...questionType,
                [filterKey]: { ...setData },
            });
        } else {
            const updatedData = Object.keys(questionType?.[filterKey])
                .filter((key) => key !== index)
                .reduce((obj, key) => {
                    obj[key] = key;
                    return obj;
                }, {});
            setQuestionType({
                ...questionType,
                [filterKey]: { ...updatedData },
            });
        }
    };

    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }
    useEffect(() => {
        const handleScroll = debounce(() => {
            const { scrollTop, scrollHeight, clientHeight } =
                document.documentElement;
            const middleOfWindow = scrollTop + clientHeight / 2;
            if (middleOfWindow > scrollHeight / 2) {
                callHandleScroll();
            }
        }, 100);

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [loading, questionList]);

    const callHandleScroll = () => {
        if (disableScroll) {
            fetchData();
        }
    };

    useEffect(() => {
        if (disableScroll) {
            fetchData();
        }
    }, [questionType]);

    useEffect(() => {
        getTestTypeCategory();
    }, []);

    const getTestTypeCategory = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(TestTypeCategory, { headers });
            const res = response.data;
            if (res.status) {
                setTestTypeCategory(res.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handelQuestionType = (e, index, no) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setDisableScroll(true);
        setMainQuestionList([]);
        setPages(1);
        let filterKey = "";
        if (no === 1) {
            filterKey = "level_types";
        } else if (no === 2) {
            filterKey = "question_types";
        } else if (no === 3) {
            filterKey = e.target.name || "test_types";
        } else if (no === 4) {
            filterKey = "time";
        }
        if (e.target.checked) {
            const setData = { ...questionType?.[filterKey] };
            setData[index] = index;
            setPages(1);
            setQuestionType({
                ...questionType,
                [filterKey]: { ...setData },
            });
        } else {
            let updatedData = {};
            if (no === 3) {
                updatedData = Object.keys(questionType?.[filterKey])
                    .filter((key) => parseInt(key) !== parseInt(index))
                    .reduce((obj, key) => {
                        obj[key] = key;
                        return obj;
                    }, {});
            } else {
                updatedData = Object.keys(questionType?.[filterKey])
                    .filter((key) => key !== index)
                    .reduce((obj, key) => {
                        obj[key] = key;
                        return obj;
                    }, {});
            }
            setQuestionType({
                ...questionType,
                [filterKey]: { ...updatedData },
            });
        }
    };
    return (
        <Box>
            <HelmetProvider>
                <Helmet>
                    <title>Test List - TestnHire</title>
                </Helmet>
            </HelmetProvider>
            <Navbar />
            <Box
                sx={{
                    bgcolor: "#F5F6F6",
                    marginTop: isMobile ? "40px" : isLaptop ? "45px" : "85px",
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0}>
                            <Grid sx={{ mt: 7 }} xs={12} md={6}>
                                <Typography
                                    variant="h2"
                                    sx={{ fontSize: "28px", fontWeight: "700" }}
                                >
                                    Tests Library
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Stack
                                    sx={{
                                        marginTop: !isMobile ? "40px" : "24px",
                                        display: "flex",
                                        justifyContent: !isMobile ? "end" : "start",
                                        flexDirection: { xs: "row", sm: "row", md: "row" },
                                        gap: 2,
                                        alignitems: "center",
                                        p: 1,
                                    }}
                                    spacing={0}
                                    direction="row"
                                >
                                    <Link
                                        to="/customer/create-assessment"
                                        style={{ color: "#fff", textDecoration: "none" }}
                                    >
                                        <Button
                                            sx={{
                                                bgcolor: "var(--button-bg-color-green)",
                                                p: 2,
                                                width: "auto",
                                                borderRadius: "4px",
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                "&:hover": {
                                                    bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                    boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                },
                                            }}
                                            variant="contained"
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "var(--text-color-OffWhite)",
                                                }}
                                                variant="body1"
                                            >
                                                {
                                                    <AddCircleOutlineIcon
                                                        sx={{
                                                            color: "var(--text-color-OffWhite)",
                                                            mr: 1,
                                                            fontSize: "30px",
                                                        }}
                                                    />
                                                }
                                                Start a New Assessment
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ flexGrow: 1, mt: 5 }}>
                        <Grid container spacing={0}>
                            <Grid md={3} xs={12}>
                                <Box sx={{ position: "sticky", top: "100px" }}>
                                    <Box>
                                        <ThemeProvider theme={customTheme(outerTheme)}>
                                            <TextField
                                                id="search"
                                                // value={searchFiledData}
                                                label="Search"
                                                variant="outlined"
                                                sx={{
                                                    bgcolor: "white",
                                                    width: !isMobile ? "75%" : "100%",
                                                }}
                                                onChange={(e) => handelFilterAndSearch(e, 1, 3)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") handelFilterAndSearch(e, 1, 3);
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <SearchIcon
                                                            sx={{ color: "black", cursor: "pointer" }}
                                                            onPress={(e) => handelFilterAndSearch(e, 1, 3)}
                                                        // style={{
                                                        //     color: "rgb(147 138 138 / 54%)",
                                                        // }}
                                                        />
                                                    ),
                                                }}
                                            // onChange={(e) => searchHandleFun(e)}
                                            />
                                        </ThemeProvider>
                                    </Box>

                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                            marginRight: FormMargin,
                                            overflow: "auto",
                                            maxHeight: "82vh",
                                            overflowY: "scroll",
                                            paddingTop: "16px",
                                            paddingBottom: "16px",
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{ fontSize: "16px", fontWeight: "700", mt: 7 }}
                                        >
                                            Filters
                                        </Typography>
                                        <Accordion
                                            sx={{
                                                boxShadow: "none",
                                                bgcolor: "var(--button-bg-color-transparent)",
                                            }}
                                            defaultExpanded
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={700}
                                                    fontSize={"16px"}
                                                >
                                                    Level
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body1" fontSize={"16px"}>
                                                    <FormControlLabel
                                                        name="easy"
                                                        onClick={(e) => handelFilterAndSearch(e, "easy", 1)}
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Easy (${levelcount?.easy ? levelcount?.easy : 0
                                                            })`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="intermediate"
                                                        onClick={(e) =>
                                                            handelFilterAndSearch(e, "intermediate", 1)
                                                        }
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Intermediate (${levelcount?.intermediate
                                                                ? levelcount?.intermediate
                                                                : 0
                                                            })`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="expert"
                                                        onClick={(e) =>
                                                            handelFilterAndSearch(e, "expert", 1)
                                                        }
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Expert (${levelcount?.expert ? levelcount?.expert : 0
                                                            })`}
                                                    />
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion
                                            sx={{
                                                boxShadow: "none",
                                                bgcolor: "var(--button-bg-color-transparent)",
                                            }}
                                            defaultExpanded
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={700}
                                                    fontSize={"16px"}
                                                >
                                                    Questions type
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="video"
                                                        onClick={(e) =>
                                                            handelFilterAndSearch(e, "video", 2)
                                                        }
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Video (${questionCount.video ?? 0})`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="essay"
                                                        onClick={(e) =>
                                                            handelFilterAndSearch(e, "essay", 2)
                                                        }
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Essay (${questionCount.essay ?? 0})`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="mcq"
                                                        onClick={(e) => handelFilterAndSearch(e, "mcq", 2)}
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Multiple-Choice (${questionCount.mcq ?? 0
                                                            })`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    <FormControlLabel
                                                        name="fileupload"
                                                        onClick={(e) =>
                                                            handelFilterAndSearch(e, "file upload", 2)
                                                        }
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`File upload (${questionCount.file_upload ?? 0
                                                            })`}
                                                    />
                                                </Typography>
                                                <Typography variant="body1" fontSize={"14px"}>
                                                    {" "}
                                                    <FormControlLabel
                                                        name="code"
                                                        onClick={(e) => handelFilterAndSearch(e, "code", 2)}
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={`Code (${questionCount.code ?? 0})`}
                                                    />
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            sx={{ boxShadow: "none", bgcolor: "transparent" }}
                                            defaultExpanded
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    fontSize={"16px"}
                                                    fontWeight={700}
                                                >
                                                    Test types
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: "0px 16px" }}>
                                                {memoizedTestTypeCategory?.map((test) => {
                                                    return (
                                                        <Typography variant="body1" fontSize={"16px"}>
                                                            <FormControlLabel
                                                                name="test_types"
                                                                onClick={(e) =>
                                                                    handelQuestionType(e, test.id, 3)
                                                                }
                                                                control={<Checkbox />}
                                                                sx={{ m: 0 }}
                                                                label={` ${test?.title} (${questionTestTypeCount[test.id] ?? 0
                                                                    }) `}
                                                            />
                                                        </Typography>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            sx={{ boxShadow: "none", bgcolor: "transparent" }}
                                            defaultExpanded
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={700}
                                                    fontSize={"16px"}
                                                >
                                                    Questions Time
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {[10, 20, 30, 40, 50, 60]?.map((time) => {
                                                    return (
                                                        <Typography
                                                            variant="body1"
                                                            key={time}
                                                            fontSize={"16px"}
                                                        >
                                                            <FormControlLabel
                                                                name="time_types"
                                                                onClick={(e) =>
                                                                    handelQuestionType(e, time.toString(), 4)
                                                                }
                                                                control={<Checkbox />}
                                                                sx={{ m: 0 }}
                                                                label={
                                                                    time === 10
                                                                        ? `Up to ${time} minutes (${questionQuestionTimeCount?.[time] ?? 0
                                                                        })`
                                                                        : `${time - 9}-${time} minutes (${questionQuestionTimeCount?.[time] ?? 0
                                                                        })`
                                                                }
                                                            />
                                                        </Typography>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Item>
                                </Box>
                            </Grid>
                            <Grid md={9} xs={12} sx={{ pb: 5 }}>
                                {questionList.length > 0 && (
                                    <TestQuestion
                                        isModalOpen={isModalOpen}
                                        setIsModalOpen={setIsModalOpen}
                                        closeModal={closeModal}
                                        openQuestionModal={openQuestionModal}
                                        modalData={modalData}
                                        questionList={questionList}
                                        questioLOader={questioLOader}
                                        mainqLoader={mainqLoader}
                                    />
                                )}
                                {questionList.length === 0 && (
                                    <>
                                        {questioLOader || mainqLoader ? (
                                            <Grid container spacing={0}>
                                                {[1, 2, 3, 4, 5, 6].map((key) => (
                                                    <CardLoaderSkeleton key={key} />
                                                ))}
                                            </Grid>
                                        ) : (
                                            mainqLoaderBlank && (
                                                <Container
                                                    maxWidth="xl"
                                                    sx={{
                                                        px: 2,
                                                        bgcolor: "#fff",
                                                        borderRadius: "4px",
                                                        boxShadow:
                                                            "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "200px",
                                                    }}
                                                >
                                                    <Box sx={{ p: "24px" }}>
                                                        <Typography
                                                            style={{ fontSize: "20px", fontWeight: 700 }}
                                                            variant="h6"
                                                        >
                                                            No record found
                                                        </Typography>
                                                    </Box>
                                                </Container>
                                            )
                                        )}
                                    </>
                                )}
                                <input
                                    type="hidden"
                                    name="page_number"
                                    value={page}
                                    ref={pageNumber}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default Index;
