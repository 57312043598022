import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
};

export default function TransitionsModal({
	handleConfirm,
	open,
	setOpen,
	data,
	setData,
	from,
}) {
	const handleClose = () => {
		setData();
		setOpen(false);
	};
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
				className="RemoveOutLine"
					sx={{ backdropFilter: "blur(4px)" }}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography
							sx={{ fontSize: "22px", fontWeight: 700 }}
							variant="h6"
							component="h2"
						>
							Confirm
						</Typography>
						<Typography
							variant="body1"
							sx={{ mt: 2, fontWeight: 500, fontSize: "16px" }}
						>
							Are you sure you want remove your {from} question ?
						</Typography>
						<Grid sx={{ mt: 3 }} container spacing={0}>
							<Grid xs={12} md={12}>
								{" "}
								<Grid container spacing={0}>
									<Grid
										sx={{
											display: "flex",
											gap: "10px",
											justifyContent: "end",
										}}
										xs={12}
										md={12}
									>
										<Button
											sx={{
												color: "var(--text-color-black)",
												bgcolor: "#ededed",
												minWidth: "48px",
												minHeight: "48px",
												textTransform: "none",
												boxShadow: "none",
												mb: 2,
												"&:hover": {
													bgcolor: "#e0e0e0",
													boxShadow: "none",
												},
											}}
											variant="contained"
											onClick={handleClose}
										>
											Cancel
										</Button>

										<Button
											sx={{
												bgcolor: "var(--text-color-Green)",
												minWidth: "48px",
												minHeight: "48px",
												textTransform: "capitalize",
												borderRadius: "4px",
												mb: 2,
												"&:hover": {
													bgcolor: "var(--button-hover-bg-color-LightGreen)",
												},
											}}
											variant="contained"
											onClick={(e) => handleConfirm(data)}
										>
											Yes
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
