import React from "react";
import { Button, Typography, Grid, IconButton, Skeleton,Divider,Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

const StepThreeSkeleton = (key) => {
    return (
        <Grid sx={{ p: 2, bgcolor: "white", mx:"24px" , borderRadius: "8px" }}>
            <Skeleton variant="rectangular" animation="wave" width={450} height={40} />
            <Grid sx={{ display: "flex" }}>
            <Skeleton variant="rectangular" animation="wave" width={330} height={30} sx={{mt:"10px"}}/>
            </Grid>
            <Divider
                sx={{
                    my: 2,
                    borderWidth: "1px",
                    borderColor: "gray",
                    width: "100%",
                }}
            />
           <Skeleton variant="rectangular" animation="wave"  height={90} />
            <Box sx={{ flexGrow: 1, mt: 5 }}>
                <Grid container spacing={0}>
                    <Grid xs={6}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                            }}
                        >
                             <Skeleton width={90} height={50} animation="wave" />
                        </Item>
                    </Grid>
                    <Grid xs={6}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                justifyContent: "end",
                                display: "flex",
                            }}
                        >
                            <Skeleton width={90} height={50} animation="wave" />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default StepThreeSkeleton;
