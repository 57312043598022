import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MUIDataTable from "mui-datatables";
import StarIcon from "@mui/icons-material/Star";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Rating from "@mui/material/Rating";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
    CandidateUpdateQuestionComment,
    getHeaders
} from "../../../../../../Constants/ConstantsUrl";
import axios from "axios";
import Loader from "../../../../Loader";
import Checkbox from '@mui/material/Checkbox';

import CodeEditor from "../../../../../Common/CodeEditor";
import ReactPlayer from "react-player";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const options = {
    filter: false,
    responsive: "standard",
    download: false,
    sort: false,
    pagination: false,
    selectableRows: "multiple",
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsSelected: [],
    print: false,
    viewColumns: false,
    searchOpen: false,
    searchText: "",
    search: false,
    customSearchRender: () => null,
    customStyles: {
        table: {
            root: {
                boxShadow: "none",
                color: "red",
                border: "5px solid blue",
            },
        },
    },
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

function CustomDetailsTable(props) {
    const { subMetaStarLoader, setSubMetaStarLoader, customQuestionList, subMetaStarIndex, customQuestionRating, handleStarClick, rowResult_id } = props;
    const columns = [
        {
            name: "question_title",
            label: <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Name</Typography>,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "var(--text-color-black)",
                            listStyle: "none",
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </Typography>
                ),
            },
        },
        {
            name: "question_type",
            label: <Typography style={{ fontSize: "14px", fontWeight: 700 }}>QUESTION TYPE</Typography>,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
            },
        },
        {
            name: "view",
            label: <Typography style={{ fontSize: "14px", fontWeight: 700 }}>VIEW ANSWER</Typography>,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    const { question_title, rating, result_sub_meta_details, what_to_look_for_ans, time, question_type } = customQuestionList[rowIndex] || {};
                    const curRating = customQuestionList[tableMeta.rowIndex].result.rating !== undefined ? customQuestionList[tableMeta.rowIndex].result.rating : rating;
                    return (
                        <>
                            {result_sub_meta_details.length > 0 ?
                                <Button
                                    onClick={() => handleOpen(question_title, curRating, result_sub_meta_details, what_to_look_for_ans, time, rowIndex, question_type)}
                                    variant="contained"
                                    style={{
                                        color: "var(--text-color-black)",
                                        backgroundColor: "var(--button-bg-color-transparent)",
                                        boxShadow: "none",
                                    }}
                                >
                                    <VisibilityIcon sx={{ mr: 1 }} />
                                    {value}
                                </Button>
                                : "Not Attempt"
                            }
                        </>
                    );
                },
            },
        },

        {
            name: "rating",
            label: <Typography style={{ fontSize: "14px", fontWeight: 700 }}>ANSWER RATING</Typography>,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    const { rating, result_sub_meta_details } = customQuestionList[rowIndex] || {};
                    const curRating = customQuestionList[tableMeta.rowIndex]?.result?.rating !== undefined ? customQuestionList[tableMeta.rowIndex]?.result?.rating : rating;
                    return (
                        <Grid
                            sx={{
                                display: "flex",
                                "& .MuiButton-startIcon": {
                                    margin: "0px",
                                },
                                "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
                                    fontSize: "25px",
                                },
                            }}
                        >
                            {result_sub_meta_details.length > 0 ?
                                <>
                                    {[1, 2, 3, 4, 5].map((star, index) => (
                                        <StarIcon
                                            key={index}
                                            onClick={() =>
                                                handleStarClick(star, "custom", tableMeta.rowIndex, result_sub_meta_details[0].result_meta_id, result_sub_meta_details[0].result_sub_meta_id, rowResult_id)
                                            }
                                            sx={{
                                                color: star <= curRating ? "#faaf00" : "#d2d2d2",
                                                fontSize: "30px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    ))}
                                    {subMetaStarLoader && subMetaStarIndex === rowIndex && <Loader />}
                                </>
                                : ""
                            }
                        </Grid>
                    );
                },
            },
        },
    ];
    const updateData = (resultMetaId, resultSubMetaId, NewRating) => {
        for (const key in customQuestionList) {
            if (customQuestionList[key].result_sub_meta_details[0] && customQuestionList[key].result_sub_meta_details[0].result_meta_id === resultMetaId && customQuestionList[key].result_sub_meta_details[0].result_sub_meta_id === resultSubMetaId) {
                customQuestionList[key].result_sub_meta_details[0].result_sub_meta_comment = NewRating;
                return true;
            }
        }
        return false; // Indicate that the comment was not updated

    }

    const [value, setValue] = React.useState(1);
    const [comment, setComment] = React.useState(1);

    const [open, setOpen] = React.useState(false);
    const [questionTitleCurrent, setQuestionTitleCurrent] = useState("");
    const [questionTypeCurrent, setQuestionTypeCurrent] = useState("");
    const [resultSubMetaDetailsCurrent, setResultSubMetaDetailsCurrent] = useState("");
    const [whatToLookForAns, setWhatToLookForAns] = useState("");
    const [whatIsIndex, setWhatIsIndex] = useState(0);
    const [commentValidaiotnError, setCommentValidaiotnError] = useState(false);
    const [timeCurrent, setTimeCurrent] = useState("00:10:00");
    const handleOpen = (question_title, rating, result_sub_meta_details, what_to_look_for_ans, time, rowIndex, question_type) => {
        setOpen(true);
        setQuestionTitleCurrent(question_title);
        setValue(rating);
        setResultSubMetaDetailsCurrent(result_sub_meta_details);
        setWhatToLookForAns(what_to_look_for_ans);
        setWhatIsIndex(rowIndex);
        setTimeCurrent(time);
        setQuestionTypeCurrent(question_type);
        setComment(result_sub_meta_details[0].result_sub_meta_comment);
    };
    const handleClose = () => setOpen(false);

    const handleSubmitComment = async (event) => {
        const headers = getHeaders();
        try {
            if (comment !== '' && comment !== null) {
                //setSubMetaStarLoader(true);
                const allData = {
                    result_meta_id: resultSubMetaDetailsCurrent[0].result_meta_id,
                    result_sub_meta_id: resultSubMetaDetailsCurrent[0].result_sub_meta_id,
                    comment: comment,
                };
                updateData(resultSubMetaDetailsCurrent[0].result_meta_id, resultSubMetaDetailsCurrent[0].result_sub_meta_id, comment)
                await axios.post(CandidateUpdateQuestionComment, allData, { headers });
                handleClose()
                //setSubMetaStarLoader(false);
            } else {
                setCommentValidaiotnError(true);
            }
        } catch (error) {
            console.log(error);
            //setSubMetaStarLoader(false);
        }
    };
    const setCommentFun = (e) => {
        setCommentValidaiotnError(false);
        //setSubMetaStarLoader(false);
        setComment(e);
    }
    return (
        <Box sx={{}}>
            {subMetaStarLoader ? (
                <Loader />
            ) : (
                <>
                    <Modal className="RemoveOutLine" sx={{ backdropFilter: "blur(4px)" }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80%",
                                bgcolor: "background.paper",
                                border: "none",
                                boxShadow: "0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f",
                                borderRadius: "8px",
                                overflow: "scroll",
                                height: "90vh",
                            }}
                        >
                            <Grid id="modal-modal-title" variant="h6" component="h2">
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={6}>
                                            <Item sx={{ p: 5, boxShadow: "none", background: "none" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "16px" }}>
                                                    Question
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "16px", mt: 4 }}>
                                                    {questionTitleCurrent ?? ""}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        mt: 5,
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    Pro tip: it might be helpful to pretend you are writing this to a new friend and just be yourself. We want to get to know you better.
                                                </Typography>
                                                <Divider sx={{ my: 3, borderTop: "1px solid rgba(0,0,0,.1)" }} />
                                                <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "16px" }}>
                                                    What to look for in the answer?
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px", mt: 2 }}>
                                                    {whatToLookForAns ?? ""}
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px", mt: 5 }}>
                                                    Look for a clear understanding of the requirements and expectations listed in your job post. A strong candidate should show a serious personal and
                                                    professional interest in the role. A weak answer will only scratch the surface of the role and state the obvious, such as “I have experience in this
                                                    field” or “I’m interested in this role.”
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Item sx={{ p: 5, boxShadow: "none", background: "none" }}>
                                                <Grid
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid>
                                                        <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "16px" }}>
                                                            Answer
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        {" "}
                                                        <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                                                    </Grid>
                                                </Grid>
                                                {questionTypeCurrent === "Video" ? (
                                                    <>
                                                        <ReactPlayer width={"100%"} height="100%" url={resultSubMetaDetailsCurrent[0]?.result_sub_meta_answer} playing={true} muted={true} controls={true} />
                                                    </>
                                                ) : questionTypeCurrent === "File Upload" ? (
                                                    <>
                                                        <a href={resultSubMetaDetailsCurrent[0]?.result_sub_meta_answer} download>
                                                            <Button
                                                                sx={{
                                                                    ...btnStyle,
                                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                                    minWidth: "48px",
                                                                    minHeight: "48px",
                                                                    textTransform: "capitalize",
                                                                    borderRadius: "4px",
                                                                    "&:hover": {
                                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                                    },
                                                                }}
                                                                variant="contained"
                                                            >
                                                                <CloudDownloadOutlinedIcon
                                                                    sx={{
                                                                        bgcolor: "var(--button-bg-color-assessment)",
                                                                        padding: "10px",
                                                                        fontSize: "50px",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                />
                                                                Download File
                                                            </Button>
                                                        </a>
                                                    </>
                                                ) : questionTypeCurrent === "Code" ? (
                                                    <>
                                                        <CodeEditor
                                                            editable={false}
                                                            name="front_temp"
                                                            language="python"
                                                            code={resultSubMetaDetailsCurrent[0]?.result_sub_meta_answer}
                                                            fontSizeValuePass="15"
                                                            sx={{
                                                                readOnly: true
                                                            }}

                                                        // theme="vs-white"
                                                        />
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: "16px",
                                                                mt: 3,
                                                                border: "1px solid #ccc",
                                                                borderRadius: "8px",
                                                                p: 1,
                                                                overflow: "auto",
                                                            }}
                                                        >
                                                            {resultSubMetaDetailsCurrent[0]?.result_sub_meta_result === 0 ? "Failed" : "Passed"}
                                                        </Typography>
                                                    </>
                                                ) : questionTypeCurrent === "Essay" ? (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontWeight: 400,
                                                            fontSize: "16px",
                                                            mt: 3,
                                                            border: "1px solid #ccc",
                                                            borderRadius: "8px",
                                                            p: 1,
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: resultSubMetaDetailsCurrent[0]?.result_sub_meta_answer }} />
                                                    </Typography>
                                                ) :
                                                    resultSubMetaDetailsCurrent[0]?.options.length > 0 &&
                                                    resultSubMetaDetailsCurrent[0]?.options.map((item, indexx) => {
                                                        const anser_Array = resultSubMetaDetailsCurrent[0]?.result_sub_meta_answer.split(',');
                                                        return (
                                                            <>
                                                                <Grid key={indexx} sx={{ pt: 1, }}>
                                                                    <Grid
                                                                        className=
                                                                        {resultSubMetaDetailsCurrent[0].correct_answer?.includes(item.id.toString()) && anser_Array?.includes(item.id.toString()) ? "rightRadioBtnChecked" :
                                                                            resultSubMetaDetailsCurrent[0].correct_answer?.includes(item.id.toString()) ?
                                                                                "rightRadioBtnUnChecked" :
                                                                                anser_Array?.includes(item.id.toString()) ? "wrongRadioBtnChecked" : "answerUnChecked"
                                                                        }

                                                                    >
                                                                        <FormControlLabel
                                                                            sx={{ width: "100%", boxShadow: "0 2px 8px 1px #a8a8a833", '& .MuiFormControlLabel-label': { width: '100%' } }}
                                                                            value={item.id}
                                                                            control={
                                                                                <Checkbox
                                                                                    inputProps={{ "aria-label": "controlled" }}
                                                                                    icon={<RadioButtonUncheckedIcon sx={{ fontSize: "35px", color: "#ededed" }} />}
                                                                                    checkedIcon={<RadioButtonCheckedIcon sx={{ fontSize: "35px", color: "white" }} />}
                                                                                    checked={anser_Array?.includes(item.id.toString()) ? true : false}
                                                                                    disabled
                                                                                ></Checkbox>
                                                                            }
                                                                            // checked={tr}
                                                                            label={<>
                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 500,
                                                                                            display: "flex",

                                                                                        }}
                                                                                    >
                                                                                        <div dangerouslySetInnerHTML={{ __html: item.options }} />
                                                                                    </span>
                                                                                    <span>
                                                                                        {resultSubMetaDetailsCurrent[0].correct_answer?.includes(item.id.toString()) && anser_Array?.includes(item.id.toString()) ? <CheckCircleOutlineIcon /> :
                                                                                            <>
                                                                                                {resultSubMetaDetailsCurrent[0].correct_answer?.includes(item.id.toString()) &&
                                                                                                    <CheckCircleOutlineIcon />}

                                                                                                {anser_Array?.includes(item.id.toString()) && <HighlightOffOutlinedIcon />}
                                                                                            </>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })
                                                }


                                                <Grid item sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
                                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                                                        Finished in {resultSubMetaDetailsCurrent[0]?.result_sub_meta_complete_time} out of {timeCurrent}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "14px", mt: 2 }}>
                                                    Rate answer
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        "& > legend": { mt: 2 },
                                                    }}
                                                >
                                                    <Rating
                                                        sx={{ mt: 3 }}
                                                        name="simple-controlled"
                                                        value={value}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                            handleStarClick(
                                                                newValue,
                                                                "custom",
                                                                whatIsIndex,
                                                                resultSubMetaDetailsCurrent[0]?.result_meta_id,
                                                                resultSubMetaDetailsCurrent[0]?.result_sub_meta_id,
                                                                rowResult_id
                                                            );
                                                        }}
                                                    />
                                                    {subMetaStarLoader && <Loader />}
                                                </Box>

                                                <Grid item sx={{ mt: 3 }}>
                                                    <TextareaAutosize
                                                        aria-label="empty textarea"
                                                        placeholder="Comment"
                                                        rows="8" cols="50"
                                                        value={comment}
                                                        onChange={(e) => setCommentFun(e.target.value)}
                                                        className="placeholder-text"
                                                        style={{
                                                            maxWidth: "100%",
                                                            width: "100%",
                                                            height: "auto",
                                                            minHeight: "50px",
                                                            minWidth: "100%",
                                                            borderRadius: "5px",
                                                            paddingTop: "10px"
                                                        }}
                                                    />
                                                    {commentValidaiotnError && <span style={{ "color": "red" }}>Please add comment</span>}
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "end",
                                                p: 5,
                                            }}
                                            xs={12}
                                            md={12}
                                        >
                                            <Button
                                                onClick={handleClose}
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "none",
                                                    boxShadow: "none",
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "#e0e0e0",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                sx={{
                                                    bgcolor: "var(--button-bg-color-green)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                    },
                                                }}
                                                onClick={handleSubmitComment}
                                                variant="contained"
                                            >
                                                Confirm
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                        </Box>
                    </Modal>

                    <Container maxWidth="xl">
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12}>
                                <Item sx={{ my: 5, boxShadow: "none", background: "none" }}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            my: 3,
                                        }}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h6" sx={{ fontSize: "14px" }} fontWeight={700}>
                                                Custom questions
                                            </Typography>
                                        </Grid>
                                    </Box>
                                    <div
                                        style={{
                                            border: "1px solid #dedede",
                                            borderRadius: "8px",
                                            backgroundColor: "#fafafa",
                                        }}
                                    >
                                        <MUIDataTable
                                            // title={"Employee List"}
                                            data={customQuestionList}
                                            columns={columns}
                                            options={options}
                                            className="css-11mde6h-MuiPaper-root "
                                        ></MUIDataTable>
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </Box>
    );
}

export default CustomDetailsTable;
