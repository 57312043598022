import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../Layouts/Index";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Autocomplete, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { MaterialReactTable } from "material-react-table";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import {
    getHeaders,
    getCandidates,
    getJobRole,
    assessmentGetData,
    GetCandidatesFilterData
} from "../../../../Constants/ConstantsUrl";
import { useNavigate } from "react-router-dom";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AllowedAccess } from "react-permission-role";
import TableRowsLoaderCopy from "../../TableRowsLoaderCopy";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "16.5px 0px 16.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 4,
            borderColor: "var(--border-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--border-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        // minWidth: "245px",
    },
}));

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f",
                        "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                            transition: "0.5s all",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            transition: "0.5s all",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));
function Index() {
    const isMobile = useMediaQuery("(max-width:899px)");
    const isLaptop = useMediaQuery("(max-width:1330px)");
    const widthDesktopTest = isMobile ? "100%" : "90%";
    const BtnPosition = isMobile ? "start" : "end";
    const BtnWidth = isMobile ? "100%" : "auto";
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedAssessment, setSelectedAssessment] = useState("");

    const [assessmentdata, setAssessmentData] = useState([]);
    const [assessmentOlddata, setAssessmentOldData] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [jobroledata, setJobRoleData] = useState([]);
    const outerTheme = useTheme();
    const [load, setLoad] = useState(true);
    const [loaderState, setLoaderState] = useState(false);
    const navigate = useNavigate();

    const fetchAssessmentData = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(assessmentGetData, {
                headers,
            });
            const res = response.data;
            if (res.status === 200) {
                setAssessment(res.data.transformed_assessment);
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                localStorage.removeItem("accessToken");
                navigate("/login");
            }
        } finally {
            setLoad(false);
        }
    };

    const getJObRoleFun = async () => {
        try {
            setLoad(true);
            const headers = getHeaders();
            await axios
                .get(getJobRole, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setJobRoleData(response.data.data);
                        setLoad(false);
                    }
                });
        } catch (error) {
            console.log(error);
            setLoad(false);
        }
    };

    const fetchData = async () => {
        setLoaderState(true);
        if (load) {
            try {
                const headers = getHeaders();
                const response = await axios.get(getCandidates, {
                    headers,
                });
                const res = response.data;
                if (res.status === 200) {
                    const processedData = res.data.map((item) => ({
                        ...item,
                        full_name: `${item.first_name === null ? "" : item.first_name} ${item.last_name === null ? "" : item.last_name}`,
                        id: item.id,
                    }));
                    setAssessmentData(processedData);
                    setAssessmentOldData(processedData);
                    setLoaderState(false);
                    // setJobRoleData(res.data.job_role);
                }
            } catch (error) {
                setLoaderState(false);
                if (error.response?.status === 401) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("accessToken");
                    navigate("/login");
                }
            } finally {
                setLoaderState(false);
                setLoad(false);
            }
        }
    };

    useEffect(() => {
        getJObRoleFun();
        fetchData();
        fetchAssessmentData();
    }, []);

    const handleChange = (event, value) => {
        setSelectedAssessment(value?.id ?? "");
        handelFilterData(1, value?.id ?? "");
    };
    // const [selectedOption1, setSelectedOption1] = useState("");

    // const handleChange1 = (event) => {
    //     setSelectedOption1(event.target.value);
    // };
    const [selectedOption2, setSelectedOption2] = useState("");

    const handleChange2 = (event, value) => {
        setSelectedOption2(value?.id ?? "");
        handelFilterData(2, value?.id ?? "");
    };
    const searchHandleFun = (e) => {
        setSelectedOption(e.target.value);
        const eventValue = e.target.value;
        if (eventValue === "") {
            setAssessmentData(assessmentOlddata);
            return false;
        } else {
            const searchTerms = eventValue.toLowerCase().split(" ");
            const filteredArray = assessmentOlddata.filter(
                (item) => {
                    // Check if any of the search terms match the properties
                    return searchTerms.some(term =>
                        item.first_name?.toLowerCase().includes(term) ||
                        item.last_name?.toLowerCase().includes(term) ||
                        item.email.toLowerCase().includes(term)
                    );
                });
            setAssessmentData(filteredArray);
        }
    };
    const handleRowClick = (rowData) => {
        navigate("/customer/candidate-all-assessment", { state: { id: rowData } });
    };
    const columns = useMemo(
        () => [
            {
                accessorKey: "full_name",
                header: "Name",
                enableColumnActions: false,
                accessorFn: (row) => (
                    <>
                        <span>
                            <strong>{row.full_name}</strong>
                        </span>
                        <br />
                        <span>{row.email}</span>
                    </>
                ),
            },
            {
                accessorKey: "count",
                header: "Assessment",
                enableColumnActions: false,
            },
            {
                accessorKey: "last_activity",
                header: "Last activity",
                enableColumnActions: false,
                accessorFn: (row) => (
                    <>
                        <span>{dayjs(row.last_activity).format("MMM DD, YYYY")}</span>
                        <span style={{ paddingLeft: 40 }}>
                            <b>&gt;</b>
                        </span>
                    </>
                ),
            },
        ],
        []
    );

    const handelFilterData = async (type, value) => {
        setLoad(true);
        try {
            let assessment = selectedAssessment;
            let job_role = selectedOption2;
            if (type === 1) {
                assessment = value;
            } else if (type === 2) {
                job_role = value;
            }
            const headers = getHeaders();
            const res = await axios.post(GetCandidatesFilterData, { assessment: assessment, job_role: job_role }, { headers });
            const response = res.data;
            setLoad(false);
            if (response.status === 200) {
                const processedData = response.data.map((item) => ({
                    ...item,
                    full_name: `${item.first_name === null ? "" : item.first_name} ${item.last_name === null ? "" : item.last_name}`,
                    id: `${item.id}`,
                }));
                setAssessmentOldData(processedData);
                setAssessmentData(processedData);
            }
        } catch (error) {
            console.log(error);
            setLoad(false);
        }
    };
    return (
        <Box>
            <Navbar />
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>My Candidate - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Box sx={{ bgcolor: "#F2F2F2", height: "91vh" }}>
                <Box sx={{ bgcolor: "#F2F2F2", marginTop: isMobile ? "75px" : isLaptop ? "75px" : "102px" }}>
                    <Container maxWidth="xl" sx={{ mt: 2 }}>
                        <Box sx={{ flexGrow: 1, }}>
                            <Grid sx={{ pt: 3 }} item container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                            textAlign: !isMobile ? "" : "start", padding: "0px"
                                        }}
                                    >
                                        <Typography sx={{ fontSize: "28px", fontWeight: 700 }} variant="h5">
                                            My candidates
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                            display: "flex",
                                            justifyContent: BtnPosition, padding: "0px"
                                        }}
                                    >
                                        <AllowedAccess permissions="add_my_assessment">
                                            <Link
                                                to="/customer/create-assessment"
                                                style={{ color: "#fff", textDecoration: "none" }}
                                            >
                                                <Button
                                                    sx={{
                                                        bgcolor: "var(--button-bg-color-green)",
                                                        p: 2,
                                                        width: "auto",
                                                        borderRadius: "4px",
                                                        boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                            boxShadow:
                                                                "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: "14px",
                                                            textTransform: "none",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            color: "var(--text-color-OffWhite)",
                                                        }}
                                                        variant="body1"
                                                    >
                                                        {
                                                            <AddCircleOutlineIcon
                                                                sx={{
                                                                    color: "var(--text-color-OffWhite)",
                                                                    mr: 1,
                                                                    fontSize: "30px",
                                                                }}
                                                            />
                                                        }
                                                        Start a New Assessment
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </AllowedAccess>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ flexGrow: 1, mb: 5, mt: isMobile ? "24px" : "40px" }}>
                            <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 15 }}>
                                <Grid item xs={6} md={12}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none", padding: "0px"
                                        }}
                                    >
                                        <ThemeProvider theme={customTheme(outerTheme)}>
                                            <TextField
                                                sx={{
                                                    bgcolor: "white",
                                                    borderRadius: "4px",
                                                    width: !isMobile ? "45%" : "100%",
                                                }}
                                                label="Search Candidate"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" sx={{ display: isMobile ? "none" : "" }}>
                                                            <SearchIcon
                                                                style={{
                                                                    color: "rgba(0, 0, 0, 0.54)",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={selectedOption}
                                                onChange={searchHandleFun}
                                            />
                                        </ThemeProvider>
                                    </Item>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                            display: "flex",
                                            justifyContent: "end",
                                            padding: "0px"
                                        }}
                                    >
                                        <FormControl sx={{ width: !isMobile ? "100% " : "100%" }} variant="standard">
                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={assessment}
                                                    value={assessment?.find((role) => role.id === selectedAssessment) || null}
                                                    getOptionLabel={(option) => option.name_of_assessment.replace(/&amp;/g, "&") ?? "Select Assessment"}
                                                    onChange={handleChange}
                                                    name="job_role_id"
                                                    sx={{
                                                        width: "100%",
                                                        bgcolor: "#fff",
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Assessment" />}
                                                />
                                            </ThemeProvider>
                                        </FormControl>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            className="MyCandidateTable MaterialReactTableStyle"
                            sx={{
                                borderRadius: "8px",
                                boxShadow: "0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
                            }}
                        >
                            {load ? (
                                <TableRowsLoaderCopy rowsNum={5} columnNum={3} headNames={
                                    columns.map((column) => column.header)
                                } />
                            ) : (
                                <MaterialReactTable
                                    enableTopToolbar={false}
                                    onRowSelectionChange="0"
                                    data={assessmentdata.length > 0 ? assessmentdata : []}
                                    columns={columns}
                                    options={{ actionsColumnIndex: 1 }}
                                    muiTableBodyRowProps={({ row }) => ({
                                        //add onClick to row to select upon clicking anywhere in the row
                                        onClick: () => handleRowClick(row.original.id),
                                        sx: { cursor: "pointer" },
                                    })}
                                    muiTableBodyProps={{
                                        // Conditionally set the display property to 'none' if there are no records
                                        style: { display: assessmentdata.length > 0 ? 'table-row-group' : '' }
                                    }}
                                />
                            )}
                        </Box>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default Index;
