import { Button, Checkbox, Container, Typography, Modal, Paper, Stack, Box, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { getHeaders, getAssessmentQualify } from "../../../../../Constants/ConstantsUrl";
import Loader from "../../../../Common/Loader";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
    background: "none"
}));


const QualifyingQuestionLibraryModal = (props) => {
    const {
        popupSubmitLoader,
        onClose,
        qualifyingQuestion,
        setQualifyingQuestion,
    } = props;
    const navigate = useNavigate();
    const [popupLoader, setPopupLoader] = useState(false);
    const [modalLoader, setModalLoader] = useState(true);
    const [allQualiQuestion, setAllQualiQuestion] = useState([""]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perpage, setPerPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [searchItem, setSearchItem] = useState("");

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleBack = () => {
        onClose(); // Close the modal or navigate back
    };

    useEffect(() => {
        fetchDataL();
    }, [navigate, currentPage, limit]);


    const fetchDataL = async (saerch = "") => {
        setModalLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(getAssessmentQualify + "?limit=" + limit + "&paginate=true&page=" + currentPage+"&search="+saerch, {
                headers,
            });
            const res = response.data;
            if (res.status === 200) {
                setAllQualiQuestion(res.data.qualifying_question);
                setTotalItem(res.data.pagination.total_records);
                setTotalPages(res.data.pagination.last_page);
                setPerPage(res.data.pagination.limit);
                setModalLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setModalLoader(false);
        }
    };

    const [expanded, setExpanded] = useState(false);
    const [checkBoxChecked, setCheckBoxChecked] = useState([]);
    const [selectedData, setSelectedData] = useState([]);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCheckboxChange = (event, id, accordion) => {
        setCheckBoxChecked(prevState => ({
            ...prevState,
            [id]: event.target.checked
        }));
        if (event.target.checked) {
            setSelectedData(prevState => ({
                ...prevState,
                [id]: accordion
            }));
        } else {
            // Create a copy of selectedData without the specified id
            const updatedSelectedData = { ...selectedData };
            delete updatedSelectedData[id];
            setSelectedData(updatedSelectedData);
        }
    };

    const isQuestionExist = (question_id) => {
        return qualifyingQuestion.some(item => item.question_id === question_id);
    };

    const handelSubmit = () => {
        const dataArray = Object.values(selectedData);

        // Map through dataArray to transform each item
        // Map through dataArray to transform each item
        const transformedData = dataArray.reduce((accumulator, item) => {
            const questionTitle = item.question.question; // {/* showing question from question */}
            // Check if the question title already exists
            if (!isQuestionExist(item.id)) {
                accumulator.push({
                    question_category_id: 3,
                    type: "Mcq",
                    question_id: item.id,
                    question: questionTitle,
                    question_type_id: item.question_type_id,
                    weight: 10,
                    time: null,
                    show_testcases_ids: null,
                    question_detail:item.question,//add deatils in array
                });
            } else {
                console.log(`Question title "${questionTitle}" already exists.`);
            }
            return accumulator;
        }, []);

        // Update the state if there are new questions to add
        if (transformedData.length > 0) {
            setQualifyingQuestion(prevState => [...prevState, ...transformedData]);
        }
        onClose();
    }
    const handleSearchChange =async (e)=>{
        setSearchItem((prevPage) => e.target.value);
        fetchDataL(e.target.value);
    }
    const clearSearchCandidate = async ()=>{
        setSearchItem((prevPage) => "");
        fetchDataL();
    }
    return (
        <Modal className="RemoveOutLine" sx={{ backdropFilter: "blur(4px)" }} open={true} onClose={onClose}>
            <Grid
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    boxShadow: "0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f",
                    width: "70%",
                    borderRadius: "8px",
                }}
            >
                <Grid container spacing={0}>
                    <Grid sx={{ width: "100%" }} xs={12}>
                        <Item
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px 20px",
                                alignItems: "center",
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: "#074234",
                            }}
                        >
                            <Grid>

                                <Typography
                                    sx={{
                                        color: "var(--text-color-OffWhite)",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                    }}
                                    variant="h2"
                                >
                                    COPY QUESTIONS FROM ANOTHER ASSESSMENT
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button
                                    style={{
                                        color: "#ffffff",
                                        fontSize: "16px",
                                    }}
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={handleBack}
                                >
                                    <CloseIcon />
                                </Button>

                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                {
                    <>
                        {popupSubmitLoader || popupLoader ? (
                            <Loader />
                        ) : (
                            <Grid sx={{
                                backgroundColor: '#eee',
                            }}>
                                <Grid sx={{ p: "0px", maxHeight: "70vh", overflowY: "scroll" }}>
                                    <Container maxWidth="xxl" sx={{ p: 1 }}>
                                        <Grid sx={{ mt: 2 }} container>
                                            <Grid item xs={12} md={12}>
                                                <Box sx={{ p: 1 }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "18px",
                                                        }}
                                                        variant="body2"
                                                    >
                                                        Search
                                                    </Typography>
                                                    <TextField 
                                                        label="Search"
                                                        name="searchItem"
                                                        value={searchItem}
                                                        onChange={(e) => handleSearchChange(e)}
                                                        InputProps={{
                                                            startAdornment: (
                                                            <SearchIcon
                                                                style={{
                                                                color: "rgba(0, 0, 0, 0.54)",
                                                                }}
                                                            />
                                                            ),
                                                            endAdornment: searchItem? (
                                                                <CloseIcon
                                                                    sx={{
                                                                    color: "rgba(0, 0, 0, 0.54)",
                                                                    cursor:"pointer",
                                                                    "&:hover":{background:"#0000000a", borderRadius:"50%"}
                                                                    }}
                                                                    onClick={clearSearchCandidate}
                                                                />
                                                                ):"",
                                                        }}
                                                    />
                                                     
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                {allQualiQuestion && allQualiQuestion.map((accordion) => (
                                                    <Accordion
                                                        key={accordion?.assessment_id}
                                                        expanded={expanded === accordion?.assessment_id}
                                                        onChange={handleChange(accordion?.assessment_id)}
                                                    >
                                                        <AccordionSummary sx={{ overflowX: "scroll" }} expandIcon={<ExpandMoreIcon />}>
                                                            <Typography>{accordion?.assessment_name}</Typography>

                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {accordion?.questions && accordion?.questions.map((items, key) => {
                                                                return (
                                                                    <Grid sx={{display:"flex", gap:"10px", alignItems:"center"}} key={key}>
                                                                        <Checkbox
                                                                            checked={checkBoxChecked[items?.question_details?.id] || false}
                                                                            onChange={(e) => handleCheckboxChange(e, items?.question_details?.id, items?.question_details)}
                                                                        />
                                                                        <div dangerouslySetInnerHTML={{ __html: items?.question_details?.question?.question }} />
                                                                    </Grid>
                                                                )
                                                            }
                                                            )}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                                <Container maxWidth="xxl" sx={{ p: 1 }}>
                                    <Grid item xs={12} md={6} sx={{
                                        display: "flex", justifyContent: "end", alignItems: "center", background: "white", mb: 2, mr: "5px", p: 1,
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)", borderRadius: "5px"
                                    }}>
                                        <Typography sx={{ fontSize: "12px", color: "0000008A" }} variant="body2">
                                            {totalItem > 0 ? currentPage * perpage - perpage + 1 + "-" : ""}
                                            {currentPage * perpage > totalItem ? totalItem : currentPage * perpage} of {totalItem}
                                        </Typography>
                                        <Grid sx={{ gap: "0px" }}>
                                            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                                                <ArrowBackIosNewIcon sx={{ color: currentPage !== 1 ? "var(--border-color-Green)" : "", fontSize: "20px" }} />
                                            </Button>
                                            <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                <ArrowForwardIosIcon sx={{ color: currentPage !== totalPages ? "var(--border-color-Green)" : "", fontSize: "20px" }} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                        )}
                    </>
                }
                <Grid
                    container
                    sx={{

                    }}
                >

                    <Grid sx={{ ml: "auto" }} xs={12} md={12}>
                        <Item
                            sx={{
                                mt: "10px",
                                mb: "10px",
                            }}
                        >
                            <Stack spacing={2} direction="row">
                                <Button
                                    style={{
                                        background: "#ededed",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={handleBack}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                        }}
                                    >
                                        Close
                                    </Typography>
                                </Button>
                                <Button
                                    style={{
                                        background: (Object.keys(selectedData).length > 0) ? "#0a5c48" : "rgb(88 112 107)",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    disabled={(Object.keys(selectedData).length) === 0}
                                    sx={{ textTransform: "none" }}
                                    onClick={handelSubmit}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                            color: "var(--text-color-OffWhite)",
                                        }}
                                    >
                                        Add {Object.keys(selectedData).length} questions
                                    </Typography>
                                </Button>
                            </Stack>
                        </Item>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};
export default QualifyingQuestionLibraryModal;
