import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import Navbar from "../../Admin/Layouts/Index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GetPricing } from "../../../Constants/ConstantsUrl";
import BoldCheckIcon from "@mui/icons-material/Check";
import { ShowLoader } from "../../Common/ApiCall";
import { APIProvider } from "../../../Components/Common/ApiProvider"
import Alerts from "../../Common/Alerts";
import BackdropLoader from "../../Common/BackdropLoader";
import PricingComponent from "./PriceComponent"
import { useMemo } from "react";
import { Tab, Tabs } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Index() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const BtnHeight = isMobile ? "62px" : "72px";
    const Btnwidth = isMobile ? "40px" : "57px";
    const [load, setLoad] = useState(true);

    const [allDurationPlans, setAllDurationPlans] = useState([]);
    const memoizedAllDurationPlans = useMemo(() => allDurationPlans, [allDurationPlans]);

    const [durationPlans, setDurationPlans] = useState([]);
    const memoizedDurationPlans = useMemo(() => durationPlans, [durationPlans]);

    const [userPlan, setUserPlan] = useState({});
    const memoizedUserPlan = useMemo(() => userPlan, [userPlan]);

    const [paymentLoader, setPaymentLoader] = useState(0);

    const [currency, setCurrency] = useState("USD");
    const [currencyList, setCurrencyList] = useState({});

    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const [durationTab, setDurationTab] = useState(0);

    const [billingCyclesList, setBillingCyclesList] = useState([]);
    const memoizedBillingCyclesList = useMemo(() => billingCyclesList, [billingCyclesList]);

    const fetchData = async () => {
        if (load) {
            try {
                await APIProvider(GetPricing, {}, "get").then((response) => {
                    if (response.status === 200) {
                        const planAndPricing = response.data.plan_and_pricing;
                        setAllDurationPlans(planAndPricing);
                        setCurrencyList(response?.data?.currency_list);
                        setBillingCyclesList(response?.data?.billing_cycles);

                        setDurationPlans(Object.values(planAndPricing)?.[0]);

                        const userData = response?.data?.user;
                        if (userData) {
                            setCurrency(userData?.currency);
                            setUserPlan(userData);

                            let durationTab = response?.data?.billing_cycles.findIndex((cycle, key) => cycle?.billing_type_slug == userData?.duration);
                            setDurationTab(durationTab);
                            setDurationPlans(planAndPricing[userData?.duration] ?? []);

                        }
                        console.log('memoizedUserPlan', memoizedUserPlan)
                    }
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setTimeout(() => {

                    setLoad(false);
                }, [1000])
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCurrencyButtonClick = (newCurrency) => {
        setCurrency(newCurrency);
    };

    const handleDurationTab = (event, newValue) => {
        let duration = memoizedBillingCyclesList.find((cycle, key) => key == newValue);
        setDurationTab(newValue);
        setDurationPlans(memoizedAllDurationPlans[duration?.billing_type_slug] ?? []);
    };

    return (
        <Box sx={{ paddingTop: "100px", }}>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Pricing - TestnHire</title>
                        <meta name="description" content="Princing of TestnHire" />
                    </Helmet>
                </div>
            </HelmetProvider>

            <Navbar />
            {paymentLoader ? <BackdropLoader /> : ""}
            <Container maxWidth="xl">
                <Box>
                    <Grid sx={{ mt: 5 }} container rowSpacing={0} columnSpacing={{ xs: 1, sm: 0, md: 0 }}>
                        <Grid
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            xs={12}
                            md={12}
                        >
                            <Item
                                style={{
                                    boxShadow: "unset",
                                    borderRadius: "none",
                                    background: "none",
                                }}
                            >
                                {" "}
                                <Typography fontWeight={700} style={{ fontSize: "45px", color: "var(--text-color-black)" }} variant="h1" sx={{ mt: 2 }}>
                                    Unlimited hiring potential
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>

                {load ? (
                    <ShowLoader />
                ) : (
                    <Box sx={{ flexGrow: 1, mt: 5, mb: 5 }}>
                        <Box sx={{ marginBottom: 3, borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={durationTab} onChange={handleDurationTab} aria-label="basic tabs example">
                                {
                                    memoizedBillingCyclesList.map((cycle, key) => {
                                        return (<Tab key={key} label={cycle.billing_type} {...a11yProps(key)} value={key} />);
                                    })
                                }
                            </Tabs>
                        </Box>
                        {
                            memoizedBillingCyclesList.map((cycle, key) => {
                                return (<CustomTabPanel key={key} value={durationTab} index={key}>
                                    <Grid container spacing={3}>
                                        {
                                            memoizedDurationPlans?.map((plan, Plankey) => {
                                                return (
                                                    <PricingComponent
                                                        key={Plankey}
                                                        Item={Item}
                                                        plan={plan}
                                                        setPaymentLoader={setPaymentLoader}
                                                        setAlertNotification={setAlertNotification}
                                                        currency={currency}
                                                        currencyList={currencyList}
                                                        cycle={cycle}
                                                        userPlan={memoizedUserPlan}
                                                        selectedPlan={memoizedUserPlan?.duration == cycle?.billing_type_slug && memoizedUserPlan?.plan_id == plan.id ? true : false}
                                                        setUserPlan={setUserPlan}
                                                    />
                                                )
                                            })
                                        }
                                    </Grid>
                                </CustomTabPanel>)
                            })
                        }
                    </Box>
                )}

                {Object.keys(memoizedUserPlan).length == 0 ? <Stack direction="row">
                    <Paper sx={{ boxShadow: 5, display: "flex", flexDirection: "column", position: "fixed", top: "25%", left: "0" }}>
                        {
                            Object.keys(currencyList).map((currencyCode) => (
                                <Button
                                    key={currencyCode}
                                    variant="text"
                                    sx={{
                                        color: currency === currencyCode ? "white" : "var(--button-bg-color-green)",
                                        backgroundColor: currency === currencyCode ? "var(--button-bg-color-green)" : "transparent",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                        minWidth: Btnwidth,
                                        minHeight: BtnHeight,
                                        borderRight: "0px",
                                        borderBottom: "1px solid #bbbbbb",
                                        flexDirection: "column",
                                        "&:hover": {
                                            backgroundColor: currency === currencyCode ? "var(--button-hover-bg-color-LightGreen)" : "unset",
                                            color: currency === currencyCode ? "white" : "var(--button-bg-color-green)",
                                        },
                                    }}
                                    onClick={() => handleCurrencyButtonClick(currencyCode)}
                                >
                                    {currency === currencyCode && <BoldCheckIcon sx={{ fontWeight: 700, height: "16px", width: "16px" }} />}
                                    {currencyCode}
                                </Button>
                            ))}
                    </Paper>
                </Stack> : <></>}

                {alertNotification?.status ? (
                    <Alerts
                        notification={alertNotification}
                        setNotification={setAlertNotification}
                    />
                ) : (
                    ""
                )}
            </Container>
            {/* <Footer /> */}
        </Box>
    );
}
