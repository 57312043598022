import { useEffect, useState } from "react";
import * as React from "react";
import { Grid, Typography, Paper, Container, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { APIProvider } from "../../../Components/Common/ApiProvider";
import { getUserCurrentPlanUrl, getUserBillingHistoryUrl, downloadPaymentPDFUrl, getHeaders } from "../../../Constants/ConstantsUrl";
import StripeTopUp from "./StripeTopUp";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import BackdropLoader from "../../Common/BackdropLoader";
import axios from "axios";
import LoaderButton from "../../Common/LoaderButton";
import Loader from "../Loader";
import useMediaQuery from "@mui/material/useMediaQuery";

dayjs.extend(advancedFormat);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));



export default function Billing({ customTheme, outerTheme }) {
    const isSmMobile = useMediaQuery("(max-width:610px)");
    const isMobile = useMediaQuery("(max-width:750px)");
    const isTablet = useMediaQuery("(max-width:1010px)");
    const isLaptop = useMediaQuery("(max-width:1250px)");
    const [currentPlan, setCurrentPlan] = useState({});
    const [billingHistory, setBillingHistory] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(true);
    const [paymentId, setPaymentId] = useState([]);

    const getUserCurrentPlan = async () => {
        try {
            await APIProvider(getUserCurrentPlanUrl, {}, "get").then((response) => {
                if (response?.status == 200) {
                    setCurrentPlan(response?.data);
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            getBillingHistory();
        }
    };

    const getBillingHistory = async () => {
        try {
            await APIProvider(getUserBillingHistoryUrl, {}, "get").then((response) => {
                if (response?.status == 200) {
                    setBillingHistory(response?.data?.user_plan_log);
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const downloadPDF = async (payment_id) => {
        setPaymentId([...paymentId, payment_id]);

        try {
            const headers = getHeaders();
            await axios.post(downloadPaymentPDFUrl, { payment_id: payment_id }, { headers, responseType: "blob" }).then(async (response) => {
                const contentType = response.headers["content-type"];
                if (contentType.includes("application/json")) {
                    console.error("Error fetching data");
                    setPaymentId((oldValues) => {
                        return oldValues.filter((id) => id !== payment_id);
                    });
                } else {
                    setPaymentId((oldValues) => {
                        return oldValues.filter((id) => id !== payment_id);
                    });
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Invoice.pdf");
                    document.body.appendChild(link);
                    link.click();
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setPaymentId((oldValues) => {
                return oldValues.filter((id) => id !== payment_id);
            });
        }
    };

    useEffect(() => {
        getUserCurrentPlan();
    }, []);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmMobile? 300: isMobile ? 600 :  isTablet? 700 : isLaptop ? 1000  : 1200,
        bgcolor: "#F2F2F2",
        border: "none",
        borderRadius: "8px",
        boxShadow: "0 6px 7px -4px #88888829,0 11px 15px 1px #8888881f,0 4px 20px 3px #88888823",
        p: 4,
        maxHeight: "700px",
        overflowY: "scroll",
        minWidth: 350 
    };
    return (
        <Container maxWidth="xl" sx={{ paddingX: 0 }}>
            {loading && <Loader />}
            <Grid container spacing={0}>
                <Grid item sx={{ p: 3 }} xs={12} md={6}>
                    <Item
                        sx={{
                            boxShadow: "0 6px 7px -4px #88888829,0 11px 15px 1px #8888881f,0 4px 20px 3px #88888823",
                            p: 3,
                        }}
                    >
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                Your current plan:
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 700 }}>
                                {currentPlan?.plan_name}
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                Credits Remaining:
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 700 }}>
                                {currentPlan?.credit_remaining}
                            </Typography>
                        </Grid>
                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 1 }}>
                            Generate and send an unlimited number of assessments to an unlimited number of candidates. Utilize a template designed to assess essential skills relevant to various job roles and industries.
                        </Typography>
                        <Grid sx={{ display: "flex", mt: 2 }}>
                            <CheckCircleOutlineOutlinedIcon sx={{ mr: 1 }} />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                Your current plan will never expire. {dayjs(currentPlan?.end_date).format("MMMM Do, YYYY")}
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", mt: 8, gap: "20px" }}>
                            <Button
                                onClick={handleOpen}
                                variant="text"
                                sx={{
                                    color: "var(--text-color-Green)",
                                    py: 1,
                                    fontWeight: 600,
                                    textTransform: "none",
                                    "&:hover": { bgcolor: "#fff0e1", borderRadius: "8px" },
                                }}
                            >
                                Billing history
                            </Button>
                            {/* <Button
                                variant="text"
                                sx={{
                                    color: "var(--text-color-Green)",
                                    py: 1,
                                    fontWeight: 600,
                                    textTransform: "none",
                                    "&:hover": { bgcolor: "#fff0e1",borderRadius:"8px", },
                                }}
                            >
                                Billing address
                            </Button>
                            <Button
                                variant="text"
                                sx={{
                                    color: "var(--text-color-Green)",
                                    py: 1,
                                    fontWeight: 600,
                                    textTransform: "none",
                                    "&:hover": { bgcolor: "#fff0e1",borderRadius:"8px", },
                                }}
                            >
                                Payment method
                            </Button> */}
                        </Grid>
                    </Item>
                </Grid>
                {/* <Grid item sx={{ p: 3 }} xs={12} md={6}>
                    <Item sx={{}}>
                        <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 500 }}>
                            Do more with our <span style={{ fontWeight: 700 }}>Starter</span> plan
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "var(--text-color-Green)",
                                mt: 2,
                            }}
                        >
                            ₹5,625 per month
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                fontStyle: "Italic",
                            }}
                        >
                            1-year commitment, pay ₹67,500 upfront.
                        </Typography>
                        <Divider sx={{ my: 2, width: "75%" }} />
                        <Grid sx={{ display: "flex", mt: 2 }}>
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    mr: 1,
                                    color: "var(--text-color-Green)",
                                    fontSize: "20px",
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                <span style={{ fontWeight: 700 }}>Unlimited</span> assessments, candidates and account users.
                            </Typography>
                        </Grid>

                        <Grid sx={{ display: "flex", mt: 2 }}>
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    mr: 1,
                                    color: "var(--text-color-Green)",
                                    fontSize: "20px",
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                All
                                <span style={{ fontWeight: 700 }}> 400 </span>
                                tests from the library.
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", mt: 2 }}>
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    mr: 1,
                                    color: "var(--text-color-Green)",
                                    fontSize: "20px",
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                Create custom essay, multiple-choice, and <span style={{ fontWeight: 700 }}>file-upload </span> questions.
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", mt: 2 }}>
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    mr: 1,
                                    color: "var(--text-color-Green)",
                                    fontSize: "20px",
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                Welcome video for candidates.
                            </Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", mt: 2 }}>
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                                sx={{
                                    mr: 1,
                                    color: "var(--text-color-Green)",
                                    fontSize: "20px",
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                PDF reports for assessments and candidates.
                            </Typography>
                        </Grid>
                    </Item>
                </Grid> */}
                {/* <StripeTopUp customTheme={customTheme} outerTheme={outerTheme} Item={Item} /> */}
                <Box>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        className="RemoveOutLine"
					sx={{ backdropFilter: "blur(4px)" }}
                    >
                        <Fade in={open}>
                            <Box sx={style}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Item sx={{ textAlign: "end", background: "none" }}>
                                            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer", mb: 2 }} />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr.</TableCell>
                                                <TableCell align="center">Transaction ID</TableCell>
                                                <TableCell align="center">Plan</TableCell>
                                                <TableCell align="center">Start Date</TableCell>
                                                <TableCell align="center">End Date</TableCell>
                                                <TableCell align="center">Amount</TableCell>
                                                <TableCell align="center">Credits</TableCell>
                                                <TableCell align="center">Transaction Date</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {billingHistory.map((row, key) => (
                                                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {key + 1}
                                                    </TableCell>
                                                    <TableCell align="center">{row?.transaction_id}</TableCell>
                                                    <TableCell align="center">{row?.plan_name}</TableCell>
                                                    <TableCell align="center">{dayjs(row.start_date).format("MMMM Do, YYYY")}</TableCell>
                                                    <TableCell align="center">{dayjs(row.end_date).format("MMMM Do, YYYY")}</TableCell>
                                                    <TableCell align="center">${row?.amount}</TableCell>
                                                    <TableCell align="center">{row?.credits}</TableCell>
                                                    <TableCell align="center">{dayjs(row.created_at).format("MMMM Do, YYYY")}</TableCell>
                                                    <TableCell align="center">
                                                        <LoaderButton
                                                            isLoading={paymentId.includes(row.payment_id) ? downloadLoading : false}
                                                            buttonText="Download"
                                                            onClick={() => downloadPDF(row.payment_id)}
                                                        />
                                                        {/* <Button sx={{
                                                            bgcolor: "var(--button-bg-color-green)",
                                                            p: 1,
                                                            width: "auto",
                                                            borderRadius: "4px",
                                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                            "&:hover": {
                                                                bgcolor: "var(--button-bg-color-green)",
                                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                            },
                                                        }}
                                                            onClick={() => downloadPDF(row.payment_id)}
                                                            variant="contained">Download</Button> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>
            </Grid>
        </Container>
    );
}
