import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Typography, Skeleton } from "@mui/material";
import DensityMediumRoundedIcon from "@mui/icons-material/DensityMediumRounded";
import CheckIcon from "@mui/icons-material/Check";
import { GET_SINGLE_QUESTION_DETAILS, getHeaders } from "./../../Constants/ConstantsUrl";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import TranslateIcon from "@mui/icons-material/Translate";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import Loader from "./Loader";
import Modal from "@mui/material/Modal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

const QuestionModal = ({ isOpen, onClose, ids, setIsModalOpen, isTestSelected, fromCall, optionForQModal, updateMainTest, handleRemoveTest, morethen5Error }) => {
    const [load, setLoad] = useState(true);
    const [main, setMain] = useState([]);
    const isMobile = useMediaQuery("(max-width:899px)");
    const isLaptop = useMediaQuery("(max-width:1199px)");
    const BorderRight = isMobile ? "none" : "1px solid gray";
    const MarginRight = isMobile ? "none" : "35px";
    const MarginLeft = isMobile ? "35px 0px 0px 0px" : "0px 0px 0px 35px";
    const ContainerPadding = isMobile ? "16px" : "24px";
    const FirstBoxPadding = isMobile ? "0px" : "8px 24px 24px 24px";
    const SecondBoxPadding = isMobile ? "0px" : "40px";
    const BtnPosition = isMobile ? "end" : "end";

    function isEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    useEffect(() => {
        if(!isEmpty(ids)){
            fetchData();
        }
    }, [ids]);

    const fetchData = async () => {
        setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(GET_SINGLE_QUESTION_DETAILS + `/${ids.id}`, {
                    headers,
                })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setMain(res.data[0]);
                        setLoad(false);
                    }
                });
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoad(false);
        }
    };
    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal open={isOpen}  className="RemoveOutLine" sx={{ backdropFilter: "blur(4px)" }} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    position: "fixed",
                    width: "95%",
                    height: "95%",
                    bgcolor: "#F2F2F2",
                    border: "none",
                    boxShadow: 24,
                    p: 0,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: "100vh",
                    overflowY: "auto",
                    zIndex: 99999,
                }}
            >
                <Box style={{ overflow: "auto" }} sx={{ bgcolor: "#F2F2F2", mb: 5 }}>
                    <Box>
                        <Container maxWidth="xxl">
                            <Box sx={{ flexGrow: 1, p: ContainerPadding }}>
                                <Grid container spacing={0}>
                                    <Grid xs={6} md={6}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                                justifyContent: "start",
                                            }}
                                        >
                                            {fromCall && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: isTestSelected(optionForQModal) ? "#d32f2f" : "var(--button-bg-color-green)",
                                                        color: "var(--text-color-black)",
                                                        p: 2,
                                                        mb: 0,
                                                        boxShadow: "none",
                                                        textTransform: "none",
                                                        borderRadius: "0px",
                                                        "&:hover": {
                                                            bgcolor: isTestSelected(optionForQModal) ? "#d32f2f" : "var(--button-hover-bg-color-LightGreen)",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={() => {
                                                        isTestSelected(optionForQModal)
                                                            ? handleRemoveTest(optionForQModal)
                                                            : updateMainTest(optionForQModal.question_title, optionForQModal.id, optionForQModal.time, optionForQModal.question_type_id, null, null);
                                                    }}
                                                >
                                                    {isTestSelected(optionForQModal) ? (
                                                        <>
                                                            <RemoveCircleOutlineIcon sx={{ color: "var(--text-color-OffWhite)", mr: 2 }} />
                                                            <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "500", color: "var(--text-color-OffWhite)" }}>
                                                                Remove Question
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <AddCircleOutlineIcon sx={{ color: "var(--text-color-OffWhite)", mr: 2 }} />
                                                            <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "500", color: "var(--text-color-OffWhite)" }}>
                                                                Add Question
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Button>
                                            )}
                                            {/* <Button
                                                sx={{
                                                    bgcolor: "#00a789",
                                                    color: "var(--text-color-black)",
                                                    p: 2,
                                                    mb: 0,
                                                    boxShadow: "none",
                                                    textTransform: "none",
                                                    borderRadius: "0px",
                                                    "&:hover": {
                                                        bgcolor: "#00a789",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                {
                                                    <VisibilityOutlinedIcon
                                                        sx={{
                                                            color: "var(--text-color-OffWhite)",
                                                            mr: 2,
                                                        }}
                                                    ></VisibilityOutlinedIcon>
                                                }

                                                <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "500", color: "var(--text-color-OffWhite)" }}>
                                                    Preview sample questions
                                                </Typography>
                                            </Button> */}
                                        </Item>
                                    </Grid>
                                    <Grid xs={6} md={6}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                                justifyContent: BtnPosition,
                                            }}
                                        >
                                            {load ? (
                                                <Button onClick={handleClose}>
                                                    <Skeleton animation="wave" variant="square" height={20} width={20} />
                                                </Button>
                                            ) : (
                                                <Button onClick={handleClose}>
                                                    <CloseIcon sx={{ color: "var(--text-color-black)" }}></CloseIcon>
                                                </Button>
                                            )}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            {fromCall && morethen5Error.msg !== null && morethen5Error.id === optionForQModal.id && (
                                <Grid xs={12}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                            padding: "10px",
                                            display: "flex",
                                            justifyContent: "end",
                                            color: "red",
                                            fontWeight: 600,
                                        }}
                                    >
                                        <div className="error_div">{morethen5Error.msg}</div>
                                    </Item>
                                </Grid>
                            )}

                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid
                                        style={{
                                            borderRight: BorderRight,
                                        }}
                                        xs={12}
                                        md={6}
                                    >
                                        <Item
                                            sx={{ p: FirstBoxPadding, mr: MarginRight }}
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                            }}
                                        >
                                            {load ? (
                                                <>
                                                    <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: 700 }}>
                                                        <Skeleton animation="wave" variant="text" />
                                                    </Typography>
                                                    <Typography sx={{ mt: 3, fontSize: "14px", fontWeight: 400 }} variant="body1">
                                                        <Skeleton animation="wave" variant="text" />
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: 700 }}>
                                                        {main?.question_title ?? ""}
                                                    </Typography>
                                                    <Typography sx={{ mt: 3, fontSize: "14px", fontWeight: 400 }} variant="body1">
                                                        {main?.summary ?? ""}
                                                    </Typography>
                                                </>
                                            )}

                                            <Box sx={{ flexGrow: 1, mt: 5 }}>
                                                <Grid container spacing={0}>
                                                    <Grid xs={6} sm={3} md={6} lg={3}>
                                                        {load ? (
                                                            <Skeleton animation="wave" variant="rectangular" height={200} />
                                                        ) : (
                                                            <>
                                                                <Item
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "0px",
                                                                        minHeight: "160px",
                                                                        maxHeight:"200px",
                                                                        boxShadow: "none",
                                                                        borderRight: "1px solid #e5e5e5",
                                                                        padding: "20px",
                                                                    }}
                                                                >
                                                                    {
                                                                        <DensityMediumRoundedIcon
                                                                            sx={{
                                                                                color: "var(--text-color-black)",
                                                                            }}
                                                                        ></DensityMediumRoundedIcon>
                                                                    }
                                                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "700" }}>
                                                                        Type
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "400" }}>
                                                                        {main?.question_category}
                                                                        <br />
                                                                        {main?.question_type}
                                                                    </Typography>
                                                                </Item>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid xs={6} sm={3} md={6} lg={3}>
                                                        {load ? (
                                                            <Skeleton animation="wave" variant="rectangular" height={200} />
                                                        ) : (
                                                            <>
                                                                <Item
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "0px",
                                                                        boxShadow: "none",
                                                                        borderRight: "1px solid #e5e5e5",
                                                                        padding: "20px",
                                                                        maxHeight:"200px",
                                                                        minHeight: "160px",
                                                                    }}
                                                                >
                                                                    {
                                                                        <AccessAlarmIcon
                                                                            sx={{
                                                                                color: "var(--text-color-black)",
                                                                            }}
                                                                        ></AccessAlarmIcon>
                                                                    }
                                                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "700" }}>
                                                                        Time
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "400" }}>
                                                                        {main?.time} mins
                                                                    </Typography>
                                                                </Item>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid xs={6} sm={3} md={6} lg={3}>
                                                        {load ? (
                                                            <Skeleton animation="wave" variant="rectangular" height={200} />
                                                        ) : (
                                                            <>
                                                                <Item
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "0px",
                                                                        boxShadow: "none",
                                                                        borderRight: "1px solid #e5e5e5",
                                                                        padding: "20px"
                                                                        ,maxHeight:"200px",
                                                                        minHeight: "160px",
                                                                     }}
                                                                    sx={{
                                                                        borderTop: (theme) => ({
                                                                            xs: "1px solid #e5e5e5",  
                                                                            sm: "none",  
                                                                            md: "1px solid #e5e5e5", 
                                                                            lg: "none", 
                                                                        }),
                                                                    }}
                                                                >
                                                                    {
                                                                        <TranslateIcon
                                                                            sx={{
                                                                                color: "var(--text-color-black)",
                                                                            }}
                                                                        ></TranslateIcon>
                                                                    }
                                                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "700" }}>
                                                                        Languages
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "400" }}>
                                                                        {main?.language}
                                                                    </Typography>
                                                                </Item>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid xs={6} sm={3} md={6} lg={3}>
                                                        {load ? (
                                                            <Skeleton animation="wave" variant="rectangular" height={200} />
                                                        ) : (
                                                            <>
                                                                <Item
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "0px",
                                                                        boxShadow: "none",
                                                                        padding: "20px",
                                                                        maxHeight:"200px",
                                                                        minHeight: "160px",
                                                                    }}
                                                                    sx={{
                                                                        borderTop: (theme) => ({
                                                                            xs: "1px solid #e5e5e5",  
                                                                            sm: "none",  
                                                                            md: "1px solid #e5e5e5", 
                                                                            lg: "none", 
                                                                        }),
                                                                    }}
                                                                >
                                                                    {
                                                                        <SignalCellularAltOutlinedIcon
                                                                            sx={{
                                                                                color: "var(--text-color-black)",
                                                                            }}
                                                                        ></SignalCellularAltOutlinedIcon>
                                                                    }
                                                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "700" }}>
                                                                        Level
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "400" }}>
                                                                        {main?.question_level}
                                                                    </Typography>
                                                                </Item>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700", mt: 5 }}>
                                                {load ? <Skeleton animation="wave" variant="text" width={200} /> : "Covered skills"}
                                            </Typography>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                {load ? (
                                                    <Skeleton animation="wave" variant="text" width={600} />
                                                ) : (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {
                                                                <CheckIcon
                                                                    sx={{
                                                                        color: "var(--text-color-black)",
                                                                        height: "30px",
                                                                    }}
                                                                ></CheckIcon>
                                                            }
                                                        </Grid>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: "400", mt: 1, pl: 2 }} variant="body1">
                                                            {main?.covered_skills}
                                                        </Typography>
                                                    </>
                                                )}
                                            </Box>
                                            <Typography sx={{ fontSize: "16px", fontWeight: "700", mt: 5 }} variant="h6">
                                                {load ? <Skeleton animation="wave" variant="text" width={200} /> : "This test is relavent for"}
                                            </Typography>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "400", mt: 1 }} variant="body1">
                                                {load ? <Skeleton animation="wave" variant="text" /> : main?.test_is_relevant_for}
                                            </Typography>
                                        </Item>
                                    </Grid>

                                    <Grid xs={12} md={6}>
                                        <Item
                                            sx={{ pl: SecondBoxPadding, m: MarginLeft }}
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                                {load ? <Skeleton animation="wave" variant="text" width={200} /> : "Description"}
                                            </Typography>
                                            {load ? (
                                                <>
                                                    <Skeleton animation="wave" variant="text" />
                                                    <Skeleton animation="wave" variant="text" />
                                                    <Skeleton animation="wave" variant="text" />
                                                </>
                                            ) : (<>
                                            <Typography variant="body1" sx={{fontSize:"14px", fontWeight:400, mt:3}}>
                                                {main?.long_desc}
                                                </Typography>
                                                </>
                                            )}
                                            {/* <Typography sx={{ fontSize: "16px", fontWeight: "700", mt: 5 }} variant="h6">
                                                About the subject- matter expert
                                            </Typography> */}
                                            {/* <Box sx={{ flexGrow: 1 }}>
                                                <Grid container spacing={0}>
                                                    <Grid sx={{ mt: 1 }} xs={12} sm={12} md={12} lg={2}>
                                                        <img
                                                            style={{
                                                                height: "80px",
                                                                borderRadius: "10px",
                                                            }}
                                                            src={Popup}
                                                            alt="../../../../Assests/Images/PopUpImg.jpg"
                                                        />
                                                    </Grid>
                                                    <Grid sx={{ ml: MarginLeft }} xs={12} sm={12} md={12} lg={9}>
                                                        <Item
                                                            style={{
                                                                boxShadow: "unset",
                                                                borderRadius: "none",
                                                                background: "none",
                                                            }}
                                                        >
                                                            {" "}
                                                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                                {main?.subject_matter_expert}
                                                            </Typography>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Box> */}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
export default QuestionModal;
