export let homeRoute = true;
export let appRoute = true;
export let talentRoute = true;
export let candidateRoute = true;

export let homeRoutePath = process.env.REACT_APP_HOME_URL;
export let appRoutePath = process.env.REACT_APP_APP_URL;
export let talentRoutePath = process.env.REACT_APP_TALENT_URL;
export let candidateRoutePath = process.env.REACT_APP_CANDIDATE_URL;

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const FRONT_Url = process.env.REACT_APP_HOME_URL;
export const Logout_API = API_BASE_URL + "/logout";
export const GET_SINGLE_QUESTION_DETAILS = API_BASE_URL + "/official-question/get-question";
export const TestTypeCategory = API_BASE_URL + "/get-test-type-category";

// Auth URLS
export const GetPricing = API_BASE_URL + "/get-pricing-list";
export const getUserCurrentPlanUrl = API_BASE_URL + "/profile/current-plan";
export const getUserBillingHistoryUrl = API_BASE_URL + "/profile/get-user-billing-history";
export const downloadPaymentPDFUrl = API_BASE_URL + "/profile/download-payment-receipt";
export const getTopUpList = API_BASE_URL + "/pricing/get-topup-plan";
export const payStripeUrl = API_BASE_URL + "/order/pay";
export const addCreditUrl = API_BASE_URL + "/order/addCredits";

export const getWorkArrangement = API_BASE_URL + "/assessment/get/work-arrangement";
export const assessmentGetData = API_BASE_URL + "/assessment/get";
export const singleAssessmentGetData = API_BASE_URL + "/assessment/get/";
export const AssessmentGetForInvite = API_BASE_URL + "/assessment/get/get-assessment-for-invite/";
export const AssessmentInvite = API_BASE_URL + "/assessment/get/invite-candidate/";
export const SendVerificationMail = API_BASE_URL + "/email/resend";
export const ConfirmVerificationMail = API_BASE_URL + "/email/verify";
export const condidateInvite = API_BASE_URL + "/assessment/add-user/by-email";
export const addMembers = API_BASE_URL + "/profile/add-member";
export const getProfileTeamMember = API_BASE_URL + "/profile/get-team-member";
export const getWebRole = API_BASE_URL + "/get-role";
export const getAllWebRole = API_BASE_URL + "/get-all-web-role";
export const TeamMembersResendPasswordLink = API_BASE_URL + "/profile/resend-password-link";
export const AssessmentEssentialsData = API_BASE_URL + "/assessment/get/assessment-creation-details";

export const setCondidateHireStage = API_BASE_URL + "/assessment/candidate/hiring-stages/update/change-hiring-stage";

//assessment create and update
export const assessmentGetDataStepOne = API_BASE_URL + "/assessment/get/first-step/";
export const assessmentGetDataStepTwo = API_BASE_URL + "/assessment/get/final-step/";
export const assessmentCreateDataStepOne = API_BASE_URL + "/assessment/create/first-step";
export const assessmentCreateDataStepTwo = API_BASE_URL + "/assessment/create/final-step";
export const assessmentCreateDataStepTwoVideo = API_BASE_URL + "/assessment/create/upload-video";
export const assessmentUpdateDataStepOne = API_BASE_URL + "/assessment/update/first-step";
export const assessmentUpdateDataStepTwo = API_BASE_URL + "/assessment/update/final-step";
export const assessmentUploadCsvFile = API_BASE_URL + "/assessment/add-user/bulk-invite/upload-file";
export const publicAssessmentStatusUpdateUrl = API_BASE_URL + "/assessment/status/public-assessment-invite";
export const getHiringStages = API_BASE_URL + "/assessment/candidate/hiring-stages/get";
export const renameAssessmentName = API_BASE_URL + "/assessment/update/rename";
export const cloneAssessment = API_BASE_URL + "/assessment/clone";
export const donwloadcondidateTemplateCsv = API_BASE_URL + "/add-user/bulk-invite/download-template/csv";
export const donwloadcondidateTemplateXlsx = API_BASE_URL + "/add-user/bulk-invite/download-template/xlsx";
export const updateTestWeight = API_BASE_URL + "/assessment/update/test-weight";
export const getLanguage = API_BASE_URL + "/lang";
export const getJobRole = API_BASE_URL + "/assessment/get/job-roles";
export const getJobRoleOp = API_BASE_URL + "/job-roles";
export const updateArchive = API_BASE_URL + "/assessment/update/archive";
export const getArchive = API_BASE_URL + "/assessment/get/archive";
export const getAssessmentTeamMember = API_BASE_URL + "/assessment/get/team-member";
export const getAssessmentQualify = API_BASE_URL + "/qualifying-question/library";
export const getInactive = API_BASE_URL + "/assessment/get/getInactive";
export const getPreviewQuestionUrl = API_BASE_URL + "/preview-question/get-question/";
export const getAssessmentPreviewTestsUrl = API_BASE_URL + "/preview-question/get-question/bunch";



//candidate
export const getCandidateReport = API_BASE_URL + "/candidate-report/get/candidate-report";
export const CandidateUpdateNote = API_BASE_URL + "/candidate-report/post/candidate-report/private-note";
export const CandidateUpdateRating = API_BASE_URL + "/candidate-report/post/candidate-report/custom-rating";
export const CandidateUpdatePrivateRating = API_BASE_URL + "/candidate-report/post/candidate-report/candidate-rating";
export const CandidateUpdateQuestionComment = API_BASE_URL + "/candidate-report/post/question-comment";
export const candidateAllReportGet = API_BASE_URL + "/assessment/candidate/all-report/get";
export const getSingleAssessmentCandidates = API_BASE_URL + "/assessment/get/candidate-list/";
//Question search and Filter
export const GetMainQuestionSearch = API_BASE_URL + "/official-question/get-question/search/";
export const GetMainQuestion = API_BASE_URL + "/official-question/get-question";
export const getCustomAllQuestion = API_BASE_URL + "/custom-question/get-question";
export const getCustomFilterQuestion = API_BASE_URL + "/custom-question/get-question/filtered-questions";
export const getOfficialFilterQuestion = API_BASE_URL + "/official-question/get-question/filtered-questions";

// Create Custom Question Answer
export const createCustomVideoQuestion = API_BASE_URL + "/custom-question/create/video-question";
export const createCustomEssayQuestion = API_BASE_URL + "/custom-question/create/essay-question";
export const createCustomFileQuestion = API_BASE_URL + "/custom-question/create/file-upload-question";
export const createCustomMcqQuestion = API_BASE_URL + "/custom-question/create/mcq-question";
export const createCustomCodeQuestion = API_BASE_URL + "/custom-question/create/code-question";
export const createQualifyingCustomMcqQuestion = API_BASE_URL + "/qualifying-question/create/mcq-question";
export const getUserList = API_BASE_URL + "/users";
export const getProgrammingLanguages = API_BASE_URL + "/get-all-programming-lang";
export const getCustomQuestionById = API_BASE_URL + "/custom-question/get-custom-question";
export const getMcqQuestion = API_BASE_URL + "/custom-question/get/mcq-question";
export const getEssayQuestion = API_BASE_URL + "/custom-question/get/essay-question";
export const getVideoQuestion = API_BASE_URL + "/custom-question/get/video-question";
export const getCodeQuestion = API_BASE_URL + "/custom-question/get/code-question";
export const getFileQuestion = API_BASE_URL + "/custom-question/get/file-upload-question";

// candidate API
export const DeleteCandidateFromAssessment = API_BASE_URL + "/assessment/candidate/update/remove-candidate";
export const getCandidates = API_BASE_URL + "/profile/candidates";
export const GetCandidatesFilterData = API_BASE_URL + "/profile/candidates-filter";
export const updateHiringStageInBulk = API_BASE_URL + "/assessment/candidate/hiring-stages/update/change-hiring-stage-in-bulk";
export const sendReminder = API_BASE_URL + "/assessment/candidate/send-reminder";

export const candidateResultPDFDownload = API_BASE_URL + "/candidate-report/get/candidate-report-pdf";
export const assessmentResultPDFDownload = API_BASE_URL + "/candidate-report/get/assessment-report-pdf";

// Talent assessment API's
export const candidateAssessmentUrl = talentRoutePath + "/confirm-details/";

// Profile API's
export const getUserDetailsUrl = API_BASE_URL + "/profile";
export const myProfileSaveUrl = API_BASE_URL + "/profile/update/profile";
export const myProfileChangeEmailUrl = API_BASE_URL + "/profile/update/email-address";
export const myProfileChangePasswordUrl = API_BASE_URL + "/profile/update/password";
export const getMyCompanyDetailsUrl = API_BASE_URL + "/profile/my-company";
export const updateMyCompanyDetailsUrl = API_BASE_URL + "/profile/update/my-company";
export const updateTeamMemberUrl = API_BASE_URL + "/profile/update/team-member/";
export const uploadCompanyLogo = API_BASE_URL + "/profile/my-company/upload-logo";

export const getHeaders = () => {
    const accessToken = localStorage.getItem("accessToken");
    const headers = {
        "Content-Type": "application/json",
    };

    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return headers;
};

export const getFileHeaders = () => {
    const accessToken = localStorage.getItem("accessToken");
    const headers = {
        "Content-Type": "multipart/form-data",
    };

    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return headers;
};

export const excludeUrl = [
    '/customer/preview-questions/'
];