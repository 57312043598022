import React from "react";
import { Button, Typography, Grid, IconButton, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

const CardLoaderSkeleton = (key) => {
    return (
        <Grid key={key} xs={12} sm={12} md={6} lg={4} sx={{ p: 1 }}>
            <Item
                style={{
                    borderRadius: "4px",
                    boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                    padding: "20px",
                }}
            >
                <Skeleton variant="rectangular" animation="wave" width={315} height={50} />
                <Skeleton variant="rectangular" animation="wave" width={310} height={85} sx={{ mt: 3, mb: 2 }} />
                <Grid container spacing={0}>
                    <Grid xs={12}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                display: "flex",
                                alignItems: "center",
                                paddingBottom: "0px",
                            }}
                        >
                            <Skeleton variant="rectangular" animation="wave" width={70} height={30} />
                        </Item>
                    </Grid>
                    <Grid xs={12}>
                        <Item
                            style={{
                                display: "flex", // Enables flexbox
                                justifyContent: "space-between", // Aligns children to either end
                                alignItems: "center", // Centers children vertically
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                paddingTop: "0px",
                                marginTop: "8px",
                            }}
                        >
                            <Skeleton width={90} height={50} animation="wave" />
                            <Skeleton animation="wave" width={70} height={40} />
                        </Item>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    );
};

export default CardLoaderSkeleton;
