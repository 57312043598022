import { Grid, Typography, Paper, Container, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { getHeaders, getProfileTeamMember, getWebRole, TeamMembersResendPasswordLink, getUserDetailsUrl } from "../../../Constants/ConstantsUrl";
import AddMemberModal from "./Modals/AddMemberModal";
import UpdateMemberModal from "./Modals/UpdateMemberModal";
import { AllowedAccess } from "react-permission-role";
import Alerts from "../../Common/Alerts";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../Loader";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));

const options = {
    filter: false,
    responsive: "standard",
    download: false,
    sort: true,
    selectableRows: "none",
    print: false,
    viewColumns: false,
    searchOpen: true,
    searchText: "",
    search: false,
    customSearchRender: () => null,
};

const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",
    border: "none",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "30px",
    paddingRight: "30px",
};

function TeamManagement() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const [webRoleData, setWebRoleData] = useState([]);
    const [teamMember, setTeamMember] = useState([]);
    const [isAdddMemberModalOpen, setAddMemberModalIsOpen] = useState(false);
    const [isUpDateMemberModalOpen, setUpDateMemberModalIsOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState(null);

    const editMember = async (index) => {
        let member = teamMember[index];
        member.role_id = member?.role?.id;
        setSelectedMember(member);
        setUpDateMemberModalIsOpen(!isUpDateMemberModalOpen);
    };

    const resendPasswordLinkMember = async (index) => {
        setLoading(true);
        let member = teamMember[index];
        const headers = getHeaders();
        await axios
            .post(TeamMembersResendPasswordLink, { id: member.id }, { headers })
            .then((response) => response)
            .then((response) => {
                const res = response.data;
                if (res.status === 200) {
                    setAlertNotification({
                        status: true,
                        type: "success",
                        message: res.data.message,
                    });
                }
            });
        setLoading(false);
    };

    const columns = [
        {
            name: "role",
            label: "TYPE",
            mantineTableHeadCellProps: {
                align: "right",
            },
            mantineTableBodyCellProps: {
                align: "right",
            },
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    let row = teamMember[tableMeta.rowIndex];
                    return (
                        <Typography
                            variant="contained"
                            style={{
                                color: "var(--text-color-OffWhite)",
                                backgroundColor:
                                    row.role.name === "Owner"
                                        ? "rgb(39 98 87)"
                                        : row.role.name === "Super Admin"
                                            ? "var(--text-color-Green)"
                                            : row.role.name === "Recruiter"
                                                ? "#cca45f"
                                                : row.role.name === "Hiring Manager"
                                                    ? "rgb(159 137 137)"
                                                    : row.role.name === "Admin"
                                                        ? "#cb7b7a"
                                                        : "#cca45f",
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            {row.role.name}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "first_name",
            label: "NAME",
            mantineTableHeadCellProps: {
                align: "right",
            },
            mantineTableBodyCellProps: {
                align: "right",
            },
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    let row = teamMember[tableMeta.rowIndex];
                    return (
                        <>
                            {row.first_name} {row.last_name}
                        </>
                    );
                },
            },
        },
        {
            name: "email",
            label: "EMAIL",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "action",
            label: "ACTION",
            mantineTableHeadCellProps: {
                align: "right",
            },
            mantineTableBodyCellProps: {
                align: "right",
            },
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    let member = teamMember[tableMeta.rowIndex];
                    return (
                        <>
                            <Grid sx={{ display: "flex", justfyContent: "end" }}>
                                {/* <typography title="Group Change" variant="contained" style={{ padding: 4, cursor: "pointer",}}>
                            <GroupIcon  sx={{color:"#c2c7c2",mr:2}}/>
                        </typography> */}

                                <Typography title="Send reset password email" variant="contained" style={{ padding: 4, cursor: "pointer" }} onClick={() => member?.role?.is_editable ? resendPasswordLinkMember(tableMeta.rowIndex):""}>
                                    <MailOutlineIcon sx={{ color: member?.role?.is_editable ? "var(--text-color-black)" : "#c2c7c2", mr: 2 }} />
                                </Typography>

                                <AllowedAccess permissions="edit_team">
                                    <Typography title="Edit" variant="contained" style={{ padding: 4, cursor: "pointer" }} onClick={() => member?.role?.is_editable ? editMember(tableMeta.rowIndex) : ""}>
                                        <EditIcon sx={{ color: member?.role?.is_editable ? "var(--text-color-black)" : "#c2c7c2", mr: 2 }} />
                                    </Typography>
                                </AllowedAccess>

                                {/* <AllowedAccess permissions="delete_team">
                                <typography title="Remove" variant="contained" style={{ padding: 4, cursor: "pointer" }}>
                                    <RemoveCircleOutlineIcon sx={{ color: "#c2c7c2", mr: 2 }} />
                                </typography>
                            </AllowedAccess> */}
                            </Grid>
                        </>
                    )
                },
            },
        },
    ];

    const getWebRoleFun = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getWebRole, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setWebRoleData(res.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getTeamMembersData = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getProfileTeamMember, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        setTeamMember(response.data.data?.user_details);
                    }
                });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const addMembertoggleModal = () => {
        setAddMemberModalIsOpen(!isAdddMemberModalOpen);
    };

    const updateMembertoggleModal = () => {
        setUpDateMemberModalIsOpen(!isUpDateMemberModalOpen);
    };

    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const getCurrentUserRole = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getUserDetailsUrl, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setUserRole(response.data.data.role);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCurrentUserRole();
        getWebRoleFun();
        getTeamMembersData();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Grid container>
                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} md={6}>
                    <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700" }}>
                        Add or remove team members
                    </Typography>
                </Grid>
                <AllowedAccess permissions="add_team">
                    <Grid item xs={12} md={6}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                display: "flex",
                                justifyContent: isMobile ? "start" : "end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={addMembertoggleModal}
                                style={btnStyle}
                                sx={{
                                    textTransform: "none",
                                    background: "var(--button-bg-color-green)",
                                    "&:hover": {
                                        background: "var(--button-hover-bg-color-LightGreen)",
                                    },
                                }}
                                variant="outlined"
                            >
                                <AddCircleOutlineIcon
                                    sx={{
                                        color: "var(--text-color-OffWhite)",
                                        mr: 2,
                                    }}
                                ></AddCircleOutlineIcon>

                                <Typography
                                    variant="body1"
                                    fontWeight={600}
                                    sx={{
                                        fontSize: "var(  --btn-fontsize)",
                                        color: "var(--text-color-OffWhite)",
                                    }}
                                >
                                    { }
                                    Add user
                                </Typography>
                            </Button>
                        </Item>
                    </Grid>
                </AllowedAccess>
            </Grid>

            <Paper
                className="ProfileTable"
                style={{
                    borderRadius: "8px",
                    padding: "0px",
                    marginTop: "20px",
                    boxShadow: "0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
                }}
            >
                {loading && (
                    <>
                        <Loader />
                    </>
                )}
                <MUIDataTable data={teamMember} columns={columns} options={options} />
            </Paper>

            {isAdddMemberModalOpen && (
                <AddMemberModal webRoleData={webRoleData} teamMember={teamMember} setTeamMember={setTeamMember} onAddRequestClose={addMembertoggleModal} setAlertNotification={setAlertNotification} />
            )}
            {isUpDateMemberModalOpen && (
                <UpdateMemberModal
                    webRoleData={webRoleData}
                    onUpdateRequestClose={updateMembertoggleModal}
                    selectedMember={selectedMember}
                    setAlertNotification={setAlertNotification}
                    getTeamMembersData={getTeamMembersData}
                    userRole={userRole}
                />
            )}
            {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
        </Container>
    );
}

export default TeamManagement;
