import { Grid, Box, Typography, Tabs, Tab, Paper, Container, TextField, Stack, Button, Divider, Autocomplete, useMediaQuery, CircularProgress } from "@mui/material";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Layouts/Index";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import axios from "axios";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import InputLabel from "@mui/material/InputLabel";
import { useLocation } from "react-router-dom";
import { getHeaders, getLanguage, myProfileSaveUrl, getUserDetailsUrl } from "../../../Constants/ConstantsUrl";

import { AllowedAccess } from "react-permission-role";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import ChangeEmailModal from "./Modals/ChangeEmailModal";
import Alerts from "../../Common/Alerts";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TeamManagement from "./TeamManagement";
import MyCompany from "./MyCompany";
import Billing from "./Billing";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box key={index} sx={{ py: 3 }}>
                    <Typography variant="text">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",
    border: "none",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "30px",
    paddingRight: "30px",
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));

function Profile() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const [user, setUser] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const location = useLocation();
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIndex = parseInt(params.get("tabIndex")) || 0;
        setValue(tabIndex);
    }, [location.search]);
    const [formData, setFormData] = useState({
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_no: "",
    });
    const [language, setLanguage] = useState([]);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setSelectedTab(newValue);
    };
    const navigate = useNavigate();
    const outerTheme = useTheme();
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "--TextField-brandBorderColor": "#E0E3E7",
                            "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                            "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                            "& label.Mui-focused": {
                                color: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: "var(--TextField-brandBorderColor)",
                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            "&::before, &::after": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            "&::before": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
            },
        });
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const setFormDataValues = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };
    const getLanguageList = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getLanguage, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setLanguage(response.data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const getUserDetails = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getUserDetailsUrl, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setUser(response.data.data);
                        setFormData(response.data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (user == null) {
            navigate("/login");
        }
        getLanguageList();
        getUserDetails();
    }, []);
    const handelSubmitMyProfile = async () => {
        setIsLoading(true);

        // Trim all whitespace from string values in the object
        const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = typeof value === 'string' ? value.trim() : value;
            return acc;
        }, {});
        
        const dataSended = {
            user_id: trimmedFormData.id,
            first_name: trimmedFormData.first_name,
            last_name: trimmedFormData.last_name,
            email: trimmedFormData.email,
            phone_no: trimmedFormData.phone_no,
            lang_id: trimmedFormData.lang_id,
        };
        try {
            const headers = getHeaders();
            await axios
                .post(myProfileSaveUrl, dataSended, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setFormData({
                            ...res?.data?.user_details,
                        });

                        localStorage.setItem(
                            "user",
                            JSON.stringify({
                                ...user,
                                ...res?.data?.user_details,
                            })
                        );

                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                    }
                });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let response = error?.response?.data;
            if (response?.data instanceof Object) {
                let inputs = response?.data;
                setAlertNotification({
                    status: true,
                    type: "error",
                    message: Object.values(inputs)[0],
                });
            }
        }
    };
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });
    return (
        <Box>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Profile - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Navbar />
            <Box
                sx={{
                    bgcolor: !isMobile ? "#f5f6f6" : "white",
                    paddingBottom: "150px",
                    paddingTop: "100px",
                }}
            >
                <Container maxWidth="xl" style={{ backgroundColor: "white", height: "auto", overflowY: "scroll",padding:"0px"}}>
                    <Grid
                        sx={{ 
                            width: "100%",
                        }}
                        className="customTabs"
                    >
                        <Box sx={{ borderBottom: 2, borderColor: "divider" }} style={{ overflowX: "scroll", overflow: "unset" }}>
                            
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                textColor="inherit"
                                indicatorColor="fff"
                                aria-label="secondary tabs example"
                                variant="fullWidth"
                                sx={{
                                    "& .Mui-selected": {
                                        color: "var(--text-color-black)",
                                        height: "70px",
                                    },
                                    "& .MuiTabs-indicator": {
                                        backgroundColor: "fff", // Set the indicator color to #0a5c48
                                        height: "0", // Adjust the indicator height
                                    },
                                }}
                                color="inherit"
                                className="custom_border_calss"
                            >
                                <Tab
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        width: "25%",
                                    }}
                                    label="My Profile"
                                    {...a11yProps(0)}
                                />
                                <AllowedAccess permissions="my_company">
                                    <Tab
                                        style={{
                                            textTransform: "none",
                                            height: "70px",
                                            color: selectedTab === 1 ? "var(--text-color-black)" : "#000000DE",
                                            fontWeight: selectedTab === 1 ? 600 : 500,
                                            fontSize: selectedTab === 1 ? "16px" : "16px",
                                            width: "25%",
                                        }}
                                        label="My Company"
                                        {...a11yProps(1)}
                                        onClick={(e) => {
                                            handleTabChange(e, 1);
                                        }}
                                        className={selectedTab === 1?"custom_my_company_Class Mui-selected":""}
                                    />
                                </AllowedAccess>
                                <Tab
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        width: "25%",
                                    }}
                                    label="Team Management"
                                    {...a11yProps(2)}
                                    onClick={(e) => {
                                        handleTabChange(e, 2);
                                    }}
                                />
                                <Tab
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        width: "25%",
                                    }}
                                    label="Plan & Billing"
                                    {...a11yProps(3)}
                                />
                            </Tabs>
                        </Box>
                    </Grid>

                    <Box>
                        <CustomTabPanel value={value} index={0} sx={{ paddingX: 0 }}>
                            <Container maxWidth="xxl" sx={{ paddingX: 0 }}>
                                <Grid>
                                    <Grid>
                                        <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700", mt: 5 }}>
                                            Personal information
                                        </Typography>
                                    </Grid>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={0}>
                                            <Grid item sx={{ pr: "24px", mt: 3 }} xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        "& > :not(style)": {},
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            sx={{
                                                                ml: 1,
                                                                width: "100%",
                                                            }}
                                                            id="outlined-basic"
                                                            label="First Name"
                                                            variant="outlined"
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            value={formData.first_name}
                                                        />
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ pr: "24px", mt: 3 }} xs={12} md={6}>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        "& > :not(style)": {},
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            sx={{
                                                                ml: 1,
                                                                width: "100%",
                                                            }}
                                                            id="outlined-basic"
                                                            label="Last Name"
                                                            variant="outlined"
                                                            name="last_name"
                                                            onChange={handleChange}
                                                            value={formData.last_name}
                                                        />
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={0}>
                                            <Grid item sx={{ pr: "24px", mt: 5 }} xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        "& > :not(style)": {},
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            sx={{
                                                                ml: 1,
                                                                width: "100%",
                                                            }}
                                                            id="outlined-basic"
                                                            label="Email"
                                                            variant="outlined"
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={formData.email}
                                                        />
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>

                                            <Grid item sx={{ pr: "24px", mt: 5 }} xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        "& > :not(style)": {},
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    {" "}
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            sx={{
                                                                ml: 1,
                                                                width: "100%",
                                                            }}
                                                            id="outlined-basic"
                                                            label="Phone"
                                                            variant="outlined"
                                                            name="phone_no"
                                                            onChange={handleChange}
                                                            value={formData?.phone_no}
                                                        />
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid sx={{ marginTop: "50px" }}>
                                        <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                            Language selection
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "400", mt: 2 }}>
                                            Select your preferred language for using Test-N-Hire and receive communications in that language.
                                        </Typography>
                                    </Grid>
                                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                        <ThemeProvider theme={customTheme(outerTheme)}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={language}
                                                getOptionLabel={(option) => option.name ?? "select language"}
                                                value={language?.find((role) => role.id === formData?.lang_id) || null}
                                                onChange={(e, value) => {
                                                    setFormDataValues("lang_id", value?.id);
                                                }}
                                                sx={{
                                                    ml: 1,
                                                    width: "100%",
                                                    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                        borderRadius: "4px",
                                                        transition: "green 0.3s ease-in-out",
                                                        "&:hover": {
                                                            borderColor: "var(--text-color-Green)",
                                                        },
                                                    },
                                                    "& #combo-box-demo": {
                                                        color: "var(--text-color-black)",
                                                    },
                                                }}
                                                renderInput={(params) => <TextField name="lang_id" {...params} label="Language of assessment" />}
                                            />
                                        </ThemeProvider>
                                    </Box>
                                    <Grid container sx={{ marginTop: "40px" }}>
                                        <Grid item xs={12} md={8}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                    alignItems: "center",
                                                    backgroundColor: "none",
                                                }}
                                            >
                                                <Stack className="ButtonText" spacing={2} direction="row">
                                                    <Button
                                                        onClick={() => {
                                                            setChangePasswordModalOpen(true);
                                                        }}
                                                        sx={{
                                                            bgcolor: "var(--button-bg-color-transparent)",
                                                            borderRadius: "4px",
                                                            color: "var(--text-color-black)",
                                                            boxShadow: "unset",
                                                            border: "none",
                                                            padding: "15px 20px",
                                                            backgroundColor: "#ededed",
                                                            "&:hover": {
                                                                bgcolor: "#e0e0e0",
                                                                boxShadow: "none",
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                textTransform: "none",
                                                                fontSize: !isMobile ? "14px" : "10px",
                                                                display: "contents",
                                                                color: "var(--text-color-black)",
                                                            }}
                                                            variant="body1"
                                                        >
                                                            <VpnKeyOutlinedIcon
                                                                sx={{
                                                                    color: "var(--text-color-black)",
                                                                    height: "19px",
                                                                    width: "19px",
                                                                    mr: 1,
                                                                }}
                                                            ></VpnKeyOutlinedIcon>
                                                            Change Password
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setChangeEmailModalOpen(true);
                                                        }}
                                                        sx={{
                                                            bgcolor: "var(--button-bg-color-transparent)",
                                                            color: "var(--text-color-black)",
                                                            boxShadow: "unset",
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            padding: "15px 20px",
                                                            backgroundColor: "#ededed",
                                                            "&:hover": {
                                                                bgcolor: "#e0e0e0",
                                                                boxShadow: "none",
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                textTransform: "none",
                                                                fontSize: "14px",
                                                                display: "contents",
                                                                color: "var(--text-color-black)",
                                                            }}
                                                            variant="body1"
                                                        >
                                                            {
                                                                <MailOutlineOutlinedIcon
                                                                    sx={{
                                                                        color: "var(--text-color-black)",
                                                                        height: "19px",
                                                                        width: "19px",
                                                                        mr: 1,
                                                                    }}
                                                                ></MailOutlineOutlinedIcon>
                                                            }
                                                            Change Email
                                                        </Typography>
                                                    </Button>

                                                    {changePasswordModalOpen ? (
                                                        <ChangePasswordModal
                                                            setAlertNotification={setAlertNotification}
                                                            open={changePasswordModalOpen}
                                                            setOpen={setChangePasswordModalOpen}
                                                            customTheme={customTheme}
                                                            outerTheme={outerTheme}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {changeEmailModalOpen ? (
                                                        <ChangeEmailModal
                                                            open={changeEmailModalOpen}
                                                            setOpen={setChangeEmailModalOpen}
                                                            customTheme={customTheme}
                                                            outerTheme={outerTheme}
                                                            ThemeProvider={ThemeProvider}
                                                            user={user}
                                                            getUserDetails={getUserDetails}
                                                            setAlertNotification={setAlertNotification}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Stack>
                                            </Item>
                                        </Grid>
                                        <Grid sx={{display:"flex", justifyContent: !isMobile ? "end" : "start", alignItems:"center"}} item xs={12} md={4}>
                                            <Item
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: !isMobile ? "end" : "start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Stack spacing={2} direction="row">
                                                    <Button
                                                        // style={btnStyle}
                                                        sx={{
                                                            textTransform: "none",
                                                            background: "var(--button-bg-color-green)",
                                                            color: "var(--text-color-black)",
                                                            boxShadow: "unset",
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            padding: isLoading ?"25px 81.5px": "15px 30px", 
                                                            "&:hover": {
                                                                background: "var(--button-hover-bg-color-LightGreen)",
                                                                boxShadow: "unset",
                                                                borderRadius: "4px",
                                                            border: "none",
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        onClick={handelSubmitMyProfile}
                                                        disabled={isLoading} // Disable the button when loading
                                                    >
                                                        {
                                                            isLoading ? ( 
                                                                <CircularProgress size={24} style={{ position: 'absolute', color:"var(--text-color-OffWhite)" }} /> 
                                                            ) : (
                                                                <Typography
                                                                    fontWeight={600}
                                                                    sx={{
                                                                        fontSize: "var(--btn-fontsize)",
                                                                        color: "var(--text-color-OffWhite)",
                                                                    }}
                                                                >
                                                                    Save Changes
                                                                </Typography>
                                                            )
                                                        }
                                                    </Button>
                                                </Stack>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
                            </Container>
                        </CustomTabPanel>
                        <AllowedAccess permissions="my_company">
                            <CustomTabPanel style={{height:"570px"}} value={value} index={1}>
                                <MyCompany customTheme={customTheme} language={language} />
                            </CustomTabPanel>
                        </AllowedAccess>

                        <CustomTabPanel value={value} index={2}>
                            <TeamManagement />
                        </CustomTabPanel>

                        <CustomTabPanel style={{height:"550px"}} value={value} index={3} sx={{ paddingX: 0 }}>
                            <Billing customTheme={customTheme} outerTheme={outerTheme} />
                        </CustomTabPanel>
                    </Box>
                </Container>
                {/* <Divider sx={{ my: 5, borderColor: "#f5f6f6", borderWidth: "0.5px" }} /> */}
            </Box>
        </Box>
    );
}
export default Profile;
