import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../Layouts/Index";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Container, Grid, Typography, Snackbar, Select } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { MaterialReactTable } from "material-react-table";
import axios from "axios";
import {
	assessmentGetData,
	getHeaders,
	renameAssessmentName,
	cloneAssessment,
	updateArchive,
	getArchive,
	getInactive,
} from "../../../../Constants/ConstantsUrl";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { AllowedAccess } from "react-permission-role";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Alerts from "../../../Common/Alerts";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import PageNotFound from "../../403/AccessDenided";
import TableRowsLoaderCopy from "../../TableRowsLoaderCopy";
import FormControl from "@mui/material/FormControl";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const customTheme = (outerTheme) =>
	createTheme({
		palette: {
			mode: outerTheme.palette.mode,
		},
		components: {
			MuiTextField: {
				styleOverrides: {
					root: {
						"--TextField-brandBorderColor": "#0000001f",
						"--TextField-brandBorderHoverColor": "var(--border-color-Green)",
						"--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
						"& label.Mui-focused": {
							color: "var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: "var(--TextField-brandBorderColor)",
					},
					root: {
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--TextField-brandBorderHoverColor)",
							transition: "0.5s all",
						},
						".MuiOutlinedInput-notchedOutline": {
							transition: "0.5s all",
						},
						[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: "var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						"&::before, &::after": {
							borderBottom: "2px solid var(--TextField-brandBorderColor)",
						},
						"&:hover:not(.Mui-disabled, .Mui-error):before": {
							borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
						},
						"&.Mui-focused:after": {
							borderBottom:
								"2px solid var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiInput: {
				styleOverrides: {
					root: {
						"&::before": {
							borderBottom: "2px solid var(--TextField-brandBorderColor)",
						},
						"&:hover:not(.Mui-disabled, .Mui-error):before": {
							borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
						},
						"&.Mui-focused:after": {
							borderBottom:
								"2px solid var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
		},
	});

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#c8d7de",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#c8d7de",
	},
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
}));
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	border: "none",
	boxShadow: 24,
	p: 4,
	borderRadius: "8px",
};

function Index() {
	const isMobile = useMediaQuery("(max-width:899px)");
	const isLaptop = useMediaQuery("(max-width:1330px)");
	const BtnPosition = isMobile ? "start" : "end";
	const SelectPosition = isMobile ? "center" : "end";
	const outerTheme = useTheme();
	const navigate = useNavigate();
	const [asses_id, setAssesId] = useState();
	const [asses_Name, setAssesName] = useState();
	const [selectedOption, setSelectedOption] = useState(1);
	const [data, setData] = useState([]);
	const [dataCopy, setDataCopy] = useState([]);
	const [load, setLoad] = useState(true);
	const [isRenameAssessmentModalOpen, setisRenameAssessmentModalOpen] =
		useState(false);
	const [notificationOpen, setNotificationOpen] = useState(false);
	const [searchAssessKey, setSearchAssessKey] = useState("");
	const location = useLocation();
	const [activeArchiveCount, setActiveArchiveCount] = useState({
		active_assessment_count: 0,
		inactive_assessment_count: 0,
		archive_assessment_count: 0
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perpage, setPerPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [totalItem, setTotalItem] = useState(0);

	useEffect(() => {
		if (location.state && location.state.key === "value") {
			setNotificationOpen(true);
			navigate("/customer/assessment", { ...location.state, key: null });
		}

		if (location.state && location.state.key === "clone") {
			setAlertNotification({
				status: true,
				type: "success",
				message: "Assesment clone successfully",
			});
		}
	}, []);

	const handleChange = async (value) => {
		setData([]);
		setSelectedOption(value);
		if (selectedOption === value) {
			fetchData();
		}
	};

	const searchMyAssessmentFun = (e) => {
		setSearchAssessKey(e.target.value);
		const eventValue = e.target.value;
		if (eventValue === "") {
			setData(dataCopy);
			return false;
		} else {
			const filteredArray = dataCopy.filter((item) =>
				item.name_of_assessment.toLowerCase().includes(eventValue.toLowerCase())
			);
			setData(filteredArray);
		}
	};

	useEffect(() => {
		fetchData();
	}, [navigate, currentPage, limit, selectedOption]);

	const fetchData = async () => {
		try {
			setLoad(true);
			const headers = getHeaders();
			let response;
			if (selectedOption === 0) {
				response = await axios.get(getArchive + "?limit=" + limit + "&paginate=true&page=" + currentPage, {
					headers,
				});
			} else if (selectedOption === 2) {
				response = await axios.get(getInactive + "?limit=" + limit + "&paginate=true&page=" + currentPage, {
					headers,
				});
			} else {
				response = await axios.get(assessmentGetData + "?limit=" + limit + "&paginate=true&page=" + currentPage, {
					headers,
				});
			}
			const res = response.data;
			if (res.status === 200) {
				setData(res.data.transformed_assessment);
				setDataCopy(res.data.transformed_assessment);
				setTotalItem(res.data.pagination.total);
				setTotalPages(res.data.pagination.last_page);
				setPerPage(limit);
				setActiveArchiveCount({
					active_assessment_count: res.data.active_assessment_count,
					inactive_assessment_count: res.data.inactive_assessment_count,
					archive_assessment_count: res.data.archive_assessment_count
				});
			}
		} catch (error) {
			if (error.response?.status === 401) {
				localStorage.removeItem("user");
				localStorage.removeItem("accessToken");
				navigate("/login");
			}
		} finally {
			setLoad(false);
		}
	};

	const handleEditClick = (data) => {
		navigate("/customer/create-assessment?id=" + data);
		// Implement your edit logic here, such as opening a modal or navigating to an edit page.
	};


	const handleInviteClick = (data, step = "invite") => {
		if(step === "edit") {
			navigate("/customer/create-assessment?id=" + data);
		} else {			
			navigate("/customer/invite?id=" + data);
		}
	};

	const handleInviteClickNew = (data) => {
		localStorage.setItem("invitedByInviteCall", 1);
		navigate("/customer/invite?id=" + data, { state: { 'inviteCall': true } });
	};




	const addMembertoggleModal = () => {
		setisRenameAssessmentModalOpen(!isRenameAssessmentModalOpen);
	};

	const renameAssessment = (data) => {
		setAssesId(data.id);
		setAssesName(data.name_of_assessment);
		setisRenameAssessmentModalOpen(!isRenameAssessmentModalOpen);
	};

	const AddMemberModal = ({ oldData, setOldData }) => {
		const [errorMsg, setErrorMsg] = useState(false);
		const [renameAss, setRenameasses] = useState(asses_Name);
		const [errorMessage, setErrorMessage] = useState(null);
		const [popupLoader, setPopupLoader] = useState(false);
		const characterCount = renameAss.length;
		const handleInputChange = (event) => {
			const value = event.target.value;
			if (value.length <= 256) {
				setErrorMessage(null);
				setRenameasses(event.target.value);
			} else {
				setErrorMessage("Title should be less than 256 characters");
			}
		};

		const updateAssessmentName = (assessmentsArray, idToUpdate, newName) => {
			return assessmentsArray.map((assessment) =>
				assessment.id === idToUpdate
					? { ...assessment, name_of_assessment: newName }
					: assessment
			);
		};

		const handleSubmitadd = async () => {
			try {
				if (renameAss === null || renameAss === "") {
					setErrorMsg(true);
					return false;
				} else {
					setPopupLoader(true);
					const data = {
						assessment_id: asses_id,
						name_of_assessment: renameAss,
					};
					const headers = getHeaders();
					await axios
						.post(renameAssessmentName, data, { headers })
						.then((response) => response)
						.then((response) => {
							if (response.status === 200) {
								setAssesId("");
								const updatedAssessments = updateAssessmentName(
									oldData,
									asses_id,
									renameAss
								);
								setOldData(updatedAssessments);
								setisRenameAssessmentModalOpen(!isRenameAssessmentModalOpen);
								setPopupLoader(false);
							}
						});
				}
			} catch (error) {
				console.log(error);
			}
		};

		return (

			<>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={isRenameAssessmentModalOpen}
					onClose={addMembertoggleModal}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
					className="RemoveOutLine"
					sx={{ backdropFilter: "blur(4px)" }}
				>
					<Fade in={isRenameAssessmentModalOpen}>
						<Box sx={style}>
							<Typography
								id="transition-modal-title"
								variant="h6"
								component="h2"
								sx={{ fontSize: "22px", fontWeight: 700 }}
							>
								Rename assessment
							</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									sx={{ mt: 2 }}
									fullWidth
									label="Assessment name"
									id="fullWidth"
									name="Name"
									value={renameAss}
									error={errorMsg ? true : false}
									helperText={
										errorMsg
											? errorMsg === true
												? "Name is required"
												: errorMsg
											: null
									}
									onChange={(e) => handleInputChange(e)}
								/>
								<Grid container spacing={0} columns={16} sx={{ pl: "30px" }}>
									<Grid xs={8}>{errorMessage && <span className="error_msg_style">{errorMessage}</span>}</Grid>
									<Grid xs={8}>
										<Typography
											sx={{
												mt: 1,
												fontSize: 16,
												color: "gray",
												display: "flex",
												justifyContent: "end",
											}}
										>
											{characterCount}/256
										</Typography>
									</Grid>
								</Grid>
							</ThemeProvider>
							<Grid
								xs={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "end",
									mt: 3,
									gap: "10px",
								}}
							>
								<Button
									sx={{
										color: "var(--text-color-black)",
										bgcolor: "#ededed",
										minWidth: "48px",
										minHeight: "48px",
										textTransform: "none",
										boxShadow: "none",
										p: "10px 20px",
										"&:hover": {
											bgcolor: "#e0e0e0",
											boxShadow: "none",
										},
									}}
									onClick={addMembertoggleModal}
								>
									<Typography
										style={{
											fontSize: "16px",
											color: "var(--text-color-black)",
											textTransform: "capitalize",
										}}
										variant="h6"
										fontWeight={600}
									>
										Cancel
									</Typography>
								</Button>
								<Button
									sx={{
										bgcolor: (errorMessage !== null) ? "#a7a2a2b5" : "var(--text-color-Green)",
										minWidth: "48px",
										minHeight: "48px",
										textTransform: "capitalize",
										p: "10px 20px",
										"&:hover": {
											bgcolor: "var(--button-hover-bg-color-LightGreen)",
										},
									}}
									disabled={errorMessage !== null}
									onClick={handleSubmitadd}
								>
									<Typography
										style={{
											fontSize: "16px",
											color: "var(--text-color-OffWhite)",
											textTransform: "capitalize",
										}}
										variant="h6"
										fontWeight={600}
									>
										{popupLoader ? "Saving..." : "Save"}
									</Typography>
								</Button>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			</>
		);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: "name_of_assessment",
				header: "Name",
				enableColumnActions: false,
				accessorFn: (row) => {
					return (
						<BootstrapTooltip
							variant="contained"
							title={row.name_of_assessment}
						>
							<Typography>
								{row.name_of_assessment.length > 20 ? row.name_of_assessment.substring(0, 20) + ("...") : row.name_of_assessment}
							</Typography>
						</BootstrapTooltip>
					)
				}
			},
			{
				accessorKey: "candidate_assessment_status",
				header: "CANDIDATES",
				enableColumnActions: false,
				accessorFn: (row) =>
					row.candidate_assessment_status?.Invited +
					row.candidate_assessment_status?.Started +
					row.candidate_assessment_status?.Completed +
					row.candidate_assessment_status?.Disqualified,
			},
			{
				accessorKey: "progress",
				header: "PROGRESS",
				enableColumnActions: false,
				accessorFn: (row) => {
					let count = 0;
					if (row.candidate_assessment_status?.Completed > 0) {
						count += row.candidate_assessment_status?.Completed;
					}
					if (row.candidate_assessment_status?.Started > 0) {
						count += row.candidate_assessment_status?.Started;
					}
					if (row.candidate_assessment_status?.Invited > 0) {
						count += row.candidate_assessment_status?.Invited;
					}
					if (row.candidate_assessment_status?.Disqualified > 0) {
						count += row.candidate_assessment_status?.Disqualified;
					}
					return (
						<div style={{ display: "flex", }}>
							{row.candidate_assessment_status?.Completed > 0 && (
								<BootstrapTooltip
									variant="contained"
									title="Completed"
									style={{
										width: `${(100 * row.candidate_assessment_status?.Completed) / count}%`,
										color: "var(--text-color-OffWhite)",
										backgroundColor: "var(--button-bg-color-green)",
										textAlign: "center",
									}}
								>
									{row.candidate_assessment_status?.Completed}
								</BootstrapTooltip>
							)}
							{row.candidate_assessment_status?.Started > 0 && (
								<BootstrapTooltip
									variant="contained"
									title="Started"
									style={{
										width: `${(100 * row.candidate_assessment_status?.Started) / count}%`,
										color: "var(--text-color-black)",
										backgroundColor: "rgb(203 215 203)",
										textAlign: "center",
									}}
								>
									{row.candidate_assessment_status?.Started}
								</BootstrapTooltip>
							)}
							{row.candidate_assessment_status?.Invited > 0 && (
								<BootstrapTooltip
									variant="contained"
									title="Invited"
									style={{
										width: `${(100 * row.candidate_assessment_status?.Invited) / count}%`,
										color: "var(--text-color-black)",
										backgroundColor: "rgb(237 237 237)",
										textAlign: "center",
									}}
								>
									{row.candidate_assessment_status?.Invited}
								</BootstrapTooltip>
							)}
							{row.candidate_assessment_status?.Disqualified > 0 && (
								<BootstrapTooltip
									variant="contained"
									title="Disqualified"
									style={{
										width: `${(100 * row.candidate_assessment_status?.Disqualified) / count}%`,
										color: "var(--text-color-black)",
										backgroundColor: "rgb(239 183 170 / 85%)",
										textAlign: "center",
									}}
								>
									{row.candidate_assessment_status?.Disqualified}
								</BootstrapTooltip>
							)}
						</div>
					);
				},
			},
			{
				accessorKey: "created_at",
				header: "DATE CREATED",
				enableColumnActions: false,

			},
			{
				accessorKey: "updated_at",
				header: "LAST ACTIVITY",
				enableColumnActions: false,
				accessorFn: (row) => row.updated_at === null ? "-" : row.updated_at
			},
			{
				accessorKey: "deadline",
				header: "DEADLINE",
				enableColumnActions: false,
				accessorFn: (row) => row.deadline === null ? "-" : row.deadline
			},
		],
		[]
	);

	const handleCloneCall = async (id) => {
		try {
			const headers = getHeaders();
			const data = { assessment_id: id };
			await axios
				.post(cloneAssessment, data, { headers })
				.then((response) => response)
				.then((response) => {
					if (response.status === 200) {
						setAlertNotification({
							status: true,
							type: "success",
							message: "Assesment clone successfully",
						});
						fetchData();
					}
				});
		} catch (error) {
			console.log(error);
		}
	};

	const updateArchiveFun = async (ass_id) => {
		try {
			const headers = getHeaders();
			const data = {
				assessment_id: ass_id,
			};
			await axios
				.post(updateArchive, data, { headers })
				.then((response) => response)
				.then((response) => {
					if (response.status === 200) {
						fetchData();
					}
					setAlertNotification({
						status: true,
						type: "success",
						message: "Success",
					});
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleNotificationClose = () => {
		setNotificationOpen(false);
	};

	const [alertNotification, setAlertNotification] = useState({
		status: false,
		type: "error",
		message: "Success!!",
	});

	const [activeButton, setActiveButton] = useState("active");

	const handleButtonClick = (buttonName) => {
		setData([]);
		setActiveButton(buttonName);
	};

	const handleLimitChange = (e) => {
		setLimit(e.target.value);
	};
	const handlePrevPage = () => {
		setCurrentPage((prevPage) => prevPage - 1);
	};

	const handleNextPage = () => {
		setCurrentPage((prevPage) => prevPage + 1);
	};
	return (
		<Box>
			<HelmetProvider>
				<div>
					<Helmet>
						<title>Assessment List - TestnHire</title>
					</Helmet>
				</div>
			</HelmetProvider>
			<Navbar />
			{isRenameAssessmentModalOpen && (
				<AddMemberModal
					onAddRequestClose={addMembertoggleModal}
					oldData={data}
					setOldData={setData}
				/>
			)}
			<Box
				sx={{
					bgcolor: "#F2F2F2",
					paddingBottom: !isMobile ? "18%" : "60%",
					marginTop: isMobile ? "50px" : isLaptop ? "65px" : "85px",
				}}
			>
				<Container maxWidth="xl">
					<Box sx={{ flexGrow: 1, mb: !isMobile ? "40px" : "20px", pt: 5 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Item
									style={{
										boxShadow: "unset",
										borderRadius: "none",
										background: "none",
										textAlign: !isMobile ? "" : "start",
									}}
								>
									<Typography
										variant="h5"
										sx={{ fontSize: "28px", fontWeight: 700 }}
									>
										My assessment
									</Typography>
								</Item>
							</Grid>
							<Grid item xs={12} md={6}>
								<Item
									style={{
										boxShadow: "unset",
										borderRadius: "none",
										background: "none",
										display: "flex",
										justifyContent: BtnPosition,
										alignItems: "center",
									}}
								>
									<AllowedAccess permissions="add_my_assessment">
										<Link
											to="/customer/create-assessment"
											style={{ color: "#fff", textDecoration: "none" }}
										>
											<Button
												sx={{
													bgcolor: "var(--button-bg-color-green)",
													p: 2,
													width: "auto",
													borderRadius: "4px",
													boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
													"&:hover": {
														bgcolor: "var(--button-hover-bg-color-LightGreen)",
														boxShadow:
															"0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
													},
												}}
												variant="contained"
											>
												<Typography
													sx={{
														fontWeight: 600,
														fontSize: "14px",
														textTransform: "none",
														display: "flex",
														alignItems: "center",
														color: "var(--text-color-OffWhite)",
													}}
													variant="body1"
												>
													{
														<AddCircleOutlineIcon
															sx={{
																color: "var(--text-color-OffWhite)",
																mr: 1,
																fontSize: "30px",
															}}
														/>
													}
													Start a New Assessment
												</Typography>
											</Button>
										</Link>
									</AllowedAccess>
								</Item>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ flexGrow: 1, mb: 5 }}>
						<Grid container spacing={0}>
							<Grid item xs={12} md={6}>
								<Item
									style={{
										boxShadow: "unset",
										borderRadius: "none",
										background: "none",
										padding: "0px",
									}}
								>
									<Box>
										<ThemeProvider theme={customTheme(outerTheme)}>
											<TextField
												sx={{
													bgcolor: "white",
													borderRadius: "4px",
													width: !isMobile ? "75%" : "100%",
												}}
												label="Search assessment"
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															sx={{ display: isMobile ? "none" : "" }}
														>
															<SearchIcon
																style={{
																	color: "rgba(0, 0, 0, 0.54)",
																}}
															/>
														</InputAdornment>
													),
												}}
												value={searchAssessKey}
												onChange={(e) => searchMyAssessmentFun(e)}
											/>
										</ThemeProvider>
									</Box>
								</Item>
							</Grid>

							<Grid item xs={12} md={6}>
								<Grid
									style={{
										boxShadow: "unset",
										borderRadius: "none",
										background: "none",
										display: "flex",
										justifyContent: SelectPosition,
										padding: "0px",
										marginLeft: isMobile?"": "29px",
										marginTop: isMobile?"29px": "",
									}}
								>
									<Button
										onClick={() => {
											handleButtonClick("active");
											handleChange(1);
										}}
										sx={{
											color:
												activeButton === "active"
													? "var(--text-color-OffWhite)"
													: "var(--text-color-black)",
											backgroundColor:
												activeButton === "active" ? "var(--button-hover-bg-color-LightGreen)" : "white",
											fontWeight: activeButton === "active" ? "700" : "500",
											boxShadow: "unset",
											border: "1px solid #e0e0e0",
											borderRadius: "5px 0px 0px 5px",
											padding: "10px 30px",
											textTransform: "capitalize",
											fontSize: "18px",
											fontFamily: "var(--open-sans-font)",
											// transition: " 0.5s ease-in-out",
											"&:hover": {
												color:
													activeButton === "active"
														? "var(--text-color-OffWhite)"
														: "var(--text-color-black)",
												backgroundColor:
													activeButton === "active" ? "var(--button-hover-bg-color-LightGreen)" : "#F2F2F2",
												border: "1px solid #e0e0e0",
												boxShadow: "none",
											},
										}}
										variant="contained"
									>
										Active ({activeArchiveCount.active_assessment_count})
									</Button>
									<Button
										onClick={() => {
											handleButtonClick("inactive");
											handleChange(2);
										}}
										sx={{
											color:
												activeButton === "inactive"
													? "var(--text-color-OffWhite)"
													: "var(--text-color-black)",
											backgroundColor:
												activeButton === "inactive" ? "var(--button-hover-bg-color-LightGreen)" : "white",
											fontWeight: activeButton === "inactive" ? "700" : "500",
											boxShadow: "unset",
											border: "1px solid #e0e0e0",
											borderRadius: "5px 0px 0px 5px",
											padding: "10px 30px",
											textTransform: "capitalize",
											fontSize: "18px",
											fontFamily: "var(--open-sans-font)",
											// transition: " 0.5s ease-in-out",
											"&:hover": {
												color:
													activeButton === "inactive"
														? "var(--text-color-OffWhite)"
														: "var(--text-color-black)",
												backgroundColor:
													activeButton === "inactive" ? "var(--button-hover-bg-color-LightGreen)" : "#F2F2F2",
												border: "1px solid #e0e0e0",
												boxShadow: "none",
											},
										}}
										variant="contained"
									>
										Inactive ({activeArchiveCount.inactive_assessment_count})
									</Button>
									<Button
										onClick={() => {
											handleButtonClick("archive");
											handleChange(0);
										}}
										sx={{
											color:
												activeButton === "archive"
													? "var(--text-color-OffWhite)"
													: "var(--text-color-black)",
											backgroundColor:
												activeButton === "archive" ? "var(--button-hover-bg-color-LightGreen)" : "white",
											fontWeight: activeButton === "archive" ? "700" : "500",
											boxShadow: "unset",
											border: "1px solid #e0e0e0",
											borderRadius: "0px 5px 5px 0px",
											padding: "10px 30px",
											textTransform: "capitalize",
											fontSize: "18px",
											fontFamily: "var(--open-sans-font)",
											// transition: " 0.5s ease-in-out",
											"&:hover": {
												color:
													activeButton === "archive"
														? "var(--text-color-OffWhite)"
														: "var(--text-color-black)",
												backgroundColor:
													activeButton === "archive" ? "var(--button-hover-bg-color-LightGreen)" : "#F2F2F2",
												border: "1px solid #e0e0e0",
												boxShadow: "none",
											},
										}}
										variant="contained"
									>
										Archive ({activeArchiveCount.archive_assessment_count})
									</Button>

								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box
						className="MaterialReactTableStyle"
						style={{
							boxShadow:
								"0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
							borderRadius: "8px",
							overflow: "hidden",
						}}
					>
						{load ? (
							<TableRowsLoaderCopy rowsNum={5} columnNum={7} headNames={[
								"Name",
								"CANDIDATES",
								"PROGRESS",
								"DATE CREATED",
								"LAST ACTIVITY",
								"Deadline",
								"Actions",
							]} />
						) : (
							<>
								<MaterialReactTable
									columns={columns}
									enablePagination={false}
									enableBottomToolbar={false}
									filter={false}
									enableTopToolbar={false}
									data={data ? data : []}
									options={{ actionsColumnIndex: 1 }}
									enableRowActions
									muiTableBodyRowProps={({ row }) => ({
										//add onClick to row to select upon clicking anywhere in the row
										onClick: () => handleInviteClick(row.original.id, row.original.step),
										sx: { cursor: "pointer" },
									})}
									onRowSelectionChange="0"
									positionActionsColumn={"last"}
									renderRowActionMenuItems={({ row }) => [
										<AllowedAccess permissions="edit_my_assessment">
											{/* edit link show up condition if active step then edit mode not visiable*/}
											{activeButton !== "active" &&
												<MenuItem key={`edit-${row.original.id}`} onClick={() => handleEditClick(row.original.id)}>
													<ModeEditOutlineOutlinedIcon sx={{ mr: 2 }} /> Edit
												</MenuItem>
											}
										</AllowedAccess>,
										<AllowedAccess permissions="edit_my_assessment">
											<MenuItem key={`rename-${row.original.id}`} onClick={() => renameAssessment(row.original)}>
												<EditNoteIcon sx={{ mr: 2 }} /> Rename
											</MenuItem>
										</AllowedAccess>,
										<AllowedAccess permissions="add_my_assessment">
											<MenuItem key={`clone-${row.original.id}`} onClick={() => handleCloneCall(row.original.id)}>
												<FileCopyOutlinedIcon sx={{ mr: 2 }} /> Clone
											</MenuItem>
										</AllowedAccess>,
										<AllowedAccess permissions="manage_access_my_assessment">
											<MenuItem key={`manage-access-${row.original.id}`} onClick={() => handleInviteClick(row.original.id)}>
												<ManageAccountsOutlinedIcon sx={{ mr: 2 }} /> Manage access
											</MenuItem>
										</AllowedAccess>,
										<AllowedAccess permissions="delete_my_assessment">
											{row.original.status !== 2 &&
												<MenuItem key={`archive-${row.original.id}`} onClick={() => updateArchiveFun(row.original.id)}>
													{row.original.status === 1 ? <ArchiveOutlinedIcon sx={{ mr: 2 }} /> : <UnarchiveOutlinedIcon sx={{ mr: 2 }} />}
													{row.original.status === 1 ? "Archive" : "Unarchive"}
												</MenuItem>
											}
										</AllowedAccess>,
										<AllowedAccess permissions="set_deadline_my_assessment">
											<MenuItem key={`set-deadline-${row.original.id}`} onClick={() => handleInviteClick(row.original.id)}>
												<CalendarMonthOutlinedIcon sx={{ mr: 2 }} /> Set Deadline
											</MenuItem>
										</AllowedAccess>,
										<AllowedAccess permissions="invite_my_assessment">
											<MenuItem key={`invite-${row.original.id}`} onClick={() => handleInviteClickNew(row.original.id)}>
												<EmailOutlinedIcon sx={{ mr: 2 }} />  Invite
											</MenuItem>
										</AllowedAccess>,
									]}
								/>
								<Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "end", alignItems: "center", background: "white" }}>
									<Typography>Rows Per Page</Typography>
									<FormControl sx={{ m: 1 }}>
										<Select value={limit} onChange={handleLimitChange} sx={{ height: "30px" }}>
											<MenuItem value={5}>5</MenuItem>
											<MenuItem value={10}>10</MenuItem>
											<MenuItem value={20}>20</MenuItem>
											<MenuItem value={30}>30</MenuItem>
											<MenuItem value={50}>50</MenuItem>
											<MenuItem value={100}>100</MenuItem>
										</Select>
									</FormControl>

									<Typography>
										{totalItem > 0 ? currentPage * perpage - perpage + 1 + "-" : ""}
										{currentPage * perpage > totalItem ? totalItem : currentPage * perpage} of {totalItem}
									</Typography>
									<Grid sx={{ gap: "0px" }}>
										<Button onClick={handlePrevPage} disabled={currentPage === 1}>
											<ArrowBackIosNewIcon sx={{ color: currentPage !== 1 ? "var(--border-color-Green)" : "" }} />
										</Button>
										<Button onClick={handleNextPage} disabled={currentPage === totalPages}>
											<ArrowForwardIosIcon sx={{ color: currentPage !== totalPages ? "var(--border-color-Green)" : "" }} />
										</Button>
									</Grid>
								</Grid>
							</>
						)}
					</Box>
				</Container>
			</Box>
			{load && <PageNotFound loading={load} />}
			<Snackbar
				open={notificationOpen}
				autoHideDuration={6000}
				onClose={handleNotificationClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleNotificationClose}
					severity="success"
					sx={{
						width: "500px",
						justifyContent: "center",
						backgroundColor: "#cbd7cb",
						color: "var(--text-color-black)",
					}}
				>
					Assessment is created successfully!
				</Alert>
			</Snackbar>
			{alertNotification?.status ? (
				<Alerts
					notification={alertNotification}
					setNotification={setAlertNotification}
				/>
			) : (
				""
			)}
		</Box>
	);
}

export default Index;
