import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation, useParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "../../../../../Assests/Images/LOGO.png"
import TimerPage from "./TimerPage";
import { APIProvider } from "../../../../Common/ApiProvider";
import { getPreviewQuestionUrl } from "../../../../../Constants/ConstantsUrl";
import BackdropLoader from "../../../../Common/BackdropLoader";
import Loader from "../../../Loader";


const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    background: "unset",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

export default function PreviewQuestions() {
    const handleCloseTab = () => {
        window.close();
    };

    const location = useLocation();
    const { official_question_id } = useParams();

    const [time, setTimer] = useState(10);
    const searchParams = new URLSearchParams(location.search);
    const companyColorCode =
        searchParams.get("companyColorCode") === "1"
            ? "var(--button-bg-color-green)"
            : searchParams.get("companyColorCode") ||
            "var(--button-bg-color-green)";
    const companyName = searchParams.get("companyName");
    const [selectedValue, setSelectedValue] = useState("");
    const [questionIndex, setQuestionIndex] = useState(0);
    const [progressQuestion, setProgressQuestion] = useState(0);
    const [pauseTime, setPauseTime] = useState(false);
    const [getNewTimer, setNewTimer] = useState(0);
    const startQuestionTime = useRef(null);
    const [loading, setLoading] = useState(true);

    const formatTime = (timeInSeconds) => {
        let minutes = Math.floor(timeInSeconds / 60);
        let seconds = Math.floor(timeInSeconds % 60);

        if (isNaN(minutes)) {
            minutes = 0;
        }

        if (isNaN(seconds)) {
            seconds = 0;
        }

        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const getQuestions = async () => {
        try {
            await APIProvider(getPreviewQuestionUrl, {
                official_question_id: official_question_id
            }, "post").then((response) => {
                if (response?.status == 200) {
                    setTimer(response?.data?.time);
                    setQuestions(response?.data?.questions);
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getQuestions();
    }, []);

    const defaultQuestions = [
        {
            id: 1,
            question:
                "The nation’s mobile home parks constitute a highly fragmented market, where there is less efficient institutional participation.",
            options: [
                "This is not an opportune time to invest in mobile home parks.",
                "Now is the right time to think of investing in mobile home parks.",
                "Gradually mobile home parks are concentrating to city centers.",
                "Nowadays, most investors are up-to-date on mobile home parks’ potential.",
            ],
        },
        {
            id: 2,
            question:
                "Which of the following statements is true about mobile home communities?",
            options: [
                "They are located in dense city centers.",
                "They are an under-researched and misunderstood asset class.",
                "Investors have modern ideas about the quality of modern mobile homes.",
                "They are becoming less popular among investors.",
            ],
        },
        {
            id: 3,
            question:
                "What is the main opportunity in the nation’s mobile home parks market?",
            options: [
                "Efficient institutional participation.",
                "Fragmentation of the market.",
                "Low investor interest.",
                "Lack of availability.",
            ],
        },
        {
            id: 4,
            question:
                "What is a challenge mentioned in the text regarding mobile home parks?",
            options: [
                "Geographical dispersion.",
                "High institutional participation.",
                "Dense city center locations.",
                "Misunderstanding among investors.",
            ],
        },
        {
            id: 5,
            question:
                "Which investment strategy is mentioned in the text regarding mobile home parks?",
            options: [
                "Avoiding mobile home park investments.",
                "Staying ahead of growing popularity.",
                "Following outdated investment ideas.",
                "Investing only in city center locations.",
            ],
        },
    ];

    const [questions, setQuestions] = useState(defaultQuestions);

    const handleNext = () => {
        setQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedValue("");
        setProgressQuestion(((questionIndex + 2) / questions.length) * 100);
    };

    const handleTimeOut = async () => {
        handleCloseTab();
    }

    const handleChange = (event) => {
        if (event.target.value === selectedValue) {
            setSelectedValue("");
        } else {
            setSelectedValue(event.target.value);
        }
    };

    const getContrastText = (background) => {
        const hexColor = background.replace("#", "");
        const r = parseInt(hexColor.substr(0, 2), 16);
        const g = parseInt(hexColor.substr(2, 2), 16);
        const b = parseInt(hexColor.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness >= 128 ? "black" : "white";
    };

    const BpIcon = styled("span")(({ theme }) => ({
        marginRight: "10px",
        border: "2px solid #ededed",
        borderRadius: "50%",
        width: 30,
        height: 30,
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        ".Mui-focusVisible &": {
            outline: "2px auto red",
            outlineOffset: 2,
        },
        "& .Mui-checked": {
            border: "2px solid red",
        },
        "input:hover ~ &": {
            border: `2px solid ${companyColorCode}`,
            transition: " 0.5s all ",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background:
                theme.palette.mode === "dark"
                    ? "rgba(57,75,89,.5)"
                    : "rgba(206,217,224,.5)",
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: { companyColorCode },
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&::before": {
            display: "block",
            width: 16,
            height: 16,
            // backgroundImage: `radial-gradient(#fff,#fff 28%,transparent 32%)`,
            backgroundImage: `radial-gradient(${getContrastText(
                companyColorCode
            )},${getContrastText(companyColorCode)} 28%,transparent 32%)`,
            content: '""',
            marginTop: "5px",
            marginLeft: "5px",
        },

        "input:hover ~ &": {
            backgroundColor: companyColorCode,
            // border:"2px solid red",
        },

        "input:checked ~ &": {
            borderColor: getContrastText(companyColorCode), // Change border color when checked
        },
    });

    function BpRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            {loading && <Loader />}
            {!loading && <>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Grid>
                        <img
                            style={{ padding: "20px" }}
                            src={Logo}
                            alt="Company logo"
                        />
                    </Grid>
                    <TimerPage
                        setNewTimer={setNewTimer}
                        pauseTime={pauseTime}
                        formatTime={formatTime}
                        attemptQuestion={questionIndex + 1}
                        progressQuestion={progressQuestion}
                        questionTime={time * 60}
                        questionsLength={questions?.length}
                        handleNext={handleNext}
                        handleTimeOut={handleTimeOut}
                    />

                    <Grid sx={{ padding: "20px" }}>
                        <Button
                            onClick={questionIndex < questions.length - 1 ? handleNext : handleCloseTab}
                            sx={{
                                ...btnStyle,
                                bgcolor: "var(--button-bg-color-assessment)",
                                minWidth: "48px",
                                minHeight: "48px",
                                textTransform: "capitalize",
                                padding: "0px 30px",
                                "&:hover": {
                                    bgcolor:
                                        "var(--button-hover-bg-color-LightGreen)",
                                },
                            }}
                            variant="contained"
                        >
                            {questionIndex < questions.length - 1 ? 'Next' : 'Submit'}
                            <ChevronRightIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        height: "90vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        style={{
                            background: "white",
                            borderRadius: "4px",
                            boxShadow:
                                "-3 6px 7px -4px #8888880f,0 11px 15px 1px #8888880b,0 4px 20px 3px #88888809",
                            padding: "0px",
                        }}
                    >
                        <Box sx={{ flexGrow: 1, p: 2 }}>
                            <Grid container spacing={0}>
                                <Grid sx={{ p: 5 }} xs={12} md={6}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: "16px",
                                                fontStyle: "italic",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Read the following paragraphs and choose
                                            the right answer.
                                        </Typography>
                                        <br />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            {questions[questionIndex].question}
                                        </Typography>
                                    </Item>
                                </Grid>
                                <Grid sx={{ p: 5 }} xs={12} md={6}>
                                    <Item>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: 500,
                                                textTransform: "uppercase",
                                                mb: "20px",
                                            }}
                                        >
                                            Select only one
                                        </Typography>

                                        <FormControl sx={{
                                            width: "100%"
                                        }}>
                                            <RadioGroup
                                                defaultValue="female"
                                                aria-labelledby="demo-customized-radios"
                                                name="customized-radios"
                                                value={selectedValue}
                                                onClick={handleChange}
                                                className="checkBoxBorder"
                                            >
                                                {questions[
                                                    questionIndex
                                                ].options.map((option, index) => (
                                                    <FormControlLabel
                                                        sx={{
                                                            boxShadow:
                                                                "0 2px 8px 1px #a8a8a833",
                                                            borderRadius: "6px",
                                                            padding: "18px",
                                                            margin: "0px 0px 32px 0px",
                                                            cursor: "pointer",
                                                            border: "2px solid #ededed",
                                                            transition:
                                                                " 0.5s ease-in-out",
                                                            backgroundColor:
                                                                selectedValue ===
                                                                    option
                                                                    ? companyColorCode
                                                                    : "white",
                                                            color:
                                                                selectedValue ===
                                                                    option
                                                                    ? getContrastText(
                                                                        companyColorCode
                                                                    )
                                                                    : "var(--text-color-black)",
                                                            "&:hover": {
                                                                border: `2px solid ${companyColorCode}`,
                                                                transition:
                                                                    " 0.5s ease-in-out",
                                                            },
                                                        }}
                                                        key={index}
                                                        value={option}
                                                        control={<BpRadio />}
                                                        label={
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontSize:
                                                                        "16px",
                                                                    fontWeight: 400,
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: option,
                                                                }}
                                                            >                                                                 
                                                            </Typography>
                                                        }
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </>}
        </Box>
    );
}
