import { Box, Button, Container, Divider, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Navbar from "../../../../Layouts/Index";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import StarIcon from "@mui/icons-material/Star";
import CandidateDetailsTable from "./CustomDetailsTable";
import QualifyingDetailsTable from "./QualifyingDetailsTable";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputBase from "@mui/material/InputBase";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VideoStream from "./VideoStream";
import VideoPopup from "./VideoPlayer/VideoPopup";
import TableForm from "../CandidateTable/TableForm";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AntiCheatingMoniter from '../../../../../Common/AntiCheatingMoniter';
import {
    AssessmentGetForInvite,
    CandidateUpdateNote,
    CandidateUpdatePrivateRating,
    CandidateUpdateRating,
    condidateInvite,
    getCandidateReport,
    getHeaders,
    getHiringStages,
    setCondidateHireStage,
    candidateResultPDFDownload,
} from "../../../../../../Constants/ConstantsUrl";
import axios from "axios";
import Loader from "../../../../../Common/Loader";
import Alerts from "../../../../../Common/Alerts";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import testnhire from "./../../../../../../Assests/img/testnhire.png";
import star3 from "./../../../../../../Assests/img/star-3.png";
import link1 from "./../../../../../../Assests/img/link-1.png";
import checkMark from "./../../../../../../Assests/img/check-mark.png";
import cancel from "./../../../../../../Assests/img/cancel.png";
import clock from "./../../../../../../Assests/img/clock.png";
import camRecord from "./../../../../../../Assests/img/cam-recorder.png";
import star2 from "./../../../../../../Assests/img/star-2.png";
import star1 from "./../../../../../../Assests/img/star-1.png";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
dayjs.extend(advancedFormat);

const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `00:${minutes.toString().padStart(2, "0")}:${Math.round(seconds).toString().padStart(2, "0")}`;
};

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "var(--border-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "2px solid #efefef",
        fontSize: 16,
        padding: "16.5px 0px 16.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 4,
            borderColor: "var(--border-color-Green)",
            border: "2px solid var(--border-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--border-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        // minWidth: "245px",
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#c8d7de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#c8d7de",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

const btnStyle = {
    color: "var(--text-color-OffWhite)",
    boxShadow: "unset",
    borderRadius: "8px",
};

function Index() {
    const outerTheme = useTheme();
    const location = useLocation();
    const idDatas = location.state;
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const navigate = useNavigate();
    const isLaptop = useMediaQuery("(max-width:1199px)");
    const isMobile = useMediaQuery("(max-width:960px)");
    const isSmMobile = useMediaQuery("(max-width:899px)");
    const isExtraSmMobile = useMediaQuery("(max-width:599px)");
    const BtnPosition = isSmMobile ? "start" : "end";
    const FormWidth = isMobile ? "80%" : "40%";
    const MiddleBoxPadding = isMobile ? "0px" : "27px";
    const contentDisplay = isMobile ? "" : "flex";
    const MobileMargin = isMobile ? "50px" : "";
    const SmMobileMargin = isExtraSmMobile ? "50px" : "";
    const [selectedOption, setSelectedOption] = useState("");
    const [data, setData] = useState([]);
    const [hiringStages, setHiringStages] = useState(null);
    const [load, setLoad] = useState(false);
    const [customQuestionList, setCustomQuestionList] = useState([]);
    const [qualifyingQuestionList, setQualifyingList] = useState([]);
    const [videoPath, setVideoPath] = useState();
    const [screenVideoPath, setScreenVideoPath] = useState();
    const [notData, setNodata] = useState(false);
    const [videoPathMarker, setVideoPathMarker] = useState();
    const [screenVideoPathMarker, setScreenVideoPathMarker] = useState();
    const [screenRecordTime, setScreenRecordTime] = useState(0);
    const [videoRecordTime, setVideoRecordTime] = useState(0);
    const [alertNotification, setAlertNotification] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [assessmentDisable, setAssessmentDisable] = useState(true);
    const [subMetaStarLoader, setSubMetaStarLoader] = React.useState(false);
    const handleChange = (event) => {
        setAssessmentDisable(false);
        setSelectedOption(event.target.value);
    };
    const [rating, setRating] = useState(0);
    const [OverallStar, setOverAllRating] = useState(0);
    const [overallPer, setOverallPer] = useState(0);
    const [subMetaStarIndex, setSubMetaStarIndex] = React.useState(null);
    const [customQuestionRating, setCustomQuestionRating] = useState([]);
    const [isEmailSending, setIsEmailSending] = React.useState(false);
    const [downloadingReport, setDownloadingReport] = React.useState(false);
    const [rejectCandidate, setRejectCandidate] = React.useState(false);
    const [currentHiringStage, setCurrentHiringStage] = React.useState(false);
    const [candidateDisqualified, setCandidateDisqualified] = useState(false);
    const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
    const [currentMarkers, setCurrentMarkers] = useState(null);
    const [currentPlayerTime, setCurrentPlayerTime] = useState(null);

    const handleStarClick = async (selectedRating, type, index, result_metas_id, result_sub_meta_id, result_id, assessment_id) => {
        const headers = getHeaders();
        if (type === "yourRating") {
            setRating(selectedRating);
            try {
                const postdata = {
                    result_id: result_id ? result_id : data?.assessment?.results?.result_id,
                    candidate_id: data?.candidate_details?.candidate_id,
                    assessment_id: idDatas.assessmentId,
                    rating: selectedRating,
                };
                await axios.post(CandidateUpdatePrivateRating, postdata, { headers });
            } catch (error) {
                console.log(error);
            }
        } else if (type === "custom") {
            //setSubMetaStarLoader(true);
            setSubMetaStarIndex(index);
            customQuestionList[index]["result"]["rating"] = selectedRating;
            try {
                const postdata = {
                    result_metas_id: result_metas_id,
                    result_sub_meta_id: result_sub_meta_id,
                    result_id: result_id,
                    rating: selectedRating,
                };
                await axios.post(CandidateUpdateRating, postdata, { headers });
                setSubMetaStarLoader(false);
                setSubMetaStarIndex(null);
            } catch (error) {
                console.log(error);
                setSubMetaStarLoader(false);
            }
        }
        // await axios.post(CandidateUpdateRating , allData)
    };

    const handleSubmit = async (event) => {
        const headers = getHeaders();
        setPrivateNote(event.target.value);
        try {
            const allData = {
                assessment_id: idDatas.assessmentId,
                candidate_id: data?.candidate_details?.candidate_id,
                invitation_id: data?.assessment?.id,
                private_note: event.target.value,
            };
            await axios.post(CandidateUpdateNote, allData, { headers });
        } catch (error) {
            console.log(error);
        }
    };
    const [steps_nu, setSteps_nu] = useState(page);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [expanded, setExpanded] = useState(null);
    const [openAntiCheating, setOpenAntiCheating] = React.useState(false);
    const handleOpenAntiCheating = () => setOpenAntiCheating(true);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const [changeInvitionId, setInvitionId] = useState();
    const [private_note, setPrivateNote] = useState("");
    const handelRedirect = (total, stepss, operator) => {
        const assessmentId = idDatas.assessmentId;
        let steps = stepss;
        let call_status = false;
        if (operator === "+" && total - stepss > 0) {
            steps = parseInt(steps) + parseInt(1);
            call_status = true;
        } else if (operator === "-" && stepss > 1) {
            steps = parseInt(steps) - parseInt(1);
            call_status = true;
        }
        if (call_status) {
            setSteps_nu(steps);
            setInvitionId(steps);
            setRating(0);
            setCustomQuestionRating([]);
            setIsEmailSending(false);
            setDownloadingReport(false);
            setRejectCandidate(false);
            setCurrentHiringStage(false);
            setCandidateDisqualified(false); //resetting all states
            navigate("/customer/candidate-details?page=" + steps, { state: { assessmentId, page: "invite" } });
        }
    };
    const handleDummyPlayerClick = (currentUrl, currentMarkers, currentTime) => {
        setCurrentVideoUrl(currentUrl);
        setCurrentMarkers(currentMarkers);
        setCurrentPlayerTime(currentTime);
        setIsVideoPopupOpen(true);
    }
    const handleVideoPopupClose = () => {
        setIsVideoPopupOpen(false);
    }
    useEffect(() => {
        fetch();
    }, [changeInvitionId]);

    useEffect(() => {
        getHiringStagesFun();
        fetch();
        getAssessmentList();
    }, []);

    const getAssessmentList = async () => {
        setLoad(true);
        const headers = getHeaders();

        try {
            await axios
                .get(AssessmentGetForInvite + idDatas.id, { headers })
                .then((resposne) => resposne)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setAssessmentList(Object.values(res.data));
                    }
                    setLoad(false);
                });
        } catch (error) {
            setLoad(false);
            console.log("Error - ", error);
        }
    };

    const fetch = async () => {
        setLoad(true);
        const headers = getHeaders();
        const sendData = {
            assessment_id: idDatas.assessmentId,
        };
        try {
            await axios
                .post(getCandidateReport + "?page=" + steps_nu, sendData, { headers })
                .then((resposne) => resposne)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        if (!res.data.result_status) {
                            setNodata(true);
                            setData(res.data);
                            setVideoPath("");
                            setScreenVideoPath("");
                            setQualifyingList(Object.values(res?.data?.assessment?.qualifying_question));

                            if (res?.data?.assessment?.status === 4) { //candidate disqualify when status is 4
                                setCandidateDisqualified(true);
                            }
                        } else {
                            setNodata(false);
                            let screenTime = 0;
                            let VideoTime = 0;
                            const cheatingLogs = JSON.parse(res?.data?.assessment?.results?.cheat_log);
                            setData(res.data);

                            setPrivateNote(res?.data?.assessment?.results?.private_note);
                            setOverAllRating(res?.data?.assessment?.results?.overall_avg ?? 0);
                            setRating((res?.data?.assessment?.results) === null ? 0 : res?.data?.assessment?.results?.rating);
                            setOverallPer(res?.data?.assessment?.results?.overall_per);
                            setVideoPath(res?.data?.assessment?.results?.video_rec_path);
                            setScreenVideoPath(res?.data?.assessment?.results?.screen_rec_path);
                            setCustomQuestionList(Object.values(res?.data?.assessment?.custom_question));
                            setQualifyingList(Object.values(res?.data?.assessment?.qualifying_question));
                            setVideoPathMarker(
                                cheatingLogs
                                    .filter((marker) => marker.errorType.includes("faces detected"))
                                    .map((marker) => ({
                                        id: 1,
                                        time: marker.timestamp,
                                        color: "red",
                                        title: marker.errorType,
                                    }))
                            );
                            let returnData = cheatingLogs
                                ?.filter((marker) => marker.errorType.includes("Cursor") || marker.errorType.includes("Mouse"))
                                .map((marker) => ({
                                    id: 1,
                                    time: marker.timestamp,
                                    color: "var(--button-bg-color-green)",
                                    title: marker.errorType,
                                }));
                            let returnData2 = cheatingLogs
                                ?.filter((marker) => marker.errorType.includes("Tab"))
                                .map((marker) => ({
                                    id: 1,
                                    time: marker.timestamp,
                                    color: "red",
                                    title: marker.errorType,
                                }));
                            setScreenVideoPathMarker([...returnData, ...returnData2]);
                            cheatingLogs.filter((marker) => marker.errorType.includes("screenEnd")).map((marker) => (screenTime = marker.timestamp));
                            cheatingLogs.filter((marker) => marker.errorType.includes("videoEnd")).map((marker) => (VideoTime = marker.timestamp));
                            setScreenRecordTime(screenTime);
                            setVideoRecordTime(VideoTime);
                        }
                        setCurrentHiringStage(res?.data?.assessment.hiring_stage);
                    }
                    setLoad(false);
                });
        } catch (error) {
            setLoad(false);
            console.log("Error - ", error);
        }
    };

    const getHiringStagesFun = async () => {
        setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(getHiringStages, { headers })
                .then((response) => response)
                .then((response) => {
                    const res_data = response.data;
                    if (res_data.status === 200) {
                        setHiringStages(res_data.data);
                    }
                });
        } catch (error) {
            console.log(error);
            setLoad(false);
        }
    };

    const handleChangeHireStage = async (e) => {
        setCurrentHiringStage(e.target.value);
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: idDatas.id,
                assessment_id: idDatas.assessmentId,
                hiring_stage_id: e.target.value,
            };
            data.hiring_stage = e.target.value;
            await axios.post(setCondidateHireStage, formsData, { headers }).then((response) => response);
        } catch (error) {
            console.log(error);
        }
    };

    const clickBackButton = () => {
        navigate("/customer/invite?id=" + idDatas.assessmentId);
    };

    const handelClickReject = async () => {
        setRejectCandidate(true);
        let hiringStageData = data.assessment.hiring_stage;
        let newHiringStage =
            hiringStageData === 12
                ? {
                    newHiringStageId: 1,
                    newHiringStageMessage: "Not Yet Evaluated",
                }
                : {
                    newHiringStageId: 12,
                    newHiringStageMessage: "Rejected",
                };
        setCurrentHiringStage(newHiringStage.newHiringStageId);
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: idDatas.id,
                assessment_id: idDatas.assessmentId,
                hiring_stage_id: newHiringStage.newHiringStageId,
            };
            await axios
                .post(setCondidateHireStage, formsData, { headers })
                .then((response) => response)
                .then((response) => {
                    data.assessment.hiring_stage = newHiringStage.newHiringStageId;
                    setAlertNotification({
                        status: true,
                        type: "success",
                        message: newHiringStage.newHiringStageMessage,
                    });
                });
            setRejectCandidate(false);
        } catch (error) {
            console.log(error);
            setRejectCandidate(false);
        }
    };

    const handelCopyCandidateLink = (email) => {
        let link = email;
        navigator.clipboard.writeText(link);
        setAlertNotification({
            status: true,
            type: "success",
            message: "Email copied!",
        });
    };

    const handelInvite = async () => {
        setOpenLoader(true);
        const dataSended = {
            user_details: [data.candidate_details],
            assessment_id: selectedOption,
        };
        try {
            const headers = getHeaders();
            await axios
                .post(condidateInvite, dataSended, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setAssessmentList((prev) => Object.values(prev).filter((value) => value.id !== selectedOption));
                        setSelectedOption("");
                    }
                });
            setOpenLoader(false);
        } catch (error) {
            console.log(error);
            setOpenLoader(false);
        }
    };

    const downloadReport = async () => {
        setDownloadingReport(true);
        try {
            const sendData = {
                assessment_id: data.assessment.assessment_details.assessment_id,
                candidate_id: data.candidate_details.candidate_id,
                send_mail: 0,
            };
            const headers = getHeaders();
            await axios
                .post(candidateResultPDFDownload, sendData, {
                    headers,
                    responseType: "blob", // Important
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "file.pdf");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error("Error downloading PDF file: ", error);
                });
            setDownloadingReport(false);
        } catch (error) {
            console.log(error);
            setDownloadingReport(false);
        }
    };

    const mailCandidateReport = async () => {
        setIsEmailSending(true);
        let response; // Declare response outside try-catch
        try {
            const sendData = {
                assessment_id: data.assessment.assessment_details.assessment_id,
                candidate_id: data.candidate_details.candidate_id,
                send_mail: 1,
            };
            const headers = getHeaders();
            response = await axios.post(candidateResultPDFDownload, sendData, { headers });
            // Handle success inside try block if you want immediate feedback
            setAlertNotification({
                status: true,
                type: "success",
                message: response.data.data.message, // Presuming your API returns a message on success
            });
        } catch (error) {
            console.error("Error mailing PDF file:", error);
            // Handle error notification here
            setAlertNotification({
                status: true,
                type: "error",
                message: error.response?.data?.message || "Failed to send the email.",
            });
        } finally {
            setIsEmailSending(false); // Stop loading regardless of the outcome
        }
    };

    return (
        <Box>
            <Navbar />
            {load || openLoader ? (
                <Loader />
            ) : (
                <>
                    {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
                    <VideoPopup isVideoPopupOpen={isVideoPopupOpen} videoUrl={currentVideoUrl} markersPoint={currentMarkers} time={currentPlayerTime} handleVideoPopupClose={handleVideoPopupClose} />
                    <Box sx={{ borderBottom: "1px solid #ddd", marginTop: "90px" }}>
                        <Container maxWidth="xl">
                            <Box sx={{ flexGrow: 1, py: 2 }}>
                                <Grid container spacing={0}>
                                    <Grid item sx={{ display: "flex" }} xs={10} md={4} lg={4}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                            }}
                                        >
                                            {" "}
                                            <Button
                                                sx={{
                                                    p: 2,
                                                    bgcolor: "#ededed",
                                                    boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    borderRadius: "4px",
                                                    "&:hover": {
                                                        bgcolor: "#e0e0e0",
                                                        boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                    },
                                                }}
                                                variant="contained"
                                                onClick={clickBackButton}
                                            >
                                                {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}
                                            </Button>
                                        </Item>

                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 700 }}>
                                                {data.candidate_details
                                                    ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                                    " " +
                                                    (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                                    : ""}
                                            </Typography>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handelCopyCandidateLink(data?.candidate_details?.email)}
                                                >
                                                    {data.candidate_details ? data?.candidate_details?.email : ""}
                                                </Typography>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ display: isSmMobile ? "none" : " ", }} xs={12} lg={6} md={5}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: isSmMobile ? "none" : "flex",
                                                justifyContent: BtnPosition,
                                            }}
                                        >
                                            {" "}
                                            <Select
                                                value={selectedOption}
                                                onChange={handleChange}
                                                displayEmpty
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                input={<BootstrapInput />}
                                                IconComponent={ExpandMoreIcon}
                                                inputProps={{
                                                    "aria-label": "Select option",
                                                }}
                                                sx={{
                                                    width: FormWidth,
                                                    bgcolor: "white",
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Invite for assessment
                                                </MenuItem>
                                                {assessmentList.length > 0 && assessmentList.map((value, index) => <MenuItem key={index} sx={{ maxWidth: "362px" }} value={value.id}>{value.name_of_assessment.length > 20 ? value.name_of_assessment.substring(0, 20) + ("...") : value.name_of_assessment}</MenuItem>)}
                                            </Select>
                                        </Item>
                                    </Grid>
                                    <Grid xs={2} md={3} lg={2}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                                justifyContent: isSmMobile ? "end" : "end",
                                            }}
                                        >
                                            <Button
                                                disabled={assessmentDisable}
                                                sx={{
                                                    ...btnStyle,
                                                    bgcolor: "var(--button-bg-color-green)",
                                                    padding: "15px 30px",
                                                    display: isSmMobile ? "none" : " ",
                                                    textTransform: "capitalize",
                                                    borderRadius: "5px",
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                    },
                                                }}
                                                onClick={handelInvite}
                                                variant="contained"
                                            >
                                                <Typography variant="btn" fontWeight={600} sx={{ fontSize: "14px" }}>
                                                    Invite
                                                </Typography>
                                            </Button>
                                            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                <KeyboardArrowLeftIcon
                                                    sx={{
                                                        height: "27px",
                                                        color: steps_nu > 1 ? "#000" : "#ebebeb",
                                                        width: "32px",
                                                        cursor: steps_nu > 1 ? "pointer" : "",
                                                    }}
                                                    onClick={() => handelRedirect(data.pagination?.total, steps_nu, "-")}
                                                />
                                                <Grid sx={{ p: "13px", background: "#ebebeb", borderRadius: "50%", height: "32px", display: "flex", alignItems: "center", mx: 1 }}>
                                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                                        {steps_nu}/{data.pagination?.total}
                                                    </Typography>
                                                </Grid>
                                                <ChevronRightIcon
                                                    sx={{
                                                        height: "27px",
                                                        color: data.pagination?.total - steps_nu > 0 ? "#000" : "#ebebeb",
                                                        width: "32px",
                                                        cursor: data.pagination?.total - steps_nu > 0 ? "pointer" : "",
                                                    }}
                                                    onClick={() => handelRedirect(data.pagination?.total, steps_nu, "+")}
                                                />
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                    <Box sx={{ bgcolor: "#F5F6F6", py: 5 }}>
                        <Container maxWidth="xl">
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <Item
                                            sx={{
                                                bgcolor: "white",
                                                boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <Box sx={{ px: isSmMobile ? " " : "24px", pt: 3, flexGrow: 1 }}>
                                                <Grid container spacing={0}>
                                                    <Grid xs={6} sm={8} md={8}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "unset",
                                                                background: "white",
                                                            }}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    display: contentDisplay,
                                                                    gap: "20px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "20px",
                                                                        fontWeight: 700,
                                                                        display: isExtraSmMobile ? "none" : " "
                                                                    }}
                                                                >
                                                                    Assessment
                                                                </Typography>
                                                                <BootstrapTooltip
                                                                    variant="contained"
                                                                    title={data?.assessment?.assessment_details?.assessment_name}
                                                                >
                                                                    <Typography
                                                                        variant="h3"
                                                                        sx={{
                                                                            fontSize: "20px",
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {data?.assessment?.assessment_details
                                                                            ? data?.assessment?.assessment_details?.assessment_name.length > 50 ? `${data?.assessment?.assessment_details?.assessment_name.substring(0, 50)}...` : data?.assessment?.assessment_details?.assessment_name +
                                                                                " - " +
                                                                                data?.assessment?.assessment_details?.work_arrangement +
                                                                                " - " +
                                                                                data?.assessment?.assessment_details?.job_role
                                                                            : ""}
                                                                    </Typography>
                                                                </BootstrapTooltip>
                                                            </Grid>
                                                            <Grid sx={{ display: "flex", mt: 2 }}>
                                                                {[1, 2, 3, 4, 5].map((star) => (
                                                                    <StarIcon
                                                                        key={star}
                                                                        sx={{
                                                                            color: star <= rating ? "#faaf00" : "#d2d2d2",
                                                                            fontSize: "30px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                ))}
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                    <Grid sx={{ margin: "auto" }} xs={6} sm={4} md={4}>
                                                        {!notData ? (
                                                            <Item
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: isExtraSmMobile ? "end" : "end",
                                                                    boxShadow: "unset",
                                                                    background: "white",
                                                                    alignItems: "center",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                {data?.assessment?.results ? (
                                                                    downloadingReport ? (
                                                                        <Grid
                                                                            sx={{
                                                                                bgcolor: "#f2f2f2",
                                                                                p: "10px",
                                                                                display: "flex",
                                                                                borderRadius: "5px",
                                                                            }}
                                                                        >
                                                                            <CircularProgress sx={{ color: "#494949" }} size={30} />
                                                                        </Grid>
                                                                    ) : (
                                                                        <BootstrapTooltip title="Download results" onClick={downloadReport}>
                                                                            <CloudDownloadOutlinedIcon
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    padding: "10px",
                                                                                    fontSize: "50px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </BootstrapTooltip>
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {data?.assessment?.results ? (
                                                                    <BootstrapTooltip title="Send results to candidate" onClick={mailCandidateReport}>
                                                                        {isEmailSending ? (
                                                                            <Grid
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    p: "10px",
                                                                                    display: "flex",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <CircularProgress sx={{ color: "#494949" }} size={30} />
                                                                            </Grid>
                                                                        ) : (
                                                                            <EmailOutlinedIcon
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    padding: "10px",
                                                                                    fontSize: "50px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </BootstrapTooltip>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {data?.assessment?.results ? (
                                                                    <BootstrapTooltip title={currentHiringStage !== 1 ? "Reject" : "Rejected"} onClick={() => handelClickReject()}>
                                                                        {rejectCandidate ? (
                                                                            <Grid
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    p: "10px",
                                                                                    display: "flex",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <CircularProgress sx={{ color: "#494949" }} size={30} />
                                                                            </Grid>
                                                                        ) : (
                                                                            <PersonOffOutlinedIcon
                                                                                sx={{
                                                                                    bgcolor: currentHiringStage === 12 ? "var(--reject-back-color)" : "#f2f2f2",
                                                                                    color: currentHiringStage === 12 ? "var(--text-color-OffWhite)" : "#000",
                                                                                    padding: "10px",
                                                                                    fontSize: "50px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </BootstrapTooltip>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Item>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>










                                            {
                                                isLaptop ?
                                                    <>
                                                        <Box sx={{ px: isMobile ? "0px" : "24px", flexGrow: 1 }}>
                                                            <Grid container spacing={0} sx={{ mt: 5 }}>
                                                                <Grid xs={12} sm={4} md={4} lg={2}>
                                                                    <Item sx={{ boxShadow: "unset" }}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            Invited
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {dayjs(data?.assessment?.invited_on).format("MMMM Do, YYYY")}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Completed
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {!notData ? (data?.assessment?.completed === null ? "N/A" : dayjs(data?.assessment?.completed).format("MMMM Do, YYYY")) : "N/A"}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Extra time breakdown
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {data?.assessment?.results?.candidate_extra_obj?.is_status ? (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyleType: "disc",
                                                                                        paddingLeft: "20px",
                                                                                        lineHeight: 1.5,
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.candidate_extra_obj?.is_disabled > 0 && <li>Granted to all candidates +{data?.assessment?.results?.candidate_extra_obj?.is_disabled + "%"}</li>}
                                                                                    {data?.assessment?.results?.candidate_extra_obj?.is_fluent_in_lang > 0 && <li>Not fluent in English + {data?.assessment?.results?.candidate_extra_obj?.is_fluent_in_lang + "%"}</li>}
                                                                                </ul>
                                                                            ) : (
                                                                                "No extra time was granted to this candidate"
                                                                            )}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Source
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {data?.assessment?.source ?? "N/A"}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                                mb: 3,
                                                                            }}
                                                                        >
                                                                            Hiring stage
                                                                        </Typography>
                                                                        <Grid className="TableFormStyle">
                                                                            <TableForm
                                                                                hiringStages={hiringStages}
                                                                                handleChangeHireStage={handleChangeHireStage}
                                                                                selectedStage={currentHiringStage}
                                                                                item={{ id: "" }}
                                                                                index={0}
                                                                            />
                                                                        </Grid>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid sx={{ mt: SmMobileMargin, px: 2 }} xs={12} sm={8} md={8} lg={4}>
                                                                    <Item sx={{ boxShadow: "unset", p: 0 }}>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    Anti-cheating monitor
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <HelpOutlineOutlinedIcon onClick={handleOpenAntiCheating} sx={{ cursor: "pointer" }} />
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 4,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Device used
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            (data?.assessment?.results?.anti_cheating_monitor?.platform !== undefined)
                                                                                                ? ""
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.platform !== undefined ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.platform ? "2px 12px" : "2px 10px",
                                                                                        color: (data?.assessment?.results?.anti_cheating_monitor?.platform === undefined) ? "var(--text-color-OffWhite)" : "",
                                                                                        borderRadius: "5px",
                                                                                        textAlign: "end",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.platform ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Location
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>

                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            (data?.assessment?.results?.location !== undefined)
                                                                                                ? ""
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.location !== undefined ? "2px 10px" : data?.assessment?.results?.location ? "2px 12px" : "2px 10px",
                                                                                        color: (data?.assessment?.results?.location === undefined) ? "var(--text-color-OffWhite)" : "",
                                                                                        borderRadius: "5px",
                                                                                        textAlign: "end",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.location ?? (data?.assessment?.completed === null ? "N/A" : "Not Found")}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Filled out only once from IP address?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Webcam enabled?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "var(--button-bg-color-green)" : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>


                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Full-screen mode always active?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Mouse always in assessment window?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <CameraComponent videoPath={videoPath} is_snapshot_enable={data?.assessment?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable} videoPathMarker={videoPathMarker} videoRecordTime={videoRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                                                                        <ScreenShotComponent screenVideoPath={screenVideoPath} is_screenshot_enable={data?.assessment?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable} screenVideoPathMarker={screenVideoPathMarker} screenRecordTime={screenRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                                                                    </Item>
                                                                </Grid>
                                                                {!notData ? (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                px: MiddleBoxPadding,
                                                                                mt: isLaptop ? "40px" : " ",
                                                                            }}
                                                                            xs={12}
                                                                            sm={12} md={12}
                                                                            lg={6}
                                                                        >
                                                                            <Item
                                                                                sx={{
                                                                                    borderRadius: "8px",
                                                                                    boxShadow: "0 8px 24px 4px #00000014",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                <Box sx={{ flexGrow: 1 }}>
                                                                                    <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                // mt: 2,
                                                                                            }}
                                                                                            xs={8}
                                                                                            md={8}
                                                                                        >
                                                                                            <Box
                                                                                                sx={{
                                                                                                    width: "100%",
                                                                                                    maxWidth: "700px"
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        m: 3,
                                                                                                    }}
                                                                                                />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        mb: 5,
                                                                                                    }}
                                                                                                >
                                                                                                    {data.candidate_details
                                                                                                        ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                                                                                        " " +
                                                                                                        (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                                                                                        : ""}
                                                                                                </Typography>
                                                                                                <Box
                                                                                                    className="tooltip123"
                                                                                                    sx={{
                                                                                                        height: "15px",
                                                                                                        borderRadius: "18px",
                                                                                                        // border: "1px solid #000",
                                                                                                        background: `linear-gradient(to right, var(--button-bg-color-green) ${overallPer}%, #cfcfcf 0%)`,
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography
                                                                                                        className="tooltiptext"
                                                                                                        sx={{
                                                                                                            left: `${overallPer}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText321">{overallPer}</span>
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        className="tooltiptext1234 additionalClass"
                                                                                                        sx={{
                                                                                                            left: `${data?.assessment?.results?.best_candidate_score}%`,
                                                                                                            "& .tooltiptext123": {
                                                                                                                zIndex: 9,
                                                                                                            },
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText">{data?.assessment?.results?.best_candidate_score}</span>
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        className="tooltiptext123"
                                                                                                        sx={{
                                                                                                            left: `${data?.assessment?.results?.avg_candidate_score}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText123">{data?.assessment?.results?.avg_candidate_score}</span>
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                                alignItems: "end",
                                                                                                mt: 5,
                                                                                                textAlign: "end"
                                                                                            }}
                                                                                            xs={4}
                                                                                            md={4}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="h1"
                                                                                                sx={{
                                                                                                    fontSize: "44px",
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {overallPer}%
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "13px",
                                                                                                    fontWeight: 500,
                                                                                                }}
                                                                                            >
                                                                                                AVERAGE SCORE
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Grid
                                                                                    sx={{
                                                                                        px: 0,
                                                                                        mb: 3,
                                                                                        mt: 8,
                                                                                        display: "flex",
                                                                                        gap: "20px",
                                                                                        alignItems: "Center",
                                                                                        flexWrap: "nowrap"
                                                                                    }}
                                                                                    container
                                                                                    spacing={0}
                                                                                >
                                                                                    <Grid>
                                                                                        <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", my: 1,fontWeight:500 }}>
                                                                                            {" "}
                                                                                            This assessment:
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid sx={{ display: "flex", my: 1, alignItems: "center" }}>
                                                                                        {/* <Typography
                                                                                sx={{
                                                                                    mr: "5px",
                                                                                    p: "7px",
                                                                                    borderRadius: "50%",
                                                                                    border: "1px solid var(--text-color-black)",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                            </Typography> */}
                                                                                        <FiberManualRecordOutlinedIcon sx={{ color: "#276678" }} />
                                                                                        <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", }}>
                                                                                            {" "}
                                                                                            Your candidate pool average
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid sx={{ display: "flex", my: 1, alignItems: "center" }}>
                                                                                        {/* <Typography
                                                                                sx={{
                                                                                    mr: "5px",
                                                                                    p: "7px",
                                                                                    borderRadius: "50%",
                                                                                    border: "1px solid var(--text-color-black)",
                                                                                    backgroundColor: "#276678",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                            </Typography> */}
                                                                                        <FiberManualRecordIcon sx={{ color: "#276678" }} />
                                                                                        <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px" }}>
                                                                                            Your best candidate score
                                                                                        </Typography>{" "}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {/* <Grid sx={{ mt: 2, px: 3 }}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontWeight: 400,
                                                                                            fontSize: "14px",
                                                                                            color: "var(--button-bg-color-green)",
                                                                                            cursor: "pointer",
                                                                                            display: "contents",
                                                                                            "&:hover": {
                                                                                                textDecoration: "underline",
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        Learn more
                                                                                    </Typography>{" "}
                                                                                </Grid> */}
                                                                            </Item>

                                                                            <Item
                                                                                sx={{
                                                                                    p: 3,
                                                                                    bgcolor: "#E9F0F1",
                                                                                    borderRadius: "16px",
                                                                                    boxShadow: "none",
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <Grid container spacing={0}>
                                                                                    <Grid xs={12} md={6}>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                                fontWeight: 400,
                                                                                                color: "var(--text-color-black)",
                                                                                                padding: "2px 6px",
                                                                                                borderRadius: "0 5px 5px 0",
                                                                                            }}
                                                                                        >
                                                                                            Scoring method
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "16px",
                                                                                                fontWeight: 700,
                                                                                                color: "var(--text-color-black)",
                                                                                                padding: "2px 6px",
                                                                                                borderRadius: "0 5px 5px 0",
                                                                                            }}
                                                                                        >
                                                                                            Percentage of correct answers
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    {/* <Grid xs={12} md={6}>
                                                                                        <Item
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "end",
                                                                                                boxShadow:
                                                                                                    "none",
                                                                                                background:
                                                                                                    "none",
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={
                                                                                                    btnStyle1
                                                                                                }
                                                                                                sx={{
                                                                                                    bgcolor:
                                                                                                        "var(--button-bg-color-transparent)",
                                                                                                    boxShadow:
                                                                                                        "none",
                                                                                                    borderRadius:
                                                                                                        "4px",
                                                                                                    "&:hover": {
                                                                                                        bgcolor:
                                                                                                            "var(--button-bg-color-transparent)",
                                                                                                        boxShadow:
                                                                                                            "none",
                                                                                                    },
                                                                                                }}
                                                                                                variant="contained"
                                                                                            >
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        fontWeight: 400,
                                                                                                        textTransform:
                                                                                                            "none",
                                                                                                        fontSize:
                                                                                                            "14px",
                                                                                                        display:
                                                                                                            "contents",
                                                                                                        color: "var(--text-color-black)",
                                                                                                    }}
                                                                                                    variant="body1"
                                                                                                >
                                                                                                    {
                                                                                                        <EditOutlinedIcon
                                                                                                            sx={{
                                                                                                                color: "var(--text-color-black)",
                                                                                                                height: "19px",
                                                                                                                width: "19px",
                                                                                                                mr: 1,
                                                                                                            }}
                                                                                                        ></EditOutlinedIcon>
                                                                                                    }
                                                                                                    Change
                                                                                                </Typography>
                                                                                            </Button>
                                                                                        </Item>
                                                                                    </Grid> */}
                                                                                </Grid>

                                                                                <Grid sx={{ mt: 5 }}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 700,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                            textTransform: "uppercase",
                                                                                            mb: 2
                                                                                        }}
                                                                                    >
                                                                                        Official Question
                                                                                    </Typography>
                                                                                    {data?.assessment?.official_question?.map((value, index) => (
                                                                                        <Accordion
                                                                                            className="accordianBorder"
                                                                                            key={index}
                                                                                            sx={{
                                                                                                borderRadius: "8px",
                                                                                                boxShadow: "none",
                                                                                                mt: 2,
                                                                                                "&.css-1nstih9-MuiPaper-root-MuiAccordion-root::before": {
                                                                                                    position: "relative",
                                                                                                },
                                                                                            }}
                                                                                            expanded={expanded === index}
                                                                                            onChange={handleAccordionChange(index)}
                                                                                        >
                                                                                            <AccordionSummary
                                                                                                aria-controls="panel1-content"
                                                                                                id="panel1-header"
                                                                                                sx={{
                                                                                                    borderRadius: "4px",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    "&:hover": {
                                                                                                        bgcolor: "#f0d6bb",
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                        }}
                                                                                                        xs={10}
                                                                                                        md={9}
                                                                                                    >
                                                                                                        <ExpandMoreIcon
                                                                                                            sx={{
                                                                                                                fontSize: "20px",
                                                                                                                mr: 1,
                                                                                                                transform: expanded === index ? "rotate(180deg)" : "rotate(0deg)",

                                                                                                                transition: "transform 0.3s ease",
                                                                                                            }}
                                                                                                        />
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontWeight: expanded === index ? "600" : "400",
                                                                                                                fontSize: "15px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.question_title?.length > 40
                                                                                                                ? value?.question_title.substring(0, 40) + "..."
                                                                                                                : value?.question_title}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "end",
                                                                                                        }}
                                                                                                        xs={2}
                                                                                                        md={3}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontSize: "14px",
                                                                                                                fontWeight: 400,
                                                                                                                color: "var(--text-color-black)",
                                                                                                                padding: "2px 6px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.correct ? ((value?.result?.correct * 100) / value?.question_count).toFixed(2) ?? 0 : 0}%
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails
                                                                                                sx={{
                                                                                                    px: 5,
                                                                                                }}
                                                                                            >
                                                                                                <Grid
                                                                                                    sx={{
                                                                                                        display: "flex",

                                                                                                        mb: 3,
                                                                                                    }}
                                                                                                >
                                                                                                    <SignalCellularAltOutlinedIcon
                                                                                                        sx={{
                                                                                                            mr: 1,
                                                                                                            fontSize: "18px",
                                                                                                        }}
                                                                                                    />
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                            mr: 2,
                                                                                                        }}
                                                                                                    >
                                                                                                        Basic
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Finished in {value?.result?.completed_time ? value?.result?.completed_time : "00:00:00"} out of {value.time}
                                                                                                    </Typography>
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid xs={12} md={12}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.in_correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Incorrect Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.in_correct * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "#cb7b7a",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.in_correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Skipped Questions"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.skipped * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-black)",
                                                                                                                        backgroundColor: "var(--button-bg-color-yellow)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Not Attempt"
                                                                                                                    style={{
                                                                                                                        width: `${100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count ===
                                                                                                                            0
                                                                                                                            ? 4
                                                                                                                            : 100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--button-hover-bg-color)",
                                                                                                                        backgroundColor: "#dfdfdf",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Correct Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.correct * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "var(--button-bg-color-green)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Divider />
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    ))}
                                                                                </Grid>
                                                                            </Item>
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <Grid
                                                                        sx={{
                                                                            px: MiddleBoxPadding,
                                                                            mt: isLaptop ? "40px" : " ",
                                                                        }}
                                                                        xs={12}
                                                                        md={12} lg={6}
                                                                    >
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={12}
                                                                                        md={12}
                                                                                    >
                                                                                        <Box>
                                                                                            <Box sx={{ m: 3 }} />
                                                                                            <Typography gutterBottom>
                                                                                                {candidateDisqualified ? "This candidate has not qualified based on the qualifying questions they answered.  Therefore there are no test results to show." : "This candidate has not started their assessment yet. Therefore there are no test results to show."}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Item>
                                                                        {/* <QualifyingDetailsTable
                                                                        qualifyingQuestionList={qualifyingQuestionList}
                                                                    /> */}
                                                                    </Grid>
                                                                )}

                                                            </Grid>
                                                        </Box>

                                                    </>
                                                    :

                                                    <>
                                                        <Box sx={{ px: 3, flexGrow: 1 }}>
                                                            <Grid container spacing={0} sx={{ mt: 5 }}>
                                                                <Grid xs={12} sm={12} md={4} lg={2}>
                                                                    <Item sx={{ boxShadow: "unset" }}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            Invited
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {dayjs(data?.assessment?.invited_on).format("MMMM Do, YYYY")}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Completed
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {!notData ? (data?.assessment?.completed === null ? "N/A" : dayjs(data?.assessment?.completed).format("MMMM Do, YYYY")) : "N/A"}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Extra time breakdown
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {data?.assessment?.results?.candidate_extra_obj?.is_status ? (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyleType: "disc",
                                                                                        paddingLeft: "20px",
                                                                                        lineHeight: 1.5,
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.candidate_extra_obj?.is_disabled > 0 && <li>Granted to all candidates +{data?.assessment?.results?.candidate_extra_obj?.is_disabled + "%"}</li>}
                                                                                    {data?.assessment?.results?.candidate_extra_obj?.is_fluent_in_lang > 0 && <li>Not fluent in English + {data?.assessment?.results?.candidate_extra_obj?.is_fluent_in_lang + "%"}</li>}
                                                                                </ul>
                                                                            ) : (
                                                                                "No extra time was granted to this candidate"
                                                                            )}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Source
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {data?.assessment?.source ?? "N/A"}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                                mb: 3,
                                                                            }}
                                                                        >
                                                                            Hiring stage
                                                                        </Typography>
                                                                        <Grid className="TableFormStyle">
                                                                            <TableForm
                                                                                hiringStages={hiringStages}
                                                                                handleChangeHireStage={handleChangeHireStage}
                                                                                selectedStage={currentHiringStage}
                                                                                item={{ id: "" }}
                                                                                index={0}
                                                                            />
                                                                        </Grid>
                                                                    </Item>
                                                                </Grid>
                                                                {!notData ? (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                px: MiddleBoxPadding,
                                                                                mt: MobileMargin,
                                                                            }}
                                                                            xs={12}
                                                                            sm={12} md={8}
                                                                            lg={6}
                                                                        >
                                                                            <Item
                                                                                sx={{
                                                                                    borderRadius: "8px",
                                                                                    boxShadow: "0 8px 24px 4px #00000014!important",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                <Box sx={{ flexGrow: 1 }}>
                                                                                    <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                // mt: 2,
                                                                                            }}
                                                                                            xs={8}
                                                                                            md={8}
                                                                                        >
                                                                                            <Box
                                                                                                sx={{
                                                                                                    width: 320,
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        m: 3,
                                                                                                    }}
                                                                                                />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        mb: 5,
                                                                                                    }}
                                                                                                >
                                                                                                    {data.candidate_details
                                                                                                        ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                                                                                        " " +
                                                                                                        (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                                                                                        : ""}
                                                                                                </Typography>
                                                                                                <Box
                                                                                                    className="tooltip123"
                                                                                                    sx={{
                                                                                                        height: "15px",
                                                                                                        borderRadius: "18px",
                                                                                                        // border: "1px solid #000",
                                                                                                        background: `linear-gradient(to right, var(--button-bg-color-green) ${overallPer}%, #cfcfcf 0%)`,
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography
                                                                                                        className="tooltiptext"
                                                                                                        sx={{
                                                                                                            left: `${overallPer}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText321">{overallPer}</span>
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        className="tooltiptext1234 additionalClass"
                                                                                                        sx={{
                                                                                                            left: `${data?.assessment?.results?.best_candidate_score}%`,
                                                                                                            "& .tooltiptext123": {
                                                                                                                zIndex: 9,
                                                                                                            },
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText">{data?.assessment?.results?.best_candidate_score}</span>
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        className="tooltiptext123"
                                                                                                        sx={{
                                                                                                            left: `${data?.assessment?.results?.avg_candidate_score}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="tooltipSpanText123">{data?.assessment?.results?.avg_candidate_score}</span>
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                                alignItems: "end",
                                                                                                mt: 5,
                                                                                                textAlign: "end"
                                                                                            }}
                                                                                            xs={4}
                                                                                            md={4}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="h1"
                                                                                                sx={{
                                                                                                    fontSize: "44px",
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {overallPer}%
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "13px",
                                                                                                    fontWeight: 500,
                                                                                                }}
                                                                                            >
                                                                                                AVERAGE SCORE
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Grid
                                                                                    sx={{
                                                                                        px: 0,
                                                                                        mb: 3,
                                                                                        mt: 8,
                                                                                        display: "flex", 
                                                                                        alignItems: "Center",
                                                                                        flexWrap: "nowrap"
                                                                                    }}
                                                                                    container
                                                                                    spacing={0}
                                                                                >
                                                                                    <Grid xs={3} md={3}>
                                                                                        <Typography variant="body2" sx={{ fontSize: "12px", my: 1, fontWeight:600 }}>
                                                                                            {" "}
                                                                                            This assessment:
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid sx={{ display: "flex", my: 1, alignItems: "center" }} xs={5} md={5}>

                                                                                        <FiberManualRecordOutlinedIcon sx={{ color: "#276678" }} />
                                                                                        <Typography variant="body2" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", }}>
                                                                                            {" "}
                                                                                            Your candidate pool average
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid sx={{ display: "flex", my: 1, alignItems: "center" }} xs={4} md={4}>

                                                                                        <FiberManualRecordIcon sx={{ color: "#276678" }} />
                                                                                        <Typography variant="body2" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px" }}>
                                                                                            Your best candidate score
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                            </Item>

                                                                            <Item
                                                                                sx={{
                                                                                    p: 3,
                                                                                    bgcolor: "#E9F0F1",
                                                                                    borderRadius: "16px",
                                                                                    boxShadow: "none",
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <Grid container spacing={0}>
                                                                                    <Grid xs={12} md={6}>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                                fontWeight: 400,
                                                                                                color: "var(--text-color-black)",
                                                                                                padding: "2px 6px",
                                                                                                borderRadius: "0 5px 5px 0",
                                                                                            }}
                                                                                        >
                                                                                            Scoring method
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "16px",
                                                                                                fontWeight: 700,
                                                                                                color: "var(--text-color-black)",
                                                                                                padding: "2px 6px",
                                                                                                borderRadius: "0 5px 5px 0",
                                                                                            }}
                                                                                        >
                                                                                            Percentage of correct answers
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    {/* <Grid xs={12} md={6}>
                                                                                        <Item
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "end",
                                                                                                boxShadow:
                                                                                                    "none",
                                                                                                background:
                                                                                                    "none",
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={
                                                                                                    btnStyle1
                                                                                                }
                                                                                                sx={{
                                                                                                    bgcolor:
                                                                                                        "var(--button-bg-color-transparent)",
                                                                                                    boxShadow:
                                                                                                        "none",
                                                                                                    borderRadius:
                                                                                                        "4px",
                                                                                                    "&:hover": {
                                                                                                        bgcolor:
                                                                                                            "var(--button-bg-color-transparent)",
                                                                                                        boxShadow:
                                                                                                            "none",
                                                                                                    },
                                                                                                }}
                                                                                                variant="contained"
                                                                                            >
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        fontWeight: 400,
                                                                                                        textTransform:
                                                                                                            "none",
                                                                                                        fontSize:
                                                                                                            "14px",
                                                                                                        display:
                                                                                                            "contents",
                                                                                                        color: "var(--text-color-black)",
                                                                                                    }}
                                                                                                    variant="body1"
                                                                                                >
                                                                                                    {
                                                                                                        <EditOutlinedIcon
                                                                                                            sx={{
                                                                                                                color: "var(--text-color-black)",
                                                                                                                height: "19px",
                                                                                                                width: "19px",
                                                                                                                mr: 1,
                                                                                                            }}
                                                                                                        ></EditOutlinedIcon>
                                                                                                    }
                                                                                                    Change
                                                                                                </Typography>
                                                                                            </Button>
                                                                                        </Item>
                                                                                    </Grid> */}
                                                                                </Grid>

                                                                                <Grid sx={{ mt: 5 }}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 700,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                            textTransform: "uppercase",
                                                                                            mb: 2
                                                                                        }}
                                                                                    >
                                                                                        Official Question
                                                                                    </Typography>
                                                                                    {data?.assessment?.official_question?.map((value, index) => (
                                                                                        <Accordion
                                                                                            className="accordianBorder"
                                                                                            key={index}
                                                                                            sx={{
                                                                                                borderRadius: "8px",
                                                                                                boxShadow: "none",
                                                                                                mt: 2,
                                                                                                "&.css-1nstih9-MuiPaper-root-MuiAccordion-root::before": {
                                                                                                    position: "relative",
                                                                                                },
                                                                                            }}
                                                                                            expanded={expanded === index}
                                                                                            onChange={handleAccordionChange(index)}
                                                                                        >
                                                                                            <AccordionSummary
                                                                                                aria-controls="panel1-content"
                                                                                                id="panel1-header"
                                                                                                sx={{
                                                                                                    borderRadius: "4px",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    "&:hover": {
                                                                                                        bgcolor: "#f0d6bb",
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                        }}
                                                                                                        xs={10}
                                                                                                        md={9}
                                                                                                    >
                                                                                                        <ExpandMoreIcon
                                                                                                            sx={{
                                                                                                                fontSize: "20px",
                                                                                                                mr: 1,
                                                                                                                transform: expanded === index ? "rotate(180deg)" : "rotate(0deg)",

                                                                                                                transition: "transform 0.3s ease",
                                                                                                            }}
                                                                                                        />
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontWeight: expanded === index ? "600" : "400",
                                                                                                                fontSize: "15px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.question_title?.length > 40
                                                                                                                ? value?.question_title.substring(0, 40) + "..."
                                                                                                                : value?.question_title}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "end",
                                                                                                        }}
                                                                                                        xs={2}
                                                                                                        md={3}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontSize: "14px",
                                                                                                                fontWeight: 400,
                                                                                                                color: "var(--text-color-black)",
                                                                                                                padding: "2px 6px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.correct ? ((value?.result?.correct * 100) / value?.question_count).toFixed(2) ?? 0 : 0}%
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails
                                                                                                sx={{
                                                                                                    px: 5,
                                                                                                }}
                                                                                            >
                                                                                                <Grid
                                                                                                    sx={{
                                                                                                        display: "flex",

                                                                                                        mb: 3,
                                                                                                    }}
                                                                                                >
                                                                                                    <SignalCellularAltOutlinedIcon
                                                                                                        sx={{
                                                                                                            mr: 1,
                                                                                                            fontSize: "18px",
                                                                                                        }}
                                                                                                    />
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                            mr: 2,
                                                                                                        }}
                                                                                                    >
                                                                                                        Basic
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Finished in {value?.result?.completed_time ? value?.result?.completed_time : "00:00:00"} out of {value.time}
                                                                                                    </Typography>
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid xs={12} md={12}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.in_correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Incorrect Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.in_correct * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "#cb7b7a",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.in_correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Skipped Questions"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.skipped * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-black)",
                                                                                                                        backgroundColor: "var(--button-bg-color-yellow)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Not Attempt"
                                                                                                                    style={{
                                                                                                                        width: `${100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count ===
                                                                                                                            0
                                                                                                                            ? 4
                                                                                                                            : 100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--button-hover-bg-color)",
                                                                                                                        backgroundColor: "#dfdfdf",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Correct Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.correct * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "var(--button-bg-color-green)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Divider />
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    ))}
                                                                                </Grid>
                                                                            </Item>
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <Grid
                                                                        sx={{
                                                                            px: MiddleBoxPadding,
                                                                            mt: MobileMargin,
                                                                        }}
                                                                        xs={12}
                                                                        md={6}
                                                                    >
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={12}
                                                                                        md={12}
                                                                                    >
                                                                                        <Box>
                                                                                            <Box sx={{ m: 3 }} />
                                                                                            <Typography gutterBottom>
                                                                                                {candidateDisqualified ? "This candidate has not qualified based on the qualifying questions they answered.  Therefore there are no test results to show." : "This candidate has not started their assessment yet. Therefore there are no test results to show."}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                )}
                                                                <Grid sx={{ mt: SmMobileMargin, px: 2 }} xs={12} md={12} lg={4}>
                                                                    <Item sx={{ boxShadow: "unset", p: 0 }}>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    Anti-cheating monitor
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <HelpOutlineOutlinedIcon onClick={handleOpenAntiCheating} sx={{ cursor: "pointer" }} />
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 4,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Device used
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            (data?.assessment?.results?.anti_cheating_monitor?.platform !== undefined)
                                                                                                ? ""
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.platform !== undefined ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.platform ? "2px 12px" : "2px 10px",
                                                                                        color: (data?.assessment?.results?.anti_cheating_monitor?.platform === undefined) ? "var(--text-color-OffWhite)" : "",
                                                                                        borderRadius: "5px",
                                                                                        textAlign: "end",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.platform ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Location
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            (data?.assessment?.results?.location !== undefined)
                                                                                                ? ""
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.location !== undefined ? "2px 10px" : data?.assessment?.results?.location ? "2px 12px" : "2px 10px",
                                                                                        color: (data?.assessment?.results?.location === undefined) ? "var(--text-color-OffWhite)" : "",
                                                                                        borderRadius: "5px",
                                                                                        textAlign: "end",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.location ?? (data?.assessment?.completed === null ? "N/A" : "Not Found")}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Filled out only once from IP address?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.filled_out_once_from_ip ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Webcam enabled?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "var(--button-bg-color-green)" : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.webcam_enabled ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Full-screen mode always active?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.full_screen_mode_always_active ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Mouse always in assessment window?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes"
                                                                                                ? "var(--button-bg-color-green)"
                                                                                                : "#cb7b7a",
                                                                                        padding: data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "2px 10px" : data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.assessment?.results?.anti_cheating_monitor?.mouse_always_in_screen ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <CameraComponent videoPath={videoPath} is_snapshot_enable={data?.assessment?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable} videoPathMarker={videoPathMarker} videoRecordTime={videoRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                                                                        <ScreenShotComponent screenVideoPath={screenVideoPath} is_screenshot_enable={data?.assessment?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable} screenVideoPathMarker={screenVideoPathMarker} screenRecordTime={screenRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>

                                                    </>

                                            }

                                            {!notData ? (
                                                <>
                                                    <CandidateDetailsTable
                                                        subMetaStarLoader={subMetaStarLoader}
                                                        setSubMetaStarLoader={setSubMetaStarLoader}
                                                        customQuestionList={customQuestionList}
                                                        subMetaStarIndex={subMetaStarIndex}
                                                        customQuestionRating={customQuestionRating}
                                                        handleStarClick={handleStarClick}
                                                        rowResult_id={data?.assessment?.results?.result_id}
                                                    />
                                                    <QualifyingDetailsTable
                                                        qualifyingQuestionList={qualifyingQuestionList}
                                                    />
                                                    <Divider
                                                        sx={{
                                                            mt: 5,
                                                            borderWidth: "1px",
                                                            borderColor: "#ddd",
                                                        }}
                                                    />
                                                    <Box sx={{ p: 3, flexGrow: 1 }}>
                                                        <Grid container spacing={0}>
                                                            <Grid xs={12} md={6}>
                                                                <Item
                                                                    sx={{
                                                                        boxShadow: "unset",
                                                                        background: "white",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        Your rating
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            mt: 2,
                                                                        }}
                                                                    >
                                                                        Rate this candidate based on your personal impressions and interactions with them.
                                                                    </Typography>

                                                                    <Grid
                                                                        sx={{
                                                                            display: "flex",
                                                                            mt: 5,
                                                                        }}
                                                                    >
                                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                                            <StarIcon
                                                                                key={star}
                                                                                onClick={() => handleStarClick(star, "yourRating")}
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    color: star <= rating ? "#faaf00" : "#d2d2d2",
                                                                                    fontSize: "30px",
                                                                                }}
                                                                            />
                                                                        ))}
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>
                                                            <Grid xs={12} md={6}>
                                                                <Item
                                                                    sx={{
                                                                        boxShadow: "unset",
                                                                        background: "white",
                                                                    }}
                                                                >
                                                                    <Box sx={{ mt: 2 }}>
                                                                        <ThemeProvider theme={customTheme(outerTheme)}>
                                                                            <TextField
                                                                                onChange={handleSubmit}
                                                                                fullWidth
                                                                                label="Add your private notes here (auto-saved)..."
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                value={private_note}
                                                                                multiline
                                                                                rows={6}
                                                                            />
                                                                        </ThemeProvider>
                                                                    </Box>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            ) : (<>
                                                {data?.assessment?.completed !== null &&
                                                    <QualifyingDetailsTable
                                                        qualifyingQuestionList={qualifyingQuestionList}
                                                    />
                                                }
                                            </>
                                            )}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                </>
            )}
            <AntiCheatingMoniter open={openAntiCheating} setOpen={setOpenAntiCheating} />
        </Box>
    );
}
export default Index;



const CameraComponent = (props) => {
    const { videoPath, is_snapshot_enable, videoPathMarker, videoRecordTime, handleDummyPlayerClick } = props;

    if (videoPath && videoPath !== "rendering" && is_snapshot_enable) {
        return (
            <Grid
                sx={{
                    display: "block",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Camera Video :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#cb7b7a",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <VideoStream videoUrl={videoPath} markersPoint={videoPathMarker} time={videoRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Camera Video :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            bgcolor: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}> {videoPath === "rendering" ? (
                            <>
                                <CircularProgress style={{ width: "22px", height: "22px" }} color="inherit" />
                                Video is Rendering
                            </>
                        ) : "N/A"} </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

const ScreenShotComponent = (props) => {
    const { screenVideoPath, is_screenshot_enable, screenVideoPathMarker, screenRecordTime, handleDummyPlayerClick } = props;

    if (screenVideoPath && screenVideoPath !== "rendering" && is_screenshot_enable) {
        return (
            <Grid
                sx={{
                    display: "block",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 2,
                    alignItems: "end"
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Screen Record :
                    </Typography>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <VideoStream videoUrl={screenVideoPath} markersPoint={screenVideoPathMarker} time={screenRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                        <Grid
                            sx={{
                                display: "flex",
                            }}
                            mt={3}
                        >
                            <Grid sx={{ display: "flex" }} sm={6}>
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        bgcolor: "#cb7b7a",
                                        borderRadius: "50%",
                                    }}
                                ></Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: "flex",
                                        color: "var(--text-color-black)",
                                        ml: 1,
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    Tab Action
                                </Typography>
                            </Grid>
                            <Grid sx={{ display: "flex" }} sm={6}>
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        bgcolor: "var(--button-bg-color-green)",
                                        borderRadius: "50%",
                                    }}
                                ></Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: "flex",
                                        color: "var(--text-color-black)",
                                        ml: 1,
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    Mouse Action
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Screen Record :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            bgcolor: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}> {screenVideoPath === "rendering" ? (
                            <>
                                <CircularProgress style={{ width: "22px", height: "22px" }} color="inherit" />
                                Video is Rendering
                            </>
                        ) : "N/A"} </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}