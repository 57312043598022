import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import PaymentSuccessImg from '../../../Assests/Images/PaymentImg/paymentSuccessImg.png';
import Navbar from '../../../Components/Admin/Layouts/Index'; 
import { Button, Container, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center', 
  background:"white",
  boxShadow: "0 6px 7px -4px #88888829,0 11px 15px 1px #8888881f,0 4px 20px 3px #88888823"
}));

export default function BasicGrid() {
  return (
    <Box sx={{height:"100vh",background:"#F2F2F2",display:"flex",alignItems:"center"}}> 
        <Navbar/>
    <Container maxWidth={"md"}  >
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <Item sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",p:5}}>
          <img src={PaymentSuccessImg} style={{height:"auto",width:"30%"}} alt="Loading" />
      <Typography variant='h2' sx={{fontWeight:700, fontSize:"30px", }}>Payment Successfull</Typography>
          
          </Item>
        </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
  );
}

