import {
    Container, Grid, Select, TextField, Typography,
    Tabs, Tab, Switch, CircularProgress, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SendIcon from "@mui/icons-material/Send";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from "axios";
import Example from "../../../../../Assests/Images/TeamMemberImg/InviteBulkImg.png";
import { getHeaders, donwloadcondidateTemplateXlsx, condidateInvite, talentRoutePath, donwloadcondidateTemplateCsv, publicAssessmentStatusUpdateUrl } from "../../../../../Constants/ConstantsUrl";
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from "@mui/icons-material/Check";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import Loader from "../../../../Common/BackdropLoader";
import Alerts from "../../../../Common/Alerts";
import { AllowedAccess } from "react-permission-role";
import { APIProvider } from "../../../../Common/ApiProvider";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Puff } from "react-loader-spinner";
import Checkbox from "@mui/material/Checkbox";
import CustomLoader from "../../../../Common/CustomLoader"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#c8d7de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#c8d7de",
    },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "16px 0px 16px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 10,
            borderColor: "var(--text-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--text-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        minWidth: "245px",
    },
}));

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f",
                        "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                            transition: "0.5s all",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            transition: "0.5s all",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",
    border: "1px solid var(--text-color-black)",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

function TeamMember({
    fileLoader,
    assessmentId,
    formFinalData,
    isModalOpen,
    toggleModal,
    manageAccessUser,
    addMemberFun,
    uploadLinkFile,
    uploadfileName,
    sebmitUploadedFile,
    webAllRoleData,
    searchTeamMemberHandle,
    searchTeamMemberValue,
    roleSelectedOption,
    roleHandleChange,
    canInviteCandidate,
    csvxlsxFileUploadError,
    isPublicLink,
    setIsPublicLink,
    candidateList,
    getSingleAssessmentCandidateData,
    getTeamMembersData,
    uploadingFile,
}) {
    const isSmMobile = useMediaQuery("(max-width:599px)");
    const isMobile = useMediaQuery("(max-width:899px)");
    const isTablet = useMediaQuery("(max-width:1010px)");
    const isLaptop = useMediaQuery("(max-width:1250px)");
    const ButtonMarginTop = isMobile ? "16px" : "";
    const SelectWidth = isMobile ? "100%" : "80%";
    const Formposition = isMobile ? "center" : "end";
    const ContentHeight = isMobile ? "16px" : "40px";
    const TextPosition = isMobile ? "center" : "start";
    const UploadPadding = isMobile ? "" : "24px";
    const ClearPosition = isMobile ? "center" : "start";
    const LinkText = isMobile ? "" : "flex";
    const navigate = useNavigate();
    const [isLoadinSendInvitation, setIsLoadinSendInvitation] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState(0);
    const [isConfirmBox, setIsConfirmBox] = useState(false);
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const handleChanges = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    const handleTabsChange = (event, newValue) => {
        setValue(newValue);
    };

    const outerTheme = useTheme();
    const [selectedTeamInvite, setSelectedTeamInvite] = useState([]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const Modal2 = ({ onRequestClose }) => {
        const [popupLoader, setPopupLoader] = useState(false);
        const paragraphRef = useRef(null);
        const [moreOptions, setMoreOptions] = useState([]);
        const [email, setEmail] = useState("");
        const [emailError, setEmailError] = useState(false);
        const [disableAddButton, setDisableAddButton] = useState(true);
        const [text, setText] = useState(false);
        const [emailEdit, setEmailEdit] = useState("");

        useEffect(() => {
            document.body.classList.add("MODALOPENHARSH");
            return () => {
                document.body.classList.remove("MODALOPENHARSH");
            };
        }, []);

        const [errorMsg, setErrorMsg] = useState([
            {
                // first_name: false,
                // last_name: false,
                email: false,
                success: "",
            },
        ]);

        const handleResetForm = () => {
            setMoreOptions([]);
        };
        const checkvalidEmail = (email) => {
            let emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x09\x0b\x0c\x0e-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x09\x0b\x0c\x0e-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
            if (!emailRegex.test(email)) {
                return 0; // Invalid email
            } else {
                return 1; // Valid email
            }
        };

        const handleInputChange = (event) => {
            setEmail(event.target.value);
            const check = checkvalidEmail(event.target.value);
            if (event.target.value === "") {
                setEmailError("Email Cannot be Empty");
                setDisableAddButton(true);
            } else if (check === 1) {
                setEmailError(false);
                setDisableAddButton(false);
            } else {
                setEmailError(true);
                setDisableAddButton(true);
            }
            // const updatedOptions = [...moreOptions];

            // updatedOptions[index] = {
            //     ...updatedOptions[index],
            //     [event.target.name]: event.target.value,
            // };

            // setMoreOptions((prevOptions) => updatedOptions);
            // const updatedfiledOptions = [...errorMsg];
            // updatedfiledOptions[index] = {
            //     ...updatedfiledOptions[index],
            //     [event.target.name]: event.target.name ? false : true,
            // };
            // setErrorMsg((prevOptions) => updatedfiledOptions);
        };

        const handleSubmitInvite = async () => {
            setPopupLoader(true);
            // const emailValid = [];
            // moreOptions.map((data, index) => {
            //     if (data?.email !== "") {
            //         emailValid.push(data.email);
            //     }
            //     let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
            //     if (data?.email === "") {
            //         const updatedfiledOptions = [...errorMsg];
            //         updatedfiledOptions[index] = {
            //             ...updatedfiledOptions[index],
            //             email: data?.email ? false : true,
            //         };
            //         setErrorMsg((prevOptions) => updatedfiledOptions);
            //     }
            //     if (!emailRegex.test(data?.email)) {
            //         const updatedfiledOptions = [...errorMsg];
            //         updatedfiledOptions[index] = {
            //             ...updatedfiledOptions[index],
            //             email: true,
            //         };
            //         setErrorMsg((prevOptions) => updatedfiledOptions);
            //     }
            // });
            // if (emailValid.length !== moreOptions.length) {
            //     setPopupLoader(false);
            //     return false;
            // }
            // return false;
            submitFunction(moreOptions);
        };

        const submitFunction = async (data) => {
            const dataSended = { user_details: data, assessment_id: assessmentId };
            try {
                const headers = getHeaders();
                await axios
                    .post(condidateInvite, dataSended, { headers })
                    .then((response) => response)
                    .then((response) => {
                        const res = response.data;
                        if (res.status === 200) {
                            let existingCandidate = res?.data?.existing_candidates || [];
                            if (existingCandidate.length > 0) {
                                setMoreOptions(existingCandidate);

                                const updatedfiledOptions = [];
                                existingCandidate.map((data, index) => {
                                    updatedfiledOptions[index] = {
                                        email: "This Email is Already Exist and Invited",
                                    };
                                });
                                setErrorMsg((prevOptions) => updatedfiledOptions);
                            }
                            else {
                                setPopupLoader(false);
                                toggleModal();
                                getSingleAssessmentCandidateData();
                            }
                        } else if (res.status === 201) {
                            let errorCandidates = res?.data?.errorCandidates || [];
                            let existingCandidate = res?.data?.existing_candidates || [];

                            setMoreOptions([...existingCandidate, ...errorCandidates]);
                            const existingOptions = [];
                            existingCandidate.map((data, index) => {
                                existingOptions.push({
                                    email: "This Email is Already Exist and Invited",
                                });
                            });
                            errorCandidates.map((data, index) => {
                                existingOptions.push({
                                    email: data?.message,
                                });
                            });
                            setErrorMsg((prevOptions) => existingOptions);
                        }
                    });
                setPopupLoader(false);
            } catch (error) {
                if (error.response.status === 403) {
                    let errorCandidates = error.response.data.data || [];
                    console.log(errorCandidates);
                    // setMoreOptions(errorCandidates);

                    // const updatedfiledOptions = [];
                    // errorCandidates.map((data, index) => {
                    //     updatedfiledOptions[index] = {
                    //         email: data?.message,
                    //     };
                    // });
                    // setErrorMsg((prevOptions) => updatedfiledOptions);
                }
                console.log(error);
                setPopupLoader(false);
            }
        };

        const handleAddOption = () => {
            const updatedOptions = {
                // first_name: "",
                // last_name: "",
                email: email,
            };

            setMoreOptions([...moreOptions, updatedOptions]);
            setEmail("");
            setDisableAddButton(true);
            // if (moreOptions.length < 10) {
            //     setMoreOptions([
            //         ...moreOptions,
            //         {
            //             first_name: "",
            //             last_name: "",
            //             email: "",
            //         },
            //     ]);
            // }
        };

        const handleDeleteOption = (index) => {
            // if (moreOptions.length > 1) {
            const updatedOptions = moreOptions.filter((_, i) => i !== index);
            setMoreOptions(updatedOptions);
            const errorOptions = errorMsg.filter((_, i) => i !== index);
            setErrorMsg(errorOptions);
            // }
        };

        const handleChangeTypoToText = (e, data, index) => {
            setEmailEdit(data.email);
            setText(index);
        };

        const handleChangeTextToTypo = (event, data, index) => {
            const check = checkvalidEmail(event.target.value);
            const updatedfiledOptions = [...errorMsg];
            if (event.target.value === "" || check === 0) {
                updatedfiledOptions[index] = {
                    ...updatedfiledOptions[index],
                    [event.target.name]: false,
                };
                setErrorMsg((prevOptions) => updatedfiledOptions);
            } else {
                const updatedOptions = [...moreOptions];
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    [event.target.name]: event.target.value,
                };

                setMoreOptions((prevOptions) => updatedOptions);
                const updatedfiledOptions = [...errorMsg];
                updatedfiledOptions[index] = {
                    ...updatedfiledOptions[index],
                    [event.target.name]: false,
                };
                setErrorMsg((prevOptions) => updatedfiledOptions);
            }
            setText(false);
        };

        const handleInputTableChange = (event, index) => {
            setEmailEdit(event.target.value);
            const check = checkvalidEmail(event.target.value);
            const updatedfiledOptions = [...errorMsg];
            if (event.target.value === "" || check === 0) {
                updatedfiledOptions[index] = {
                    ...updatedfiledOptions[index],
                    [event.target.name]: true,
                };
                setErrorMsg((prevOptions) => updatedfiledOptions);
            } else {
                updatedfiledOptions[index] = {
                    ...updatedfiledOptions[index],
                    [event.target.name]: false,
                };
                setErrorMsg((prevOptions) => updatedfiledOptions);
            }
        };

        const copyFun = () => {
            navigator.clipboard.writeText(talentRoutePath + "/assessment/add-user/public-assessment-invite/" + formFinalData.public_url + '/' + formFinalData.invite_user_url).then(
                setAlertNotification({
                    status: true,
                    type: "success",
                    message: "Source link copied",
                })
            );
        };

        const StyledTabs = styled(Tabs)({
            "& .MuiTabs-indicator": {
                backgroundColor: "var(--text-color-black)",
            },
        });

        const StyledTab = styled(Tab)({
            fontWeight: 500,
            fontSize: "16px",
            color: "var(--text-color-black)",
            "&.Mui-selected": {
                color: "var(--text-color-black)",
                fontWeight: 600,
            },
        });

        const VisuallyHiddenInput = styled("input")({
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
        });

        const [callLoader, setCallLoader] = useState(false);
        const [activeSourceLink, setActiveSourceLink] = useState(isPublicLink);
        const handleSwitchChange = async () => {
            //setCallLoader(true);
            try {
                const headers = getHeaders();
                await axios.post(publicAssessmentStatusUpdateUrl, { assessment_id: assessmentId }, { headers }).then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setIsPublicLink(res?.data?.current_status == 1 ? true : false);
                        setCallLoader(false);
                    }
                });
            } catch (error) {
                console.log(error);
                setCallLoader(false);
            }
        };
        const handleCopy = (event) => {
            event.preventDefault(); // Prevent the default copy behavior
        };
        const label = { inputProps: { "aria-label": "Switch demo" } };
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmMobile ? 400 : isMobile ? 600 : isTablet ? 700 : isLaptop ? 1000 : 1200,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: isTablet ? "12px" : "32px 32px 0px 32px",
            height: 800,
            overflow: "scroll",
            borderRadius: "8px"
        };
        return (
            <>
                <Modal
                    className="RemoveOutLine"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isModalOpen}
                    onClose={onRequestClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                    sx={{ backdropFilter: "blur(4px)" }}
                >
                    <Fade
                        in={true}
                    >
                        <Box sx={style}>
                            <Grid>
                                <Grid sx={{ p: 0 }}>
                                    <Grid container spacing={0} sx={{ mt: 1 }}>
                                        <Grid xs={11} md={11}>
                                            <Typography variant="body1" sx={{ fontSize: "22px", fontWeight: 700 }}>
                                                Invite Candidates
                                            </Typography>
                                        </Grid>
                                        <Grid xs={1} md={1}>
                                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                <CloseIcon onClick={onRequestClose} sx={{ cursor: "pointer" }} />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ width: "100%", p: isMobile ? "8px" : "16px" }}>
                                        {/* {popupLoader && <Loader />} */}
                                        <Box className="HideScrollBar" sx={{ borderBottom: 1, borderColor: "divider", }}>
                                            <StyledTabs value={value} onChange={handleTabsChange} aria-label="basic tabs example">
                                                <StyledTab label="Invite by email" {...a11yProps(0)} />
                                                <StyledTab label="Invite in bulk" {...a11yProps(1)} />
                                                {formFinalData.public_url + '/' + formFinalData.invite_user_url && <StyledTab label="Share link" {...a11yProps(2)} />}
                                            </StyledTabs>
                                        </Box>
                                        <Grid sx={{ transition: "1s all ease" }} className="smooth_scroll">
                                            <CustomTabPanel value={value} index={0} className="tabs_padding">
                                                <Grid sx={{ mt: 2, mb: 3, height: "500px", overflow: "scroll" }} xs={12} md={12}>
                                                    <Typography variant="body1" sx={{ fontSize: "14px", px: "16px" }}>
                                                        Invite several candidates by entering their email addresses below.
                                                    </Typography>
                                                    <Grid sx={{ px: "8px" }}>
                                                        <Grid
                                                            sx={{
                                                                border: "1px solid #ccc",
                                                                borderRadius: "10px",
                                                                overflow: "hidden",
                                                                mt: 2,
                                                                pb: 5,

                                                            }}
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid xs={12} md={12} sx={{ bgcolor: "#fbfbfb", borderBottom: "1px solid #ccc" }} container spacing={0}>
                                                                <Grid xs={11} md={11} container spacing={0} sx={{ p: 2 }}>

                                                                    <Grid sx={{ mt: 1 }} xs={12} md={12}>
                                                                        <Item sx={{ boxShadow: "none", background: "none" }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    textTransform: "uppercase",
                                                                                    fontSize: "12px",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                Email
                                                                            </Typography>
                                                                        </Item>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                sx={{
                                                                    overflowY: "scroll",
                                                                    mt: 1,
                                                                    padding: "0px ",
                                                                }}
                                                            >
                                                                {moreOptions &&
                                                                    moreOptions.map((data, index) => (
                                                                        <>
                                                                            <Grid key={index} xs={9} md={11}>
                                                                                <Grid container spacing={0} sx={{ mt: 1, padding: "0px 10px" }}>
                                                                                    <Grid xs={12} md={12}>
                                                                                        <Item
                                                                                            sx={{
                                                                                                boxShadow: "none",
                                                                                                background: "none",
                                                                                            }}
                                                                                        >
                                                                                            {text === index ? (
                                                                                                <TextField
                                                                                                    sx={{
                                                                                                        "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                                                            borderRadius: "4px",
                                                                                                            borderColor: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                                                                                            transition: "green 0.3s ease-in-out",
                                                                                                            "&:hover": {
                                                                                                                // borderColor: '#0000001f',
                                                                                                            },
                                                                                                        },
                                                                                                        "& #combo-box-demo": {
                                                                                                            color: "green",
                                                                                                        },
                                                                                                        "&:focus-within .MuiInputLabel-root": {
                                                                                                            color: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                                                                                        },
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    label="Email"
                                                                                                    id="fullWidth"
                                                                                                    name="email"
                                                                                                    value={text === index ? emailEdit : data?.email}
                                                                                                    error={errorMsg[index]?.email ? true : false}
                                                                                                    helperText={errorMsg[index]?.email ? (errorMsg[index]?.email === true ? "Invalid Email" : errorMsg[index]?.email) : null}
                                                                                                    onBlur={(e) => handleChangeTextToTypo(e, data, index)}
                                                                                                    onChange={(e) => handleInputTableChange(e, index)}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === "Enter") {
                                                                                                            handleChangeTextToTypo(e, data, index);
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            ) : (
                                                                                                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <Typography sx={{ paddingTop: 2, cursor: "pointer" }} onClick={(e) => handleChangeTypoToText(e, data, index)}>
                                                                                                        {data?.email}
                                                                                                    </Typography>
                                                                                                    {popupLoader && <CircularProgress sx={{ color: "black" }} size={20} />}
                                                                                                    {errorMsg[index]?.email &&
                                                                                                        <BootstrapTooltip variant="contained" title={errorMsg[index]?.email} >
                                                                                                            <PriorityHighIcon />
                                                                                                        </BootstrapTooltip>}
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Item>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid sx={{}} xs={3} md={1}>
                                                                                <Grid container spacing={0} sx={{ mt: 1, justifyContent: !isMobile ? "center" : "right", }}>
                                                                                    <Item
                                                                                        sx={{
                                                                                            fontWeight: 600,
                                                                                            boxShadow: "none",
                                                                                            background: "none",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            border: "none",
                                                                                            p: 0,
                                                                                        }}
                                                                                    >
                                                                                        <Button
                                                                                            fullWidth
                                                                                            label="Email"
                                                                                            id="fullWidth"
                                                                                            name="email"
                                                                                            variant="h6"
                                                                                            value={data?.email}
                                                                                            error={errorMsg.email ? true : false}
                                                                                            helperText={errorMsg.email ? (errorMsg.email === true ? "Email is required" : errorMsg.email) : null}
                                                                                            onClick={() => handleDeleteOption(index)}
                                                                                            sx={{ mt: "6px" }}
                                                                                            style={{
                                                                                                fontSize: "18px",
                                                                                                color: !disableAddButton ? "var(--text-color-OffWhite)" : "var(--text-color-black)",
                                                                                                cursor: "pointer",
                                                                                                borderRadius: "5px",
                                                                                                padding: "11px 10px ",
                                                                                                border: "none",
                                                                                                backgroundColor: "#cf1c1c",
                                                                                            }}
                                                                                        >
                                                                                            <RemoveCircleOutlineIcon sx={{ color: "white" }} />
                                                                                        </Button>

                                                                                    </Item>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Divider orientation="vertical" sx={{ border: "0.2px solid #ccc", height: 0, width: "100%" }} />
                                                                        </>
                                                                    ))}
                                                                <Grid xs={9} md={11}>
                                                                    <Grid container spacing={0} sx={{ mt: 1 }}>
                                                                        <Grid xs={12} md={12}>
                                                                            <Item
                                                                                sx={{
                                                                                    boxShadow: "none",
                                                                                    background: "none",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    sx={{
                                                                                        "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                                            borderRadius: "4px",
                                                                                            borderColor: emailError ? "red" : "blue",
                                                                                            transition: "green 0.3s ease-in-out",
                                                                                            "&:hover": {
                                                                                                // borderColor: '#0000001f',
                                                                                            },
                                                                                        },
                                                                                        "& #combo-box-demo": {
                                                                                            color: "green",
                                                                                        },
                                                                                        "&:focus-within .MuiInputLabel-root": {
                                                                                            color: emailError ? "red" : "var(--button-bg-color-green)",
                                                                                        },
                                                                                    }}
                                                                                    fullWidth
                                                                                    label="Email*"
                                                                                    name="email"
                                                                                    value={email}
                                                                                    error={emailError}
                                                                                    helperText={emailError ? (emailError === true ? "Invalid Email Address" : emailError) : null}
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                />
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid sx={{ alignItems: "center" }} xs={3} md={1}>
                                                                    <Grid container spacing={0} sx={{ mt: !isMobile ? "16px" : "20px", justifyContent: !isMobile ? "center" : "right" }}>
                                                                        <Item
                                                                            sx={{
                                                                                fontWeight: 600,
                                                                                boxShadow: "none",
                                                                                // background: "none",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                border: "none",
                                                                                p: 0,
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                fullWidth
                                                                                label="Email"
                                                                                id="fullWidth"
                                                                                name="email"
                                                                                variant="h6"
                                                                                style={{
                                                                                    fontSize: "18px",
                                                                                    color: !disableAddButton ? "var(--text-color-OffWhite)" : "var(--text-color-black)",
                                                                                    cursor: "pointer",
                                                                                    borderRadius: "5px",
                                                                                    padding: "11px 10px ",
                                                                                    border: "none",
                                                                                    // backgroundColor:"var(--button-bg-color-green)",
                                                                                    backgroundColor: !disableAddButton ? "var(--button-bg-color-green)" : "#ededed",
                                                                                    "&.Mui-disabled": {
                                                                                        backgroundColor: "var(--button-bg-color-green)",
                                                                                    },
                                                                                }}
                                                                                disabled={disableAddButton}
                                                                                onClick={handleAddOption}
                                                                            >
                                                                                <AddCircleOutlineIcon sx={{ color: !disableAddButton ? "white" : "black", }} />
                                                                            </Button>
                                                                        </Item>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ border: "1px solid #ccc" }} />
                                                <Grid sx={{ p: 1 }} container spacing={0}>
                                                    <Grid sx={{ display: "flex", justifyContent: ClearPosition }} xs={12} md={6}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "none",
                                                                background: "none",
                                                                display: "flex",
                                                                justifyContent: TextPosition,
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontSize: "18px",
                                                                    color: "#cf1c1c",
                                                                    fontWeight: 600,
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handleResetForm}
                                                            >
                                                                Clear list
                                                            </Typography>
                                                        </Item>
                                                    </Grid>

                                                    <Grid xs={12} md={6}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "none",
                                                                background: "none",
                                                                display: "flex",
                                                                justifyContent: isMobile ? "center" : "end",
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    color: "var(--text-color-black)",
                                                                    boxShadow: "unset",
                                                                    borderRadius: "5px",
                                                                    border: "unset",
                                                                    padding: "11px 28px ",
                                                                    cursor: "pointer",
                                                                    backgroundColor: moreOptions.length > 0 ? "var(--button-bg-color-green)" : "#ededed",
                                                                    "&:hover": {
                                                                        backgroundColor: "var(--button-hover-bg-color-LightGreen)",
                                                                    },
                                                                }}
                                                                disabled={moreOptions.length > 0 ? false : true}
                                                                variant="contained"
                                                                onClick={handleSubmitInvite}
                                                            >
                                                                <Typography
                                                                    style={{ fontSize: "18px", color: moreOptions.length > 0 ? "var(--text-color-OffWhite)" : "var(--text-color-black)" }}
                                                                    variant="body1"
                                                                    fontWeight={600}
                                                                >
                                                                    {popupLoader ? "Sending..." : "Invite candidate"}
                                                                </Typography>
                                                            </Button>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </CustomTabPanel>

                                            <CustomTabPanel value={value} index={1} className="tabs_padding">
                                                <Grid sx={{ mt: 1, mb: 2, overflow: "scroll" }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontSize: "14px",
                                                            my: 2,
                                                        }}
                                                    >
                                                        Invite multiple candidates at once by uploading a CSV or XLSX file.
                                                    </Typography>
                                                    {
                                                        isMobile ? <>
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Grid container spacing={0}>
                                                                    <Grid
                                                                        sx={{
                                                                            pl: UploadPadding,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                        xs={12}
                                                                        md={4}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                cursor: "pointer",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignContent: "center",
                                                                                bgcolor: "#f0f0f0",
                                                                                border: "1px dashed #888",
                                                                                borderRadius: "8px",
                                                                                flexDirection: "column",
                                                                                textAlign: "center",
                                                                                padding: "16px 32px",
                                                                                height: 170,
                                                                            }}
                                                                            component="label"
                                                                            variant="contained"
                                                                            starticon={<CloudUploadIcon />}
                                                                        >
                                                                            {uploadingFile ? (
                                                                                <Grid>
                                                                                    <CustomLoader />
                                                                                </Grid>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid>
                                                                                        <UploadFileIcon />
                                                                                    </Grid>
                                                                                    <Typography sx={{ fontSize: "14px" }}>
                                                                                        Drag and drop or{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 600,
                                                                                                fontSize: "14px",
                                                                                            }}
                                                                                        >
                                                                                            browse
                                                                                        </span>{" "}
                                                                                        your files.
                                                                                    </Typography>
                                                                                    <Typography sx={{ mt: 2, fontSize: "14px" }}>You can upload up to 1200 candidates per file.</Typography>
                                                                                    <Typography>
                                                                                        <span style={{ fontWeight: 600 }}>{uploadfileName}</span>
                                                                                    </Typography>
                                                                                    <VisuallyHiddenInput type="file" accept=".csv, .xls, .xlsx" onChange={(e) => uploadLinkFile(e)} />
                                                                                </>
                                                                            )
                                                                            }
                                                                        </Grid>
                                                                        <Typography sx={{ mt: 2, fontSize: "12px" }}>
                                                                            <span style={{ fontWeight: 600, fontSize: "12px" }}>Supported file types:</span> .csv .xls .xlsx
                                                                        </Typography>
                                                                        {csvxlsxFileUploadError && (
                                                                            <Typography sx={{ mt: 2, fontSize: "12px" }}>
                                                                                <span style={{ fontWeight: 600, fontSize: "18px", color: "red" }}> Upload file </span>
                                                                            </Typography>
                                                                        )}

                                                                    </Grid>
                                                                    <Grid sx={{ p: 3, bgcolor: "#e9f0f1", borderRadius: "8px" }} xs={12} md={8}>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 600,
                                                                                my: 1,
                                                                            }}
                                                                        >
                                                                            Please remember...
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                my: 1,
                                                                            }}
                                                                        >
                                                                            Make sure you use three columns in your file in the following order: First name, Last name, Email. You can only add 1200 candidates per file.
                                                                        </Typography>

                                                                        <img src={Example} style={{ width: "47%", borderRadius: "8px" }} alt="../../../../../Assests/Images/InviteTabImg.png" />

                                                                        <Grid sx={{ my: 2 }} container spacing={0}>
                                                                            <Grid sx={{ display: "flex", alignItems: "center" }} xs={12} md={7}>
                                                                                <Item
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignContent: "center",
                                                                                        boxShadow: "none",
                                                                                        background: "none",
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                        }}
                                                                                    >
                                                                                        You can download one of these templates:
                                                                                    </Typography>
                                                                                </Item>
                                                                            </Grid>
                                                                            <Grid sx={{ display: "flex", gap: "10px" }} xs={12} md={5}>
                                                                                <Button
                                                                                    style={btnStyle}
                                                                                    sx={{
                                                                                        textTransform: "none",
                                                                                    }}
                                                                                    variant="outlined"
                                                                                >
                                                                                    <FileDownloadOutlinedIcon />
                                                                                    <Typography
                                                                                        fontWeight={600}
                                                                                        sx={{
                                                                                            fontSize: "var(  --btn-fontsize)",
                                                                                        }}
                                                                                        onClick={downloadCsvTemplate}
                                                                                    >
                                                                                        CSV
                                                                                    </Typography>
                                                                                </Button>
                                                                                <Button
                                                                                    style={btnStyle}
                                                                                    sx={{
                                                                                        textTransform: "none",
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    onClick={downloadXlsxTemplate}
                                                                                >
                                                                                    <FileDownloadOutlinedIcon />
                                                                                    <Typography
                                                                                        fontWeight={600}
                                                                                        sx={{
                                                                                            fontSize: "var(  --btn-fontsize)",
                                                                                        }}
                                                                                    >
                                                                                        XLSV
                                                                                    </Typography>
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </> :
                                                            <>
                                                                <Box sx={{ flexGrow: 1 }}>
                                                                    <Grid container spacing={0}>
                                                                        <Grid sx={{ p: 3, bgcolor: "#e9f0f1", borderRadius: "8px" }} xs={12} md={7}>
                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 600,
                                                                                    my: 1,
                                                                                }}
                                                                            >
                                                                                Please remember...
                                                                            </Typography>

                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    my: 1,
                                                                                }}
                                                                            >
                                                                                Make sure you use three columns in your file in the following order: First name, Last name, Email. You can only add 1200 candidates per file.
                                                                            </Typography>

                                                                            <img src={Example} style={{ width: "47%", borderRadius: "8px" }} alt="../../../../../Assests/Images/InviteTabImg.png" />

                                                                            <Grid sx={{ my: 2 }} container spacing={0}>
                                                                                <Grid sx={{ display: "flex", alignItems: "center" }} xs={12} md={7}>
                                                                                    <Item
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignContent: "center",
                                                                                            boxShadow: "none",
                                                                                            background: "none",
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                            }}
                                                                                        >
                                                                                            You can download one of these templates:
                                                                                        </Typography>
                                                                                    </Item>
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", gap: "10px" }} xs={12} md={5}>
                                                                                    <Button
                                                                                        style={btnStyle}
                                                                                        sx={{
                                                                                            textTransform: "none",
                                                                                        }}
                                                                                        variant="outlined"
                                                                                    >
                                                                                        <FileDownloadOutlinedIcon />
                                                                                        <Typography
                                                                                            fontWeight={600}
                                                                                            sx={{
                                                                                                fontSize: "var(  --btn-fontsize)",
                                                                                            }}
                                                                                            onClick={downloadCsvTemplate}
                                                                                        >
                                                                                            CSV
                                                                                        </Typography>
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={btnStyle}
                                                                                        sx={{
                                                                                            textTransform: "none",
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        onClick={downloadXlsxTemplate}
                                                                                    >
                                                                                        <FileDownloadOutlinedIcon />
                                                                                        <Typography
                                                                                            fontWeight={600}
                                                                                            sx={{
                                                                                                fontSize: "var(  --btn-fontsize)",
                                                                                            }}
                                                                                        >
                                                                                            XLSV
                                                                                        </Typography>
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                pl: UploadPadding,
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                            }}
                                                                            xs={12}
                                                                            md={5}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignContent: "center",
                                                                                    bgcolor: "#f0f0f0",
                                                                                    border: "1px dashed #888",
                                                                                    borderRadius: "8px",
                                                                                    flexDirection: "column",
                                                                                    textAlign: "center",
                                                                                    padding: "16px 32px",
                                                                                    height: 170,
                                                                                }}
                                                                                component="label"
                                                                                variant="contained"
                                                                                starticon={<CloudUploadIcon />}
                                                                            >
                                                                                {uploadingFile ? (
                                                                                    <Grid>
                                                                                        <CustomLoader />
                                                                                    </Grid>
                                                                                ) : (
                                                                                    <>
                                                                                        <Grid>
                                                                                            <UploadFileIcon />
                                                                                        </Grid>
                                                                                        <Typography sx={{ fontSize: "14px" }}>
                                                                                            Drag and drop or{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                    fontSize: "14px",
                                                                                                }}
                                                                                            >
                                                                                                browse
                                                                                            </span>{" "}
                                                                                            your files.
                                                                                        </Typography>
                                                                                        <Typography sx={{ mt: 2, fontSize: "14px" }}>You can upload up to 1200 candidates per file.</Typography>
                                                                                        <Typography>
                                                                                            <span style={{ fontWeight: 600 }}>{uploadfileName}</span>
                                                                                        </Typography>
                                                                                        <VisuallyHiddenInput type="file" accept=".csv, .xls, .xlsx" onChange={(e) => uploadLinkFile(e)} />
                                                                                    </>
                                                                                )
                                                                                }
                                                                            </Grid>
                                                                            <Typography sx={{ mt: 2, fontSize: "12px" }}>
                                                                                <span style={{ fontWeight: 600, fontSize: "12px" }}>Supported file types:</span> .csv .xls .xlsx
                                                                            </Typography>
                                                                            {csvxlsxFileUploadError && (
                                                                                <Typography sx={{ mt: 2, fontSize: "12px" }}>
                                                                                    <span style={{ fontWeight: 600, fontSize: "18px", color: "red" }}> Upload file </span>
                                                                                </Typography>
                                                                            )}

                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </>
                                                    }

                                                </Grid>
                                                <Divider sx={{ border: "1px solid #ccc" }} />
                                                <Grid sx={{ p: 2 }} container spacing={0}>
                                                    {" "}
                                                    <Grid xs={12} md={12}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "none",
                                                                background: "none",
                                                                display: "flex",
                                                                justifyContent: isMobile ? "center" : "end",
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    color: "var(--text-color-black)",
                                                                    boxShadow: "unset",
                                                                    borderRadius: "5px",
                                                                    border: "unset",
                                                                    padding: "11px 28px ",
                                                                    cursor: "pointer",
                                                                    backgroundColor: "var(--button-bg-color-green)",
                                                                    "&:hover": {
                                                                        backgroundColor: "var(--button-hover-bg-color-LightGreen)",
                                                                    },
                                                                }}
                                                                variant="contained"
                                                                onClick={fileLoader ? "" : sebmitUploadedFile}
                                                            >
                                                                <Typography style={{ fontSize: "18px", color: "var(--text-color-OffWhite)" }} variant="body1" fontWeight={600}>
                                                                    {fileLoader ? "Uploading..." : "Invite candidate"}
                                                                </Typography>
                                                            </Button>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </CustomTabPanel>
                                            {formFinalData.public_url && formFinalData.invite_user_url && (
                                                <CustomTabPanel value={value} index={2} className="tabs_padding">
                                                    <Grid sx={{ my: 3, overflow: "scroll" }}>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontSize: "14px",
                                                                my: 2,
                                                            }}
                                                        >
                                                            Share a public link on your job post to invite candidates to the assessment. This link captures their name and email for tracking.
                                                        </Typography>

                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <Grid container spacing={0}>
                                                                <Grid xs={12} md={4}>
                                                                    <Item
                                                                        sx={{
                                                                            bgcolor: "#f6f6f6",
                                                                            display: "flex",
                                                                            boxShadow: "none",
                                                                            py: 2,
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <Grid>
                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 600,
                                                                                }}
                                                                            >
                                                                                General public link
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid sx={{ ml: "auto" }}>
                                                                            <KeyboardArrowRightIcon />
                                                                        </Grid>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid xs={12} md={8}>
                                                                    <Item
                                                                        sx={{
                                                                            bgcolor: "#f6f6f6",
                                                                            boxShadow: "none",
                                                                            padding: "16px 24px",
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "end",
                                                                            }}
                                                                            xs={12}
                                                                            md={12}
                                                                        >
                                                                            <Typography sx={{ color: "gray" }}>Active source</Typography>
                                                                            <Switch
                                                                                {...label}
                                                                                checked={activeSourceLink}
                                                                                onChange={handleSwitchChange}
                                                                                sx={{
                                                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                                                        color: "var(--text-color-Green)",
                                                                                    },
                                                                                }}
                                                                            />
                                                                            {callLoader && <Loader />}
                                                                        </Grid>
                                                                        <Grid container spacing={0} sx={{ my: 3 }}>
                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 700,
                                                                                    mb: 2,
                                                                                    textTransform: "uppercase",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                URL
                                                                            </Typography>
                                                                            <Grid xs={12} md={9}>
                                                                                <Box className={activeSourceLink ? "" : "Mui-disabled"}
                                                                                    sx={{
                                                                                        width: 500,
                                                                                        maxWidth: "100%",
                                                                                        "& .MuiInputBase-root": {
                                                                                            maxHeight: 42,
                                                                                        },
                                                                                        "& .MuiFormLabel-root": {
                                                                                            position: "absolute",
                                                                                            top: "-5px",
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <TextField sx={{ cursor: 'not-allowed' }}
                                                                                        style={{
                                                                                            pointerEvents: activeSourceLink ? "" : "none"
                                                                                        }}
                                                                                        disabled
                                                                                        className={activeSourceLink ? "" : "Mui-disabled"}
                                                                                        fullWidth
                                                                                        label="Link"
                                                                                        id="fullWidth"
                                                                                        onCopy={handleCopy}
                                                                                        value={talentRoutePath + "/assessment/add-user/public-assessment-invite/" + formFinalData.public_url + '/' + formFinalData.invite_user_url}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid sx={{ pl: 2, textAlign: isMobile ? "center" : " ", mt: isMobile ? "10px" : " " }} xs={12} md={3}>
                                                                                <Button
                                                                                    className={activeSourceLink ? "" : "Mui-disabled"}
                                                                                    style={{
                                                                                        color: "var(--text-color-black)",
                                                                                        boxShadow: "unset",
                                                                                        borderRadius: "5px",
                                                                                        border: "1px solid var(--text-color-black)",
                                                                                        cursor: "pointer",
                                                                                        height: "43px",
                                                                                    }}
                                                                                    variant="body1"
                                                                                    onClick={copyFun}
                                                                                >
                                                                                    <CopyAllIcon />
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            color: "var(--text-color-black)",
                                                                                            fontWeight: 500,
                                                                                        }}
                                                                                    >
                                                                                        Copy Link
                                                                                    </Typography>
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mb: 2,
                                                                                textTransform: "uppercase",
                                                                            }}
                                                                        >
                                                                            Copy and add this text to your job post
                                                                        </Typography>

                                                                        <Grid
                                                                            className={activeSourceLink ? "" : "prevent-select"}
                                                                            sx={{
                                                                                bgcolor: "white",
                                                                                border: "1px solid var(--text-color-black)",
                                                                                p: 2,
                                                                            }}
                                                                            ref={paragraphRef}
                                                                        >
                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                }}
                                                                            >
                                                                                We want to ensure that all applicants have a fair and equal chance, so we’re using a TestNHire assessment{" "}
                                                                                {activeSourceLink ? (
                                                                                    <a href="https://www.testnhire.com" style={{ color: "var(--text-color-Green)" }}>
                                                                                        (https://www.testnhire.com)
                                                                                    </a>
                                                                                ) : (
                                                                                    <span> https://www.testnhire.com </span>
                                                                                )}
                                                                                . This minimizes unconscious bias in our hiring process. Successful candidates will be invited to a job interview.
                                                                            </Typography>

                                                                            <Grid sx={{ display: LinkText }}>
                                                                                <Grid sx={{}}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            my: 2,
                                                                                        }}
                                                                                    >
                                                                                        Ready to join our team? Start by clicking the link below.
                                                                                        <br />
                                                                                        {activeSourceLink ? (
                                                                                            <a
                                                                                                href={talentRoutePath + "/assessment/add-user/public-assessment-invite/" + formFinalData.public_url + '/' + formFinalData.invite_user_url}
                                                                                                style={{ color: "var(--text-color-Green)", overflowWrap: "anywhere" }}
                                                                                            >
                                                                                                {talentRoutePath + "/assessment/add-user/public-assessment-invite/" + formFinalData.public_url + '/' + formFinalData.invite_user_url}
                                                                                            </a>
                                                                                        ) : (
                                                                                            <span style={{ overflowWrap: "anywhere" }}>{talentRoutePath + "/assessment/add-user/public-assessment-invite/" + formFinalData.public_url + '/' + formFinalData.invite_user_url} </span>
                                                                                        )}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                {activeSourceLink ? (
                                                                                    <Grid>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                                my: 2,
                                                                                                textDecoration: "underline",
                                                                                                textWrap: "nowrap",
                                                                                                mt: 5,
                                                                                                cursor: "pointer",
                                                                                                ml: 2,
                                                                                            }}
                                                                                            onClick={copyFun}
                                                                                        >
                                                                                            Copy invitation
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                ) : (
                                                                                    " "
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </CustomTabPanel>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        );
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const downloadCsvTemplate = async () => {
        const link = document.createElement("a");
        link.href = donwloadcondidateTemplateCsv;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadXlsxTemplate = async () => {
        const link = document.createElement("a");
        link.href = donwloadcondidateTemplateXlsx;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handelInviteTeamToAssessment = async () => {
        if (canInviteCandidate === 0) {
            setIsConfirmBox(true);
            return false;
        }
        setIsLoadinSendInvitation(true);
        const data = { user_details: selectedTeamInvite, assessment_id: assessmentId };

        await APIProvider(condidateInvite, data, "post").then((response) => {
            if (response.status === 200) {
                getSingleAssessmentCandidateData();
                getTeamMembersData();
                setSelectedTeamInvite([]);
                setAlertNotification({
                    status: true,
                    type: "success",
                    message: "Invited!",
                });
            } else if (response?.status === 201) {
                setAlertNotification({
                    status: true,
                    type: "error",
                    message: "Credits Exhausted, Please Buy Credits",
                });
            }
        }).then(() => {
            setTimeout(() => {
                setIsLoadinSendInvitation(false);
            }, 1000)
        });
    };

    const handelInviteSelected = (e) => {
        const email = e.target.value;
        if (e.target.checked) {
            setSelectedTeamInvite([
                ...selectedTeamInvite,
                {
                    "email": email
                }
            ])
        } else {
            let invite = selectedTeamInvite.filter((row) => row.email !== email);
            setSelectedTeamInvite(invite)
        }
    }

    const invitableMembers = useMemo(() => {
        let users = manageAccessUser.filter((row) => !row.is_sent).map((row) => ({ email: row?.email }));
        return users || [];
    }, [manageAccessUser])

    const handelInviteAllTeam = (e) => {
        if (e.target.checked) {
            setSelectedTeamInvite(invitableMembers);
        } else {
            setSelectedTeamInvite([]);
        }
    }
    const onConfirmClose = () => {
        setIsConfirmBox(false);
    }
    const handleConfirm = () => {
        navigate("/customer/create-assessment?id=" + assessmentId);
    }
    const ConfirmEditModal = ({ open }) => {
        const style = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
        };
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={onConfirmClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                    className="RemoveOutLine"
                    sx={{ backdropFilter: "blur(4px)" }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography
                                sx={{ fontSize: "20px", fontWeight: 600 }}
                                variant="h6"
                                component="h2"
                            >
                                Assessment Edit Required
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ mt: 2, fontWeight: 500, fontSize: "12px" }}
                            >
                                Before inviting candidate, please include at least one skill test or custom question.
                            </Typography>
                            <Grid sx={{ mt: 3 }} container spacing={0}>
                                <Grid xs={12} md={12}>
                                    {" "}
                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "end",
                                            }}
                                            xs={12}
                                            md={12}
                                        >
                                            <Button
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "none",
                                                    boxShadow: "none",
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "#e0e0e0",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                                onClick={onConfirmClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                sx={{
                                                    bgcolor: "var(--text-color-Green)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    borderRadius: "4px",
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                    },
                                                }}
                                                variant="contained"
                                                onClick={handleConfirm}
                                            >
                                                Edit Assessment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        );
    }

    return (
        <Box sx={{ bgcolor: "#EDEDED" }}>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid xs={12} md={12}>
                        {isConfirmBox && <ConfirmEditModal open={isConfirmBox} />}
                        {isModalOpen && <Modal2 onRequestClose={toggleModal} />}
                        {canInviteCandidate === 0 && (
                            <Grid xs={12} md={12}>
                                <Item
                                    sx={{
                                        boxShadow: "unset",
                                        my: ContentHeight,
                                        py: 1,
                                        borderRadius: "8px",
                                        bgcolor: "#cb7b7a",
                                        color: "#fff0e1",
                                        fontSize: "20px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Grid>
                                        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <span>
                                                <WarningAmberIcon sx={{ mr: "5px", fontSize: "20px" }} />
                                            </span>
                                            <span style={{ fontSize: "16px" }}>This Assessment have not any questions</span>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                        )}
                        <Item
                            sx={{
                                boxShadow: "unset",
                                background: "none",
                                mt: isMobile ? "16px" : "40px",
                                p: 0,
                                borderRadius: "8px",
                            }}
                        >
                            <Accordion
                                sx={{
                                    boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",

                                    py: 2,
                                    px: 3,
                                }}
                                style={{ borderRadius: "8px" }}
                                expanded={expanded}
                                onChange={handleChanges}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                display: "flex",

                                                alignItems: "center",
                                            }}
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 400 }}>
                                                You can try the assessment yourself or invite your team members to trial it.
                                            </Typography>
                                            <BootstrapTooltip title="You and your team can modify the assessment and test it before inviting candidates to participate.">
                                                <HelpOutlineOutlinedIcon sx={{ ml: 1 }} />
                                            </BootstrapTooltip>
                                        </Grid>

                                        <Grid
                                            sx={{
                                                display: "flex",
                                                justifyContent: Formposition,

                                                marginTop: ButtonMarginTop,
                                            }}
                                            xs={12}
                                            md={6}
                                        >
                                            <Item
                                                sx={{
                                                    boxShadow: "none",
                                                    gap: "10px",
                                                    background: "none",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        boxShadow: "unset",
                                                        borderRadius: "4px",
                                                        border: "unset",
                                                        padding: "15px 20px ",
                                                        cursor: "pointer",
                                                        backgroundColor: "#ededed",
                                                        mr: 2,
                                                        "&:hover": {
                                                            backgroundColor: "#e0e0e0",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "var(--text-color-black)",
                                                            textTransform: "none",
                                                        }}
                                                        variant="body1"
                                                        fontWeight={600}
                                                    >
                                                        Try yourself
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        boxShadow: "unset",
                                                        border: "unset",
                                                        padding: "3px 3px ",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                        "&:hover": {
                                                            bgcolor: "white",
                                                        },
                                                    }}
                                                >
                                                    <AccordionSummary className="AccordionSummaryHeight" sx={{
                                                        minHeight: "50px", margin: "0px",
                                                        borderRadius: "4px", "& .MuiAccordionSummary-root .Mui-expanded": {
                                                            minHeight: "50px", margin: "0px",
                                                        }, "&:hover": {
                                                            bgcolor: "#ededed",
                                                        },
                                                    }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "var(--text-color-black)",
                                                                textTransform: "capitalize",
                                                            }}
                                                            variant="body1"
                                                            fontWeight={500}
                                                        >
                                                            Invite Team Member
                                                        </Typography>
                                                    </AccordionSummary>
                                                </Button>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Grid sx={{alignItems:"center"}} container spacing={0}>
                                            <Grid xs={12} lg={4} md={12}>
                                                <Typography variant="h6" fontSize={"16px"} fontWeight={600} marginTop={"16px"}>
                                                    Team Members
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    justifyContent: Formposition,
                                                }}
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                sm={6}
                                            >
                                                <Box>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            value={searchTeamMemberValue}
                                                            sx={{
                                                                bgcolor: "white",
                                                                borderRadius: "10px",
                                                                width: "100%",
                                                                mt: 2,
                                                            }}
                                                            label="Search"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <SearchIcon
                                                                            style={{
                                                                                color: "rgba(0, 0, 0, 0.54)",
                                                                            }}
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={searchTeamMemberHandle}
                                                        />
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                }}
                                                xs={12}
                                                lg={4}
                                                md={6}
                                                sm={6}
                                            >
                                                <Select
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    input={<BootstrapInput />}
                                                    value={roleSelectedOption}
                                                    onChange={roleHandleChange}
                                                    displayEmpty
                                                    IconComponent={ExpandMoreIcon}
                                                    inputProps={{
                                                        "aria-label": "Select option",
                                                    }}
                                                    sx={{
                                                        width: SelectWidth,
                                                        bgcolor: "white",
                                                        mt: 2,
                                                        pl:isSmMobile?"":"10px"
                                                    }}
                                                >
                                                    <MenuItem value="allRole">All Roles</MenuItem>

                                                    {webAllRoleData?.map((val, index) => (
                                                        <MenuItem key={index} value={val.id}>
                                                            {val.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid sx={{ my: 5 }}>
                                        <TableContainer sx={{ bgcolor: "#fbfbfb", boxShadow: "none", borderRadius: "0px",border: "1px solid #e0e0e0" }} component={Paper}>
                                            <Table className="condidate_table1" aria-label="simple table" sx={{  }}>
                                                <TableHead>
                                                    <TableRow key="1">
                                                        <TableCell align="center" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
                                                            <Checkbox disabled={invitableMembers?.length == 0 ? true : false} checked={invitableMembers?.length === selectedTeamInvite?.length && invitableMembers?.length !== 0 ? true : false} inputProps={{ 'aria-label': 'Select All' }} onClick={handelInviteAllTeam} />
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
                                                            Name
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
                                                            Role
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
                                                            Invitations
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {manageAccessUser.map((item, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell align="center">
                                                                <Checkbox disabled={item?.is_sent} checked={selectedTeamInvite.map((row) => row.email).includes(item?.email)} onClick={handelInviteSelected} value={item?.email} inputProps={{ 'aria-label': "member-select" }} />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <strong>{item?.first_name} {item?.last_name}</strong> <br />
                                                                {item?.email}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button variant="contained" style={{
                                                                    color: "var(--text-color-OffWhite)", backgroundColor:
                                                                        item?.role?.name === "Owner"
                                                                            ? "rgb(39 98 87)"
                                                                            : item?.role?.name === "Super Admin"
                                                                                ? "var(--text-color-Green)"
                                                                                : item?.role?.name === "Recruiter"
                                                                                    ? "#cca45f"
                                                                                    : item?.role?.name === "Hiring Manager"
                                                                                        ? "rgb(159 137 137)"
                                                                                        : item?.role?.name === "Admin"
                                                                                            ? "#cb7b7a"
                                                                                            : "#cca45f",
                                                                }}>
                                                                    {item?.role?.name}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    item.is_sent ? (
                                                                        <Grid sx={{ display: "flex", justifyContent:"center", gap:"5px" }}>
                                                                            <CheckIcon /> <Typography>Sent</Typography>
                                                                        </Grid>
                                                                    ) : (
                                                                        <>
                                                                        </>
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                }}
                                                xs={5}
                                                md={6}
                                                sm={6}
                                                lg={6}

                                            >
                                                <AllowedAccess permissions="add_team">
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 600,
                                                            textTransform: "none",
                                                            fontSize: "16px",
                                                            display: "contents",
                                                            color: "var(--text-color-Green)",
                                                            cursor: "pointer",
                                                        }}
                                                        variant="body1"
                                                        onClick={addMemberFun}
                                                    >
                                                        {
                                                            <AddCircleOutlineIcon
                                                                sx={{
                                                                    color: "var(--text-color-Green)",
                                                                    fontSize: "27px",
                                                                }}
                                                            ></AddCircleOutlineIcon>
                                                        }
                                                        Add team member
                                                    </Typography>
                                                </AllowedAccess>
                                            </Grid>


                                            <Grid
                                                style={{ 
                                                    textAlign: "end",
                                                }}
                                                xs={7}
                                                md={6}
                                                sm={6}
                                                lg={6}

                                            >
                                                <>
                                                    <AllowedAccess permissions="invite_my_assessment">
                                                        <Button
                                                            sx={{
                                                                bgcolor: "var(--button-bg-color-green)",
                                                                boxShadow: "none",
                                                                borderRadius: "5px",
                                                                padding: isMobile ? "16px":"15px 30px",
                                                                "&:hover": {
                                                                    bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                                    boxShadow: "none",
                                                                },
                                                                "&:disabled": {
                                                                    color: "var(--text-color-black)",
                                                                },
                                                            }}
                                                            variant="contained"
                                                            onClick={handelInviteTeamToAssessment}
                                                            disabled={selectedTeamInvite.length > 0 && !isLoadinSendInvitation ? false : true}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    display: "contents", 
                                                                }}
                                                                variant="body1"
                                                            >
                                                                <SendIcon
                                                                    sx={{
                                                                        fontSize: "20px",
                                                                        mr: 1,
                                                                    }}
                                                                ></SendIcon>

                                                                Send Invitation
                                                            </Typography>
                                                            {
                                                                isLoadinSendInvitation ? <Puff
                                                                    type="ThreeDots"
                                                                    color="#000000"
                                                                    height={20}
                                                                    width={20}
                                                                    wrapperStyle={{
                                                                        marginLeft: "5px",
                                                                    }}
                                                                /> : ""}
                                                        </Button>
                                                    </AllowedAccess>
                                                </>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Item>
                    </Grid>
                </Grid>
                {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
            </Container>
        </Box>
    );
}

export default TeamMember;
