import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography, Snackbar } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation, useParams } from "react-router-dom";
import Logo from "../../../../../../Assests/Images/LOGO.png";
import axios from "axios";
import { getHeaders, getMcqQuestion } from "../../../../../../Constants/ConstantsUrl";

const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    background: "unset",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PreviewMcqQuestions() {
    const handleCloseTab = () => {
        window.close();
    };
    const location = useLocation();
    const { main_question_id } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const companyColorCode =
        searchParams.get("companyColorCode") === "1"
            ? "var(--button-bg-color-green)"
            : searchParams.get("companyColorCode") ||
            "var(--button-bg-color-green)";
    const [selectedValue, setSelectedValue] = useState("");
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [questionData, setQuestionData] = useState({
        id: 0,
        question: null,
        options: []
    });


    useEffect(() => {
        if (main_question_id > 0) {
            fetchMainCustomQuestion();
        }
    }, []);

    const fetchMainCustomQuestion = async () => {
        const headers = getHeaders();
        const data = {
            'custom_main_question_id': main_question_id
        }
        try {
            await axios.post(getMcqQuestion, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data.data.custom_question;
                    if (response.status === 200) {
                        setQuestionData({
                            id: res.id,
                            question: res.question_title,
                            options: res.question_data.mcq_options
                        })
                    }
                })
        } catch (error) {
            if (error?.response.data.status === 404) {
                setErrorMsg(error?.response.data.data.message);
                setNotificationOpen(true);
                setTimeout(function () {
                    handleCloseTab();
                }, 5000);
            }
        }
    }

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };
    const handleChange = (event) => {
        if (event.target.value === selectedValue) {
            setSelectedValue("");
        } else {
            setSelectedValue(event.target.value);
        }
    };

    const getContrastText = (background) => {
        const hexColor = background.replace("#", "");
        const r = parseInt(hexColor.substr(0, 2), 16);
        const g = parseInt(hexColor.substr(2, 2), 16);
        const b = parseInt(hexColor.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness >= 128 ? "black" : "white";
    };
    const BpIcon = styled("span")(({ theme }) => ({
        marginRight: "10px",
        border: "2px solid #ededed",
        borderRadius: "50%",
        width: 30,
        height: 30,
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        ".Mui-focusVisible &": {
            outline: "2px auto red",
            outlineOffset: 2,
        },
        "& .Mui-checked": {
            border: "2px solid red",
        },
        "input:hover ~ &": {
            border: `2px solid ${companyColorCode}`,
            transition: " 0.5s all ",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background:
                theme.palette.mode === "dark"
                    ? "rgba(57,75,89,.5)"
                    : "rgba(206,217,224,.5)",
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: { companyColorCode },
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&::before": {
            display: "block",
            width: 16,
            height: 16,
            // backgroundImage: `radial-gradient(#fff,#fff 28%,transparent 32%)`,
            backgroundImage: `radial-gradient(${getContrastText(
                companyColorCode
            )},${getContrastText(companyColorCode)} 28%,transparent 32%)`,
            content: '""',
            marginTop: "5px",
            marginLeft: "5px",
        },

        "input:hover ~ &": {
            backgroundColor: companyColorCode,
            // border:"2px solid red",
        },

        "input:checked ~ &": {
            borderColor: getContrastText(companyColorCode), // Change border color when checked
        },
    });
    function BpRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid>
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="Company logo"
                    />
                </Grid>
                <Grid sx={{ padding: "20px" }}>
                    <Button
                        onClick={handleCloseTab}
                        sx={{
                            ...btnStyle,
                            bgcolor: "var(--button-bg-color-assessment)",
                            minWidth: "48px",
                            minHeight: "48px",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            "&:hover": {
                                bgcolor:
                                    "var(--button-hover-bg-color-LightGreen)",
                            },
                        }}
                        variant="contained"
                    >
                        {'Go back to tests'}

                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    height: "90vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Container
                    maxWidth="lg"
                    style={{
                        background: "white",
                        borderRadius: "4px",
                        boxShadow:
                            "-3 6px 7px -4px #8888880f,0 11px 15px 1px #8888880b,0 4px 20px 3px #88888809",
                        padding: "0px",
                    }}
                >
                    <Box sx={{ flexGrow: 1, p: 2 }}>
                        <Grid container spacing={0}>
                            <Grid sx={{ p: 5 }} xs={12} md={6}>
                                <Item>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: "16px",
                                            fontStyle: "italic",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Question
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}
                                    >
                                        {questionData.question}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid sx={{ p: 5 }} xs={12} md={6}>
                                <Item>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: 500,
                                            textTransform: "uppercase",
                                            mb: "20px",
                                        }}
                                    >
                                        Select only one
                                    </Typography>

                                    <FormControl sx={{
                                        width: "100%"
                                    }}>
                                        <RadioGroup
                                            defaultValue="female"
                                            aria-labelledby="demo-customized-radios"
                                            name="customized-radios"
                                            value={selectedValue}
                                            onClick={handleChange}
                                            className="checkBoxBorder"
                                        >
                                            {questionData.id > 0 && questionData?.options.map((option, index) => (
                                                <FormControlLabel
                                                    sx={{
                                                        boxShadow:
                                                            "0 2px 8px 1px #a8a8a833",
                                                        borderRadius: "6px",
                                                        padding: "18px",
                                                        margin: "0px 0px 32px 0px",
                                                        cursor: "pointer",
                                                        border: "2px solid #ededed",
                                                        transition:
                                                            " 0.5s ease-in-out",
                                                        backgroundColor:
                                                            selectedValue ===
                                                                option.id
                                                                ? companyColorCode
                                                                : "white",
                                                        color:
                                                            selectedValue ===
                                                                option.id
                                                                ? getContrastText(
                                                                    companyColorCode
                                                                )
                                                                : "var(--text-color-black)",
                                                        "&:hover": {
                                                            border: `2px solid ${companyColorCode}`,
                                                            transition:
                                                                " 0.5s ease-in-out",
                                                        },
                                                    }}
                                                    key={index}
                                                    value={option.id}
                                                    control={<BpRadio />}
                                                    label={
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontSize:
                                                                    "16px",
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            <span dangerouslySetInnerHTML={{ __html: option.options }}></span>
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={handleNotificationClose}
                    severity="error"
                    className="error_msg"
                    sx={{
                        width: "500px",
                        justifyContent: "center",
                        background: "#cb7b7a",
                        color: "var(--text-color-OffWhite)",
                    }}
                >
                    {errorMsg}
                </Alert>
            </Snackbar>
        </Box>
    );
}
