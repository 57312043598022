import { Box, Button, Container, Divider, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Navbar from "../../../Layouts/Index";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import StarIcon from "@mui/icons-material/Star";
import CandidateDetailsTable from "../../MyAssessment/Invite/CandidateDetails/CustomDetailsTable";
import QualifyingDetailsTable from "../../MyAssessment/Invite/CandidateDetails/QualifyingDetailsTable";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputBase from "@mui/material/InputBase";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import TableForm from "../../MyAssessment/Invite/CandidateTable/TableForm";
import Loader from "../../../Loader";
import VideoStream from "./../../MyAssessment/Invite/CandidateDetails/VideoStream";
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AntiCheatingMoniter from '../../../../Common/AntiCheatingMoniter';
import {
    CandidateUpdateNote,
    CandidateUpdatePrivateRating,
    CandidateUpdateRating,
    getHeaders,
    getHiringStages,
    setCondidateHireStage,
    candidateAllReportGet,
    AssessmentGetForInvite,
    condidateInvite,
    candidateResultPDFDownload,
} from "../../../../../Constants/ConstantsUrl";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import dayjs from "dayjs";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import Alerts from "../../../../Common/Alerts";
import CircularProgress from "@mui/material/CircularProgress";
import VideoPopup from "../../MyAssessment/Invite/CandidateDetails/VideoPlayer/VideoPopup";
dayjs.extend(advancedFormat);

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "var(--border-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "2px solid #efefef",
        fontSize: 16,
        padding: "16.5px 0px 16.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 4,
            borderColor: "var(--border-color-Green)",
            border: "2px solid var(--border-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--border-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        // minWidth: "245px",
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#c8d7de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#c8d7de",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));
const btnStyle = {
    color: "var(--text-color-OffWhite)",
    boxShadow: "unset",
    borderRadius: "8px",
};

function Index() {
    const outerTheme = useTheme();
    const location = useLocation();
    const idDatas = location.state;
    const navigate = useNavigate();
    const isLaptop = useMediaQuery("(max-width:1199px)");
    const isMobile = useMediaQuery("(max-width:960px)");
    const isSmMobile = useMediaQuery("(max-width:899px)");
    const isExtraSmMobile = useMediaQuery("(max-width:599px)");
    const BtnPosition = isSmMobile ? "start" : "end";
    const FormWidth = isMobile ? "100%" : "48.5%";
    const MiddleBoxPadding = isMobile ? "0px" : "27px";
    const contentDisplay = isMobile ? "flex" : "flex";
    const SmMobileMargin = isMobile ? "50px" : "";
    const [selectedOption, setSelectedOption] = useState("");
    const [data, setData] = useState([]);
    const [hiringStages, setHiringStages] = useState(null);
    const [notData, setNodata] = useState([]);
    const [curretnUserEmail, setCurretnUserEmail] = useState();
    const [openLoader, setOpenLoader] = React.useState(false);
    const [starLoader, setStarLoader] = React.useState(false);
    const [subMetaStarLoader, setSubMetaStarLoader] = React.useState(false);
    const [subMetaStarIndex, setSubMetaStarIndex] = React.useState(null);
    const [rating, setRating] = useState([""]);
    const [customQuestionRating, setCustomQuestionRating] = useState([]);
    const [assessmentDisable, setAssessmentDisable] = useState(true);
    const [openHireLoader, setOpenHireLoader] = React.useState({
        loader: false,
        index: null,
    });
    const [privateNoteLoader, setPrivateNoteLoader] = useState({
        loader: false,
        index: null,
    });
    const [assessmentList, setAssessmentList] = useState([]);
    const [alertNotification, setAlertNotification] = useState([]);

    const [private_note, setPrivateNote] = useState([]);
    const [customHireStage, setCustomHireStage] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [downloadingReport, setDownloadingReport] = React.useState(false);
    const [rejectCandidate, setRejectCandidate] = React.useState(false);
    const [currentHiringStage, setCurrentHiringStage] = React.useState();
    const [openAntiCheating, setOpenAntiCheating] = React.useState(false);
    const handleOpenAntiCheating = () => setOpenAntiCheating(true); const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
    const [currentMarkers, setCurrentMarkers] = useState(null);
    const [currentPlayerTime, setCurrentPlayerTime] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleChange = (event) => {
        setAssessmentDisable(false);
        setSelectedOption(event.target.value);
    };

    const updateData = (resultMetaId, resultSubMetaId, resultId, NewRating) => {
        for (const dataArray of data.assessment) {
            if (dataArray.custom_question && dataArray?.results?.result_id === resultId) { // add question mark for check nul value by Narendra
                for (const key in dataArray.custom_question) {
                    const customQuestion = dataArray.custom_question[key];
                    if (
                        customQuestion.result_sub_meta_details[0] &&
                        customQuestion.result_sub_meta_details[0]?.result_meta_id === resultMetaId &&
                        customQuestion.result_sub_meta_details[0]?.result_sub_meta_id === resultSubMetaId
                    ) {
                        customQuestion.result.rating = NewRating;
                        return true;
                    }
                }
            }
        }
        return false; // Indicate that the comment was not updated
    };
    const handleStarClick = async (selectedRating, type, index, result_metas_id, result_sub_meta_id, result_id, assessment_id) => {
        if (type === "yourRating") {
            //setStarLoader(true);
            setRating((prevItems) => {
                const newItems = [...prevItems]; // Create a copy of the original array
                newItems[index] = selectedRating; // Update the value at the specified index
                return newItems; // Return the new array
            });
            const postdata = {
                result_id: result_id,
                candidate_id: parseInt(idDatas.id),
                assessment_id: assessment_id,
                rating: selectedRating,
            };
            const headers = getHeaders();
            await axios.post(CandidateUpdatePrivateRating, postdata, { headers });
            //setStarLoader(false);
        } else if (type === "custom") {
            //setSubMetaStarLoader(true);
            setSubMetaStarIndex(index);
            updateData(result_metas_id, result_sub_meta_id, result_id, selectedRating);
            const postdata = {
                result_metas_id: result_metas_id,
                result_sub_meta_id: result_sub_meta_id,
                result_id: result_id,
                rating: selectedRating,
            };
            const headers = getHeaders();
            await axios.post(CandidateUpdateRating, postdata, { headers });
            //setSubMetaStarLoader(false);
            setSubMetaStarIndex(null);
        }
        // await axios.post(CandidateUpdateRating , allData)
    };

    const handleSubmit = async (event, keys, assessment_id, invited_id) => {
        setPrivateNoteLoader({
            loader: true,
            index: keys,
        });
        setPrivateNote({ ...private_note, [event.target.name]: event.target.value });
        const allData = {
            assessment_id: assessment_id,
            candidate_id: parseInt(idDatas.id),
            invitation_id: invited_id,
            private_note: event.target.value,
        };
        try {
            const headers = getHeaders();
            await axios.post(CandidateUpdateNote, allData, { headers }).then((response) => {
                console.log(private_note, "hihihihiih");
            });
            setPrivateNoteLoader({
                loader: false,
                index: null,
            });
        } catch (error) { }
    };

    useEffect(() => {
        const userEmail = JSON.parse(localStorage.getItem("user"));
        setCurretnUserEmail(userEmail["email"]);
        getHiringStagesFun();
        fetch();
        getAssessmentList();
    }, []);

    const getAssessmentList = async () => {
        const headers = getHeaders();

        try {
            await axios
                .get(AssessmentGetForInvite + idDatas.id, { headers })
                .then((resposne) => resposne)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setAssessmentList(Object.values(res.data));
                    }
                });
        } catch (error) {
            console.log("errororrorro", error);
        }
    };
    const handelCopyCandidateLink = (email) => {
        let link = email;
        navigator.clipboard.writeText(link);
        setAlertNotification({
            status: true,
            type: "success",
            message: "Email copied!",
        });
    };

    const fetch = async () => {
        setOpenLoader(true);
        const headers = getHeaders();
        const sendData = {
            candidate_id: idDatas.id,
        };
        try {
            await axios
                .post(candidateAllReportGet, sendData, { headers })
                .then((resposne) => resposne)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        if (res.data.message) {
                            setNodata(res.data.message);
                        } else {
                            setData(res.data);
                            setCurrentHiringStage(res?.data?.assessment.hiring_stage);
                        }
                    }
                    setOpenLoader(false);
                });
        } catch (error) {
            setOpenLoader(false);
            console.log("errororrorro", error);
        }
    };

    const handelInvite = async () => {
        setOpenLoader(true);
        const dataSended = { user_details: [data.candidate_details], assessment_id: selectedOption };
        const headers = getHeaders();
        await axios
            .post(condidateInvite, dataSended, { headers })
            .then((response) => response)
            .then((response) => {
                const res = response.data;
                if (res.status === 200) {
                    setAssessmentList((prev) => Object.values(prev).filter((value) => value.id !== selectedOption));
                    setSelectedOption("");
                }
            });
        setOpenLoader(false);
    };

    const getHiringStagesFun = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getHiringStages, { headers })
                .then((response) => response)
                .then((response) => {
                    const res_data = response.data;
                    if (res_data.status === 200) {
                        setHiringStages(res_data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const handleChangeHireStage = async (e, assessmentId, keys) => {
        setOpenHireLoader({
            loader: true,
            index: keys,
        });
        setCustomHireStage({ ...customHireStage, [e.target.name]: e.target.value });
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: parseInt(idDatas.id),
                assessment_id: assessmentId,
                hiring_stage_id: e.target.value,
            };
            //data.hiring_stage = e.target.value;
            await axios
                .post(setCondidateHireStage, formsData, { headers })
                .then((response) => response)
                .then((response) => {
                    setOpenHireLoader({
                        loader: false,
                        index: null,
                    });
                });
        } catch (error) {
            console.log(error);
            setOpenHireLoader({
                loader: false,
                index: null,
            });
        }
    };

    const clickBackButton = () => {
        navigate(-1);
    };
    const handelClickReject = async (rowdata, key) => {
        setRejectCandidate(true);
        let hiringStageData = currentHiringStage; //data.assessment.hiring_stage;
        let newHiringStage =
            hiringStageData === 12
                ? {
                    newHiringStageId: 1,
                    newHiringStageMessage: "Not Yet Evaluated",
                }
                : {
                    newHiringStageId: 12,
                    newHiringStageMessage: "Rejected",
                };
        setCurrentHiringStage(newHiringStage.newHiringStageId);
        const newState = data;
        newState["assessment"][key].hiring_stage = newHiringStage.newHiringStageId;
        setData({ ...data, newState });
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: idDatas.id,
                assessment_id: rowdata.assessment_details.assessment_id,
                hiring_stage_id: newHiringStage.newHiringStageId,
            };
            await axios
                .post(setCondidateHireStage, formsData, { headers })
                .then((response) => response)
                .then((response) => {
                    data.hiring_stage = 12;
                    setOpenLoader(false);
                    setAlertNotification({
                        status: true,
                        type: "success",
                        message: "Reject succesfully!",
                    });
                });
            setRejectCandidate(false);
        } catch (error) {
            console.log(error);
            setOpenLoader(false);
            setRejectCandidate(false);
        }
    };

    const printDocument = async (rowdata, key) => {
        setDownloadingReport(true);
        try {
            const sendData = {
                assessment_id: rowdata.assessment_details.assessment_id,
                candidate_id: data.candidate_details.candidate_id,
                send_mail: 0,
            };
            const headers = getHeaders();
            await axios
                .post(candidateResultPDFDownload, sendData, {
                    headers,
                    responseType: "blob", // Important
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "file.pdf");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error("Error downloading PDF file: ", error);
                });
            setDownloadingReport(false);
        } catch (error) {
            console.log(error);
            setDownloadingReport(false);
        }
    };

    function getVideoPathMarker(cheatingLogs, type) {
        let returnData = [];
        let returnData2 = [];
        if (cheatingLogs !== null) {
            if (type === "video") {
                returnData = cheatingLogs
                    ?.filter((marker) => marker.errorType.includes("faces detected"))
                    .map((marker) => ({
                        id: 1,
                        time: marker.timestamp,
                        color: "red",
                        title: marker.errorType,
                    }));
            } else if (type === "screen") {
                returnData = cheatingLogs
                    ?.filter((marker) => marker.errorType.includes("Cursor") || marker.errorType.includes("Mouse"))
                    .map((marker) => ({
                        id: 1,
                        time: marker.timestamp,
                        color: "var(--button-bg-color-green)",
                        title: marker.errorType,
                    }));
                returnData2 = cheatingLogs
                    ?.filter((marker) => marker.errorType.includes("Tab"))
                    .map((marker) => ({
                        id: 1,
                        time: marker.timestamp,
                        color: "red",
                        title: marker.errorType,
                    }));
            }
        }
        return [...returnData, ...returnData2];
    }
    const handleDummyPlayerClick = (currentUrl, currentMarkers, currentTime) => {
        setCurrentVideoUrl(currentUrl);
        setCurrentMarkers(currentMarkers);
        setCurrentPlayerTime(currentTime);
        setIsVideoPopupOpen(true);
    }
    const handleVideoPopupClose = () => {
        setIsVideoPopupOpen(false);
    }
    const getVideoRecordTime = (cheatingLogs, type) => {
        let returnTime = [];
        if (cheatingLogs !== null) {
            if (type === "video") {
                returnTime = cheatingLogs.filter((marker) => marker.errorType.includes("videoEnd")).map((marker) => marker.timestamp);
            } else if (type === "screen") {
                returnTime = cheatingLogs.filter((marker) => marker.errorType.includes("screenEnd")).map((marker) => marker.timestamp);
            }
        }
        return returnTime;
    };

    return (
        <Box>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Candidate All Assessment - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Navbar />
            <Box sx={{ borderBottom: "1px solid #ddd", marginTop: "90px" }}>
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1, py: 2 }}>
                        <Grid container spacing={0}>
                            <Grid sx={{ display: "flex" }} xs={12} md={5}>
                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                    }}
                                >
                                    {" "}
                                    <Button
                                        sx={{
                                            p: 2,
                                            bgcolor: "#ededed",
                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            minWidth: "48px",
                                            minHeight: "48px",

                                            borderRadius: "4px",
                                            "&:hover": {
                                                bgcolor: "#e0e0e0",
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={clickBackButton}
                                    >
                                        {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}
                                    </Button>
                                </Item>

                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 700 }}>
                                        {data.candidate_details
                                            ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                            " " +
                                            (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                            : ""}
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handelCopyCandidateLink(data?.candidate_details?.email)}
                                        >
                                            {data?.candidate_details?.email}
                                        </Typography>
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid sx={{ display: isMobile ? "none" : " ", }} xs={12} md={6}>
                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        display: isMobile ? "none" : "flex",
                                        justifyContent: BtnPosition,
                                    }}
                                >
                                    {" "}
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        displayEmpty
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        input={<BootstrapInput />}
                                        IconComponent={ExpandMoreIcon}
                                        inputProps={{
                                            "aria-label": "Select option",
                                        }}
                                        sx={{
                                            width: FormWidth,
                                            bgcolor: "white",
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Invite for an assessments
                                        </MenuItem>

                                        {assessmentList.length > 0 && assessmentList.map((value, index) => <MenuItem key={index} sx={{ maxWidth: "352px" }} value={value.id}>{value.name_of_assessment.length > 20 ? value.name_of_assessment.substring(0, 20) + ("...") : value.name_of_assessment}</MenuItem>)}
                                    </Select>
                                </Item>
                            </Grid>

                            <Grid sx={{ display: isMobile ? "none" : " ", }} xs={2} md={1}>
                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        display: "flex",
                                        justifyContent: isMobile ? "end" : "end",
                                    }}
                                >
                                    {" "}
                                    <Button
                                        sx={{
                                            ...btnStyle,
                                            bgcolor: "var(--button-bg-color-green)",
                                            padding: "15px 30px",

                                            textTransform: "capitalize",
                                            borderRadius: "5px",
                                            "&:hover": {
                                                bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                            },
                                        }}
                                        disabled={assessmentDisable}
                                        onClick={handelInvite}
                                        variant="contained"
                                    >
                                        <Typography variant="btn" fontWeight={600} sx={{ fontSize: "14px" }}>
                                            Invite
                                        </Typography>
                                    </Button>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
            {openLoader ? (
                <Loader />
            ) : (
                data?.assessment?.map((rowdata, key) => (
                    <Box key={key} sx={{ bgcolor: "#F5F6F6", py: 5 }}>
                        <Container maxWidth="xl">
                            <VideoPopup isVideoPopupOpen={isVideoPopupOpen} videoUrl={currentVideoUrl} markersPoint={currentMarkers} time={currentPlayerTime} handleVideoPopupClose={handleVideoPopupClose} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <Item
                                            sx={{
                                                bgcolor: "white",
                                                boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <Box sx={{ px: isSmMobile ? " " : "24px", pt: 3, flexGrow: 1 }}>
                                                <Grid container spacing={0}>
                                                    <Grid xs={6} sm={8} md={8}>
                                                        <Item sx={{ boxShadow: "unset", background: "white" }}>
                                                            <Grid
                                                                sx={{
                                                                    display: contentDisplay,
                                                                    gap: "20px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "20px",
                                                                        fontWeight: 700,
                                                                        display: isExtraSmMobile ? "none" : " "
                                                                    }}
                                                                >
                                                                    Assessment
                                                                </Typography>
                                                                <BootstrapTooltip
                                                                    variant="contained"
                                                                    title={rowdata?.assessment_details?.assessment_name}
                                                                >
                                                                    <Typography
                                                                        variant="h3"
                                                                        sx={{
                                                                            fontSize: "20px",
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {(rowdata?.assessment_details?.assessment_name.length > 50) ?
                                                                            `${rowdata?.assessment_details?.assessment_name.substring(0, 50)}...` : rowdata?.assessment_details?.assessment_name +
                                                                            " - " +
                                                                            rowdata?.assessment_details?.work_arrangement +
                                                                            " - " +
                                                                            rowdata?.assessment_details?.job_role ?? "N/A"}
                                                                    </Typography>
                                                                </BootstrapTooltip>
                                                            </Grid>
                                                            <Grid sx={{ display: "flex", mt: 2 }}>
                                                                {[1, 2, 3, 4, 5].map((star) => (
                                                                    <StarIcon
                                                                        key={star}
                                                                        sx={{
                                                                            // color: star <= rowdata?.results?.overall_avg ? "#faaf00" : "#d2d2d2",
                                                                            //set rating according to results rating in data also shows in end of the result
                                                                            color: star <= (rating[key] ? rating[key] : rowdata?.results?.rating) ? "#faaf00" : "#d2d2d2",
                                                                            fontSize: "30px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                ))}
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                    <Grid sx={{ margin: "auto" }} xs={6} sm={4} md={4}>
                                                        {rowdata?.results?.result_id ? (
                                                            <Item
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: isExtraSmMobile ? "end" : "end",
                                                                    boxShadow: "unset",
                                                                    background: "white",

                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                {rowdata?.status!==4 && rowdata?.results ? ( // for hide button download report when status is 4 and no result available ? (
                                                                    downloadingReport ? (
                                                                        <Grid
                                                                            sx={{
                                                                                bgcolor: "#f2f2f2",
                                                                                p: "10px",
                                                                                display: "flex",
                                                                                borderRadius: "5px",
                                                                            }}
                                                                        >
                                                                            <CircularProgress sx={{ color: "#494949" }} size={30} />
                                                                        </Grid>
                                                                    ) : (
                                                                        <BootstrapTooltip title="Download results" onClick={() => printDocument(rowdata, key)}>
                                                                            <CloudDownloadOutlinedIcon
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    padding: "10px",
                                                                                    fontSize: "50px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </BootstrapTooltip>
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {rowdata?.results ? (
                                                                    <BootstrapTooltip title={currentHiringStage !== 1 ? "Reject" : "Rejected"} onClick={(e) => handelClickReject(rowdata, key)}>
                                                                        {rejectCandidate ? (
                                                                            <Grid
                                                                                sx={{
                                                                                    bgcolor: "#f2f2f2",
                                                                                    p: "10px",
                                                                                    display: "flex",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <CircularProgress sx={{ color: "#494949" }} size={30} />
                                                                            </Grid>
                                                                        ) : (
                                                                            <PersonOffOutlinedIcon
                                                                                sx={{
                                                                                    bgcolor:
                                                                                        (customHireStage["hireStage_" + key] ? customHireStage["hireStage_" + key] : rowdata.hiring_stage) === 12
                                                                                            ? "var(--reject-back-color)"
                                                                                            : "#f2f2f2",
                                                                                    color:
                                                                                        (customHireStage["hireStage_" + key] ? customHireStage["hireStage_" + key] : rowdata.hiring_stage) === 12
                                                                                            ? "var(--text-color-OffWhite)"
                                                                                            : "#000",
                                                                                    padding: "10px",
                                                                                    fontSize: "50px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </BootstrapTooltip>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Item>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>






                                            {
                                                isLaptop ?
                                                    <>
                                                        <Box sx={{ px: isMobile ? "0px" : "24px", flexGrow: 1 }}>
                                                            <Grid container spacing={0} sx={{ mt: 5 }}>
                                                                <Grid xs={12} sm={4} md={4} lg={2}>
                                                                    <Item sx={{ boxShadow: "unset" }}>
                                                                        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 700 }}>
                                                                            Invited
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {dayjs(rowdata?.invited_on).format("MMMM Do, YYYY")}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Completed
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.completed !== null ? dayjs(rowdata?.completed).format("MMMM Do, YYYY") : "N/A"}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Extra time breakdown
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.results?.candidate_extra_obj?.is_status ? (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyleType: "disc",
                                                                                        paddingLeft: "20px",
                                                                                        lineHeight: 1.5,
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.candidate_extra_obj?.is_disabled > 0 && <li>Granted to all candidates +{rowdata?.results?.candidate_extra_obj?.is_disabled + "%"}</li>}
                                                                                    {rowdata?.results?.candidate_extra_obj?.is_fluent_in_lang > 0 && <li>Not fluent in English + {rowdata?.results?.candidate_extra_obj?.is_fluent_in_lang + "%"}</li>}
                                                                                </ul>
                                                                            ) : (
                                                                                "No extra time was granted to this candidate"
                                                                            )}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Source
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.source !== null ? rowdata?.source : "N/A"}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                                mb: 3,
                                                                            }}
                                                                        >
                                                                            Hiring stage
                                                                            {openHireLoader.loader && openHireLoader.index === key && <Loader />}
                                                                        </Typography>
                                                                        <Grid className="TableFormStyle">
                                                                            <TableForm
                                                                                name={"hireStage_" + key}
                                                                                hiringStages={hiringStages}
                                                                                handleChangeHireStage={(e) => handleChangeHireStage(e, rowdata?.assessment_details?.assessment_id, key)}
                                                                                selectedStage={customHireStage["hireStage_" + key] ? customHireStage["hireStage_" + key] : rowdata.hiring_stage}
                                                                                item={{ id: "" }}
                                                                                index={0}
                                                                            />
                                                                        </Grid>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid sx={{ mt: SmMobileMargin, px: 2 }} xs={12} sm={8} md={8} lg={4}>
                                                                    <Item sx={{ boxShadow: "unset", p: 0 }}>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    Anti-cheating monitor
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <HelpOutlineOutlinedIcon onClick={handleOpenAntiCheating} sx={{ cursor: "pointer" }} />
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 4,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Device used
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        background: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "#cb7b7a" : "",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "2px 10px" : "",
                                                                                        color: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "var(--text-color-OffWhite)" : "",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.platform ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid sx={{}}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Location
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid sx={{}}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontWeight: 700,
                                                                                        fontSize: data?.location ? "10px" : "14px",
                                                                                        textAlign: "end",
                                                                                        background: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "#cb7b7a" : "",
                                                                                        padding: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "2px 10px" : "",
                                                                                        color: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "var(--text-color-OffWhite)" : "",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.location ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Filled out only once from IP address?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Webcam enabled?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.webcam_enabled ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.webcam_enabled ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>


                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Full-screen mode always active?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes"
                                                                                                ? "var(--text-color-Green)"
                                                                                                : "#cb7b7a",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Mouse always in assessment window?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen ? "2px 12px" : "2px 10px",
                                                                                        color: "var(--text-color-OffWhite)",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            rowdata?.results?.video_rec_path && rowdata?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable && rowdata?.results?.cheat_log ? (
                                                                                <>
                                                                                    <CameraComponent
                                                                                        videoPath={rowdata?.results?.video_rec_path ?? 'DefaultVideoPath'}
                                                                                        is_snapshot_enable={rowdata?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable ?? 'DefaultSnapshotEnable'}
                                                                                        videoPathMarker={getVideoPathMarker(JSON.parse(rowdata?.results?.cheat_log), "video") ?? 'DefaultVideoPathMarker'}
                                                                                        videoRecordTime={getVideoRecordTime(JSON.parse(rowdata?.results?.cheat_log), "video") ?? 'DefaultVideoRecordTime'}
                                                                                        handleDummyPlayerClick={handleDummyPlayerClick}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            mt: 2,
                                                                                        }}
                                                                                    >
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                }}
                                                                                            >
                                                                                                Camera Video
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 700,
                                                                                                    background: "#cb7b7a",
                                                                                                    color: "var(--text-color-OffWhite)",
                                                                                                    padding: "2px 10px"
                                                                                                }}
                                                                                            >
                                                                                                {"N/A"}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            rowdata?.results?.screen_rec_path && rowdata?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable && rowdata?.results?.cheat_log ? (
                                                                                <>
                                                                                    <ScreenShotComponent
                                                                                        screenVideoPath={rowdata?.results?.screen_rec_path ?? 'DefaultScreenVideoPath'}
                                                                                        is_screenshot_enable={rowdata?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable ?? 'DefaultScreenshotEnable'}
                                                                                        screenVideoPathMarker={Object.values(getVideoPathMarker(JSON.parse(rowdata?.results?.cheat_log), "screen")) ?? 'DefaultScreenVideoPathMarker'}
                                                                                        screenRecordTime={getVideoRecordTime(JSON.parse(rowdata?.results?.cheat_log), "screen") ?? 'DefaultScreenRecordTime'}
                                                                                        handleDummyPlayerClick={handleDummyPlayerClick}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            mt: 1.5,
                                                                                        }}
                                                                                    >
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                }}
                                                                                            >
                                                                                                Screen Record
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 700,
                                                                                                    background: "#cb7b7a",
                                                                                                    color: "var(--text-color-OffWhite)",
                                                                                                    padding: "2px 10px"
                                                                                                }}
                                                                                            >
                                                                                                {"N/A"}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }

                                                                    </Item>
                                                                </Grid>
                                                                {rowdata.results === null || rowdata.status === 4 ? (
                                                                    <Grid sx={{ px: MiddleBoxPadding, mt: isLaptop ? "40px" : " " }} xs={12} md={12} lg={6}>
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={12}
                                                                                        md={12}
                                                                                    >
                                                                                        <Box>
                                                                                            <Box />
                                                                                            <Typography gutterBottom>
                                                                                                {rowdata.status === 4 ? "This candidate has not qualified based on the qualifying questions they answered.  Therefore there are no test results to show." : "This candidate has not started their assessment yet. Therefore there are no test results to show."}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid sx={{ px: MiddleBoxPadding, mt: isLaptop ? "40px" : " " }} xs={12} sm={12} md={12} lg={6}>
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={8}
                                                                                        md={8}
                                                                                    >
                                                                                        <Box sx={{
                                                                                            width: "100%",
                                                                                            maxWidth: "700px"
                                                                                        }}>
                                                                                            <Box sx={{ m: 3 }} />
                                                                                            <Typography sx={{ mb: 5 }}>
                                                                                                {data.candidate_details
                                                                                                    ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                                                                                    " " +
                                                                                                    (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Box
                                                                                                className="tooltip123"
                                                                                                sx={{
                                                                                                    height: "15px",
                                                                                                    borderRadius: "18px",
                                                                                                    // border: "1px solid #000",
                                                                                                    background: `linear-gradient(to right, var(--button-bg-color-green) ${rowdata?.results?.overall_per}%, #cfcfcf 0%)`,
                                                                                                }}
                                                                                            >
                                                                                                <Typography
                                                                                                    className="tooltiptext"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.overall_per}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText321">{rowdata?.results?.overall_per}</span>
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    className="tooltiptext1234 additionalClass"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.best_candidate_score}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText">{rowdata?.results?.best_candidate_score}</span>
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    className="tooltiptext123"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.avg_candidate_score}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText123">{rowdata?.results?.avg_candidate_score}</span>
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            alignItems: "end",
                                                                                            mt: 7,
                                                                                            textAlign: "end"
                                                                                        }}
                                                                                        xs={4}
                                                                                        md={4}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="h1"
                                                                                            sx={{
                                                                                                fontSize: "44px",
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {rowdata?.results?.overall_per}%
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "13px",
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            AVERAGE SCORE
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>

                                                                            <Grid
                                                                                sx={{
                                                                                    px: 0,
                                                                                    mb: 3,
                                                                                    mt: 8,
                                                                                    display: "flex",
                                                                                    gap: "20px",
                                                                                    alignItems: "Center",
                                                                                    flexWrap: "nowrap"
                                                                                }}
                                                                                container
                                                                                spacing={0}
                                                                            >
                                                                                <Grid>
                                                                                    <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", my: 1, fontWeight:500 }}>
                                                                                        {" "}
                                                                                        This assessment:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", my: 1, alignItems: "center" }}>
                                                                                    <FiberManualRecordOutlinedIcon sx={{ color: "#276678" }} />
                                                                                    <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", }}>
                                                                                        {" "}
                                                                                        Your candidate pool average
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", my: 1, alignItems: "center" }}>
                                                                                    <FiberManualRecordIcon sx={{ color: "#276678" }} />
                                                                                    <Typography variant="text" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px" }}>
                                                                                        Your best candidate score
                                                                                    </Typography>{" "}
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Item>

                                                                        <Item
                                                                            sx={{
                                                                                p: 3,
                                                                                bgcolor: "#E9F0F1",
                                                                                borderRadius: "16px",
                                                                                boxShadow: "none",
                                                                                mt: 3,
                                                                            }}
                                                                        >
                                                                            <Grid container spacing={0}>
                                                                                <Grid xs={12} md={6}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 400,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                        }}
                                                                                    >
                                                                                        Scoring method
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "16px",
                                                                                            fontWeight: 700,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                        }}
                                                                                    >
                                                                                        Percentage of correct answers
                                                                                    </Typography>
                                                                                </Grid>

                                                                            </Grid>

                                                                            <Grid sx={{ mt: 5 }}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        color: "var(--text-color-black)",
                                                                                        padding: "2px 6px",
                                                                                        borderRadius: "0 5px 5px 0",
                                                                                        textTransform: "uppercase",
                                                                                        mb: 2
                                                                                    }}
                                                                                >
                                                                                    Official Question
                                                                                </Typography>
                                                                                {rowdata?.official_question?.length > 0 &&
                                                                                    rowdata?.official_question?.map((value, index) => (
                                                                                        <Accordion
                                                                                            key={index}
                                                                                            sx={{
                                                                                                borderRadius: "8px",
                                                                                                boxShadow: "none",
                                                                                                mt: 2,
                                                                                            }}
                                                                                            expanded={expanded === index}
                                                                                            onChange={handleAccordionChange(index)}
                                                                                        >
                                                                                            <AccordionSummary
                                                                                                aria-controls="panel1-content"
                                                                                                id="panel1-header"
                                                                                                sx={{
                                                                                                    borderRadius: "4px",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    "&:hover": {
                                                                                                        bgcolor: "#f0d6bb",
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid sx={{ display: "flex" }} xs={6} md={9}>
                                                                                                        <ExpandMoreIcon
                                                                                                            sx={{
                                                                                                                fontSize: "20px",
                                                                                                                mr: 1,
                                                                                                                transform: expanded === index ? "rotate(180deg)" : "rotate(0deg)",

                                                                                                                transition: "transform 0.3s ease",
                                                                                                            }}
                                                                                                        />
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontWeight: expanded === index ? "600" : "400",
                                                                                                                fontSize: "15px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value.question_title?.length > 40
                                                                                                                ? value.question_title.substring(0, 40) + "..."
                                                                                                                : value.question_title}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "end",
                                                                                                        }}
                                                                                                        xs={6}
                                                                                                        md={3}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontSize: "14px",
                                                                                                                fontWeight: 400,
                                                                                                                color: "var(--text-color-black)",
                                                                                                                padding: "2px 6px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.correct ? ((value?.result?.correct * 100) / value?.question_count).toFixed(2) ?? 0 : 0}%
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails sx={{ px: 5 }}>
                                                                                                <Grid
                                                                                                    sx={{
                                                                                                        display: "flex",

                                                                                                        mb: 3,
                                                                                                    }}
                                                                                                >
                                                                                                    <SignalCellularAltOutlinedIcon
                                                                                                        sx={{
                                                                                                            mr: 1,
                                                                                                            fontSize: "18px",
                                                                                                        }}
                                                                                                    />
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                            mr: 2,
                                                                                                        }}
                                                                                                    >
                                                                                                        {" "}
                                                                                                        Basic
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                                                                                        Finished in {value?.result?.completed_time ? value?.result?.completed_time : "00:00:00"} out of{" "}
                                                                                                        {value?.time}
                                                                                                    </Typography>{" "}
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid xs={12} md={12}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.in_correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Incorrect Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.in_correct * 100) / value?.question_count === 0
                                                                                                                            ? 3
                                                                                                                            : (value?.result?.in_correct * 100) / value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "#cb7b7a",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.in_correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Skipped Questions"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.skipped * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-black)",
                                                                                                                        backgroundColor: "var(--button-bg-color-yellow)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Not Attempt"
                                                                                                                    style={{
                                                                                                                        width: `${100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count ===
                                                                                                                            0
                                                                                                                            ? 4
                                                                                                                            : 100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--button-hover-bg-color)",
                                                                                                                        backgroundColor: "#dfdfdf",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Correct Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.correct * 100) / value?.question_count === 0
                                                                                                                            ? 3
                                                                                                                            : (value?.result?.correct * 100) / value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "var(--button-bg-color-green)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    ))}
                                                                            </Grid>
                                                                        </Item>
                                                                    </Grid>
                                                                )}

                                                            </Grid>
                                                        </Box>
                                                    </>
                                                    :
                                                    <>
                                                        <Box sx={{ px: 3, flexGrow: 1 }}>
                                                            <Grid container spacing={0} sx={{ mt: 5 }}>
                                                                <Grid xs={12} sm={12} md={4} lg={2}>
                                                                    <Item sx={{ boxShadow: "unset" }}>
                                                                        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 700 }}>
                                                                            Invited
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {dayjs(rowdata?.invited_on).format("MMMM Do, YYYY")}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Completed
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.completed !== null ? dayjs(rowdata?.completed).format("MMMM Do, YYYY") : "N/A"}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Extra time breakdown
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.results?.candidate_extra_obj?.is_status ? (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyleType: "disc",
                                                                                        paddingLeft: "20px",
                                                                                        lineHeight: 1.5,
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.candidate_extra_obj?.is_disabled > 0 && <li>Granted to all candidates +{rowdata?.results?.candidate_extra_obj?.is_disabled + "%"}</li>}
                                                                                    {rowdata?.results?.candidate_extra_obj?.is_fluent_in_lang > 0 && <li>Not fluent in English + {rowdata?.results?.candidate_extra_obj?.is_fluent_in_lang + "%"}</li>}
                                                                                </ul>
                                                                            ) : (
                                                                                "No extra time was granted to this candidate"
                                                                            )}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                            }}
                                                                        >
                                                                            Source
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                mt: 2,
                                                                            }}
                                                                        >
                                                                            {rowdata?.source !== null ? rowdata?.source : "N/A"}
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                mt: 5,
                                                                                mb: 3,
                                                                            }}
                                                                        >
                                                                            Hiring stage
                                                                            {openHireLoader.loader && openHireLoader.index === key && <Loader />}
                                                                        </Typography>
                                                                        <Grid className="TableFormStyle">
                                                                            <TableForm
                                                                                name={"hireStage_" + key}
                                                                                hiringStages={hiringStages}
                                                                                handleChangeHireStage={(e) => handleChangeHireStage(e, rowdata?.assessment_details?.assessment_id, key)}
                                                                                selectedStage={customHireStage["hireStage_" + key] ? customHireStage["hireStage_" + key] : rowdata.hiring_stage}
                                                                                item={{ id: "" }}
                                                                                index={0}
                                                                            />
                                                                        </Grid>

                                                                    </Item>
                                                                </Grid>
                                                                {rowdata.results === null || rowdata.status === 4 ? (
                                                                    <Grid sx={{ px: MiddleBoxPadding, mt: isLaptop ? "40px" : " " }} xs={12} md={12} lg={6}>
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={12}
                                                                                        md={12}
                                                                                    >
                                                                                        <Box>
                                                                                            <Box />
                                                                                            <Typography gutterBottom>
                                                                                                {rowdata.status === 4 ? "This candidate has not qualified based on the qualifying questions they answered.  Therefore there are no test results to show." : "This candidate has not started their assessment yet. Therefore there are no test results to show."}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid sx={{ px: MiddleBoxPadding, mt: isLaptop ? "40px" : " " }} xs={12} sm={12} md={12} lg={6}>
                                                                        <Item
                                                                            sx={{
                                                                                borderRadius: "8px",
                                                                                boxShadow: "0 8px 24px 4px #00000014!important",
                                                                            }}
                                                                        >
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid sx={{ px: 3 }} container spacing={0}>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            mt: 2,
                                                                                        }}
                                                                                        xs={8}
                                                                                        md={8}
                                                                                    >
                                                                                        <Box sx={{ width: 320 }}>
                                                                                            <Box sx={{ m: 3 }} />
                                                                                            <Typography sx={{ mb: 5 }}>
                                                                                                {data.candidate_details
                                                                                                    ? (data.candidate_details.first_name === null ? "" : data?.candidate_details?.first_name) +
                                                                                                    " " +
                                                                                                    (data.candidate_details.last_name === null ? "" : data?.candidate_details?.last_name)
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Box
                                                                                                className="tooltip123"
                                                                                                sx={{
                                                                                                    height: "15px",
                                                                                                    borderRadius: "18px",
                                                                                                    // border: "1px solid #000",
                                                                                                    background: `linear-gradient(to right, var(--button-bg-color-green) ${rowdata?.results?.overall_per}%, #cfcfcf 0%)`,
                                                                                                }}
                                                                                            >
                                                                                                <Typography
                                                                                                    className="tooltiptext"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.overall_per}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText321">{rowdata?.results?.overall_per}</span>
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    className="tooltiptext1234 additionalClass"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.best_candidate_score}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText">{rowdata?.results?.best_candidate_score}</span>
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    className="tooltiptext123"
                                                                                                    sx={{
                                                                                                        left: `${rowdata?.results?.avg_candidate_score}%`,
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="tooltipSpanText123">{rowdata?.results?.avg_candidate_score}</span>
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            alignItems: "end",
                                                                                            mt: 7,
                                                                                            textAlign: "end"
                                                                                        }}
                                                                                        xs={4}
                                                                                        md={4}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="h1"
                                                                                            sx={{
                                                                                                fontSize: "44px",
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {rowdata?.results?.overall_per}%
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body1"
                                                                                            sx={{
                                                                                                fontSize: "13px",
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            AVERAGE SCORE
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>

                                                                            <Grid
                                                                                sx={{
                                                                                    px: 0,
                                                                                    mb: 3,
                                                                                    mt: 8,
                                                                                    display: "flex", 
                                                                                    alignItems: "Center",
                                                                                    flexWrap: "nowrap"
                                                                                }}
                                                                                container
                                                                                spacing={0}
                                                                            >
                                                                                <Grid xs={12} md={3}>
                                                                                    <Typography variant="body2" sx={{ fontSize: "12px", my: 1, fontWeight:600 }}>
                                                                                        {" "}
                                                                                        This assessment:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", my: 1, alignItems: "center" }} xs={12} md={5}>
                                                                                    <FiberManualRecordOutlinedIcon sx={{ color: "#276678" }} />
                                                                                    <Typography variant="body2" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px", }}>
                                                                                        {" "}
                                                                                        Your candidate pool average
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", my: 1, alignItems: "center" }} xs={12} md={4}>
                                                                                    <FiberManualRecordIcon sx={{ color: "#276678" }} />
                                                                                    <Typography variant="body2" sx={{ fontSize: isLaptop ? "10px" : isMobile ? "9px" : "10px" }}>
                                                                                        Your best candidate score
                                                                                    </Typography>{" "}
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Item>

                                                                        <Item
                                                                            sx={{
                                                                                p: 3,
                                                                                bgcolor: "#E9F0F1",
                                                                                borderRadius: "16px",
                                                                                boxShadow: "none",
                                                                                mt: 3,
                                                                            }}
                                                                        >
                                                                            <Grid container spacing={0}>
                                                                                <Grid xs={12} md={6}>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 400,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                        }}
                                                                                    >
                                                                                        Scoring method
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "16px",
                                                                                            fontWeight: 700,
                                                                                            color: "var(--text-color-black)",
                                                                                            padding: "2px 6px",
                                                                                            borderRadius: "0 5px 5px 0",
                                                                                        }}
                                                                                    >
                                                                                        Percentage of correct answers
                                                                                    </Typography>
                                                                                </Grid>

                                                                            </Grid>

                                                                            <Grid sx={{ mt: 5 }}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        color: "var(--text-color-black)",
                                                                                        padding: "2px 6px",
                                                                                        borderRadius: "0 5px 5px 0",
                                                                                        textTransform: "uppercase",
                                                                                        mb: 2
                                                                                    }}
                                                                                >
                                                                                    Official Question
                                                                                </Typography>
                                                                                {rowdata?.official_question?.length > 0 &&
                                                                                    rowdata?.official_question?.map((value, index) => (
                                                                                        <Accordion
                                                                                            key={index}
                                                                                            sx={{
                                                                                                borderRadius: "8px",
                                                                                                boxShadow: "none",
                                                                                                mt: 2,
                                                                                            }}
                                                                                            expanded={expanded === index}
                                                                                            onChange={handleAccordionChange(index)}
                                                                                        >
                                                                                            <AccordionSummary
                                                                                                aria-controls="panel1-content"
                                                                                                id="panel1-header"
                                                                                                sx={{
                                                                                                    borderRadius: "4px",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    "&:hover": {
                                                                                                        bgcolor: "#f0d6bb",
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid sx={{ display: "flex" }} xs={6} md={9}>
                                                                                                        <ExpandMoreIcon
                                                                                                            sx={{
                                                                                                                fontSize: "20px",
                                                                                                                mr: 1,
                                                                                                                transform: expanded === index ? "rotate(180deg)" : "rotate(0deg)",

                                                                                                                transition: "transform 0.3s ease",
                                                                                                            }}
                                                                                                        />
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontWeight: expanded === index ? "600" : "400",
                                                                                                                fontSize: "15px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value.question_title?.length > 40
                                                                                                                ? value.question_title.substring(0, 40) + "..."
                                                                                                                : value.question_title}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        sx={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "end",
                                                                                                        }}
                                                                                                        xs={6}
                                                                                                        md={3}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            variant="body1"
                                                                                                            sx={{
                                                                                                                fontSize: "14px",
                                                                                                                fontWeight: 400,
                                                                                                                color: "var(--text-color-black)",
                                                                                                                padding: "2px 6px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.correct ? ((value?.result?.correct * 100) / value?.question_count).toFixed(2) ?? 0 : 0}%
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails sx={{ px: 5 }}>
                                                                                                <Grid
                                                                                                    sx={{
                                                                                                        display: "flex",

                                                                                                        mb: 3,
                                                                                                    }}
                                                                                                >
                                                                                                    <SignalCellularAltOutlinedIcon
                                                                                                        sx={{
                                                                                                            mr: 1,
                                                                                                            fontSize: "18px",
                                                                                                        }}
                                                                                                    />
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            fontSize: "12px",
                                                                                                            mr: 2,
                                                                                                        }}
                                                                                                    >
                                                                                                        {" "}
                                                                                                        Basic
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                                                                                        Finished in {value?.result?.completed_time ? value?.result?.completed_time : "00:00:00"} out of{" "}
                                                                                                        {value?.time}
                                                                                                    </Typography>{" "}
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                                <Grid container spacing={0}>
                                                                                                    <Grid xs={12} md={12}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                            }}
                                                                                                        >
                                                                                                            {value?.result?.in_correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Incorrect Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.in_correct * 100) / value?.question_count === 0
                                                                                                                            ? 3
                                                                                                                            : (value?.result?.in_correct * 100) / value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "#cb7b7a",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.in_correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Skipped Questions"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.skipped * 100) / value?.question_count}%`,
                                                                                                                        color: "var(--text-color-black)",
                                                                                                                        backgroundColor: "var(--button-bg-color-yellow)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Not Attempt"
                                                                                                                    style={{
                                                                                                                        width: `${100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count ===
                                                                                                                            0
                                                                                                                            ? 4
                                                                                                                            : 100 -
                                                                                                                            ((value?.result?.correct + value?.result?.in_correct + value?.result?.skipped) * 100) /
                                                                                                                            value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--button-hover-bg-color)",
                                                                                                                        backgroundColor: "#dfdfdf",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.question_count - value?.result?.correct - value?.result?.in_correct - value?.result?.skipped}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                            {value?.result?.correct > 0 && (
                                                                                                                <BootstrapTooltip
                                                                                                                    variant="contained"
                                                                                                                    title="Correct Answers"
                                                                                                                    style={{
                                                                                                                        width: `${(value?.result?.correct * 100) / value?.question_count === 0
                                                                                                                            ? 3
                                                                                                                            : (value?.result?.correct * 100) / value?.question_count
                                                                                                                            }%`,
                                                                                                                        color: "var(--text-color-OffWhite)",
                                                                                                                        backgroundColor: "var(--button-bg-color-green)",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {value?.result?.correct}
                                                                                                                </BootstrapTooltip>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Divider />
                                                                                            </AccordionDetails>
                                                                                        </Accordion>
                                                                                    ))}
                                                                            </Grid>
                                                                        </Item>
                                                                    </Grid>
                                                                )}
                                                                <Grid sx={{ mt: SmMobileMargin, px: 2 }} xs={12} md={12} lg={4}>
                                                                    <Item sx={{ boxShadow: "unset", p: 0 }}>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    Anti-cheating monitor
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <HelpOutlineOutlinedIcon onClick={handleOpenAntiCheating} sx={{ cursor: "pointer" }} />
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 4,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Device used
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        background: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "#cb7b7a" : "",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "2px 10px" : "",
                                                                                        color: rowdata?.results?.anti_cheating_monitor?.platform === "N/A" || !rowdata?.results?.anti_cheating_monitor?.platform ? "var(--text-color-OffWhite)" : "",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.platform ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid sx={{}}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Location
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid sx={{}}>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontWeight: 700,
                                                                                        fontSize: data?.location ? "10px" : "14px",
                                                                                        textAlign: "end",
                                                                                        background: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "#cb7b7a" : "",
                                                                                        padding: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "2px 10px" : "",
                                                                                        color: rowdata?.results?.location === "N/A" || !rowdata?.results?.location ? "var(--text-color-OffWhite)" : "",
                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.location ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Filled out only once from IP address?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        color: rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "var(--text-color-OffWhite)" : "var(--text-color-OffWhite)",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip ? "2px 12px" : "2px 10px",

                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.filled_out_once_from_ip ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Webcam enabled?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.webcam_enabled ? "2px 12px" : "2px 10px",
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        color: rowdata?.results?.anti_cheating_monitor?.webcam_enabled === "Yes" ? "var(--text-color-OffWhite)" : "var(--text-color-OffWhite)",

                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.webcam_enabled ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Full-screen mode always active?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor:
                                                                                            rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes"
                                                                                                ? "var(--text-color-Green)"
                                                                                                : "#cb7b7a",
                                                                                        color:
                                                                                            rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes"
                                                                                                ? "var(--text-color-OffWhite)"
                                                                                                : "var(--text-color-OffWhite)",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active ? "2px 12px" : "2px 10px",

                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.full_screen_mode_always_active ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    Mouse always in assessment window?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 700,
                                                                                        bgcolor: rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "var(--text-color-Green)" : "#cb7b7a",
                                                                                        color: rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "var(--text-color-OffWhite)" : "var(--text-color-OffWhite)",
                                                                                        padding: rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen === "Yes" ? "2px 10px" : rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen ? "2px 12px" : "2px 10px",

                                                                                    }}
                                                                                >
                                                                                    {rowdata?.results?.anti_cheating_monitor?.mouse_always_in_screen ?? "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {
                                                                            rowdata?.results?.video_rec_path && rowdata?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable && rowdata?.results?.cheat_log ? (
                                                                                <>
                                                                                    <CameraComponent
                                                                                        videoPath={rowdata?.results?.video_rec_path ?? 'DefaultVideoPath'}
                                                                                        is_snapshot_enable={rowdata?.assessment_details?.is_anti_cheating_mode?.is_snapshot_enable ?? 'DefaultSnapshotEnable'}
                                                                                        videoPathMarker={getVideoPathMarker(JSON.parse(rowdata?.results?.cheat_log), "video") ?? 'DefaultVideoPathMarker'}
                                                                                        videoRecordTime={getVideoRecordTime(JSON.parse(rowdata?.results?.cheat_log), "video") ?? 'DefaultVideoRecordTime'}
                                                                                        handleDummyPlayerClick={handleDummyPlayerClick}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            mt: 2,
                                                                                        }}
                                                                                    >
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                }}
                                                                                            >
                                                                                                Camera Video
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 700,
                                                                                                    background: "#cb7b7a",
                                                                                                    color: "var(--text-color-OffWhite)",
                                                                                                    padding: "2px 10px"
                                                                                                }}
                                                                                            >
                                                                                                {"N/A"}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            rowdata?.results?.screen_rec_path && rowdata?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable && rowdata?.results?.cheat_log ? (
                                                                                <>
                                                                                    <ScreenShotComponent
                                                                                        screenVideoPath={rowdata?.results?.screen_rec_path ?? 'DefaultScreenVideoPath'}
                                                                                        is_screenshot_enable={rowdata?.assessment_details?.is_anti_cheating_mode?.is_screenshot_enable ?? 'DefaultScreenshotEnable'}
                                                                                        screenVideoPathMarker={Object.values(getVideoPathMarker(JSON.parse(rowdata?.results?.cheat_log), "screen")) ?? 'DefaultScreenVideoPathMarker'}
                                                                                        screenRecordTime={getVideoRecordTime(JSON.parse(rowdata?.results?.cheat_log), "screen") ?? 'DefaultScreenRecordTime'}
                                                                                        handleDummyPlayerClick={handleDummyPlayerClick}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            mt: 1.5,
                                                                                        }}
                                                                                    >
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                }}
                                                                                            >
                                                                                                Screen Record
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 700,
                                                                                                    background: "#cb7b7a",
                                                                                                    color: "var(--text-color-OffWhite)",
                                                                                                    padding: "2px 10px"
                                                                                                }}
                                                                                            >
                                                                                                {"N/A"}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>}


                                            
                                            {rowdata?.custom_question && Object.values(rowdata?.custom_question).length > 0 && (
                                                <CandidateDetailsTable
                                                    subMetaStarLoader={subMetaStarLoader}
                                                    setSubMetaStarLoader={setSubMetaStarLoader}
                                                    rowResult_id={rowdata?.results?.result_id}
                                                    subMetaStarIndex={subMetaStarIndex}
                                                    customQuestionList={Object.values(rowdata?.custom_question)}
                                                    customQuestionRating={customQuestionRating}
                                                    handleStarClick={handleStarClick}
                                                />
                                            )}
                                            {rowdata?.qualifying_question && Object.values(rowdata?.qualifying_question).length > 0 && (
                                                <QualifyingDetailsTable
                                                    qualifyingQuestionList={Object.values(rowdata?.qualifying_question)} />
                                            )}
                                            {rowdata?.results !== null && (
                                                <>
                                                    <Divider sx={{ mt: 5, borderWidth: "1px", borderColor: "#ddd" }} />

                                                    <Box sx={{ p: 3, flexGrow: 1 }}>
                                                        <Grid container spacing={0}>
                                                            <Grid xs={12} md={6}>
                                                                <Item sx={{ boxShadow: "unset", background: "white" }}>
                                                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 700 }}>
                                                                        Your rating
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            mt: 2,
                                                                        }}
                                                                    >
                                                                        Rate this candidate based on your personal impressions and interactions with them.
                                                                    </Typography>

                                                                    <Grid sx={{ display: "flex", mt: 5, cursor: "pointer" }}>
                                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                                            <StarIcon
                                                                                key={star}
                                                                                onClick={() =>
                                                                                    handleStarClick(
                                                                                        star,
                                                                                        "yourRating",
                                                                                        key,
                                                                                        "",
                                                                                        "",
                                                                                        rowdata?.results?.result_id,
                                                                                        rowdata?.assessment_details?.assessment_id
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    color: star <= (rating[key] ? rating[key] : rowdata?.results?.rating) ? "#faaf00" : "#d2d2d2",
                                                                                    fontSize: "30px",
                                                                                }}
                                                                            />
                                                                        ))}
                                                                        {starLoader && <Loader />}
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>
                                                            <Grid xs={12} md={6}>
                                                                <Item
                                                                    sx={{
                                                                        boxShadow: "unset",
                                                                        background: "white",
                                                                    }}
                                                                >
                                                                    <Box sx={{ mt: 2 }}>
                                                                        <ThemeProvider theme={customTheme(outerTheme)}>
                                                                            <TextField
                                                                                onChange={(e) => handleSubmit(e, key, rowdata?.assessment_details?.assessment_id, rowdata?.id)}
                                                                                fullWidth
                                                                                name={"private_" + key}
                                                                                label="Add your private notes here (auto-saved)..."
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                value={private_note["private_" + key] ? private_note["private_" + key] : rowdata?.results?.private_note}
                                                                                multiline
                                                                                rows={6}
                                                                            />
                                                                        </ThemeProvider>
                                                                    </Box>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            )}

                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                ))
            )}
            <AntiCheatingMoniter open={openAntiCheating} setOpen={setOpenAntiCheating} />
        </Box>
    );
}

export default Index;



const CameraComponent = (props) => {
    const { videoPath, is_snapshot_enable, videoPathMarker, videoRecordTime, handleDummyPlayerClick } = props;

    if (videoPath && videoPath !== "rendering" && is_snapshot_enable) {
        return (
            <Grid
                sx={{
                    display: "block",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Camera Video :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#cb7b7a",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <VideoStream videoUrl={videoPath} markersPoint={videoPathMarker} time={videoRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Camera Video :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            bgcolor: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}> {videoPath === "rendering" ? (
                            <>
                                <CircularProgress style={{ width: "22px", height: "22px" }} color="inherit" />
                                Video is Rendering
                            </>
                        ) : "N/A"} </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

const ScreenShotComponent = (props) => {
    const { screenVideoPath, is_screenshot_enable, screenVideoPathMarker, screenRecordTime, handleDummyPlayerClick } = props;

    if (screenVideoPath && screenVideoPath !== "rendering" && is_screenshot_enable) {
        return (
            <Grid
                sx={{
                    display: "block",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 2,
                    alignItems: "end"
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Screen Record :
                    </Typography>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <VideoStream videoUrl={screenVideoPath} markersPoint={screenVideoPathMarker} time={screenRecordTime} handleDummyPlayerClick={handleDummyPlayerClick} />
                        <Grid
                            sx={{
                                display: "flex",
                            }}
                            mt={3}
                        >
                            <Grid sx={{ display: "flex" }} sm={6}>
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        bgcolor: "#cb7b7a",
                                        borderRadius: "50%",
                                    }}
                                ></Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: "flex",
                                        color: "var(--text-color-black)",
                                        ml: 1,
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    Tab Action
                                </Typography>
                            </Grid>
                            <Grid sx={{ display: "flex" }} sm={6}>
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        bgcolor: "var(--button-bg-color-green)",
                                        borderRadius: "50%",
                                    }}
                                ></Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: "flex",
                                        color: "var(--text-color-black)",
                                        ml: 1,
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    Mouse Action
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 3,
                    mb: 2,
                }}
            >
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        Screen Record :
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 700,
                            bgcolor: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                            padding: "2px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}> {screenVideoPath === "rendering" ? (
                            <>
                                <CircularProgress style={{ width: "22px", height: "22px" }} color="inherit" />
                                Video is Rendering
                            </>
                        ) : "N/A"} </Grid>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}