export const truncateWithEllipsis = (text, maxLen, useWordBoundary = false) => {
    if (text.length <= maxLen) {
        return text;
    }

    const truncated = text.slice(0, maxLen - 3);
    if (useWordBoundary) {
        return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
    } else {
        return truncated + "...";
    }
}