import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate() {
    return (
        <Box className='loaderAdmin' sx={{ display: "flex", justifyContent: "center", alignItems: "center",  }}>
            <CircularProgress sx={{ color: "var(--text-color-black)" }} />
        </Box>
    );
}
