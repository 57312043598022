import { Grid, Box, Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { getHeaders, myProfileChangeEmailUrl } from "../../../../Constants/ConstantsUrl";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function ChangeEmailModal({ user, getUserDetails, setAlertNotification, open, setOpen, customTheme, outerTheme, ThemeProvider }) {
    const [newEmail, setNewEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleClose = () => {
        setOpen(!open);
    };

    const handleSubmitAction = async () => {
        const data = {
            new_email: newEmail,
            password: btoa(password),
        };

        try {
            const headers = getHeaders();
            await axios
                .post(myProfileChangeEmailUrl, data, { headers })
                .then((response) => response)
                .then(async (response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        getUserDetails();
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                        setOpen(false);
                    }
                });
        } catch (error) {
            let response = error?.response?.data;
            if (response?.data instanceof Object) {
                let inputs = response?.data;
                setAlertNotification({
                    status: true,
                    type: "error",
                    message: Object.values(inputs)[0],
                });
            }
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            className="RemoveOutLine"
					sx={{ backdropFilter: "blur(4px)" }}
        >
            <Fade in={"open"}>
                <Box
                    className="FadeModal"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "none",
                        borderRadius: "8px",
                        boxShadow: 24,
                    }}
                >
                    <Grid id="transition-modal-title" variant="h6">
                        <Grid
                            sx={{
                                bgcolor: "var(--text-color-Green)",
                                p: 2,
                                borderRadius: "8px 8px 0px 0px",
                            }}
                            container
                            spacing={0}
                        >
                            <Grid xs={10}>
                                <Typography
                                    id="modal-modal-title"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "var(--text-color-OffWhite)",
                                    }}
                                    variant="h6"
                                >
                                    Change email address
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                                xs={2}
                            >
                                <CloseIcon
                                    onClick={handleClose}
                                    sx={{
                                        color: "var(--text-color-OffWhite)",
                                        cursor: "pointer",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography id="transition-modal-description" sx={{ p: 3 }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                my: 2,
                            }}
                            variant="h6"
                        >
                            Current email: {user?.email}
                        </Typography>

                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                type="email"
                                sx={{
                                    mt: 2,
                                    width: "100%",
                                }}
                                id="outlined-basic"
                                label="New email*"
                                variant="outlined"
                                InputProps={{}}
                                value={newEmail}
                                onChange={(e) => {
                                    setNewEmail(e.target.value);
                                }}
                            />
                        </ThemeProvider>

                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                                sx={{
                                    mt: 2,
                                    width: "100%",
                                }}
                                id="outlined-basic"
                                label="Current password*"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </ThemeProvider>

                        <Grid container spacing={0}>
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "end",
                                    mt: 5,
                                }}
                                xs={12}
                                md={12}
                            >
                                <Button
                                    onClick={handleClose}
                                    sx={{
                                        color: "var(--text-color-black)",
                                        bgcolor: "#ededed",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "none",
                                        boxShadow: "none",

                                        "&:hover": {
                                            bgcolor: "#e0e0e0",
                                            boxShadow: "none",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    sx={{
                                        bgcolor: "var(--text-color-Green)",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "capitalize",

                                        "&:hover": {
                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={handleSubmitAction}
                                >
                                    Change email
                                </Button>
                            </Grid>
                        </Grid>
                    </Typography>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ChangeEmailModal;
