import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, LinearProgress, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation } from "react-router-dom";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    background: "unset",
}));

export default function Index() {
    const handleCloseTab = () => {
        window.close();
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const companyColorCode = searchParams.get('companyColorCode') === "1" ? "var(--button-bg-color-green)" : searchParams.get('companyColorCode') || "var(--button-bg-color-green)";
    const companyName = searchParams.get('companyName');
    const [selectedValue, setSelectedValue] = useState("");

	const handleChange = (event) => {
        if (event.target.value === selectedValue) {
            setSelectedValue("");
        } else {
            setSelectedValue(event.target.value);
        }
    };

	const getContrastText = (background) => {
		const hexColor = background.replace("#", "");
		const r = parseInt(hexColor.substr(0, 2), 16);
		const g = parseInt(hexColor.substr(2, 2), 16);
		const b = parseInt(hexColor.substr(4, 2), 16);
		const brightness = (r * 299 + g * 587 + b * 114) / 1000;
		return brightness >= 128 ? "black" : "white";
	};

	const BpIcon = styled("span")(({ theme }) => ({
		marginRight: "10px",
		border: "2px solid #ededed",
		borderRadius: "50%",
		width: 30,
		height: 30,
		backgroundImage:
			theme.palette.mode === "dark"
				? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
				: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		".Mui-focusVisible &": {
			outline: "2px auto red",
			outlineOffset: 2,
		},
		"& .Mui-checked": {
			border: "2px solid red",
		},
		"input:hover ~ &": {
			border: `2px solid ${companyColorCode}`,
			transition: " 0.5s all ",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background:
				theme.palette.mode === "dark"
					? "rgba(57,75,89,.5)"
					: "rgba(206,217,224,.5)",
		},
	}));

	const BpCheckedIcon = styled(BpIcon)({
		backgroundColor: {companyColorCode},
		backgroundImage:
			"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&::before": {
			display: "block",
			width: 16,
			height: 16,
			// backgroundImage: `radial-gradient(#fff,#fff 28%,transparent 32%)`,
			backgroundImage: `radial-gradient(${getContrastText(companyColorCode)},${getContrastText(companyColorCode)} 28%,transparent 32%)`,
			content: '""',
			marginTop: "5px",
			marginLeft: "5px",
		},
		
		"input:hover ~ &": {
			backgroundColor: companyColorCode,
			// border:"2px solid red",
		},

		"input:checked ~ &": {
			borderColor: getContrastText(companyColorCode), // Change border color when checked
		  },
	});
	function BpRadio(props) {
		return (
			<Radio
				disableRipple
				color="default"
				checkedIcon={<BpCheckedIcon />}
				icon={<BpIcon />}
				{...props}
			/>
		);
	}
	return (
		<Box sx={{ background: "#f5f6f6", height: "100vh" }}>
			<Box sx={{ flexGrow: 1, background: "white" }}>
				<Grid container spacing={0}>
					<Grid xs={12}>
						<Container maxWidth="lg" sx={{ py: 1 }}>
							<Grid
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Grid>
									<Typography
										variant="body1"
										sx={{ fontSize: "24px", fobtWeight: 600 }}
									>
										{companyName}
									</Typography>
								</Grid>



                                <Grid item sx={{ display: "block", width: "30%", padding: "20px", my: "auto" }}>
                <Grid item xs={12} sx={{ alignItems: "center", display: "flex", }} >
                    <AccessAlarmIcon sx={{ marginRight: "5px", fontSize: "13px" }} /> <span style={{ fontSize: "13px" }}>00.10</span>
                    <LinearProgress variant="determinate" value={50} sx={{ flexGrow: 1, mx: 1 }} />
                    <span style={{ fontSize: "13px" }}>30 min</span>
                </Grid>
                <Grid item xs={12} sx={{ alignItems: "center", display: "flex", }} >
                    <span style={{ fontSize: "13px" }}>
                    1/3
                    </span>
                    <LinearProgress variant="determinate" value={75} sx={{ flexGrow: 1, mx: 1 }} />
                </Grid>
            </Grid> 

                                
								<Grid>
									<Button
										style={{ padding: "16px 30px" }}
										sx={{
											textTransform: "none",
											background: companyColorCode,
											"&:hover": {
												background: companyColorCode,
												// border:"2px solid red",
											},
										}}
										variant="outlined"
										onClick={handleCloseTab}
									>
										<Typography
											fontWeight={600}
											sx={{
												fontSize: "var(  --btn-fontsize)",
												color: getContrastText(companyColorCode),
												display: "flex",
												alignItems: "center",
											}}
										>
											Finish
											<ArrowForwardIcon
												sx={{
													color: getContrastText(companyColorCode),
													ml: 1,
													fontSize: "22px",
												}}
											/>
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</Container>
					</Grid>
				</Grid>
			</Box>
			<Box
				sx={{
					height: "90vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Container
					maxWidth="lg"
					style={{
						background: "white",
						borderRadius: "4px",
						boxShadow:
							"-3 6px 7px -4px #8888880f,0 11px 15px 1px #8888880b,0 4px 20px 3px #88888809",
						padding: "0px",
					}}
				>
					<Box sx={{ flexGrow: 1, p: 2 }}>
						<Grid container spacing={0}>
							<Grid sx={{ p: 5 }} xs={12} md={6}>
								<Item>
									<Typography
										variant="body1"
										sx={{
											fontSize: "16px",
											fontStyle: "italic",
											fontWeight: 400,
										}}
									>
										Read the following paragraphs and choose the right answer.
									</Typography>
									<br />
									<Typography
										variant="body1"
										sx={{ fontSize: "16px", fontWeight: 400 }}
									>
										The nation’s mobile home parks constitute a highly
										fragmented market, where there is less efficient
										institutional participation. Inefficiency is our
										opportunity, but the asset class is becoming more popular
										with experienced and newer investors who may ‘bid-up’
										prices, resulting in inflated expectations of sellers.
										Believing the timing is right, we are aggressively
										evaluating opportunities to stay ahead of its growing
										popularity.
									</Typography>
									<br />
									<Typography
										variant="body1"
										sx={{ fontSize: "16px", fontWeight: 400 }}
									>
										Because mobile home communities are located away from dense
										city centers and are geographically dispersed, they are an
										under-researched and misunderstood asset class. Most
										investors have greatly outmoded ideas about the quality and
										appeal of modern mobile homes and mobile home communities.
									</Typography>
									<br />
								</Item>
							</Grid>
							<Grid sx={{ p: 5 }} xs={12} md={6}>
								<Item>
									<Typography
										variant="body1"
										sx={{
											fontSize: "18px",
											fontWeight: 500,
											textTransform: "uppercase",
											mb: "20px",
										}}
									>
										Select only one
									</Typography>

									<FormControl>
										<RadioGroup
											defaultValue="female"
											aria-labelledby="demo-customized-radios"
											name="customized-radios"
											value={selectedValue}
											onClick={handleChange}
											className="checkBoxBorder"
										>
											<FormControlLabel
												sx={{
													boxShadow: "0 2px 8px 1px #a8a8a833",
													borderRadius: "6px",
													padding: "18px",
													margin: "0px 0px 32px 0px",
													cursor: "pointer",
													border: "2px solid #ededed",
													transition: " 0.5s ease-in-out",
													backgroundColor:
														selectedValue === "female"
															? companyColorCode
															: "white",
													color:
														selectedValue === "female"
															? getContrastText(companyColorCode)
															: "var(--text-color-black)",
													"&:hover": {
														border: `2px solid ${companyColorCode}`,
														transition: " 0.5s ease-in-out",
													},
												}}
												value="female"
												control={<BpRadio />}
												label={
													<Typography
														variant="body1"
														sx={{ fontSize: "16px", fontWeight: 400 }}
													>
														this is not an opportune time to invest in mobile
														home parks.
													</Typography>
												}
											/>

											<FormControlLabel
												sx={{
													boxShadow: "0 2px 8px 1px #a8a8a833",
													borderRadius: "6px",
													padding: "18px",
													margin: "0px 0px 32px 0px",
													cursor: "pointer",
													border: "2px solid #ededed",
													transition: " 0.5s ease-in-out",
													backgroundColor:
														selectedValue === "male"
															? companyColorCode
															: "white",
													color:
														selectedValue === "male"
															? getContrastText(companyColorCode)
															: "var(--text-color-black)",
													"&:hover": {
														border: `2px solid ${companyColorCode}`,
														transition: " 0.5s ease-in-out",
													},
												}}
												value="male"
												control={<BpRadio />}
												label={
													<Typography
														variant="body1"
														sx={{ fontSize: "16px", fontWeight: 400 }}
													>
														now is the right time to think of investing in
														mobile home parks.
													</Typography>
												}
											/>

											<FormControlLabel
												sx={{
													boxShadow: "0 2px 8px 1px #a8a8a833",
													borderRadius: "6px",
													padding: "18px",
													margin: "0px 0px 32px 0px",
													cursor: "pointer",
													border: "2px solid #ededed",
													transition: " 0.5s ease-in-out",
													backgroundColor:
														selectedValue === "other"
															? companyColorCode
															: "white",
													color:
														selectedValue === "other"
															? getContrastText(companyColorCode)
															: "var(--text-color-black)",
													"&:hover": {
														border: `2px solid ${companyColorCode}`,
														transition: " 0.5s ease-in-out",
													},
												}}
												value="other"
												control={<BpRadio />}
												label={
													<Typography
														variant="body1"
														sx={{ fontSize: "16px", fontWeight: 400 }}
													>
														gradually mobile home parks are concentrating to
														city centers.
													</Typography>
												}
											/>
											<FormControlLabel
												sx={{
													boxShadow: "0 2px 8px 1px #a8a8a833",
													borderRadius: "6px",
													padding: "18px",
													margin: "0px 0px 32px 0px",
													cursor: "pointer",
													border: "2px solid #ededed",
													transition: " 0.5s ease-in-out",
													backgroundColor:
														selectedValue === "other1"
															? companyColorCode
															: "white",
													color:
														selectedValue === "other1"
															? getContrastText(companyColorCode)
															: "var(--text-color-black)",
													"&:hover": {
														border: `2px solid ${companyColorCode}`,
														transition: " 0.5s ease-in-out",
													},
												}}
												value="other1"
												control={<BpRadio />}
												label={
													<Typography
														variant="body1"
														sx={{ fontSize: "16px", fontWeight: 400 }}
													>
														nowadays, most investors are up-to-date on mobile
														home parks’ potential.
													</Typography>
												}
											/>
											<FormControlLabel
												sx={{
													boxShadow: "0 2px 8px 1px #a8a8a833",
													borderRadius: "6px",
													padding: "18px",
													margin: "0px 0px 32px 0px",
													cursor: "pointer",
													border: "2px solid #ededed",
													transition: " 0.5s ease-in-out",
													backgroundColor:
														selectedValue === "other2"
															? companyColorCode
															: "white",
													color:
														selectedValue === "other2"
															? getContrastText(companyColorCode)
															: "var(--text-color-black)",
													"&:hover": {
														border: `2px solid ${companyColorCode}`,
														transition: " 0.5s ease-in-out",
													},
												}}
												value="other2"
												control={<BpRadio />}
												label={
													<Typography
														variant="body1"
														sx={{ fontSize: "16px", fontWeight: 400 }}
													>
														now is the right time to think of investing in
														mobile home parks.
													</Typography>
												}
											/>
										</RadioGroup>
									</FormControl>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</Box>
	);
}
