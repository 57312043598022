// Loader.js

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

const Loader = () => {
  return (
    <Grid className='loaderAdmin'>
       <CircularProgress  sx={{ color: "var(--text-color-black)" }}/>
    </Grid>
  );
};

export default Loader;