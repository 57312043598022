import React from "react";
import { Button, Typography, Grid, IconButton, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

const StepTwoSkeleton = (key) => {
    return (
        <Grid key={key} xs={12} md={4}>
            <Item
                style={{
                    borderRadius: "8px",
                    padding: "0px",
                    boxShadow: "rgba(136, 136, 136, 0.06) 0px 6px 7px -4px, rgba(136, 136, 136, 0.043) 0px 11px 15px 1px, rgba(136, 136, 136, 0.035) 0px 4px 20px 3px",
                }}
            >
                <Grid sx={{ bgcolor: "var(--button-bg-color-green)", borderRadius: "8px 8px 0px 0px" }}>
                    <Grid style={{ padding: "0px" }}>
                        <Skeleton variant="rectangular" animation="wave" width={300} height={65} />
                    </Grid>
                </Grid>
                <Grid sx={{ px: 2 }}>
                    <Skeleton variant="rectangular" animation="wave" width={300} height={55} sx={{ my: 4 }} />
                </Grid>
                <Grid sx={{ px: 2, mt: 3 }} container spacing={0}>
                    <Grid xs={8} md={8}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                            }}
                        >
                            <Skeleton variant="rectangular" animation="wave" width={70} height={30} />
                        </Item>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} xs={4} md={4}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                            }}
                        >
                            <Skeleton variant="rectangular" animation="wave" width={70} height={30} />
                        </Item>
                    </Grid>
                </Grid>

                <Grid sx={{ px: 2, pb: 2, mt: 1 }} container spacing={0}>
                    <Grid xs={8} md={8}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                padding: "10px",
                                padding: "0px",
                            }}
                        >
                            <Skeleton width={90} height={50} animation="wave" />
                        </Item>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} xs={4} md={4}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",

                                padding: "0px",
                            }}
                        >
                            <Skeleton animation="wave" width={70} height={40} />
                        </Item>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
    );
};

export default StepTwoSkeleton;
