import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
	const refVideo = useRef(null);
	const refPlayer = useRef(null);
	const { options, onReady, markers, time } = props;
	const [isPlaying, setIsPlaying] = useState(false);
	let progress = 0
	let muiButton = null
	useEffect(() => {

		if (!refPlayer.current) {
			const videoElement = document.createElement("video-js");

			videoElement.classList.add('vjs-big-play-centered');
			refVideo.current.appendChild(videoElement);
			const player = refPlayer.current = videojs(videoElement, options, () => {
				videojs.log('player is ready');
				onReady && onReady(player);
			});
			player.ready(() => {
				player.on('timeupdate', () => {
					const currentTime = player.currentTime();
					const duration = player.duration();
					progress = (currentTime / duration) * 100;
					progressBarInside.setAttribute('value', progress);
				});
				const controlBar = player.controlBar;
				const progressControl = controlBar.progressControl;
				const progressControlEl = progressControl.el();
				const progressBar = progressControlEl.querySelector('.vjs-progress-holder, .vjs-slider, .vjs-slider-horizontal');
				const blackTooltip = document.querySelector('.vjs-mouse-display .vjs-time-tooltip');
				progressBar.addEventListener('mousedown', () => {
					blackTooltip.classList.add('hide');
				});
				progressControlEl.addEventListener('mousedown', () => {
					blackTooltip.classList.add('hide');
				});
				document.addEventListener('mouseup', () => {
					blackTooltip.classList.remove('hide');
				});
				const customProgressBar = document.createElement('div');
				customProgressBar.classList.add('custom-progress-bar');
				const progressBarInside = document.createElement('progress');
				progressBarInside.setAttribute('value', progress)
				progressBarInside.setAttribute('max', "100")
				const allMarkers = document.createElement('div');
				allMarkers.classList.add('markers')
				markers.forEach(marker => {
					const markerElement = document.createElement('div');
					markerElement.classList.add('marker');
					markerElement.style.left = `${(marker.time / time) * 100}%`;
					markerElement.style.backgroundColor = marker.color;

					const tooltip = document.createElement('div');
					const tooltipInnerDiv = document.createElement('div');
					tooltip.classList.add('tooltip');
					tooltipInnerDiv.classList.add('tooltip-inner');
					tooltipInnerDiv.textContent = marker.title;
					tooltip.appendChild(tooltipInnerDiv)
					markerElement.appendChild(tooltip);
					allMarkers.appendChild(markerElement);

					markerElement.addEventListener('mouseover', () => {
						tooltip.classList.add('tooltip-visible');
					});
					markerElement.addEventListener('mouseout', () => {
						tooltip.classList.remove('tooltip-visible');
					});
				});
				customProgressBar.appendChild(allMarkers)
				customProgressBar.appendChild(progressBarInside)
				progressControlEl.insertBefore(customProgressBar, progressControlEl.firstChild);
				const playButton = player.controlBar.playToggle.el();
				const newPlayButton = document.createElement('button');
				newPlayButton.className = 'vjs-play-control vjs-control vjs-button custom-play-toggle-class';
				newPlayButton.innerHTML = '<span class="custom-icon" aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite"></span>';
				newPlayButton.querySelector('.custom-icon').innerHTML = (`<svg xmlns="http://www.w3.org/2000/svg" class="play-icon" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-272.31v-415.38L686.15-480 360-272.31ZM400-480Zm0 134 211.54-134L400-614v268Z"/></svg>`)
				playButton.parentNode.replaceChild(newPlayButton, playButton);
				const handlePlayPause = () => {
					setIsPlaying(prevIsPlaying => {
						const newIsPlaying = !prevIsPlaying;
						console.log("playing status: ", newIsPlaying);
						if (newIsPlaying) {
							document.body.classList.add('playing');
							newPlayButton.querySelector('.custom-icon').innerHTML = (`<svg xmlns="http://www.w3.org/2000/svg" class="pause-icon" height="24" viewBox="0 -960 960 960" width="24"><path d="M540-240v-480h180v480H540Zm-300 0v-480h180v480H240Zm340-40h100v-400H580v400Zm-300 0h100v-400H280v400Zm0-400v400-400Zm300 0v400-400Z"/></svg>`)
							document.body.classList.remove('paused');
							player.play();
						} else {
							document.body.classList.add('paused');
							newPlayButton.querySelector('.custom-icon').innerHTML = (`<svg xmlns="http://www.w3.org/2000/svg" class="play-icon" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-272.31v-415.38L686.15-480 360-272.31ZM400-480Zm0 134 211.54-134L400-614v268Z"/></svg>`)
							document.body.classList.remove('playing');
							player.pause();
						}
						return newIsPlaying;
					});
				}
				newPlayButton.addEventListener('click', handlePlayPause);
				videoElement.querySelector('video').addEventListener('click', handlePlayPause);
				videoElement.querySelector('.vjs-big-play-button').addEventListener('click', handlePlayPause);
			});
		} else {
			const player = refPlayer.current;

			player.autoplay(options.autoplay);
			player.src(options.sources);
		}
	}, [options, refVideo, muiButton]);
	useEffect(() => {
		const player = refPlayer.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				refPlayer.current = null;
			}
		};
	}, [refPlayer,]);

	return (
		<div style={{textAlign:"center"}} className='CustomVideoStyle' data-vjs-player>
			<div ref={refVideo} />
		</div>
	);
}

export default VideoJS;
