import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, List, Typography } from "@mui/material";
import { Reorder } from "framer-motion";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#c8d7de",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#c8d7de",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function DataTable({
  combineQuestion,
  handleRemoveItem,
  fromCall,
  handleEditItem,
  setCombineQuestion,
  moveRow,
}) {
  return (
    <Box
      sx={{
        mt: 5,
        boxShadow:
          "0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f;",
      }}
      component={Paper}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          textTransform: "uppercase",
          py: 3,
          color: "#0000008A",
          ml: 4,
        }}
      >
        {fromCall === 4 ? "Selected Tests" : "Custom Selected"}
      </Typography>

      <Box sx={{ minWidth: 700 }} aria-label="customized table">
        <Grid
          sx={{
            bgcolor: "#f5f5f5",
            display: "flex",
            borderTop: "1px solid #0000001f",
            borderBottom: "1px solid #0000001f",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              width: "45%",
              py: 2,
              ml: 4,
              color: "#0000008A",
            }}
          >
            Type
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              width: "20%",
              py: 2,
              color: "#0000008A",
            }}
            align="left"
          >
            Duration
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              width: "20%",
              py: 2,
              color: "#0000008A",
            }}
            align="left"
          >
            Question
          </Typography>
          <Grid sx={{ display: "flex", gap: "30px" }}>
            <Typography sx={{ py: 2 }}>&nbsp;</Typography>
            <Typography sx={{ py: 2 }}>&nbsp;</Typography>
            <Typography sx={{ py: 2 }}>&nbsp;</Typography>
          </Grid>
        </Grid>
        {fromCall === 4 && (
          <Reorder.Group
            as={List}
            axis="y"
            onReorder={setCombineQuestion}
            id="table_4thStep"
            //   values={items}
            // values={(combineQuestion) => combineQuestion.question}
            values={combineQuestion}
            itemKey={(item) => item.question}
          >
            {combineQuestion.map(
              (row, index) =>
                row.question_category_id !== 3 && (
                  <Reorder.Item key={row.question} value={row}>
                    <Grid
                      key={row.question}
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #0000000f",
                        "&:hover": { background: "#f0d6bb", cursor: "grab" },
                      }}
                    >
                      <Typography
                        component="p"
                        scope="row"
                        sx={{
                          width: "45%",
                          py: 3,
                          ml: 4,
                          color: "#000000DE",
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.question_category_id === 1
                          ? "Official"
                          : "Custom"}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          color: "#000000DE",
                          py: 3,
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AccessAlarmsIcon sx={{ fontSize: "15px" }} />
                        {row.time} min
                      </Typography>
                      <Typography
                        component="p"
                        scope="row"
                        sx={{
                          width: "20%",
                          color: "#000000DE",
                          py: 3,
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                row.question.length > 110
                                  ? row.question.substring(0, 110) + "..."
                                  : row.question,
                            }}
                          />
                        }
                      </Typography>
                      <Grid sx={{ display: "flex", gap: "30px" }}>
                        <Typography align="center" sx={{ py: 3 }}>
                          {index !== combineQuestion.length - 1 ? (
                            <Grid
                              sx={{
                                cursor: "pointer",
                                p: "5px",
                                borderRadius: "50%",
                                "&:hover": { background: "#f6f6f6" },
                              }}
                            >
                              <BootstrapTooltip variant="contained" title="Down">
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    index < combineQuestion.length - 1 &&
                                    moveRow(index, index + 1)
                                  }
                                /></BootstrapTooltip>
                            </Grid>
                          ) : (
                            <Grid sx={{ cursor: "pointer", p: "5px" }}>
                              <KeyboardArrowDownIcon
                                sx={{ color: "#b1b1b1" }}
                              />
                            </Grid>
                          )}
                        </Typography>
                        <Typography align="center" sx={{ py: 3 }}>
                          {index !== 0 ? (
                            <Grid
                              sx={{
                                cursor: "pointer",
                                p: "5px",
                                borderRadius: "50%",
                                "&:hover": { background: "#f6f6f6" },
                              }}
                            > <BootstrapTooltip variant="contained" title="Up">
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    index > 0 && moveRow(index, index - 1)
                                  }
                                /></BootstrapTooltip>
                            </Grid>
                          ) : (
                            <Grid sx={{ cursor: "pointer", p: "5px" }}>
                              <KeyboardArrowUpIcon
                                sx={{ color: "#b1b1b1" }}
                              />
                            </Grid>
                          )}
                        </Typography>
                        <Typography align="right" sx={{ py: 3 }}>
                          <Grid
                            sx={{
                              cursor: "pointer",
                              p: "5px",
                              borderRadius: "50%",
                              "&:hover": { background: "#f6f6f6" },
                            }}
                          > <BootstrapTooltip variant="contained" title="Delete">
                              <DeleteOutlineOutlinedIcon
                                sx={{ width: "100%", margin: "auto" }}
                                onClick={() => handleRemoveItem(index, row)}
                              />
                            </BootstrapTooltip>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Reorder.Item>
                )
            )}
          </Reorder.Group>
        )}

        {fromCall === 3 && (
          <Grid>
            {combineQuestion.map(
              (row, index) =>
                row.question_category_id !== 3 && (
                  <Grid key={row.question}>
                    <Grid
                      key={row.question}
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #0000000f",
                        "&:hover": { background: "#f0d6bb", },
                      }}
                    >
                      <Typography
                        component="p"
                        scope="row"
                        sx={{
                          width: "45%",
                          py: 3,
                          ml: 4,
                          color: "#000000DE",
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.question_category_id === 1
                          ? "Official"
                          : "Custom"}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          color: "#000000DE",
                          py: 3,
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AccessAlarmsIcon sx={{ fontSize: "15px" }} />
                        {row.time} min
                      </Typography>
                      <Typography
                        component="p"
                        scope="row"
                        sx={{
                          width: "20%",
                          color: "#000000DE",
                          py: 3,
                          fontSize: "13px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                row.question.length > 110
                                  ? row.question.substring(0, 110) + "..."
                                  : row.question,
                            }}
                          />
                        }
                      </Typography>
                      <Grid sx={{ display: "flex", gap: "30px" }}>
                        <Typography align="center" sx={{ py: 3 }}>
                          {index !== combineQuestion.length - 1 ? (
                            <Grid
                              sx={{
                                cursor: "pointer",
                                p: "5px",
                                borderRadius: "50%",
                                "&:hover": { background: "#f6f6f6" },
                              }}
                            >
                              <BootstrapTooltip variant="contained" title="Down">
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    index < combineQuestion.length - 1 &&
                                    moveRow(index, index + 1)
                                  }
                                /></BootstrapTooltip>
                            </Grid>
                          ) : (
                            <Grid sx={{ cursor: "pointer", p: "5px" }}>
                              <KeyboardArrowDownIcon
                                sx={{ color: "#b1b1b1" }}
                              />
                            </Grid>
                          )}
                        </Typography>
                        <Typography align="center" sx={{ py: 3 }}>
                          {index !== 0 ? (
                            <Grid
                              sx={{
                                cursor: "pointer",
                                p: "5px",
                                borderRadius: "50%",
                                "&:hover": { background: "#f6f6f6" },
                              }}
                            > <BootstrapTooltip variant="contained" title="Up">
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    index > 0 && moveRow(index, index - 1)
                                  }
                                /></BootstrapTooltip>
                            </Grid>
                          ) : (
                            <Grid sx={{ cursor: "pointer", p: "5px" }}>
                              <KeyboardArrowUpIcon
                                sx={{ color: "#b1b1b1" }}
                              />
                            </Grid>
                          )}
                        </Typography>
                        <Typography align="right" sx={{ py: 3 }}>
                          <Grid
                            sx={{
                              cursor: "pointer",
                              p: "5px",
                              borderRadius: "50%",
                              "&:hover": { background: "#f6f6f6" },
                            }}
                          >
                            <BootstrapTooltip variant="contained" title="Delete">
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  cursor: "pointer",
                                  width: "100%",
                                  margin: "auto",
                                }}
                                onClick={() => handleRemoveItem(index, row)}
                              />
                            </BootstrapTooltip>
                            {/* <BootstrapTooltip variant="contained" title="Edit" > 
                                <EditIcon sx={{ cursor: "pointer", width: "50%", margin: "auto" }} onClick={() => handleEditItem(index, row)} />
                            </BootstrapTooltip> */}
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
