import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  TextField,
  Typography,
  Collapse,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider"; 
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputBase from "@mui/material/InputBase";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import { AllowedAccess } from "react-permission-role";
import TableForm from "./TableForm";
import { candidateAssessmentUrl, updateHiringStageInBulk, getHeaders, sendReminder } from "../../../../../../Constants/ConstantsUrl";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormControl from "@mui/material/FormControl";
import TableRowsLoader from "./../../../../TableRowsLoader";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CountdownTimer from "./countDownTimer";


const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
	  color: "#c8d7de",
	},
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: "#c8d7de",
	},
  }));


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "16.5px 0px 16.5px 14px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "var(--border-color-Green)",
    },
    "&:hover": {
      borderColor: "var(--border-color-Green)",
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  "& #demo-customized-select": {
    boxSizing: "border-box",
    // minWidth: "245px",
  },
}));

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#0000001f",
            "--TextField-brandBorderHoverColor": "var(--border-color-Green)",
            "--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--TextField-brandBorderHoverColor)",
              transition: "0.5s all",
            },
            ".MuiOutlinedInput-notchedOutline": {
              transition: "0.5s all",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ITEM_HEIGHT = 48;
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "var(--button-bg-color-green)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 10,
    backgroundColor: "var(--button-bg-color-green)",
    border: "1px solid var(--border-color-Green)",
    borderRadius: "2px",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .MuiSlider-valueLabel": {
      color: "var(--text-color-Green)",
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "var(--button-bg-color-transparent)",
      transformOrigin: "bottom",
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "var(--button-bg-color-green)",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "yellow" : "#bbbbbb",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

function Index({
  metaquestionData,
  metaData,
  candidateList,
  hiringStages,
  handleChangeRejectStage,
  handleChangeHireStage,
  selectedOptionStage,
  handleChangeStage,
  toggleModal,
  handleDeleteCandidate,
  assessmentId,
  canInviteCandidate,
  candidateTableLoader,
  selectedOptionStatus,
  handleChangeStatus,
  searchCondidate,
  onChangeSearchCondidate,
  handelScoreFilterCandidateTable,
  mailCandidateReport,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
  candidateLoader,
  perpage,
  limit,
  handleLimitChange,
  totalItem,
  scoresValues,
  reSetScoresFun,
  clearSearchCandidateAndSetData,
  setCandidateList,
  setSortBy,
  setSortByquestionId,
  setSortByColumnName
}) {
	const navigate = useNavigate();
	const isMobile = useMediaQuery("(max-width:960px)");
	const isExtraSmMobile = useMediaQuery("(max-width:500px)");
	const isSmMobile = useMediaQuery("(max-width:610px)");
	const isMobiles = useMediaQuery("(max-width:750px)");
	const isTablet = useMediaQuery("(max-width:1010px)");
	const isLaptop = useMediaQuery("(max-width:1250px)");
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [anchorElArray, setAnchorElArray] = useState(
		new Array(candidateList.length).fill(null)
	);
	const [isListVisible, setListVisible] = useState(false);
	const collapseRef = useRef(null);
	const [hiddenColumns, setHiddenColumns] = useState([]);
	const [boferhiddenColumns, setBeforeHiddenColumns] = useState([]);
	const [applyButtonFlage, setApplyButtonFlage] = useState(false);
	const [hiringStatePopup, setHiringStatePopup] = useState(false);
	const [selectedOptionPopupStage, setSelectedOptionPopupStage] = useState("");
	const [hiringStatePopupAfterSubmit, setHiringStatePopupAfterSubmit] = useState(false);
	const [ascOrderPR, setAscOrderPR] = useState(false);
	const [ascOrderStatus, setAscOrderStatus] = useState(false);
	const [ascOrderAvgScore, setAscOrderAvgScore] = useState(false);
	const [sortedCandidates, setSortedCandidates] = useState(candidateList);
	const [dynamicColumnName, setDynamicColumnName] = useState("");
	const [dynamicColumnNameIcon, setDynamicColumnNameIcon] = useState(false);
	const handleSortByOverallRating = (column_name, column_id, question_id) => {
		// Sort candidates based on API call BY Narendra
		setDynamicColumnName(column_name);
		setDynamicColumnNameIcon(!dynamicColumnNameIcon);
		setSortBy(dynamicColumnNameIcon);
  		setSortByquestionId(question_id);
		setSortByColumnName("avg_per");
		 
	};

	const handleSortByAvgScore = (column_name)=>{
		if(column_name ==='avgscore'){
			setAscOrderAvgScore(!ascOrderAvgScore);
		}else if(column_name ==='status'){
			setAscOrderStatus(!ascOrderStatus);
		}else if(column_name ==="overallrating"){
			setAscOrderPR(!ascOrderPR);
		}
		const sortedList = [...sortedCandidates].sort((a, b) => {	
			let statusA,statusB = 0;
			if(column_name ==='avgscore'){
				statusA = (a.candidate_result.overall_per !== null) ? a.candidate_result.overall_per : 0;
				statusB = (b.candidate_result.overall_per !== null) ? b.candidate_result.overall_per : 0;
				if (ascOrderAvgScore) {
					return statusA - statusB;
				} else {
					return statusB - statusA;
				}
			}else if(column_name ==='status'){
				statusA = (a.status.id !== null) ? a.status.id : 0;
				statusB = (b.status.id !== null) ? b.status.id : 0;	
				if (ascOrderStatus) {
					return statusA - statusB;
				} else {
					return statusB - statusA;
				}
			}else if(column_name ==="overallrating"){
				statusA = (a.candidate_result.private_rating !== null) ? a.candidate_result.private_rating : 0;
				statusB = (b.candidate_result.private_rating !== null) ? b.candidate_result.private_rating : 0;
				if (ascOrderPR) {
					return statusA - statusB;
				} else {
					return statusB - statusA;
				}
			}
		});
		setCandidateList(sortedList);
	}
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
		document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (collapseRef.current && !collapseRef.current.contains(event.target)) {
		setListVisible(false);
		}
	};

	const handleResourceClick = (event) => {
		event.stopPropagation();
		setListVisible(!isListVisible);
	};

	const handleCloseCollapse = () => {
		setListVisible(false);
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: !isMobile ? "550px" : "420px",
		bgcolor: "white",
		border: "none",
		borderRadius: "8px",
		boxShadow: "0 8px 16px #00000040",
		p: 4,
	};
	const handleClick = (event, index) => {
		const newAnchorElArray = [...anchorElArray];
		newAnchorElArray[index] = event.currentTarget;
		setAnchorElArray(newAnchorElArray);
	};

	const handleCloseMenu = (index) => {
		const newAnchorElArray = [...anchorElArray];
		newAnchorElArray[index] = null;
		setAnchorElArray(newAnchorElArray);
	};
	

	const toggleColumnVisibility = (columnName) => {
		setApplyButtonFlage(true);
		if (hiddenColumns.includes(columnName)) {
		setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
		} else {
		setHiddenColumns([...hiddenColumns, columnName]);
		}
	};

	const applyColumnFun = () => {
		setBeforeHiddenColumns(hiddenColumns);
		setApplyButtonFlage(false);
		setListVisible(!isListVisible);
	};
	 
	const numberOfStars = 5;
	const outerTheme = useTheme();
	const handelRedirect = (id, invitation_id, index) => {
		index = (currentPage - 1) * perpage + (parseInt(index) + 1);
		navigate("/customer/candidate-details?page=" + index, {
		state: { assessmentId, id, invitation_id, page: "invite" },
		});
	};

	const handelCopyCandidateLink = (token) => {
		let link = candidateAssessmentUrl + token;
		navigator.clipboard.writeText(link);
		handleCloseMenu();
	};
	const [scoreValue, setScroreValue] = useState([20, 40]);

	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [isCheckedID, setIsCheckedID] = useState([]);
	useEffect(()=>{
		if(isCheckedAll === true){
			const newCandidateIDs = candidateList.filter(candidate => !isCheckedID.includes(candidate.id)).map(candidate => candidate.id);
			 const newUPdaIDs = [...isCheckedID, ...newCandidateIDs];
			setIsCheckedID(newUPdaIDs);
		}
	},[candidateList])
	const handleAllCheckboxChange = () => {
		const newIsCheckedAll = !isCheckedAll;
		setIsCheckedAll(newIsCheckedAll);
		if(newIsCheckedAll){
			setIsCheckedID(candidateList.map((item) => item.id));
		}else{
			setIsCheckedID([]);
		}
	};

	const handleRowCheckboxChange = (id, index) => {
		
		const indexs = isCheckedID.indexOf(id);
		if (indexs !== -1) {
			// If the value exists, remove it
			const newArray = [...isCheckedID.slice(0, indexs), ...isCheckedID.slice(indexs + 1)];
			setIsCheckedID(newArray);
		} else {
			// If the value doesn't exist, add it
			setIsCheckedID([...isCheckedID, id]);
		}
		allCheckedFun();
	};
 
	const countTrue = isCheckedID.reduce((count, currentValue) => {
		return count + (currentValue > 0 ? 1 : 0);
	}, 0); 
	const allCheckedFun = ()=>{ 
		const newIsCheckedAll = countTrue > 0? true: false; 
		setIsCheckedAll({prev : newIsCheckedAll});	
	}
	const handlehiringStatePopupClose = ()=>{
		setHiringStatePopup(!hiringStatePopup);
		setSelectedOptionPopupStage("");
	}

	const openHiringStage=()=>{		
		setHiringStatePopup(!hiringStatePopup);
	}

	const handleChangePopupStage=async(event)=>{
		setSelectedOptionPopupStage(event.target.value);        
	}
	const submitHiringStageINBulk = async()=>{
		if(selectedOptionPopupStage === ""){
			console.log("please select before");
			return false;
		}
		try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: isCheckedID,
                assessment_id: assessmentId,
                hiring_stage_id: selectedOptionPopupStage,
            };
            await axios.post(updateHiringStageInBulk, formsData, { headers })
			.then((response) =>{
				const res = response.data;
				if(res.status ===200){
					setHiringStatePopup(false);
					setHiringStatePopupAfterSubmit(true);
				}
			});
        } catch (error) {
            console.log(error);
        }
	}
	
	const closeHiringStatePopupAfterSubmitFun = ()=>{
		const filteredStages = hiringStages.filter(stage => selectedOptionPopupStage===stage.id);
		candidateList.forEach(candidate => {
			const filteredData =  isCheckedID.includes(candidate.id);
			if (filteredData) {
				candidate.hiring_stage = filteredStages[0].name;
				candidate.hiring_stage_id = filteredStages[0].id;
			}
		});
		setHiringStatePopupAfterSubmit(false);
	}
	const [sendReminderLoader, setSendReminderLoader] = useState(false);
	const submitSendReminder = async()=>{
		setSendReminderLoader(true);
		const filteredData = candidateList.filter(obj => isCheckedID.includes(obj.id));

		// Extract the name and email from the filtered data
		const result = filteredData.map(obj => ({ candidate_id: obj.id, candidate_email: obj.email, candidate_assessment_status:obj.status.id }));
		const formsData = {
			candidate_details: result,
			assessment_id: assessmentId
		};
		try{
			const headers = getHeaders();
			await axios.post(sendReminder, formsData, { headers })
			.then((response)=>{
				setSendReminderLoader(true);
				if(response.data.status === 200){
					console.log("successe", response.data);
				}
			})
			setSendReminderLoader(false);
		}catch(error){
			setSendReminderLoader(false);
		}
	}
	 
	const CurrentPageData = () => {
		const newCandidateIDs = candidateList.filter(candidate => isCheckedID.includes(candidate.id)).map(candidate => candidate.id);	
		return (newCandidateIDs.length);
	}
	
	const getClockTimer = (time_data)=>{
		 return <CountdownTimer time_data={time_data} />
	} 
	 
	const sendSingleReminderFun= async(candidate_id, email, status_id)=>{		
		try{
			const headers = getHeaders();
			const formData = {
				"candidate_details": [
					{
						"candidate_id": candidate_id,
						"candidate_email": email,
						"candidate_assessment_status": status_id
					},					
				],
				"assessment_id": assessmentId
			};
			await axios.post(sendReminder,formData,{headers})
			.then((response)=>{
				if(response.data.status===200){
					console.log("res:", response.data);
				}
			})
		}catch(error){
			console.log("error");
		}
	}
	const clearSearchCandidate = ()=>{
		clearSearchCandidateAndSetData();
	}
	return (
		<Box sx={{ bgcolor: "#EDEDED" }}>
			<Container maxWidth="xl">
				<Grid container spacing={0}>
					<Grid item xs={12} md={12} className="custom_class_condidate_tables">
						<Item
						sx={{
							mt: isMobile ? "16px" : "40px",
							boxShadow:
							"0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
							borderRadius: "8px",
							px:0,
						}}
						>
							<Box
								sx={{
								flexGrow: 1,
								display: "flex",
								alignItems: "center",
								my: 3,
								pl: 2,
								}}
							>
								<Grid sx={{}} container spacing={0}>
									<Grid xs={12} xl={3} lg={3} md={12}sm={12} item sx={{ display: "flex", alignItems: "center" }}
										
										
									>
										<Typography variant="h6" fontSize={"16px"} fontWeight={700}>
											CANDIDATES
											{candidateTableLoader && (
												<Box
												className="loaderForCondidate"
												sx={{
													display: "contents",
													justifyContent: "center",
													alignItems: "left",
												}}
												>
												<CircularProgress
													sx={{
													height: "20px",
													width: "20px",
													color: "var(--text-color-black)",
													}}
												/>
												</Box>
											)}
										</Typography>
									</Grid>

									<Grid item xs={12} xl={2} lg={2} md={3}sm={6}>
										<Item sx={{ boxShadow: "none", background: "none" }}>
										<ThemeProvider theme={customTheme(outerTheme)}>
											<TextField
												fullWidth
												label="Search Candidates"
												id="search"
												InputProps={{
													startAdornment: (
													<SearchIcon
														style={{
														color: "rgba(0, 0, 0, 0.54)",
														}}
													/>
													),
													endAdornment: searchCondidate? (
														<CloseIcon
															sx={{
															color: "rgba(0, 0, 0, 0.54)",
															cursor:"pointer",
															"&:hover":{background:"#0000000a", borderRadius:"50%"}
															}}
															onClick={clearSearchCandidate}
														/>
														):"",
												}}
												value={searchCondidate}
												onChange={onChangeSearchCondidate}
											/>
										</ThemeProvider>
										</Item>
									</Grid>
									<Grid item xs={12} xl={2} lg={2} md={3}sm={6}>
										<Item sx={{ boxShadow: "none", background: "none" }}>										
										<form noValidate autoComplete="off">
											<ThemeProvider theme={customTheme(outerTheme)}>
											<TextField
												id="outlined-basic"
												label="Score range"
												variant="outlined"
												sx={{
												bgcolor: "white",
												}}
												style={{ width: "100%" }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="end" sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width:"100%" }}>
															{(scoresValues.length > 1)?
																<IconButton sx={{justifyContent:"left",fontSize:"15px", color:"#000000DE", fontWeight:500,background:"transparent", height:"50px", width:"80%", borderRadius:"0px", "&:hover":{background:"unset"}  }} onClick={handleOpen}>
																	{(scoresValues.length > 1)?scoresValues[0]+"% to "+ scoresValues[1]+"%":""}
																</IconButton>
																:
																<IconButton sx={{justifyContent:"left",fontSize:"15px", color:"#000000DE", fontWeight:500,background:"transparent", height:"50px", width:"170px", borderRadius:"0px", width:"95%","&:hover":{background:"unset"}  }} onClick={handleOpen}>
																	 Score range
																</IconButton>
															}
															
														</InputAdornment>
														),
														
													endAdornment: scoresValues.length> 1? (
													<CloseIcon
														sx={{
														color: "rgba(0, 0, 0, 0.54)",
														cursor:"pointer",
														"&:hover":{background:"#0000000a", borderRadius:"50%"}
														}}
														onClick={reSetScoresFun}
													/>
													):"",
													readOnly: true,
												}}
											/>
											</ThemeProvider>
										</form>
										<Modal
											aria-labelledby="transition-modal-title"
											aria-describedby="transition-modal-description"
											open={open}
											onClose={handleClose}
											closeAfterTransition
											slots={{ backdrop: Backdrop }}
											slotProps={{
											backdrop: {
												timeout: 500,
											},
											}}
											className="RemoveOutLine"
											sx={{ backdropFilter: "blur(4px)" }}
										>
											<Fade in={open}>
											<Box sx={style}>
												<Typography
												id="transition-modal-title"
												variant="h6"
												sx={{
													fontSize: "14px",
													fontWeight: 400,
												}}
												component="h2"
												>
												Show candidates with scores between
												</Typography>

												<Box sx={{ p: 3 }} />
												<AirbnbSlider
												slots={{ thumb: AirbnbThumbComponent }}
												getAriaLabel={(index) =>
													index === 0 ? "Minimum price" : "Maximum price"
												}
												// defaultValue={}
												value={scoreValue}
												valueLabelDisplay="auto"
												marks={marks}
												onChange={(e) => {
													setScroreValue(e.target.value);
												}}
												/>

												<Typography
												id="transition-modal-description"
												sx={{
													mt: 2,
													fontSize: "12px",
													fontWeight: 400,
												}}
												>
												Note: Candidates without score won't be shown on
												this list.
												</Typography>
												<Grid sx={{ mt: 3 }} container spacing={0}>
												<Grid item xs={12} md={12}>
													<Item
													sx={{
														boxShadow: "none",
														background: "none",
													}}
													>
													{" "}
													<Grid container spacing={0}>
														<Grid
														item
														sx={{
															display: "flex",
															gap: "10px",
															justifyContent: "end",
														}}
														xs={12}
														md={12}
														>
														<Button
															sx={{
															color: "var(--text-color-black)",
															bgcolor: "#ededed",
															minWidth: "48px",
															minHeight: "48px",
															textTransform: "none",
															boxShadow: "none",

															"&:hover": {
																bgcolor: "#e0e0e0",
																boxShadow: "none",
															},
															}}
															variant="contained"
															onClick={handleClose}
														>
															Clear
														</Button>

														<Button
															sx={{
															bgcolor:
																"var(--button-bg-color-green)",
															minWidth: "48px",
															minHeight: "48px",
															textTransform: "capitalize",

															"&:hover": {
																bgcolor:
																"var(--button-hover-bg-color-LightGreen)",
															},
															}}
															variant="contained"
															onClick={() => {
															handelScoreFilterCandidateTable(
																scoreValue
															);
															handleClose();
															}}
														>
															Confirm
														</Button>
														</Grid>
													</Grid>
													</Item>
												</Grid>
												</Grid>
											</Box>
											</Fade>
										</Modal>
										</Item>
									</Grid>									 
									<Grid item xs={12} xl={3} lg={3} md={3}sm={6}>
										<Item sx={{ boxShadow: "none", background: "none", display:"flex", justifyContent:"center" }}>
										<ThemeProvider theme={customTheme(outerTheme)}>
											<Autocomplete
												disablePortal
												options={hiringStages}
												sx={{ width: "100%" }}
												id="stage-select-demo"
												autoHighlight
												getOptionLabel={(option) => option.name}
												onChange={handleChangeStage}												 
												renderOption={(props, option) => (
													<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
													<img
														loading="lazy"
														width="20"
														srcSet={`https://flagcdn.com/w40/${option.name.toLowerCase()}.png 2x`}
														src={`https://flagcdn.com/w20/${option.name.toLowerCase()}.png`}
														alt=""
													/>
													{option.name}
													</Box>
												)}
												renderInput={(params) => <TextField {...params} label="Select Hiring Stage" />}
												/>		
												</ThemeProvider>								 
										</Item>
									</Grid>
									<Grid item xs={12} xl={2} lg={2} md={3}sm={6}>
										<Item sx={{ boxShadow: "none", background: "none" }}>
										<Select
											labelId="demo-customized-select-label"
											id="demo-customized-select"
											input={<BootstrapInput />}
											value={selectedOptionStatus}
											onChange={handleChangeStatus}
											displayEmpty
											IconComponent={ExpandMoreIcon}
											inputProps={{
											"aria-label": "Select option",
											}}
											sx={{
											width: "100%",
											}}
										>
											<MenuItem value="" disabled>
											Status
											</MenuItem>

											<MenuItem value={"invited"}>Invited</MenuItem>
											<MenuItem value={"started"}>Started</MenuItem>
											<MenuItem value={"completed"}>Completed</MenuItem>
											<MenuItem value={"disqualified"}>Disqualified</MenuItem>
										</Select>
										</Item>
									</Grid>
								</Grid>
							</Box>
						
							{countTrue > 0 &&  
							<Grid sx={{backgroundColor:"var(--button-hover-bg-color-LightGreen)",padding:"20px", display:"flex",alignItems:"center",justifyContent:"space-between"}}>
								<Typography style={{ fontSize:"12px", fontWeight:600,color:"var(--text-color-OffWhite)"}}>
									{countTrue} selected ( { CurrentPageData()} on this page and { (countTrue - CurrentPageData()) } more from other pages)
								</Typography>	
								<Grid sx={{display:"flex", gap:"15px"}}>
									<BootstrapTooltip variant="contained" title="Change hiring stage">
										<ManageAccountsOutlinedIcon sx={{color:"var(--text-color-OffWhite)", fontSize:"27px", cursor:"pointer"}} onClick={openHiringStage}/>
									</BootstrapTooltip>
									<BootstrapTooltip variant="contained" title="Send reminder to candidates">
										{sendReminderLoader ? (<Grid><CircularProgress									
											sx={{ color: "var(--text-color-OffWhite)" }}
											size={30}
											/> 
											</Grid>) : (<NotificationsActiveOutlinedIcon sx={{color:"var(--text-color-OffWhite)", fontSize:"27px", cursor:"pointer"}} onClick={submitSendReminder}/>
										)}
									</BootstrapTooltip>
								</Grid>
								{hiringStatePopup &&
									 <Modal
										aria-labelledby="transition-modal-title"
										aria-describedby="transition-modal-description"
										open={hiringStatePopup}
										onClose={handlehiringStatePopupClose}
										closeAfterTransition
										slots={{ backdrop: Backdrop }}
										slotProps={{
										backdrop: {
											timeout: 500,
										},
										}}
										className="RemoveOutLine"
										sx={{ backdropFilter: "blur(4px)" }}
									>
										<Fade in={hiringStatePopup}>
											<Box sx={style}>
												<Typography
													id="transition-modal-title"
													variant="h5"
													sx={{ fontWeight: 600, fontSize: "20px" }} 
												>
													 Change hiring stage
												</Typography>
												<Typography
													id="transition-modal-title"
													variant="h6"
													sx={{
														fontSize:"14px",
														fontWeight:500, my:3
													}} 
													component="h6"
												>
													 Choose your hiring stage you want to set for all the selected candidates.
												</Typography>
 
												<Grid item xs={12} md={6}>
													<Item sx={{ boxShadow: "none", background: "none",padding:"0px" }}>
														<TableForm
															hiringStages={hiringStages}
															handleChangeHireStage={handleChangePopupStage}
															selectedStage={1}
															item={{ id: "" }}
															index={0}
														/>
													</Item>
												</Grid>
												<Grid sx={{background:"#ededed", px:1,py:2, mt:4, display:"flex",alignItems:"center", gap:"5px", borderRadius:"5px"}}>
													<HelpOutlineIcon sx={{fontSize:"14px"}}/>
													<Typography
														id="transition-modal-description"
														sx={{
															fontSize: "13px",
															fontWeight: 500,
														}}
													>
														Candidates who are currently in the hiring stage wont't be affected.
													</Typography>
												</Grid>
												<Grid sx={{ mt: 3 }} container spacing={0}>
													<Grid item xs={12} md={12}>
														<Item
															sx={{
																boxShadow: "none",
																background: "none",
															}}
														> 
															<Grid container spacing={0}>
																<Grid
																item
																sx={{
																	display: "flex",
																	gap: "10px",
																	justifyContent: "end",
																}}
																xs={12}
																md={12}
																>
																<Button
																	sx={{
																	color: "var(--text-color-black)",
																	bgcolor: "#ededed",
																	minWidth: "48px",
																	minHeight: "48px",
																	textTransform: "none",
																	boxShadow: "none",

																	"&:hover": {
																		bgcolor: "#e0e0e0",
																		boxShadow: "none",
																	},
																	}}
																	variant="contained"
																	onClick={handlehiringStatePopupClose}
																>
																	Close
																</Button>

																<Button
																	sx={{
																	bgcolor:
																		"var(--button-bg-color-green)",
																	minWidth: "48px",
																	minHeight: "48px",
																	textTransform: "capitalize",

																	"&:hover": {
																		bgcolor:
																		"var(--button-hover-bg-color-LightGreen)",
																	},
																	}}
																	variant="contained"
																	onClick={submitHiringStageINBulk}
																>
																	Change
																</Button>
																</Grid>
															</Grid>
														</Item>
													</Grid>
												</Grid>
											</Box>
										</Fade>
									</Modal>
								}
								{hiringStatePopupAfterSubmit &&
									<Modal
										aria-labelledby="transition-modal-title"
										aria-describedby="transition-modal-description"
										open={hiringStatePopupAfterSubmit}
										onClose={handlehiringStatePopupClose}
										closeAfterTransition
										slots={{ backdrop: Backdrop }}
										slotProps={{
										backdrop: {
											timeout: 500,
										},
										}}
										className="RemoveOutLine"
										sx={{ backdropFilter: "blur(4px)" }}
									>
										<Fade in={hiringStatePopupAfterSubmit}>
											<Box sx={style}>
											<Typography
													id="transition-modal-title"
													variant="h5"
													sx={{ fontWeight: 600, fontSize: "20px" }} 
												>
													 Change hiring stage
												</Typography>
												<Typography
													id="transition-modal-title"
													variant="h6"
													sx={{
														fontSize:"14px",
														fontWeight:500, mt:6
													}} 
													component="h6"
												>
													Bulk action started successfully!
												</Typography>


												<Typography
													id="transition-modal-title"
													variant="h6"
													sx={{
														fontSize:"14px",
														fontWeight:500, mt:3
													}} 
													component="h6"
												>
													You will receive an email with the execution result of your bulk action shortly.
												</Typography>

 
													  

											 
												<Grid sx={{ mt: 3 }} container spacing={0}>
													<Grid item xs={12} md={12}>
														<Item
															sx={{
																boxShadow: "none",
																background: "none",
															}}
														> 
															<Grid container spacing={0}>
																<Grid
																item
																sx={{
																	display: "flex",
																	gap: "10px",
																	justifyContent: "end",
																}}
																xs={12}
																md={12}
																>
																<Button
																	sx={{
																	color: "var(--text-color-black)",
																	bgcolor: "#ededed",
																	minWidth: "48px",
																	minHeight: "48px",
																	textTransform: "none",
																	boxShadow: "none",

																	"&:hover": {
																		bgcolor: "#e0e0e0",
																		boxShadow: "none",
																	},
																	}}
																	variant="contained"
																	onClick={closeHiringStatePopupAfterSubmitFun}
																>
																	Close
																</Button>
																</Grid>
															</Grid>
														</Item>
													</Grid>
												</Grid>
											</Box>
										</Fade>
									</Modal>
								}
							</Grid>	
							}
							<TableContainer
								sx={{
								bgcolor: "#fbfbfb",
								boxShadow: "none",
								borderRadius: "0px",
								}}
								style={{
								overflow: isListVisible ? "hidden" : "scroll",
								height:
									candidateList.length === 1
									? "170px"
									: candidateList.length > 1
									? " "
									: "250px",
								}}
								component={Paper}
							>
								{candidateLoader ? (
								<Grid
									sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									}}
								>
									<Table
									className="condidate_table"
									style={{ minWidth: "450px" }}
									aria-label="simple table"
									>
									<TableHead>
										<TableRow key="1">
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											Name
										</TableCell>
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											AVG. % SCORE
										</TableCell>

										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											HIRING STAGE
										</TableCell>
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											STATUS
										</TableCell>
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											INVITED ON
										</TableCell>
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											OVERALL RATING
										</TableCell>
										<TableCell
											className="condidate_tables setting_icon"
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
											style={{ position: "relative" }}
										>
											<ListItem onClick={handleResourceClick}>
											<SettingsOutlinedIcon
												sx={{
												color: "var(--text-color-black)",
												fontSize: "30px",
												cursor: "pointer",
												}}
											/>
											</ListItem>

											<Collapse
											sx={{
												position: "absolute",
												background: "white",
											}}
											in={isListVisible}
											timeout={500}
											unmountOnExit
											// onClose={handleCloseCollapse}
											></Collapse>
										</TableCell>
										</TableRow>
									</TableHead>
									<TableBody
										style={{
										cursor: "pointer",
										position: "relative",
										}}
									>
										<TableRowsLoader rowsNum={5} columnNum={7} />
									</TableBody>
									</Table>
								</Grid>
								) : (
								<Table
									className="condidate_table"
									style={{
									minWidth: "450px",
									float: isListVisible ? "right" : "",
									}}
									aria-label="simple table"
								>
									<TableHead>
									<TableRow key="1">										
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase", }}
										><Grid sx={{display:"flex", alignItems:"center", gap:"10px"}}>
											<Checkbox
												checked={countTrue}
												onChange={handleAllCheckboxChange}
												color="primary"
												inputProps={{ 'aria-label': 'select all rows' }}
											/>
											<Typography variant="h6" sx={{fontSize:"14px", fontWeight:700}}>
											Name
											</Typography>
											</Grid>
										</TableCell>
										{!boferhiddenColumns.includes("AVG. % SCORE") && (
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
											onClick={()=>handleSortByAvgScore('avgscore')}
										>
											<Grid sx={{display:"flex", alignItems:"center", gap:"5px"}}>
											AVG. % SCORE {ascOrderAvgScore? <ArrowUpwardIcon sx={{fontSize:"1.2rem"}} />:<ArrowDownwardIcon sx={{fontSize:"1.2rem"}} />}
											</Grid>
										</TableCell>
										)}

										{metaData?.map(
										(row, ind) =>
											!boferhiddenColumns.includes(row.name) &&
											row.question_category_id === 1 && (
											<TableCell
												key={ind}
												align="center"
												sx={{
												fontWeight: 700,
												textTransform: "uppercase",
												}}
												onClick={()=>handleSortByOverallRating(row.name, ind, row.question_id)}
											>
												{console.log("row:::", row)}
												<Grid sx={{display:"flex", alignItems:"center", gap:"5px"}}>
													{row.name} {dynamicColumnName===row.name && dynamicColumnNameIcon? <ArrowUpwardIcon sx={{fontSize:"1.2rem"}} />:<ArrowDownwardIcon sx={{fontSize:"1.2rem"}} />}
												</Grid>
												
											</TableCell>
											)
										)}
										{!boferhiddenColumns.includes("HIRING STAGE") && (
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											HIRING STAGE
										</TableCell>
										)}
										{!boferhiddenColumns.includes("STATUS") && (
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
											onClick={()=>handleSortByAvgScore('status')}
										>
											<Grid sx={{display:"flex", alignItems:"center", gap:"5px"}}>
												STATUS {ascOrderStatus? <ArrowUpwardIcon sx={{fontSize:"1.2rem"}} />:<ArrowDownwardIcon sx={{fontSize:"1.2rem"}} />}
											</Grid>
										</TableCell>
										)}
										{!boferhiddenColumns.includes("INVITED ON") && (
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
											INVITED ON
										</TableCell>
										)}
										{!boferhiddenColumns.includes("OVERALL RATING") && (
										<TableCell
											align="center"
											sx={{ fontWeight: 700, textTransform: "uppercase" }} 
											onClick={()=>handleSortByAvgScore('overallrating')}
										>
											<Grid sx={{display:"flex", alignItems:"center", gap:"5px"}}>
												OVERALL RATING {ascOrderPR? <ArrowUpwardIcon sx={{fontSize:"1.2rem"}}/>:<ArrowDownwardIcon sx={{fontSize:"1.2rem"}}/>}
											</Grid>
										</TableCell>
										)}
										<TableCell
										className={
											isListVisible
											? "setting_icon setting_iconzzz"
											: " setting_icon"
										}
										align="center"
										sx={{ fontWeight: 700, textTransform: "uppercase" }}
										>
										<ListItem
											sx={{ display: "flex", justifyContent: "end", pr:"10px" }}
											onClick={handleResourceClick}
										>
											<SettingsOutlinedIcon
											sx={{
												color: "var(--text-color-black)",
												fontSize: "30px",
												cursor: "pointer",
											}}
											/>
										</ListItem>

										<Collapse
										ref={collapseRef}
											sx={{
											position: "absolute",
											background: "white",
											zIndex: 1,
											boxShadow:
												"0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f",
											borderRadius: "4px",
											}}
											in={isListVisible}
											timeout={500}
											unmountOnExit
											// onClose={handleCloseCollapse}
										>
											<List
											className="LiBackground"
											sx={{
												height: isListVisible ? "auto" : 0,
												overflow: "scroll",
												p: 0,
												maxHeight: "290px",
											}}
											>
											<ListItem
												sx={{ py: 0 }}
												selected={"Pyxis" === "Pyxis"}
											>
												<FormControlLabel
												control={<Checkbox defaultChecked />}
												label={
													<span style={{ fontSize: "14px" }}>
													AVG. % SCORE{" "}
													</span>
												}
												checked={
													!hiddenColumns.includes("AVG. % SCORE")
												}
												onChange={() =>
													toggleColumnVisibility("AVG. % SCORE")
												}
												/>
											</ListItem>
											{metaData.map((option) => (
												<>
												{option.question_category_id === 1 && (
													<ListItem
													sx={{ py: 0 }}
													key={option}
													selected={option === "Pyxis"}
													>
													<FormControlLabel
														control={<Checkbox defaultChecked />}
														label={
														<span style={{ fontSize: "14px" }}>
															{" "}
															{option?.name.length > 10
															? option?.name.substring(0, 10) +
																"..."
															: option?.name}{" "}
														</span>
														}
														checked={
														!hiddenColumns.includes(option.name)
														}
														onChange={() =>
														toggleColumnVisibility(option.name)
														}
													/>
													</ListItem>
												)}
												</>
											))}

											<ListItem
												sx={{ py: 0 }}
												selected={"Pyxis" === "Pyxis"}
											>
												<FormControlLabel
												control={<Checkbox defaultChecked />}
												label={
													<span style={{ fontSize: "14px" }}>
													HIRING STAGE
													</span>
												}
												checked={
													!hiddenColumns.includes("HIRING STAGE")
												}
												onChange={() =>
													toggleColumnVisibility("HIRING STAGE")
												}
												/>
											</ListItem>
											<ListItem
												sx={{ py: 0 }}
												selected={"Pyxis" === "Pyxis"}
											>
												<FormControlLabel
												control={<Checkbox defaultChecked />}
												label={
													<span style={{ fontSize: "14px" }}>
													STATUS
													</span>
												}
												checked={!hiddenColumns.includes("STATUS")}
												onChange={() =>
													toggleColumnVisibility("STATUS")
												}
												/>
											</ListItem>
											<ListItem
												sx={{ py: 0 }}
												selected={"Pyxis" === "Pyxis"}
											>
												<FormControlLabel
												control={<Checkbox defaultChecked />}
												label={
													<span style={{ fontSize: "14px" }}>
													INVITED ON
													</span>
												}
												checked={
													!hiddenColumns.includes("INVITED ON")
												}
												onChange={() =>
													toggleColumnVisibility("INVITED ON")
												}
												/>
											</ListItem>
											<ListItem
												sx={{ py: 0 }}
												selected={"Pyxis" === "Pyxis"}
											>
												<FormControlLabel
												control={<Checkbox defaultChecked />}
												label={
													<span style={{ fontSize: "14px" }}>
													OVERALL RATING
													</span>
												}
												checked={
													!hiddenColumns.includes("OVERALL RATING")
												}
												onChange={() =>
													toggleColumnVisibility("OVERALL RATING")
												}
												/>
											</ListItem>
											</List>
											<Button
											sx={{
												bgcolor: applyButtonFlage
												? "var(--text-color-Green)"
												: "#0000001f",
												padding: "10px 20px",
												width: "90%",
												mb: "10px",
												"&:hover": {
												bgcolor: applyButtonFlage
													? "var(--button-hover-bg-color-LightGreen)"
													: "#0000001f",
												},
											}}
											variant="contained"
											onClick={applyColumnFun}
											>
											<Typography
												fontWeight={600}
												sx={{
												fontSize: "var(--btn-fontsize)",
												color: applyButtonFlage
													? "var(--text-color-OffWhite)"
													: "black",
												textTransform: "capitalize",
												}}
											>
												Apply
											</Typography>
											</Button>
										</Collapse>
										</TableCell>
									</TableRow>
									</TableHead>
									<TableBody
									style={{
										cursor: "pointer",
										position: "relative",
									}}
									> 
										 
											<>
											{candidateList.map((item, index) => (
												<TableRow
												key={index}
												sx={{
													"&:last-child td, &:last-child th": {
													border: 0,
													},
												}}
												>
													
												<TableCell
													align="center"                            
												>
													<Box sx={{display:"flex", alignItems:"center", gap:"10px"}}>
													<Checkbox
														// checked={isCheckedRows[index]}
														checked={isCheckedID.includes(item.id)}
														onChange={() => handleRowCheckboxChange(item.id, index)}
														color="primary"
														inputProps={{ 'aria-label': `select row ${index}` }}
													/>
													<Grid onClick={() =>
														handelRedirect(
															item.id,
															item.invitation_details.invitation_id,
															index
														)
														}>
														{(item?.name !== null && (item?.name).trim() !== '') ?
															<Typography variant="h6" sx={{fontSize:"16px", fontWeight:700}}>{item?.name}</Typography>
															:
															<Typography variant="body2" sx={{fontSize:"14px", fontWeight:500}}>{item?.email}</Typography>
														}
														
													</Grid>
													</Box>
												</TableCell>
												{!boferhiddenColumns.includes("AVG. % SCORE") && (
													<TableCell
													align="center"
													onClick={() =>
														handelRedirect(
														item.id,
														item.invitation_details.invitation_id,
														index
														)
													}
													>
													{item.candidate_result.overall_per ? (
														item.candidate_result.overall_per + " %"
													) : (
														<MinimizeOutlinedIcon
														sx={{ color: "#7e7575" }}
														/>
													)}
													</TableCell>
												)}
												{metaData?.map(
													(row, indd) =>
													!boferhiddenColumns.includes(row.name) &&
													row.question_category_id === 1 && (
														<>
														<TableCell
															key={indd}
															align="center"
															onClick={() =>
															handelRedirect(
																item.id,
																item.invitation_details.invitation_id,
																index
															)
															}
														>
															{item.candidate_result?.questionResult ? (
															<>
																{item.candidate_result?.questionResult?.filter(
																(result) =>
																	result.question_id ===
																	row.question_id &&
																	result.question_cat_id ===
																	row.question_category_id &&
																	result.question_type_id ===
																	row.question_type_id
																).length > 0 ? (
																item.candidate_result?.questionResult
																	?.filter(
																	(result) =>
																		result.question_id ===
																		row.question_id &&
																		result.question_cat_id ===
																		row.question_category_id &&
																		result.question_type_id ===
																		row.question_type_id
																	)
																	.map(
																	(res, i) =>
																		i === 0 &&
																		(res.avg_per !== null ? (
																		res.avg_per + " %"
																		) : (
																		<MinimizeOutlinedIcon
																			sx={{ color: "#7e7575" }}
																		/>
																		))
																	)
																) : (
																<MinimizeOutlinedIcon
																	sx={{ color: "#7e7575" }}
																/>
																)}
															</>
															) : (
															<MinimizeOutlinedIcon
																sx={{ color: "#7e7575" }}
															/>
															)}
														</TableCell>
														</>
													)
												)}
												{!boferhiddenColumns.includes("HIRING STAGE") && (
													<TableCell align="center">
													<TableForm
														hiringStages={hiringStages}
														handleChangeHireStage={handleChangeHireStage}
														selectedStage={item.hiring_stage_id}
														item={item}
														index={index}
													/>
													</TableCell>
												)}
												{!boferhiddenColumns.includes("STATUS") && (
													<TableCell
													align="center"
													onClick={() =>
														handelRedirect(
														item.id,
														item.invitation_details.invitation_id,
														index
														)
													}
													>
													{item?.status.name}
													</TableCell>
												)}
												{!boferhiddenColumns.includes("INVITED ON") && (
													<TableCell
													onClick={() =>
														handelRedirect(
														item.id,
														item.invitation_details.invitation_id,
														index
														)
													}
													align="center"
													>
													{item?.invited_on}
													</TableCell>
												)}
												{!boferhiddenColumns.includes("OVERALL RATING") && (
													<TableCell align="center">
													<Grid
														sx={{
														display: "flex",
														"& .MuiButton-startIcon": {
															margin: "0px",
														},
														"& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium":
															{
															fontSize: "25px",
															},
														}}
													>
														{Array.from({ length: numberOfStars }).map(
														(_, index) => (
															<Button
															key={index}
															style={{
																border: "none",
																minWidth: "0px",
																marginRight: "0px",
																padding: "0px",
															}}
															variant="outlined"
															startIcon={
																<StarRateRoundedIcon
																sx={{
																	margin: "0px",
																	color:
																	index <
																	item.candidate_result
																		.private_rating
																		? "#faaf00"
																		: "#b2b2b2",
																}}
																/>
															}
															></Button>
														)
														)}
													</Grid>
													</TableCell>
												)}

												<TableCell align="center">
													<Button
													id={`basic-button-${item.id}`}
													aria-controls={
														anchorElArray[index]
														? `basic-menu-${item.id}`
														: undefined
													}
													aria-haspopup="true"
													aria-expanded={
														anchorElArray[index] ? "true" : undefined
													}
													onClick={(event) => handleClick(event, index)}
									sx={{p:"6px 13px"}}
													>
													<MoreVertOutlinedIcon
														sx={{ color: "var(--text-color-black)" }}
													/>
													</Button>
													<Menu
													id={`basic-menu-${item.id}`}
													anchorEl={anchorElArray[index]}
													open={Boolean(anchorElArray[index])}
													onClose={() => handleCloseMenu(index)}
													MenuListProps={{
														"aria-labelledby": `basic-button-${item.id}`,
													}}
													sx={{
														"& .MuiPaper-root": {
														boxShadow: "0 0 10px 0 #0002",
														borderRadius: "8px",
														},
														"& .MuiPaper-root li": {
														px: 3,
														"&:hover": {
															backgroundColor: "#e9f0f1",
														},
														},
													}}
													>
													<MenuItem
														onClick={() => {
														handelCopyCandidateLink(
															item?.invitation_details?.token
														);
														handleCloseMenu(index);
														}}
													>
														<ContentCopyIcon sx={{ mr: 2 }} />
														Copy candidate link
													</MenuItem>

													<MenuItem
														onClick={() => {
														handleCloseMenu(index);
														mailCandidateReport(item);
														}}
														disabled={item?.status.name !== "Completed"}
													>
														<EmailOutlinedIcon sx={{ mr: 2 }} /> Send
														results
													</MenuItem>
													{(item.status.name==='Invited' || item.status.name==='Started') &&  (item.next_reminder_to_be_sent_after > 0 ?
														<MenuItem
														disabled
														>
															<NotificationsActiveIcon sx={{ mr: 2 }} />
															Send Reminder <span style={{paddingLeft:"10px"}}> { getClockTimer(item.next_reminder_to_be_sent_after)} </span>
														</MenuItem>
														:
														<MenuItem
															onClick={() => {
															sendSingleReminderFun(item.id, item.email, item.status.id);
															handleCloseMenu(index);
															}}
														>
															<NotificationsActiveIcon sx={{ mr: 2 }} />
															Send Reminder
														</MenuItem>)
													}
													<MenuItem
														onClick={() => {
														handleChangeRejectStage(item.id);
														handleCloseMenu(index);
														}}
													>
														<PersonOffOutlinedIcon sx={{ mr: 2 }} />
														Reject
													</MenuItem>
													<AllowedAccess permissions="delete_candidate">
														<MenuItem
														onClick={() => {
															handleDeleteCandidate(item.id);
															handleCloseMenu(index);
														}}
														>
														<DeleteOutlineOutlinedIcon sx={{ mr: 2 }} />
														Delete candidate
														</MenuItem>
													</AllowedAccess>
													</Menu>
												</TableCell>
												</TableRow>
											))}
											{candidateList.length <= 0 && (
												<Grid container spacing={2}>
												<Grid
													className="custom_class_team_member_parent"
													item
													sx={{
													position: "absolute",
													top: "18%",
													right: "45%",
													}}
													xs={12}
													md={12}
												>
													<Item
													sx={{
														boxShadow: "none",
														background: "none",
														mt: 5,
													}}
													>
													{canInviteCandidate === 1 ? (
														<AllowedAccess permissions="invite_my_assessment">
														<>
															<Typography
															variant="h2"
															sx={{
																mb: 2,
																fontSize: "20px",
																fontWeight: 700,
															}}
															>
															Invite candidates
															</Typography>
															<Button
															sx={{
																ml: "30px",
																bgcolor:
																"var(--button-bg-color-transparent)",
																color: "var(--text-color-black)",
																padding: "8px 16px",
																textTransform: "capitalize",
																boxShadow:
																"0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
																borderRadius: "4px",
																border:
																"1px solid var(--text-color-black)",
																"&:hover": {
																bgcolor: "#e0e0e0",
																boxShadow:
																	"0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
																},
															}}
															variant="contained"
															onClick={toggleModal}
															>
															<EmailOutlinedIcon
																sx={{
																mr: 1,
																color: "var(--text-color-black)",
																}}
															/>
															<Typography
																variant="body1"
																fontWeight={600}
																sx={{ fontSize: "18px" }}
															>
																Invite
															</Typography>
															</Button>
														</>
														</AllowedAccess>
													) : (
														<>
														<Typography
															className="custom_class_team_member"
															variant="h2"
															sx={{
															marginRight: "-126px",
															fontSize: "20px",
															fontWeight: 700,
															}}
														>
															Assessment have not any Question
														</Typography>
														</>
													)}
													</Item>
												</Grid>
												</Grid>
											)}
											</>
										 
									</TableBody>
								</Table>				   
								)}
								{/* {isSidebarOpen && <div className="sidebar"></div>} */}
							</TableContainer>
							<Grid container spacing={2}>
								{totalItem !== 0 ? (
								<Grid
									item
									xs={12}
									md={6}
									sx={{ display: "flex", alignItems: "center" }}
								>
									<Typography sx={{ marginRight: "1rem" }}>
									Total Page: {totalPages}
									</Typography>
									<Typography>Current page: {currentPage}</Typography>
								</Grid>
								) : (
								<Grid
									item
									xs={12}
									md={6}
									sx={{ display: "flex", alignItems: "center" }}
								></Grid>
								)}
								<Grid
								item
								xs={12}
								md={6}
								sx={{
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
								}}
								>
								<Typography>Rows Per Page</Typography>
								<FormControl sx={{ m: 1 }}>
									<Select
									value={limit}
									onChange={handleLimitChange}
									sx={{ height: "30px" }}
									>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={50}>50</MenuItem>
									<MenuItem value={100}>100</MenuItem>
									</Select>
								</FormControl>

								<Typography>
									{totalItem > 0
									? currentPage * perpage - perpage + 1 + "-"
									: ""}
									{currentPage * perpage > totalItem
									? totalItem
									: currentPage * perpage}{" "}
									of {totalItem}
								</Typography>
								<Grid sx={{ gap: "0px" }}>
									<Button
									onClick={handlePrevPage}
									disabled={currentPage === 1}
									>
									<ArrowBackIosNewIcon
										sx={{
										color:
											currentPage !== 1
											? "var(--border-color-Green)"
											: "",
										}}
									/>
									</Button>
									<Button
									onClick={handleNextPage}
									disabled={currentPage === totalPages}
									>
									<ArrowForwardIosIcon
										sx={{
										color:
											currentPage !== totalPages
											? "var(--border-color-Green)"
											: "",
										}}
									/>
									</Button>
								</Grid>
								</Grid>
							</Grid>
						</Item>
					</Grid>
				</Grid>
			</Container>
		</Box>
  	);
}

export default Index;
