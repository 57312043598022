import React, { useState } from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import { candidateAssessmentUrl } from "../../../../../../Constants/ConstantsUrl";

function ExampleComponent({ hiringStages, handleChangeHireStage, selectedStage, index, item, name }) {
  const dotColors = ['#ede7f2', 'grey', '#cca45f', '#cca45f', '#cca45f', '#cca45f', '#cca45f', '#b9d1cd', '#cb7b7a', '#cb7b7a', '#cb7b7a', '#cb7b7a', '#cb7b7a', "var(--button-bg-color-green)"]; // Define an array of dot colors

  const getSelectBackgroundColor = () => {
    return selectedStage ? dotColors[hiringStages?.findIndex(stage => stage.id === selectedStage)] : 'white';
  };

  const getSelectTextColor = () => {
    const backgroundColor = getSelectBackgroundColor();
    return backgroundColor === 'grey' || backgroundColor === '#cca45f' || backgroundColor === '#cb7b7a' || backgroundColor === "var(--button-bg-color-green)" ? 'white' : 'black';
  };

  return (
    <Select
    className='TableFormStyle'
      name={name}
      style={{ backgroundColor: getSelectBackgroundColor(), color: getSelectTextColor(),height:"40px" }} 
      value={selectedStage}
      onChange={(e) => handleChangeHireStage(e, index, item.id)}
      displayEmpty
      inputProps={{
        'aria-label': 'Select option',
      }}
      sx={{
        width: '100%',
        bgcolor: 'rgb(255 255 255)',
        '& .css-yf8vq0-MuiSelect-nativeInput': {
          fontSize: '12px',
        },
        '& .MuiSvgIcon-fontSizeMedium': {
          color: getSelectTextColor(),
        },
        '& ul': {
          padding:"0px"
        },
      }}
      MenuProps={{
        sx: {
          '& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            border:"1px solid #ccc",
            boxShadow:"none",
            borderRadius:"0px",
            borderTop:"none",
          },
          '& ul': {
            padding: '0px',  
          },
        },
      }}
      
    >
       
      {Array.isArray(hiringStages) &&  
        hiringStages.map((stage, mapIndex) => (
          <MenuItem sx={{ borderTop:"1px solid #ccc", }} key={stage.id} value={stage.id} style={{ paddingLeft: '16px' }}>  
            {mapIndex !== hiringStages.findIndex(stage => stage.id === selectedStage) &&  
              <span style={{ backgroundColor: dotColors[mapIndex], marginRight: '8px', width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block' }}></span>}
            <Typography variant="inherit" sx={{fontSize:"14px"}}>{stage.name}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
}

export default ExampleComponent;
