import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../../Constants/ConstantsUrl";
// import { HashLoader } from "react-spinners";
import Loader from "../Common/Loader"
import { Box } from "@mui/material";

export const ShowLoader = () => {
    return (
        <Box>
        <div className="loader-container"  style={{display:"flex", justifyContent: "center",alignItems: "center"}}>
            <Loader color={'#36D7B7'} size={200} />
        </div>
        </Box>
    );
};

export const useAxiosAuthGetCall = (url, alertShow = false, callBack = "") => {
    const navigate = useNavigate();
    const headers = getHeaders();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const fetchData = async () => {
        try {
            const response = await axios.get(url, { headers });
            const res = response.data;
            if (res.status) {
                setData(res.data);
                if (alertShow) {
                    SwalmodelSuccess(res.message, callBack);
                }
            } else {
                if (alertShow) {
                    SwalmodelFailed(res.message, callBack);
                }
            }
            
        } catch (error) {
            console.log(error)
            setError(true)
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.clear();
                navigate("/login");
            }else{
                if (alertShow) {
                    SwalmodelFailed(error.message, callBack);
                }
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {       
        fetchData();        
    }, []);

    return { data, error, loading };
};

export const useAxiosAuthGetCallSecound = (url, alertShow = false, callBack = "") => {
    const navigate = useNavigate();
    const headers = getHeaders();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const fetchData = async () => {
        try {
            const response = await axios.get(url, { headers });
            const res = response.data;
            if (res.status) {
                setData(res.data);
                if (alertShow) {
                    SwalmodelSuccess(res.message, callBack);
                }
                return () => {};
            } else {
                if (alertShow) {
                    SwalmodelFailed(res.message, callBack);
                }
                return () => {};
            }
            
        } catch (error) {
            console.log(error)
            setError(true)
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.clear();
                navigate("/login");
            }else{
                if (alertShow) {
                    SwalmodelFailed(error.message, callBack);
                }
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {       
        fetchData();        
    }, []);

    return { data, error, loading };
};

export const useAxiosAuthPostCall = (url, postData, alertShow = false, callBack = "") => {
    const navigate = useNavigate();
    const headers = getHeaders();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(url, postData, { headers });
                const res = response.data;
                if (res.success) {
                    setData(res.data);
                    if (alertShow) {
                        SwalmodelSuccess(res.message, callBack);
                    }
                    return () => {};
                } else {
                    if (alertShow) {
                        SwalmodelFailed(res.message, callBack);
                    }
                    return () => {};
                }
                
            } catch (error) {
                setError(true)
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    localStorage.clear();
                    navigate("/login");
                }else{
                    if (alertShow) {
                        SwalmodelFailed(error.message, callBack);
                    }
                }
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        return () => {
        };
    }, [url]);

    return { data, error, loading };
};

export const SwalmodelSuccess = (message, callback) => {
    Swal.fire({
        title: "Success",
        html: message,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor:  "var(--text-color-Green)",
    }).then(() => {
        if (callback && typeof callback === "function") {
            callback();
        }
    });
};

export const SwalmodelFailed = (message, callback) => {
    Swal.fire({
        title: "Failed",
        html: message,
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#ff0000",
    }).then(() => {
        if (callback && typeof callback === "function") {
            callback();
        }
    });
};