import { Box, Container, Button, Grid, Table, TableBody, Typography, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { getHeaders, updateTestWeight } from "../../../../../../Constants/ConstantsUrl";
import QuestionModal from "../../../../../Common/QuestionModal";
import { truncateWithEllipsis } from "../../../../../../helpers/String";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#c8d7de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#c8d7de",
    },
}));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));
const columns_custom_question = [
    {
        name: "question",
        label: <Typography style={{ fontSize: "18px", fontWeight: 600 }}>Question</Typography>,
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                return (
                    <BootstrapTooltip title={value}>
                        <Typography style={{ fontSize: "14px", fontWeight: 600 }}>{truncateWithEllipsis(value, 152)}</Typography>
                    </BootstrapTooltip>
                )
            },

        },
    },
    {
        name: "question_type_slug",
        label: <Typography style={{ fontSize: "18px", fontWeight: 600 }}>Type</Typography>,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "time",
        label: <Typography style={{ fontSize: "18px", fontWeight: 600 }}>Duration</Typography>,
        options: {
            filter: true,
            sort: false,
        },
    },
];

const data = [
    {
        test: "",
        duration: 0,
    },
];

const options = {
    filter: false,
    responsive: "standard",
    download: false,
    sort: false,
    selectableRows: "none", // Set to "none" to disable select functionality
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsSelected: [],
    print: false,
    viewColumns: false,
    searchOpen: true,
    searchText: "",
    search: false,
    customSearchRender: () => null,
    pagination: false,
    customStyles: {
        table: {
            root: {
                boxShadow: "none",
                color: "red",
                border: "5px solid blue",
            },
        },
    },
};

function Index({ toggleModalWeight, isModalOpenWeight, metaquestionData, weightQ = [], activeBox, boxClickFun, activeBoxImpact, assessmentId, reSetCall }) {
    const isMobile = useMediaQuery("(max-width:960px)");
    const isSmMobile = useMediaQuery("(max-width:899px)");
    const contentMarginLeft = isSmMobile ? "" : "16px";
    const ContentHeight = isSmMobile ? "16px" : "40px";
    const filteredData = metaquestionData.filter((item) => item.question_category_id === 2);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const saveFunCall = async () => {
        const data = {
            assessment_id: assessmentId,
            test_weight: weightQ,
        };
        try {
            const headers = getHeaders();
            await axios
                .post(updateTestWeight, data, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        toggleModalWeight();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const cancelFunCall = () => {
        toggleModalWeight();
        reSetCall();
    };

    function openQuestionModal(id) {
        setModalData({ id });
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const Modal = ({ onRequestClose }) => {
        const progress = 0;
        return (
            <Grid style={{ overflow: "auto" }} className="invite_popup_modal__backdrop">
                <Container
                    maxWidth={"md"}
                    sx={{
                        p: 4,
                        borderRadius: "8px",
                        bgcolor: "white",
                        maxWidth: "700px",
                        m: "50px auto",
                    }}
                // className="invite_popup_modal__container"
                >
                    <Grid
                        container
                        spacing={0}
                    // sx={{ mt: 1, padding: "32px 32px 0px 32px" }}
                    >
                        <Grid xs={11} md={11}>
                            <Typography variant="body1" sx={{ fontSize: "20px", fontWeight: 700 }}>
                                Set test weights
                            </Typography>
                        </Grid>
                        <Grid xs={1} md={1}>
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <CloseIcon onClick={onRequestClose} sx={{ cursor: "pointer" }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: "100%" }}>
                        <Box>
                            <Typography variant="body1" sx={{ fontSize: "14px", mt: 5, fontWeight: 400 }}>
                                Prioritize the tests that are most important to you by assigning them a weight. The weight of a test directly{" "}
                                <span style={{ fontWeight: 700, fontSize: "14px" }}> impacts your average score.</span>
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: "14px", mt: 2, fontWeight: 400 }}>
                                You can assign a weight value between <span style={{ fontWeight: 700, fontSize: "14px" }}>x1</span> and <span style={{ fontWeight: 700, fontSize: "14px" }}>x5</span> to
                                each test. A test with a weight of <span style={{ fontWeight: 700, fontSize: "14px" }}>x5</span> will boost the final score five times more than a test with a weight of{" "}
                                <span style={{ fontWeight: 700, fontSize: "14px" }}>x1</span>.
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: "14px", mt: 2, fontWeight: 400 }}>
                                How to work with test weights? <span style={{ fontWeight: 500, fontSize: "14px", color: "var(--text-color-Green)" }}>Read more</span>
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Table sx={{}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow sx={{ bgcolor: "#f6f6f6", height: "56px", borderRadius: "20px" }}>
                                        <TableCell align="start" sx={{ fontSize: "14px", fontWeight: 700, borderRadius: "10px 0px 0px 0px" }}>
                                            Tests
                                        </TableCell>
                                        <TableCell align="start" sx={{ fontSize: "14px", fontWeight: 700 }}>
                                            Weight
                                        </TableCell>
                                        <TableCell align="start" sx={{ fontSize: "14px", fontWeight: 700, borderRadius: "0px 10px 0px 0px" }}>
                                            Impact
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell scope="row" style={{ border: "none" }}></TableCell>
                                        <TableCell align="start" style={{ border: "none", width: "30%", paddingTop: "24px" }}>
                                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} className="progressBarcommonCssHeading">
                                                X1
                                            </Typography>
                                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} className="progressBarcommonCssHeading">
                                                X2
                                            </Typography>
                                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} className="progressBarcommonCssHeading">
                                                X3
                                            </Typography>
                                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} className="progressBarcommonCssHeading">
                                                X4
                                            </Typography>
                                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} className="progressBarcommonCssHeading">
                                                X5
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" style={{ border: "none" }}>
                                            {" "}
                                        </TableCell>
                                    </TableRow>
                                    {weightQ &&
                                        weightQ.map(
                                            (row, index) =>
                                                row.question_category_id === 1 && (
                                                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                        <TableCell scope="row" style={{ border: "none" }}>
                                                            {row.question.length > 20 ? row.question.slice(0, 20) + "..." : row.question}
                                                        </TableCell>
                                                        <TableCell align="start" style={{ border: "none" }}>
                                                            <div
                                                                className={activeBox[index]?.box1 >= 1 ? "progressBarcommonCss fstepDiv active" : "progressBarcommonCss fstepDiv"}
                                                                onClick={(e) => boxClickFun(1, index)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: `${progress}%`,
                                                                        height: "100%",
                                                                        background: "#4caf50",
                                                                        transition: "width 0.5s",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div
                                                                className={activeBox[index]?.box1 >= 2 ? "progressBarcommonCss active" : "progressBarcommonCss"}
                                                                onClick={(e) => boxClickFun(2, index)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: `${progress}%`,
                                                                        height: "100%",
                                                                        background: "#4caf50",
                                                                        transition: "width 0.5s",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div
                                                                className={activeBox[index]?.box1 >= 3 ? "progressBarcommonCss active" : "progressBarcommonCss"}
                                                                onClick={(e) => boxClickFun(3, index)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: `${progress}%`,
                                                                        height: "100%",
                                                                        background: "#4caf50",
                                                                        transition: "width 0.5s",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div
                                                                className={activeBox[index]?.box1 >= 4 ? "progressBarcommonCss active" : "progressBarcommonCss"}
                                                                onClick={(e) => boxClickFun(4, index)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: `${progress}%`,
                                                                        height: "100%",
                                                                        background: "#4caf50",
                                                                        transition: "width 0.5s",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div
                                                                className={activeBox[index]?.box1 >= 5 ? "progressBarcommonCss lstepDiv active" : "progressBarcommonCss lstepDiv"}
                                                                onClick={(e) => boxClickFun(5, index)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: `${progress}%`,
                                                                        height: "100%",
                                                                        background: "#4caf50",
                                                                        transition: "width 0.5s",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="start" style={{ border: "none" }}>
                                                            {activeBoxImpact[index].impact1} %
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box sx={{ borderBottom: 0, marginTop: 6 }}>
                            <Grid container spacing={0}>
                                <Grid xs={6} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            color: "var(--text-color-black)",
                                            border: "1px solid var(--text-color-black)",
                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            fontWeight: 600,
                                            textTransform: "none",
                                            "&:hover": {
                                                border: "1px solid var(--text-color-black)",
                                                bgcolor: "#f5f5f5",
                                            },
                                        }}
                                        onClick={reSetCall}
                                    >
                                        Reset weights
                                    </Button>
                                </Grid>
                                <Grid xs={6} md={6}>
                                    <Item
                                        sx={{
                                            boxShadow: "none",
                                            background: "none",
                                        }}
                                    >
                                        {" "}
                                        <Grid container spacing={0}>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    justifyContent: "end",
                                                }}
                                                xs={12}
                                                md={12}
                                            >
                                                <Button
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        bgcolor: "#ededed",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "none",
                                                        boxShadow: "none",

                                                        "&:hover": {
                                                            bgcolor: "#e0e0e0",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={cancelFunCall}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    sx={{
                                                        bgcolor: "var(--button-bg-color-green)",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "capitalize",
                                                        padding: " 6px 26px",
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={saveFunCall}
                                                >
                                                    Set
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Grid>
        );
    };

    const total_weight =
        Array.isArray(weightQ) &&
        weightQ.length > 1 &&
        weightQ.reduce((acc, row) => {
            if (row.question_category_id === 1) {
                return acc + parseFloat(row.weight);
            }
            return acc;
        }, 0);

    const showImpactValue = (impact, weight) => {
        if (impact === "" || typeof impact === "undefined") {
            const calculatPer = ((weight / total_weight) * 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return calculatPer + " %";
        } else {
            return impact + " %";
        }
    };
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#c8d7de",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#c8d7de",
        },
    }));
    return (
        <Box sx={{ bgcolor: "#EDEDED" }}>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    {isModalOpenWeight && <Modal onRequestClose={toggleModalWeight} />}
                    <Grid xs={12} md={6} sx={{ pr: contentMarginLeft }}>
                        <Item
                            sx={{
                                my: ContentHeight,
                                p: 0,
                                boxShadow: "0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
                                borderRadius: "8px",
                            }}
                        >
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <Grid container spacing={0}>
                                        <Grid xs={6} md={6} sx={{ pr: contentMarginLeft }}>
                                            <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: 600, py: 3, px: 2 }}>
                                                INCLUDE TEST
                                            </Typography>
                                        </Grid>
                                        {/* <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} xs={6} md={6}>
                                            <Item sx={{ boxShadow: "none", background: "none" }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        border: "1px solid var(--text-color-black)",
                                                        boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                        fontWeight: 600,
                                                        textTransform: "none",
                                                        "&:hover": {
                                                            border: "1px solid var(--text-color-black)",
                                                            bgcolor: "#f5f5f5",
                                                        },
                                                    }}
                                                    onClick={toggleModalWeight}
                                                >
                                                    Set test weights
                                                </Button>
                                            </Item>
                                        </Grid> */}
                                    </Grid>
                                    <Grid
                                        sx={{
                                            bgcolor: "white",
                                            p: 0,
                                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                            height: "500px",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead style={{ height: "60px" }}>
                                                <TableRow sx={{ bgcolor: "#fafafa " }}>
                                                    <TableCell align="start">Tests</TableCell>
                                                    <TableCell align="center">Type</TableCell>
                                                    {/* <TableCell align="center">Weight</TableCell>
                                                    <TableCell align="center">Impact</TableCell> */}
                                                    <TableCell align="center">Duration</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ backgroundColor: "white" }}>
                                                {weightQ &&
                                                    weightQ.map(
                                                        (row, index) =>
                                                            row.question_category_id === 1 && (
                                                                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                    <TableCell
                                                                        scope="row"
                                                                        sx={{
                                                                            width: "50%",
                                                                            color: "var(--button-bg-color-green)",
                                                                            cursor: "pointer",
                                                                            py: 2,

                                                                        }}
                                                                        onClick={() => openQuestionModal(row.question_id)}
                                                                    >
                                                                        <BootstrapTooltip title={row.question.length > 20 ? row.question.slice(0, 20) + "..." : row.question}>
                                                                            <Typography variant="body1" sx={{
                                                                                "&:hover": {
                                                                                    textDecoration: "underline",
                                                                                },
                                                                            }}> {row.question.length > 20 ? row.question.slice(0, 20) + "..." : row.question}</Typography>
                                                                        </BootstrapTooltip>
                                                                    </TableCell>
                                                                    <TableCell align="center" style={{ width: "30%" }}>
                                                                        {row.type}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center" style={{ width: "30%" }}>
                                                                        {row.weight}
                                                                    </TableCell> */}
                                                                    {/* <TableCell align="right" style={{ width: "20%" }}>
                                                                        {showImpactValue(activeBoxImpact[index]?.impact1, row.weight)}
                                                                    </TableCell> */}
                                                                    <TableCell align="center" style={{ width: "10%" }}>
                                                                        {row.time} min
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                    )}
                                            </TableBody>
                                        </Table>
                                        {weightQ.length === 0 &&
                                            <Grid sx={{ display: "flex", justifyContent: "center", marginTop: "25%" }}>
                                                <Typography variant="h5" fontWeight={500}>
                                                    No results match your search criteria
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid xs={12} md={6} sx={{ pl: contentMarginLeft }}>
                        <Item
                            sx={{
                                my: 5,
                                p: 0,
                                borderRadius: "8px",
                                boxShadow: "0 6px 7px -4px #8883, 0 11px 15px 1px #88888824, 0 4px 20px 3px #8888881f",
                            }}
                        >
                            <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: 600, py: 3, px: 2 }}>
                                CUSTOM QUESTIONS
                            </Typography>
                            <Grid
                                sx={{
                                    bgcolor: "white",
                                    p: 0,
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            >
                                <Grid sx={{ height: "500px", overflowY: "scroll" }}>
                                    <MUIDataTable data={filteredData} columns={columns_custom_question} options={options} className={filteredData.length === 0 ? "custom-mui-datatable data-not-fonud" : "custom-mui-datatable"}></MUIDataTable>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                {<QuestionModal isOpen={isModalOpen} onClose={closeModal} ids={modalData} setIsModalOpen={setIsModalOpen} />}
            </Container>
        </Box>
    );
}

export default Index;
