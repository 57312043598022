import { Box, Button, Container, Grid, Typography, TextField, Select, MenuItem, Backdrop, Modal, Fade, AccordionSummary } from "@mui/material";
import Navbar from "../../../Layouts/Index";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AlarmIcon from "@mui/icons-material/Alarm";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import TeamMember from "./TeamMember";
import AdvanceFields from "./AdvanceFields/Index";
import CandidateTable from "./CandidateTable/Index";
import IncludeTest from "./IncludeTest/Index";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation, Link } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import dayjs from "dayjs";
import axios from "axios";
import Loader from "../../../../Common/Loader";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
    getHeaders,
    DeleteCandidateFromAssessment,
    updateArchive,
    cloneAssessment,
    singleAssessmentGetData,
    getFileHeaders,
    getWebRole,
    addMembers,
    assessmentUploadCsvFile,
    getHiringStages,
    setCondidateHireStage,
    candidateResultPDFDownload,
    getSingleAssessmentCandidates,
    getAllWebRole,
    getAssessmentTeamMember,
    condidateInvite,
    renameAssessmentName,
    assessmentResultPDFDownload
} from "../../../../../Constants/ConstantsUrl";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AllowedAccess } from "react-permission-role";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Alerts from "../../../../Common/Alerts";
import { NestedMenuItem } from 'mui-nested-menu';
import EditIcon from '@mui/icons-material/Edit';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#c8d7de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#c8d7de",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));
const btnStyle = {
    color: "var(--text-color-OffWhite)",
    boxShadow: "unset",
    borderRadius: "8px",
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	border: "none",
	boxShadow: 24,
	p: 4,
	borderRadius: "8px",
};
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "#0a5c48",
                        "--TextField-brandBorderFocusedColor": "#276678",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            border: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

function Index() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const assessment_id = params.get("id");
    const { finished,inviteCall } = location.state || false;
    const [alertNotification, setAlertNotification] = useState([]);
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const FixGap = useMediaQuery("(max-width:1013px)");
    const isMobile = useMediaQuery("(max-width:960px)");
    // const SelectWidth = isMobile ? "100%" : "80%";
    const Formposition = isMobile ? "end" : "end";
    const [openLoader, setOpenLoader] = React.useState(false);
    const [isModalOpen, setModalIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isModalOpenWeight, setModalIsOpenWeight] = useState(false);
    const [isadddMemberModalOpen, setAddMemberModalIsOpen] = useState(false);
    const [manageAccessUser, setManageAccessUser] = useState([]);
    const [manageAccessUserCopy, setManageAccessUserCopy] = useState([]);
    const [webRoleData, setWebRoleData] = useState([]);
    const [webAllRoleData, setWebAllRoleData] = useState([]);
    const [metaData, setMetaData] = useState([]);
    const [metaquestionData, setMetaquestionData] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [candidateListCopy, setCandidateListCopy] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hiringStages, setHiringStages] = useState(null);
    const [uploadfileName, setUploadfileName] = useState(null);
    const [popupLoader, setPopupLoader] = useState(false);
    const [load, setLoad] = useState(true);
    const [singleAssessmentData, setSingleAssessmentData] = useState([]);
    const [weightQ, setWeightQ] = useState([]);
    const [selectedOptionStage, setSelectedOptionStage] = useState("");
    const [selectedOptionStatus, setSelectedOptionStatus] = useState("");
    const outerTheme = useTheme();
    const [fileLoader, setFileLoader] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);
    const [checkBoxVal, setCheckBoxVal] = useState(["avg", "hiring-stage", "status", "invited-on", "overall-rating"]);
    const [searchCondidate, setSearchCondidate] = useState("");
    const [roleSelectedOption, setRoleSelectedOption] = useState("allRole");
    const [searchTeamMemberValue, setSearchTeamMemberValue] = useState();
    const [canInviteCandidate, setCanInviteCandidate] = useState(0);
    const [csvxlsxFileUploadError, setCsvxlsxFileUploadError] = useState(false);
    const [candidateTableLoader, setCandidateTableLoader] = useState(false);
    const [candidateLoader, setCandidateLoader] = useState(false);
    const [isEmailSending, setIsEmailSending] = React.useState(false);
    const [formFinalData, setFormFinalData] = useState({
        name_of_assessment: "",
        language: "",
        total_time: "",
        deadline: "",
        public_url: "",
    });
    const [activeBox, setActiveBox] = useState([
        {
            box1: 1,
        },
    ]);

    const [activeBoxImpact, setActiveBoxImpact] = useState([
        {
            impact1: undefined,
            totalStp: 0,
            rowNo: 0,
        },
    ]);

    const [isPublicLink, setIsPublicLink] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perpage, setPerPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [getCloneLoader, SetCloneLoader] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [inviteCallState, setInviteCallState] = useState(false)
    const invitedModal = localStorage.getItem('invitedModal');
    const invitedByInviteCall = localStorage.getItem("invitedByInviteCall");
    const [scoresValues, setScoresValues] = useState([]);
    const [sortBy, setSortBy] = useState('asc');
    const [sortByquestionId, setSortByquestionId] = useState('');
    const [sortByColumnName, setSortByColumnName] = useState('');
    const [isRenameAssessmentModalOpen, setisRenameAssessmentModalOpen] =
    useState(false);
    const [renameModal, setRenameModal] = useState(false);

    useEffect(() =>{
        if(finished && invitedModal !== "false"){
            setOpenModal(true);
        }  
        localStorage.setItem('invitedModal', false);         
    },[])

    useEffect(()=>{
        getTeamMembersData();
        getWebRoleFun();
        getWebAllRoleFun();
        getHiringStagesFun();
        updateBoxValues(); 
    },[])

    useEffect(() => {
        if (assessment_id) {
            getAssessmentData(assessment_id);
        }          
    }, [assessment_id]);

    useEffect(() => {
        getSingleAssessmentCandidateData();
    }, [currentPage, limit, sortBy]);

    const getSingleAssessmentCandidateData = async () => {
        // add sorting parameters in api url by Narendra
        try {
            const headers = getHeaders();
            const sorttype = sortBy?"asc":"desc";
            await axios
                .get(getSingleAssessmentCandidates + assessment_id + "?page=" + currentPage + "&limit=" + limit + "&sortByColumnName=" + sortByColumnName + "&questionId=" + sortByquestionId + "&sorttype=" + sorttype, {
                    headers,
                })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setCandidateList(res.data.candidate_list);
                        setCandidateListCopy(res.data.candidate_list);
                        setTotalPages(res.data.pagination.last_page);
                        setPerPage(res.data.pagination.per_page);
                        setTotalItem(res.data.pagination.total);
                        // setCandidateTableLoader(false);
                        //setCandidateLoader(false);
                    }
                });
        } catch (error) {
            navigate("/customer/assessment");
            // setCandidateTableLoader(false);
            //setCandidateLoader(false);
        }
    };

    const exportToCSV = () => {
        setLoad(true);
    
        let question = [];
        let modifiedCandidateList = candidateListCopy.map((candidate) => {
            metaData?.map(
                (row, indd) => {
                    if(row.question_category_id === 1){
                        (question[row.name] = candidate.candidate_result?.questionResult
                            ? candidate.candidate_result?.questionResult?.filter(
                                (result) => result.question_id === row.question_id && result.question_cat_id === row.question_category_id && result.question_type_id === row.question_type_id
                            ).length > 0
                                ? candidate.candidate_result?.questionResult
                                    ?.filter(
                                        (result) => result.question_id === row.question_id && result.question_cat_id === row.question_category_id && result.question_type_id === row.question_type_id
                                    )
                                    .map((res, i) => i === 0 && (res.avg_per !== null ? res.avg_per + " %" : "-"))[0]
                                : "-"
                            : "")
                    }
                }
            );
            const completionDate = new Date(candidate.extra_data.completed);
            const formattedCompletionDate = completionDate.toISOString().split('T')[0];
            return {
                "First name": candidate.extra_data.first_name,
                "Last name": candidate.extra_data.last_name,
                Email: candidate.email,
                Source: candidate.extra_data.source,
                "Invitation date": candidate.invited_on,
                "Completion date": candidate.extra_data.completed ? formattedCompletionDate : candidate.extra_data.completed,
                Status: candidate.status,
                Stage: candidate.hiring_stage,
                "Scoring benchmark": "Percentage of correct answers",
                "Average score": candidate.candidate_result.overall_per,
                ...question,
                "Filled out only once from IP address": candidate.extra_data.fill_out_ip,
                "Webcam enabled": candidate.extra_data.web_cam_enabled,
                "Full-screen mode always active": candidate.extra_data.full_screen,
                "Mouse always in assessment window": candidate.extra_data.mouse,
                "Your rating": candidate.candidate_result.private_rating,
                "Notes about the candidate": candidate.extra_data.notes,
            };
        });
    
        const ws = XLSX.utils.json_to_sheet(modifiedCandidateList);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csv], { type: "text/csv" });
        FileSaver.saveAs(data, `${formFinalData?.name_of_assessment}.csv`);
        setLoad(false);
    };
    
    const exportToExcel = () => {
        setLoad(true);

        let question = [];
        let modifiedCandidateList = candidateListCopy.map((candidate) => {
            metaData?.map(
                (row, indd) => {
                    if(row.question_category_id === 1){
                        (question[row.name] = candidate.candidate_result?.questionResult
                            ? candidate.candidate_result?.questionResult?.filter(
                                (result) => result.question_id === row.question_id && result.question_cat_id === row.question_category_id && result.question_type_id === row.question_type_id
                            ).length > 0
                                ? candidate.candidate_result?.questionResult
                                    ?.filter(
                                        (result) => result.question_id === row.question_id && result.question_cat_id === row.question_category_id && result.question_type_id === row.question_type_id
                                    )
                                    .map((res, i) => i === 0 && (res.avg_per !== null ? res.avg_per + " %" : "-"))[0]
                                : "-"
                            : "")
                    }
                }
            );
            const completionDate = new Date(candidate.extra_data.completed);
            const formattedCompletionDate = completionDate.toISOString().split('T')[0];
            return {
                "First name": candidate.extra_data.first_name,
                "Last name": candidate.extra_data.last_name,
                Email: candidate.email,
                Source: candidate.extra_data.source,
                "Invitation date": candidate.invited_on,
                "Completion date": candidate.extra_data.completed ? formattedCompletionDate : candidate.extra_data.completed,
                Status: candidate.status.name,
                Stage: candidate.hiring_stage,
                "Scoring benchmark": "Percentage of correct answers",
                "Average score": candidate.candidate_result.overall_per,
                ...question,
                "Filled out only once from IP address": candidate.extra_data.fill_out_ip,
                "Webcam enabled": candidate.extra_data.web_cam_enabled,
                "Full-screen mode always active": candidate.extra_data.full_screen,
                "Mouse always in assessment window": candidate.extra_data.mouse,
                "Your rating": candidate.candidate_result.private_rating,
                "Notes about the candidate": candidate.extra_data.notes,
            };
        });
        const ws = XLSX.utils.json_to_sheet(modifiedCandidateList);
        let maxLengths = {};
        XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
            row.forEach((cell, i) => {
                if (cell !== null && cell !== undefined) {
                    const cellLength = cell.toString().length;
                    if (!maxLengths[i] || maxLengths[i] < cellLength) {
                        maxLengths[i] = cellLength;
                    }
                }
            });
        });
        // Set the column widths based on the maximum length of the data in each column
        Object.keys(maxLengths).forEach((i) => {
            ws['!cols'] = ws['!cols'] || [];
            ws['!cols'][i] = { wch: maxLengths[i] + 2 }; // Adding 2 for some padding
        });
        const wb = { Sheets: { Result: ws }, SheetNames: ["Result"] }; // Make sure the sheet name matches
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // Use correct MIME type for Excel files
        FileSaver.saveAs(data, `${formFinalData?.name_of_assessment}${fileExtension}`); // handleCloseMenu();
        setLoad(false);
    };

    const exportToPDF = async () => {
        try{
            const headers = getHeaders()
            const sendData = {
                assessment_id: assessment_id
            };
            await axios
            .post(assessmentResultPDFDownload, sendData, {headers, responseType: "blob",})
            .then((response)=>{
                // console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.pdf");
                document.body.appendChild(link)
;
                link.click();
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
        }
        catch (error) {
            navigate("/customer/assessment");
        }
    }

    const getHiringStagesFun = async () => {
        //setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(getHiringStages, { headers })
                .then((response) => response)
                .then((response) => {
                    const res_data = response.data;
                    if (res_data.status === 200) {
                        setHiringStages(res_data.data);
                        //setLoad(false);
                    }
                });
        } catch (error) {
            console.log(error);
            //setLoad(false);
        }
    };

    const DeleteCandidate = async (id) => {
        // setOpenLoader(true);
        try {
            const headers = getHeaders();
            const data = { assessment_id: assessment_id, candidate_id: id };
            await axios
                .post(DeleteCandidateFromAssessment, data, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        getSingleAssessmentCandidateData();
                        getTeamMembersData();
                        // setOpenLoader(false);
                    }
                });
        } catch (error) {
            console.log(error);
            // setOpenLoader(false);
        }
    };

    const handleDeleteCandidate = (candidateid) => {
        DeleteCandidate(candidateid);
    };

    const getTeamMembersData = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getAssessmentTeamMember + `/${assessment_id}`, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        setManageAccessUser(response.data.data?.user_details);
                        setManageAccessUserCopy(response.data.data?.user_details);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getAssessmentData = async (ids) => {
        setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(singleAssessmentGetData + ids, {
                    headers,
                })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setIsPublicLink(res.data.is_public_active == 1 ? true : false);
                        setCanInviteCandidate(res.data.can_invite_candidate);
                        setSingleAssessmentData(res.data);
                        // getSingleAssessmentCandidateData(assessment_id);
                        // setCandidateList(res.data.candidate_list);
                        // setCandidateListCopy(res.data.candidate_list);
                        setWeightQ(res.data.meta);
                        const metaData = res.data.meta;
                        const lastMetaData = [...metaData];
                        metaData.map((valu, index) => {
                            if (valu?.question_category_slug == "tnh-official-questions") {
                                lastMetaData[index] = {
                                    ...lastMetaData[index],
                                    name: valu.question.length > 10 ? valu.question.substring(0, 10) : valu.question,
                                    question_id: valu.question_id,
                                    question_type_id: valu.question_type_id,
                                    question_category_id: valu.question_category_id,
                                    question_category_slug: valu.question_category_slug,
                                };
                            }
                        });
                        setMetaData((prevOptions) => lastMetaData);
                        if (lastMetaData) {
                            lastMetaData.map((value) => {
                                if (!checkBoxVal.includes(value.name)) {
                                    checkBoxVal.push(value.name);
                                }
                            });
                            setCheckBoxVal(checkBoxVal);
                        }
                        setMetaquestionData(res.data.meta);
                        setFormFinalData({
                            name_of_assessment: res.data.name_of_assessment,
                            language: res.data.language,
                            total_time: res.data.total_time,
                            deadline: res.data.deadline ? dayjs(res.data.deadline).format("DD-MM-YYYY") : "",
                            public_url: res.data.public_url,
                            invite_user_url: res.data.invite_user_url
                        });                        
                        if(res.data.can_invite_candidate > 0 && (inviteCall && invitedByInviteCall !== "false")){
                            setInviteCallState(prev => null);
                            localStorage.setItem('invitedByInviteCall', false);
                            toggleModal();
                        }
                        setTimeout(() => {
                            setLoad(false);
                        }, 2000);
                    }
                });
        } catch (error) {
            navigate("/customer/assessment");
            setLoad(false);
        }
    };

    const handleLimitChange = (e) => {
        // reset current page on change limit  y Narendra
        setCurrentPage(1);
        setLimit(e.target.value);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const toggleModal = () => {
        if(isModalOpen){
            setInviteCallState(prev => null);
        }
        setModalIsOpen(!isModalOpen);
    };

    const toggleModalWeight = () => {
        setModalIsOpenWeight(!isModalOpenWeight);
        updateBoxValues();
    };

    const addMembertoggleModal = () => {
        handleCloseMenu();
        setAddMemberModalIsOpen(!isadddMemberModalOpen);
    };

    const handleCloneCall = async (id) => {
        SetCloneLoader(true);
        try {
            const headers = getHeaders();
            const data = { assessment_id: id };
            await axios
                .post(cloneAssessment, data, { headers })
                .then((response) => response)
                .then((response) => {
                    SetCloneLoader(false);
                    if (response.status === 200) {
                        handleCloseMenu();
                        navigate("/customer/invite?id=" + response.data.data.id);
                    }
                });
        } catch (error) {
            SetCloneLoader(false);
            console.log(error);
        }
    };

    const updateArchiveFun = async (ass_id) => {
        try {
            const headers = getHeaders();
            const data = {
                assessment_id: ass_id,
            };
            await axios
                .post(updateArchive, data, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.status === 200) {
                        navigate("/customer/assessment");
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getWebRoleFun = async () => {
        //setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(getWebRole, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setWebRoleData(res.data);
                        //setLoad(false);
                    }
                });
        } catch (error) {
            console.log(error);
            //setLoad(false);
        }
    };

    const getWebAllRoleFun = async () => {
        //setLoad(true);
        try {
            const headers = getHeaders();
            await axios
                .get(getAllWebRole, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setWebAllRoleData(res.data);
                        //setLoad(false);
                    }
                });
        } catch (error) {
            console.log(error);
            //setLoad(false);
        }
    };

    const uploadLinkFile = (e) => {
        setUploadingFile(true);
        setCsvxlsxFileUploadError(false);
        const file = e.target.files[0];
        if (file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setUploadfileName(file.name);
            setSelectedFile(file);
        } else {
            console.log("File format not accept :::", file.type);
            setUploadingFile(false);
            return false;
        }
        setUploadingFile(false);
        
    };

    const sebmitUploadedFile = async () => {
        setFileLoader(true);
        try {
            if (selectedFile !== null) {
                const headers = getFileHeaders();
                const formData = new FormData();
                formData.append("file", selectedFile);
                formData.append("assessment_id", assessment_id);
                await axios.post(assessmentUploadCsvFile, formData, { headers, responseType: "blob" }).then(async (response) => {
                    setFileLoader(false);
                    const contentType = response.headers["content-type"];
                    if (contentType.includes("application/json")) {
                        setModalIsOpen(!isModalOpen);
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: "All Candidates Invited!!",
                        });
                        getSingleAssessmentCandidateData();
                    } else {
                        const blob = new Blob([response.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "candidate_error.xlsx");
                        document.body.appendChild(link);
                        link.click();

                        setAlertNotification({
                            status: true,
                            type: "error",
                            message: "Some Candidates are not invited, please see the file for reasons",
                        });
                    }
                });
            } else {
                setFileLoader(false);
                setCsvxlsxFileUploadError(true);
            }
        } catch (error) {
            setFileLoader(false);
            console.log(error);
        }
    };

    const handleChangeRejectStage = async (c_id) => {
        // setOpenLoader(true);
        const condidateData = { ...candidateList };
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: c_id,
                assessment_id: assessment_id,
                hiring_stage_id: 12,
            };
            await axios
                .post(setCondidateHireStage, formsData, { headers })
                .then((response) => response)
                .then((response) => {
                    getSingleAssessmentCandidateData();
                    // setOpenLoader(false);
                });
        } catch (error) {
            console.log(error);
            // setOpenLoader(false);
        }

        const dataArray = Object.values(condidateData);
        setCandidateList(dataArray);
    };

    const handleChangeHireStage = async (e, index, c_id) => {
        setCandidateTableLoader(true);
        const condidateData = { ...candidateList };
        condidateData[index].hiring_stage_id = e.target.value;
        try {
            const headers = getHeaders();
            const formsData = {
                candidate_id: c_id,
                assessment_id: assessment_id,
                hiring_stage_id: e.target.value,
            };
            await axios
                .post(setCondidateHireStage, formsData, { headers })
                .then((response) => response)
                .then((response) => {
                    setCandidateTableLoader(false);
                });
        } catch (error) {
            console.log(error);
            setCandidateTableLoader(false);
        }

        const dataArray = Object.values(condidateData);
        setCandidateList(dataArray);
    };

    const handleChangeStage = (event, newvalue) => {
        setLoad(true);
        setSelectedOptionStage(newvalue);
        const eventValue = newvalue?.id;
        if (eventValue === "" || eventValue === undefined) {
            setCandidateList(candidateListCopy);
            setLoad(false);
            return false;
        } else {
            const filteredArray = candidateListCopy.filter((item) => item.hiring_stage_id === eventValue);
            setCandidateList(filteredArray);
        }
        setLoad(false);
    };

    const handleChangeStatus = (event) => {
        setLoad(true);
        setSelectedOptionStatus(event.target.value);
        const eventValue = event.target.value;
        if (eventValue === "") {
            setCandidateList(candidateListCopy);
            setLoad(false);
            return false;
        } else {
            const filteredArray = candidateListCopy.filter((item) => item.status.name.toLowerCase() === eventValue);
            setCandidateList(filteredArray);
        }
        setLoad(false);
    };

    const onChangeSearchCondidate = (event) => {
        setLoad(true);
        setSearchCondidate(event.target.value);
        const eventValue = event.target.value;
        if (eventValue === "") {
            setCandidateList(candidateListCopy);
            setLoad(false);
            return false;
        } else {
            const filteredArray = candidateListCopy.filter((item) => item.name.toLowerCase().includes(eventValue.toLowerCase()) || item.email.toLowerCase().includes(eventValue.toLowerCase()));
            setCandidateList(pre=> filteredArray);
        }
        setLoad(false);
    };
    const clearSearchCandidateAndSetData = ()=>{
        setSearchCondidate("");
        setCandidateList(candidateListCopy);
        setLoad(false);
        return false;
    }

    const handelScoreFilterCandidateTable = (scores) => {
        setLoad(true);
        setScoresValues(scores);
        const filteredArray = candidateListCopy.filter((item) => {
            let overall_per = (item?.candidate_result?.overall_per !== null)? item?.candidate_result?.overall_per:0;
            return overall_per > scores[0] && overall_per < scores[1];
        });
        setCandidateList(pre =>filteredArray);
        setLoad(false);
    };

    const reSetScoresFun = ()=>{
        setScoresValues([]);
        setCandidateList(candidateListCopy);
    }

    const mailCandidateReport = async (item) => {
        setIsEmailSending(true);
        let response; // Declare response outside try-catch
        try {
            const sendData = {
                assessment_id: assessment_id,
                candidate_id: item.id,
                send_mail: 1,
            };
            const headers = getHeaders();
            response = await axios.post(candidateResultPDFDownload, sendData, { headers });
            // Handle success inside try block if you want immediate feedback
            setAlertNotification({
                status: true,
                type: "success",
                message: response.data.data.message, // Presuming your API returns a message on success
            });
        } catch (error) {
            console.error("Error mailing PDF file:", error);
            // Handle error notification here
            setAlertNotification({
                status: true,
                type: "error",
                message: error.response?.data?.message || "Failed to send the email.",
            });
        } finally {
            setIsEmailSending(false); // Stop loading regardless of the outcome
        }
    };

    const AddMemberModal = ({ onAddRequestClose }) => {
        const [moreAddOptions, setMoreAddOptions] = useState([
            {
                role_id: 5,
                email: "",
            },
        ]);
        const [errorMsg, setErrorMsg] = useState([
            {
                role_id: false,
                email: false,
            },
        ]);
        const handleInputChange = (event, index) => {
            // Copy the array using spread operator
            const updatedOptions = [...moreAddOptions];
            // Update the specific element in the array at the given index
            updatedOptions[index] = {
                ...updatedOptions[index],
                [event.target.name]: event.target.value,
            };
            // Update the state using the functional form of setMoreAddOptions
            setMoreAddOptions((prevOptions) => updatedOptions);
            const updatedfiledOptions = [...errorMsg];
            updatedfiledOptions[index] = {
                ...updatedfiledOptions[index],
                [event.target.name]: event.target.name ? false : true,
            };
            setErrorMsg((prevOptions) => updatedfiledOptions);
        };

        let validationCheck = (value) => {
            let data = false;
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
                data = true;
            }
            return data;
        };

        const handleSubmitadd = async () => {
            const emailValid = [];
            const updatedfiledOptions = [...errorMsg];
            moreAddOptions.map((data, index) => {
                if (data?.email !== "") {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                        updatedfiledOptions[index] = {
                            ...updatedfiledOptions[index],
                            role_id: data?.role_id ? false : true,
                            email: true,
                        };
                    } else {
                        emailValid.push(data.email);
                    }
                }
                if (data?.role_id === "" || data?.email === "") {
                    updatedfiledOptions[index] = {
                        ...updatedfiledOptions[index],
                        role_id: data?.role_id ? false : true,
                        email: data?.email ? validationCheck(data) : true,
                    };
                }
            });
            setErrorMsg((prevOptions) => updatedfiledOptions);
            if (emailValid.length !== moreAddOptions.length) {
                return false;
            } else {
                submitFunction(moreAddOptions);
            }
        };
        const submitFunction = async (data) => {
            const dataSended = { user_details: data };
            try {
                const headers = getHeaders();
                await axios
                    .post(addMembers, dataSended, { headers })
                    .then((response) => response)
                    .then((response) => {
                        const res = response.data;
                        if (res.status === 200) {
                            const combinedData = [...manageAccessUser, res.data.user_details[0]];
                            setManageAccessUser(combinedData);
                            addMembertoggleModal();
                        }
                    });
                setPopupLoader(false);
            } catch (error) {
                const retundata = error.response.data.data;
                const createobj = Object.keys(retundata);
                const updatedfiledOptions = [...errorMsg];
                createobj?.map((data, indes) => {
                    let numberIndex = data.split(".");
                    updatedfiledOptions[numberIndex[1]] = {
                        ...updatedfiledOptions[numberIndex[1]],
                        email: retundata[data],
                        exist_error: true,
                    };
                });
                setErrorMsg((prevOptions) => updatedfiledOptions);
                return false;
            }
        };
        const handleAddOption = () => {
            if (moreAddOptions.length < 10) {
                setMoreAddOptions([
                    ...moreAddOptions,
                    {
                        role_id: "5",
                        email: "",
                    },
                ]);
            }
        };
        const handleDeleteOption = (index) => {
            if (moreAddOptions.length > 1) {
                const updatedOptions = moreAddOptions.filter((_, i) => i !== index);
                setMoreAddOptions(updatedOptions);
            }
        };

        return (
            <Box sx={{ overflow: "auto" }} className="invite_popup_modal__backdrop">
                <Container
                    maxWidth={"sm"}
                    sx={{
                        borderRadius: "8px",
                        bgcolor: "white",
                        maxWidth: "100%",
                        m: "50px auto",
                    }}
                >
                    <Box sx={{ py: 3 }}>
                        <Grid container spacing={0} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={11}>
                                <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>Add new team members</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                            <Typography sx={{ fontWeight: 400, fontSize: "16px", color: "#0a5c48" }}>You can always add and edit team members in the settings.</Typography>
                            <Grid container spacing={0} sx={{ mt: 1 }}>
                                <Grid
                                    sx={{
                                        maxHeight: "220px",
                                        overflowY: "auto",
                                        width: "100%",
                                        overflowX: "hidden",
                                    }}
                                >
                                    {moreAddOptions &&
                                        moreAddOptions.map((data, index) => (
                                            <>
                                                <Grid key={index} sx={{}} container spacing={0}>
                                                    <Grid item xs={11} md={11}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "none",
                                                                background: "none",
                                                            }}
                                                            className="text"
                                                        >
                                                            <Grid sx={{ position: "relative" }}>
                                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                                    <TextField
                                                                        className="InviteFormWidth"
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        name="email"
                                                                        value={data?.email}
                                                                        error={errorMsg[index]?.email ? true : false}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        sx={{ width: "100%" }}
                                                                        label={!isMobile ? "Teammate's work email" : "Teammate's email"}
                                                                    />

                                                                    <Select
                                                                        value={data?.role_id}
                                                                        name="role_id"
                                                                        defaultValue="5"
                                                                        IconComponent={ExpandMoreIcon}
                                                                        inputProps={{
                                                                            "aria-label": "Select option",
                                                                        }}
                                                                        sx={{
                                                                            position: "absolute",
                                                                            right: "0px",
                                                                            top: "50%",
                                                                            transform: "translateY(-50%)",
                                                                            color: "var(--text-color-black)",
                                                                            boxShadow: "unset",
                                                                            padding: "0px 20px ",
                                                                            cursor: "pointer",
                                                                            backgroundColor: "var(--button-bg-color-transparent)",
                                                                            fontSize: "14px",
                                                                            borderRadius: "0px",
                                                                            "&:hover": {
                                                                                backgroundColor: "#ededed",
                                                                            },
                                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                                border: "none",
                                                                                borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                                borderRadius: "0px",
                                                                                "&:hover": {
                                                                                    borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                                    borderRadius: "0px",
                                                                                },
                                                                            },
                                                                        }}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                    >
                                                                        {webRoleData?.map((val, index) => (
                                                                            <MenuItem key={index} value={val.id}>
                                                                                {val.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </ThemeProvider>
                                                            </Grid>
                                                        </Item>
                                                        {errorMsg[index]?.email ? (
                                                            errorMsg[index]?.email === true ? (
                                                                <Grid className="error_class">
                                                                    <div className="error_class_font_color">{errorMsg[index]?.exist_error ? "Email has already been taken" : "Email is required"}</div>
                                                                </Grid>
                                                            ) : (
                                                                <div className="error_class_font_color">
                                                                    {errorMsg[index]?.email}
                                                                </div>
                                                            )
                                                        ) : null}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        xs={1}
                                                        md={1}
                                                    >
                                                        <Button sx={{ "&:hover": { background: "unset" } }} fullWidth id="fullWidth" onClick={() => handleDeleteOption(index)}>
                                                            {" "}
                                                            <CloseIcon sx={{ color: "var(--text-color-black,)" }} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ))}
                                </Grid>
                                <Grid
                                    sx={{
                                        mt: 5,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            textTransform: "none",
                                            fontSize: "16px",
                                            display: "contents",
                                            color: "var(--text-color-Green)",
                                            cursor: "pointer",
                                        }}
                                        variant="body1"
                                        onClick={handleAddOption}
                                    >
                                        {
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    color: "var(--text-color-Green)",
                                                    fontSize: "27px",
                                                    mr: 1,
                                                }}
                                            ></AddCircleOutlineIcon>
                                        }
                                        Add more
                                    </Typography>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={12}>
                                        <Item
                                            sx={{
                                                boxShadow: "none",
                                                background: "none",
                                            }}
                                        >
                                            {" "}
                                            <Grid container spacing={0}>
                                                <Grid
                                                    item
                                                    sx={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        justifyContent: "end",
                                                    }}
                                                    xs={12}
                                                    md={12}
                                                >
                                                    <Button
                                                        sx={{
                                                            color: "var(--text-color-black)",
                                                            bgcolor: "#ededed",
                                                            minWidth: "48px",
                                                            minHeight: "48px",
                                                            textTransform: "none",
                                                            boxShadow: "none",
                                                            mb: 2,
                                                            "&:hover": {
                                                                bgcolor: "#e0e0e0",
                                                                boxShadow: "none",
                                                            },
                                                        }}
                                                        variant="contained"
                                                        onClick={onAddRequestClose}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        sx={{
                                                            bgcolor: "var(--button-bg-color-green)",
                                                            minWidth: "48px",
                                                            minHeight: "48px",
                                                            textTransform: "capitalize",
                                                            mb: 2,
                                                            "&:hover": {
                                                                bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                            },
                                                        }}
                                                        variant="contained"
                                                        onClick={handleSubmitadd}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    };

    const updateBoxValues = () => {
        const actdata = { ...activeBox };
        //let total_weight = weightQ.reduce((acc, row) => acc + parseFloat(row.weight), 0);
        let total_weight = weightQ.reduce((acc, row) => {
            // Replace 'important' with your actual condition
            if (row.question_category_id === 1) {
                return acc + parseFloat(row.weight);
            }
            return acc;
        }, 0);
        const impactData = { ...activeBoxImpact };
        weightQ.map((data, key) => {
            if (data.question_category_id === 1) {
                actdata[key] = {
                    box1: parseFloat(data.weight),
                };
                let stepGet = data.weight;
                const calculatPer = ((stepGet / total_weight) * 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                impactData[key] = {
                    impact1: calculatPer,
                    rowStepNo: stepGet,
                    totalStp: total_weight,
                };
                weightQ[key].weight = parseInt(weightQ[key].weight);
            }
        });
        setActiveBoxImpact(impactData);
        setActiveBox(actdata);
    };

    const boxClickFun = (box_no, index) => {
        const actdata = { ...activeBox };
        const mainData = { ...weightQ };
        mainData[index].weight = box_no;
        actdata[index] = {
            box1: parseFloat(box_no),
        };
        setActiveBox(actdata);
        updateBoxValues();
    };

    const reSetCall = () => {
        setModalIsOpenWeight(!isModalOpenWeight);
        getAssessmentData(assessment_id);
    };

    const [anchorE2, setAnchorE2] = React.useState(null);
    const open = Boolean(anchorE2);

    const handleClickMenu = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorE2(null);
    };

    const handleEditClick = (data) => {
        navigate("/customer/create-assessment?id=" + data);
        // Implement your edit logic here, such as opening a modal or navigating to an edit page.
    };

    const searchTeamMemberHandle = (event) => {
        setLoad(true);
        const eventValue = event.target.value;
        setSearchTeamMemberValue(eventValue);
        if (eventValue === "") {
            setManageAccessUser(manageAccessUserCopy);
            setLoad(false);
            return false;
        } else {
            const filteredArray = manageAccessUserCopy.filter((item) =>
            (item.name?.toLowerCase().includes(eventValue.toLowerCase()) ||
                item.email?.toLowerCase().includes(eventValue.toLowerCase()))
            );
            setManageAccessUser(filteredArray);
        }
        setLoad(false);
    };

    const roleHandleChange = (event) => {
        setLoad(true);
        const getValue = event.target.value;
        setRoleSelectedOption(getValue);
        if (getValue === "allRole") {
            setManageAccessUser(manageAccessUserCopy);
            setLoad(false);
            return false;
        } else {
            const filteredArray = manageAccessUserCopy.filter((item) => item.role.id === getValue);
            setManageAccessUser(filteredArray);
        }
        setLoad(false);
    };
    const handleClose = () => {
        setOpenModal(false);
    }

    const inviteSelfFun = async ()=>{
        const current_user = JSON.parse(localStorage.getItem("user"));
        const data = [{email:current_user['email']}];
            
        const dataSended = { user_details: data, assessment_id: assessment_id };
        try {
            const headers = getHeaders();
            await axios
                .post(condidateInvite, dataSended, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        let existingCandidate=res?.data?.existing_candidates || [];
                        if( existingCandidate.length >0){
                            setAlertNotification({
                                status: true,
                                type: "error",
                                message: "This Email is Already Exist and Invited",
                            });                             
                        }
                        else{
                            setAlertNotification({
                                status: true,
                                type: "success",
                                message: "Invited",
                            });   
                            handleClose();
                            getSingleAssessmentCandidateData();
                        }
                    } else if (res.status === 201) {
                        setAlertNotification({
                            status: true,
                            type: "error",
                            message: "This Email is Already Exist and Invited",
                        });
                    }
                }); 
        } catch (error) {
            if (error.response.status === 403) {
                let errorCandidates = error.response.data.data || [];
                console.log(errorCandidates);
                // setMoreOptions(errorCandidates);

                // const updatedfiledOptions = [];
                // errorCandidates.map((data, index) => {
                //     updatedfiledOptions[index] = {
                //         email: data?.message,
                //     };
                // });
                // setErrorMsg((prevOptions) => updatedfiledOptions);
            }
            console.log(error); 
        }
    }

    const handleClickEdit=()=>{
        setRenameModal(true);
        setisRenameAssessmentModalOpen(true);
    }
    const EditAssessmentNameModal = ({onsetisRenameAssessmentModalOpen, onsetRenameModal}) => {
        const [errorMsg, setErrorMsg] = useState(false); 
		const [errorMessage, setErrorMessage] = useState(null);
		const [popupLoader, setPopupLoader] = useState(false);
        const [renameAss, setRenameasses] = useState(formFinalData.name_of_assessment);
		const characterCount = renameAss.length;
		const handleInputChange = (event) => {
			const value = event.target.value;
            if (value.length <= 256) {
                setErrorMessage(null);
                setRenameasses(event.target.value);
            } else {
                setErrorMessage("Title should be less than 256 characters");
            }
		}; 
		 
		 
        const closeModal = ()=>{
            onsetRenameModal(false);
            onsetisRenameAssessmentModalOpen(false);
        }
         
		const updateAssessmentName = (assessmentsArray, newName) => {
            const data = assessmentsArray['name_of_assessment'] = newName;
            return assessmentsArray; 
		};

		const handleSubmitadd = async () => {
			try {
				if (renameAss === null || renameAss === "") {
					setErrorMsg(true);
					return false;
				} else {
					setPopupLoader(true);
					const data = {
						assessment_id: assessment_id,
						name_of_assessment: renameAss,
					};
                      
					const headers = getHeaders();
					await axios
						.post(renameAssessmentName, data, { headers })
						.then((response) => response)
						.then((response) => {
							if (response.status === 200) {
								const updatedAssessments = updateAssessmentName(
									formFinalData,
									renameAss
								);	
                                setFormFinalData(updatedAssessments);				 
                                setRenameModal(false);
							}
						});
				}
			} catch (error) {
				console.log(error);
			}
		};

		return (
			 
			<>
				<Modal
                className="RemoveOutLine"
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={isRenameAssessmentModalOpen}
                    onClose={closeModal}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
					sx={{ backdropFilter: "blur(4px)" }}
				>
					<Fade in={isRenameAssessmentModalOpen}>
						<Box sx={style}>
							<Typography
								id="transition-modal-title"
								variant="h6"
								component="h2"
								sx={{ fontSize: "22px", fontWeight: 700 }}
							>
								Rename assessment
							</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									sx={{ mt: 2 }}
									fullWidth
									label="Assessment name"
									id="fullWidth"
									name="Name"
									value={renameAss}
									error={errorMsg ? true : false}
									helperText={
										errorMsg
											? errorMsg === true
												? "Name is required"
												: errorMsg
											: null
									}
									onChange={(e) => handleInputChange(e)}
								/>
                                <Grid container spacing={0} columns={16} sx={{ pl: "30px" }}>
                                    <Grid xs={8}>{errorMessage && <span className="error_msg_style">{errorMessage}</span>}</Grid>
                                    <Grid xs={8}>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                fontSize: 16,
                                                color: "gray",
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            {characterCount}/256
                                        </Typography>
                                    </Grid>
                                </Grid>
							</ThemeProvider>
							<Grid
								xs={12}
								md={12}
								sx={{
									display: "flex",
									justifyContent: "end",
									mt: 3,
									gap: "10px",
								}}
							>
								<Button
									sx={{
										color: "var(--text-color-black)",
										bgcolor: "#ededed",
										minWidth: "48px",
										minHeight: "48px",
										textTransform: "none",
										boxShadow: "none",
										p: "10px 20px",
										"&:hover": {
											bgcolor: "#e0e0e0",
											boxShadow: "none",
										},
									}}
                                    onClick={closeModal}
								>
									<Typography
										style={{
											fontSize: "16px",
											color: "var(--text-color-black)",
											textTransform: "capitalize",
										}}
										variant="h6"
										fontWeight={600}
									>
										Cancel
									</Typography>
								</Button>
								<Button
									sx={{
                                        bgcolor: (errorMessage !==null)?"#a7a2a2b5":"var(--text-color-Green)",
										minWidth: "48px",
										minHeight: "48px",
										textTransform: "capitalize",
										p: "10px 20px",
										"&:hover": {
											bgcolor: "var(--button-hover-bg-color-LightGreen)",
										},
									}}
                                    disabled={errorMessage !==null}
                                    onClick={handleSubmitadd}
								>
									<Typography
										style={{
											fontSize: "16px",
											color: "var(--text-color-OffWhite)",
											textTransform: "capitalize",
										}}
										variant="h6"
										fontWeight={600}
									>
										{popupLoader ? "Saving..." : "Save"}
									</Typography>
								</Button>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			</>
		);
	};
    return (
        <Box>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Candidate Invite - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Navbar />
            {load || openLoader ? (
                <Grid sx={{ position: "fixed", width: "100%", height: "100%", top: "50%", left: 0, zIndex: 9999 }}>
                    <Loader />
                </Grid>
            ) : (
                <Grid>
                    { renameModal && <EditAssessmentNameModal 
                    onsetRenameModal={setRenameModal} 
                    onsetisRenameAssessmentModalOpen={setisRenameAssessmentModalOpen}
                      />}
                    {isadddMemberModalOpen && <AddMemberModal onAddRequestClose={addMembertoggleModal} />}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                            timeout: 10000,
                            },
                        }}
                        className="RemoveOutLine"
					    sx={{ backdropFilter: "blur(4px)" }}
                    >
                        <Fade in={openModal}>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 600,
                                height: 590,
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                                boxShadow: 24,
                                p: 4,
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '40px', marginBottom: '20px'}}>
                                    <Typography id="transition-modal-title" variant="h6" component="h2" sx={{fontWeight: 600, fontSize: 20, letterSpacing: '-.5px'}}>
                                        See your assessment in practice!
                                    </Typography>
                                    <Button onClick={handleClose} sx={{ "&:hover": { background: "unset" }, minWidth: 0, height: '40px', width: '40px', padding: '0' }}>
                                        <CloseIcon sx={{ color: "#2C2C2C", fontSize: "18px" }} />
                                    </Button>
                                </Box>
                                    <Typography id="transition-modal-description" variant="body2" sx={{ mb: 2, fontSize: '14px', fontWeight: 400, letterSpacing: '-.1px' }}>
                                        Congratulations on completing the assessment! You can now use it internally with your team or share it with candidates.
                                    </Typography>
                                    <Typography id="transition-modal-description" variant="body2" sx={{ mb: 4, fontSize: '14px', fontWeight: 400, letterSpacing: '-.1px' }}>
                                        Please be aware that once you send out invitations to the candidates, you won't be able to make further changes.
                                    </Typography>
                                <Box sx={{ display: 'flex', width: '100%', height: '50%', marginBottom: 1}}>
                                    <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column', 
                                            borderRadius: 1,
                                            padding: 4,
                                            border: '1px solid #E0E0E0',
                                            justifyContent: 'space-between',
                                            gap: '16px',
                                            marginRight: 4,
                                        }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            gap: '8px',
                                            }}>
                                            <RemoveRedEyeOutlinedIcon sx={{ color: "#707070", height: '35px'}}/>
                                            <Typography id="transition-modal-title" variant="h6" component="h2" fontSize={'16px'} fontWeight={600}>
                                                Try assessment
                                            </Typography>
                                        </Box>
                                        <Typography id="transition-modal-description" variant="body2" sx={{ fontSize: 14, fontWeight: 400, }}>
                                            Try this assessment individually or with your team.
                                        </Typography>
                                        <Button
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "4px",
                                                border: "1px solid var(--text-color-black)",
                                                padding: "24px 28px",
                                            }}
                                            sx={{...btnStyle,
                                                bgcolor: "var(--button-bg-color-green)",
                                                textTransform: "none",
                                                "&:hover": {
                                                    bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                },
                                                width: "100%",
                                                height: "36px",
                                            }}
                                            variant="outlined"
                                            onClick={inviteSelfFun}
                                        >
                                            <Typography
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "var(--text-color-white)",
                                                }}
                                            >
                                                Try yourself
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: 1,
                                            padding: 4,
                                            border: '1px solid #E0E0E0',
                                            justifyContent: 'space-between',
                                            gap: '16px',
                                        }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            gap: '8px',
                                            }}>
                                            <EmailOutlinedIcon sx={{ color: "#707070", height: '35px'}} />
                                            <Typography id="transition-modal-title" variant="h6" component="h2" fontSize={'16px'} fontWeight={600}>
                                                Invite candidates
                                            </Typography>
                                        </Box>
                                        <Typography id="transition-modal-description" variant="body2" sx={{ fontSize: 14, fontWeight: 400, }}>
                                            Invite your candidates to take this assessment.
                                        </Typography>
                                        <Button
                                            sx={{
                                                ...btnStyle,
                                                bgcolor: "var(--button-bg-color-green)",
                                                padding: "24px 28px",
                                                textTransform: "capitalize",

                                                borderRadius: "4px",
                                                "&:hover": {
                                                    bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                },
                                                "&:disabled": {
                                                    bgcolor: "#ededed",
                                                },
                                                width: "100%",
                                                height: "36px",
                                            }}
                                            variant="contained"
                                            onClick={()=>{
                                                toggleModal()
                                                handleClose()
                                            }}
                                            disabled={canInviteCandidate === 1 ? false : true}
                                        >
                                            <Typography variant="body1" fontWeight={400} sx={{ fontSize: "15px"}}>
                                                Invite
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Button
                                    sx={{
                                        color: "var(--text-color-black)",
                                        height: "50px",
                                        width: "100%",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        border: "unset",
                                        padding: "3px 3px ",
                                        margin: "24px 0",
                                        cursor: "pointer",
                                        backgroundColor: "white",
                                        "&:hover": {
                                            bgcolor: "#e0e0e0",
                                        },
                                    }}
                                    onClick={handleClose}
                                >
                                        <Typography
                                            style={{
                                                fontSize: "14px",
                                                color: "var(--text-color-black)",
                                                display: "contents",
                                                textTransform: "capitalize",
                                            }}
                                            variant="body1"
                                            fontWeight={500}
                                        >
                                            I'll do it later
                                        </Typography>
                                </Button>
                            </Box>
                        </Fade>
                    </Modal>
                    <Grid sx={{ marginTop: "120px" }}>
                        <Container maxWidth="xl">
                            <Box sx={{ flexGrow: 1, mt: 5, pb: 2 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                            }}
                                        >
                                            {" "}
                                            <Link style={{ marginRight: "24px" }} to="/customer/assessment">
                                                <Button
                                                    sx={{
                                                        p: 0,
                                                        bgcolor: "#ededed",
                                                        boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        borderRadius: "4px",
                                                        "&:hover": {
                                                            bgcolor: "#e0e0e0",
                                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}
                                                </Button>
                                            </Link>
                                            <Grid>
                                                <Typography sx={{ display: "flex", alignItems: "center" }} variant="h6">
                                                    <BootstrapTooltip
                                                        variant="contained"
                                                        title={formFinalData?.name_of_assessment} 
                                                    > 
                                                        <strong  >{formFinalData?.name_of_assessment.length > 20 ? formFinalData?.name_of_assessment.substring(0, 20) + "...":formFinalData?.name_of_assessment}</strong>
                                                    </BootstrapTooltip>
                                                  
                                                </Typography>

                                                <Grid
                                                    item
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap:FixGap?"10px" : "20px",
                                                        pt:2
                                                    }}
                                                >
                                                    <Grid>
                                                        <Typography variant="text" sx={{ fontSize: isMobile ? "12px" : "15px", display:"flex",alignItems:"center" }}>
                                                            {<TextSnippetOutlinedIcon sx={{ height: "15px" }}></TextSnippetOutlinedIcon>}
                                                            {metaquestionData?.length} tests
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="text" sx={{ fontSize: isMobile ? "12px" : "15px", display:"flex",alignItems:"center" }}>
                                                            {<AlarmIcon sx={{ height: "15px" }}></AlarmIcon>}
                                                            {formFinalData?.total_time ? formFinalData?.total_time : 0} min
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="text" sx={{ fontSize: isMobile ? "12px" : "15px", display:"flex",alignItems:"center" }}>
                                                            {<AutoStoriesOutlinedIcon sx={{ height: "15px" }}></AutoStoriesOutlinedIcon>}
                                                            {formFinalData?.language}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="text" sx={{ fontSize: isMobile ? "12px" : "15px", display:"flex",alignItems:"center" }}>
                                                            {<FeaturedPlayListOutlinedIcon sx={{ height: "15px" }}></FeaturedPlayListOutlinedIcon>}
                                                            {formFinalData?.deadline ? "Deadline " + formFinalData?.deadline : "No Deadline"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                                justifyContent: Formposition,
                                            }}
                                        >
                                            {" "}
                                            <BootstrapTooltip
                                                        variant="contained"
                                                        title="Rename assessment"
                                                    >
                                                        <Button
                                                            id="basic-button"
                                                            aria-controls={open ? "basic-menu" : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? "true" : undefined}
                                                            onClick={handleClickEdit}
                                                            sx={{
                                                                p: 2,
                                                                mr: 3,
                                                                bgcolor: "#ededed",
                                                                minWidth: "40px",
                                                                minHeight: "40px",
                                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                                borderRadius: "4px",
                                                                "&:hover": {
                                                                    bgcolor: "#e0e0e0",
                                                                    boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                                },
                                                            }} 
                                                        >
                                                            <EditIcon
                                                                sx={{
                                                                    color: "var(--text-color-black)",
                                                                    borderRadius: "5px",
                                                                    // padding: "4px ",
                                                                    fontSize: "20px"
                                                                }}
                                                            />
                                                        </Button>
                                                    </BootstrapTooltip>
                                            <Button
                                                id="basic-button"
                                                aria-controls={open ? "basic-menu" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? "true" : undefined}
                                                onClick={handleClickMenu}
                                                sx={{
                                                    p: 0,
                                                    mr: 3,
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",

                                                    borderRadius: "4px",
                                                    "&:hover": {
                                                        bgcolor: "#e0e0e0",
                                                        boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                    },
                                                }}
                                            >
                                                <MoreVertOutlinedIcon
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        borderRadius: "5px",
                                                        padding: "15px ",
                                                        fontSize: "50px",
                                                    }}
                                                />
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorE2}
                                                open={open}
                                                onClose={handleCloseMenu}
                                                MenuListProps={{
                                                    "aria-labelledby": "basic-button",
                                                }}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                        boxShadow: "0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f",
                                                        borderRadius: "8px",
                                                    },
                                                    "& .MuiPaper-root li": {
                                                        px: 2,
                                                        py: 2,
                                                        "&:hover": {
                                                            backgroundColor: "#e9f0f1",
                                                        },
                                                    },
                                                }}
                                            >
                                                <NestedMenuItem 
                                                    leftIcon={<FileDownloadOutlinedIcon sx={{ mr: 1 }} />} 
                                                    disabled={candidateList.filter((list) => list.status.name === "Completed").length > 0 ? false : true}
                                                    label="Download results"
                                                    parentMenuOpen={candidateList.filter((list) => list.status.name === "Completed").length > 0}
                                                    >
                                                    <MenuItem sx={{ pl: 2, pr: 8, py: 2 }} onClick={exportToCSV}>CSV</MenuItem>
                                                    <MenuItem sx={{ pl: 2, pr: 8, py: 2 }} onClick={exportToExcel}>Excel</MenuItem>
                                                    <MenuItem sx={{ pl: 2, pr: 8, py: 2 }} onClick={exportToPDF}>PDF</MenuItem>
                                                </NestedMenuItem>
                                                <AllowedAccess permissions="edit_my_assessment">
                                                    <BootstrapTooltip title={candidateList.length > 0 ? "You can't edit an assessment after inviting candidates" : "Edit assessments"} placement="left">
                                                        {candidateList.length > 0 ? (
                                                            <MenuItem
                                                                onClick={handleCloseMenu}
                                                                sx={{
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                <ModeEditOutlineOutlinedIcon sx={{ mr: 2 }} /> Edit assessments
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem onClick={() => handleEditClick(assessment_id)}>
                                                                <ModeEditOutlineOutlinedIcon sx={{ mr: 2 }} /> Edit assessments
                                                            </MenuItem>
                                                        )}
                                                    </BootstrapTooltip>
                                                </AllowedAccess>

                                                <AllowedAccess permissions="manage_access_my_assessment">
                                                    <MenuItem onClick={addMembertoggleModal}>
                                                        <ManageAccountsOutlinedIcon sx={{ mr: 2 }} />
                                                        Manage access
                                                    </MenuItem>
                                                </AllowedAccess>
                                                {/* <MenuItem onClick={handleCloseMenu}>
                                                    <StarBorderOutlinedIcon sx={{ mr: 2 }} />
                                                    Leave test feedback
                                                </MenuItem> */}
                                                <AllowedAccess permissions="add_my_assessment">
                                                    <MenuItem onClick={() => handleCloneCall(assessment_id)}>
                                                        <FileCopyOutlinedIcon sx={{ mr: 2 }} />
                                                        {getCloneLoader ? "Cloning..." : "Clone"}
                                                    </MenuItem>
                                                </AllowedAccess>
                                                <AllowedAccess permissions="delete_my_assessment">
                                                    <MenuItem onClick={() => updateArchiveFun(assessment_id)}>
                                                        <ArchiveOutlinedIcon sx={{ mr: 2 }} />
                                                        Archive
                                                    </MenuItem>
                                                </AllowedAccess>
                                            </Menu>
                                            {
                                                // <BootstrapTooltip title="Try yourself">
                                                //     <IconButton
                                                //         color="inherit"
                                                //         sx={{
                                                //             p: 0,
                                                //             mr: 3,
                                                //             bgcolor: "#ededed",
                                                //             minWidth: "48px",
                                                //             minHeight: "48px",
                                                //             borderRadius: "4px",
                                                //             "&:hover": {
                                                //                 bgcolor: "#ededed",
                                                //                 boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                //             },
                                                //         }}
                                                //     >
                                                //         <RemoveRedEyeOutlinedIcon
                                                //             sx={{
                                                //                 borderRadius: "5px",
                                                //                 padding: "15px ",
                                                //                 fontSize: "60px",
                                                //                 bgcolor: "#EDEDED",
                                                //             }}
                                                //         />
                                                //     </IconButton>
                                                // </BootstrapTooltip>
                                            }
                                            <AllowedAccess permissions="invite_my_assessment">
                                                <Button
                                                    sx={{
                                                        ...btnStyle,
                                                        bgcolor: "var(--button-bg-color-green)",
                                                        padding: "15px 22px",
                                                        textTransform: "capitalize",

                                                        borderRadius: "4px",
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                        },
                                                        "&:disabled": {
                                                            bgcolor: "#ededed",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={toggleModal}
                                                    disabled={canInviteCandidate === 1 ? false : true}
                                                >
                                                    <EmailOutlinedIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body1" fontWeight={600} sx={{ fontSize: "18px" }}>
                                                        Invite
                                                    </Typography>
                                                </Button>
                                            </AllowedAccess>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Grid>

                    <AllowedAccess permissions="manage_access_my_assessment">
                        <TeamMember
                            fileLoader={fileLoader}
                            uploadLinkFile={uploadLinkFile}
                            uploadfileName={uploadfileName}
                            sebmitUploadedFile={sebmitUploadedFile}
                            addMemberFun={addMembertoggleModal}
                            assessmentId={assessment_id}
                            formFinalData={formFinalData}
                            isModalOpen={isModalOpen}
                            toggleModal={toggleModal}
                            manageAccessUser={manageAccessUser}
                            webAllRoleData={webAllRoleData}
                            searchTeamMemberValue={searchTeamMemberValue}
                            searchTeamMemberHandle={searchTeamMemberHandle}
                            roleHandleChange={roleHandleChange}
                            roleSelectedOption={roleSelectedOption}
                            getAssessmentData={getAssessmentData}
                            canInviteCandidate={canInviteCandidate}
                            csvxlsxFileUploadError={csvxlsxFileUploadError}
                            isPublicLink={isPublicLink}
                            setIsPublicLink={setIsPublicLink}
                            candidateList={candidateList}
                            getSingleAssessmentCandidateData={getSingleAssessmentCandidateData}
                            getTeamMembersData={getTeamMembersData}
                            uploadingFile={uploadingFile}
                        />
                    </AllowedAccess>

                    <CandidateTable
                        handleChangeStage={handleChangeStage}
                        handleChangeStatus={handleChangeStatus}
                        selectedOptionStage={selectedOptionStage}
                        selectedOptionStatus={selectedOptionStatus}
                        handleChangeHireStage={handleChangeHireStage}
                        hiringStages={hiringStages}
                        candidateList={candidateList}
                        metaquestionData={metaquestionData}
                        metaData={metaData}
                        isModalOpen={isModalOpen}
                        toggleModal={toggleModal}
                        handleDeleteCandidate={handleDeleteCandidate}
                        handleChangeRejectStage={handleChangeRejectStage}
                        checkBoxVal={checkBoxVal}
                        setCheckBoxVal={setCheckBoxVal}
                        searchCondidate={searchCondidate}
                        clearSearchCandidateAndSetData={clearSearchCandidateAndSetData}
                        onChangeSearchCondidate={onChangeSearchCondidate}
                        assessmentId={assessment_id}
                        canInviteCandidate={canInviteCandidate}
                        candidateTableLoader={candidateTableLoader}
                        handelScoreFilterCandidateTable={handelScoreFilterCandidateTable}
                        mailCandidateReport={mailCandidateReport}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        candidateLoader={candidateLoader}
                        perpage={perpage}
                        limit={limit}
                        handleLimitChange={handleLimitChange}
                        totalItem={totalItem}
                        scoresValues={scoresValues}
                        reSetScoresFun={reSetScoresFun}
                        getAssessmentData={getAssessmentData}
                        setCandidateList={setCandidateList}
                        setSortByquestionId={setSortByquestionId}
                        setSortBy={setSortBy}
                        setSortByColumnName={setSortByColumnName}
                    />

                    <IncludeTest
                        activeBox={activeBox}
                        isModalOpenWeight={isModalOpenWeight}
                        toggleModalWeight={toggleModalWeight}
                        metaquestionData={metaquestionData}
                        weightQ={weightQ}
                        reSetCall={reSetCall}
                        boxClickFun={boxClickFun}
                        activeBoxImpact={activeBoxImpact}
                        assessmentId={assessment_id}
                    />
                    <AdvanceFields singleAssessmentData={singleAssessmentData} />

                    {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
                </Grid>
            )}
        </Box>
    );
}

export default Index;
