import { Box, Button, Typography, Modal, Switch, Paper, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AllImg from "../../../../Assests/Images/LanguageImg/All.png";
import HoverImg from "../../../../Assests/Images/LanguageImg/AllHoverImg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../../../Common/Loader";
import CodeImage from "../../../Admin/Assissment/MyAssessment/CodeImages";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const LangSelectCodeModal = (props) => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { closeCodeModal, allProgrammingLang, handelProgrammingCheckbox, programmingSelected, handelSubmit, modalLoader, selectAll, errormsg, fromCall, setToactiveSwitchButton } = props;
    const ArrowPosition = isMobile ? "absolute" : "";
    const ArrowTop = isMobile ? "0px" : "";
    const ArrowLeft = isMobile ? "0px" : "";
    const PositionTop = isMobile ? "50%" : "25%";
    const BoxWidth = isMobile ? "90%" : "70%";
    const [activeSwitchButton, setActiveSwitchButton] = useState(false);
    const handleSwitchChange = () => {
        setToactiveSwitchButton(!activeSwitchButton);
        setActiveSwitchButton(!activeSwitchButton);
    };
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Modal open={true} onClose={closeCodeModal}>
            <Grid
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                }}
            >
                <Grid
                    container
                    sx={{
                        backgroundColor: "#074234",
                    }}
                >
                    <Grid xs={12} md={6}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                display: "flex",
                                padding: "0",
                                alignItems: "center",
                            }}
                        >
                            <Grid
                                sx={{
                                    backgroundColor: "var(--button-bg-color-green)",
                                    p: "20px",
                                    position: ArrowPosition,
                                    left: ArrowTop,
                                    top: ArrowLeft,
                                    cursor: "pointer",
                                    display: isMobile ? "none" : "",
                                }}
                                onClick={closeCodeModal}
                            >
                                <KeyboardArrowLeftIcon sx={{ color: "var(--text-color-OffWhite)", height: "40px" }}></KeyboardArrowLeftIcon>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid sx={{ ml: "auto" }} xs={6} md={6}>
                        <Item
                            sx={{
                                mx: "50px",
                                mt: "10px",
                                mb: "10px",
                            }}
                        >
                            <Stack spacing={2} direction="row">
                                <Button
                                    style={{
                                        background: "#FFF0E1",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={closeCodeModal}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>
                                <Button
                                    style={{
                                        background: "#0a5c48",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    sx={{ textTransform: "none" }}
                                    onClick={handelSubmit}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                            color: "var(--text-color-OffWhite)",
                                        }}
                                    >
                                        Save
                                    </Typography>
                                </Button>
                            </Stack>
                        </Item>
                    </Grid>
                </Grid>

                <Grid container sx={{ justifyContent: "center", p: 2 }}>
                    <Grid sx={{ justifyContent: "center" }}>
                        <Typography variant="body1" sx={{ fontSize: "18px", marginTop: "25px", bgcolor: "#e0eff2", color: "black", px: "8px 16px", fontWeight: 500 }}>
                            Candidates can take the test in any of the programming languages you choose.{" "}
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: "700", marginTop: "25px", textAlign: "center" }}>
                            Choose programming languages
                        </Typography>

                        {errormsg && (
                            <Typography fontWeight={500} fontSize={18} color={"red"}>
                                Choose At least one Language
                            </Typography>
                        )}
                    </Grid>
                    <Box
                        sx={{
                            marginTop: "40px",
                            top: PositionTop,
                            width: BoxWidth,
                            padding: "0px",
                        }}
                    >
                        {modalLoader ? (
                            <Loader />
                        ) : (
                            <Grid container spacing={2}>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={2}
                                    key={0}

                                >
                                    <FormControlLabel
                                        control={<Checkbox id="programmingCheckboxAll" checked={selectAll} sx={{ display: "none" }} />}
                                        label={
                                            <label htmlFor="programmingCheckboxAll" onClick={(e) => handelProgrammingCheckbox(e, 0)} checked={selectAll}>
                                                <Grid sx={{
                                                    textAlign: "center",
                                                    padding: "40px",
                                                    borderRadius: "5px",
                                                    "& .Mui-checked ": {
                                                        color: "var(--button-bg-color-green) !important",
                                                    },
                                                    "&:hover": {
                                                        bgcolor: "var(--button-bg-color-green)",
                                                        boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                                        cursor: "pointer",
                                                        "& .languageText": {
                                                            color: "var(--text-color-OffWhite)", //
                                                        },
                                                        "& .customCheckbox": {
                                                            color: "var(--button-bg-color-green)",
                                                        },
                                                        "& .MuiCheckbox-root ": {
                                                            color: "var(--text-color-OffWhite)",
                                                        },
                                                    },
                                                }}
                                                    onMouseEnter={() => setIsHovered(true)}
                                                    onMouseLeave={() => setIsHovered(false)}>
                                                    <img
                                                        src={isHovered ? HoverImg : AllImg}
                                                        alt="all"
                                                        style={{
                                                            width: "100px", // Adjust the width as needed
                                                            height: "100px",
                                                            marginBottom: "10px", // Adjust the height as needed
                                                        }}
                                                    />
                                                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Checkbox className="customCheckbox" id="programmingCheckboxAll" {...label} checked={selectAll} />
                                                        <Typography
                                                            className="languageText"
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                // "&:hover": {
                                                                //     color: selectedLanguage === language.id ? "var(--text-color-OffWhite)" : "var(--text-color-black)",
                                                                // },
                                                            }}
                                                        >
                                                            All
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </label>
                                        } />
                                </Grid>
                                {allProgrammingLang.map((language, index) => (
                                    <>
                                        <CodeImage language={language} handelProgrammingCheckbox={handelProgrammingCheckbox} programmingSelected={programmingSelected} lable={label} />
                                    </>
                                ))}
                            </Grid>
                        )}
                        {fromCall && (
                            <Grid sx={{ justifyContent: "center" }}>
                                <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "500", marginTop: "25px" }}>
                                    Do you want to show TestCases to the Candidate
                                    <Switch
                                        {...label}
                                        checked={activeSwitchButton}
                                        onChange={handleSwitchChange}
                                        sx={{
                                            "& .MuiSwitch-switchBase.Mui-checked": {
                                                color: "var(--text-color-Green)",
                                            },
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Modal>
    );
};
export default LangSelectCodeModal;
