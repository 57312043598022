import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../../Assests/Images/LogoImg/LOGO.png";
import { useNavigate } from "react-router-dom";
import {
	getUserDetailsUrl,
	payStripeUrl,
} from "../../../Constants/ConstantsUrl";
import { APIProvider, LogOut } from "../../../Components/Common/ApiProvider";
import { usePermission } from "react-permission-role";
import { useLocation } from "react-router-dom";

function Index() {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const navigate = useNavigate();
	const { setUser } = usePermission();
	const Login = async () => {
		try {
			await APIProvider(getUserDetailsUrl, {}, "get").then(async (response) => {
				if (response.status === 200) {
					setUser({
						id: response.data.id,
						roles: response.data.roles,
						permissions: response.data.permission,
					});
					localStorage.setItem("user", JSON.stringify(response.data));
					localStorage.setItem(
						"userName",
						response.data.first_name + " " + response.data.last_name
					);

					if (params.get("continue") == "pricings") {
						if (!response.data.subscription) {
							let paramsObject = Object.fromEntries(params.entries());
							await APIProvider(payStripeUrl, paramsObject, "post").then(
								(response) => {
									if (response.status === 200) {
										window.location.href = response?.data?.message;
									} else {
										navigate("/pricing");
									}
								}
							);
						} else {
							navigate("/pricing");
						}
					} else {
						navigate("/customer/assessment");
					}
				}
			});
		} catch (error) {
			LogOut();
			console.log(error);
		}
	};

	useEffect(() => {
		Login();
	}, []);

	return (
		<>
			<Grid sx={{ display: "flex", height: "100vh" }}>
				<Container
					maxWidth="xxl"
					sx={{
						margin: "auto",
						width: "auto",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<img src={Logo} alt="Loading" />
					<CircularProgress sx={{ margin: "auto", mt: 5 }} disableShrink />
				</Container>
			</Grid>
		</>
	);
}

export default Index;
