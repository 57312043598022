import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography, Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";
import Logo from "../../../../../../Assests/Images/LOGO.png"
import InsertDriveFileIcon from "@mui/icons-material/UploadFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { getHeaders, getFileQuestion } from "../../../../../../Constants/ConstantsUrl";
import CustomLoader from "../../../../../Common/CustomLoader";
import Alerts from "../../../../../Common/Alerts";

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PreviewFileQuestions() {
    const handleCloseTab = () => {
        window.close();
    };
    const { main_question_id } = useParams();
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [questionData, setQuestionData] = useState({
        id: 0,
        options: []
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });
    const FileSize = 200;
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const fileMaxSize = FileSize * 1024 * 1024; // 5MB 
        if (file.size > fileMaxSize) {
            setAlertNotification({
                status: true,
                type: "failed",
                message: `Max File Size is ${FileSize}MB`,
            })
            return false;
        }
        setSelectedFile(file);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif", "image/svg+xml", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", "application/vnd.ms-excel"];
        if (file && allowedTypes.includes(file.type)) {
            e.target = e.dataTransfer;
            handleFileUpload(e);
        } else {
            setAlertNotification({
                status: true,
                type: "failed",
                message: "Unsupported file type",
            })
        }

    };
    useEffect(() => {
        if (main_question_id > 0) {
            fetchMainCustomQuestion();
        }
    }, []);

    const fetchMainCustomQuestion = async () => {
        const headers = getHeaders();
        const data = {
            'custom_main_question_id': main_question_id
        }
        try {
            await axios.post(getFileQuestion, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data.data.custom_question;
                    if (response.data.status === 200) {
                        setQuestionData({
                            id: res.id,
                            options: res.question_data.question
                        })
                    }
                })
        } catch (error) {
            if (error?.response.data.status === 404) {
                setErrorMsg(error?.response.data.data.message);
                setNotificationOpen(true);
                setTimeout(function () {
                    //handleCloseTab();
                }, 5000);
            }
        }
    }
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid>
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="Company logo"
                    />
                </Grid>

                <Grid sx={{ padding: "20px" }}>
                    <Button
                        onClick={handleCloseTab}
                        sx={{
                            ...btnStyle,
                            bgcolor: "var(--button-bg-color-assessment)",
                            minWidth: "48px",
                            minHeight: "48px",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            "&:hover": {
                                bgcolor:
                                    "var(--button-hover-bg-color-LightGreen)",
                            },
                        }}
                        variant="contained"
                    >
                        {'Go back to tests'}
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    height: "90vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Container
                    maxWidth="lg"
                    style={{
                        padding: "0px",
                        borderRadius: "12px",
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow:
                                "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Question
                                </Typography>
                                <Grid>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: "13px", fontWeight: 400, mt: 2 }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: questionData?.options }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item style={{ padding: "0px 24px" }} xs={12} md={6}>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Upload file
                                </Typography>
                                {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
                                <Grid
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        bgcolor: "#f0f0f0",
                                        border: "1px dashed #888",
                                        borderRadius: "8px",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        padding: "16px 32px",
                                        height: 200,
                                    }}
                                    component="label"
                                    variant="contained"
                                >
                                    <Grid>
                                        {selectedFile ? (
                                            <InsertDriveFileIcon sx={{ height: "30px" }} />
                                        ) : (
                                            <UploadFileIcon sx={{ height: "30px" }} />
                                        )}
                                    </Grid>
                                    <Typography sx={{ mt: 2, wordWrap: "break-word" }}>
                                        {selectedFile ? (
                                            `Selected file: ${selectedFile.name}`
                                        ) : (
                                            <>
                                                Drag and drop or{" "}
                                                <span style={{ fontWeight: 600 }}>browse</span> your files.
                                                <Typography sx={{ mt: 2 }}>{FileSize} MB max file size</Typography>
                                            </>
                                        )}
                                    </Typography>
                                    <input className="videoUpload_input" type="file" onChange={(e) => handleFileUpload(e)} accept=".pdf, image/*, .xlsx, .csv, .xls" />
                                </Grid>
                                <Typography sx={{ mt: 2 }}>
                                    <span style={{ fontWeight: 600 }}>Supported file types:</span> .csv, .xls, .xlsx, .pdf, .png, .jpg
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <Alert
                        onClose={handleNotificationClose}
                        severity="error"
                        className="error_msg"
                        sx={{
                            width: "500px",
                            justifyContent: "center",
                            background: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                        }}
                    >
                        {errorMsg}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}
