import React, { useEffect } from "react"; 
import { Container, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../Assests/Images/LogoImg/LOGO.png'
import { LogOut } from "../../Components/Common/ApiProvider"

const Logout = () => {
    useEffect(() => {
        LogOut();
    }, []);

    return <>
        <Grid sx={{ display: "flex", height: "100vh" }}>
            <Container maxWidth="xxl" sx={{ margin: "auto", width: "auto", display: "flex", flexDirection: "column" }}>
                <img src={Logo} alt="Loading" />
                <CircularProgress sx={{ margin: "auto", mt: 5 }} disableShrink />
            </Container>
        </Grid>
    </>;
};

export default Logout;
