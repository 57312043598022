import { Grid, Box, Typography, Paper, Container, TextField, Button } from "@mui/material";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getHeaders, updateTeamMemberUrl } from "../../../../Constants/ConstantsUrl";

function UpdateMemberModal({ getTeamMembersData, onUpdateRequestClose, selectedMember, webRoleData, setAlertNotification,userRole }) {
    const outerTheme = useTheme();
    const [validatioError, setValidatioError] = useState({ first_name: false, last_name: false });
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "--TextField-brandBorderColor": "#0000001f",
                            "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                            "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                            "& label.Mui-focused": {
                                color: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: "var(--TextField-brandBorderColor)",
                        },
                        root: {
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "var(--TextField-brandBorderHoverColor)",
                                transition: "0.5s all",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                transition: "0.5s all",
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            "&::before, &::after": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            "&::before": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
            },
        });

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        boxShadow: "none",
    }));

    const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
            backgroundColor: "#c8d7de",
        },
    });

    const [formData, setFormData] = useState(selectedMember);
    const [rolesModeChangeError,setRolesModeChangeError] = useState(false);
    const setFormDataCompany = (name, value) => {
        if(name === "role_id"){
            setRolesModeChangeError(false);
            setValidatioError({ ...validatioError, [name]: false });
            if(userRole !== null && selectedMember.role_id > userRole){
                setFormData({ ...formData, [name]: value });
            }else{  
                if(userRole !== null){
                    setRolesModeChangeError(true);
                }  
            }
            setTimeout(function(){
                setRolesModeChangeError(false);
            },5000)
        }else{
            setFormData({ ...formData, [name]: value });
        }
    };

    const submitFunction = async (data) => {
        try {
            let data = {
                ...formData,
                role: formData.role_id,
            };
            const headers = getHeaders();
            await axios
                .post(updateTeamMemberUrl + `${selectedMember?.id}`, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        getTeamMembersData();
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                        onUpdateRequestClose();
                    }
                });
        } catch (error) {
            let response = error?.response?.data;
            if (response?.data instanceof Object) {
                let inputs = response?.data;
                setAlertNotification({
                    status: true,
                    type: "error",
                    message: Object.values(inputs)[0],
                });
            }
        }
    };

    const texts = {
        "hiring manager": `Read only access`,
        recruiter: `Full access rights to assessment and candidates.`,
        admin: `Controls access to recruiters and hiring managers.`,
    };    

    return (
        <Box sx={{ overflow: "auto" }} className="invite_popup_modal__backdrop">
            <Container
                maxWidth={"sm"}
                sx={{
                    p: 0,
                    borderRadius: "8px",
                    bgcolor: "white",
                    maxWidth: "100%",
                    m: "50px auto",
                }}
            >
                <Box sx={{ p: 3, py: 3 }}>
                    <Grid container spacing={0} sx={{ mt: 1 }}>
                        <Grid xs={11} md={11}>
                            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>Edit user</Typography>
                        </Grid>
                        <Grid sx={{display:"flex", justifyContent:"end", alignItems:"center"}} xs={1} md={1}>
                            <CloseIcon onClick={onUpdateRequestClose} sx={{ cursor: "pointer" }} />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>Role:</Typography>
                        <Grid container spacing={0} sx={{ mt: 1 }}>
                            <Grid>
                                <Grid sx={{}} container spacing={0}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container spacing={0}>
                                            {webRoleData.map((role) => {
                                                let selectedOption = role.id === formData?.role_id;
                                                return (
                                                    <Grid xs={12} md={4} key={role.id}>
                                                        <CustomWidthTooltip title={texts[role?.title.toLowerCase()]}>
                                                            <Item
                                                                onClick={() => setFormDataCompany("role_id", role.id)}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    border: "1px solid #e0e0e0",
                                                                    borderRadius: "0px",
                                                                    cursor: "pointer",
                                                                    borderBottom: 0,
                                                                    borderRight: 0,
                                                                    backgroundColor: selectedOption ? "var(--text-color-Green)" : "white",
                                                                    "&:hover": {
                                                                        backgroundColor: selectedOption ? "var(--text-color-Green)" : "white",
                                                                    },
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        padding: "6px",
                                                                        color: selectedOption ? "var(--text-color-OffWhite)" : "var(--text-color-black)",
                                                                        fontWeight: selectedOption ? "700" : "400",
                                                                    }}
                                                                >
                                                                    {role?.title}
                                                                </Typography>
                                                            </Item>
                                                        </CustomWidthTooltip>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    {rolesModeChangeError &&
                                        <Grid xs={12} md={12}>
                                            <Grid
                                                sx={{
                                                    boxShadow: "none",
                                                    background: "none",
                                                }}
                                                className="text"
                                            >
                                                <Typography sx={{
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                        my: 2,
                                                        color:"red"
                                                    }}>
                                                    Can't update Role
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid xs={12} md={12}>
                                        <Grid
                                            sx={{
                                                boxShadow: "none",
                                                background: "none",
                                            }}
                                            className="text"
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: "16px",
                                                    my: 2,
                                                }}
                                            >
                                                User details:
                                            </Typography>
                                            <Grid>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField
                                                        type="email"
                                                        fullWidth
                                                        id="fullWidth"
                                                        name="email"
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                borderRadius: "10px",
                                                                transition: "green 0.5s ease-in-out",
                                                                "&:hover": {
                                                                    borderColor: "var(--border-color-Green)",
                                                                },
                                                            },
                                                            "& #combo-box-demo": {
                                                                color: "green",
                                                            },
                                                        }}
                                                        value={formData?.email}
                                                        onChange={(e) => {
                                                            setFormDataCompany("email", e.target.value);
                                                        }}
                                                        label="Teammate's work email"
                                                    />
                                                </ThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <Grid
                                            sx={{
                                                boxShadow: "none",
                                                background: "none",
                                                mt: 2,
                                            }}
                                            className="text"
                                        >
                                            <Grid>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        name="first_name"
                                                        value={formData?.first_name}
                                                        onChange={(e) => setFormDataCompany("first_name", e.target.value)}
                                                        label="First Name"
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                borderRadius: "10px",
                                                                borderColor: validatioError?.first_name ? "rgb(211, 47, 47)" : " ",
                                                                transition: "green 0.5s ease-in-out",
                                                                "&:hover": {
                                                                    borderColor: "var(--border-color-Green)",
                                                                },
                                                            },
                                                            "& #combo-box-demo": {
                                                                color: "green",
                                                            },
                                                            "&:focus-within .MuiInputLabel-root": {
                                                                color: validatioError?.first_name ? "rgb(211, 47, 47)" : "#0A5C48",
                                                            },
                                                        }}
                                                    />
                                                    {validatioError?.first_name ? (
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                padding: "6px",
                                                                color: "red",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            First name field is required!
                                                        </Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <Grid
                                            sx={{
                                                boxShadow: "none",
                                                background: "none",
                                                mt: 2,
                                            }}
                                            className="text"
                                        >
                                            <Grid>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        name="last_name"
                                                        value={formData?.last_name}
                                                        onChange={(e) => setFormDataCompany("last_name", e.target.value)}
                                                        label="Last Name"
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                borderRadius: "10px",
                                                                borderColor: validatioError?.last_name ? "rgb(211, 47, 47)" : " ",
                                                                transition: "green 0.5s ease-in-out",
                                                                "&:hover": {
                                                                    borderColor: "var(--border-color-Green)",
                                                                },
                                                            },
                                                            "& #combo-box-demo": {
                                                                color: "green",
                                                            },
                                                            "&:focus-within .MuiInputLabel-root": {
                                                                color: validatioError?.last_name ? "rgb(211, 47, 47)" : "#0A5C48",
                                                            },
                                                        }}
                                                    />
                                                    {validatioError?.last_name ? (
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                padding: "6px",
                                                                color: "red",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            Last name field is required!
                                                        </Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid sx={{ mt: 3 }} container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <Item
                                        sx={{
                                            boxShadow: "none",
                                            background: "none",
                                        }}
                                    >
                                        {" "}
                                        <Grid container spacing={0}>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    justifyContent: "end",
                                                }}
                                                xs={12}
                                                md={12}
                                            >
                                                <Button
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        bgcolor: "#ededed",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "none",
                                                        boxShadow: "none",
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "#e0e0e0",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={onUpdateRequestClose}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    sx={{
                                                        bgcolor: "var(--text-color-Green)",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "capitalize",
                                                        borderRadius: "4px",
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={submitFunction}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default UpdateMemberModal;
