import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Container } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import Fade from "@mui/material/Fade";
import { getAssessmentPreviewTestsUrl } from "../../../../../Constants/ConstantsUrl";
import { APIProvider } from "../../../../../Components/Common/ApiProvider";

import { FormControlLabel, RadioGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Item = styled(Paper)(({ theme }) => ({
    padding: "0px",
    boxShadow: "unset",
    background: "none",
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    background: "#f5f6f6",
    height: "100vh",
    width: "100%",
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicModal({ handleClose, open, preDefineQuestion }) {
    const [value, setValue] = React.useState(0);
    const [message,setMessage]=useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [selectButton, setselectButton] = useState("");
    const handleButtonChange = (e, count) => {
        if (selectButton === e.target.value) {
            setselectButton("");
        } else {
            setselectButton(e.target.value);
        }
    };

    const [questions, setQuestions] = useState([]);

    const getPreviewTests = async () => {
        try {
            const questionIds = preDefineQuestion
                .map((item) => item.question_id)
                .filter((id) => id !== null && id !== undefined)
                .join(",");
            await APIProvider(
                getAssessmentPreviewTestsUrl,
                { official_question_id: questionIds },
                "post"
            ).then((response) => {
                if (response.status === 200) {
                    setQuestions(response.data);
                    setMessage("")
                }else if(response.status === 400){ //when no previewe then bad request with 400 then show message
                    setMessage("No Preview Questions Available for Questions, Contact to Admin..... ");
                    setQuestions([]);
                }
            });
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        getPreviewTests();
    }, []);

    const [checkedOption, setCheckedOption] = useState(null);

    const handleCheckboxClick = (option) => {
        setCheckedOption(option);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="RemoveOutLine"
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Container maxWidth="xl">
                            <Box sx={{ flexGrow: 1, pt: 5 }}>
                                <Grid container spacing={0}>
                                    <Grid xs={8}>
                                        <Item>
                                            <Typography
                                                variant="h2"
                                                sx={{ fontSize: "22px", fontWeight: 600 }}
                                            >
                                                Tests preview
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={{ fontSize: "16px", fontWeight: 400, mt: 2 }}
                                            >
                                                Each test includes a set of questions randomly selected
                                                from our question bank
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item
                                            sx={{
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CloseOutlinedIcon
                                                onClick={handleClose}
                                                sx={{ fontSize: "20px", cursor: "pointer" }}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            <Box sx={{ width: "100%", mt: 5, background: "white" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs
                                        className="previewTabs"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        {questions?.map((item, key) => {
                                            return (
                                                <Tab
                                                    sx={{
                                                        color: "#666",
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        p: 3,
                                                    }}
                                                    label={item.title}
                                                    {...a11yProps(key)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </Box>
                                {message === "" ?  //message empty then show this else message show
                                <Box sx={{ height: "75vh", overflow: "scroll" }}>
                                    {questions?.map((item, index) => {
                                        return (
                                            <>
                                                <CustomTabPanel value={value} index={index}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            sx={{ fontSize: "18px", fontWeight: 700 }}
                                                        >
                                                            Sample questions
                                                        </Typography>
                                                        <Grid
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "25px",
                                                            }}
                                                        >
                                                            <Item
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                <SignalCellularAltOutlinedIcon
                                                                    sx={{ fontSize: "18px", color: "#666" }}
                                                                />
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontSize: "16px", fontWeight: 700 }}
                                                                >
                                                                    {item.level}
                                                                </Typography>
                                                            </Item>
                                                            <Item
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <AccessAlarmIcon
                                                                    sx={{ fontSize: "18px", color: "#666" }}
                                                                />
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontSize: "16px", fontWeight: 700 }}
                                                                >
                                                                    {item.time} mins
                                                                </Typography>
                                                            </Item>
                                                        </Grid>
                                                    </Box>

                                                    {item?.questions?.map((question, index) => {
                                                        return (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                        py: 2,
                                                                        border: "1px solid #e0e0e0",
                                                                        borderRadius: "8px",
                                                                        p: 2,
                                                                        mt: 2,
                                                                    }}
                                                                >
                                                                    <Grid container spacing={0}>
                                                                        <Grid sx={{ p: 5 }} md={6} xs={12}>
                                                                            <Typography
                                                                                variant="h6"
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 600,
                                                                                    mt: 2,
                                                                                }}
                                                                            >
                                                                                {question.question}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid sx={{ p: 5 }} md={6} xs={12}>
                                                                            <Item>
                                                                                <Typography
                                                                                    variant="h2"
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: 400,
                                                                                    }}
                                                                                >
                                                                                    SELECT ONLY ONE
                                                                                </Typography>

                                                                                <Grid
                                                                                    sx={{
                                                                                        maxHeight: "60vh",
                                                                                        overflowY: "scroll",
                                                                                    }}
                                                                                >
                                                                                    <RadioGroup
                                                                                        style={{ flexDirection: "column" }}
                                                                                        row
                                                                                        aria-label="radio-group-english"
                                                                                        name="fluentEnglish"
                                                                                    >
                                                                                        {question?.options?.map(
                                                                                            (option) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Grid
                                                                                                            className={
                                                                                                                checkedOption === option
                                                                                                                    ? "talentRadioBtnChecked"
                                                                                                                    : "talentRadioBtnUnChecked"
                                                                                                            }
                                                                                                            sx={{
                                                                                                                my: 1,
                                                                                                            }}
                                                                                                            onClick={() =>
                                                                                                                handleCheckboxClick(
                                                                                                                    option
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                sx={{
                                                                                                                    width: "100%",
                                                                                                                    boxShadow:
                                                                                                                        "0 2px 8px 1px #a8a8a833",
                                                                                                                }}
                                                                                                                // value={item.id}
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        inputProps={{
                                                                                                                            "aria-label":
                                                                                                                                "controlled",
                                                                                                                        }}
                                                                                                                        icon={
                                                                                                                            <RadioButtonUncheckedIcon
                                                                                                                                sx={{
                                                                                                                                    fontSize:
                                                                                                                                        "35px",
                                                                                                                                    color:
                                                                                                                                        "#ededed",
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        }
                                                                                                                        checkedIcon={
                                                                                                                            <RadioButtonCheckedIcon
                                                                                                                                sx={{
                                                                                                                                    fontSize:
                                                                                                                                        "35px",
                                                                                                                                    color:
                                                                                                                                        "white",
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        }
                                                                                                                        checked={ checkedOption === option?true:false}
                                                                                                                    ></Checkbox>
                                                                                                                }
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: "14px",
                                                                                                                            fontWeight: 500,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                                __html: option,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                }
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </RadioGroup>
                                                                                </Grid>
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </>
                                                        );
                                                    })}
                                                </CustomTabPanel>
                                            </>
                                        );
                                    })}
                                </Box>
                            :<Box sx={{ height: "75vh", overflow: "scroll",display:"flex",justifyContent:"center",alignItems:"center" }}>{message}</Box>}
                            </Box>
                        </Container>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
