import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function BasicGrid({
    language,
    handelProgrammingCheckbox,
    label,
    programmingSelected,
}) {
    const [isHoveredLanguage, setIsHoveredLanguage] = useState(false);

    return (
        <Grid xs={12} sm={6} md={4} lg={2} key={language.id}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={`programmingCheckbox${language.id}`}
                        checked={programmingSelected.includes(language.id)}
                        sx={{ display: "none" }}
                    />
                }
                label={
                    <label
                        htmlFor={`programmingCheckbox${language.id}`}
                        onClick={(e) => handelProgrammingCheckbox(e, language.id)}
                    >
                        <Grid
                            sx={{
                                textAlign: "center",
                                padding: "40px",
                                borderRadius: "5px",
                                "& .Mui-checked ": {
                                    color: "var(--button-bg-color-green)",
                                },
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    cursor: "pointer",
                                    "& .languageText": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .customCheckbox": {
                                        color: "var(--button-bg-color-green)",
                                    },
                                    "& .MuiCheckbox-root ": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onMouseEnter={() => setIsHoveredLanguage(true)}
                            onMouseLeave={() => setIsHoveredLanguage(false)}
                        >
                            <img
                                src={
                                    isHoveredLanguage
                                        ? language.image_path.hoverImg
                                        : language.image_path.frontImg
                                }
                                alt={language.name}
                                style={{
                                    width: "100px", // Adjust the width as needed
                                    height: "100px",
                                    marginBottom: "10px", // Adjust the height as needed
                                }}
                            />
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Grid>
                                    <Checkbox
                                        className="customCheckbox"
                                        {...label}
                                        id={`programmingCheckbox${language.id}`}
                                        checked={programmingSelected.includes(language.id)}
                                        style={{}} // Adjust the spacing between the Typography and Checkbox
                                    />
                                </Grid>
                                <Typography
                                    className="languageText"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                    }}
                                >
                                    {language.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </label>
                }
            />
        </Grid>
    );
}
