import { Grid, Box, Typography, Paper, Container, TextField, Button, Autocomplete, useMediaQuery } from "@mui/material";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import axios from "axios";
import { getFileHeaders, getHeaders, getMyCompanyDetailsUrl, updateMyCompanyDetailsUrl, uploadCompanyLogo } from "../../../Constants/ConstantsUrl";
import { useEffect, useState } from "react";
import Alerts from "../../Common/Alerts";
import Loader from "../Loader";
import CustomLoader from "../../Common/CustomLoader"


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));

function MyCompany({ customTheme, language }) {
    const isMobile = useMediaQuery("(max-width:899px)");
    const outerTheme = useTheme();
    const [loaderState, setLoaderState] = useState(false);
    const [uploadingLogo, setUploadingLogo] = useState(false);

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });

    const [formData, setFormData] = useState({
        company_name: "Test-n-Hire",
        // country_id: 1,
        lang_id: 1, 
        company_logo_path:"",
        company_color_code: "",
    });
    const [errorFormData, setErrorFormData] = useState({
        company_name: "",
        lang_id: "", 
        company_logo_path:"",
        company_color_code: "",
    });
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });
    const [logo, setLogo] = useState();

    const setFormDataCompany = (name, value) => {
        setErrorFormData({...errorFormData, [name]:""
                });
        setFormData({ ...formData, [name]: (name==="lang_id")?value: value.trim() });
    };

    const getMyCompanyDetails = async () => {
        setLoaderState(true);
        try {
            const headers = getHeaders();
            await axios
            .get(getMyCompanyDetailsUrl, { headers })
            .then((response) => response)
            .then((response) => {
                const res = response.data;
                if (res.status === 200) {
                    setFormData(res.data);
                    setLogo(res.data.company_logo);
                    setLoaderState(false);
                    }
                });
        } catch (error) {
            console.log(error);
            setLoaderState(false);
        }
    };

    const handleNavigate = () => {  
        const params = new URLSearchParams({ 
            companyColorCode: formData?.company_color_code,
            companyName: formData?.company_name,
        }).toString();
        window.open(`/customer/preview?${params}`, '_blank');
    };
     

    const handelSubmitCompany = async () => {
        try {
            const headers = getHeaders();            
            if(formData.company_name ==='' || formData.company_name === undefined || formData.company_name === null){
                setErrorFormData({...errorFormData, ['company_name']:"Insert company name"
                });
                return false;
            }
            if(formData.lang_id ==='' || formData.lang_id === undefined || formData.lang_id === null){
                setErrorFormData({...errorFormData, ['lang_id']:"Select Language"
                });
                return false;
            }
            await axios
                .post(updateMyCompanyDetailsUrl, formData, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                    }
                });
        } catch (error) {
            setAlertNotification({
                status: true,
                type: "error",
                message: "Please try again later",
            });
        }
    };

    const handelUploadCompanyLogo = async (e) => {
        setUploadingLogo(true)
        try {
            const headers = getFileHeaders();
            const formData = new FormData();
            formData.append("company_logo", e.target.files[0]);
            await axios
                .post(uploadCompanyLogo, formData, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    setUploadingLogo(false)
                    if (res.status === 200) {
                        setLogo(res.data.logo_path);                         
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                    }
                });
        } catch (error) {
            setUploadingLogo(false)
            setAlertNotification({
                status: true,
                type: "error",
                message: "Please try again later",
            });
        }
    };

    useEffect(() => {
        getMyCompanyDetails();
    }, []);

    useEffect(() => {}, [formData]);

    return (
        <Container maxWidth="xl" style={{ padding: "2px" }}>
            {loaderState? <Loader />:
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5}
                        sx={{
                            bgcolor: "#f0f0f0",
                            height: "300px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                            border: "1px dashed #888",
                            borderRadius: "8px",
                            p: 1,
                        }}
                        component="label"
                        variant="contained"
                    >
                        {uploadingLogo ? (
                            <Grid>
                                <CustomLoader/>
                            </Grid>
                        ):(
                            <>
                                <VisuallyHiddenInput type="file" accept=".jpg,.png,.jpeg" onChange={(e) => handelUploadCompanyLogo(e)} />
                                {logo ? (
                                    <img style={{ width: "90%", maxWidth: "70%", maxHeight: "70%", marginTop: "30px" }} src={logo} alt="Company Logo" />
                                ) : (
                                    <Item
                                        sx={{
                                            backgroundColor: "rgb(240 240 240)",
                                            height: "300px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            cursor: "pointer",
                                            marginTop: "30px", // Add consistent styling
                                        }}
                                    >
                                        <PersonOutlineIcon sx={{ fontSize: "55px" }} />
                                        <Typography sx={{ fontSize: "16px", fontWeight: 400 }} variant="body1">
                                            Upload your company logo
                                        </Typography>
                                    </Item>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid item sx={{ p: 1 }} xs={12} sm={4}  md={4}>
                        <Item>
                            <Grid container spacing={0}>
                                <Grid xs={6} sm={6} md={6}>
                                    <Box
                                        component="form"
                                        sx={{
                                            "& .MuiTextField-root": {
                                                width: "90%",
                                            },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField disabled id="outlined-disabled" label="Color" value={formData?.company_color_code} />
                                    </Box>
                                </Grid>
                                <Grid sx={{ display: "flex", justifyContent: "end",}} xs={6} sm={6} md={6}>
                                    <Button
                                        style={{ padding: "16px 30px" }}
                                        sx={{
                                            textTransform: "none",
                                            background: "var(--button-bg-color-green)",
                                            "&:hover": {
                                                background: "var(--button-hover-bg-color-LightGreen)",
                                            },
                                        }}
                                        variant="outlined"
                                        onClick={handleNavigate}
                                    >
                                        <Typography
                                            fontWeight={600}
                                            sx={{
                                                fontSize: "var(  --btn-fontsize)",
                                                color: "var(--text-color-OffWhite)",
                                            }}
                                        >
                                            Preview
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid sx={{ mt: 2 }}>
                                <input
                                    type="color"
                                    value={formData?.company_color_code}
                                    onChange={(e) => {
                                        var color = e?.target?.value;
                                        setTimeout(function () {
                                            // apiCall(e.target.value);
                                            // handleColorChange(color);
                                            setFormDataCompany("company_color_code", color);
                                        }, 300);
                                    }}
                                    style={{
                                        width: "100%",
                                        height:  !isMobile ? "222px" : "150px",
                                        background: "white",
                                        border: "none",
                                        cursor: "pointer",
                                    }}
                                />
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item sx={{ p: 1 }} xs={12} sm={3} md={3}>
                        <Item>
                            <Box>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <TextField
                                        sx={{
                                            bgcolor: "white",
                                            borderRadius: "4px",
                                            width: "100%",
                                        }}
                                        label="Company name"
                                        value={formData?.company_name}
                                        onChange={(e) => setFormDataCompany("company_name", e.target.value)}
                                        error={errorFormData.company_name}
                                        helperText={errorFormData.company_name ? 'Company name cannot be empty' : ''}
                                    />
                                </ThemeProvider>
                            </Box>
                            <Box sx={{ mt: 3, width: "100%" }}>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={language}
                                        getOptionLabel={(option) => option.name ?? "select language"}
                                        value={language?.find((role) => role.id === formData?.lang_id) || null}
                                        onChange={(e, value) => {
                                            setFormDataCompany("lang_id", value?.id);
                                        }}
                                        sx={{
                                            width: "100%",
                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "4px",
                                                transition: "green 0.3s ease-in-out",
                                                "&:hover": {
                                                    borderColor: "var(--button-bg-color-green)",
                                                },
                                            },
                                            "& #combo-box-demo": {
                                                color: "var(--text-color-black)",
                                            },
                                        }}
                                        renderInput={(params) => <TextField name="lang_id" {...params} label="Language of assessment"
                                        error={errorFormData.lang_id}
                                        helperText={errorFormData.lang_id ? 'Language cannot be empty' : ''} />}
                                    />
                                </ThemeProvider>
                            </Box>
                        </Item>
                    </Grid>

                    <Grid sx={{ mt: 1, pl: 1, pr: 2 }} container spacing={0}>
                        <Grid item xs={12} sm={9} md={9}>
                            <Item sx={{ backgroundColor: "rgb(233 239 241)" }}>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        py: 1,
                                    }}
                                    variant="body1"
                                >
                                    Customize the appearance of your assessments to match your company's branding.
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: 5 }} container spacing={0}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ display: "flex", justifyContent: "end" }}>                             
                                <Button
                                    style={{ padding: "15px" }}
                                    sx={{
                                      
                                        textTransform: "none",
                                        background: "var(--button-bg-color-green)",
                                        "&:hover": {
                                            background: "var(--button-hover-bg-color-LightGreen)",
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={handelSubmitCompany}
                                >
                                    <Typography
                                        fontWeight={600}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                            color: "var(--text-color-OffWhite)",
                                        }}
                                    >
                                        Save Changes
                                    </Typography>
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
        </Container>
    );
}

export default MyCompany;
