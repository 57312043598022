import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function VideoStream({ videoUrl, markersPoint, time, handleDummyPlayerClick }) {
	const [url] = useState(videoUrl);
	// const [isPlaying, setIsPlaying] = useState(false);
	// const [volume, setVolume] = useState(0.7);
	// const [timeStart] = useState(0);

	// const videoJsOptions = {
	//   autoplay: false,
	//   controls: true,
	//   responsive: true,
	//   height: '350px',
	//   width: '450px',
	//   sources: [{
	//     src: url,
	//     type: 'video/mp4'
	//   }]
	// };
	// const handlePlay = () => {
	//   setIsPlaying(true);
	// };

	// const handlePause = () => {
	//   setIsPlaying(false);
	// };

	// const handleVolume = value => {
	//   setVolume(value);
	// };

	// const handleProgress = e => {
	// };

	// const handleDuration = duration => {
	//   // console.log('Duration: ', duration);
	// };

	// const handleMarkerClick = marker => {
	//   // alert(marker.title);
	// };


	return (
		<div style={{ position: "relative", height: '250px', width: '100%' }}>
			{/* <VideoPlayer
        url={url}
        isPlaying={isPlaying}
        volume={volume}
        loop={true}
        markers={markersPoint}
        height={'auto'}
        width={'100%'}
        timeStart={timeStart}
        onPlay={handlePlay}
        onPause={handlePause}
        onVolume={handleVolume}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onMarkerClick={handleMarkerClick}
        compDuration={time}
      /> */}
			<video src={url} alt="" style={{ width: "100%", height: "100%", opacity: 0.7 }} />
			<Button
				variant="outlined"
				style={{
					position: "absolute",
					top: "50%",
					left: "45%",
					color: "black",
					borderColor: "black",
					opacity: 1,
				}}
				onClick={(e) => {
					if (typeof handleDummyPlayerClick === 'function') {
						handleDummyPlayerClick(videoUrl, markersPoint, time);
					} else {
						console.log('handleDummyPlayerClick type: ', typeof handleDummyPlayerClick);
					}
				}}
			>
				<PlayArrowIcon />
			</Button>
		</div>
	);
}

export default VideoStream;
