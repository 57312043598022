import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactQuill from "react-quill";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CodeEditor from "../../Common/CodeEditor";
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { outlinedInputClasses } from '@mui/material/OutlinedInput'; 
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "16.5px 0px 16.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 4,
            borderColor: "var(--border-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--border-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        minWidth: "245px",
    },
}));

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': "var(--border-color-Green)",
            '--TextField-brandBorderHoverColor': "var(--border-color-Green)",
            '--TextField-brandBorderFocusedColor': "var(--border-color-Green)",
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&::before, &::after': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&::before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "unset",
    background: "unset",
}));
const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",

    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
};
export default function BasicGrid() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const ArrowPosition = isMobile ? "absolute" : "";
    const ArrowTop = isMobile ? "0px" : "";
    const ArrowLeft = isMobile ? "0px" : "";
    const FormWidth = isMobile ? "100%" : "100%";
    const TextMarginLeft = isMobile ? "117px" : "";
    const TeamPadding = isMobile ? "" : "10px";
    const outerTheme = useTheme();
    const [value, setValue] = React.useState("one");
    const [fields, setFields] = useState([{ name: "", age: "", isDelete: false }]);

    const handleChangeForm = (index, event) => {
        const updatedFields = [...fields];
        updatedFields[index][event.target.name] = event.target.value;
        setFields(updatedFields);
    };

    const handleAddNew = () => {
        setFields([...fields, { name: "", age: "", isDelete: false }]);
    };

    const handleDelete = (index) => {
        const updatedFields = [...fields];
        updatedFields.splice(index, 1);
        setFields(updatedFields);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [age, setAge] = React.useState("");

    const handleChangeSelect = (event) => {
        setAge(event.target.value);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }
    return (
        <Box>
            <Grid
                container
                sx={{
                    backgroundColor: "#074234",
                }}
            >
                <Grid xs={12} md={6}>
                    <Item
                        style={{
                            boxShadow: "unset",
                            borderRadius: "none",
                            background: "unset",
                            display: "flex",
                            padding: "0",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            sx={{
                                backgroundColor: "var(--button-bg-color-green)",
                                p: "20px",
                                position: ArrowPosition,
                                left: ArrowTop,
                                top: ArrowLeft,
                                cursor: "pointer",
                                display: isMobile ? "none" : "",
                            }}
                            // onClick={onClose}
                        >
                            <KeyboardArrowLeftIcon
                                sx={{ color: "var(--text-color-OffWhite)", height: "40px" }}
                            ></KeyboardArrowLeftIcon>
                        </Grid>

                        <Grid sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography
                                sx={{
                                    color: "var(--text-color-OffWhite)",
                                    fontWeight: 600,
                                    fontSize: "var(  --nav-fontsize)",
                                    p: "20px",
                                    marginLeft: TextMarginLeft,
                                }}
                                variant="h2"
                            >
                                New Question
                            </Typography>
                        </Grid>
                    </Item>
                </Grid>
                <Grid
                    sx={{ ml: !isMobile ? "auto" : "170px", m: !isMobile ? "auto" : " " }}
                    xs={6}
                    md={6}
                >
                    <Item
                        sx={{
                            mx: "50px",
                            mt: "10px",
                            mb: "10px",
                            background: "unset",
                            boxShadow: "unset",
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <Stack spacing={2} direction="row">
                            <Button
                                style={{
                                    background: "#FFF0E1",
                                    color: "var(--text-color-black)",
                                    boxShadow: "unset",
                                    borderRadius: "4px",
                                    padding: "15px 30px",
                                }}
                                sx={{
                                    textTransform: "none",
                                }}
                                // onClick={handleBack}
                            >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        fontSize: "var(  --btn-fontsize)",
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                            <Button
                                style={{
                                    background: "var(--text-color-Green)",
                                    color: "var(--text-color-black)",
                                    boxShadow: "unset",
                                    borderRadius: "4px",
                                    padding: "15px 30px",
                                }}
                                sx={{ textTransform: "none" }}
                                // onClick={handelSubmit}
                            >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        fontSize: "var(  --btn-fontsize)",
                                        color: "var(--text-color-OffWhite)",
                                    }}
                                >
                                    Save
                                </Typography>
                            </Button>
                        </Stack>
                    </Item>
                </Grid>
            </Grid>
            <Box sx={{ width: "100%", mt: 5 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#074234",
                        },
                        "& .MuiTab-root": {
                            fontSize: "20px",
                            fontWeight: 700,
                        },
                        "& .MuiTab-textColorPrimary": {
                            color: "var(--text-color-Green)",
                            textTransform: "capitalize",
                        },
                        "& .MuiTab-textColorPrimary.Mui-selected": {
                            color: "var(--text-color-Green)",
                        },
                        "& .MuiTabs-centered ": {
                            gap: "80px",
                        },
                    }}
                    aria-label="secondary tabs example"
                    centered
                >
                    <Tab value="one" label="Questions" />
                    <Tab value="two" label="Code Editor" />
                </Tabs>
                <TabPanel value={value} index="one">
                    <Box sx={{ flexGrow: 1, mt: 5 }}>
                        <Grid container spacing={2}>
                            <Grid sx={{ px: 2 }} xs={12} md={6}>
                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        padding: "0px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "24px", fontWeight: "700" }}
                                    >
                                        Question
                                    </Typography>
                                    <Grid>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                mt: "15px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {/* {extraQuestionBox && extraQuestionBox !== 6 && ( */}
                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                            <TextField
                                                name="time"
                                                // value={formData?.time}
                                                fullWidth
                                                label="Enter time in min"
                                                type="number"
                                                id="time"
                                                variant="outlined"
                                                // error={errorMsg.time ? true : false}
                                                // helperText={errorMsg.time ? (errorMsg.time === true ? "time is required" : errorMsg.time) : null}
                                                // onChange={handelChangeTextBox}
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderRadius: "4px",
                                                            transition: "green 0.3s ease-in-out",
                                                            "&:hover": {
                                                                // borderColor: '#0000001f',
                                                            },
                                                        },
                                                    "& #time-label": {
                                                        color: "var(--text-color-black)",
                                                    },
                                                }}
                                            />
                                            </ThemeProvider>
                                            {/* )} */}
                                        </Box>

                                        <Box
                                            sx={{
                                                bgcolor: "#E4EDED",
                                                mt: "15px",
                                                borderRadius: "5px",
                                                px: "25px",
                                                display: "flex",
                                                py: "10px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <InfoOutlinedIcon sx={{ mr: "15px" }} />
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                }}
                                            >
                                                The language of your assessment is English. Make
                                                sure to phrase the question using the same language.
                                            </Typography>
                                        </Box>
                                        <Box
                                            component="form"
                                            sx={{
                                                width: "100%",
                                                mt: 3,
                                                border: "1px solid #aaa",
                                                borderRadius: "5px",
                                                position: "relative",
                                            }}
                                        >
                                            {" "}
                                            <ReactQuill
                                                name="question"
                                                label=" For example: Kindly introduce yourself."
                                                theme="snow"
                                                // value={formData.question.trimStart()}
                                                // onChange={(value) => handleEditorChange("question", value)}
                                            />
                                            {/* {errorMsg?.question && <span className="error_msg">{errorMsg?.question}</span>} */}
                                        </Box>
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Grid sx={{ bgcolor: "var(--text-color-OffWhite)", p: isMobile ? "0" : "40px" }}>
                                    <Grid sx={{ p: "0px" }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: "20px",
                                            }}
                                            variant="body1"
                                        >
                                            Information for your team
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "16px",
                                            }}
                                            variant="h6"
                                        >
                                            This information will not be shown to candidates.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        sx={{
                                            p: "0px",
                                            mt: 5,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Grid xs={12} md={12} sx={{ p: TeamPadding }}>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: "18px",
                                                    }}
                                                    variant="body2"
                                                >
                                                    Question title
                                                </Typography>
                                                <HelpIcon sx={{ m: "3px" }} />
                                            </Grid>
                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                            <TextField
                                                sx={{ bgcolor: "white" }}
                                                name="question_title"
                                                fullWidth
                                                margin="normal"
                                                // onChange={handelChangeTextBox}
                                                // error={errorMsg.question_title ? true : false}
                                                // helperText={errorMsg.question_title ? (errorMsg.question_title === true ? "question_title is required" : errorMsg.question_title) : null}
                                            />
</ThemeProvider>
                                            <Box sx={{ mt: "30px" }}>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "18px",
                                                        }}
                                                        variant="body2"
                                                    >
                                                        What to look for in the answer?
                                                    </Typography>
                                                    <HelpIcon sx={{ m: "3px" }} />
                                                </Grid>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                <TextField
                                                    sx={{ bgcolor: "white" }}
                                                    name="what_to_look_for_ans"
                                                    fullWidth
                                                    margin="normal"
                                                    multiline
                                                    rows={8}
                                                    //  onChange={handelChangeTextBox}
                                                />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={12} sx={{ p: TeamPadding }}>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: "18px",
                                                    }}
                                                    variant="body2"
                                                >
                                                    Question category
                                                </Typography>
                                                <Select
                                                    // value={formData.question_cat_id}
                                                    // onChange={handelChangeTextBox}
                                                    input={<BootstrapInput />}
                                                    displayEmpty
                                                    IconComponent={ArrowDropDownIcon}
                                                    inputProps={{
                                                        "aria-label": "Select option",
                                                    }}
                                                    name="question_cat_id"
                                                    sx={{
                                                        width: FormWidth,
                                                        bgcolor: "white",
                                                        borderRadius: "5px",
                                                        mt: "18px",
                                                        mb: "8px",
                                                    }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Choose
                                                    </MenuItem>
                                                    {/* {testTypeCategory.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.title.replace(/&amp;/g, "&")}
                                                                </MenuItem>
                                                            ))} */}
                                                </Select>
                                            </Box>
                                            <Box sx={{ mt: "30px", mb: 7 }}>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: "18px",
                                                        }}
                                                        variant="body2"
                                                    >
                                                        Why is this question relevant?
                                                    </Typography>
                                                    <HelpIcon sx={{ m: "3px" }} />
                                                </Grid>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                <TextField
                                                    sx={{ bgcolor: "white" }}
                                                    name="why_is_the_ques_rel"
                                                    fullWidth
                                                    margin="normal"
                                                    multiline
                                                    rows={8}
                                                    //  onChange={handelChangeTextBox}
                                                />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index="two">
                    <Box sx={{ flexGrow: 1, mt: 5 }}>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                                <Item
                                    sx={{
                                        boxShadow: "none",
                                        background: "none",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "24px", fontWeight: "700", mb: 3 }}
                                    >
                                        Variables
                                    </Typography>
                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                    <TextField
                                        sx={{
                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "4px",
                                                    // borderColor: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                                    transition: "green 0.3s ease-in-out",
                                                    "&:hover": {
                                                        // borderColor: '#0000001f',
                                                    },
                                                },
                                            "& #combo-box-demo": {
                                                color: "green",
                                            },
                                            "&:focus-within .MuiInputLabel-root": {
                                                // color: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                            },
                                        }}
                                        fullWidth
                                        label="Name"
                                        id="fullWidth"
                                        name="email"
                                        // value={data?.email}
                                        // error={errorMsg[index]?.email ? true : false}
                                        // helperText={errorMsg[index]?.email ? (errorMsg[index]?.email === true ? "Email is required" : errorMsg[index]?.email) : null}
                                        // onChange={(e) => handleInputChange(e, index)}
                                    />
                                    </ThemeProvider>
                                </Item>
                                <Box sx={{ height: "400px", overflowY: "scroll", mt: 3 }}>
                                    {fields.map((field, index) => (
                                        <Grid
                                            key={index}
                                            container
                                            spacing={2}
                                            columns={{ xs: 11, sm: 11, md: 11 }}
                                        >
                                            <Grid xs={5} md={5}>
                                                <Item>
                                                    {" "}
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    borderRadius: "4px",
                                                                    // borderColor: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                                                    transition:
                                                                        "green 0.3s ease-in-out",
                                                                    "&:hover": {
                                                                        // borderColor: '#0000001f',
                                                                    },
                                                                },
                                                            "& #combo-box-demo": {
                                                                color: "green",
                                                            },
                                                            "&:focus-within .MuiInputLabel-root": {
                                                                // color: errorMsg[index]?.email ? "red" : "var(--button-bg-color-green)",
                                                            },
                                                        }}
                                                        fullWidth
                                                        label="Name"
                                                        id="fullWidth"
                                                        name="email"
                                                        // value={data?.email}
                                                        // error={errorMsg[index]?.email ? true : false}
                                                        // helperText={errorMsg[index]?.email ? (errorMsg[index]?.email === true ? "Email is required" : errorMsg[index]?.email) : null}
                                                        // onChange={(e) => handleInputChange(e, index)}
                                                        value={field.name}
                                                        onChange={(e) => handleChangeForm(index, e)}
                                                    />
                                                    </ThemeProvider>
                                                </Item>
                                            </Grid>
                                            <Grid xs={5} md={5}>
                                                <Item>
                                                    <FormControl sx={{ minWidth: "100%" }}>
                                                        <InputLabel id="demo-simple-select-autowidth-label">
                                                            Age
                                                        </InputLabel>
                                                        <Select
                                                        input={<BootstrapInput />}
                                                            // labelId="demo-simple-select-autowidth-label"
                                                            id="demo-simple-select-autowidth"
                                                            // value={age}
                                                            // onChange={handleChangeSelect}
                                                            labelId={`age-label-${index}`}
                                                            value={field.age}
                                                            onChange={(e) =>
                                                                handleChangeForm(index, e)
                                                            }
                                                            autoWidth
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value={10}>Twenty</MenuItem>
                                                            <MenuItem value={21}>
                                                                Twenty one
                                                            </MenuItem>
                                                            <MenuItem value={22}>
                                                                Twenty one and a half
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Item>
                                            </Grid>
                                            <Grid sx={{ mt: "12px" }} xs={1} md={1}>
                                                {index === 0 ? (
                                                    <Button
                                                        onClick={handleAddNew}
                                                        fullWidth
                                                        label="Email"
                                                        id="fullWidth"
                                                        name="email"
                                                        // value={data?.email}
                                                        // error={errorMsg.email ? true : false}
                                                        // helperText={errorMsg.email ? (errorMsg.email === true ? "Email is required" : errorMsg.email) : null}
                                                        // onClick={() => handleDeleteOption(index)}
                                                        sx={{
                                                            textTransform: "none",
                                                            background: "#ccc",
                                                            border: "0",
                                                            "&:hover": {
                                                                background: "#ccc",
                                                                border: "none",
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <Typography
                                                            fontWeight={600}
                                                            sx={{
                                                                fontSize: "var(  --btn-fontsize)",
                                                                color: "var(--text-color-black)",
                                                            }}
                                                        >
                                                            Add
                                                        </Typography>

                                                        {/* <CloseIcon
                       sx={{ color: "var(--text-color-black)" }}
                   /> */}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => handleDelete(index)}
                                                        fullWidth
                                                        label="Email"
                                                        id="fullWidth"
                                                        name="email"
                                                        // value={data?.email}
                                                        // error={errorMsg.email ? true : false}
                                                        // helperText={errorMsg.email ? (errorMsg.email === true ? "Email is required" : errorMsg.email) : null}
                                                        // onClick={() => handleDeleteOption(index)}
                                                        //   style={btnStyle}
                                                        sx={{
                                                            textTransform: "none",
                                                            background: "#ccc",
                                                            border: "0",
                                                            "&:hover": {
                                                                background: "#ccc",
                                                                border: "none",
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <Typography
                                                            fontWeight={600}
                                                            sx={{
                                                                color: "var(--text-color-black)",
                                                                fontSize: "var(  --btn-fontsize)",
                                                            }}
                                                        >
                                                            Delete{" "}
                                                        </Typography>

                                                        {/* <CloseIcon
                      sx={{ color: "var(--text-color-black)" }}
                  /> */}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>

                                <Stack
                                    direction="Column"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        py: "15px",
                                    }}
                                >
                                    <Button
                                        style={btnStyle}
                                        sx={{
                                            textTransform: "none",
                                            background: "#ccc",
                                            border: "0",
                                            my: "10px",
                                            "&:hover": {
                                                background: "#ccc",
                                                border: "none",
                                            },
                                        }}
                                        variant="outlined"
                                    >
                                        {" "}
                                        <Typography
                                            fontWeight={600}
                                            sx={{
                                                fontSize: "var(  --btn-fontsize)",
                                            }}
                                        >
                                            Update
                                        </Typography>
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid xs={6}>
                                <Box sx={{ textAlign: "end" }}>
                                    <FormControl sx={{ minWidth: "30%" }}>
                                        <InputLabel id="demo-simple-select-autowidth-label">
                                            Age
                                        </InputLabel>
                                        <Select
                                        input={<BootstrapInput />}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={age}
                                            onChange={handleChangeSelect}
                                            autoWidth
                                            label="Age"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>Twenty</MenuItem>
                                            <MenuItem value={21}>Twenty one</MenuItem>
                                            <MenuItem value={22}>Twenty one and a half</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ paddingX: 0 }}>
                                    <Box sx={{ width: "100%" }}>
                                        <CodeEditor
                                            name="back_temp"
                                            // onChange={(value) => handleCodeChange("back_temp", value)}
                                            language="python"
                                            // code={backTemp}
                                            theme="vs-dark" // Change the theme to your preference
                                        />
                                        {/* {errorMsg?.back_temp && <span className="error_msg">{errorMsg?.back_temp}</span>}   */}
                                    </Box>

                                    <Stack
                                        direction="Column"
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end", // Aligns the buttons at the bottom
                                            alignItems: "flex-end", // Aligns the buttons to the right
                                            height: "100%",
                                            py: "15px", // Ensures the box takes full height
                                        }}
                                    >
                                        <Button
                                            style={btnStyle}
                                            sx={{
                                                textTransform: "none",
                                                background: "#ccc",
                                                border: "0",
                                                my: "10px",
                                                "&:hover": {
                                                    background: "#ccc",
                                                    border: "none",
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            {" "}
                                            <Typography
                                                fontWeight={600}
                                                sx={{
                                                    fontSize: "var(  --btn-fontsize)",
                                                }}
                                            >
                                                Run
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        {" "}
                        <Typography variant="h2" sx={{ fontSize: "18px", fontWeight: 700 }}>
                            {" "}
                            Test Cases{" "}
                        </Typography>
                        <Grid container spacing={0}>
                            
                            <Grid xs={12} md={12}>
                                <Box sx={{ width: "100%", overflowX: "scroll", height: "450px" }}>
                                    <table className="CodeTable">
                                        <tbody>
                                            {[1, 2, 3, 4].map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField label="Name" />
                                                        </ThemeProvider>
                                                    </TableCell>
                                                    <TableCell>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField label="Email" />
                                                        </ThemeProvider>
                                                    </TableCell>
                                                    <TableCell>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField label="Mobile Number" />
                                                        </ThemeProvider>
                                                    </TableCell>
                                                    <TableCell>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField label="Last Name" />
                                                        </ThemeProvider>
                                                    </TableCell>
                                                    <TableCell>
                                                        <CloseIcon sx={{ cursor: "pointer" }} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </tbody>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
            </Box>
        </Box>
    );
}
