import React, { useState, useEffect, useMemo } from "react";
import _debounce from "lodash/debounce";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Typography, Box, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MainSelectQuestion from "./MainSelectQuestion";
import MainQuestionSelected from "./MainQuestionSelected";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import axios from "axios";
import { GetMainQuestion, getHeaders, TestTypeCategory } from "../../../../../Constants/ConstantsUrl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ConfirmModal from "../../../../Common/ConfirmModal";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });
export default function SelectTest({ mainQuestionValue, setMainQuestionValue, SetCountQuestion, countQuestion, SetTimeQuestion, timeQuestion }) {
    const isMobile = useMediaQuery("(max-width:960px)");
    const BoxMT = isMobile ? "40px" : "";
    const FormMargin = isMobile ? "" : "20px";
    const outerTheme = useTheme();
    const [mainqLoader, setMainqLoader] = useState(false);
    const [morethen5Error, setMorethen5Error] = useState({ id: 0, msg: null });
    // const [questionList, setMainQuestionList] = useState([]);
    const [questionCount, setMainQuestionCount] = useState([]);
    const [questionLevelCount, setMainQuestionLevelCount] = useState([]);
    const [page, setPages] = useState(1);
    const [questionType, setQuestionType] = useState({
        level_types: {},
        question_types: {},
        search: "",
        test_types: {},
        time: {},
    });
    const [searchedData, setSearchedData] = useState("");
    const [testTypeCategory, setTestTypeCategory] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [optionForConfirm, setOptionForConfirm] = useState();
    const memoizedTestTypeCategory = useMemo(() => testTypeCategory, [testTypeCategory]);
    const [emptyArry, setEmptyArray] = useState([1, 2, 3, 4, 5]);
    const [questionTestTypeCount, setMainQuestionTestTypeCount] = useState([]);
    const [questionQuestionTimeCount, setMainQuestionQuestionTimeCount] = useState([]);

    const [expandedAccordions, setExpandedAccordions] = useState({
        // Set true to open by default for accodian
        levelAccordion: true,
        questionTypeAccordian: true,
        timeAccordion: true,
        testTypeAccordian: true
    });

    // State to control the open/close of multiple accordions
    const handleChange = (panel) => {
        const isExpanded = expandedAccordions[panel]; // current state
        setExpandedAccordions(prev => ({
            ...prev,
            [panel]: !isExpanded // toggle
        }));
    };

    const handleConfirm = (option) => {
        const updatedTests = mainQuestionValue.filter((selectedTest) => selectedTest.question_id !== option.id);
        setMainQuestionValue(updatedTests);
        const newValue = emptyArry.length + 1; // Generate a new value
        const newArray = [...emptyArry, newValue]; // Create a new array with the new value appended
        setEmptyArray(newArray);
        SetCountQuestion(countQuestion - 1);
        SetTimeQuestion(timeQuestion - option.time);
        setOptionForConfirm();
        setOpen(false);
    };

    const handleRemoveTest = (option) => {
        setOpen(true);
        setOptionForConfirm(option);
    };

    const updateMainTestCase = (name, id, time, type_id, prog, show_testcase) => {
        setMainqLoader(true);
        if (mainQuestionValue.length < 10) {
            const newArray = emptyArry.slice(0, -1);
            setEmptyArray(newArray);
            const data = {
                question_category_id: 1,
                type: "Official",
                question_id: id,
                time: time,
                question: name,
                question_type_id: type_id,
                weight: 1,
                pro_lang_id: prog,
                show_testcases_ids: show_testcase,
            };
            SetCountQuestion(countQuestion + 1);
            SetTimeQuestion(timeQuestion + parseInt(time));
            setMainQuestionValue([...mainQuestionValue, data]);
            setMainqLoader(false);
        } else {
            setMainqLoader(false);
            setMorethen5Error({
                id: id,
                msg: "Sorry, You have already 10 questions, If you want to add a new then delete any one.",
            });
            setTimeout(() => {
                setMorethen5Error({ id: 0, msg: null });
            }, 6000);
        }
    };

    const fetchData = async ({ pageParam, questType }) => {
        try {
            const headers = getHeaders();
            const searchData = `search=` + questionType?.search;
            const queryString = Object.entries(questionType?.question_types)
                .map(([key, value]) => `question_types[]=${key}`)
                .join("&");

            const queryLevelString = Object.entries(questionType?.level_types)
                .map(([key, value]) => `level_types[]=${key}`)
                .join("&");

            const queryTestTypes = Object.entries(questionType?.test_types)
                .map(([key, value]) => `question_cat_id[]=${key}`)
                .join("&");
            const queryTime = Object.entries(questionType?.time)
                .map(([key, value]) => `time[]=${key}`)
                .join("&");

            const questionData = await axios
                .get(GetMainQuestion + "?page=" + pageParam + "&" + queryString + "&" + queryLevelString + "&" + queryTestTypes + "&" + queryTime + "&" + searchData, {
                    headers,
                })
                .then((response) => response.data)
                .then((response) => {
                    const res = response.data;
                    setMainQuestionCount(res.counts);
                    setMainQuestionLevelCount(res.level);
                    setMainQuestionQuestionTimeCount(res?.time);
                    setMainQuestionTestTypeCount(res?.test_type);
                    return res.questions;
                });

            return questionData;
        } catch (error) {
            console.error("Error fetching data:", error);
            setMainqLoader(false);
        }
    };

    const { ref, inView } = useInView();
    const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, status } = useInfiniteQuery({
        cacheTime: 2 * 60 * 1000,
        queryKey: ["questions", questionType],
        queryFn: fetchData,
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            const nextPage = lastPage.length ? allPages.length + 1 : undefined;
            return nextPage;
        },
    });
    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage]);

    const handelQuestionType = (e, index, no) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        let filterKey = "";
        if (no === 1) {
            filterKey = "level_types";
        } else if (no === 2) {
            filterKey = "question_types";
        } else if (no === 3) {
            filterKey = e.target.name || "test_types";
        } else if (no === 4) {
            filterKey = "time";
        }
        if (e.target.checked) {
            const setData = { ...questionType?.[filterKey] };
            setData[index] = index;
            setPages(1);
            setQuestionType({
                ...questionType,
                [filterKey]: { ...setData },
            });
        } else {
            let updatedData = {};
            if (no === 3) {
                updatedData = Object.keys(questionType?.[filterKey])
                    .filter((key) => parseInt(key) !== parseInt(index))
                    .reduce((obj, key) => {
                        obj[key] = key;
                        return obj;
                    }, {});
            } else {
                updatedData = Object.keys(questionType?.[filterKey])
                    .filter((key) => key !== index)
                    .reduce((obj, key) => {
                        obj[key] = key;
                        return obj;
                    }, {});
            }
            setQuestionType({
                ...questionType,
                [filterKey]: { ...updatedData },
            });
        }
    };

    const searchHandleFun = (e) => {
        setSearchedData(e.target.value);
        const eventValue = e.target.value;
        setQuestionType({
            ...questionType,
            ["search"]: eventValue,
        });
    };

    useEffect(() => {
        getTestTypeCategory();
    }, []);

    const getTestTypeCategory = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(TestTypeCategory, { headers });
            const res = response.data;
            if (res.status) {
                setTestTypeCategory(res.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <Box sx={{ mt: 5 }}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid sx={{ bgcolor: "#e0eff2" }} xs={12} md={12}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                padding: "2px",
                            }}
                        >
                            <Typography sx={{}} variant="body1">
                                Your assessment can contain up to five tests. Browse the test library and add the most relevant ones.{" "}
                                <span style={{ textDecoration: "underline" }}>Utilize various types of tests for optimal results</span> .
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Container>
            <MainQuestionSelected emptyArry={emptyArry} selectedQuestionValue={mainQuestionValue} setSelectedQuestionValue={setMainQuestionValue} handleRemoveTest={handleRemoveTest} />

            <Container sx={{ mt: 5 }} maxWidth="xl">
                <Grid container sx={{ mb: "80px" }} spacing={0}>
                    <Grid xs={12} md={3}>
                        <Box sx={{ position: "sticky", top: "100px" }}>
                            <Box sx={{ mt: 2, display: "flex", ml: !isMobile ? "24px" : "0px" }}>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <TextField
                                        id="search"
                                        label="Search"
                                        value={searchedData}
                                        variant="outlined"
                                        sx={{
                                            bgcolor: "white",
                                            width: !isMobile ? "85%" : "100%",
                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "4px",
                                                borderColor: "var(--text-color-Green)",
                                                transition: "green 0.3s ease-in-out",
                                            },

                                            "& #combo-box-demo": {
                                                color: "var(--text-color-Green)",
                                            },
                                            "&:focus-within .MuiInputLabel-root": {
                                                color: "var(--text-color-Green)",
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <SearchIcon
                                                    style={{
                                                        color: "rgba(0, 0, 0, 0.54)",
                                                        marginRight: "16px",
                                                    }}
                                                />
                                            ),
                                        }}
                                        onChange={(e) => searchHandleFun(e)}
                                    />
                                </ThemeProvider>
                            </Box>

                            <Item
                                style={{
                                    boxShadow: "unset",
                                    borderRadius: "none",
                                    background: "none",
                                    marginRight: FormMargin,
                                    overflow: "auto",
                                    maxHeight: "70vh",
                                    paddingTop: "16px",
                                    paddingBottom: "16px",
                                }}
                            >
                                <Typography variant="h6" fontSize={"20px"} fontWeight={700} sx={{ px: 2, mt: 2 }}>
                                    Filters
                                </Typography>

                                <Accordion expanded={expandedAccordions.levelAccordion} onChange={() => handleChange('levelAccordion')} sx={{ boxShadow: "none", bgcolor: "none" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h6" fontSize={"16px"} fontWeight={700}>
                                            Level
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: "0px 16px" }}>
                                        <Typography variant="body1" fontSize={"16px"}>
                                            {" "}
                                            <FormControlLabel
                                                name="easy"
                                                onClick={(e) => handelQuestionType(e, "easy", 1)}
                                                control={<Checkbox />}
                                                sx={{ m: 0 }}
                                                label={`Easy (${questionLevelCount?.easy ?? 0})`}
                                            />
                                        </Typography>
                                        <Typography variant="body1" fontSize={"16px"}>
                                            {" "}
                                            <FormControlLabel
                                                name="intermediate"
                                                onClick={(e) => handelQuestionType(e, "intermediate", 1)}
                                                control={<Checkbox />}
                                                sx={{ m: 0 }}
                                                label={`Intermediate (${questionLevelCount?.intermediate ?? 0})`}
                                            />
                                        </Typography>
                                        <Typography variant="body1" fontSize={"16px"}>
                                            {" "}
                                            <FormControlLabel
                                                name="expert"
                                                onClick={(e) => handelQuestionType(e, "expert", 1)}
                                                control={<Checkbox />}
                                                sx={{ m: 0 }}
                                                label={`Expert (${questionLevelCount?.expert ?? 0})`}
                                            />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expandedAccordions.questionTypeAccordian} onChange={() => handleChange('questionTypeAccordian')} sx={{ boxShadow: "none", bgcolor: "none" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h6" fontSize={"16px"} fontWeight={700}>
                                            Questions type
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: "0px 16px" }}>
                                        <Typography variant="body1" fontSize={"16px"}>
                                            <FormControlLabel
                                                name="mcq"
                                                onClick={(e) => handelQuestionType(e, "Mcq", 2)}
                                                control={<Checkbox />}
                                                sx={{ m: 0 }}
                                                label={`Multiple-Choise (${questionCount?.mcq ?? 0})`}
                                            />
                                        </Typography>

                                        <Typography variant="body1" fontSize={"16px"}>
                                            <FormControlLabel
                                                name="code"
                                                onClick={(e) => handelQuestionType(e, "Code", 2)}
                                                control={<Checkbox />}
                                                sx={{ m: 0 }}
                                                label={`Code (${questionCount?.code ?? 0})`}
                                            />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expandedAccordions.testTypeAccordian} onChange={() => handleChange('testTypeAccordian')} sx={{ boxShadow: "none", bgcolor: "none" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h6" fontSize={"16px"} fontWeight={700}>
                                            Test types
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: "0px 16px" }}>
                                        {memoizedTestTypeCategory?.map((test) => {
                                            return (
                                                <Typography variant="body1" fontSize={"16px"}>
                                                    <FormControlLabel
                                                        name="test_types"
                                                        onClick={(e) => handelQuestionType(e, test.id, 3)}
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={` ${test?.title} (${questionTestTypeCount[test.id] ?? 0}) `}
                                                    />
                                                </Typography>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expandedAccordions.timeAccordion} onChange={() => handleChange('timeAccordion')} sx={{ boxShadow: "none", bgcolor: "none" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h6" fontWeight={700} fontSize={"16px"}>
                                            Questions Time
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {[10, 20, 30, 40, 50, 60].map((time) => {
                                            return (
                                                <Typography variant="body1" key={time} fontSize={"16px"}>
                                                    <FormControlLabel
                                                        name="time_types"
                                                        onClick={(e) => handelQuestionType(e, time.toString(), 4)}
                                                        control={<Checkbox />}
                                                        sx={{ m: 0 }}
                                                        label={
                                                            time === 10
                                                                ? `Up to ${time} minutes (${questionQuestionTimeCount?.[time] ?? 0})`
                                                                : `${time - 9}-${time} minutes (${questionQuestionTimeCount?.[time] ?? 0})`
                                                        }
                                                    />
                                                </Typography>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </Item>
                        </Box>
                    </Grid>

                    <Grid sx={{ mt: BoxMT, pb: 5 }} xs={12} md={9}>
                        <MainSelectQuestion
                            morethen5Error={morethen5Error}
                            updateMainTest={updateMainTestCase}
                            selectedTests={mainQuestionValue}
                            handleRemoveTest={handleRemoveTest}
                            questionList={data?.pages?.flat() || []}
                            innerref={ref}
                            isFetchingNextPage={isFetchingNextPage}
                            status={status}
                        />
                    </Grid>
                </Grid>
            </Container>
            {open && <ConfirmModal open={open} setOpen={setOpen} handleConfirm={handleConfirm} data={optionForConfirm} setData={setOptionForConfirm} from={"Official"} />}
        </Box>
    );
}
