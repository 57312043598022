import { Box, useMediaQuery, Button, Checkbox, Container, Divider, TextField, Typography, Paper, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import DataObjectIcon from '@mui/icons-material/DataObject';
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import StepThreeSkeleton from "../../../../Common/StepThreeSkeleton";
import {
    getHeaders,
    createCustomMcqQuestion,
    createCustomVideoQuestion,
    createCustomCodeQuestion,
    createCustomFileQuestion,
    createCustomEssayQuestion,
    getProgrammingLanguages,
} from "../../../../../Constants/ConstantsUrl";
import "react-quill/dist/quill.snow.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopupModel from "./PopupModel";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import LangSelectCodeModal from "../LangSelectCodeModal";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Fade from '@mui/material/Fade';
import ConfirmModal from "../../../../Common/ConfirmModal";
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "var(--border-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function AddCustomQuestionsSelect({
    searchedData,
    searchCustomHandleFun,
    setSelectedQuestion,
    selectedQuestion,
    customQuestionList,
    customQuestionTypeCount,
    customQuestionCount,
    language_id,
    handelQuestionType,
    CustomqLoader,
    SetCountQuestion,
    countQuestion,
    SetTimeQuestion,
    timeQuestion,
    setSearchedData,
    setQuestionType,
    questionType,
    callPopupForEdit,
}) {
    const isMobile = useMediaQuery("(max-width:960px)");
    const MobileWidth = isMobile ? "100%" : "75%";
    const ScrollMargin = isMobile ? "" : "20px";
    const ContentMargin = isMobile ? "40px" : "";
    const outerTheme = useTheme();
    const [errorMsg, setErrorMsg] = useState(
        {
            test_out: "",
            test_other: "",
            no_of_test_cases: "",
            test_cases: "",
            show_test_cases: "",
            // front_temp: {},
            // back_temp: {},
            pro_lang_id: "",
            // default_lang_id: "",
            language_id: "",
            time: "",
            question: "",
            question_title: "",
            question_cat_id: "",
            what_to_look_for_ans: "",
            why_is_the_ques_rel: "",
            mcq_options_id: [{}, {}],
        }
    );
    const [sussesMsg, setSussesMsg] = useState("");
    const [test, setTest] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [extraQuestionBox, setExtraQuestionBox] = useState(0);
    const [checked, setChecked] = useState(true);
    const [options, setOptions] = useState(["", "", "", ""]);
    const [answer, setAnswer] = useState("");
    const [step2Popup5, setStep2Popup5] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [testCases, setTestCases] = useState([""]);
    const [testOut, setTestOut] = useState([""]);
    const [testOther, setTestOther] = useState([""]);
    const [showMoreIndex, setShowMoreIndex] = useState(-1);
    const [optionsValidation, setOptionsValidation] = useState([]);
    const [errorMsgCode, setErrorMsgCode] = useState([""]);
    const [errorMsgTest, setErrorMsgTest] = useState([""]);
    const [testCaseErrMsg, setTestCaseErrMsg] = useState("");
    const [popupSubmitLoader, setPopupSubmitLoader] = useState(false);
    const [answerData, setAnswerData] = useState({});
    const [frontTemp, setFrontTemp] = useState("");
    const [backTemp, setBackTemp] = useState("");
    const [isCodeModalOpen, setCodeModelOpen] = useState(false);
    const [programmingSelected, setProgrammingSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedoption, setSelectedOption] = useState();
    const [modalLoader, setModalLoader] = useState(true);
    const [allProgrammingLang, setAllProgrammingLang] = useState([""]);
    const [languageError, setLanguageError] = useState(false);
    const [previewSaveLoader, setPreviewSaveLoader] = useState(false);
    const [toActiveSwitchButton, setToactiveSwitchButton] = useState(false);
    const [testCaseOP, setTestCaseOP] = useState([
        {
            t: "",
            o: "",
            checked: false,
        },
    ]);
    const [errorTestCaseMsg, setErrorTestCaseMsg] = useState([
        {
            t: false,
            o: false,
        },
    ]);
    const [twoTestCaseSelect, setTwoTestCaseSelect] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [optionForConfirm, setOptionForConfirm] = useState();
    const [indexsWithMatchingValues, setIndexsWithMatchingValues] = useState(['']);

    const closeCodeModal = () => {
        setCodeModelOpen(false);
        setProgrammingSelected([]);
        setSelectAll(false);
        setLanguageError(false);
    };
    function openCodeModel(option) {
        setSelectedOption(option);
        setCodeModelOpen(true);
    }

    useEffect(() => {
        if (isCodeModalOpen === true) {
            fetchDataL();
        }
    }, [isCodeModalOpen]);
    const fetchDataL = async () => {
        setModalLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(getProgrammingLanguages, {
                headers,
            });
            const res = response.data;
            if (res.status) {
                setAllProgrammingLang(res.data);
                setModalLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setModalLoader(false);
        }
    };

    const handelProgrammingCheckbox = (e, id) => {
        if (id === 0) {
            setSelectAll(!selectAll);
            setProgrammingSelected((prevSelected) => {
                if (!selectAll) {
                    // If selectAll true, include all programming language IDs (except 0)
                    const allLangIds = allProgrammingLang.map((lang) => lang.id).filter((langId) => langId !== 0);
                    return allLangIds;
                } else {
                    // If selectAll false, clear
                    return [];
                }
            });
        } else {
            setProgrammingSelected((prevSelected) => {
                if (prevSelected.includes(id)) {
                    // If selected, remove
                    return prevSelected.filter((selectedId) => selectedId !== id);
                } else {
                    // If not add
                    return [...prevSelected, id];
                }
            });
            const allLanguagesSelected = programmingSelected.length + 1 === allProgrammingLang.length;
            setSelectAll(allLanguagesSelected);
        }
    };
    const handelSubmitCodeModal = async () => {
        if (programmingSelected.length === 0) {
            setLanguageError(true);
            return false;
        }
        let prog = programmingSelected.join(",");
        updateSelectedQuestion(selectedoption.id, selectedoption.question_type, selectedoption.id, selectedoption.time, selectedoption.title, selectedoption.question_type_id, prog, toActiveSwitchButton);
        setCodeModelOpen(false);
        setToactiveSwitchButton(false);
        setProgrammingSelected([]);
        setSelectAll(false);
        setLanguageError(false);
    };

    const [formData, setFormData] = useState({
        test_out: "",
        test_other: "",
        no_of_test_cases: "",
        test_cases: "",
        show_test_cases: "",
        // front_temp: {},
        // back_temp: {},
        pro_lang_id: "",
        // default_lang_id: "",
        language_id: "",
        time: "",
        question: "",
        question_title: "",
        question_cat_id: "",
        what_to_look_for_ans: "",
        why_is_the_ques_rel: "",
        mcq_options_id: [{}, {}],
    });

    const handlePopupOpen = (e, no) => {
        setErrorMsg({
            test_out: "",
            test_other: "",
            no_of_test_cases: "",
            test_cases: "",
            show_test_cases: "",
            // front_temp: {},
            // back_temp: {},
            pro_lang_id: "",
            // default_lang_id: "",
            language_id: "",
            time: "",
            question: "",
            question_title: "",
            question_cat_id: "",
            what_to_look_for_ans: "",
            why_is_the_ques_rel: "",
            mcq_options_id: [{}, {}],
        });
        setIndexsWithMatchingValues(['']);
        setExtraQuestionBox(no);
        setPopupOpen(true);
    };

    const handlePopupClose = () => {
        setStep2Popup5(false);
        setPopupOpen(false);
        formDataReset();
    };

    const formDataReset = () => {
        setFormData({
            ...formData,
            test_out: "",
            test_other: "",
            no_of_test_cases: "",
            test_cases: "",
            show_test_cases: "",
            // front_temp: {},
            // back_temp: {},
            pro_lang_id: "",
            time: "",
            question: "",
            question_title: "",
            question_cat_id: "",
            what_to_look_for_ans: "",
            why_is_the_ques_rel: "",
            mcq_options_id: [{}, {}],
        });
        setBackTemp("");
        setFrontTemp("");
        setTestCases([""]);
        setTestOther([""]);
        setTestOut([""]);
        setTestCaseOP([
            {
                t: "",
                o: "",
                checked: false,
            },
        ]);
        setErrorTestCaseMsg([
            {
                t: false,
                o: false,
            },
        ]);
        setOptions(["", "", "", ""]);
        setAnswer("");
        setAnswerData({});
    };

    const handleChange = (event) => {
        if (event.target.name === "distributePoint") {
            setChecked(event.target.checked);
        }
        if (event.target.name === "demo-simple-select") {
            setTest(event.target.value);
        }
    };

    const updateSelectedQuestion = (key, type, id, time, question, type_id, prog, show_case) => {
        SetCountQuestion(countQuestion + 1);
        SetTimeQuestion(timeQuestion + parseInt(time));
        setSelectedQuestion([
            ...selectedQuestion,
            {
                question_category_id: 2,
                type: type,
                question_id: id,
                time: time,
                question: question,
                question_type_id: type_id,
                weight: 1,
                key: key,
                pro_lang_id: prog,
                show_testcases_ids: show_case,
            },
        ]);
    };

    const handleConfirm = (option) => {
        const updatedItems = selectedQuestion.filter((item) => item.question_id !== option.id || item.question_type_id !== option.type_id);
        setSelectedQuestion(updatedItems);
        SetCountQuestion(countQuestion - 1);
        SetTimeQuestion(timeQuestion - option.time);
        setOptionForConfirm();
        setOpen(false);
    };


    const removeItem = (key, type, id, time, question, type_id) => {
        setOpen(true);
        setOptionForConfirm({ key, type, id, time, question, type_id });
    };

    const toggleShowMore = (index) => {
        setShowMoreIndex(showMoreIndex === index ? -1 : index);
    };

    function handleEditorChange(name, value) {
        setErrorMsg({ ...errorMsg, [name]: "" });
        setFormData({ ...formData, [name]: value });
    }

    function isQuillEmpty(value) {
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img")) {
            return true;
        }
        return false;
    }

    function handelChangeTextBox(e) {
        setErrorMsg({ ...errorMsg, [e.target.name]: "" });
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    function handleChangeTimeBox(e, value) {
        if (value === "Time out of limit") {
            setErrorMsg({ ...errorMsg, "time": value });
            setFormData({ ...formData, "time": "" });
        }
        if (value === null) {
            setErrorMsg({ ...errorMsg, "time": "Please select a value" });
            setFormData({ ...formData, "time": value });
        } else {
            setFormData({ ...formData, "time": value });
            setErrorMsg({ ...errorMsg, "time": "" });
        }
    }

    const handleAddOption = () => {
        if (options.length < 10) {
            setOptions([...options, ""]);
        }
    };

    const handleDeleteOption = (index) => {
        if (options.length > 2) {
            const updatedOptions = options.filter((_, i) => i !== index);
            setOptions(updatedOptions);
        }
    };

    const handleOptionChange = (index, value) => {
        setErrorMsg({
            ...errorMsg,
            optionNotChoose: "",
        });
        if(indexsWithMatchingValues[index]){
            setIndexsWithMatchingValues({...indexsWithMatchingValues, [index]:''})
        }
        setOptionsValidation({ ...optionsValidation, [index]: "" });
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setFormData({
            ...formData,
            mcq_options_id: [{ ...updatedOptions }, { answer: answer }],
        });
        setOptions(updatedOptions);
        if (value === "") {
            handelAnswer("", index, false);
        }
    };

    const handelAnswer = (i, value, condition) => {
        setErrorMsg({
            ...errorMsg,
            optionNotChoose: "",
        });
        setOptionsValidation({ ...optionsValidation, [value]: "" });
        if (condition !== true) {
            if (answer[value]) {
                setOptionsValidation({
                    ...optionsValidation,
                    [value]: "Please fill this before",
                });
            }
            setAnswer({
                ...answer,
                [value]: false,
            });
            return false;
        }
        if (condition === true && options[value] === "") {
            if (i.target.checked) {
                setOptionsValidation({
                    ...optionsValidation,
                    [value]: "Please fill this before",
                });
            }
        }
        setAnswer({
            ...answer,
            [value]: i.target.checked,
        });
        setAnswerData({
            ...answerData,
            [value]: i.target.checked,
        });
        setFormData({
            ...formData,
            mcq_options_id: [{ ...options }, { ...answer, [value]: i.target.checked }],
        });
    };
    
    const handelSubmit = async (preview = false) => {
        if (preview) {
           setPreviewSaveLoader(true);
        }
        setPopupSubmitLoader(true);
        try {
            formData["language_id"] = language_id;
            let APIurl = "";
            const question = formData?.question;
            let question_type = "";
            if (extraQuestionBox === 1) {
                question_type = "Video";
                if (formData?.time === "" || formData?.time === undefined || isQuillEmpty(question) || formData?.language_id === "" || formData?.question_title === "" || formData?.question_cat_id === "") {
                    setErrorMsg({
                        ...errorMsg,
                        time: formData?.time ? "" : "Time is required",
                        question: isQuillEmpty(question) ? "Question is required" : "",
                        language_id: formData?.language_id ? "" : "Language is required",
                        question_title: formData?.question_title ? "" : "Question Title is required",
                        question_cat_id: formData?.question_cat_id ? "" : "Question category is required",
                    });
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }
                APIurl = createCustomVideoQuestion;
            } else if (extraQuestionBox === 2) {
                question_type = "Essay";
                if (formData?.time === "" || formData?.time === undefined || isQuillEmpty(question) || formData?.language_id === "" || formData?.question_title === "" || formData?.question_cat_id === "") {
                    setErrorMsg({
                        ...errorMsg,
                        time: formData?.time ? "" : "Time is required",
                        question: isQuillEmpty(question) ? "Question is required" : "",
                        language_id: formData?.language_id ? "" : "Language is required",
                        question_title: formData?.question_title ? "" : "Question Title is required",
                        question_cat_id: formData?.question_cat_id ? "" : "Question category is required",
                    });
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }
                APIurl = createCustomEssayQuestion;
            } else if (extraQuestionBox === 3) {
                question_type = "McQ";
                if (formData?.time === "" || isQuillEmpty(question) || formData?.language_id === "" || Object.keys(answer).length === 0 || formData?.question_title === "" || formData?.question_cat_id === "") {
                    setErrorMsg({
                        ...errorMsg,
                        time: formData?.time ? "" : "Time is required",
                        question: isQuillEmpty(question) ? "Question is required" : "",
                        language_id: formData?.language_id ? "" : "Language is required",
                        optionNotChoose: Object.keys(answer).length > 0 ? "" : "Please Select Any Answer",
                        question_title: formData?.question_title ? "" : "Question Title is required",
                        question_cat_id: formData?.question_cat_id ? "" : "Question category is required",
                    });
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }
                const mainData = formData.mcq_options_id[0];
                const indicesWithMatchingValues = [];
                Object.keys(mainData).forEach((key1, index1) => {
                    Object.keys(mainData).forEach((key2, index2) => {
                        if (index1 < index2 && mainData[key1] === mainData[key2]) {
                            indicesWithMatchingValues.push(parseInt(key1), parseInt(key2));
                        }
                    });
                });
                if(indicesWithMatchingValues.length > 0)
                {
                    // add same option value and loader false
                    setIndexsWithMatchingValues(indicesWithMatchingValues);
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }
                let checkaVali = false;
                let checkaVali2 = false;
                let mcq = formData?.mcq_options_id;
                const totalValues = Object.values(mcq[0]).filter((value) => value !== "").length;
                if (totalValues < 2) {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please Fill minimum 2 option",
                    });
                    setPopupSubmitLoader(false);
                    return false;
                }
                Object.entries(mcq[0]).forEach((data, key) => {
                    if (optionsValidation[key] !== "" && optionsValidation[key] !== undefined) {
                        checkaVali = true;
                    }
                    if (isQuillEmpty(mcq[0][key])) {
                        checkaVali2 = true;
                    }
                });
                if (checkaVali || checkaVali2) {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please Fill Answer and select any",
                    });
                    setPopupSubmitLoader(false);
                    return false;
                }
                if (Object.keys(answerData).length > 0) {
                    const trueKeys = Object.keys(answerData).filter((key) => answerData[key] === true);
                    if (trueKeys.length > 0) {
                        const trueKeysString = trueKeys.join(", ");
                        formData["mcq_options_id"][1]["answer"] = trueKeysString;
                    }
                    else {
                        setErrorMsg({
                            ...errorMsg,
                            optionNotChoose: "Please choose any Answer",
                        });
                        setPopupSubmitLoader(false);
                        return false;
                    }
                } else {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please choose any Answer",
                    });
                    setPopupSubmitLoader(false);
                    return false;
                }
                APIurl = createCustomMcqQuestion;
            } else if (extraQuestionBox === 4) {
                question_type = "File Upload";
                if (formData?.time === "" || formData?.time === undefined || isQuillEmpty(question) || formData?.language_id === "" || formData?.question_title === "" || formData?.question_cat_id === "") {
                    setErrorMsg({
                        ...errorMsg,
                        time: formData?.time ? "" : "Time is required",
                        question: isQuillEmpty(question) ? "Question is required" : "",
                        language_id: formData?.language_id ? "" : "Language is required",
                        question_title: formData?.question_title ? "" : "Question Title is required",
                        question_cat_id: formData?.question_cat_id ? "" : "Question category is required",
                    });
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }                 
                APIurl = createCustomFileQuestion;
            } else if (extraQuestionBox === 5) {
                question_type = "Code";

                if (
                    formData?.time === "" || formData?.time === undefined ||
                    isQuillEmpty(question) ||
                    formData?.language_id === "" ||
                    // formData?.back_temp === "" || formData?.front_temp === "" ||
                    formData?.question_title === "" ||
                    formData?.question_cat_id === ""
                ) {
                    setErrorMsg({
                        ...errorMsg,
                        time: formData?.time ? "" : "Time is required",
                        question: isQuillEmpty(question) ? "Question is required" : "",
                        language_id: formData?.language_id ? "" : "Language is required",
                        // front_temp: formData?.front_temp ? "" : "Front Code is required",
                        // back_temp: formData?.back_temp ? "" : "Back Code is required",
                        question_title: formData?.question_title ? "" : "Question Title is required",
                        question_cat_id: formData?.question_cat_id ? "" : "Question category is required",
                    });
                    setPreviewSaveLoader(false);
                    setPopupSubmitLoader(false);
                    return false;
                }
                let errorFlag;
                const checkedCount = testCaseOP.reduce((count, testCase) => {
                    return count + (testCase.checked ? 1 : 0);
                }, 0);

                if (checkedCount < 2) {
                    setTwoTestCaseSelect(true);
                    setPopupSubmitLoader(false);
                    return false;
                }
                setTwoTestCaseSelect(false);
                for (let index = 0; index < testCaseOP.length; index++) {
                    const data = testCaseOP[index];
                    let updatedErrorMsg = errorTestCaseMsg;
                    if (data.t.trim() === "" || data.o.trim() === "") {
                        updatedErrorMsg = {
                            t: data.t === "" ? true : updatedErrorMsg.t,
                            o: data.o === "" ? true : updatedErrorMsg.o,
                        };
                        setErrorTestCaseMsg(updatedErrorMsg);
                        setPopupSubmitLoader(false);
                        errorFlag = true;
                        break;
                    } else {
                        updatedErrorMsg = {
                            t: false,
                            o: false,
                        };
                        setErrorTestCaseMsg(updatedErrorMsg);
                    }
                }

                if (errorFlag) {
                    return false;
                }
                const show_output = [];
                let finalData = [];
                testCaseOP.forEach((data, index) => {
                    if (data?.checked === true) {
                        show_output.push(index);
                    }
                    const alData = {
                        ["test" + index]: data.t,
                        ["output" + index]: data.o,
                    };
                    finalData[index] = alData;
                });
                formData["test_cases"] = JSON.stringify(finalData);
                formData["no_of_test_cases"] = finalData.length;
                formData["show_test_cases"] = show_output.toString();
                APIurl = createCustomCodeQuestion;
            }
            setOptions(["", "", "", ""]);
            setAnswer("");
            setAnswerData({});
            const headers = getHeaders();
            await axios
                .post(APIurl, formData, { headers })
                .then((response) => response)
                .then((response) => {
                    const customdata = response.data;
                    if (customdata.status === 200) {
                        setPopupSubmitLoader(false);
                        setNotificationOpen(true);
                        // setSussesMsg(customdata.data.message);

                        updateSelectedQuestion(
                            customdata.data.custom_question_id,
                            question_type,
                            customdata.data.custom_question_id,
                            formData.time,
                            formData.question,
                            customdata.data.custom_question_type_id,
                            formData.pro_lang_id,
                            formData.show_test_cases
                        );

                        if (preview) {
                            switch (extraQuestionBox) {
                                case 1:
                                    window.open(`/customer/preview-video-questions/${customdata.data.custom_question_id}`, "_blank");
                                    break;
                                case 2:
                                    window.open(`/customer/preview-essay-questions/${customdata.data.custom_question_id}`, "_blank");
                                    break;
                                case 3:
                                    window.open(`/customer/preview-mcq-questions/${customdata.data.custom_question_id}`, "_blank");
                                    break;
                                case 4:
                                    window.open(`/customer/preview-file-questions/${customdata.data.custom_question_id}`, "_blank");
                                    break;
                                case 5:
                                    window.open(`/customer/preview-code-questions/${customdata.data.custom_question_id}`, "_blank");
                                    break;
                                default:
                                    // Handle default case if necessary
                                    break;
                            }
                        }

                        setPreviewSaveLoader(false);
                        handlePopupClose();
                        formDataReset();
                    }
                });
        } catch (error) {
            console.error("Error fetching data:", error);
            setPopupSubmitLoader(false);
        }
    };
    const handleLanguageSelect = (name, prog) => {
        // if (language) {
        // setSelectedLanguage(language.name);
        setFormData({
            ...formData,
            [name]: prog,
            // pro_lang_id: prog, // Update the pro_lang field in formData
        });
        setStep2Popup5(true);
        // }
    };

    const handleTestOtherChange = (e, index) => {
        const updatedTestCases = [...testOther];
        updatedTestCases[index] = e.target.value;
        setTestOther(updatedTestCases);
    };

    const handleTestOutChange = (e, index) => {
        const errmode = [...errorMsgCode];
        errmode[index] = "";
        setErrorMsgCode(errmode);
        const updatedTestCases = [...testOut];
        updatedTestCases[index] = e.target.value;
        setTestOut(updatedTestCases);
    };


    const handleTestCaseChange = (e, index, field) => {
        const updatedTestCases = [...testCaseOP];
        if (field === "checkbox") {
            updatedTestCases[index].checked = e?.target?.checked;
        } else {
            updatedTestCases[index][field] = e?.target?.value;
            const updatedErrorMsg = {
                ...errorTestCaseMsg,
                [field]: e.target.value ? false : true,
            };
            setErrorTestCaseMsg(updatedErrorMsg);
        }
        setTestCaseOP(updatedTestCases);

    };

    const addTestCase = () => {
        // setTestCases([...testCases, ""]);
        setTestCaseOP([
            ...testCaseOP,
            {
                t: "",
                o: "",
                checked: false,
            },
        ]);
    };

    const deleteTestCase = (index) => {
        const updatedTestCases = [...testCaseOP];
        updatedTestCases.splice(index, 1); // Remove the test case at the specified index
        setTestCaseOP(updatedTestCases);
    };

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const handelResetFilters = () => {
        let blankSearch = {
            target: {
                value: "",
            },
        };
        searchCustomHandleFun(blankSearch);
        setQuestionType({});
    };

    return popupOpen === true ? (
        <PopupModel
            popupSubmitLoader={popupSubmitLoader}
            onClose={handlePopupClose}
            extraQuestionBox={extraQuestionBox}
            formData={formData}
            handelAnswer={handelAnswer}
            handleEditorChange={handleEditorChange}
            handelChangeTextBox={handelChangeTextBox}
            handleChangeTimeBox={handleChangeTimeBox}
            handleAddOption={handleAddOption}
            handleDeleteOption={handleDeleteOption}
            handleOptionChange={handleOptionChange}
            handleChange={handleChange}
            options={options}
            checked={checked}
            handelSubmit={handelSubmit}
            step2Popup5={step2Popup5}
            selectedLanguage={selectedLanguage}
            handleLanguageSelect={handleLanguageSelect}
            errorMsg={errorMsg}
            optionsValidation={optionsValidation}
            // handleCodeChange={handleCodeChange}
            handleTestCaseChange={handleTestCaseChange}
            handleTestOtherChange={handleTestOtherChange}
            handleTestOutChange={handleTestOutChange}
            testCases={testCases}
            testOut={testOut}
            testOther={testOther}
            deleteTestCase={deleteTestCase}
            addTestCase={addTestCase}
            errorMsgCode={errorMsgCode}
            errorMsgTest={errorMsgTest}
            testCaseErrMsg={testCaseErrMsg}
            frontTemp={frontTemp}
            backTemp={backTemp}
            testCaseOP={testCaseOP}
            errorTestCaseMsg={errorTestCaseMsg}
            twoTestCaseSelect={twoTestCaseSelect}
            answer={answer}
            previewSaveLoader={previewSaveLoader}
            indexsWithMatchingValues={indexsWithMatchingValues}
        />
    ) : (
        <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h5" sx={{ my: 5, fontWeight: 700, fontSize: "22px" }}>
                    Create a customized question from scratch... {sussesMsg && <span style={{ color: "var(--button-bg-color-green)" }}>{sussesMsg}</span>}
                </Typography>
                <Grid container spacing={4} columns={{ xs: 4, sm: 10, md: 10 }}>
                    <Grid xs={12} md={2}>
                        <Item
                            sx={{
                                bgcolor: "#c8d6d9",

                                borderRadius: "5px",
                                p: 2,
                                textAlign: "center",
                                transition: "0.3s",
                                cursor: "pointer",
                                boxShadow: "1px 1px 7px 0px #00000030",
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    transform: "translateY(-8px)",

                                    "& .MuiTypography-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onClick={(e) => handlePopupOpen(e, 1)}
                        >
                            <VideocamOutlinedIcon sx={{ fontSize: "50px", color: "var(--button-bg-color-green)" }} />
                            <Typography variant="h5" style={{ fontSize: "22px", fontWeight: 600 }}>
                                Video
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Item
                            sx={{
                                bgcolor: "#c8d6d9",

                                borderRadius: "5px",
                                p: 2,
                                textAlign: "center",
                                transition: "0.3s",
                                cursor: "pointer",
                                boxShadow: "1px 1px 7px 0px #00000030",
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    transform: "translateY(-8px)",

                                    "& .MuiTypography-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onClick={(e) => handlePopupOpen(e, 2)}
                        >
                            <GradingOutlinedIcon sx={{ fontSize: "50px", color: "var(--button-bg-color-green)" }} />
                            <Typography variant="h5" style={{ fontSize: "22px", fontWeight: 600 }}>
                                Essay
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Item
                            sx={{
                                bgcolor: "#c8d6d9",

                                borderRadius: "5px",
                                p: 2,
                                textAlign: "center",
                                transition: "0.3s",
                                cursor: "pointer",
                                boxShadow: "1px 1px 7px 0px #00000030",
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    transform: "translateY(-8px)",

                                    "& .MuiTypography-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onClick={(e) => handlePopupOpen(e, 3)}
                        >
                            <LibraryAddCheckOutlinedIcon sx={{ fontSize: "50px", color: "var(--button-bg-color-green)" }} />
                            <Typography variant="h5" style={{ fontSize: "22px", fontWeight: 600 }}>
                                Multiple-Choice
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Item
                            sx={{
                                bgcolor: "#c8d6d9",

                                borderRadius: "5px",
                                p: 2,
                                textAlign: "center",
                                transition: "0.3s",
                                cursor: "pointer",
                                boxShadow: "1px 1px 7px 0px #00000030",
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    transform: "translateY(-8px)",
                                    "& .MuiTypography-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onClick={(e) => handlePopupOpen(e, 4)}
                        >
                            <UploadFileOutlinedIcon sx={{ fontSize: "50px", color: "var(--button-bg-color-green)" }} />
                            <Typography variant="h5" style={{ fontSize: "22px", fontWeight: 600 }}>
                                File Upload
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <Item
                            sx={{
                                bgcolor: "#c8d6d9",

                                borderRadius: "5px",
                                p: 2,
                                textAlign: "center",
                                transition: "0.3s",
                                cursor: "pointer",
                                boxShadow: "1px 1px 7px 0px #00000030",
                                "&:hover": {
                                    bgcolor: "var(--button-bg-color-green)",
                                    boxShadow: "0 17px 25px rgb(0 0 0 / 23%)",
                                    transform: "translateY(-8px)",

                                    "& .MuiTypography-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: "var(--text-color-OffWhite)",
                                    },
                                },
                            }}
                            onClick={(e) => handlePopupOpen(e, 5)}
                        >
                            <DataObjectIcon sx={{ fontSize: "50px", color: "var(--button-bg-color-green)" }} />
                            <Typography variant="h5" style={{ fontSize: "22px", fontWeight: 600 }}>
                                Code
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ mt: 5, borderWidth: "1px", borderColor: "gray" }} />

            <Typography variant="h5" sx={{ my: 5, fontWeight: 700, fontSize: "20px" }}>
                ...Or use a custom question from our library
            </Typography>

            <Box sx={{ flexGrow: 1, marginBottom: "150px" }}>
                <Grid container spacing={0}>
                    <Grid xs={12} md={3}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                overflow: "auto",
                                maxHeight: "100vh",
                                marginRight: ScrollMargin,
                                position: "sticky",
                                top: "100px",
                                paddingTop: "16px",
                                paddingBottom: "16px",
                            }}
                        >
                            <Box sx={{ mb: 5 }}>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <TextField
                                        id="search"
                                        label="Search"
                                        variant="outlined"
                                        value={searchedData}
                                        sx={{
                                            width: MobileWidth,
                                            bgcolor: "white",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <SearchIcon
                                                    style={{
                                                        color: "rgba(0, 0, 0, 0.54)",
                                                    }}
                                                />
                                            ),
                                        }}
                                        onChange={(e) => searchCustomHandleFun(e)}
                                    />
                                </ThemeProvider>
                            </Box>
                            <Typography variant="h6" fontWeight={700} sx={{ fontSize: "16px", my: 3 }}>
                                Filters
                            </Typography>

                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                    backgroundColor: "none",
                                    padding: "0px",
                                }}
                                defaultExpanded
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h6" fontWeight={700} sx={{ fontSize: "16px" }}>
                                        Questions type
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                        {" "}
                                        <Checkbox {...label} name="video" onClick={(e) => handelQuestionType(e, "video")} checked={questionType?.video ? true : false} />
                                        Video  ({customQuestionTypeCount.Video})
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                        {" "}
                                        <Checkbox {...label} name="essay" onClick={(e) => handelQuestionType(e, "essay")} checked={questionType?.essay ? true : false} />
                                        Essay  ({customQuestionTypeCount.Essay})
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                        {" "}
                                        <Checkbox {...label} name="mcq" onClick={(e) => handelQuestionType(e, "mcq")} checked={questionType?.mcq ? true : false} />
                                        Multiple-Choise  ({customQuestionTypeCount.Mcq})
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                        {" "}
                                        <Checkbox {...label} name="fileupload" onClick={(e) => handelQuestionType(e, "file upload")} checked={questionType?.["file upload"] ? true : false} />
                                        File upload  ({customQuestionTypeCount.File_Upload})
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                        {" "}
                                        <Checkbox {...label} name="code" onClick={(e) => handelQuestionType(e, "code")} checked={questionType?.code ? true : false} />
                                        Code  ({customQuestionTypeCount.Code})
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Item>
                    </Grid>

                    <Grid xs={12} md={9} sx={{ marginTop: ContentMargin }}>
                        <Typography variant="body1" sx={{ mb: 2, ml: !isMobile ? "32px" : "8px", fontWeight: 700, fontSize: "16px" }}>
                            Results ({customQuestionCount})
                        </Typography>
                        {CustomqLoader ? (
                            <Grid sx={{ bgcolor: "#e9f0f1", pb: 5 }}>
                                {[1, 2, 3, 4, 5, 6].map((key) => (
                                    <Item key={key} sx={{ mt: 3, background: "none", boxShadow: "none" }}>
                                        <StepThreeSkeleton key={key} />
                                    </Item>
                                ))}
                            </Grid>
                        ) : (
                            <Grid sx={{ pb: 5 }}>
                                {customQuestionList.length > 0 ? (
                                    <>
                                        {customQuestionList?.map((option, index) => (
                                            <Item key={index} sx={{ mt: 3, background: "none", boxShadow: "none" }}>
                                                <Grid sx={{ p: 2, bgcolor: "white", mx: !isMobile ? "24px" : "0px", borderRadius: "8px", border: "1px solid #E0E0E0" }}>



                                                    <Typography variant="body1" fontWeight={600} fontSize={"16px"} sx={{ p: 1, textAlign: "justify", wordWrap: "break-word" }}>
                                                        {option.title}
                                                    </Typography>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={{ display: "flex", alignItems: "center", borderRight: "1px solid rgba(0,0,0,.2)", width: "100px", justifyContent: "center" }}>
                                                            {option.question_type === "Mcq" ? <LibraryAddCheckOutlinedIcon sx={{ fontSize: "18px" }} /> :
                                                                option.question_type === "File Upload" ? <UploadFileOutlinedIcon sx={{ fontSize: "18px" }} /> :
                                                                    option.question_type === "Essay" ? <GradingOutlinedIcon sx={{ fontSize: "18px" }} /> :
                                                                        option.question_type === "Code" ? <DataObjectIcon sx={{ fontSize: "18px" }} /> :
                                                                            <VideocamOutlinedIcon sx={{ fontSize: "18px" }} />
                                                            }
                                                            <Typography
                                                                sx={{
                                                                    p: 1,
                                                                    fontSize: "12px",
                                                                }}
                                                                variant="body1"
                                                            >
                                                                {option.question_type}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid sx={{ display: "flex", alignItems: "center", borderRight: "1px solid rgba(0,0,0,.2)", width: "100px", justifyContent: "center" }}>
                                                            <AccessAlarmIcon sx={{ fontSize: "18px", }} />

                                                            <Typography
                                                                sx={{
                                                                    p: 1,
                                                                    fontSize: "12px",
                                                                }}
                                                                variant="body1"
                                                            >
                                                                {option.time} min
                                                            </Typography>
                                                        </Grid>
                                                        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                            <Typography sx={{ p: 1, fontSize: "12px" }} variant="body1">
                                                                {option.user}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                                    <Divider
                                                        sx={{
                                                            my: 2,
                                                            borderWidth: "1px",
                                                            borderColor: "rgba(0,0,0,.2)",
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <Typography sx={{ mt: 3, fontSize: "16px", p: 1, wordWrap: "break-word" }} variant="body1">
                                                        {<div dangerouslySetInnerHTML={{ __html: option.question }} />}
                                                    </Typography>

                                                    <Box sx={{ flexGrow: 1, mt: 1 }}>

                                                        {showMoreIndex === index && (
                                                            <Fade timeout={{ enter: 1000 }} in={showMoreIndex === index}>
                                                                <Grid container spacing={0} sx={{ backgroundColor: "#f8f8f8", }}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <Item
                                                                            style={{
                                                                                boxShadow: "unset",
                                                                                borderRadius: "none",
                                                                                background: "none",
                                                                                padding: "16px",
                                                                            }}
                                                                            spacing={2}
                                                                        >
                                                                            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>Why is this question relevant?</Typography>
                                                                            <Typography sx={{ fontSize: "16px", mt: 2, textAlign: "justify", wordWrap: "break-word" }}>{option.why_is_the_ques_rel}</Typography>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <Item
                                                                            style={{
                                                                                boxShadow: "unset",
                                                                                borderRadius: "none",
                                                                                background: "none",
                                                                                padding: "16px",
                                                                            }}
                                                                        >
                                                                            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>What to look for in the answer?</Typography>
                                                                            <Typography sx={{ fontSize: "16px", mt: 2, textAlign: "justify", wordWrap: "break-word" }}>{option.what_to_look_for_ans}</Typography>
                                                                        </Item>
                                                                    </Grid>
                                                                </Grid>
                                                            </Fade>
                                                        )}
                                                        <Grid container spacing={0}>
                                                            <Grid xs={6}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        color="inherit"
                                                                        sx={{
                                                                            color: "var(--button-bg-color-green)",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            "&:hover": {
                                                                                textDecoration: "underline",
                                                                                background: "none"
                                                                            }
                                                                        }}
                                                                        onClick={() => toggleShowMore(index)}
                                                                    >
                                                                        {showMoreIndex === index ? (
                                                                            <>
                                                                                Hide
                                                                                <ChevronRightIcon />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Show more
                                                                                <ChevronRightIcon />
                                                                            </>
                                                                        )}
                                                                    </Button>
                                                                </Item>
                                                            </Grid>
                                                            <Grid xs={6}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",
                                                                        justifyContent: "end",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    {!selectedQuestion?.find((item) => item.question_id === option.id && item.question_type_id === option.question_type_id) ? (
                                                                        <div>
                                                                            <Button
                                                                                color="inherit"
                                                                                style={{
                                                                                    color: "var(--text-color-OffWhite)",
                                                                                    backgroundColor: "var(--button-bg-color-green)",
                                                                                    padding: "8px 30px",
                                                                                    border: "none",
                                                                                    borderRadius: "4px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (option.question_type_id === 4) {
                                                                                        openCodeModel(option);
                                                                                    } else {
                                                                                        updateSelectedQuestion(
                                                                                            index + 1,
                                                                                            option.question_type,
                                                                                            option.id,
                                                                                            option.time,
                                                                                            option.title,
                                                                                            option.question_type_id,
                                                                                            null,
                                                                                            null
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        </div>
                                                                    ) : (
                                                                        <Button
                                                                            style={{
                                                                                border: "none",
                                                                                color: "var(--text-color-OffWhite)",
                                                                                background: "#cb7b7a"
                                                                            }}
                                                                            variant="outlined"
                                                                            onClick={() => removeItem(index + 1, option.question_type, option.id, option.time, option.title, option.question_type_id)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </Button>
                                                                    )}
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Item>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <Box sx={{ bgcolor: "none" }}>
                                            <Grid container spacing={2}>
                                                <Grid xs={12}>
                                                    <Item
                                                        sx={{ display: "flex", justifyContent: "center", alignItmes: "center", flexDirection: "column", p: 3, bgcolor: "#e9f0f1", boxShadow: "none" }}
                                                    >
                                                        <SentimentVeryDissatisfiedIcon sx={{ color: "#7d7d7d", fontSize: "50px", margin: "auto" }} />
                                                        <Typography variant="h5" sx={{ fontWeight: 700, fontSize: "22px", textAlign: "center", mt: 2 }}>
                                                            Unfortunately, there are no questions matching your criteria
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ mt: 4, fontWeight: 500, fontSize: "16px", textAlign: "center", color: "#7c7c7c" }}>
                                                            To get better results, you might want to consider re-adjusting <br /> or even reseting all the filters
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{ mt: 3, fontWeight: 600, fontSize: "16px", color: "var(--button-bg-color-green)", textAlign: "center", cursor: "pointer" }}
                                                            onClick={handelResetFilters}
                                                        >
                                                            Reset all filters
                                                        </Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={handleNotificationClose}
                    severity="success"
                    sx={{
                        width: "500px",
                        justifyContent: "center",
                        backgroundColor: "#cbd7cb",
                        color: "var(--text-color-black)",
                    }}
                >
                    Question is created successfully!
                </Alert>
            </Snackbar>
            {isCodeModalOpen && (
                <LangSelectCodeModal
                    fromCall={true}
                    setToactiveSwitchButton={setToactiveSwitchButton}
                    closeCodeModal={closeCodeModal}
                    allProgrammingLang={allProgrammingLang}
                    handelProgrammingCheckbox={handelProgrammingCheckbox}
                    programmingSelected={programmingSelected}
                    handelSubmit={handelSubmitCodeModal}
                    modalLoader={modalLoader}
                    selectAll={selectAll}
                    errormsg={languageError}
                />
            )}
            {open && <ConfirmModal open={open} setOpen={setOpen} handleConfirm={handleConfirm} data={optionForConfirm} setData={setOptionForConfirm} from={"Custom"} />}
        </Container>
    );
}

export default AddCustomQuestionsSelect;
