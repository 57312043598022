import { Box, Button, Checkbox, Container, MenuItem, TextField, Typography, Modal, IconButton, Paper, Select, Stack, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import VideoImage from "../../../../../Assests/Images/PopUpImg/video_call_interface.png";
import FileUploadImage from "../../../../../Assests/Images/PopUpImg/Fileuploadpopupimg.png";
import Grid from "@mui/material/Unstable_Grid2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpIcon from "@mui/icons-material/Help";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getProgrammingLanguages, getHeaders, TestTypeCategory } from "../../../../../Constants/ConstantsUrl";
import Loader from "../../../../Common/Loader";
import LangSelectCodeModal from "../LangSelectCodeModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#074234",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "none",
}));

const btnStyle = {
    color: "var(--text-color-black)",
    boxShadow: "unset",
    borderRadius: "4px",

    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
};
const EditPopupModel = (props) => {
    const {
        popupSubmitLoader,
        onClose,
        handleOptionChange,
        extraQuestionBox,
        handelAnswer,
        formData,
        handleEditorChange,
        handelChangeTextBox,
        handleAddOption,
        handleDeleteOption,
        options,
        handelSubmit,
        step2Popup5,
        handleLanguageSelect,
        errorMsg,
        handleTestCaseChange,
        deleteTestCase,
        addTestCase,
        errorMsgCode,
        errorMsgTest,
        testCaseOP,
        errorTestCaseMsg,
        twoTestCaseSelect,
        answer,
    } = props;
    const isMobile = useMediaQuery("(max-width:960px)");
    const FormWidth = isMobile ? "100%" : "50%";
    const BtnPosition = isMobile ? "center" : "end";
    const ArrowPosition = isMobile ? "absolute" : "";
    const ArrowTop = isMobile ? "0px" : "";
    const ArrowLeft = isMobile ? "0px" : "";
    const TextMarginLeft = isMobile ? "117px" : "";
    const TeamPadding = isMobile ? "" : "10px";
    const [popupLoader, setPopupLoader] = useState(false);
    const selectedOption = useState("");
    const [testTypeCategory, setTestTypeCategory] = useState([]);
    const [isCodeModalOpen, setCodeModelOpen] = useState(false);
    const [programmingSelected, setProgrammingSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedoption, setSelectedOption] = useState(0);
    const [modalLoader, setModalLoader] = useState(true);
    const [allProgrammingLang, setAllProgrammingLang] = useState([""]);
    const [languageError, setLanguageError] = useState(false);

    const handleBack = () => {
        onClose(); // Close the modal or navigate back
    };

    useEffect(() => {
        fetchTestTypeCategory();
        if (extraQuestionBox === 5) {
            fetchDataL();
        }
    }, [extraQuestionBox]);

    const fetchTestTypeCategory = async () => {
        setPopupLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(TestTypeCategory, { headers });
            const res = response.data;
            if (res.status) {
                setTestTypeCategory(res.data);
                setPopupLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setPopupLoader(false);
        }
    };

    const closeCodeModal = () => {
        setCodeModelOpen(false);
        setProgrammingSelected([]);
        setSelectAll(false);
        onClose();
    };

    const fetchDataL = async () => {
        setModalLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(getProgrammingLanguages, {
                headers,
            });
            const res = response.data;
            if (res.status) {
                setAllProgrammingLang(res.data);
                setModalLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setModalLoader(false);
        }
    };

    const handelProgrammingCheckbox = (e, id) => {
        if (id === 0) {
            setSelectAll(!selectAll);
            setProgrammingSelected((prevSelected) => {
                if (!selectAll) {
                    // If selectAll true, include all programming language IDs (except 0)
                    const allLangIds = allProgrammingLang.map((lang) => lang.id).filter((langId) => langId !== 0);
                    return allLangIds;
                } else {
                    // If selectAll false, clear
                    return [];
                }
            });
        } else {
            setProgrammingSelected((prevSelected) => {
                if (prevSelected.includes(id)) {
                    // If selected, remove
                    return prevSelected.filter((selectedId) => selectedId !== id);
                } else {
                    // If not add
                    return [...prevSelected, id];
                }
            });
            const allLanguagesSelected = programmingSelected.length + 1 === allProgrammingLang.length;
            setSelectAll(allLanguagesSelected);
        }
    };
    const handelSubmitCodeModal = async () => {
        if (programmingSelected.length === 0) {
            setLanguageError(true);
            return false;
        }
        setLanguageError(false);
        let prog = programmingSelected.join(",");
        setSelectedOption(programmingSelected.length);
        handleLanguageSelect("pro_lang_id", prog);
        setCodeModelOpen(false);
        setProgrammingSelected([]);
        setSelectAll(false);
    };

    return (
        <Modal open={true} onClose={onClose}>
            <Grid
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",

                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                }}
            >
                <Grid
                    container
                    sx={{
                        backgroundColor: "#074234",
                    }}
                >

                    <Grid sx={{ ml: !isMobile ? "auto" : "48px" }} xs={12} md={12}>
                        <Item
                            sx={{
                                mx: "50px",
                                mt: "10px",
                                mb: "10px",
                            }}
                        >
                            <Stack spacing={2} direction="row">
                                <Button
                                    style={{
                                        background: "#FFF0E1",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={handleBack}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>
                                <Button
                                    style={{
                                        background: "#0a5c48",
                                        color: "var(--text-color-black)",
                                        boxShadow: "unset",
                                        borderRadius: "4px",
                                        padding: "15px 30px",
                                    }}
                                    sx={{ textTransform: "none" }}
                                //onClick={handelSubmit}
                                >
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: "var(  --btn-fontsize)",
                                            color: "var(--text-color-OffWhite)",
                                        }}
                                    >
                                        Save
                                    </Typography>
                                </Button>
                            </Stack>
                        </Item>
                    </Grid>
                </Grid>
                {extraQuestionBox === 5 && step2Popup5 === false ? (
                    <LangSelectCodeModal
                        closeCodeModal={closeCodeModal}
                        allProgrammingLang={allProgrammingLang}
                        handelProgrammingCheckbox={handelProgrammingCheckbox}
                        programmingSelected={programmingSelected}
                        handelSubmit={handelSubmitCodeModal}
                        modalLoader={modalLoader}
                        selectAll={selectAll}
                        errormsg={languageError}
                    />
                ) : (
                    <>
                        {popupSubmitLoader || popupLoader ? (
                            <Loader />
                        ) : (
                            <Grid>
                                <Grid sx={{ p: isMobile ? "0px" : "24px" }}>
                                    <Container maxWidth="xxl" sx={{ p: 0 }}>
                                        <Grid sx={{ mt: 2 }} container>
                                            <Grid sx={{ px: 2 }} xs={12} md={6}>
                                                <Item
                                                    style={{
                                                        boxShadow: "unset",
                                                        borderRadius: "none",
                                                        background: "none",
                                                        padding: "0px",
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: "700" }}>
                                                        Question
                                                    </Typography>
                                                    <Grid>
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                mt: "15px",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            {extraQuestionBox && extraQuestionBox !== 6 && (
                                                                <TextField
                                                                    name="time"
                                                                    value={formData?.time}
                                                                    fullWidth
                                                                    label="Enter time in min"
                                                                    type="number"
                                                                    id="time"
                                                                    variant="outlined"
                                                                    error={errorMsg?.time ? true : false}
                                                                    helperText={errorMsg?.time ? (errorMsg?.time === true ? "time is required" : errorMsg?.time) : null}
                                                                    onChange={handelChangeTextBox}
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                            borderRadius: "4px",
                                                                            transition: "green 0.3s ease-in-out",
                                                                            "&:hover": {
                                                                                // borderColor: '#0000001f',
                                                                            },
                                                                        },
                                                                        "& #time-label": {
                                                                            color: "var(--text-color-black)",
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>

                                                        <Box
                                                            sx={{
                                                                bgcolor: "#E4EDED",
                                                                mt: "15px",
                                                                borderRadius: "5px",
                                                                px: "25px",
                                                                display: "flex",
                                                                py: "10px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <InfoOutlinedIcon sx={{ mr: "15px" }} />
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                The language of your assessment is English. Make sure to phrase the question using the same language.
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                width: "100%",
                                                                mt: 3,
                                                                border: "1px solid #aaa",
                                                                borderRadius: "5px",
                                                                position: "relative",
                                                            }}
                                                        >
                                                            <ReactQuill
                                                                name="question"
                                                                label=" For example: Kindly introduce yourself."
                                                                theme="snow"
                                                                value={formData.question_data.question.trimStart()}
                                                                onChange={(value) => handleEditorChange("question", value)}
                                                            />
                                                            {errorMsg?.question && <span className="error_msg">{errorMsg?.question}</span>}
                                                        </Box>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            {extraQuestionBox && extraQuestionBox === 1 && (
                                                <Grid sx={{ px: 1 }} xs={12} md={6}>
                                                    <Grid
                                                        sx={{}}
                                                        style={{
                                                            boxShadow: "unset",
                                                            borderRadius: "none",
                                                            background: "none",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={VideoImage}
                                                            style={{
                                                                maxWidth: "100%",
                                                                padding: "30px",
                                                            }}
                                                            alt="../../../../Assests/Images/video_call_interface.png"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {extraQuestionBox && extraQuestionBox === 2 && (
                                                <Grid sx={{ px: 1 }} xs={12} md={6}>
                                                    <Grid
                                                        sx={{}}
                                                        style={{
                                                            boxShadow: "unset",
                                                            borderRadius: "none",
                                                            background: "none",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={VideoImage}
                                                            style={{
                                                                maxWidth: "100%",
                                                                padding: "30px",
                                                            }}
                                                            alt="../../../../Assests/Images/video_call_interface.png"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {extraQuestionBox && (extraQuestionBox === 3 || extraQuestionBox === 6) && (
                                                <Grid sx={{ px: 2 }} xs={12} md={6}>
                                                    <Box
                                                        component="div"
                                                        style={{
                                                            boxShadow: "unset",
                                                            borderRadius: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginBottom: "16px",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Grid>
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "18px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                >
                                                                    {errorMsg?.optionNotChoose && (
                                                                        <span
                                                                            className="error_msg"
                                                                            style={{
                                                                                color: "red",
                                                                                padding: 4,
                                                                            }}
                                                                        >
                                                                            {errorMsg?.optionNotChoose}
                                                                        </span>
                                                                    )}
                                                                    Select the right answer
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Box
                                                            sx={{
                                                                overflowY: "scroll",
                                                                mt: 5,
                                                            }}
                                                        >
                                                            {options.map((optionData, index) => (
                                                                <>
                                                                    <Grid container spacing={0} key={index} alignItems="center">
                                                                        <Grid
                                                                            sx={{
                                                                                display: "flex",
                                                                                // mb: 10,
                                                                            }}
                                                                            xs={12}
                                                                            md={12}
                                                                        >
                                                                            <Checkbox
                                                                                checked={(answer.includes(index.toString())) ? true : false}
                                                                                className="BtnBackground"
                                                                                icon={<RadioButtonUncheckedIcon />}
                                                                                checkedIcon={<RadioButtonCheckedIcon />}
                                                                                onClick={(e) => handelAnswer(e, index, true)}
                                                                            />
                                                                            <ReactQuill
                                                                                style={{
                                                                                    // paddingTop:"20px",
                                                                                    // minHeight: "100px",
                                                                                    // maxHeight: "100px",
                                                                                    width: "100%",
                                                                                    // resize: "vertical",
                                                                                    // overflow: "hidden",
                                                                                }}
                                                                                modules={{
                                                                                    toolbar: [
                                                                                        [{ header: "1" }, { header: "2" }, { font: [] }],
                                                                                        [{ size: [] }],
                                                                                        ["bold", "italic", "underline", "strike", "blockquote"],
                                                                                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                                                                                        ["link", "image", "video"],
                                                                                        ["clean"],
                                                                                    ],
                                                                                }}
                                                                                className="custom-quill-editor"
                                                                                name="question"
                                                                                label=" For example: Kindly introduce yourself."
                                                                                theme="snow"
                                                                                value={optionData['options']}
                                                                                onChange={(content) => handleOptionChange(index, content)}
                                                                            />
                                                                            <IconButton className="BtnBackground" onClick={() => handleDeleteOption(index)}>
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Divider />
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Box>

                                                        <Grid container spacing={0}>
                                                            <Grid
                                                                xs={12}
                                                                md={5}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    m: "10px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{
                                                                        background: "#ededed",
                                                                        color: "var(--text-color-black)",
                                                                        boxShadow: "unset",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: formData.question_data.mcq_options.length >= 10 ? "#ccc" : "#ededed",
                                                                    }}
                                                                    onClick={handleAddOption}
                                                                    variant="contained"
                                                                    disabled={formData.question_data.mcq_options.length >= 10}
                                                                >
                                                                    <AddIcon />
                                                                    Add another answer
                                                                </Button>
                                                            </Grid>

                                                            <Grid
                                                                xs={12}
                                                                md={4}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: BtnPosition,
                                                                    m: "10px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "10px",
                                                                            color: "var(--button-bg-color-green)",
                                                                        }}
                                                                    />

                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "600",
                                                                            mx: 1,
                                                                        }}
                                                                    >
                                                                        Shuffle answers
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {extraQuestionBox && extraQuestionBox === 4 && (
                                                <Grid xs={12} md={6}>
                                                    <Grid
                                                        sx={{}}
                                                        style={{
                                                            boxShadow: "unset",
                                                            borderRadius: "none",
                                                            background: "none",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={FileUploadImage}
                                                            style={{
                                                                maxWidth: "100%",
                                                                padding: "30px",
                                                            }}
                                                            alt="../../../../Assests/Images/video_call_interface.png"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {extraQuestionBox && extraQuestionBox === 5 && (
                                                <Grid xs={12} md={6} style={{}}>
                                                    <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: "700", p: 2 }}>
                                                        TestCases
                                                    </Typography>
                                                    <Container maxWidth="xl" sx={{ p: 2 }}>
                                                        <Box
                                                            sx={{
                                                                overflowY: "scroll",
                                                                maxHeight: "500px",
                                                            }}
                                                        >
                                                            <Grid>
                                                                <Grid>
                                                                    <Typography fontWeight={700} fontSize={18}>
                                                                        Test cases to be used during the test
                                                                    </Typography>
                                                                    <Box
                                                                        sx={{
                                                                            bgcolor: "#E4EDED",
                                                                            mt: "15px",
                                                                            borderRadius: "5px",
                                                                            px: "25px",
                                                                            display: "flex",
                                                                            py: "10px",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <InfoOutlinedIcon sx={{ mr: "15px" }} />
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 500,
                                                                                fontSize: "16px",
                                                                            }}
                                                                        >
                                                                            During test, only <strong>selected test cases will be visible</strong> to the candidate while others remain hidden.
                                                                            <br />
                                                                            Please enter the test cases in accordance with all selected programming languages so they can be read from STDINPUT. Ensure
                                                                            to specify array size, string size, and all necessary inputs.{" "}
                                                                            <strong>Separate each element with a space and each argument with a new line.</strong> For example, inputting an array
                                                                            [2,5,7,8] should be formatted as follows:
                                                                            <br />
                                                                            4
                                                                            <br />2 5 7 8
                                                                        </Typography>
                                                                    </Box>
                                                                    {twoTestCaseSelect && (
                                                                        <Typography fontWeight={500} fontSize={16} color={"red"}>
                                                                            Please create and check at least two test cases To show For Candidate
                                                                        </Typography>
                                                                    )}
                                                                    {errorTestCaseMsg.t && (
                                                                        <Typography fontWeight={500} fontSize={16} color={"red"}>
                                                                            Please Fill all Input Fields
                                                                        </Typography>
                                                                    )}
                                                                    {errorTestCaseMsg.o && (
                                                                        <Typography fontWeight={500} fontSize={16} color={"red"}>
                                                                            Please Fill all Output Fields
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                                <Item
                                                                    sx={{
                                                                        backgroundColor: "var(--button-bg-color-transparent)",
                                                                    }}
                                                                >
                                                                    {testCaseOP.map((testCase, index) => (
                                                                        <>
                                                                            <Typography sx={{ mt: 5 }} fontWeight={500} fontSize={16} color={"var(--button-bg-color-green)"}>
                                                                                Test Case {index + 1}
                                                                            </Typography>
                                                                            <Grid sx={{ mt: 1 }} container spacing={2} alignItems="center" key={index}>
                                                                                <Grid xs={1}>
                                                                                    <Checkbox
                                                                                        className="BtnBackground"
                                                                                        icon={<RadioButtonUncheckedIcon />}
                                                                                        checkedIcon={<RadioButtonCheckedIcon />}
                                                                                        checked={testCase.checked || false}
                                                                                        onClick={(e) => handleTestCaseChange(e, index, "checkbox")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid xs={5}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label="TestCase Input"
                                                                                        name="test_cases"
                                                                                        multiline
                                                                                        // value={testCase}
                                                                                        value={testCase.t || ""}
                                                                                        onChange={(e) => handleTestCaseChange(e, index, "t")}
                                                                                        rows={3}
                                                                                    />
                                                                                    {errorMsgTest[index] && <span className="error_msg">{errorMsgTest[index]} </span>}
                                                                                </Grid>
                                                                                <Grid sx={{ display: "flex", justifyContent: "center" }} xs={1}>
                                                                                    <ArrowRightAltIcon />
                                                                                </Grid>
                                                                                <Grid xs={4}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label="Expected OutPut"
                                                                                        name="testOut"
                                                                                        multiline
                                                                                        value={testCase.o || ""}
                                                                                        // value={testOut[index]}
                                                                                        onChange={(e) => handleTestCaseChange(e, index, "o")}
                                                                                        rows={3}
                                                                                    />
                                                                                    {errorMsgCode[index] && <span className="error_msg">{errorMsgCode[index]} </span>}
                                                                                </Grid>
                                                                                <Grid xs={1}>
                                                                                    <IconButton onClick={() => deleteTestCase(index)}>
                                                                                        <DeleteForeverIcon color="red" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                                </Item>
                                                            </Grid>
                                                        </Box>
                                                    </Container>
                                                    <Grid>
                                                        <Stack
                                                            direction="Column"
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-end", // Aligns the buttons at the bottom
                                                                alignItems: "flex-end", // Aligns the buttons to the right
                                                                height: "100%",
                                                                py: "15px", // Ensures the box takes full height
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={addTestCase}
                                                                style={btnStyle}
                                                                sx={{
                                                                    textTransform: "none",
                                                                    background: "var(--button-bg-color-green)",
                                                                    border: "0",
                                                                    my: "10px",

                                                                    "&:hover": {
                                                                        background: "var(--button-hover-bg-color-LightGreen)",
                                                                        border: "none",
                                                                    },
                                                                }}
                                                                variant="outlined"
                                                            >
                                                                {" "}
                                                                <Typography
                                                                    fontWeight={600}
                                                                    sx={{
                                                                        fontSize: "var(  --btn-fontsize)",
                                                                        color: "var(--text-color-OffWhite)",
                                                                    }}
                                                                >
                                                                    Add new
                                                                </Typography>
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Container>
                                </Grid>
                                <Grid sx={{ bgcolor: "#f9f9f9", p: isMobile ? "0" : "40px" }}>
                                    <Container maxWidth="xxl">
                                        <Grid
                                            container
                                            sx={{
                                                p: 0,
                                            }}
                                        >
                                            <Grid xs={12} sx={{ p: "0px" }}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: "20px",
                                                    }}
                                                    variant="body1"
                                                >
                                                    Information for your team
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                    }}
                                                    variant="h6"
                                                >
                                                    This information will not be shown to candidates.
                                                </Typography>
                                            </Grid>
                                            <Grid container xs={12} sx={{ p: "0px", mt: 5 }}>
                                                <Grid xs={12} md={6} sx={{ p: TeamPadding }}>
                                                    <Box>
                                                        <Grid sx={{ display: "flex" }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize: "18px",
                                                                }}
                                                                variant="body2"
                                                            >
                                                                Question title
                                                            </Typography>
                                                            <HelpIcon sx={{ m: "3px" }} />
                                                        </Grid>
                                                        <TextField
                                                            sx={{ bgcolor: "white" }}
                                                            name="question_title"
                                                            value={formData?.question_title}
                                                            fullWidth
                                                            margin="normal"
                                                            onChange={handelChangeTextBox}
                                                            error={errorMsg?.question_title ? true : false}
                                                            helperText={errorMsg?.question_title ? (errorMsg?.question_title === true ? "question_title is required" : errorMsg?.question_title) : null}
                                                        />
                                                    </Box>
                                                    <Box sx={{ mt: "30px" }}>
                                                        <Grid sx={{ display: "flex" }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize: "18px",
                                                                }}
                                                                variant="body2"
                                                            >
                                                                What to look for in the answer?
                                                            </Typography>
                                                            <HelpIcon sx={{ m: "3px" }} />
                                                        </Grid>
                                                        <TextField
                                                            sx={{ bgcolor: "white" }}
                                                            value={formData?.what_to_look_for_ans}
                                                            name="what_to_look_for_ans"
                                                            fullWidth
                                                            margin="normal"
                                                            multiline
                                                            rows={8}
                                                            onChange={handelChangeTextBox}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} md={6} sx={{ p: TeamPadding }}>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                            }}
                                                            variant="body2"
                                                        >
                                                            Question category
                                                        </Typography>
                                                        <Select
                                                            value={formData.question_cat_id}
                                                            onChange={handelChangeTextBox}
                                                            displayEmpty
                                                            IconComponent={ArrowDropDownIcon}
                                                            inputProps={{
                                                                "aria-label": "Select option",
                                                            }}
                                                            name="question_cat_id"
                                                            sx={{
                                                                width: FormWidth,
                                                                bgcolor: "white",
                                                                borderRadius: "5px",
                                                                mt: "18px",
                                                                mb: "8px",
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                Choose
                                                            </MenuItem>
                                                            {testTypeCategory.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.title.replace(/&amp;/g, "&")}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                    <Box sx={{ mt: "30px", mb: 7 }}>
                                                        <Grid sx={{ display: "flex" }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize: "18px",
                                                                }}
                                                                variant="body2"
                                                            >
                                                                Why is this question relevant?
                                                            </Typography>
                                                            <HelpIcon sx={{ m: "3px" }} />
                                                        </Grid>
                                                        <TextField
                                                            sx={{ bgcolor: "white" }}
                                                            name="why_is_the_ques_rel"
                                                            fullWidth
                                                            margin="normal"
                                                            value={formData?.why_is_the_ques_rel}
                                                            multiline
                                                            rows={8}
                                                            onChange={handelChangeTextBox}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Modal>
    );
};
export default EditPopupModel;
