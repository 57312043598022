import * as React from "react";
import { Box, Button, Container, Grid, Typography, Checkbox, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{}}>
                <TableCell component="th" scope="row">
                    {row.question_title}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{row.result_sub_meta_details[0] && (row.result_sub_meta_details[0].result_sub_meta_result === 1 ? <CheckCircleOutlineIcon sx={{ color: "#2c8f38" }} /> : <HighlightOffOutlinedIcon sx={{ color: "#cb7b7a" }} />)}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 3 }}>
                            <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5, py: 2 }}>
                                <Grid xs={12} md={4}>
                                    <Grid sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
                                        <RadioGroup style={{ flexDirection: "column" }} row aria-label="radio-group-english" name="fluentEnglish">
                                            {row.result_sub_meta_details[0] && row.result_sub_meta_details[0].options.map((option, index) => (
                                                <Grid
                                                    key={index}
                                                    className=
                                                    {row.result_sub_meta_details[0].answers.includes(option.id.toString()) && row.result_sub_meta_details[0].result_sub_meta_answer.split(",").includes(option.id.toString()) ? "rightRadioBtnChecked" :
                                                        row.result_sub_meta_details[0].answers.includes(option.id.toString()) ?
                                                            "rightRadioBtnUnChecked" :
                                                            row.result_sub_meta_details[0].result_sub_meta_answer.split(",").includes(option.id.toString()) ? "wrongRadioBtnChecked" : "answerUnChecked"
                                                    }
                                                    sx={{
                                                        my: 1,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        sx={{ width: "100%", boxShadow: "0 2px 8px 1px #a8a8a833", '& .MuiFormControlLabel-label': { width: '100%' } }}
                                                        value={option.id}
                                                        control={
                                                            <Checkbox
                                                                inputProps={{ "aria-label": "controlled" }}
                                                                icon={<RadioButtonUncheckedIcon sx={{ fontSize: "35px", color: "#ededed" }} />}
                                                                checkedIcon={<RadioButtonCheckedIcon sx={{ fontSize: "35px", color: "white" }} />}
                                                                checked={row.result_sub_meta_details[0].result_sub_meta_answer.split(",").includes(option.id.toString()) ? true : false}
                                                                disabled
                                                            ></Checkbox>
                                                        }
                                                        // checked={tr}
                                                        label={<>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <span
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 500,
                                                                        display: "flex",

                                                                    }}
                                                                >
                                                                    <div dangerouslySetInnerHTML={{ __html: option.options }} />
                                                                </span>
                                                                <span>
                                                                    {row.result_sub_meta_details[0].answers.includes(option.id.toString()) && row.result_sub_meta_details[0].result_sub_meta_answer.split("").includes(option.id.toString()) ? <CheckCircleOutlineIcon /> :
                                                                        <>
                                                                            {row.result_sub_meta_details[0].answers.includes(option.id.toString()) &&
                                                                                <CheckCircleOutlineIcon />}

                                                                            {row.result_sub_meta_details[0].result_sub_meta_answer.split("").includes(option.id.toString()) && <HighlightOffOutlinedIcon />}
                                                                        </>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </>
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function QualifyingDetailsTable(props) {
    const { qualifyingQuestionList } = props;
    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    my: 3,
                }}
            >
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ fontSize: "14px" }} fontWeight={700}>
                        Qualifying Questions
                    </Typography>
                </Grid>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Question</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">Answer</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {qualifyingQuestionList.map((row) => (
                            <Row key={row.question_title} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default QualifyingDetailsTable;
