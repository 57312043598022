import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Grid, Typography, TextField, Select, MenuItem, Backdrop, Modal, Fade, AccordionSummary } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import videojs from 'video.js';
import VideoPlayer from './index.jsx';
import "./styles.css"
import useMediaQuery from "@mui/material/useMediaQuery";
function VideoPopup({ videoUrl, markersPoint, time, isVideoPopupOpen, handleVideoPopupClose }) {
  const isExtraSmMobile = useMediaQuery("(max-width:500px)");
  const isSmMobile = useMediaQuery("(max-width:610px)");
  const isMobile = useMediaQuery("(max-width:750px)");
  const isTablet = useMediaQuery("(max-width:1010px)");
  const isLaptop = useMediaQuery("(max-width:1250px)");
  const refPlayer = useRef(null);
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    playbackRates: [0.5, 1, 1.5, 2],
    responsive: true,
    enableSmoothSeeking: true,
    width: 1100,
    height: 530, 
    sources: [{
      src: videoUrl,
      type: 'video/mp4'
    }]
  };
  const handlePlayerReady = (player) => {
    refPlayer.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isVideoPopupOpen}
        onClose={handleVideoPopupClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
            timeout: 10000,
            },
        }}
        className="RemoveOutLine"
        sx={{ backdropFilter: "blur(4px)" }}
        >
        <Fade in={isVideoPopupOpen}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isExtraSmMobile?400: isSmMobile? 500: isMobile ? 600 :  isTablet? 700 : isLaptop ? 1000  : 1200,
                height:"100%",
                bgcolor: "#F2F2F2",
                border: "none",
        borderRadius: "8px",
        boxShadow: "0 6px 7px -4px #88888829,0 11px 15px 1px #8888881f,0 4px 20px 3px #88888823",
                p:isExtraSmMobile?"8px": 4, 
                maxHeight: "800px",
        overflowY: "scroll",
        minWidth: 350 
            }}>
                <Box sx={{textAlign:"end"}}>
                    <Button sx={{ "&:hover": { background: "unset" }}}>
                        <CloseIcon sx={{ color: "#2C2C2C", fontSize: "18px" }} onClick={handleVideoPopupClose} />
                    </Button>
                </Box> 
                <VideoPlayer className="video-js" options={videoJsOptions} onReady={handlePlayerReady} markers={markersPoint} time={time} />
               
            </Box>
        </Fade>
    </Modal>
  );
}

export default VideoPopup;
