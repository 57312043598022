import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Typography } from "@mui/material";
import DataTable from "./DataTable";
import Advanceoption from "./Advanceoption";
import Loader from "../../../../Common/Loader";
import ConfirmModal from "../../../../Common/ConfirmModal";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

export default function SelectQuestion({
    combineQuestion,
    setCombineQuestion,
    moveRow,
    handleRemoveItem,
    formFinalData,
    setFormFinalData,
    setText,
    setDataTable,
    language_id,
    setQualifyingQuestion,
    qualifyingQuestion,
    handleDeadlineChange,
    introSource,
    handleIntroFileChange,
    handleIntroChoose,
    outroSource,
    handleOutroFileChange,
    handleOutroChoose,
    introFileName,
    outroFileName,
    outroFileNameError,
    introFileNameError,
    loaderstepfour,
    introVideoUploading,
    outroVideoUploading,
}) {
    const [open, setOpen] = React.useState(false);
    const [optionForConfirm, setOptionForConfirm] = useState();
    const handleConfirmModalOpen = (index, rowData) => {
        setOptionForConfirm({ index, rowData });
        setOpen(true);
    };
    const handleConfirm = (option) => {
        handleRemoveItem(option.index, option.rowData);
        setOptionForConfirm();
        setOpen(false);
    };
    return (
        <Box sx={{ mt: 5 }}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid sx={{ bgcolor: "#e0eff2" }} xs={12} md={12}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                padding: "0px",
                            }}
                        >
                            <Typography sx={{ p: 1 }} variant="body1">
                                Your assessment can contain up to five tests. Browse the test library and add the most relevant ones.{" "}
                                <span style={{ textDecoration: "underline" }}>Utilize various types of tests for optimal results</span> .
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Container>
            {loaderstepfour ? (
                <Container maxWidth="xl">
                    <Loader />
                </Container>

            ) : (
                <Container maxWidth="xl">
                    <DataTable combineQuestion={combineQuestion} setCombineQuestion={setCombineQuestion} moveRow={moveRow} handleRemoveItem={handleConfirmModalOpen} fromCall={4} />
                    <Advanceoption
                        handleDeadlineChange={handleDeadlineChange}
                        formData={formFinalData}
                        setFormData={setFormFinalData}
                        setText={setText}
                        setDataTable={setDataTable}
                        language_id={language_id}
                        setQualifyingQuestion={setQualifyingQuestion}
                        qualifyingQuestion={qualifyingQuestion}
                        handleIntroChoose={handleIntroChoose}
                        handleIntroFileChange={handleIntroFileChange}
                        introSource={introSource}
                        outroSource={outroSource}
                        introFileName={introFileName}
                        outroFileName={outroFileName}
                        handleOutroFileChange={handleOutroFileChange}
                        handleOutroChoose={handleOutroChoose}
                        introFileNameError={introFileNameError}
                        outroFileNameError={outroFileNameError}
                        introVideoUploading={introVideoUploading}
                        outroVideoUploading={outroVideoUploading}
                    />
                </Container>
            )}
            {open && <ConfirmModal open={open} setOpen={setOpen} handleConfirm={handleConfirm} data={optionForConfirm} setData={setOptionForConfirm} from={"this"} />}
        </Box>
    );
}
