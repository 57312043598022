import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function BackdropLoader() {
    return (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
