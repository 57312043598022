import React from "react";
import Topbar from "../Layouts/Index";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import img404 from "../../../Assests/Images/404Img/404.png";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Loader from "../Loader";

const Item = styled(Paper)(({ theme }) => ({
	boxShadow: "none",
	padding: "5px",
}));

const LinkStyle = {
	color: "var(--text-color-Green)",
	textDecoration: "none",
};
const PageNotFound = (loading) => {
	return (
		<div>
			{loading ? (
				<></>
			):(
				<Container maxWidth="xl">
				<HelmetProvider>
					<div>
						<Helmet>
							<title>Access Denied - TestnHire</title>
						</Helmet>
					</div>
				</HelmetProvider>
				<Topbar />
				<Box sx={{ boxShadow: "none", border: "none", mt: "122px" }}>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
					>
						<Grid
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								border: "none",
								boxShadow: "none",
							}}
							xs={12}
							md={8}
						>
							<Item>
								{" "}
								<Typography
									fontWeight={500}
									style={{ color: "var(--text-color-black)" }}
									variant="h3"
									sx={{ mt: 2 }}
								>
									<strong> Access Denied (403)</strong>
								</Typography>
								<Typography
									fontWeight={500}
									style={{ color: "var(--text-color-black)" }}
									variant="h5"
									sx={{ mt: 2 }}
								>
									We Can't find what you're looking for, but the links below may
									help.
								</Typography>
								<Typography fontSize={18} variant="subtitle1" sx={{ mt: 2 }}>
									⦿ See what's happening at TestNHire on our awesome{" "}
									<Link style={LinkStyle} to="/blogs">
										blog
									</Link>
								</Typography>
								<Typography fontSize={18} variant="subtitle1" sx={{ mt: 2 }}>
									⦿ Read more about our software on the{" "}
									<Link style={LinkStyle} to="/product">
										product page
									</Link>
								</Typography>
								<Typography fontSize={18} variant="subtitle1" sx={{ mt: 2 }}>
									⦿ Or we can just take you{" "}
									<Link style={LinkStyle} to="/">
										home
									</Link>
								</Typography>
							</Item>
						</Grid>
						<Grid xs={12} md={4}>
							<img
								style={{ width: "100%", maxWidth: "100%", marginTop: "30px" }}
								src={img404}
								alt="404"
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
			)}
		</div>
	);
};

export default PageNotFound;
