import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography, Snackbar, Divider, TextField, Checkbox } from "@mui/material";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import LinearProgress from "@mui/material/LinearProgress";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import CodeEditor from "../../../../../Common/CodeEditor";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Logo from "../../../../../../Assests/Images/LOGO.png";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { getHeaders, getCodeQuestion, getLanguage, getProgrammingLanguages } from "../../../../../../Constants/ConstantsUrl";
import LockIcon from "@mui/icons-material/Lock";
import ReplayIcon from "@mui/icons-material/Replay";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#f0f0f0",
                        "--TextField-brandBorderHoverColor": "#f0f0f0",
                        "--TextField-brandBorderFocusedColor": "#f0f0f1",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "16.5px 0px 16.5px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
            ","
        ),
        "&:focus": {
            borderRadius: 4,
            borderColor: "var(--border-color-Green)",
        },
        "&:hover": {
            borderColor: "var(--border-color-Green)",
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    "& #demo-customized-select": {
        boxSizing: "border-box",
        minWidth: "245px",
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function SwipeableTemporaryDrawer({ state, setState, toggleDrawer, handleFontChange, fontSize, handleThemeChange, theme }) {
    const list = () => (
        <Box sx={{ width: 650 }} role="presentation" onKeyDown={toggleDrawer(false)}>
            <Grid container spacing={2} sx={{ marginTop: 5 }}>
                <Grid item xs={8}>
                    <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, padding: "18px 0 18px 40px" }}>
                        Configuration
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                    <CloseIcon color="black" sx={{ m: "15px" }} onClick={toggleDrawer(false)} />
                </Grid>
            </Grid>
            <Divider />
            <Box sx={{ marginTop: 3 }}>
                <Typography variant="h3" sx={{ fontSize: "20px", fontWeight: 500, padding: "18px 0 18px 40px" }}>
                    Formatting
                </Typography>
            </Box>
            <Grid container spacing={0} sx={{ marginTop: 3 }}>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={{ fontSize: "15px", fontWeight: 400, padding: "18px 0 18px 40px" }}>
                        Color Theme
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{ pr: 2 }}>
                    <select
                        style={{
                            width: "100%",
                            fontSize: "1.15rem",
                            padding: "0.675em 3em 0.675em 1em",
                            backgroundColor: "#fff",
                            border: "1px solid #caced1",
                            borderRadius: "0.25rem",
                            color: "#000",
                        }}
                        input={<BootstrapInput />}
                        value={theme}
                        onChange={handleThemeChange}
                        inputProps={{
                            "aria-label": "Select option",
                        }}
                    >
                        <option value={"vs-white"}>Light</option>
                        <option value={"vs-dark"}>Dark</option>
                    </select>
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ marginTop: 3 }}>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={{ fontSize: "15px", fontWeight: 400, padding: "18px 0 18px 40px" }}>
                        Font Size
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{ pr: 2 }}>
                    <select
                        style={{
                            width: "100%",
                            fontSize: "1.15rem",
                            padding: "0.675em 3em 0.675em 1em",
                            backgroundColor: "#fff",
                            border: "1px solid #caced1",
                            borderRadius: "0.25rem",
                            color: "#000",
                        }}
                        input={<BootstrapInput />}
                        value={fontSize}
                        onChange={handleFontChange}
                        inputProps={{
                            "aria-label": "Select option",
                        }}
                    >
                        <option value={10}>10px</option>
                        <option value={12}>12px</option>
                        <option value={14}>14px</option>
                        <option value={16}>16px</option>
                        <option value={18}>18px</option>
                        <option value={20}>20px</option>
                    </select>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <Backdrop
                    aria-labelledby="transition-title"
                    aria-describedby="transition-description"
                    open={state}
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                    sx={{ zIndex: 9999 }}
                >
                    <Fade className="FadeModal" in={state}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "40%",
                                right: "0%",
                                transform: "translateY(-40%)",
                                display: state === true ? "block" : "none",
                                width: 650,
                                height: 1080,
                                bgcolor: "background.paper",
                                border: "none",
                                borderRadius: "8px",
                                boxShadow: 24,
                                zIndex: 9999,
                            }}
                            onOutsideClick={toggleDrawer()}
                        >
                            <Grid variant="h6">{list()}</Grid>
                        </Box>
                    </Fade>
                </Backdrop>
            </React.Fragment>
        </div>
    );
}

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PreviewCodeQuestions() {
    const handleCloseTab = () => {
        window.close();
    };
    const { main_question_id } = useParams();
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [languages, setlanguages] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [sizes, setSizes] = useState([400, 400]);
    const { data, count, handleCodeChangeSubmit } = questionData;
    const [result, setResult] = useState({});
    const [codeResult, setCodeResult] = useState(0);
    const [updatedCode, setUpdatedCode] = useState("");
    const [compileLoad, setCompileLoad] = useState(false);
    const [langChangePop, setLangChangePop] = useState("");
    const defaultCode = {
        javascript: "\n\n// Start your code below \n",
        cpp: "",
        java: "",
        python: "",
        php: "\n<?php\n",
        html: "\n\n<!--  Start your code below --> \n\n// <!-- end your code above --> \n\n",
        c: "\n\n// Start your code below ",
        // Add more languages as needed
    };
    const [error, setError] = useState("");
    const [language, setlanguage] = useState("");
    const [passCount, setPassCount] = useState(0);
    const [failCount, setFailCount] = useState(0);
    const [oneTimeRun, setOneTimeRun] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [customInput, setCustomInput] = useState("");
    const [canResetCode, setCanResetCode] = useState(false);
    const [theme, setTheme] = useState("vs-white");
    const [initialWriteCode, setInitialWriteCode] = useState("");
    const [writeCode, setWriteCode] = useState(initialWriteCode);
    useEffect(() => {
        if (main_question_id > 0) {
            fetchMainCustomQuestion();
            fetchDataL();
        }
    }, [initialWriteCode]);

    const fetchMainCustomQuestion = async () => {
        const headers = getHeaders();
        const data = {
            'custom_main_question_id': main_question_id
        }
        try {
            await axios.post(getCodeQuestion, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data.data.custom_question;
                    if (response.data.status === 200) {
                        setQuestionData(res)
                    }
                })
        } catch (error) {
            if (error?.response.data.status === 404) {
                setErrorMsg(error?.response.data.data.message);
                setNotificationOpen(true);
                setTimeout(function () {
                    //handleCloseTab();
                }, 5000);
            }
        }
    }

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const fetchDataL = async () => {
        try {
            const headers = getHeaders();
            await axios.get(getProgrammingLanguages, { headers })
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setlanguages(res.data);
                        setlanguage(res.data[0]?.name);
                        setWriteCode(res.data[0]?.template);
                    }
                });
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };

    const show_test_arr = []; //showTestCase !== null ? showTestCase.split(",") : [];
    const handleClose = () => {
        setLangChangePop("");
        setResettingCode(false);
    };
    const [resettingCode, setResettingCode] = useState(false);
    const handleCodeChange = (value) => {
        setCanResetCode(true);
        setUpdatedCode(value);
        setWriteCode(value);
        setResult({});
    };

    const handleWarningClose = () => {
        setOneTimeRun(false);
    };
    const handelSubmitCode = () => {
        if (Object.keys(result).length !== 0) {
            //handleCodeChangeSubmit(questionData.question_data.question[count]?.id, writeCode, codeResult);
        } else {
            setOneTimeRun(true);
        }
    };

    const handleOpenLanguageChange = (event) => {
        setLangChangePop(event.target.value);
    };

    const handleLanguageChangeConfirm = (event) => {
        setlanguage(langChangePop);
        let changes = languages.find((item) => item.name === langChangePop);
        setWriteCode(changes.template);
        setUpdatedCode(changes.template);
        setLangChangePop("");
        setCanResetCode(false);
        setResult({});
        setCodeResult(0);
    };

    const compileCode = async () => {
        setError("");
        setCompileLoad(true);
        let testcase;
        let testcasecount;
        if (showInput !== true) {
            testcase = questionData.question_data.question[count]?.test_cases;
            testcasecount = questionData.question_data.question[count]?.no_of_test_cases;
        } else {
            const alData = [
                {
                    ["test0"]: customInput,
                    ["output0"]: "3",
                },
            ];
            testcase = JSON.stringify(alData);
            testcasecount = 1;
        }

        let data123 = JSON.stringify({
            lang: language,
            code: updatedCode,
            question_id: questionData.question_data.id,
            test_case_json: testcase,
            test_case_count: testcasecount,
            back_temp: "",
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://givemeprompt.me:8000/api/" + language,
            headers: {
                "Content-Type": "application/json",
            },
            data: data123,
        };
        try {
            const response = await axios.request(config);
            setResult(response.data);
            setCompileLoad(false);
            if (showInput !== true) {
                Object.keys(result).forEach((key) => {
                    const testCaseResult = result[key][0]?.testcase_result;

                    if (testCaseResult === "pass") {
                        setPassCount((prevCount) => prevCount + 1);
                    } else if (testCaseResult === "fail") {
                        setFailCount((prevCount) => prevCount + 1);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            setError(error?.response?.data.detail[0].msg);
            setCompileLoad(false);
        }
    };
    function removeFileNamesFromError(errorText) {
        const filePathRegex = /\/[\w\/.-]+\/\w+\.\w+/g;
        const cleanedError = errorText.replace(filePathRegex, "");
        return cleanedError;
    }

    useEffect(() => {
        let passCount = 0;
        let failCount = 0;

        Object.keys(result).forEach((key) => {
            const testCaseResult = result[key][0]?.testcase_result;
            if (testCaseResult === "pass") {
                passCount++;
            } else if (testCaseResult === "fail") {
                failCount++;
            }
        });
        setPassCount(passCount);
        setFailCount(failCount);
        if (questionData?.question_data?.question?.no_of_test_cases === passCount) {
            setCodeResult(1);
        }
        else {
            setCodeResult(0);
        }
    }, [result]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [fontSize, setFontSize] = useState(14);

    const handleFontChange = (e) => {
        setFontSize(e.target.value);
    };

    const handleCustomInput = (e) => {
        setCustomInput(e.target.value);
    };
    const handleCheckboxChange = (event) => {
        setFailCount(0);
        setPassCount(0);
        setResult({});
        setError("");
        setCustomInput("");
        setShowInput(event.target.checked);
    };

    const handleResetCodeOpen = (e) => {
        setResettingCode(true);
    };
    const handleResetCodeConfirm = (e) => {
        let changes = languages.find((item) => item.name === language);
        setWriteCode(changes.template);
        setUpdatedCode(changes.template);
        setCanResetCode(false);
        setResult({});
        setCodeResult(0);
        setResettingCode(false);
    };

    const handleThemeChange = (e) => {
        setTheme(e.target.value);
    };
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState(open);
    };

    const outerTheme = useTheme();

    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid>
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="Company logo"
                    />
                </Grid>

                <Grid sx={{ padding: "20px" }}>
                    <Button
                        onClick={handleCloseTab}
                        sx={{
                            ...btnStyle,
                            bgcolor: "var(--button-bg-color-assessment)",
                            minWidth: "48px",
                            minHeight: "48px",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            "&:hover": {
                                bgcolor:
                                    "var(--button-hover-bg-color-LightGreen)",
                            },
                        }}
                        variant="contained"
                    >
                        {'Go back to tests'}
                    </Button>
                </Grid>
            </Grid>

            <Container
                maxWidth="xl"
                style={{
                    backgroundColor: "white",
                    padding: "0px",
                    borderRadius: "12px",
                }}
            >
                {langChangePop !== "" ? (
                    <Backdrop
                        aria-labelledby="transition-title"
                        aria-describedby="transition-description"
                        open={true}
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        sx={{ zIndex: 9999 }}
                    >
                        <Fade className="FadeModal" in={true}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 500,
                                    bgcolor: "background.paper",
                                    border: "none",
                                    borderRadius: "8px",
                                    boxShadow: 24,
                                }}
                            >
                                <Grid id="transition-modal-title" variant="h6">
                                    <Grid
                                        sx={{
                                            bgcolor: "red",
                                            p: 2,
                                            borderRadius: "8px 8px 0px 0px",
                                        }}
                                        container
                                        spacing={0}
                                    >
                                        <Grid xs={10}>
                                            <Typography
                                                id="modal-modal-title"
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "18px",
                                                    color: "var(--text-color-OffWhite)",
                                                }}
                                                variant="h2"
                                            >
                                                Warning
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography id="transition-modal-description" sx={{ p: 3 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            my: 2,
                                        }}
                                        variant="h6"
                                    >
                                        You are switching the programming language
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            my: 2,
                                        }}
                                        variant="h6"
                                    >
                                        Your Current Progress will be deleted as well as your result. It will not available if you switch back to this language.
                                    </Typography>

                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "end",
                                                mt: 5,
                                            }}
                                            xs={12}
                                            md={12}
                                        >
                                            <Button
                                                onClick={handleClose}
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "none",
                                                    boxShadow: "none",

                                                    "&:hover": {
                                                        bgcolor: "#ededed",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                sx={{
                                                    bgcolor: "var(--text-color-Green)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",

                                                    "&:hover": {
                                                        bgcolor: "var(--text-color-Green)",
                                                    },
                                                }}
                                                variant="contained"
                                                onClick={handleLanguageChangeConfirm}
                                            >
                                                Confirm
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Fade>
                    </Backdrop>
                ) : (
                    ""
                )}

                {resettingCode && (
                    <Backdrop
                        aria-labelledby="transition-title"
                        aria-describedby="transition-description"
                        open={true}
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        sx={{ zIndex: 9999 }}
                    >
                        <Fade className="FadeModal" in={true}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 500,
                                    bgcolor: "background.paper",
                                    border: "none",
                                    borderRadius: "8px",
                                    boxShadow: 24,
                                }}
                            >
                                <Grid id="transition-modal-title" variant="h6">
                                    <Grid
                                        sx={{
                                            bgcolor: "red",
                                            p: 2,
                                            borderRadius: "8px 8px 0px 0px",
                                        }}
                                        container
                                        spacing={0}
                                    >
                                        <Grid xs={10}>
                                            <Typography
                                                id="modal-modal-title"
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "18px",
                                                    color: "var(--text-color-OffWhite)",
                                                }}
                                                variant="h2"
                                            >
                                                Warning
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography id="transition-modal-description" sx={{ p: 3 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "18px",
                                            my: 2,
                                        }}
                                        variant="h6"
                                    >
                                        Are you sure you want to reset your code?
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            my: 2,
                                        }}
                                        variant="h6"
                                    >
                                        Your code will be cleared and you’d have to start again from the scratch. Is that what you want to do?
                                    </Typography>

                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "end",
                                                mt: 5,
                                            }}
                                            xs={12}
                                            md={12}
                                        >
                                            <Button
                                                onClick={handleClose}
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "none",
                                                    boxShadow: "none",

                                                    "&:hover": {
                                                        bgcolor: "#ededed",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                sx={{
                                                    bgcolor: "var(--text-color-Green)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",

                                                    "&:hover": {
                                                        bgcolor: "var(--text-color-Green)",
                                                    },
                                                }}
                                                variant="contained"
                                                onClick={handleResetCodeConfirm}
                                            >
                                                Reset Code
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Fade>
                    </Backdrop>
                )}

                {oneTimeRun !== false ? (
                    <Backdrop
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={true}
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        sx={{ zIndex: 9999 }}
                    >
                        <Fade in={true}>
                            <Box
                                className="FadeModal"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    bgcolor: "background.paper",
                                    border: "none",
                                    borderRadius: "8px",
                                    boxShadow: 24,
                                }}
                            >
                                <Grid id="transition-modal-title" variant="h6">
                                    <Grid
                                        sx={{
                                            bgcolor: "red",
                                            p: 2,
                                            borderRadius: "8px 8px 0px 0px",
                                        }}
                                        container
                                        spacing={0}
                                    >
                                        <Grid xs={10}>
                                            <Typography
                                                id="modal-modal-title"
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "18px",
                                                    color: "var(--text-color-OffWhite)",
                                                }}
                                                variant="h2"
                                            >
                                                Warning
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography id="transition-modal-description" sx={{ p: 3 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            my: 2,
                                        }}
                                        variant="h6"
                                    >
                                        Run Your Code First
                                    </Typography>

                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "end",
                                                mt: 5,
                                            }}
                                            xs={12}
                                            md={12}
                                        >
                                            <Button
                                                onClick={handleWarningClose}
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    bgcolor: "#ededed",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "none",
                                                    boxShadow: "none",

                                                    "&:hover": {
                                                        bgcolor: "#ededed",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Fade>
                    </Backdrop>
                ) : (
                    ""
                )}

                <Box style={{ boxShadow: "0 6px 7px -4px #8888880f,0 11px 15px 1px #8888880b,0 4px 20px 3px #88888809", borderRadius: "4px", height: "905px", overflowY: "scroll" }}>
                    <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
                        <Pane minSize={300} maxSize="100%" sx={{ overflow: "auto" }}>
                            <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, padding: "18px 0 18px 40px" }}>
                                Question
                            </Typography>

                            <Divider
                                sx={{
                                    my: 1,
                                    borderColor: "#E0E0E0",
                                    width: "100%",
                                }}
                            />
                            <Grid sx={{ p: "16px 40px 30px" }}>
                                <Typography variant="body1" sx={{ fontSize: "18px", fontWeight: 500, textWrap: "balance" }}>
                                    <div dangerouslySetInnerHTML={{ __html: questionData?.question_data?.question }} />
                                </Typography>
                                <Grid sx={{ mt: 5 }}>
                                    <Typography variant="body2" sx={{}}>
                                        For this test you’re using <span style={{ color: "black", fontWeight: "bold" }}>{language}</span>
                                        <br />
                                        Feel free to add comments in your code explaining your solution.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Pane>
                        <Pane>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between", padding: "8px 16px", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, padding: "10px 8px" }}>
                                            Coding Window
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: "flex", justifyContent: "end", gap: "16px" }}>
                                        <FormControl sx={{ minWidth: 120 }}>
                                            <select
                                                style={{
                                                    fontSize: "1.15rem",
                                                    padding: "0.375em 3em 0.375em 1em",
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #caced1",
                                                    borderRadius: "0.25rem",
                                                    color: "#000",
                                                }}
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                onChange={handleOpenLanguageChange}
                                                value={language}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                {languages?.map((item) => (
                                                    <option
                                                        style={{
                                                            paddingInline: "10px", // Adjust horizontal padding as needed
                                                            paddingBlock: "10px",
                                                            width: "150px", // Adjust width as needed
                                                            height: "40px", // Adjust height as needed
                                                            borderRadius: "4px", // Optional: Apply border radius for a rounded look
                                                            border: "1px solid #ced4da", // Optional: Apply border for a
                                                        }}
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormControl>
                                        <Box onClick={!canResetCode ? () => { } : handleResetCodeOpen}>
                                            <ReplayIcon sx={{ fontSize: 30, color: !canResetCode ? "grey" : "black" }} />
                                        </Box>
                                        <SettingsIcon sx={{ fontSize: 30 }} onClick={toggleDrawer(true)} />
                                    </Grid>
                                </Grid>
                                <Divider
                                    sx={{
                                        my: 1,
                                        borderColor: "#E0E0E0",
                                        width: "100%",
                                    }}
                                />

                                <Grid className="custom-code-editor">
                                    <CodeEditor theme={theme} name="front_temp" onChange={(value) => handleCodeChange(value)} language={language} code={writeCode} fontSizeValuePass={fontSize} />
                                </Grid>

                                <Grid sx={{ p: "10px", borderTop: "1px solid #ededed", borderBottom: "1px solid #ededed", mt: 2 }} container spacing={0}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "start",
                                        }}
                                        xs={12}
                                        md={4}
                                    >
                                        <Typography sx={{ fontSize: "18px", fontWeight: 700, my: 1 }}>Testcases</Typography>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "end",
                                        }}
                                        xs={12}
                                        md={8}
                                    >
                                        <Button
                                            sx={{
                                                color: "var(--text-color-black)",
                                                bgcolor: "#ededed",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "none",
                                                boxShadow: "none",

                                                "&:hover": {
                                                    bgcolor: "#ededed",
                                                    boxShadow: "none",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={compileCode}
                                        >
                                            Run Code
                                        </Button>

                                        <Button
                                            sx={{
                                                bgcolor: "#46a997",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "capitalize",
                                                padding: " 6px 26px",
                                                "&:hover": {
                                                    bgcolor: "#46a997",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={handelSubmitCode}
                                        >
                                            Submit code
                                        </Button>
                                    </Grid>
                                    {/* <Divider
                                            sx={{
                                                my: 1,
                                                borderColor: "#E0E0E0",
                                                width: "100%",
                                            }}
                                        /> */}
                                </Grid>
                                <Grid>
                                    {compileLoad ? (
                                        <Box sx={{ width: "100%" }}>
                                            <LinearProgress sx={{ color: "var(--text-color-green)" }} />
                                        </Box>
                                    ) : (
                                        <Grid>
                                            {showInput ? (
                                                <Grid>
                                                    <Box sx={{ display: "flex", marginTop: 2 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    id="custom_input"
                                                                    name="custom_input"
                                                                    label="Input"
                                                                    multiline
                                                                    value={customInput}
                                                                    onChange={(e) => handleCustomInput(e)}
                                                                    rows={3} // Adjust the number of rows as needed
                                                                    sx={{ height: "auto", width: "100%" }}
                                                                />
                                                            </Grid>
                                                            {result[0] && (
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        id="outlined-uncontrolled"
                                                                        label="Output"
                                                                        multiline
                                                                        rows={3}
                                                                        sx={{ height: "auto", width: "100%" }}
                                                                        disabled
                                                                        defaultValue={result?.stdout === null ? "none" : result[0][1]?.stdout}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                    {result[0] && (
                                                        <Box sx={{ marginTop: "10px" }}>
                                                            <Typography style={{ color: "red" }}>Error</Typography>
                                                            <Grid style={{ backgroundColor: "#f0f0f0", padding: "10px", overflow: "scroll" }}>
                                                                {result[0][1]?.error === null ? "none" : removeFileNamesFromError(result[0][1]?.error)}
                                                            </Grid>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Box sx={{ display: "flex", marginTop: 2, maxHeight: 220 }}>
                                                    <Tabs
                                                        className="testcasetab"
                                                        orientation="vertical"
                                                        variant="scrollable"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="Vertical tabs example"
                                                        sx={{ borderRight: 1, borderColor: "divider", overflow: "unset", width: "191px" }}
                                                    >
                                                        {questionData && questionData.question_data?.test_cases.map((testcase, index) => (
                                                            <Tab
                                                                key={index}
                                                                label={
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <span style={{ marginRight: "5px", fontSize: "1.2rem" }}>{`Testcase ${index + 1}`}</span>
                                                                        {result[index] && result[index][0]?.testcase_result === "pass" ? "✅" : "❌"}
                                                                    </div>
                                                                }
                                                                icon={!show_test_arr.includes(index.toString()) ? <LockIcon /> : ""}
                                                                {...a11yProps(index)}
                                                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                                            />
                                                        ))}
                                                    </Tabs>
                                                    {questionData && questionData.question_data?.test_cases.map((testcase, index) => (
                                                        <TabPanel style={{ overflowY: "auto", maxHeight: "300px", width: "75%" }} key={index} value={value} index={index}>
                                                            {show_test_arr.includes(index.toString()) ? (
                                                                <Grid>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                id="outlined-uncontrolled"
                                                                                label="Input"
                                                                                defaultValue={`${questionData.question_data?.test_cases[index][`test${index}`]}`}
                                                                                multiline
                                                                                rows={3} // Adjust the number of rows as needed
                                                                                sx={{ height: "auto", width: "100%" }}
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="outlined-uncontrolled"
                                                                                label="Expected Output"
                                                                                multiline
                                                                                defaultValue={questionData.question_data?.test_cases[index][`output${index}`]}
                                                                                sx={{ height: "auto", width: "100%" }}
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    {result[index] ? (
                                                                        <Grid>
                                                                            <Typography>Output</Typography>
                                                                            <Grid style={{ backgroundColor: "#f0f0f0" }}>
                                                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                                                    <TextField
                                                                                        sx={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                        multiline
                                                                                        disabled
                                                                                        defaultValue={result[index][1]?.stdout === null ? "none" : result[index][1]?.stdout}
                                                                                    />
                                                                                </ThemeProvider>
                                                                            </Grid>
                                                                            {result[index][1]?.error !== null ? (
                                                                                <Box sx={{ marginTop: "10px" }}>
                                                                                    <Typography style={{ color: "red" }}>Error</Typography>
                                                                                    <Grid style={{ backgroundColor: "#f0f0f0", padding: "10px", overflow: "scroll" }}>
                                                                                        {result[index][1]?.error === null ? "none" : removeFileNamesFromError(result[index][1]?.error)}
                                                                                    </Grid>
                                                                                </Box>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Grid>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <>
                                                                        <strong>Testcase Result: </strong>
                                                                        {result[index] ? result[index][0]?.testcase_result : "none"}
                                                                    </>
                                                                </Grid>
                                                            ) : (
                                                                <Grid style={{ display: "flex", justifyContent: "center" }}>
                                                                    {result[index] ? (
                                                                        <>
                                                                            {result[index][1]?.error === null ? (
                                                                                <>
                                                                                    <LockIcon style={{ fontSize: "150px", color: "#ededed", width: "100%" }} />
                                                                                </>
                                                                            ) : (
                                                                                <Box sx={{ marginTop: "10px" }}>
                                                                                    <Typography style={{ color: "red" }}>Error</Typography>
                                                                                    <Grid style={{ backgroundColor: "#f0f0f0", padding: "10px", overflow: "scroll" }}>
                                                                                        {removeFileNamesFromError(result[index][1]?.error)}
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <LockIcon style={{ fontSize: "150px", color: "#ededed", width: "100%" }} />
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        </TabPanel>
                                                    ))}
                                                </Box>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={0} sx={{ backgroundColor: "#f6f6f6", position: "fixed", bottom: "86px", width: "40%", zIndex: "999" }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRight: 1,
                                            borderColor: "divider",
                                        }}
                                        xs={3}
                                    >
                                        <Checkbox checked={showInput} onChange={handleCheckboxChange} />
                                        <Typography sx={{ fontSize: "18px", fontWeight: 700, my: 1 }}>Custom Input</Typography>
                                    </Grid>
                                    {showInput ? (
                                        ""
                                    ) : (
                                        <Grid xs={9}>
                                            <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", spacing: 2, height: "50px" }}>
                                                <Typography style={{ color: "green", marginRight: "20px", fontWeight: 700 }}>Passed: {passCount}</Typography>
                                                <Typography style={{ color: "red", marginRight: "20px", fontWeight: 700 }}>Error: {failCount}</Typography>
                                                <Typography style={{ fontWeight: 700 }}>Total: {questionData?.question_data?.no_of_test_cases}</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Pane>
                    </SplitPane>
                </Box>
                {state && (
                    <SwipeableTemporaryDrawer
                        state={state}
                        setState={setState}
                        toggleDrawer={toggleDrawer}
                        handleFontChange={handleFontChange}
                        fontSize={fontSize}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                    />
                )}
            </Container>
            <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={handleNotificationClose}
                    severity="error"
                    className="error_msg"
                    sx={{
                        width: "500px",
                        justifyContent: "center",
                        background: "#cb7b7a",
                        color: "var(--text-color-OffWhite)",
                    }}
                >
                    {errorMsg}
                </Alert>
            </Snackbar>

        </Box>
    );
}
