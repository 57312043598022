import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Front End Components
import Home from "./Components/FrontEnd/Home/Index";
import Pricing from "./Components/FrontEnd/Pricing/Index";
import PaymentSuccess from "./Components/FrontEnd/PaymentSuccess/Index";
import PaymentFail from "./Components/FrontEnd/PaymentFail/Index";

// Common
import Logout from "./Components/Common/Logout";

// Admin Components
import AssessmentTable from "./Components/Admin/Assissment/AssessmentTable/Index";
import MyAssessment from "./Components/Admin/Assissment/MyAssessment/Index";
import MyCandidates from "./Components/Admin/Assissment/MyCandidates/Index";
import CodePopup from "./Components/Admin/CodePopup/Index";
import Tests from "./Components/Admin/Assissment/Tests/Index";
import TestsModal from "./Components/Admin/Assissment/TestsModal/Index";
import Invite from "./Components/Admin/Assissment/MyAssessment/Invite/Index";
import CandidateDetails from "./Components/Admin/Assissment/MyAssessment/Invite/CandidateDetails/Index";

import Profile from "./Components/Admin/Dashboard/Profile";
import CustomerPageNotFound from "./Components/Admin/404/PageNotFound";
import AccessDenided from "./Components/Admin/403/AccessDenided";
import CandidateAllDetails from "./Components/Admin/Assissment/MyCandidates/CandidateDetails/Index";
import { PermissionProvider } from "react-permission-role";
import { AllowedAccess } from "react-permission-role";
import Preview from "./Components/Admin/Dashboard/Preview/Index";
import QuestionPreview from "./Components/Admin/Dashboard/QuestionPreview/Index";
import PreviewQuestions from "./Components/Admin/Assissment/MyAssessment/Step2/PreviewQuestions";
import PreviewMcqQuestions from "./Components/Admin/Assissment/MyAssessment/Step3/PreviewQuestions/PreviewMcqQuestions";
import PreviewEssayQuestions from "./Components/Admin/Assissment/MyAssessment/Step3/PreviewQuestions/PreviewEssayQuestions";
import PreviewCodeQuestions from "./Components/Admin/Assissment/MyAssessment/Step3/PreviewQuestions/PreviewCodeQuestions";
import PreviewVideoQuestions from "./Components/Admin/Assissment/MyAssessment/Step3/PreviewQuestions/PreviewVideoQuestions";
import PreviewFileQuestions from "./Components/Admin/Assissment/MyAssessment/Step3/PreviewQuestions/PreviewFileQuestions";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins, sans-serif",
    },
});

function Redirect() {
    // window.location.replace(process.env.REACT_APP_HOME_URL);
    return <></>;
}

function App() {
    return (
        <PermissionProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    <Router basename="/">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/pricing" element={<Pricing />} />
                            <Route path="/payment-successfull" element={<PaymentSuccess />} />
                            <Route path="/payment-fail" element={<PaymentFail />} />
                            <Route
                                path="/logout"
                                element={
                                    <Logout />
                                }
                            />

                            {/* Admin Route */}
                            <Route
                                path="/customer/*"
                                element={
                                    <CustomerPageNotFound />
                                }
                            />
                            <Route
                                path="/customer/access-denied"
                                element={
                                    <AccessDenided />
                                }
                            />
                            <Route
                                path="/customer/profile"
                                element={
                                    <Profile />
                                }
                            />

                            <Route
                                path="/customer/assessment"
                                element={
                                    <AllowedAccess permissions="view_my_assessment" renderAuthFailed={<AccessDenided />}>
                                        <AssessmentTable />
                                    </AllowedAccess>
                                }
                            />
                            <Route
                                path="/customer/create-assessment"
                                element={
                                    <AllowedAccess permissions="add_my_assessment" renderAuthFailed={<AccessDenided />}>
                                        <MyAssessment />
                                    </AllowedAccess>
                                }
                            />
                            <Route
                                path="/customer/candidates"
                                element={
                                    <MyCandidates />
                                }
                            />
                            <Route
                                path="/customer/code-popup"
                                element={
                                    <CodePopup />
                                }
                            />
                            <Route
                                path="/customer/tests"
                                element={
                                    <Tests />
                                }
                            />
                            <Route
                                path="/customer/testsmodal"
                                element={
                                    <TestsModal />
                                }
                            />
                            <Route
                                path="/customer/invite"
                                element={
                                    <Invite />
                                }
                            />
                            <Route
                                path="/customer/candidate-details"
                                element={
                                    <CandidateDetails />
                                }
                            />
                            <Route
                                path="/customer/candidate-all-assessment"
                                element={
                                    <CandidateAllDetails />
                                }
                            />
                            <Route
                                path="/customer/preview"
                                element={
                                    <Preview/>
                                }
                            />
                            <Route
                                path="/customer/question-preview"
                                element={
                                    <QuestionPreview/>
                                }
                            />
                            <Route
                                path="/customer/preview-questions/:official_question_id"
                                element={
                                    <PreviewQuestions/>
                                }
                            />
                            <Route
                                path="/customer/preview-mcq-questions/:main_question_id"
                                element={
                                    <PreviewMcqQuestions/>
                                }
                            />
                            <Route
                                path="/customer/preview-essay-questions/:main_question_id"
                                element={
                                    <PreviewEssayQuestions/>
                                }
                            />
                            <Route
                                path="/customer/preview-code-questions/:main_question_id"
                                element={
                                    <PreviewCodeQuestions/>
                                }
                            />
                            <Route
                                path="/customer/preview-video-questions/:main_question_id"
                                element={
                                    <PreviewVideoQuestions/>
                                }
                            />
                            <Route
                                path="/customer/preview-file-questions/:main_question_id"
                                element={
                                    <PreviewFileQuestions/>
                                }
                            />
                            <Route path="*" element={<Redirect />} />
                        </Routes>
                    </Router>
                </div>
            </ThemeProvider>
        </PermissionProvider>
    );
}

export default App;
