import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: "https://4bf14a25b504225302c83acfc4ed2a81@o4506637456179200.ingest.sentry.io/4506637462405120",
    debug: process.env.SENTRY_DEBUG_MODE,
    environment: process.env.SENTRY_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    maxBreadcrumbs:100,
    attachStacktrace:true,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["app.testnhire.com","testnhire-react.givemeprompt.me"],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();