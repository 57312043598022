import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, IconButton, Typography } from "@mui/material";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import QuestionModal from "../../../Common/QuestionModal";
import CardLoaderSkeleton from "../../../Common/CardLoaderSkeleton";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

function TestQuestion({ isModalOpen, setIsModalOpen, closeModal, openQuestionModal, modalData, questionList, questioLOader, mainqLoader }) {
    return (
        <Box>
            <Grid container spacing={0}>
                {questionList.length > 0 ? (
                    questionList?.map((option) => (
                        <Grid key={option.id} xs={12} sm={12} md={6} lg={4} sx={{ p: 1 }}>
                            <Item
                                style={{
                                    borderRadius: "4px",
                                    boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                    padding: "20px",
                                }}
                            >
                                <Typography style={{ fontSize: "20px", fontWeight: 700 }} variant="h6">
                                    <strong>{option.question_title.length > 20 ? option.question_title.substring(0, 20) + "..." : option.question_title} </strong>
                                </Typography>

                                <Typography sx={{ height: "100px", fontSize: "14px", mt: 5 }} variant="body1">
                                    {option.summary.length > 120 ? option.summary.substring(0, 120) + "..." : option.summary}
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid xs={12}>
                                        <Item
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingBottom: "0px",
                                            }}
                                        >
                                            <IconButton color="var(--text-color-black)" style={{ paddingLeft: "0px" }}>
                                                <AccessAlarmOutlinedIcon />
                                            </IconButton>
                                            <Typography style={{}} variant="subtitle2">
                                                {option.time} min
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Item
                                            style={{
                                                display: "flex", // Enables flexbox
                                                justifyContent: "space-between", // Aligns children to either end
                                                alignItems: "center", // Centers children vertically
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                background: "none",
                                                paddingTop: "0px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    border: "1px solid var(--text-color-black)",
                                                    color: "var(--text-color-black)",
                                                }}
                                                variant="outlined"
                                                onClick={() => openQuestionModal(option.id)}
                                            >
                                                Details
                                            </Button>
                                            <Typography style={{}} variant="subtitle2">
                                                {option.question_level}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    ))
                ) : (
                    <Grid xs={12} md={12} sx={{ m: 1 }}>
                        <Item
                            style={{
                                boxShadow: "none",
                                display: "flex",
                                justifyContent: "center",
                                background: "none",
                                marginTop: "40px",
                            }}
                        >
                            <Typography style={{ fontSize: "40px", fontWeight: 700 }} variant="h1">
                                Data Not found
                            </Typography>
                        </Item>
                    </Grid>
                )}
                {questionList.length > 0 && (questioLOader || mainqLoader) && (
                    <Grid container spacing={0}>
                        {[1, 2, 3, 4, 5, 6].map((key) => (
                            <CardLoaderSkeleton key={key} />
                        ))}
                    </Grid>
                )}

                {<QuestionModal isOpen={isModalOpen} onClose={closeModal} ids={modalData} setIsModalOpen={setIsModalOpen} />}
            </Grid>
        </Box>
    );
}

export default TestQuestion;
