import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Typography, useMediaQuery } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

export default function MainQuestionSelected({ emptyArry, selectedQuestionValue, handleRemoveTest, setSelectedQuestionValue }) {
    const isMobile = useMediaQuery("(max-width:960px)");

    let sourceElement = null
    /* change opacity for the dragged item. 
    remember the source item for the drop later */
    const handleDragStart = (event) => {
        event.target.style.opacity = 0.5
        sourceElement = event.target
        event.dataTransfer.effectAllowed = 'move'
    }

    /* do not trigger default event of item while passing (e.g. a link) */
    const handleDragOver = (event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    /* add class .over while hovering other items */
    const handleDragEnter = (event) => {
        event.target.classList.add('over')
    }

    /* remove class .over when not hovering over an item anymore*/
    const handleDragLeave = (event) => {
        event.target.classList.remove('over')
    }

    const handleDrop = (event) => {
        /* prevent redirect in some browsers*/
        event.stopPropagation()

        /* only do something if the dropped on item is 
        different to the dragged item*/
        if (sourceElement !== event.target) {

            /* remove dragged item from list */
            const list = selectedQuestionValue.filter((item, i) =>
                i.toString() !== sourceElement.id)

            /* this is the removed item */
            const removed = selectedQuestionValue.filter((item, i) =>
                i.toString() === sourceElement.id)[0]

            /* insert removed item after this number. */
            let insertAt = Number(event.target.id)

            let tempList = []

            /* if dropped at last item, don't increase target id by +1. 
                max-index is arr.length */
            if (insertAt >= list.length) {
                tempList = list.slice(0).concat(removed)
                setSelectedQuestionValue(tempList)
                event.target.classList.remove('over')
            } else
                if ((insertAt < list.length)) {
                    /* original list without removed item until the index it was removed at */
                    tempList = list.slice(0, insertAt).concat(removed)

                    /* add the remaining items to the list */
                    const newList = tempList.concat(list.slice(
                        insertAt))

                    /* set state to display on page */
                    setSelectedQuestionValue(prev => newList)
                    event.target.classList.remove('over')
                }
        }
        else
            event.target.classList.remove('over')
    }

    const handleDragEnd = (event) => {
        event.target.style.opacity = 1

    }

    return (
        <Box sx={{ mt: 5 }}>
            <Container maxWidth="xl">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>

                        {selectedQuestionValue &&
                            selectedQuestionValue.map((item, index) => {
                                return (
                                    <Grid xs={4} sm={2} md={2} key={index} className='dnd-list'  >
                                        <Item
                                            id={index}
                                            draggable='true'
                                            onDragStart={handleDragStart}
                                            onDragOver={handleDragOver}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}
                                            onDragEnd={handleDragEnd}
                                            value={selectedQuestionValue[index].question_id}
                                            style={{
                                                boxShadow: "unset",
                                                borderRadius: "none",
                                                border: `${item ? "" : "2px dashed var(--button-bg-color-green)"}`,
                                            }}
                                            fontWeight={600}
                                            sx={{
                                                height: isMobile ? "59px" : "auto",
                                                p: "8px 16px",
                                                cursor: "grab",
                                                bgcolor: `${item ? "var(--button-bg-color-green)" : ""}`,
                                                opacity: `${item ? 1 : 0.5}`,
                                                display: "flex",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                    "& > .close-icon": {
                                                        opacity: 1,
                                                    },
                                                },
                                            }}
                                        >
                                            <Typography
                                                id={index}
                                                value={selectedQuestionValue[index].question_id}
                                                style={{
                                                    color: `${item ? "var(--text-color-OffWhite)" : "var(--text-color-black)"}`,
                                                    fontSize: "16px",
                                                    overflow: "hidden",
                                                    maxWidth: "100%",
                                                    display: "-webkit-box",
                                                    WebkitBoxOrient: "vertical",
                                                    WebkitLineClamp: 1,
                                                    lineHeight: "35px",
                                                }}
                                            >
                                                {item ? item.question : "1st Test"}
                                            </Typography>
                                            {item &&
                                                <CloseIcon
                                                    className="close-icon"
                                                    sx={{
                                                        opacity: 0,
                                                        color: "var(--text-color-OffWhite)",
                                                        cursor: "pointer",
                                                        height: "35px",
                                                        width: "35px",
                                                        padding: "5px",
                                                        "&:hover": {
                                                            bgcolor: "#ddbaba",
                                                            borderRadius: "20px",
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        item.id = item.question_id;
                                                        handleRemoveTest(item);
                                                    }}
                                                />

                                            }
                                        </Item>
                                    </Grid>
                                );
                            })
                        }

                        {emptyArry && emptyArry.map((values, index) => {
                            return (
                                <Grid xs={4} sm={2} md={2} key={index}>
                                    <Item
                                        sx={{
                                            height: isMobile ? "59px" : "auto",
                                            p: "8px 16px",
                                            border: "2px dotted black",
                                            opacity: `1`,
                                            display: "flex",

                                        }}
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            border: `  "2px dashed var(--button-bg-color-green)"`,
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: `black`,
                                                fontSize: "16px",
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 1,
                                                lineHeight: "35px",
                                            }}
                                            fontWeight={600}
                                        >
                                            Blank Test
                                        </Typography>
                                    </Item>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
