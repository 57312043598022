import axios from "axios";
import Cookies from "js-cookie";
import {
    excludeUrl
} from "../../Constants/ConstantsUrl";

const baseApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // timeout: 1000,
    headers: {
        "Content-Type": "application/json",
    }
});

export const APIProvider = (URL, data, method = "post") => {
    return new Promise(async (resolve, reject) => {
        const request = {
            url: URL,
            method: method,
            data: data,
        }

        await baseApiInstance(request).then(function (response) {
            resolve(response?.data);
            return response;
        }).catch(function (error) {
            const { response } = error;
            let message;
            switch (response && response?.status) {              
                case 401:
                    console.log("session expired. please login again..!!")
                    LogOut();                     
                    break;
                case 403:
                    // LogOut();                     
                    break;
                default: {
                    message = response;
                    break;
                }
            }             
            resolve(message);             
        });
    });

}

const setToken = (AUTH_TOKEN) => {
    localStorage.setItem("accessToken", AUTH_TOKEN);
    baseApiInstance.defaults.headers.common['Authorization'] = "Bearer " + AUTH_TOKEN;
}


const IsLogin = () => {
    const currentUrl = window.location.pathname;

    // First, determine if the current URL is excluded from the login check
    const isExcluded = excludeUrl.some(excludedPath => {
        if (currentUrl.startsWith(excludedPath)) {
            // Check if after the excludedPath, the rest of the URL is numerical
            const restOfUrl = currentUrl.slice(excludedPath.length);
            return /^[0-9]+(\/)?$/.test(restOfUrl); // Updated to allow a trailing slash
        }
        return false;
    });

    // If the URL is excluded, proceed without login check
    if (isExcluded) {
        return true; // Return true or user token if you need to signify no redirection should happen
    }

    // If the URL is not excluded, perform the login check
    const user = Cookies.get("_token");
    if (!user) {
        window.location.replace(process.env.REACT_APP_HOME_URL);
        return false; // Indicating the user is not logged in
    } else {
        return user; // Return the user token indicating the user is logged in
    }
}


export const LogOut = () => {
    localStorage.clear();
    Cookies.remove('_token')
    removeCookies();
    setTimeout(() => {
        window.location.replace(process.env.REACT_APP_HOME_URL);
    }, 5000)
}

function removeCookies() {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
        var key = multiple[i].split("=");
        document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC; domain=" + process.env.REACT_APP_COOKIE_DOMAIN;
    }
}

const User = () => {
    const token = IsLogin();
    setToken(token);
}
User();


axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response?.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        window.location.href = '/login'
    }
    return Promise.reject(error);
  });