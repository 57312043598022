import React, { useEffect, useState, useRef } from "react";
import { Grid, LinearProgress } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const TimerPage = (props) => {
    const {
        formatTime,
        attemptQuestion,
        progressQuestion,
        questionTime,
        questionsLength,
        handleNext,
        handleTimeOut,
        pauseTime,
        setNewTimer,
    } = props;

    const [progress, setProgress] = useState(100);
    const [times, setTimes] = useState(questionTime);
    const intervalRef = useRef(null);
    const prevQuestionTime = useRef(questionTime);

    // Reset timer if questionTime changes
    useEffect(() => {
        if (questionTime !== prevQuestionTime.current) {
            setTimes(questionTime);
            setProgress(100);
            prevQuestionTime.current = questionTime;
        }
    }, [questionTime]);

    // Handle timer countdown
    useEffect(() => {
        const startTimer = () => {
            clearInterval(intervalRef.current); // Clear existing timer
            if (!pauseTime) {
                intervalRef.current = setInterval(() => {
                    setTimes((prevTime) => {
                        let newTime = prevTime - 1;
                        // Update progress
                        setNewTimer(newTime);
                        setProgress((newTime / questionTime) * 100);

                        if (newTime <= 0) {
                            clearInterval(intervalRef.current); // Clear timer
                            handleTimeOut();
                            return 0; // Reset time to prevent going negative
                        }
                        return newTime;
                    });
                }, 1000);
            }
        };

        startTimer();

        return () => clearInterval(intervalRef.current); // Cleanup on unmount
    }, [questionTime, pauseTime]);

    return (
        <Grid item sx={{ display: "block", width: "30%", padding: "20px", my: "auto" }}>
            <Grid item xs={12} sx={{ alignItems: "center", display: "flex", }} >
                <AccessAlarmIcon sx={{ marginRight: "5px", fontSize: "13px" }} /> <span style={{ fontSize: "13px" }}>{formatTime(times)}</span>
                <LinearProgress variant="determinate" value={progress} sx={{ flexGrow: 1, mx: 1 }} />
                <span style={{ fontSize: "13px" }}>{formatTime(questionTime)} min</span>
            </Grid>
            <Grid item xs={12} sx={{ alignItems: "center", display: "flex", }} >
                <span style={{ fontSize: "13px" }}>
                    {attemptQuestion}/{questionsLength}
                </span>
                <LinearProgress variant="determinate" value={progressQuestion} sx={{ flexGrow: 1, mx: 1 }} />
            </Grid>
        </Grid>
    )
};

export default TimerPage;