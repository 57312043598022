import React from "react";
import { Button } from "@mui/material";
import { Puff } from "react-loader-spinner";

const LoaderButton = ({ isLoading, buttonText, onClick }) => {
    return (
        <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
                backgroundColor: "var(--border-color-Green)",
                position: "relative",
                "&:hover": {
                    backgroundColor: "var(--button-hover-bg-color-LightGreen)",
                },
            }}
            disabled={isLoading}
            onClick={onClick}
        >
            {isLoading ? (
                <>
                    {buttonText}
                    <Puff
                        type="ThreeDots"
                        color="#ffffff"
                        height={20}
                        width={20}
                        style={{
                            marginLeft: "10px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                </>
            ) : (
                buttonText
            )}
        </Button>
    );
};

export default LoaderButton;
