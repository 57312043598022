import React, { useEffect, useState } from "react";
import { Box, Container, Snackbar, Select,RadioGroup } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Accordion from "@mui/material/Accordion";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Checkbox, MenuItem, TextField } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import MUIDataTable from "mui-datatables";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AllowedAccess } from "react-permission-role";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import ReactPlayer from "react-player";
import PopupModel from "../Step3/PopupModel";
import { createQualifyingCustomMcqQuestion, getHeaders, addMembers } from "../../../../../Constants/ConstantsUrl";
import dayjs from "dayjs";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import Alerts from "../../../../Common/Alerts";
import CustomLoader from "../../../../Common/CustomLoader";
import ConfirmModal from "../../../../Common/ConfirmModal";
import QualifyingQuestionLibraryModal from "../Step3/QualifyingQuestionLibraryModal";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(1),
}));
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const QualifyingTableOptions = {
    filter: false,
    responsive: "standard",
    download: false,
    sort: false,
    selectableRows: "none",
    print: false,
    viewColumns: false,
    searchOpen: true,
    searchText: "",
    search: false,
    selectableRowsHeader: false,
};
const ExtraTime = [
    {
        value: "0",
        label: "0%",
    },
    {
        value: "10",
        label: "10%",
    },
    {
        value: "20",
        label: "20%",
    },
    {
        value: "50",
        label: "50%",
    },
];

export default function Advanceoption({
    formData,
    setFormData,
    setText,
    language_id,
    setQualifyingQuestion,
    qualifyingQuestion,
    handleDeadlineChange,
    handleIntroFileChange,
    introFileName,
    outroFileName,
    handleOutroFileChange,
    outroFileNameError,
    introFileNameError,
    introVideoUploading,
    outroVideoUploading,
}) {
    const updatedeadline = useState(formData?.deadline || "");
    const [newFormData, setNewFormData] = useState({
        is_deadline_never_expire: updatedeadline[0] === "" ? true : false,
    });
    const handleOnChangeFinalCheckbox = (event) => {
        setNewFormData({
            ...newFormData,
            [event.target.name]: event.target.checked,
        });
        if (event.target.checked === true) {
            handleDeadlineChange("");
        }
    };

    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const isMobile = useMediaQuery("(max-width:899px)");
    const UploadPadding = isMobile ? "" : "24px";
    const [extraQuestionBox, setExtraQuestionBox] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [anotherPopupOpen, setAnotherPopupOpen] = useState(false);
    const [step2Popup5, setStep2Popup5] = useState(false);
    const [options, setOptions] = useState(["", ""]);
    const [answer, setAnswer] = useState([false]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [optionsValidation, setOptionsValidation] = useState([]);
    const currentDateTime = dayjs();
    const [webRoleData, setWebRoleData] = useState([]);
    const [popupQualifySubmitLoader, setPopupQualifySubmitLoader] = useState(false);
    const [isadddMemberModalOpen, setAddMemberModalIsOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [indexsWithMatchingValues, setIndexsWithMatchingValues] = useState(['']);
    const [formDataQualifying, setFormDataQualifying] = useState({
        language_id: "",
        time: null,
        question: "",
        question_title: "",
        question_cat_id: "",
        what_to_look_for_ans: "",
        why_is_the_ques_rel: "",
        mcq_options_id: [{}, { answer: "" }],
    });
    // const [answerData, setAnswerData] = useState({});
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    const formDataReset = () => {
        setFormDataQualifying({
            ...formDataQualifying,
            time: null,
            question: "",
            question_title: "",
            question_cat_id: "",
            what_to_look_for_ans: "",
            why_is_the_ques_rel: "",
            mcq_options_id: [{}, { answer: "" }],
        });
        setOptions(["", ""]);
        setOptionsValidation([]);
    };
    const [introVideo, setInteroVideo] = useState(null);
    const [outroVideo, setOuteroVideo] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [optionForConfirm, setOptionForConfirm] = useState();

    const handleConfirm = (datas) => {
        const filtersData = qualifyingQuestion.filter((question) => question.question_id !== datas);
        setQualifyingQuestion(filtersData);
        setOptionForConfirm();
        setOpen(false);
    };

    useEffect(() => {
        const videoData = formData.intro_outro_video_path?.split(",");
        if (videoData[0] != "null") {
            setInteroVideo(videoData[0]);
        }

        if (videoData[1] != "null") {
            setOuteroVideo(videoData[1]);
        }
    }, []);

    const deleteActionFun = (datas) => {
        setOpen(true);
        setOptionForConfirm(datas);
    };

    const Qualifyingcolumns = [
        {
            name: "question_id",
            label: "Sr No",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => dataIndex + 1,
            },
        },
        {
            name: "question",
            label: "Question",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
            },
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "action",
            label: "ACTION",
            mantineTableHeadCellProps: {
                align: "right",
            },
            mantineTableBodyCellProps: {
                align: "right",
            },
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => (
                    <Grid sx={{ display: "flex", justfyContent: "end" }}>
                        <AllowedAccess permissions="delete_team">
                            <typography title="Remove" variant="contained" style={{ padding: 4, cursor: "pointer" }} onClick={() => deleteActionFun(tableMeta?.tableData[0][0])}>
                                <RemoveCircleOutlineIcon sx={{ color: "#c2c7c2", mr: 2 }} />
                            </typography>
                        </AllowedAccess>
                    </Grid>
                ),
            },
        },
    ];
    const updateSelectedQuestion = (key, type, id, question, type_id,details) => {
        setQualifyingQuestion([
            ...qualifyingQuestion,
            {
                question_category_id: 3,
                type: type,
                question_id: id,
                question: question,
                question_type_id: type_id,
                weight: 10,
                time: null,
                show_testcases_ids: null,
                question_detail:details //add deatils in array
            },
        ]);
    };     
    const handlePopupOpen = (e, no) => {
        setExtraQuestionBox(no);
        setPopupOpen(true);
    };

    const handleAnotherAssessmentPopupOpen = () => {
        setAnotherPopupOpen(true);
    }
    const handleAnotherAssePopupClose = () => {
        setAnotherPopupOpen(false);
    }

    const handlePopupClose = () => {
        setStep2Popup5(false);
        setPopupOpen(false);
        formDataReset();
    };
    function handleEditorChange(name, value) {
        setErrorMsg({ ...errorMsg, [name]: "" });
        setFormDataQualifying({ ...formDataQualifying, [name]: value });
    }
    function handelChangeTextBox(e) {
        setErrorMsg({ ...errorMsg, [e.target.name]: "" });
        setFormDataQualifying({
            ...formDataQualifying,
            [e.target.name]: e.target.value,
        });
    }

    const handleOptionChange = (index, value) => {
        setErrorMsg({
            ...errorMsg,
            optionNotChoose: "",
        });
        if(indexsWithMatchingValues[index]){
            setIndexsWithMatchingValues({...indexsWithMatchingValues, [index]:''})
        }
        setOptionsValidation({ ...optionsValidation, [index]: "" });
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setFormDataQualifying({
            ...formDataQualifying,
            mcq_options_id: [{ ...updatedOptions }, { answer: answer }],
        });
        setOptions(updatedOptions);
    };

    const handelAnswer = (i, value, condition) => {
        setErrorMsg({
            ...errorMsg,
            optionNotChoose: "",
        });
        setOptionsValidation({ ...optionsValidation, [value]: "" });
        if (condition !== true) {
            if (answer[value]) {
                setOptionsValidation({
                    ...optionsValidation,
                    [value]: "Please fill this before",
                });
            }
            setAnswer({
                ...answer,
                [value]: false,
            });
            return false;
        }

        if (condition === true && isQuillEmpty(options[value]) && options[value] === "") {
            if (i.target.checked) {
                setOptionsValidation({
                    ...optionsValidation,
                    [value]: "Please fill this before",
                });
            }
        }

        setAnswer({
            // ...answer,
            [value]: i.target.checked,
        });

        // setAnswerData({
        //     // ...answerData,
        //     [value]: i.target.checked,
        // });

        setFormDataQualifying({
            ...formDataQualifying,
            mcq_options_id: [{ ...options }, { answer, [value]: i.target.checked }],
        });
    };

    const handleAddOption = () => {
        if (options.length < 10) {
            setOptions([...options, ""]);
        }
    };

    const handleDeleteOption = (index) => {
        if (options.length > 2) {
            const updatedOptions = options.filter((_, i) => i !== index);
            setOptions(updatedOptions);
            setAnswer({
                [index]: false,
            })
        }
    };

    function isQuillEmpty(value) {
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img")) {
            return true;
        }
        return false;
    }

    const handelSubmit = async () => {
        if (extraQuestionBox === 6) {
            try {
                formDataQualifying["language_id"] = language_id;
                formDataQualifying["question_title"] = formDataQualifying.question;
                const question = formDataQualifying?.question;
                let question_type = "";
                question_type = "McQ";
                if (isQuillEmpty(question) || formDataQualifying?.language_id === "" || Object.keys(answer).length === 0) {
                    setErrorMsg({
                        ...errorMsg,
                        question: isQuillEmpty(question) ? "This field is required" : "",
                        language_id: formDataQualifying?.language_id ? "" : "This field is required",
                        optionNotChoose: Object.keys(answer).length > 0 ? "" : "Please Select Any Answer",
                    });
                    setPopupQualifySubmitLoader(false);
                    return false;
                }
                const mainData = formDataQualifying.mcq_options_id[0];
                const indicesWithMatchingValues = [];
                Object.keys(mainData).forEach((key1, index1) => {
                    Object.keys(mainData).forEach((key2, index2) => {
                        if (index1 < index2 && mainData[key1] === mainData[key2]) {
                            indicesWithMatchingValues.push(parseInt(key1), parseInt(key2));
                        }
                    });
                });
                if(indicesWithMatchingValues.length > 0)
                {
                    setIndexsWithMatchingValues(indicesWithMatchingValues);
                    return false;
                }
                let checkaVali = false;
                let checkaVali2 = false;
                let mcq = formDataQualifying?.mcq_options_id;
                const totalValues = Object.values(mcq[0]).filter((value) => value !== "").length;
                if (totalValues < 2) {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please Fill minimum 2 option",
                    });
                    setPopupQualifySubmitLoader(false);
                    return false;
                }
                Object.entries(mcq[0]).forEach((data, key) => {
                    if (optionsValidation[key] !== "" && optionsValidation[key] !== undefined) {
                        checkaVali = true;
                    }
                    if (isQuillEmpty(mcq[0][key])) {
                        checkaVali2 = true;
                    }
                });
                if (checkaVali || checkaVali2) {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please Select Right Answer and Fill all option",
                    });
                    setPopupQualifySubmitLoader(false);
                    return false;
                }
                if (Object.keys(answer).length > 0) {
                    const trueKeys = Object.keys(answer).filter((key) => answer[key] === true);
                    if (trueKeys.length > 0) {
                        const trueKeysString = trueKeys.join(", ");
                        formDataQualifying["mcq_options_id"][1]["answer"] = trueKeysString;
                    }
                    else {
                        setErrorMsg({
                            ...errorMsg,
                            optionNotChoose: "Please Select Right Answer",
                        })
                        setPopupQualifySubmitLoader(false);
                        return false;
                    }

                } else {
                    setErrorMsg({
                        ...errorMsg,
                        optionNotChoose: "Please Select Right Answer",
                    });
                    setPopupQualifySubmitLoader(false);
                    return false;
                }
                const headers = getHeaders();
                await axios
                    .post(createQualifyingCustomMcqQuestion, formDataQualifying, {
                        headers,
                    })
                    .then((response) => response)
                    .then((response) => {
                        const qualifyingdata = response.data;
                        if (qualifyingdata.status === 200) {
                            setPopupQualifySubmitLoader(false);
                            setNotificationOpen(true);
                            updateSelectedQuestion(
                                qualifyingdata.data.custom_question_id,
                                question_type,
                                qualifyingdata.data.custom_question_id,
                                qualifyingdata.data.question.question,
                                qualifyingdata.data.custom_question_type_id,
                                qualifyingdata.data.question
                            );
                            setOptions(["", ""]);
                            setAnswer([false]);
                            handlePopupClose();
                            formDataReset();
                        }
                    });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const addMembertoggleModal = () => {
        setAddMemberModalIsOpen(!isadddMemberModalOpen);
    };

    const AddMemberModal = ({ onAddRequestClose }) => {
        const [moreAddOptions, setMoreAddOptions] = useState([
            {
                role_id: "5",
                email: "",
            },
        ]);
        const [validationErrors, setValidationErrors] = useState([]);
        const [errorMsg, setErrorMsg] = useState([
            {
                role_id: false,
                email: false,
            },
        ]);
        const handleInputChange = (event, index) => {
            // Copy the array using spread operator
            const updatedOptions = [...moreAddOptions];
            // Update the specific element in the array at the given index
            updatedOptions[index] = {
                ...updatedOptions[index],
                [event.target.name]: event.target.value,
            };
            // Update the state using the functional form of setMoreAddOptions
            setMoreAddOptions((prevOptions) => updatedOptions);
            const updatedfiledOptions = [...errorMsg];
            updatedfiledOptions[index] = {
                ...updatedfiledOptions[index],
                [event.target.name]: event.target.name ? false : true,
            };
            setErrorMsg((prevOptions) => updatedfiledOptions);
        };

        let validationCheck = (value) => {
            let data = false;
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
                data = true;
            }
            return data;
        };

        const handleSubmitadd = async () => {
            const emailValid = [];
            const updatedfiledOptions = [...errorMsg];
            moreAddOptions.map((data, index) => {
                if (data?.email !== "") {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                        updatedfiledOptions[index] = {
                            ...updatedfiledOptions[index],
                            ["role_id"]: data?.role_id ? false : true,
                            ["email"]: true,
                        };
                    } else {
                        emailValid.push(data.email);
                    }
                }
                if (data?.role_id === "" || data?.email === "") {
                    updatedfiledOptions[index] = {
                        ...updatedfiledOptions[index],
                        ["role_id"]: data?.role_id ? false : true,
                        ["email"]: data?.email ? validationCheck(data) : true,
                    };
                }
            });
            setErrorMsg((prevOptions) => updatedfiledOptions);
            if (emailValid.length !== moreAddOptions.length) {
                return false;
            } else {
                submitFunction(moreAddOptions);
            }
        };
        const submitFunction = async (data) => {
            const dataSended = { user_details: data };
            try {
                const headers = getHeaders();
                await axios
                    .post(addMembers, dataSended, { headers })
                    .then((response) => response)
                    .then((response) => {
                        const res = response.data;
                        if (res.status === 200) {
                            addMembertoggleModal();
                        }
                    });
            } catch (error) {
                const retundata = error.response.data.data;
                const createobj = Object.keys(retundata);
                const updatedfiledOptions = [...errorMsg];
                createobj?.map((data, indes) => {
                    let numberIndex = data.split(".");
                    updatedfiledOptions[numberIndex[1]] = {
                        ...updatedfiledOptions[numberIndex[1]],
                        ["email"]: true,
                        ["exist_error"]: true,
                    };
                });
                setErrorMsg((prevOptions) => updatedfiledOptions);
            }
        };
        const handleAddOption = () => {
            if (moreAddOptions.length < 10) {
                setMoreAddOptions([
                    ...moreAddOptions,
                    {
                        role_id: "5",
                        email: "",
                    },
                ]);
            }
        };
        const handleDeleteOption = (index) => {
            if (moreAddOptions.length > 1) {
                const updatedOptions = moreAddOptions.filter((_, i) => i !== index);
                setMoreAddOptions(updatedOptions);
            }
        };

        return (
            <Box sx={{ overflow: "auto" }} className="invite_popup_modal__backdrop">
                <Container
                    maxWidth={"sm"}
                    sx={{
                        p: 0,
                        borderRadius: "8px",
                        bgcolor: "white",
                        maxWidth: "100%",
                        m: "50px auto",
                    }}
                // className="invite_popup_modal__container"
                >
                    <Box sx={{ py: 3 }}>
                        <Grid container spacing={0} sx={{ mt: 1 }}>
                            <Grid xs={12} md={11}>
                                <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>Add new team members</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                            <Typography sx={{ fontWeight: 400, fontSize: "16px", color: "var(--text-color-Green)" }}>You can always add and edit team members in the settings.</Typography>
                            <Grid container spacing={0} sx={{ mt: 1 }}>
                                <Grid sx={{ maxHeight: "220px", overflowY: "auto", width: "100%", overflowX: "hidden" }}>
                                    {moreAddOptions &&
                                        moreAddOptions.map((data, index) => (
                                            <>
                                                <Grid sx={{}} container spacing={0}>
                                                    <Grid xs={11} md={11}>
                                                        <Item
                                                            sx={{
                                                                boxShadow: "none",
                                                                background: "none",
                                                            }}
                                                            className="text"
                                                        >
                                                            <Grid sx={{ position: "relative" }}>
                                                                <TextField
                                                                    fullWidth
                                                                    id="fullWidth"
                                                                    name="email"
                                                                    value={data?.email}
                                                                    error={errorMsg[index]?.email ? true : false}
                                                                    // helperText={
                                                                    //     errorMsg[index]?.email
                                                                    //         ? errorMsg[index]
                                                                    //               ?.email === true
                                                                    //             ? "Email is required"
                                                                    //             : errorMsg[index]
                                                                    //                   ?.email
                                                                    //         : null
                                                                    // }
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    sx={{ width: "100%" }}
                                                                    label="Teammate's work email"
                                                                />

                                                                <Select
                                                                    value={data?.role_id}
                                                                    name="role_id"
                                                                    defaultValue="5"
                                                                    IconComponent={ExpandMoreIcon}
                                                                    inputProps={{
                                                                        "aria-label": "Select option",
                                                                    }}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        right: "2px",
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        color: "var(--text-color-black)",
                                                                        boxShadow: "unset",
                                                                        padding: "0px 20px ",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "var(--button-bg-color-transparent)",
                                                                        fontSize: "14px",
                                                                        borderRadius: "0px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#ededed",
                                                                        },
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            border: "none",
                                                                            borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                            borderRadius: "0px",
                                                                            "&:hover": {
                                                                                borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                                borderRadius: "0px",
                                                                            },
                                                                        },
                                                                    }}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                >
                                                                    {webRoleData?.map((val, index) => (
                                                                        <MenuItem key={index} value={val.id}>
                                                                            {val.title}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Item>
                                                        {errorMsg[index]?.email ? (
                                                            errorMsg[index]?.email === true ? (
                                                                <Grid className="error_class">
                                                                    <div className="error_class_font_color">{errorMsg[index]?.exist_error ? "Email has already been taken" : "Email is required"}</div>
                                                                </Grid>
                                                            ) : (
                                                                errorMsg[index]?.email
                                                            )
                                                        ) : null}
                                                    </Grid>
                                                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={1} md={1}>
                                                        <Button fullWidth id="fullWidth" onClick={() => handleDeleteOption(index)}>
                                                            {" "}
                                                            <CloseIcon sx={{ color: "var(--text-color-black)" }} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ))}
                                </Grid>
                                <Grid
                                    sx={{
                                        mt: 5,
                                        display: "flex",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            textTransform: "none",
                                            fontSize: "16px",
                                            display: "contents",
                                            color: "var(--text-color-Green)",
                                            cursor: "pointer",
                                        }}
                                        variant="body1"
                                        onClick={handleAddOption}
                                    >
                                        {
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    color: "var(--text-color-Green)",
                                                    fontSize: "27px",
                                                    mr: 1,
                                                }}
                                            ></AddCircleOutlineIcon>
                                        }
                                        Add more
                                    </Typography>
                                </Grid>

                                <Grid style={{ width: "100%" }} container spacing={0}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "end",
                                        }}
                                        xs={12}
                                        md={12}
                                    >
                                        <Button
                                            sx={{
                                                color: "var(--text-color-black)",
                                                bgcolor: "#ededed",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "none",
                                                boxShadow: "none",
                                                mb: 2,
                                                "&:hover": {
                                                    bgcolor: "#e0e0e0",
                                                    boxShadow: "none",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={onAddRequestClose}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            sx={{
                                                bgcolor: "var(--text-color-Green)",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "capitalize",
                                                mb: 2,
                                                "&:hover": {
                                                    bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={handleSubmitadd}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const allowedTypes = ["video/mp4", "video/webm", "video/ogg"];
        if (file && allowedTypes.includes(file.type)) {
            e.target = e.dataTransfer;
            handleIntroFileChange(e);
        } else {
            setAlertNotification({
                status: true,
                type: "failed",
                message: "Unsupported file type",
            });
        }
    };

    const handleDragOverOutro = (e) => {
        e.preventDefault();
    };

    const handleDropOutro = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const allowedTypes = ["video/mp4", "video/webm", "video/ogg"];
        if (file && allowedTypes.includes(file.type)) {
            e.target = e.dataTransfer;
            handleOutroFileChange(e);
        } else {
            setAlertNotification({
                status: true,
                type: "failed",
                message: "Unsupported file type",
            });
        }
    };

    const setBlobUrl = (type, event) => {
        const file = event.target.files[0];
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        if (file.size > maxSize) {
            console.log("File size exceeds the maximum limit");
            type === "intro" ? setInteroVideo("") : setOuteroVideo("");
            return false;
        }
        console.log("File size exceeds the maximum limit");
        let urlBlob = URL.createObjectURL(file);
        type === "intro" ? setInteroVideo(urlBlob) : setOuteroVideo(urlBlob);
    };

    return popupOpen === true ? (
        <PopupModel
            popupSubmitLoader={popupQualifySubmitLoader}
            onClose={handlePopupClose}
            extraQuestionBox={extraQuestionBox}
            formData={formDataQualifying}
            handelAnswer={handelAnswer}
            handleEditorChange={handleEditorChange}
            handelChangeTextBox={handelChangeTextBox}
            handleAddOption={handleAddOption}
            handleDeleteOption={handleDeleteOption}
            handleOptionChange={handleOptionChange}
            // handleChange={handleChange}
            options={options}
            // checked={checked}
            handelSubmit={handelSubmit}
            step2Popup5={step2Popup5}
            errorMsg={errorMsg}
            optionsValidation={optionsValidation}
            answer={answer}
            indexsWithMatchingValues={indexsWithMatchingValues}
        />
    ) : anotherPopupOpen === true ? (
        <QualifyingQuestionLibraryModal
            popupSubmitLoader={popupQualifySubmitLoader}
            onClose={handleAnotherAssePopupClose}
            extraQuestionBox={extraQuestionBox}
            formData={formDataQualifying}
            qualifyingQuestion={qualifyingQuestion}
            handleEditorChange={handleEditorChange}
            handelChangeTextBox={handelChangeTextBox}
            handleAddOption={handleAddOption}
            handleDeleteOption={handleDeleteOption}
            updateSelectedQuestion={updateSelectedQuestion}
            // handleChange={handleChange}
            options={options}
            // checked={checked}
            handelSubmit={handelSubmit}
            setQualifyingQuestion={setQualifyingQuestion}
            errorMsg={errorMsg}
            optionsValidation={optionsValidation}
            answer={answer}
        />
    ) : (
        <Box sx={{ flexGrow: 1, my: 5 }}>
            {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
            {isadddMemberModalOpen && <AddMemberModal onAddRequestClose={addMembertoggleModal} />}
            <Grid container spacing={2}>
                <Grid xs={12} md={12}>
                    <Item sx={{ boxShadow: 5, mb: "65px" }}>
                        {" "}
                        <Accordion style={{ boxShadow: "none", border: "none" }} defaultExpanded>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    "& .MuiGrid2-root > svg": {
                                        display: "none",
                                    },
                                }}
                            >
                                <Grid>
                                    <Typography variant="body1" fontWeight={700} fontSize={"16px"}>
                                        ADVANCED OPTIONS
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <ExpandMoreIcon />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                        {open && <ConfirmModal open={open} setOpen={setOpen} handleConfirm={handleConfirm} data={optionForConfirm} setData={setOptionForConfirm} from={"Custom"} />}
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                QUALIFYING QUESTION
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={"14px"}>
                                            Qualifying questions are given to candidates before they take the tests. These questions help determine
                                            if candidates meet the basic requirements for the job. Candidates proceed to the tests only if they answer all
                                            questions correctly. You can add up to 5 qualifying questions, and you will only be charged for candidates who
                                            pass these questions.
                                        </Typography>
                                        {qualifyingQuestion.length < 5 &&
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        border: "1px solid var(--text-color-black)",
                                                        mt: 2,
                                                    }}
                                                    onClick={(e) => handlePopupOpen(e, 6)}
                                                >
                                                    New Question
                                                </Button>

                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        border: "1px solid var(--text-color-black)",
                                                        mt: 2,
                                                        ml: 3,
                                                    }}
                                                    onClick={(e) => handleAnotherAssessmentPopupOpen(e, 6)}
                                                >
                                                    Copy questions from another assessment
                                                </Button>
                                            </>
                                        }
                                        <Container maxWidth="xl" sx={{px:"0px !important"}}>
                                            {/* new qualifyingquestion Table with preview of options, component defined below the flie */}
                                            <QualifyingQuestion mcqList={qualifyingQuestion} deleteActionFun={deleteActionFun}/>
                                        </Container>
                                        
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                INTRO/OUTRO VIDEO
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ p: "15px" }}>
                                            You can add a pre-recorded video to welcome candidates to your assessment. It will be displayed at the beginning of the assessment by default.
                                        </Typography>
                                        <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }}>
                                            <Grid xs={6} md={6} className={introFileNameError ? "error_class" : ""}>
                                                <Grid
                                                    sx={{
                                                        pl: UploadPadding,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Grid
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleDrop}
                                                        sx={{
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            bgcolor: "#f0f0f0",
                                                            border: "1px dashed #888",
                                                            borderRadius: "8px",
                                                            flexDirection: "column",
                                                            textAlign: "center",
                                                            padding: "16px 32px",
                                                            height: 150,
                                                        }}
                                                        component="label"
                                                        variant="contained"
                                                        starticon={<CloudUploadIcon />}
                                                    >
                                                        {introVideoUploading ? (
                                                            <Grid>
                                                                <CustomLoader />
                                                            </Grid>
                                                        ) : (
                                                            <>
                                                                <Grid>
                                                                    <UploadFileIcon />
                                                                </Grid>
                                                                <Typography>
                                                                    Intro Drag and drop or <span style={{ fontWeight: 600 }}>browse</span> your files.
                                                                </Typography>
                                                                <Typography sx={{ mt: 2 }} className={introFileNameError ? "error_class_font_color" : ""}>
                                                                    {introFileName}
                                                                </Typography>
                                                                <VisuallyHiddenInput
                                                                    type="file"
                                                                    accept=".mov,.mp4,.avi,.mpeg"
                                                                    onChange={(e) => {
                                                                        setBlobUrl("intro", e);
                                                                        handleIntroFileChange(e);
                                                                    }}
                                                                />
                                                            </>
                                                        )
                                                        }
                                                    </Grid>
                                                    <div className="VideoInput">
                                                        {formData.id !== null ? (
                                                            introVideo ? (
                                                                <>
                                                                    <ReactPlayer style={{ height: "auto", width: "100%", aspectRatio: "16/9", marginTop: "16px", borderRadius: "8px", objectFit: "cover" }} width={"100%"} height="auto" url={introVideo} playing={true} muted={true} controls={true} />
                                                                </>
                                                            ) : (
                                                                <Typography className="video_not_found">No intro video for this assessment</Typography>
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <Typography sx={{ mt: 2 }}>
                                                        <span style={{ fontWeight: 600 }}>Supported file types:</span> avi,mp4,mpeg,mov
                                                    </Typography>
                                                    <Typography sx={{ mt: 2 }}>
                                                        <span style={{ fontWeight: 600 }}>Max File Size:</span> 25MB only
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={6} md={6} className={outroFileNameError ? "error_class" : ""}>
                                                <Grid
                                                    sx={{
                                                        pl: UploadPadding,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Grid
                                                        onDragOver={handleDragOverOutro}
                                                        onDrop={handleDropOutro}
                                                        sx={{
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            bgcolor: "#f0f0f0",
                                                            border: "1px dashed #888",
                                                            borderRadius: "8px",
                                                            flexDirection: "column",
                                                            textAlign: "center",
                                                            padding: "16px 32px",
                                                            height: 150,
                                                        }}
                                                        component="label"
                                                        variant="contained"
                                                        starticon={<CloudUploadIcon />}
                                                    >
                                                        {outroVideoUploading ? (
                                                            <Grid>
                                                                <CustomLoader />
                                                            </Grid>
                                                        ) : (
                                                            <>
                                                                <Grid>
                                                                    <UploadFileIcon />
                                                                </Grid>
                                                                <Typography>
                                                                    Outro Drag and drop or <span style={{ fontWeight: 600 }}>browse</span> your files.
                                                                </Typography>
                                                                <Typography sx={{ mt: 2 }} className={outroFileNameError ? "error_class_font_color" : ""}>
                                                                    {outroFileName}
                                                                </Typography>

                                                                <VisuallyHiddenInput
                                                                    type="file"
                                                                    accept=".mov,.mp4,.avi,.mpeg"
                                                                    onChange={(e) => {
                                                                        setBlobUrl("outro", e);
                                                                        handleOutroFileChange(e);
                                                                    }}
                                                                />
                                                            </>
                                                        )
                                                        }
                                                    </Grid>
                                                    <div className="VideoInput">
                                                        {formData.id !== null ? (
                                                            outroVideo ? (
                                                                <>
                                                                    <ReactPlayer style={{ height: "auto", width: "100%", aspectRatio: "16/9", marginTop: "16px", borderRadius: "8px", objectFit: "cover" }} width={"100%"} height="auto" url={outroVideo} playing={true} muted={true} controls={true} />
                                                                </>
                                                            ) : (
                                                                <Typography className="video_not_found">No intro video for this assessment</Typography>
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <Typography sx={{ mt: 2 }}>
                                                        <span style={{ fontWeight: 600 }}>Supported file types:</span> avi,mpeg,mov,mp4
                                                    </Typography>
                                                    <Typography sx={{ mt: 2 }}>
                                                        <span style={{ fontWeight: 600 }}>Max File Size:</span> 25MB only
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                ANTI-CHEATING SETTINGS
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: 0 }}>
                                        <Grid>
                                            <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                <FormControlLabel
                                                    sx={{ ml: 0, mr: 1 }}
                                                    control={
                                                        <Checkbox
                                                            checked={formData?.is_anti_cheating_mode?.is_screenshot_enable}
                                                            name="is_screenshot_enable"
                                                            onChange={setFormData} {...label}
                                                        />
                                                    }
                                                    label="Candidate Screen snapshots will be captured every 1 seconds. " />
                                                <HelpOutlineIcon sx={{}} />
                                            </Typography>
                                        </Grid>
                                        <Collapse in={formData?.is_anti_cheating_mode?.is_screenshot_enable} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 2, bgcolor: "#e0eff2" }}>
                                                <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5 }}>
                                                    <Grid xs={12} md={6}>
                                                        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                            <FormControlLabel
                                                                sx={{ ml: 0, mr: 1 }}
                                                                control={
                                                                    <Checkbox
                                                                        name="is_mandatory_screenrec" onChange={setFormData} {...label}
                                                                    />
                                                                }
                                                                label={<span
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: 500,
                                                                        display: "flex",

                                                                    }}
                                                                >is it mandatory for candidate to Screen Record?</span>}
                                                                checked={formData?.is_anti_cheating_mode?.is_mandatory_screenrec}
                                                            />
                                                            <HelpOutlineIcon sx={{}} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Grid>
                                            <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                <FormControlLabel
                                                    sx={{ ml: 0, mr: 1 }}
                                                    control={
                                                        <Checkbox
                                                            name="is_snapshot_enable" onChange={setFormData} {...label}
                                                        />
                                                    }
                                                    label="Candidate snapshots will be captured every 1 seconds using the webcam."
                                                    checked={formData?.is_anti_cheating_mode?.is_snapshot_enable}
                                                />
                                                <HelpOutlineIcon sx={{}} />
                                            </Typography>
                                        </Grid>
                                        <Collapse in={formData?.is_anti_cheating_mode?.is_snapshot_enable} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 2, bgcolor: "#e0eff2" }}>
                                                <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5 }}>
                                                    <Grid xs={12} md={6}>
                                                        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                            <FormControlLabel
                                                                sx={{ ml: 0, mr: 1 }}
                                                                control={
                                                                    <Checkbox
                                                                        name="is_mandatory_webcam" onChange={setFormData} {...label}
                                                                    />
                                                                }
                                                                label={<span
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontWeight: 500,
                                                                        display: "flex",

                                                                    }}
                                                                >is it mandatory for Candidate to Use Web Cam?</span>}
                                                                checked={formData?.is_anti_cheating_mode?.is_mandatory_webcam}
                                                            />
                                                            <HelpOutlineIcon sx={{}} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </AccordionDetails>
                                </Accordion>
                                {/* <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                EXTRA TIME FOR TEXTS
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={"14px"}>Provide additional time for all candidates during their tests.</Typography>
                                        <Typography fontSize={"14px"}>Extra time applies to all tests from the library, excluding custom questions.</Typography>

                                        <Box
                                            component="form"
                                            sx={{
                                                "& .MuiTextField-root": { m: 1, width: "25ch" },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    id="outlined-select-time"
                                                    select
                                                    name="extra_time"
                                                    label="Select Time"
                                                    defaultValue="0"
                                                    helperText="Extra time"
                                                    onChange={setText}
                                                    fontSize={"14px"}
                                                    value={formData.extra_time}
                                                >
                                                    {ExtraTime.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        </Box>

                                        <Grid sx={{ bgcolor: "#e0eff2" }}>
                                            <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                                Please remember...
                                            </Typography>
                                            <Typography variant="body1" fontSize={"14px"}>
                                                {" "}
                                                Percentile ranking of candidates is not available with granted extra time.
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                                Candidates will be scored based on "% correct".
                                            </Typography>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion> */}
                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                ACCOMMODATION FOR CANDIDATES
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid>
                                            <Typography variant="body1" fontSize={"14px"}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={formData.is_fluent_in_lang} name="is_fluent_in_lang" onChange={setFormData} {...label} />}
                                                    label="Non-fluent English speakers"
                                                />
                                            </Typography>


                                            {formData.is_fluent_in_lang == true && <>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <TextField
                                                            id="outlined-select-time"
                                                            select
                                                            name="extra_time_english"
                                                            label="Select Time"
                                                            defaultValue="0"
                                                            // helperText="Extra time"
                                                            onChange={setText}
                                                            fontSize={"14px"}
                                                            value={formData.extra_time_english}
                                                        >
                                                            {ExtraTime.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>
                                                </Box>
                                            </>}

                                            <Typography variant="body1" fontSize={"14px"}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={formData.is_disabled} name="is_disabled" onChange={setFormData} {...label} />}
                                                    label="Candidates with conditions that affect their concentration or memory capacity."
                                                />
                                            </Typography>

                                            {formData.is_disabled == true && <>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <TextField
                                                            id="outlined-select-time"
                                                            select
                                                            name="extra_time"
                                                            label="Select Time"
                                                            defaultValue="0"
                                                            // helperText="Extra time"
                                                            onChange={setText}
                                                            fontSize={"14px"}
                                                            value={formData.extra_time}
                                                        >
                                                            {ExtraTime.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>
                                                </Box>
                                            </>}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>


                                {/* Show Result To Candidates is Commented By Nakshatra From Here  ..... */}

                                {/* <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                SHOW RESULTS TO CANDIDATES
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid>
                                            <Typography variant="body1" fontSize={"14px"}>
                                                {" "}
                                                <FormControlLabel
                                                    control={<Checkbox checked={formData.is_result_shown} name="is_result_shown" onChange={setFormData} {...label} />}
                                                    label="Enable candidates redirection to TestNHire Profiles"
                                                />{" "}
                                            </Typography>
                                            <Typography variant="body1" fontSize={"14px"}>
                                                {" "}
                                                    The assessment results will be accessible to candidates through their TestNHire Profiles.
                                                    This profile serves as a centralized hub for all their assessments and results. Candidates can view their results immediately
                                                    after completing an assessment. Disabling this feature will only disable the post-assessment redirect. In compliance with 
                                                    Europe's GDPR regulations, TestNHire is obligated to share results with candidates upon request, as outlined in our data processing 
                                                    agreement.
                                                <span style={{ color: "var(--text-color-Green)", cursor: "pointer" }} fontSize={"14px"}>
                                                    {" "}
                                                    Learn more
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion> */}

                                {/* Till Here Because Right Now We dont Have Such Functionality But Later or sooner we will introduce  */}

                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={700} fontSize={"14px"}>
                                                DEADLINE
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" fontSize={"14px"}>
                                            <FormControlLabel
                                                control={<Checkbox checked={newFormData.is_deadline_never_expire} name="is_deadline_never_expire" onChange={handleOnChangeFinalCheckbox} {...label} />}
                                                label="Never Expire The Assessment"
                                            />
                                        </Typography>
                                        <Grid>
                                            <Typography variant="body1" fontSize={"14px"}>
                                                Schedule a specific date and time (at least 24 hours from now) for candidates to finish the assessment.
                                                <br />
                                                Candidates who have already started can continue and complete the assessment even after the deadline has passed.
                                            </Typography>
                                        </Grid>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                disabled={newFormData.is_deadline_never_expire}
                                                className="DateCalandera deadline_custom_css"
                                                value={formData.deadline}
                                                minDateTime={currentDateTime}
                                                onChange={handleDeadlineChange}
                                            />
                                        </LocalizationProvider>
                                    </AccordionDetails>
                                </Accordion>
                            </AccordionDetails>
                        </Accordion>
                    </Item>
                </Grid>
            </Grid>
            <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={handleNotificationClose}
                    severity="success"
                    sx={{
                        width: "500px",
                        justifyContent: "center",
                        backgroundColor: "#cbd7cb",
                        color: "var(--text-color-black)",
                    }}
                >
                    Qualifying Question is created successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
}

//showing qualifying question
function QualifyingQuestion({ mcqList,deleteActionFun }) {
    return (
        <Grid>
            <Box sx={{ p: 2, boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)" }}>
                <Typography variant="body1" fontWeight={600}>
                    Questions
                </Typography>
            </Box>
            {mcqList?.map((item, keys) => {
                return (
                    <Accordion key={keys} style={{ boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)", border: "none" }}>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <Grid xs={12} md={6}>
                                <Typography variant="body1" fontWeight={600}  dangerouslySetInnerHTML={{ __html: item?.question_detail?.question}} />
                            </Grid>
                            <Grid xs={12} md={6} sx={{ display: "flex", justifyContent: "end", alignItems:"center" }}>
                            
                                <AllowedAccess permissions="delete_team">
                                    <typography title="Remove" variant="contained" style={{ padding: 4, cursor: "pointer" }} onClick={() => deleteActionFun(item.question_id)}>
                                        <RemoveCircleOutlineIcon sx={{ color: "#c2c7c2", mr: 2 }} />
                                    </typography>
                                </AllowedAccess>
                   
                                <ExpandMoreIcon />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5, py: 2 }}>
                                <Grid xs={12} md={6}>
                                    <div dangerouslySetInnerHTML={{ __html: item?.question_detail?.question }} />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Grid sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
                                        <RadioGroup style={{ flexDirection: "column" }} row aria-label="radio-group-english" name="fluentEnglish">
                                            {/* Mapping through each option */}
                                            {item?.question_detail?.options?.map((option, index) => (
                                                <Grid
                                                    key={index}
                                                    className={item?.question_detail?.mcq_ans.split(",").includes(option.id.toString()) ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}
                                                    sx={{
                                                        my: 1,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        sx={{ width: "100%", boxShadow: "0 2px 8px 1px #a8a8a833" }}
                                                        value={option.id}
                                                        control={
                                                            <Checkbox
                                                                inputProps={{ "aria-label": "controlled" }}
                                                                icon={<RadioButtonUncheckedIcon sx={{ fontSize: "35px", color: "#ededed" }} />}
                                                                checkedIcon={<RadioButtonCheckedIcon sx={{ fontSize: "35px", color: "white" }} />}
                                                                checked={item.question_detail?.mcq_ans.split(",").includes(option.id.toString()) ? true : false}
                                                                disabled
                                                            ></Checkbox>
                                                        }
                                                        // checked={tr}
                                                        label={
                                                            <span
                                                                style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                <div dangerouslySetInnerHTML={{ __html: option.options }} />
                                                            </span>
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Grid>
    );
}
