import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

export default function Alerts({ notification, setNotification }) {
    const handleClose = () => {
        setNotification({
            ...notification,
            status: false,
        });
    };

    return (
        <Snackbar open={notification?.status} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={notification?.type === "success" ? "success" : "error"}
                className={notification?.type === "success" ? "" : "error_msg"}
                sx={{
                    width: "500px",
                    justifyContent: "center",
                    backgroundColor: notification?.type === "success" ? "#cbd7cb" : "",
                    color: notification?.type === "success" ? "var(--text-color-black)" : "var(--text-color-OffWhite)",
                }}
            >
                {notification?.message}
            </MuiAlert>
        </Snackbar>
    );
}
