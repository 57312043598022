import { Grid, Typography, TextField, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { APIProvider } from "../../Common/ApiProvider";
import { addCreditUrl, getTopUpList } from "../../../Constants/ConstantsUrl";
import BackdropLoader from "../../Common/BackdropLoader";
import Loader from "../Loader";

function StripeTopUp({ customTheme, outerTheme, Item }) {
    const [paymentLoader, setPaymentLoader] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const [credit, setCredit] = useState(0);
    const [creditList, setCreditList] = useState([]);

    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const getCreditList = async () => {
        try {
            await APIProvider(getTopUpList, {}, "get").then((response) => {
                if (response.status === 200) {
                    setCreditList(response?.data?.plan_details);                      
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setPaymentLoader(false);
        }
    };  

    const changeCredits = (type) => {
        if (type === 1) {
            setCredit((credit) => credit + 1);
        } else {
            setCredit((credit) => credit - 1);
        }
    };

    const addCredits = async () => {
        setPaymentLoader(true);
        try {
            let pricings = creditList[credit]?.pricings[currency];             
            let data = {
                amount: pricings.pricing,
                currency: currency,
                stripe_payment_id: pricings.stripe_payment_id,
            };

            await APIProvider(addCreditUrl, data, "post").then((response) => {
                if (response.status === 200) {
                    window.location.href = response?.data?.message;
                }
            });
        } catch (error) {
            setPaymentLoader(false);
        }
    };

    useEffect(() => {
        setPaymentLoader(true);
        getCreditList();
    }, []);

    const btnStyles = {
        ml: 2,
        bgcolor: "var(--button-bg-color-green)",
        padding: "10px",
        "&:hover": {
            bgcolor: "var(--button-hover-bg-color-LightGreen)",
        },
        "&:disabled": {
            bgcolor: "var( --pricing-btn-color)",
            color: "white",
        },
    };

    return (
        <>
            {paymentLoader ? <Loader /> : <></>}
            <Grid sx={{ p: 5 }} xs={12} md={6}>
                <Item sx={{ p: 3, boxShadow: "0 6px 7px -4px #88888829,0 11px 15px 1px #8888881f,0 4px 20px 3px #88888823", display: "flex" }}>
                    <Button
                        variant="contained"
                        size="small"
                        sx={btnStyles}
                        disabled={credit === 0 ? true : false}
                        onClick={() => {
                            changeCredits(0);
                        }}
                    >
                        <Typography variant="h5" fontSize={"18px"} fontWeight={700}>
                            -
                        </Typography>
                    </Button>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            className="CounterValue"
                            type="number"
                            value={creditList[credit]?.credits}
                            sx={{
                                m: 1,
                                "& input": {
                                    color: "black",
                                    fontWeight: "bold",
                                },
                            }}
                            disabled
                            fullWidth
                            variant="standard"
                        ></TextField>
                    </ThemeProvider>
                    <Button
                        variant="contained"
                        size="small"
                        sx={btnStyles}
                        disabled={credit === creditList.length - 1 ? true : false}
                        onClick={() => {
                            changeCredits(1);
                        }}
                    >
                        <Typography variant="h5" fontSize={"18px"} fontWeight={700}>
                            +
                        </Typography>
                    </Button>
                </Item>
                <Button
                    style={btnStyles}
                    sx={{
                        textTransform: "none",
                        width: "100%", mt: 3,
                        background:
                            "var(--button-bg-color-green)",
                        "&:hover": {
                            background:
                                "var(--button-hover-bg-color-LightGreen)",
                        },
                    }}
                    variant="outlined"
                    onClick={addCredits}
                >
                    <Typography
                        fontWeight={600}
                        sx={{
                            fontSize: "var(  --btn-fontsize)",
                            color: "var(--text-color-OffWhite)",
                        }}

                    >
                        Add Credit
                    </Typography>
                </Button>
            </Grid >

        </>

    )
}

export default StripeTopUp;