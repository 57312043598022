import { Skeleton } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const TableRowsLoader = ({ rowsNum, columnNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        {
            [...Array(columnNum)].map((row, index2) => (
                <TableCell key={index2} component="th" scope="row">
                    <Skeleton animation="wave" variant="rectangular" />
                </TableCell>
            ))
        }         
      </TableRow>
    ));
  };

export default TableRowsLoader;  