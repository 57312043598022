import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Logo from "../../../../Assests/Images/LogoImg/LOGO.png";
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1,bgcolor:"#f6f6f6",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center" }}>
       <Container maxWidth="sm"> 
      <Grid container spacing={0}>
        <Grid xs={12}>
          <Item sx={{boxShadow:"none",borderRadius:"0px",p:5}}>
          <img style={{}} src={Logo} alt="../../../../Assests/Images/LOGO.png" />
          <Typography variant="h3" sx={{fontSize:"14px",fontWeight:400, mt:6,lineHeight:"1.3rem"}}>
        Welcome back! It seems like you've already finised your assessment. The results are avaibale to Test-N-Hire.
      </Typography>
          </Item>
        </Grid>
     
      </Grid>
      </Container>
    </Box>
  );
}
