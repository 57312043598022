import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, Button, Checkbox, TextField, RadioGroup, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import MUIDataTable from "mui-datatables";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import dayjs from "dayjs";
import ReactPlayer from "react-player";
import { AllowedAccess } from "react-permission-role";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

const columnsMcq = [
    {
        name: "question",
        label: <Typography style={{ fontSize: "18px", fontWeight: 600 }}>Question</Typography>,
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta) => {
                return value?.slice(0, 150) + (value?.length > 150 ? "..." : "");
            },
        },
    },
    {
        name: "type",
        label: <Typography style={{ fontSize: "18px", fontWeight: 600 }}>Type</Typography>,
        options: {
            filter: true,
            sort: true,
        },
    },
];
function Index(props) {
    const { singleAssessmentData } = props;
    const isMobile = useMediaQuery("(max-width:960px)");
    const [userList, setUserList] = useState(singleAssessmentData?.managing_users);
    const [mcqList, setMcqList] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const currentDateTime = dayjs();
    useEffect(() => {
        getMcqQuestion();
    }, [props]);
    const getMcqQuestion = () => {
        const video_url = singleAssessmentData?.intro_outro_video_path ?? "";
        const videoArray = video_url?.split(",");
        setVideoUrls(videoArray);
        setUserList(singleAssessmentData?.managing_users);
        if (singleAssessmentData?.meta) {
            const mcqQuestion = singleAssessmentData ? singleAssessmentData.meta.filter((question) => question.question_category_id === 3) : [];
            setMcqList(mcqQuestion);
        }
    };
    const Tableoptions = {
        filter: false,
        responsive: "standard",
        download: false,
        sort: false,
        selectableRows: "none",
        print: false,
        viewColumns: false,
        searchOpen: false,
        searchText: "",
        search: false,
        selectableRowsHeader: false,
    };
    return (
        <Box sx={{ bgcolor: "#EDEDED" }}>
            <Container maxWidth="xl" sx={{ pb: isMobile ? "40px" : " " }}>
                <Grid container spacing={0}>
                    <Grid xs={12} md={12} sx={{ marginBottom: "50px" }}>
                        <Grid xs={12} md={12}>
                            <Item sx={{ boxShadow: 5, overflow: "hidden" }}>
                                <Accordion style={{ boxShadow: "none", border: "none" }} defaultExpanded>
                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid>
                                            <Typography variant="body1" fontWeight={600}>
                                                ADVANCED OPTIONS
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <ExpandMoreIcon />
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Accordion style={{ boxShadow: "none" }}>
                                            <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                                <Grid>
                                                    <Typography variant="body1" fontWeight={600}>
                                                        QUALIFYING QUESTION
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <ExpandMoreIcon />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Qualifying questions are given to candidates before they take the tests. These questions help determine if candidates meet the basic requirements
                                                    for the job. Candidates proceed to the tests only if they answer all questions correctly. You can add up to 5 qualifying questions, and you will
                                                    only be charged for candidates who pass these questions.
                                                </Typography>
                                                <Container maxWidth="xl" sx={{ mt: 2, paddingLeft: "0px !important" }}>
                                                    <QualifyingQuestion mcqList={mcqList} />
                                                </Container>
                                            </AccordionDetails>
                                        </Accordion>
                                        {/* <Accordion style={{ boxShadow: "none", border: "none" }}>
                                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                <Grid>
                                                    <Typography variant="body1" fontWeight={600}>
                                                        Manage Access
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <ExpandMoreIcon />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <MUIDataTable data={userList} columns={columns} options={Tableoptions} />
                                            </AccordionDetails>
                                        </Accordion> */}
                                        {/* {singleAssessmentData?.intro_outro_video_path && */}
                                        <Accordion style={{ boxShadow: "none" }}>
                                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                <Grid>
                                                    <Typography variant="body1" fontWeight={600}>
                                                        INTRO/OUTRO VIDEO
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <ExpandMoreIcon />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }}>
                                                    <Grid xs={12} md={6}>
                                                        <Grid sx={{ p: 5 }} className="VideoInput">
                                                            {videoUrls[0] && videoUrls[0] !== "null" ? (
                                                                <ReactPlayer width={"100%"} height="100%" url={videoUrls[0]} playing={true} muted={true} controls={true} />
                                                            ) : (
                                                                <Typography variant="h5" sx={{ fontWeight: 700, fontSize: "16px", textAlign: "center" }} className="video_not_found">
                                                                    No intro video for this assessment
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={12} md={6}>
                                                        <Grid sx={{ p: 5 }} className="VideoInput">
                                                            {videoUrls[1] && videoUrls[1] !== "null" ? (
                                                                <ReactPlayer width={"100%"} height="100%" url={videoUrls[1]} playing={true} muted={true} controls={true} />
                                                            ) : (
                                                                <Typography variant="h5" sx={{ fontWeight: 700, fontSize: "16px", textAlign: "center" }} className="video_not_found">
                                                                    No Outro Video for this assessment
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion style={{ boxShadow: "none" }}>
                                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                <Grid>
                                                    <Typography variant="body1" fontWeight={600}>
                                                        ANTI-CHEATING SETTINGS
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <ExpandMoreIcon />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid>
                                                    <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                        <FormControlLabel
                                                            sx={{ ml: 0, mr: 1 }}
                                                            control={<Checkbox name="is_anti_cheating_mode" checked={singleAssessmentData?.is_anti_cheating_mode?.is_snapshot_enable} />}
                                                            label="Candidate snapshots will be captured every 1 seconds using the webcam. "
                                                        />
                                                        <HelpOutlineIcon sx={{}} />
                                                    </Typography>
                                                    <Collapse in={singleAssessmentData?.is_anti_cheating_mode?.is_snapshot_enable} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 4, bgcolor: "#e0eff2" }}>
                                                            <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5 }}>
                                                                <Grid xs={12} md={6}>
                                                                    <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                                        <FormControlLabel
                                                                            sx={{ ml: 0, mr: 1 }}
                                                                            control={
                                                                                <Checkbox
                                                                                    name="is_mandatory_webcam"
                                                                                />
                                                                            }
                                                                            label={<span
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    display: "flex",

                                                                                }}
                                                                            >is it mandatory for candidate to Use Web Cam??</span>}
                                                                            checked={singleAssessmentData?.is_anti_cheating_mode?.is_mandatory_webcam}
                                                                        />
                                                                        <HelpOutlineIcon sx={{}} />
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Collapse>
                                                    <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                        <FormControlLabel
                                                            sx={{ ml: 0, mr: 1 }}
                                                            control={<Checkbox name="is_anti_cheating_mode" checked={singleAssessmentData?.is_anti_cheating_mode?.is_screenshot_enable} />}
                                                            label="Candidate Screen snapshots will be captured every 1 seconds."
                                                        />
                                                        <HelpOutlineIcon sx={{}} />
                                                    </Typography>
                                                    <Collapse in={singleAssessmentData?.is_anti_cheating_mode?.is_screenshot_enable} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 4, bgcolor: "#e0eff2" }}>
                                                            <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5 }}>
                                                                <Grid xs={12} md={6}>
                                                                    <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                                                        <FormControlLabel
                                                                            sx={{ ml: 0, mr: 1 }}
                                                                            control={
                                                                                <Checkbox
                                                                                    name="is_mandatory_screenrec"
                                                                                />
                                                                            }
                                                                            label={<span
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    display: "flex",

                                                                                }}
                                                                            >is it mandatory for candidate to Screen Record?</span>}
                                                                            checked={singleAssessmentData?.is_anti_cheating_mode?.is_mandatory_screenrec}
                                                                        />
                                                                        <HelpOutlineIcon sx={{}} />
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Collapse>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>

                                        {singleAssessmentData?.is_fluent_in_lang || singleAssessmentData?.is_disabled ? (
                                            <Accordion style={{ boxShadow: "none" }}>
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Grid>
                                                        <Typography variant="body1" fontWeight={600}>
                                                            EXTRA TIME FOR TEXTS
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <ExpandMoreIcon />
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography fontSize={"14px"}>Provide additional time for all candidates during their tests.</Typography>
                                                    <Typography fontSize={"14px"}>Extra time applies to all tests from the library, excluding custom questions.</Typography>

                                                    <ul
                                                        style={{
                                                            listStyleType: "disc",
                                                            paddingLeft: "20px",
                                                            lineHeight: 1.5,
                                                        }}
                                                    >
                                                        {singleAssessmentData?.is_fluent_in_lang ? <li>Not fluent in English + {singleAssessmentData?.extra_time_english + "%"}</li> : <></>}
                                                        {singleAssessmentData?.is_disabled ? <li>Granted to all candidates +{singleAssessmentData?.extra_time + "%"}</li> : <></>}
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : (
                                            " "
                                        )}
                                        <Accordion style={{ boxShadow: "none" }}>
                                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                <Grid>
                                                    <Typography variant="body1" fontWeight={600}>
                                                        ACCOMMODATION FOR CANDIDATES
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <ExpandMoreIcon />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid>
                                                    <Typography variant="body1">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={singleAssessmentData?.is_fluent_in_lang} {...label} />}
                                                            label="Non-fluent English speakers."
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={singleAssessmentData?.is_disabled} {...label} />}
                                                            label="Candidates with conditions that affect their concentration or memory capacity."
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <AllowedAccess permissions="set_deadline_my_assessment">
                                            <Accordion style={{ boxShadow: "none" }}>
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Grid>
                                                        <Typography variant="body1" fontWeight={600}>
                                                            DEADLINE
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <ExpandMoreIcon />
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body1" fontSize={"14px"}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={singleAssessmentData?.deadline === null ? "checked" : ""} disabled name="is_deadline_never_expire" {...label} />
                                                            }
                                                            label="Never Expire The Assessment"
                                                        />
                                                    </Typography>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            className="DateCalandera"
                                                            disabled
                                                            sx={{ mt: 2 }}
                                                            value={singleAssessmentData.deadline ? dayjs(singleAssessmentData.deadline) : ""}
                                                            minDateTime={currentDateTime}
                                                        />
                                                    </LocalizationProvider>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AllowedAccess>
                                    </AccordionDetails>
                                </Accordion>
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Index;
function QualifyingQuestion({ mcqList }) {
    return (
        <Grid>
            <Box sx={{ p: 2, boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)" }}>
                <Typography variant="body1" fontWeight={600}>
                    Questions
                </Typography>
            </Box>
            {mcqList.map((item, keys) => {
                return (
                    <Accordion key={keys} style={{ boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)", border: "none" }}>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <Grid xs={12} md={6}>
                                {/* showing question from question data */}
                                <Typography variant="body1" fontWeight={600} dangerouslySetInnerHTML={{ __html: item?.question_data?.question}}/> 
                                {/* <div dangerouslySetInnerHTML={{ __html: item.question}} /> */}
                                {/* </Typography> */}
                            </Grid>
                            <Grid xs={12} md={6} sx={{ display: "flex", justifyContent: "end",alignItems:"center" }}>
                                <ExpandMoreIcon />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4} columns={{ xs: 12, sm: 12, md: 12 }} sx={{ px: 5, py: 2 }}>
                                <Grid xs={12} md={6} sx={{mt:5}}> 
                                {/* showing question from question data */}
                                    <div dangerouslySetInnerHTML={{ __html: item?.question_data?.question }} /> 
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Grid sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
                                        <RadioGroup style={{ flexDirection: "column" }} row aria-label="radio-group-english" name="fluentEnglish">
                                            {/* map option with null check */}
                                            {item?.question_data?.mcqOptionsList.map((option, index) => (
                                                <Grid
                                                    key={index}
                                                    className={item.question_data.mcq_ans.split(",").includes(option.id.toString()) ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}
                                                    sx={{
                                                        my: 1,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        sx={{ width: "100%", boxShadow: "0 2px 8px 1px #a8a8a833" }}
                                                        value={option.id}
                                                        control={
                                                            <Checkbox
                                                                inputProps={{ "aria-label": "controlled" }}
                                                                icon={<RadioButtonUncheckedIcon sx={{ fontSize: "35px", color: "#ededed" }} />}
                                                                checkedIcon={<RadioButtonCheckedIcon sx={{ fontSize: "35px", color: "white" }} />}
                                                                checked={item.question_data.mcq_ans.split(",").includes(option.id.toString()) ? true : false}
                                                                disabled
                                                            ></Checkbox>
                                                        }
                                                        // checked={tr}
                                                        label={
                                                            <span
                                                                style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                <div dangerouslySetInnerHTML={{ __html: option.options }} />
                                                            </span>
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Grid>
    );
}
