import Grid from "@mui/material/Unstable_Grid2";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useMemo, useState } from "react";
import { APIProvider } from "../../../Components/Common/ApiProvider"
import { payStripeUrl } from "../../../Constants/ConstantsUrl";

export default function PricingComponent(props) {
    const { Item, plan, setPaymentLoader, currency, currencyList, setAlertNotification, cycle, selectedPlan, userPlan, setUserPlan } = props;
    const isMobile = useMediaQuery("(max-width:960px)");
    const contantHeight = isMobile ? "auto" : "894px";
    const BoxPadding = isMobile ? "36px" : "32px";

    const isSelectedPlan = useMemo(() => selectedPlan, [selectedPlan]);

    const pricings = useMemo(() => plan?.pricings, [plan]);
    const features = useMemo(() => plan?.plan_details_feature, [plan]);

    const [employeesKey, setEmployeesKey] = useState(pricings[0]?.id);
    const memoizedEmployeesKey = useMemo(() => employeesKey, [employeesKey]);

    const handelChangeEmployeeSize = (key) => {
        setEmployeesKey(key);
        getSelectedPrice();
    };

    const getSelectedPrice = () => {
        const price = pricings.find((pricing) => {
            return pricing.id === employeesKey
        });
        return price;
    }

    const selectedPricing = useMemo(() => {
        return getSelectedPrice();
    }, [employeesKey, pricings]);

    const selectedPlanIndex = useMemo(() => {
        if (isSelectedPlan) {
            const selectedIndex = pricings.find((pricing) => {
                return pricing.is_purchased === true
            })
            handelChangeEmployeeSize(selectedIndex?.id);
            return selectedIndex?.id;
        } else {
            return false;
        }
    }, [pricings, isSelectedPlan]);

    const selectedPrice = useMemo(() => {
        return selectedPricing?.pricings[currency];
    }, [selectedPricing, currency]);

    const getDisplayPrice = () => {
        let newPrice = selectedPrice;
        if (cycle?.billing_type_slug === "yearly") {
            newPrice = selectedPrice / 12;
        }
        let currencySymbol = currencyList[currency] || "";
        return `${currencySymbol}${newPrice?.toFixed(2) ?? 0}`;
    }

    const displayPrice = useMemo(() => getDisplayPrice(), [currency, selectedPrice]);

    const bulletStyle = {
        listStyleType: "none",
        paddingLeft: "1em",
    };

    const listItemStyle = {
        position: "relative",
        marginTop: "20px",
    };

    const bulletColorStyle = {
        position: "absolute",
        left: "-1em",
        color: "#44a997",
        fontWeight: 400,
        fontSize: "14px",
    };

    const btnStyle2 = {
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#44a997",
        color: "white",
        width: "100%",
        textTransform: "none",
        padding:"12px 30px",
        "&:disabled": {
            backgroundColor: "rgb(160 179 176)",
            color: "black"
        },
        "&:hover": {
            backgroundColor: "var(--button-hover-bg-color-LightGreen)",
            color: "white",
        },
    };

    const payViaStripe = async () => {
        setPaymentLoader(true);
        try {
            let data = {
                id: selectedPricing?.id,
                amount: selectedPrice,
                currency: currency || "USD",
                recurring: cycle?.billing_type_slug || "month",
            }

            setUserPlan({
                currency: currency,
            })

            await APIProvider(payStripeUrl, data, "post").then((response) => {
                if (response.status === 200) {
                    window.location.href = response?.data?.message;
                } else if (response?.status === 201) {
                    setAlertNotification({
                        status: true,
                        type: "success",
                        message: response?.data?.message,
                    });
                    window.location.reload();
                    setPaymentLoader(false);
                } else if (response?.status === 400) {                     
                    setAlertNotification({
                        status: true,
                        type: "error",
                        message: response?.data?.data?.message,
                    });
                    setPaymentLoader(false);
                }
            });
        } catch (error) {
            setAlertNotification({
                status: true,
                type: "error",
                message: error?.data?.message,
            });
            setPaymentLoader(false);
        }
    };

    const getBillingTerms = () => {
        let text;
        let currencySymbol = currencyList[currency] || "";
        let displayP = `${currencySymbol}${selectedPrice?.toFixed(2) ?? 0}`;

        if (cycle?.billing_type_slug === "yearly") {
            text = `Billed at ${displayP}/year`;
        } else if (cycle?.billing_type_slug === "lifetime") {
            text = `For lifetime`;
        } else if (cycle?.billing_type_slug === "pay_per_usage") {
            text = `Billed as per usage`;
        }

        return text;
    }

    const billingTerms = useMemo(() => getBillingTerms(), [selectedPrice, cycle, currency]);


    const btnText = useMemo(() => {
        if (userPlan?.currency) {
            if (userPlan?.is_upcoming === selectedPricing?.id) {
                return "Upcoming";
            }

            if (selectedPricing?.pricing > userPlan?.amount) {
                return "Upgrade Plan";
            } else if (selectedPricing?.pricing < userPlan?.amount) {
                return "Downgrade Plan";
            } else if (selectedPlanIndex === selectedPricing?.id) {
                return "Subscribed";
            }
        }
        return "Subcribe now"
    }, [selectedPrice, cycle, currency]);

    return (
        <Grid xs={12} md={3}>
            <Item
                sx={{ p: BoxPadding }}
                style={{
                    borderRadius: "8px",
                    height: contantHeight,
                    backgroundColor: isSelectedPlan ? "#276678" : "unset",
                    color: isSelectedPlan ? "white" : "black",
                    boxShadow:"rgba(16, 41, 48, 0.07) 0px 0px 12px 0px",
                    border:"2px solid #EDEDED"
                }} 
            >
                <Typography variant="h2" sx={{ fontWeight: 700, fontSize: "40px", minHeight:"100px" }}>
                    {plan.plan_name}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    {plan.plan_summary}
                </Typography>

                <Grid sx={{ padding: "10px 0px",}} xs={12} md={12}>
                    <Item
                        style={{
                            boxShadow: "unset",
                            borderRadius: "none",
                            background: "none",
                            padding: "0px",
                        }}
                    >
                        <TextField
                            id="outlined-select-theme"
                            style={{
                                color: isSelectedPlan ? "white" : "var(--text-color-black)",
                                width: "100%",
                                border: isSelectedPlan ? "1px solid white" : "1px solid var(--text-color-black)",
                                fill: isSelectedPlan ? "white" : "var(--text-color-black)",
                            }}
                            sx={
                                {
                                    "& #outlined-select-theme": {
                                        color: isSelectedPlan ? "white" : "var(--text-color-black)"
                                    },
                                    input: {
                                        color: isSelectedPlan ? "white" : "var(--text-color-black)"
                                    }
                                }
                            }
                            select
                            onChange={(e) => handelChangeEmployeeSize(e.target.value)}
                            value={memoizedEmployeesKey}
                        >
                            {pricings.map((pricing, key) => (
                                <MenuItem key={pricing.id} value={pricing.id}>
                                    {pricing.credits} Credits
                                </MenuItem>
                            ))}
                        </TextField>
                    </Item>
                </Grid>

                <Grid sx={{ display: "flex", gap: "10px" }}>
                    <Typography id="Secont-$" variant="h2" sx={{ mt: 2, fontWeight: 700, fontSize: "35px" }}>
                        {displayPrice}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        {currency} <br />
                        per month
                    </Typography>
                </Grid>

                <Typography variant="caption" sx={{ color: "var(--text-color-black)" }}>
                    <i>{billingTerms} </i>
                </Typography>

                <Stack sx={{ mt: 2 }}>
                    <Button
                        variant="text"
                        sx={btnStyle2}
                        // sx={price === 0 ? btnStyle : btnStyle2}
                        onClick={() => {
                            payViaStripe({ amount: selectedPrice, currency: currency });
                        }}
                        disabled={isSelectedPlan === true && selectedPlanIndex === selectedPricing?.id || userPlan?.user_disable == true ? true : false}
                    >
                        <Typography variant="h6" fontWeight={500} sx={{ textAlign: "center",fontSize:"14px" }}>
                            {/* {selectedPlanIndex === selectedPricing?.id ? "Subscribed" : "Subcribe now"} */}
                            {btnText}
                        </Typography>
                    </Button>
                </Stack>

                {/* <Typography variant="body1" sx={{ mt: 2 }}>
                    {oldPlanName !== "" ?
                        <>
                            All feature in the <strong>{oldPlanName}</strong> plan, plus:
                        </> : ""}
                </Typography> */}
                <Typography variant="body1" component="ul" style={bulletStyle} sx={{ mt: 2 }}>
                    {features?.map((feature, index) => (
                        <li key={index} style={listItemStyle}>
                            <strong style={bulletColorStyle}>•</strong> {feature}
                        </li>
                    ))}
                </Typography>
            </Item>
        </Grid>
    )
}