import { Grid, Box, Typography, MenuItem, Container, TextField, Button, Paper } from "@mui/material";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Select from "@mui/material/Select";
import { getHeaders, addMembers } from "../../../../Constants/ConstantsUrl";
import useMediaQuery from "@mui/material/useMediaQuery";

function AddMemberModal({ webRoleData, teamMember, setTeamMember, onAddRequestClose, setAlertNotification }) {
    const outerTheme = useTheme();
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
                components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "--TextField-brandBorderColor": "#0000001f",
                            "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                            "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                            "& label.Mui-focused": {
                                color: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: "var(--TextField-brandBorderColor)",
                        },
                        root: {
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "var(--TextField-brandBorderHoverColor)",
                                transition: "0.5s all",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                transition: "0.5s all",
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: "var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            "&::before, &::after": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            "&::before": {
                                borderBottom: "2px solid var(--TextField-brandBorderColor)",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                                borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                            },
                            "&.Mui-focused:after": {
                                borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                            },
                        },
                    },
                },
            },
        });

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        boxShadow: "none",
    }));

    const [moreAddOptions, setMoreAddOptions] = useState([
        {
            role_id: "5",
            email: "",
        },
    ]);

    const [errorMsg, setErrorMsg] = useState([
        {
            role_id: false,
            email: false,
        },
    ]);

    const handleInputChange = (event, index) => {
        // Copy the array using spread operator
        const updatedOptions = [...moreAddOptions];
        // Update the specific element in the array at the given index
        updatedOptions[index] = {
            ...updatedOptions[index],
            [event.target.name]: event.target.value,
        };
        // Update the state using the functional form of setMoreAddOptions
        setMoreAddOptions((prevOptions) => updatedOptions);
        const updatedfiledOptions = [...errorMsg];
        updatedfiledOptions[index] = {
            ...updatedfiledOptions[index],
            [event.target.name]: event.target.name ? false : true,
            exist_error:event.target.name ? false : true,
        };
        setErrorMsg((prevOptions) => updatedfiledOptions);
    };

    let validationCheck = (value) => {
        let data = false;
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
            data = true;
        }
        return data;
    };

    const handleSubmitadd = async () => {
        const emailValid = [];
        const updatedfiledOptions = [...errorMsg];
        moreAddOptions.map((data, index) => {
            if (data?.email !== "") {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    updatedfiledOptions[index] = {
                        ...updatedfiledOptions[index],
                        role_id: data?.role_id ? false : true,
                        email: true,
                    };
                } else {
                    emailValid.push(data.email);
                }
            }
            if (data?.role_id === "" || data?.email === "") {
                updatedfiledOptions[index] = {
                    ...updatedfiledOptions[index],
                    role_id: data?.role_id ? false : true,
                    email: data?.email ? validationCheck(data) : true,
                };
            }
        });
        setErrorMsg((prevOptions) => updatedfiledOptions);
        if (emailValid.length !== moreAddOptions.length) {
            return false;
        } else {
            submitFunction(moreAddOptions);
        }
    };

    const submitFunction = async (data) => {
        const dataSended = { user_details: data };
        try {
            const headers = getHeaders();
            await axios
                .post(addMembers, dataSended, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        const combinedData = [...teamMember, res.data.user_details[0]];
                        setTeamMember(combinedData);
                        setAlertNotification({
                            status: true,
                            type: "success",
                            message: res?.data?.message,
                        });
                        onAddRequestClose();
                    }
                });
        } catch (error) {
            const retundata = error.response.data.data;
            const createobj = Object.keys(retundata);
            const updatedfiledOptions = [...errorMsg];
            createobj?.map((data, indes) => {
                let numberIndex = data.split(".");
                updatedfiledOptions[numberIndex[1]] = {
                    ...updatedfiledOptions[numberIndex[1]],
                    email: retundata[data],
                    exist_error: true,
                };
            });
            setErrorMsg((prevOptions) => updatedfiledOptions);
        }
    };
    const handleAddOption = () => {
        if (moreAddOptions.length < 10) {
            setMoreAddOptions([
                ...moreAddOptions,
                {
                    role_id: "5",
                    email: "",
                },
            ]);
        }
    };

    const handleDeleteOption = (index) => {
        if (moreAddOptions.length > 1) {
            const updatedOptions = moreAddOptions.filter((_, i) => i !== index);
            setMoreAddOptions(updatedOptions);
        }
    };
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box sx={{ overflow: "auto" }} className="invite_popup_modal__backdrop">
            <Container
                maxWidth={"sm"}
                sx={{
                    p: 0,
                    borderRadius: "8px",
                    bgcolor: "white",
                    maxWidth: "100%",
                    m: "50px auto",
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={0} sx={{ mt: 1 }}>
                        <Grid xs={12} md={11}>
                            <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>Add new team members</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontWeight: 400, fontSize: "16px", color: "var(--text-color-Green)" }}>You can always add and edit team members in the settings.</Typography>
                        <Grid container spacing={0} sx={{ mt: 1 }}>
                            <Grid
                                sx={{
                                    maxHeight: "220px",
                                    overflowY: "auto",
                                    width: "100%",
                                    overflowX: "hidden",
                                }}
                            >
                                {moreAddOptions &&
                                    moreAddOptions.map((data, index) => (
                                        <>
                                            <Grid sx={{}} container spacing={0}>
                                                <Grid xs={11} md={11}>
                                                    <Grid
                                                        sx={{
                                                            mt: 2,
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                        className="text"
                                                    >
                                                        <Grid sx={{ position: "relative" }}>
                                                            <ThemeProvider theme={customTheme(outerTheme)}>
                                                                <TextField
                                                                 className="InviteFormWidth"
                                                                    fullWidth
                                                                    id="fullWidth"
                                                                    name="email"
                                                                    value={data?.email}
                                                                    error={errorMsg[index]?.email ? true : false}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    sx={{ width: "100%" }}
                                                                    label= {!isMobile ? "Teammate's work email" : "Teammate's email"}
                                                                />

                                                                <Select
                                                                    value={data?.role_id}
                                                                    name="role_id"
                                                                    defaultValue="5"
                                                                    IconComponent={ExpandMoreIcon}
                                                                    inputProps={{
                                                                        "aria-label": "Select option",
                                                                    }}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        right: "0px",
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        color: "var(--text-color-black)",
                                                                        boxShadow: "unset",
                                                                        padding: "0px 20px ",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "var(--button-bg-color-transparent)",
                                                                        fontSize: "14px",
                                                                        borderRadius: "0px",
                                                                        "&:hover": {
                                                                            backgroundColor: "#ededed",
                                                                        },
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            border: "none",
                                                                            borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                            borderRadius: "0px",
                                                                            "&:hover": {
                                                                                borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
                                                                                borderRadius: "0px",
                                                                            },
                                                                        },
                                                                    }}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                >
                                                                    {webRoleData?.map((val, index) => (
                                                                        <MenuItem key={index} value={val.id}>
                                                                            {val.title}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </ThemeProvider>
                                                        </Grid>
                                                    </Grid>
                                                    {errorMsg[index]?.email ? (
                                                        errorMsg[index]?.email === true ? (
                                                            <Grid className="error_class">
                                                                <div className="error_class_font_color">{errorMsg[index]?.exist_error ? "Email has already been taken" : "Email is Invalid"}</div>
                                                            </Grid>
                                                        ) : (
                                                            <div className="error_class_font_color">
                                                            {errorMsg[index]?.email}
                                                            </div>
                                                        )
                                                    ) : null}
                                                </Grid>
                                                <Grid
                                                 item
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    xs={1}
                                                    md={1}
                                                >
                                                    <Button sx={{"&:hover":{ background:"unset"} }} fullWidth id="fullWidth" onClick={() => handleDeleteOption(index)}>
                                                        {" "}
                                                        <CloseIcon sx={{ color: "var(--text-color-black)", mt:"12px" }} />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                            </Grid>
                            <Grid
                                sx={{
                                    mt: 5,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        textTransform: "none",
                                        fontSize: "16px",
                                        display: "contents",
                                        color: "var(--text-color-Green)",
                                        cursor: "pointer",
                                    }}
                                    variant="body1"
                                    onClick={handleAddOption}
                                >
                                    {
                                        <AddCircleOutlineIcon
                                            sx={{
                                                color: "var(--text-color-Green)",
                                                fontSize: "27px",
                                                mr: 1,
                                            }}
                                        ></AddCircleOutlineIcon>
                                    }
                                    Add more
                                </Typography>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <Item
                                        sx={{
                                            boxShadow: "none",
                                            background: "none",
                                        }}
                                    >
                                        {" "}
                                        <Grid container spacing={0}>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    justifyContent: "end",
                                                }}
                                                xs={12}
                                                md={12}
                                            >
                                                <Button
                                                    sx={{
                                                        color: "var(--text-color-black)",
                                                        bgcolor: "#ededed",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "none",
                                                        boxShadow: "none",
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "#e0e0e0",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={onAddRequestClose}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    sx={{
                                                        bgcolor: "var(--text-color-Green)",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "capitalize",
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                                        },
                                                    }}
                                                    variant="contained"
                                                    onClick={handleSubmitadd}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default AddMemberModal;
