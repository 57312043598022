import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";




export default function TransitionsModal({setOpen, open}) {
  const isSmMobile = useMediaQuery("(max-width:610px)");
  const isMobile = useMediaQuery("(max-width:750px)");
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:  isSmMobile? 400: 600,
    bgcolor: 'background.paper', 
    boxShadow: "0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f",
    p: 4,
    borderRadius:"8px",
  };
  return (
    <div> 
      <Modal 
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="RemoveOutLine"
					sx={{ backdropFilter: "blur(4px)" }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid sx={{px:"20px"}}>
            <Typography variant="h3"  sx={{fontSize:"16px", fontWeight:700, mb:3, mt:2}}>
            Anti-cheating measures
            </Typography>
            <Typography variant="h4"  sx={{ mt: 2, fontWeight:500, fontSize:"15px" }}>
            Device and Location Tracking:
            </Typography>
            <Typography variant="h4"  sx={{ mt: 1, fontWeight:400, fontSize:"14px" }}>
            We record the type of device used for the assessment and the candidate's geographic location based on their IP address.
            </Typography>
           
            <Typography variant="h4"  sx={{ mt: 2, fontWeight:500, fontSize:"15px" }}>
            One Attempt per IP Address:
            </Typography>
            <Typography variant="h4"  sx={{ mt: 1, fontWeight:400, fontSize:"14px" }}>
            Using the candidate's IP address, we ensure that they can fill out the assessment only once. This prevents candidates from attempting the assessment multiple times using different email addresses.
            </Typography>
           
            <Typography variant="h4"  sx={{ mt: 2, fontWeight:500, fontSize:"15px" }}>
            Webcam/Camera Activation:
            </Typography>
            <Typography variant="h4"  sx={{ mt: 1, fontWeight:400, fontSize:"14px" }}>
            Candidates are required to activate their webcam/front camera when starting the assessment. We capture images of the candidate every 30 seconds to verify that only one person is taking the assessment.
            </Typography>
           
            <Typography variant="h4"  sx={{ mt: 2, fontWeight:500, fontSize:"15px" }}>
            Full-Screen Mode Requirement:
            </Typography>
            <Typography variant="h4"  sx={{ mt: 1, fontWeight:400, fontSize:"14px" }}>
            For candidates using a desktop or laptop, we enforce full-screen mode to prevent browsing the internet for answers. While candidates can deactivate full-screen mode, we can detect if they do so, which indicates a potential violation.
            </Typography>
           
            <Typography variant="h4"  sx={{ mt: 2, fontWeight:500, fontSize:"15px" }}>
            Mouse Activity Monitoring:
            </Typography>
            <Typography variant="h4"  sx={{ mt: 1, fontWeight:400, fontSize:"14px" }}>
            We monitor mouse activity to ensure that it remains within the assessment window. This prevents candidates with multiple screens from using another window to browse the internet.
            </Typography>
            </Grid>
            <Grid container spacing={0}>
                                                                        <Grid
                                                                            item
                                                                            sx={{
                                                                                display: "flex", 
                                                                                justifyContent: "end",
                                                                                mt:5
                                                                            }}
                                                                            xs={12}
                                                                            md={12}
                                                                        >
                                                                            <Button
                                                                                sx={{
                                                                                    color: "var(--text-color-black)",
                                                                                    bgcolor: "#ededed",
                                                                                    minWidth: "48px",
                                                                                    minHeight: "48px",
                                                                                    textTransform: "none",
                                                                                    boxShadow: "none",

                                                                                    "&:hover": {
                                                                                        bgcolor: "#e0e0e0",
                                                                                        boxShadow: "none",
                                                                                    },
                                                                                }}
                                                                                variant="contained"
                                                                                onClick={handleClose}
                                                                            >
                                                                                Close
                                                                            </Button>

                                                                           
                                                                        </Grid>
                                                                    </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
