// CodeEditorWindow.js
import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ name, onChange, language, code, theme, fontSizeValuePass, sx={} }) => {
    const [value, setValue] = useState(code || "");

    const defaultCode = {
        javascript: "// Your JavaScript code here",
        cpp: "// Your c++ code here",
        java: "//java code here",
        python: "# Your Python code here",
        php: "<?php \n //your php code is here \n?>",
        html: "<!-- Your HTML code here -->",
        // Add more languages as needed
    };
    const fontSizeValue = fontSizeValuePass === "" || fontSizeValuePass === undefined ? 20 : fontSizeValuePass;
    useEffect(() => {
          setValue(code || "");
    }, [language, code, value, name]);

    const handleEditorChange = (newValue) => {
        setValue(newValue);
        onChange(newValue);
    };
    return (
        <Editor
            height="500px"
            language={language.toString().toLowerCase() || "javascript"}
            options={{
                fontSize: fontSizeValue,
                ...sx
            }}
            value={value}
            theme={theme || "vs-dark"} // Default theme: vs-dark
            defaultValue={defaultCode[language.toString().toLowerCase()] || ""}
            onChange={handleEditorChange}
        />
    );
};

export default CodeEditorWindow;
