import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Autocomplete, Container, Typography, useMediaQuery } from "@mui/material";
import { getHeaders,AssessmentEssentialsData } from "../../../../../Constants/ConstantsUrl";
import PropTypes from "prop-types";
import axios from "axios";
import Loader from "../../../../Common/Loader";
import TextField from "@mui/material/TextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, Theme, useTheme } from "@mui/material/styles";



const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f",
                        "--TextField-brandBorderHoverColor": "var(--text-color-Green)",
                        "--TextField-brandBorderFocusedColor": "var(--text-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                            transition: "0.5s all",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            transition: "0.5s all",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });
const NameAssessment = (props) => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const FormPAdding = isMobile ? "" : "24px";
    const { handleChange, errorMessage, setErrorMessage, formData, setFormData, loader, handleNext } = props;
    const [language, setLanguage] = useState([]);
    const [workarrangement, setWorkArrangement] = useState([]);
    const [jobRole, setJobRole] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const characterCount = inputValue.length;
    const [apiloader, setAPILoader] = useState(false);
    const outerTheme = useTheme();
    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value.length <= 256) {
            setInputValue(value);
            handleChange(event);
        } else {
            setErrorMessage({ ...errorMessage, name_of_assessment: "Title should be less than 256 characters" });
        }
    };

    useEffect(() => {
        getAssessmentEssentials();
    }, []);

    // Now Only Calling a single API instead of three different API
    const getAssessmentEssentials = async () => {
        setAPILoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(AssessmentEssentialsData, { headers });
            const languages = response.data.data.language;
            setLanguage(languages); // Set the languages first
            setJobRole(response.data.data.job_role);
            setWorkArrangement(response.data.data.work_arrangement);

            // Check if the lang_id is already set in formData, if not, find and set the English language
            setFormData(prevFormData => {
                if (prevFormData.lang_id) {
                    // If lang_id already exists, return previous formData without changes
                    return prevFormData;
                } else {
                    // Find the English language and update the formData with its ID
                    const englishLanguage = languages.find(lang => lang.name === 'English');
                    return {
                        ...prevFormData,
                        lang_id: englishLanguage ? englishLanguage.id : prevFormData.lang_id,
                    };
                }
            });

            setAPILoader(false);
        } catch (error) {
            console.error("Error fetching languages:", error);
            setAPILoader(false);
        }
    };

    const handleJobRoleChange = (event, value) => {
        if (value === null) {
            setErrorMessage({
                ...errorMessage,
                job_role_id: "Job Role selection Neccesssary",
            });
        } else {
            setErrorMessage({ ...errorMessage, job_role_id: "" });
            setFormData({ ...formData, job_role_id: value?.id });
        }
    };

    const handleWorkArrangementChnage = (event, value) => {
        if (value === null) {
            setErrorMessage({
                ...errorMessage,
                work_arrangement_id: "Work Arrangement selection Neccesssary",
            });
        } else {
            setErrorMessage({ ...errorMessage, work_arrangement_id: "" });
            setFormData({ ...formData, work_arrangement_id: value?.id });
        }
    };

    const handleLanguageChange = (event, value) => {
        if (value === null) {
            setErrorMessage({
                ...errorMessage,
                lang_id: "Language selection Neccesssary",
            });
        } else {
            setErrorMessage({ ...errorMessage, lang_id: "" });
            setFormData({
                ...formData,
                lang_id: value?.id,
            });
        }
    };
    return (
        <Container maxWidth="xl" sx={{ pb: 10 }}>
            {loader || apiloader ? (
                <Loader />
            ) : (
                <Grid>
                    <Box sx={{ flexGrow: 1, }}>
                        <Grid container spacing={0}>
                            <Grid sx={{ pr: FormPAdding, mt: 5 }} xs={12} md={6}>
                                <Box
                                    component="form"
                                    sx={{
                                        "& > :not(style)": {},
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleNext()
                                    }}
                                >
                                    <HelpOutlineIcon />
                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <TextField
                                            sx={{
                                                ml: 1,
                                                width: "100%",
                                                "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: "4px",
                                                    borderColor: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : " ",
                                                    transition: "green 0.3s ease-in-out",
                                                    "&:hover": {
                                                        borderColor: "var(--text-color-Green)",
                                                    },
                                                },
                                                "& #combo-box-demo": {
                                                    color: "var(--text-color-Green)",
                                                },
                                                "&:focus-within .MuiInputLabel-root": {
                                                    color: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : "var(--text-color-Green)",
                                                },
                                            }}
                                            id="outlined-basic"
                                            label="Name your assessment"
                                            variant="outlined"
                                            name="name_of_assessment"
                                            onChange={handleInputChange}
                                            value={formData.name_of_assessment}
                                        />
                                    </ThemeProvider>
                                </Box>
                                <Grid container spacing={0} columns={16} sx={{ pl: "30px" }}>
                                    <Grid xs={8}>{errorMessage?.name_of_assessment && <span className="error_msg_style">{errorMessage?.name_of_assessment}</span>}</Grid>
                                    <Grid xs={8}>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                fontSize: 16,
                                                color: "gray",
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            {characterCount}/256
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{ mt: 5, pl: FormPAdding }} xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <HelpOutlineIcon />
                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={language}
                                            getOptionLabel={(option) => option.name ?? "select language"}
                                            onChange={handleLanguageChange}
                                            value={language?.find((role) => role.id === formData.lang_id) || null}
                                            sx={{
                                                ml: 1,
                                                width: "100%",
                                                "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: "4px",
                                                    borderColor: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : " ",
                                                    transition: "green 0.3s ease-in-out",
                                                    "&:hover": {
                                                        borderColor: "var(--text-color-Green)",
                                                    },
                                                },
                                                "& #combo-box-demo": {
                                                    color: "var(--text-color-black)",
                                                },
                                                "&:focus-within .MuiInputLabel-root": {
                                                    color: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : "var(--text-color-Green)",
                                                },
                                            }}
                                            renderInput={(params) => <TextField name="lang_id" {...params} label="Language of assessment" />}
                                        />
                                    </ThemeProvider>
                                </Box>
                                <Box sx={{ pl: "30px" }}>{errorMessage?.lang_id && <span className="error_msg_style">{errorMessage?.lang_id}</span>}</Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0}>
                            <Grid sx={{ pr: FormPAdding, mt: 5 }} xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <HelpOutlineIcon />
                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={jobRole}
                                            value={jobRole?.find((role) => role.id === formData.job_role_id) || null}
                                            getOptionLabel={(option) => option.title.replace(/&amp;/g, '&') ?? "select Job Role"}
                                            onChange={handleJobRoleChange}
                                            name="job_role_id"
                                            sx={{
                                                ml: 1,
                                                width: "100%",
                                                "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: "4px",
                                                    borderColor: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : " ",
                                                    transition: "green 0.3s ease-in-out",
                                                    "&:hover": {
                                                        borderColor: "var(--text-color-Green)",
                                                    },
                                                },
                                                "& #combo-box-demo": {
                                                    color: "var(--text-color-black)",
                                                },
                                                "&:focus-within .MuiInputLabel-root": {
                                                    color: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : "var(--text-color-Green)",
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Job role" />}
                                        />
                                    </ThemeProvider>
                                </Box>
                                <Box sx={{ pl: "30px" }}>{errorMessage?.job_role_id && <span className="error_msg_style">{errorMessage?.job_role_id}</span>}</Box>
                            </Grid>
                            <Grid sx={{ mt: 5, pl: FormPAdding }} xs={12} md={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <HelpOutlineIcon />
                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={workarrangement}
                                            value={workarrangement?.find((work) => work.id === formData.work_arrangement_id) || null}
                                            getOptionLabel={(option) => option.title ?? "select Any Work Arrangment"}
                                            onChange={handleWorkArrangementChnage}
                                            name="work_arrangement_id"
                                            sx={{
                                                ml: 1,
                                                width: "100%",
                                                "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: "4px",
                                                    borderColor: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : " ",
                                                    transition: "green 0.3s ease-in-out",
                                                    "&:hover": {
                                                        borderColor: "var(--text-color-Green)",
                                                    },
                                                },
                                                "& #combo-box-demo": {
                                                    color: "var(--text-color-black)",
                                                },
                                                "&:focus-within .MuiInputLabel-root": {
                                                    color: errorMessage?.name_of_assessment ? "rgb(211, 47, 47)" : "var(--text-color-Green)",
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Work arrangement" />}
                                        />
                                    </ThemeProvider>
                                </Box>
                                <Box sx={{ pl: "30px" }}>{errorMessage?.work_arrangement_id && <span className="error_msg_style">{errorMessage?.work_arrangement_id}</span>}</Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </Container>
    );
};

NameAssessment.prototype = {
    handleChange: PropTypes.func,
};

export default NameAssessment;
