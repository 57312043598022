import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, IconButton, Typography } from "@mui/material";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import QuestionModal from "../../../../Common/QuestionModal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getProgrammingLanguages, getHeaders } from "../../../../../Constants/ConstantsUrl";
import axios from "axios";
import Loader from "../../../../Common/Loader";
import LangSelectCodeModal from "../LangSelectCodeModal";
import StepTwoSkeleton from "../../../../Common/StepTwoSkeleton";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

export default function MainSelectQuestion({ updateMainTest, selectedTests, handleRemoveTest, morethen5Error, questionList, innerref, isFetchingNextPage, hasNextPage, status }) {
    const isMobile = useMediaQuery("(max-width:960px)");
    const BoxPadding = isMobile ? "16px" : "24px";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCodeModalOpen, setCodeModelOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalLoader, setModalLoader] = useState(true);
    const [allProgrammingLang, setAllProgrammingLang] = useState([""]);
    const [programmingSelected, setProgrammingSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedoption, setSelectedOption] = useState();
    const [languageError, setLanguageError] = useState(false);
    const [toActiveSwitchButton, setToactiveSwitchButton] = useState(false);
    const [optionForQModal, setOptionForQModal] = useState();
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeCodeModal = () => {
        setCodeModelOpen(false);
        setProgrammingSelected([]);
        setSelectAll(false);
        setLanguageError(false);
    };
    function openCodeModel(option) {
        setSelectedOption(option);
        setCodeModelOpen(true);
    }

    function openQuestionModal(option, id) {
        setModalData({ id });
        setOptionForQModal(option);
        setIsModalOpen(true);
    }
    useEffect(() => {
        if (isCodeModalOpen === true) {
            fetchDataL();
        }
    }, [isCodeModalOpen]);

    const fetchDataL = async () => {
        setModalLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(getProgrammingLanguages, {
                headers,
            });
            const res = response.data;
            if (res.status) {
                setAllProgrammingLang(res.data);
                setModalLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setModalLoader(false);
        }
    };

    const handelProgrammingCheckbox = (e, id) => {
        if (id === 0) {
            setSelectAll(!selectAll);
            setProgrammingSelected((prevSelected) => {
                if (!selectAll) {
                    const allLangIds = allProgrammingLang.map((lang) => lang.id).filter((langId) => langId !== 0);
                    return allLangIds;
                } else {
                    return [];
                }
            });
        } else {
            setProgrammingSelected((prevSelected) => {
                if (prevSelected.includes(id)) {
                    return prevSelected.filter((selectedId) => selectedId !== id);
                } else {
                    return [...prevSelected, id];
                }
            });
            const allLanguagesSelected = programmingSelected.length + 1 === allProgrammingLang.length;
            setSelectAll(allLanguagesSelected);
        }
    };
    const handelSubmit = async () => {
        if (programmingSelected.length === 0) {
            setLanguageError(true);
            return false;
        }
        let prog = programmingSelected.join(",");
        updateMainTest(selectedoption.question_title, selectedoption.id, selectedoption.time, selectedoption.question_type_id, prog, toActiveSwitchButton);
        setCodeModelOpen(false);
        setToactiveSwitchButton(false);
        setProgrammingSelected([]);
        setSelectAll(false);
        setLanguageError(false);
    };
    const isTestSelected = (option) => {
        return Array.isArray(selectedTests) && selectedTests.some((selectedTest) => selectedTest.question_id === option.id);
    };
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#c8d7de",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#c8d7de",
        },
    }));
    const openPreview = (qTime) => {
        window.open(`/customer/preview-questions/${qTime}`, "_blank");
    };
    return (
        <Grid
            sx={{
                bgcolor: "#e0eff2",
                borderRadius: "12px",
            }}
        >
            <Container maxWidth="xl" sx={{ px: 2 }}>
                <Box sx={{ p: BoxPadding, mb: 5 }}>
                    <Typography variant="h5" sx={{ fontSize: "22px", fontWeight: 700, py: 1 }}>
                        Test N Hire Official Question
                    </Typography>
                    {(!isFetchingNextPage || questionList.length > 0) && (
                        <Grid container sx={{ mt: "10px" }} spacing={2}>
                            <>
                                {questionList.length > 0 ? (
                                    <>
                                        {questionList.map((option, index) => (
                                            <Grid key={option.id} xs={12} lg={4} md={6} sm={6} ref={questionList.length === index + 1 ? innerref : null}>
                                                <Item
                                                    style={{
                                                        borderRadius: "8px",
                                                        padding: "0px",
                                                        boxShadow:
                                                            "rgba(136, 136, 136, 0.06) 0px 6px 7px -4px, rgba(136, 136, 136, 0.043) 0px 11px 15px 1px, rgba(136, 136, 136, 0.035) 0px 4px 20px 3px",
                                                    }}
                                                >
                                                    <Grid sx={{ bgcolor: "var(--button-bg-color-green)", borderRadius: "8px 8px 0px 0px", p: "20px" }}>
                                                        <Grid style={{ padding: "0px" }}>
                                                            <Typography
                                                                sx={{
                                                                    overflow: "hidden",
                                                                    maxWidth: "100%",
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    fontSize: "18px",
                                                                    fontWeight: 700,
                                                                    // WebkitLineClamp: 1,
                                                                    color: "white",
                                                                    // height: "50px",
                                                                }}
                                                                variant="h2"
                                                            >
                                                                <BootstrapTooltip title={option.question_title}>
                                                                    {option.question_title.length > 20 ? option.question_title.substring(0, 20) + "..." : option.question_title}
                                                                </BootstrapTooltip>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid sx={{ p: "16px 24px" }}>
                                                        <Typography
                                                            style={{
                                                                padding: "0px",
                                                                height: "80px",
                                                                overflow: "hidden",
                                                                maxWidth: "100%",
                                                                display: "-webkit-box",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 4,
                                                                fontSize: "14px",
                                                                marginTop: "8px",
                                                            }}
                                                            variant="body1"
                                                        >
                                                            {option.summary}
                                                        </Typography>
                                                        <Grid sx={{ p: 0, mt: 2 }} container spacing={0}>
                                                            <Grid xs={8} md={8}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px",
                                                                    }}
                                                                >
                                                                    {
                                                                        <IconButton className="BtnBackground" sx={{ pl: 0 }} color="var(--text-color-black)">
                                                                            <AccessAlarmOutlinedIcon sx={{ color: "var(--text-color-black)" }} />
                                                                        </IconButton>
                                                                    }
                                                                    <Typography style={{}} variant="subtitle2">
                                                                        {option.time} min
                                                                    </Typography>
                                                                </Item>
                                                            </Grid>
                                                            <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} xs={4} md={4}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0px",
                                                                    }}
                                                                >
                                                                    <Typography style={{}} variant="subtitle2">
                                                                        {option.question_level}
                                                                    </Typography>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid sx={{ p: 0, mt: 1 }} container spacing={0}>
                                                            <Grid xs={8} md={8}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",
                                                                        padding: "10px",
                                                                        padding: "0px",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {
                                                                            <IconButton className="BtnBackground" sx={{ pl: 0 }}>
                                                                                <BootstrapTooltip title="Preview" style={{}}>
                                                                                    <RemoveRedEyeOutlinedIcon
                                                                                        sx={{
                                                                                            backgroundColor: "#ededed",
                                                                                            borderRadius: "5px",
                                                                                            padding: "5px",
                                                                                            fontSize: "35px",
                                                                                            color: "var(--text-color-black)",
                                                                                        }}
                                                                                        onClick={() => openPreview(option.id)}
                                                                                    />
                                                                                </BootstrapTooltip>
                                                                            </IconButton>
                                                                        }
                                                                        <Button
                                                                            style={{
                                                                                //   border: "1px solid var(--text-color-black)",
                                                                                backgroundColor: "#ededed",
                                                                                color: "var(--text-color-black)",
                                                                                textTransform: "capitalize",
                                                                                padding: "8px",
                                                                            }}
                                                                            onClick={() => openQuestionModal(option, option.id)}
                                                                        >
                                                                            Details
                                                                        </Button>
                                                                    </div>
                                                                </Item>
                                                            </Grid>

                                                            <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} xs={4} md={4}>
                                                                <Item
                                                                    style={{
                                                                        boxShadow: "unset",
                                                                        borderRadius: "none",
                                                                        background: "none",

                                                                        padding: "0px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        sx={{
                                                                            color: "var(--text-color-OffWhite)",
                                                                            backgroundColor: isTestSelected(option) ? "#cf1c1c" : "var(--button-bg-color-green)",
                                                                            ":hover": {
                                                                                backgroundColor: isTestSelected(option) ? "#cf1c1c" : "var(--button-hover-bg-color-LightGreen)",
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            if (option.question_type_id === 4) {
                                                                                isTestSelected(option) ? handleRemoveTest(option) : openCodeModel(option);
                                                                            } else {
                                                                                isTestSelected(option)
                                                                                    ? handleRemoveTest(option)
                                                                                    : updateMainTest(option.question_title, option.id, option.time, option.question_type_id, null, null);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {isTestSelected(option) ? <CloseIcon sx={{ height: "25px", width: "25px" }} /> : "Add"}
                                                                    </Button>
                                                                </Item>
                                                            </Grid>
                                                            {morethen5Error.msg !== null && morethen5Error.id === option.id && (
                                                                <Grid xs={12}>
                                                                    <Item
                                                                        style={{
                                                                            boxShadow: "unset",
                                                                            borderRadius: "none",
                                                                            background: "none",
                                                                            padding: "10px",
                                                                            display: "flex",
                                                                            justifyContent: "end",
                                                                            color: "red",
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        <div className="error_div">{morethen5Error.msg}</div>
                                                                    </Item>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        ))}
                                    </>
                                ) : ( //fixing the No Question Found text when loading
                                    <>
                                        {(!isFetchingNextPage && status === "pending") ? "" :
                                            <Typography variant="text">No Question Found</Typography>
                                        }
                                    </>
                                )}
                                {/* this loader is showing below  the exist cards*/}
                                {isFetchingNextPage && (
                                    <Grid container sx={{ mt: 3, gap: "16px" }} spacing={2}>
                                        {" "}
                                        {[1, 2, 3, 4, 5, 6].map((key) => (
                                            <StepTwoSkeleton key={key} />
                                        ))}
                                    </Grid>
                                )}
                            </>
                        </Grid>
                    )}
                    {!isFetchingNextPage && status === "pending" && (
                        <Grid container sx={{ mt: 3, gap: "16px" }} spacing={2}>
                            {[1, 2, 3, 4, 5, 6].map((key) => (
                                <StepTwoSkeleton key={key} />
                            ))}
                        </Grid>
                    )}
                </Box>

                {isModalOpen && (
                    <QuestionModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        ids={modalData}
                        setIsModalOpen={setIsModalOpen}
                        isTestSelected={isTestSelected}
                        fromCall={true}
                        optionForQModal={optionForQModal}
                        updateMainTest={updateMainTest}
                        handleRemoveTest={handleRemoveTest}
                        morethen5Error={morethen5Error}
                    />
                )}
                {isCodeModalOpen && (
                    <LangSelectCodeModal
                        fromCall={true}
                        setToactiveSwitchButton={setToactiveSwitchButton}
                        closeCodeModal={closeCodeModal}
                        allProgrammingLang={allProgrammingLang}
                        handelProgrammingCheckbox={handelProgrammingCheckbox}
                        programmingSelected={programmingSelected}
                        handelSubmit={handelSubmit}
                        modalLoader={modalLoader}
                        selectAll={selectAll}
                        errormsg={languageError}
                    />
                )}
            </Container>
        </Grid>
    );
}
