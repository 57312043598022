import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Container, Typography, Box, Paper, Grid } from "@mui/material";
import DataTable from "../Step4/DataTable";
import ConfirmModal from "../../../../Common/ConfirmModal";
import EditPopupModel from "../Step2/EditPopupModel";
import { getCustomQuestionById, getHeaders } from "../../../../../Constants/ConstantsUrl";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
}));

export default function AddCustomQuestions({ setSelectedQuestion, selectedQuestion }) {
    const moveRow = (fromIndex, toIndex) => {
        const updatedRows = [...selectedQuestion];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        setSelectedQuestion(updatedRows);
    };

    const [extraQuestionBox, setExtraQuestionBox] = useState(0);
    const [options, setOptions] = useState(["", "", "", ""]);
    const [open, setOpen] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [openPopupModal, setOpenPopupModal] = useState(false);
    const [optionForConfirm, setOptionForConfirm] = useState();
    const [mcqAnswer, setMcqAnswer] = useState({});
    const [errorMsg, setErrorMsg] = useState([]);
    const [formData, setFormData] = useState({
        test_out: "",
        test_other: "",
        no_of_test_cases: "",
        test_cases: "",
        show_test_cases: "",
        pro_lang_id: "",
        language_id: "",
        time: "",
        question: "",
        question_title: "",
        question_cat_id: "",
        what_to_look_for_ans: "",
        why_is_the_ques_rel: "",
        mcq_options_id: [{}, {}],
    });
    const handleRemoveItem = (key, row) => {
        setOptionForConfirm(row);
        setOpen(true);
    };
    const handleConfirm = (row) => {
        const newData = selectedQuestion.filter(item => item.key !== row.key);
        setSelectedQuestion(newData);
        setOpen(false);
    };

    const handleEditItem = async (index, row) => {
        if (row.type === "Video") {
            setExtraQuestionBox(1);
        } else if (row.type === "Essay") {
            setExtraQuestionBox(2);
        } else if (row.type === "Mcq") {
            setExtraQuestionBox(3);
        } else if (row.type === "File Upload") {
            setExtraQuestionBox(4);
        } else if (row.type === "Code") {
            setExtraQuestionBox(5);
        }
        const headers = getHeaders();
        await axios.get(getCustomQuestionById + "?custom_main_question_id=" + row.question_id + "&question_type_id=" + row.question_type_id, {
            headers,
        })
            .then((response) => response)
            .then((response) => {
                if (response.data.status === 200) {
                    setCurrentData(response.data.data.custom_question);
                    setOptions(response.data.data.custom_question.question_data.mcq_options);
                    const mcq_ans = response.data.data.custom_question.question_data.mcq_ans;
                    if (mcq_ans) {
                        const mcq_ans_res = mcq_ans.split(',');
                        setMcqAnswer(mcq_ans_res);
                    }
                    setOpenPopupModal(true);
                }
            });
    }

    const editPopupClose = () => {
        setOpenPopupModal(false);
    }

    function handleEditorChange(name, value) {
        //setErrorMsg({ ...errorMsg, [name]: "" });
        //setFormData({ ...formData, [name]: value });
    }


    return (
        <Box sx={{ mt: 5 }}>
            {openPopupModal &&
                <EditPopupModel
                    open={openPopupModal}
                    setOpen={setOpenPopupModal}
                    onClose={editPopupClose}
                    formData={currentData}
                    extraQuestionBox={extraQuestionBox}
                    handleEditorChange={handleEditorChange}
                    options={options}
                    answer={mcqAnswer}
                />}
            {open && <ConfirmModal open={open} setOpen={setOpen} handleConfirm={handleConfirm} data={optionForConfirm} setData={setOptionForConfirm} from={"Custom"} />}
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item sx={{ bgcolor: "#e0eff2" }} xs={12} md={12}>
                        <Item
                            style={{
                                boxShadow: "unset",
                                borderRadius: "none",
                                background: "none",
                                padding: "2px",
                            }}
                        >
                            <Typography padding={"8px"} variant="body1">
                                Your assessment can contain up to five tests. Browse the test library and add the most relevant ones.
                                <span style={{ textDecoration: "underline" }}>Utilize various types of tests for optimal results</span> .
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl">
                <Box sx={{ flexGrow: 1, mt: 5, boxShadow: 3 }}>
                    <Grid columns={{ xs: 12, sm: 6, md: 6 }}>
                        <DataTable combineQuestion={selectedQuestion} moveRow={moveRow} fromCall={3} handleRemoveItem={handleRemoveItem} handleEditItem={handleEditItem} />
                    </Grid>
                </Box>
            </Container>
            <Grid
                sx={{
                    width: "fullWidth",
                    mt: 5,
                    bgcolor: "var(--button-bg-color-green)",
                }}
            ></Grid>
        </Box>
    );
}
