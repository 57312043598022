import React, { useState, useEffect, useRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { Button, Container, Typography, Paper, Grid, Box, Stepper, Step, StepLabel, useMediaQuery, Snackbar } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AlarmIcon from "@mui/icons-material/Alarm";
import NameAssessment from "./Step1/NameAssessments";
import SelectTest from "./Step2/SelectTests";
import AddCustomQuestions from "./Step3/AddCustomQuestions";
import QuestionPreviewTest from "../MyAssessment/QuestionPreview/Index"
import {
    assessmentCreateDataStepOne,
    assessmentCreateDataStepTwo,
    getHeaders,
    assessmentUpdateDataStepOne,
    assessmentUpdateDataStepTwo,
    getCustomAllQuestion,
    getCustomFilterQuestion,
    assessmentCreateDataStepTwoVideo,
    assessmentGetDataStepOne,
    assessmentGetDataStepTwo,
    getFileHeaders,
} from "../../../../Constants/ConstantsUrl";
import ReviewSubmit from "./Step4/ReviewSubmit";
import axios from "axios";
import AddCustomQuestionsSelect from "./Step3/AddCustomQuestionsSelect";
import { useNavigate, useLocation, json } from "react-router-dom";
import dayjs from "dayjs";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PageNotFound from "../../403/AccessDenided";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';






const queryClient = new QueryClient();
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));

const btnStyle = {
    color: "var(--text-color-OffWhite)",
    boxShadow: "unset",
};
const file_extensions = {
    0: "mpeg",
    1: "mp4",
    2: "avi",
    3: "quicktime",
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Index() {
    // const isMobile1 = useMediaQuery("(max-width:960px)");
    
    // const TextMargin = isMobile1 ? "" : "20px";

    const [previewTestsOpen, setPreviewTestsOpen] = React.useState(false);
    const handlePreviewOpen = () => setPreviewTestsOpen(true);
    const handlePreviewClose = () => setPreviewTestsOpen(false);


    const navigte = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [assessmentId, setAssessmentId] = useState("");
    const [preDefineQuestion, setPreDefineQuestion] = useState([]);
    const [combineQuestion, setCombineQuestion] = useState([]);
    const [customSelected, setCustomSelected] = useState([]);
    const [qualifyingQuestion, setQualifyingQuestion] = useState([]);
    const [countQuestion, SetCountQuestion] = useState(0);
    const [timeQuestion, SetTimeQuestion] = useState(0);
    const [errorMessage, setErrorMessage] = useState();
    const [customQuestionList, setCustomQuestionList] = useState([]);
    const [customQuestionListCopy, setCustomQuestionListCopy] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [introVideoUrl, setIntroVideoUrl] = useState("");
    const [outroVideoUrl, setOutroVideoUrl] = useState("");
    const [videoUploading, setVideoUploading] = useState(false);
    const [introVideoUploading, setIntroVideoUploading] = useState(false);
    const [outroVideoUploading, setOutroVideoUploading] = useState(false);
    const [customQuestionCount, setCustomQuestionCount] = useState([]);
    const [loader, setLoader] = useState(false);
    const [fourSetpSubmit, setFourSetpSubmit] = useState(false);
    const [questionType, setQuestionType] = useState({});
    const [customQuestionTypeCount, setCustomQuestionTypeCount] = useState({});
    // const [introFileExtension, setIntroFileExtension] = useState();
    // const [outroFileExtension, setOutroFileExtension] = useState();
    const inputRef = useRef();
    const [introSource, setIntroSource] = useState(null);
    const isMobile = useMediaQuery("(max-width:960px)");
    const BtnPosition = isMobile ? "end" : "end";
    const [outroSource, setOutroSource] = useState(null);
    const [introFileName, setintroFileName] = useState();
    const [outroFileName, setoutroFileName] = useState();
    const [outroFileNameError, setoutroFileNameError] = useState(false);
    const [introFileNameError, setintroFileNameError] = useState(false);
    const [customqLoader, setCustomqLoader] = useState(false);
    const [formFinalData, setFormFinalData] = useState({
        manage_access: "",
        qualifying_question_id: "",
        intro_outro_video_path: "",
        extra_time: "0",
        is_anti_cheating_mode: {
            is_snapshot_enable: false,
            is_screenshot_enable: false,
            is_mandatory_webcam: false,
            is_mandatory_screenrec: false
        },
        is_result_shown: false,
        deadline: "",
        id: id,
        is_fluent_in_lang: false,
        is_disabled: false,
        extra_time_english: "0",
    });

    useEffect(() => {
        if (id) {
            fetchAssessmentData(id);
        }
    }, []);

    const steps = ["Name assessments", "Select tests", "Add questions", "Review & configures"];
    const moveRow = (fromIndex, toIndex) => {
        const updatedRows = [...combineQuestion];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        setCombineQuestion(updatedRows);
    };
    const [formData, setFormData] = useState({
        id: 0,
        name_of_assessment: "",
        lang_id: "",
        job_role_id: "",
        work_arrangement_id: "",
    });
    const fetchAssessmentData = async (id) => {
        setLoader(true);
        try {
            const headers = getHeaders();
            const response = await axios.get(assessmentGetDataStepOne + id, {
                headers,
            });
            const res = response.data;
            if (res.status === 200) {
                setFormData({
                    ...formData,
                    id: res.data[0].id,
                    name_of_assessment: res.data[0].name_of_assessment,
                    job_role_id: res.data[0].job_role_id,
                    lang_id: res.data[0].lang_id,
                    work_arrangement_id: res.data[0].work_arrangement_id,
                });
                setAssessmentId({
                    ...assessmentId,
                    id: res.data[0].id,
                });
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        }

        try {
            const headers = getHeaders();
            const response = await axios.get(assessmentGetDataStepTwo + id, {
                headers,
            });
            const res = response.data;
            if (res.status === 200) {
                setActiveStep(res.data.steps);
                SetCountQuestion(res.data.meta.length);
                const total_time = res.data.meta.reduce((acc, question) => acc + question.time, 0);
                SetTimeQuestion(total_time);
                setFormFinalData({
                    ...formFinalData,
                    manage_access: res.data.manage_access !== null ? res.data.manage_access : "",
                    intro_outro_video_path: res.data.intro_outro_video_path !== null ? res.data.intro_outro_video_path : "",
                    is_anti_cheating_mode: {
                        is_screenshot_enable: res.data?.is_anti_cheating_mode?.is_screenshot_enable || false,
                        is_snapshot_enable: res.data?.is_anti_cheating_mode?.is_snapshot_enable || false,
                        is_mandatory_webcam: res.data?.is_anti_cheating_mode?.is_mandatory_webcam || false,
                        is_mandatory_screenrec: res.data?.is_anti_cheating_mode?.is_mandatory_screenrec || false
                    },
                    is_result_shown: res.data.is_result_shown !== null ? (res.data.is_result_shown === 1 ? true : false) : false,
                    deadline: res.data.deadline !== null ? dayjs(res.data.deadline) : "",
                    is_disabled: res?.data?.is_disabled ?? false,
                    extra_time: res?.data?.extra_time ?? "",
                    is_fluent_in_lang: res?.data?.is_fluent_in_lang ?? false,
                    extra_time_english: res?.data?.extra_time_english ?? "",
                });
                setPreDefineQuestion(res.data.meta.filter((item) => item.question_category_id === 1));
                setCustomSelected(res.data.meta.filter((item) => item.question_category_id === 2));
                setQualifyingQuestion(res.data.meta.filter((item) => item.question_category_id === 3));
                setCombineQuestion(res.data.meta.filter((item) => item.question_category_id !== 3));//not include qualifying question bcz it already merging at last step
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        }
    };

    const handleRemoveItem = (index, rowData) => {
        if (rowData.question_category_id === 1) {
            const updatedTests = preDefineQuestion.filter((selectedTest) => selectedTest.id !== rowData.id);
            setPreDefineQuestion(updatedTests);
        } else if (rowData.question_category_id === 2) {
            const updatedCustomTests = customSelected.filter((selectedTest) => selectedTest.id !== rowData.id);
            setCustomSelected(updatedCustomTests);
        } else if (rowData.question_category_id === 3) {
            const updatedQualifyTests = qualifyingQuestion.filter((selectedTest) => selectedTest.id !== rowData.id);
            setQualifyingQuestion(updatedQualifyTests);
        }
        const newArray = [...combineQuestion.slice(0, index), ...combineQuestion.slice(index + 1)];
        setCombineQuestion(newArray);
        SetCountQuestion(newArray.length);
        SetTimeQuestion(newArray.reduce((sum, item) => sum + parseInt(item.time), 0));
    };
    const handleOnChange = (event) => {
        if (event.target.name === "name_of_assessment") {
            setErrorMessage({ ...errorMessage, name_of_assessment: "" });
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value.trimStart(),
        });
    };

    const handleOnChangeFinal = (event) => {
        setFormFinalData({
            ...formFinalData,
            [event.target.name]: event.target.value,
        });
    };

    const handleOnChangeFinalCheckbox = (event) => {
        if (event.target.name === "is_screenshot_enable" || event.target.name === "is_snapshot_enable" || event.target.name === "is_mandatory_webcam" || event.target.name === "is_mandatory_screenrec") {
            const namess = event.target.name;
            const setData = { ...formFinalData };
            if (namess === "is_screenshot_enable" && event.target.checked === false) {
                setData["is_anti_cheating_mode"]["is_mandatory_screenrec"] = false;
            }
            if (namess === "is_snapshot_enable" && event.target.checked === false) {
                setData["is_anti_cheating_mode"]["is_mandatory_webcam"] = false;
            }
            setData["is_anti_cheating_mode"][namess] = event.target.checked;
            setFormFinalData(setData);
        } else {
            setFormFinalData({
                ...formFinalData,
                [event.target.name]: event.target.checked,
            });
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const [finalCode, setFinalCode] = useState([]);
    async function handleNext() {
        if (activeStep === 0) {
            if (formData?.name_of_assessment.trimStart() === "" || formData?.job_role_id === "" || formData?.lang_id === "" || formData.work_arrangement_id === "") {
                setErrorMessage({
                    ...errorMessage,
                    name_of_assessment: formData?.name_of_assessment.trimStart() === "" ? "Please fill above field" : "",
                    job_role_id: formData?.job_role_id === "" ? "Please select Job Role" : "",
                    lang_id: formData?.lang_id === "" ? "Please select Language" : "",
                    work_arrangement_id: formData?.work_arrangement_id === "" ? "Please select WorkArrangement" : "",
                });
                return false;
            }
            createAssessment();
        } else if (activeStep === 1) {
            if (preDefineQuestion.length !== 0) {
                //SetCountQuestion(preDefineQuestion.length);
                setFinalCode(preDefineQuestion);
                //SetTimeQuestion(preDefineQuestion.reduce((sum, item) => sum + parseInt(item.time), 0));
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 2) {
            const combineQuestionDataWithoutQualifying = [...preDefineQuestion, ...customSelected];
            setCombineQuestion(combineQuestionDataWithoutQualifying);
            //SetCountQuestion(combineQuestionDataWithoutQualifying.length);
            //SetTimeQuestion(combineQuestionDataWithoutQualifying.reduce((sum, item) => sum + parseInt(item.time), 0));
            setFinalCode(combineQuestionDataWithoutQualifying);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 3) {
            const combineQuestionDataWithQualifying = [...combineQuestion, ...qualifyingQuestion];
            setCombineQuestion(combineQuestionDataWithQualifying);
            setFinalCode(combineQuestionDataWithQualifying);
            createAssessmentStepTwo(combineQuestionDataWithQualifying, activeStep);
        }
    }
    async function createAssessment() {
        setLoader(true);
        if (assessmentId?.id) {
            try {
                if (
                    formData?.name_of_assessment !== assessmentId?.name_of_assessment ||
                    formData?.lang_id !== assessmentId?.lang_id ||
                    formData?.job_role_id !== assessmentId?.job_role_id ||
                    formData?.work_arrangement_id !== assessmentId?.work_arrangement_id
                ) {
                    const headers = getHeaders();
                    const response = await axios.post(assessmentUpdateDataStepOne, formData, {
                        headers,
                    });
                    if (response.status) {
                        setAssessmentId({
                            ...assessmentId,
                            id: formData.id,
                            job_role_id: formData.job_role_id,
                            lang_id: formData.lang_id,
                            name_of_assessment: formData.name_of_assessment,
                            work_arrangement_id: formData.work_arrangement_id,
                        });
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setLoader(false);
                    }
                    setLoader(false);
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.error("Error fetching data:", error);
            }
        } else {
            try {
                const headers = getHeaders();
                formData['steps'] = 0;
                const response = await axios.post(assessmentCreateDataStepOne, formData, {
                    headers,
                });
                const res = response.data;
                if (response.status === 200) {
                    setAssessmentId({
                        ...assessmentId,
                        id: res.data.id,
                        job_role_id: res.data.job_role_id,
                        lang_id: res.data.lang_id,
                        name_of_assessment: res.data.name_of_assessment,
                        work_arrangement_id: res.data.work_arrangement_id,
                    });
                    setFormData({ ...formData, id: res.data.id });
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.error("Error fetching data:", error);
            }
        }
    }

    async function createAssessmentStepTwo(combineQuestionDataWithQualifying, steps) {
        setLoader(true);
        setFourSetpSubmit(true);
        if (id === null || id === undefined) {
            try {
                if (combineQuestionDataWithQualifying.length === 0) {
                    setErrorMessage({
                        ...errorMessage,
                        question_required: "Question Required!!",
                    });
                    setNotificationOpen(true);
                    setTimeout(() => {
                        setErrorMessage({ ...errorMessage, question_required: "" });
                    }, 2000);
                    setLoader(false);
                    setFourSetpSubmit(false);
                    return false;
                }
                let data = {
                    assessment_id: assessmentId.id,
                    questions: combineQuestionDataWithQualifying,
                    is_anti_cheating_mode: JSON.stringify({
                        is_screenshot_enable: formFinalData?.is_anti_cheating_mode?.is_screenshot_enable || false,
                        is_snapshot_enable: formFinalData?.is_anti_cheating_mode?.is_snapshot_enable || false,
                        is_mandatory_webcam: formFinalData?.is_anti_cheating_mode?.is_mandatory_webcam || false,
                        is_mandatory_screenrec: formFinalData?.is_anti_cheating_mode?.is_mandatory_screenrec || false,
                    }),
                    is_result_shown: formFinalData?.is_result_shown,
                    is_fluent_in_lang: formFinalData?.is_fluent_in_lang,
                    extra_time_english: formFinalData?.extra_time_english,
                    is_disabled: formFinalData?.is_disabled,
                    extra_time: formFinalData?.extra_time,
                };
                if (formFinalData?.manage_access !== "") {
                    data["manage_access"] = formFinalData?.manage_access;
                }
                if (formFinalData?.qualifying_question_id !== "") {
                    data["qualifying_question_id"] = formFinalData?.qualifying_question_id;
                }
                if (formFinalData?.deadline !== "") {
                    // if(currentDate.format('YYYY-MM-DD') === formFinalData?.deadline.format('YYYY-MM-DD')){
                    //     data["deadline"] = dayjs(currentDate.add(1, 'month')).format("YYYY-MM-DD HH:mm:ss");
                    // }else{
                    data["deadline"] = dayjs(formFinalData.deadline.$d).format("YYYY-MM-DD HH:mm:ss");
                    //     }
                }
                data["intro_outro_video_path"] = "";
                data["total_time"] = timeQuestion;
                data["steps"] = steps;
                const headers = getHeaders();
                let introVideoName = introVideoUrl;
                let outroVideoName = outroVideoUrl;

                if (introVideoName && outroVideoName) {
                    data["intro_outro_video_path"] = introVideoName + "," + outroVideoName;
                } else if (introVideoName) {
                    data["intro_outro_video_path"] = introVideoName + ",null";
                } else if (outroVideoName) {
                    data["intro_outro_video_path"] = "null," + outroVideoName;
                }

                await axios
                    .post(assessmentCreateDataStepTwo, data, { headers })
                    .then((response) => response)
                    .then((response) => {
                        setFourSetpSubmit(false);
                        const res = response.data;
                        if (res.status === 200) {
                            setLoader(false);
                            localStorage.setItem("invitedModal", true);
                            navigte("/customer/invite?id=" + res.data.assessment_id, {
                                state: { finished: true },
                            });
                        }
                    });
            } catch (error) {
                setLoader(false);
                setFourSetpSubmit(false);
                console.error("Error fetching data:", error);
            }
        } else {
            try {
                if (combineQuestionDataWithQualifying.length === 0) {
                    setErrorMessage({
                        ...errorMessage,
                        question_required: "Question Required!!",
                    });
                    setTimeout(() => {
                        setErrorMessage({ ...errorMessage, question_required: "" });
                    }, 2000);
                    setFourSetpSubmit(false);
                    setLoader(false);
                    return false;
                }
                let data = {
                    assessment_id: assessmentId.id,
                    questions: combineQuestionDataWithQualifying,
                    is_anti_cheating_mode: JSON.stringify({
                        is_screenshot_enable: formFinalData?.is_anti_cheating_mode?.is_screenshot_enable || false,
                        is_snapshot_enable: formFinalData?.is_anti_cheating_mode?.is_snapshot_enable || false,
                        is_mandatory_webcam: formFinalData?.is_anti_cheating_mode?.is_mandatory_webcam || false,
                        is_mandatory_screenrec: formFinalData?.is_anti_cheating_mode?.is_mandatory_screenrec || false,
                    }),
                    is_result_shown: formFinalData?.is_result_shown,
                    is_fluent_in_lang: formFinalData?.is_fluent_in_lang,
                    extra_time_english: formFinalData?.extra_time_english,
                    is_disabled: formFinalData?.is_disabled,
                    extra_time: formFinalData?.extra_time,
                };
                if (formFinalData?.manage_access !== "") {
                    data["manage_access"] = formFinalData?.manage_access;
                }
                if (formFinalData?.qualifying_question_id !== "") {
                    data["qualifying_question_id"] = formFinalData?.qualifying_question_id;
                }
                if (formFinalData?.deadline !== "") {
                    data["deadline"] = formFinalData.deadline !== null ? dayjs(formFinalData.deadline.$d).format("YYYY-MM-DD HH:mm:ss") : "";
                }
                data["intro_outro_video_path"] = "";
                data["total_time"] = timeQuestion;
                const headers = getHeaders();
                //let customdata = "";
                let introVideoName = introVideoUrl;
                let outroVideoName = outroVideoUrl;

                const videoData = formFinalData?.intro_outro_video_path?.split(",");
                if (introVideoName && outroVideoName) {
                    data["intro_outro_video_path"] = introVideoName + "," + outroVideoName;
                } else if (introVideoName) {
                    data["intro_outro_video_path"] = introVideoName + ",null";
                    if (typeof videoData[1] !== "undefined" && videoData[1] !== "null") {
                        data["intro_outro_video_path"] = introVideoName + "," + videoData[1];
                    }
                } else if (outroVideoName) {
                    data["intro_outro_video_path"] = "null," + outroVideoName;
                    if (typeof videoData[0] !== "undefined" && videoData[0] !== "null" && videoData[0] !== "") {
                        data["intro_outro_video_path"] = videoData[0] + "," + outroVideoName;
                    }
                }
                data['steps'] = steps;
                await axios
                    .post(assessmentUpdateDataStepTwo, data, { headers })
                    .then((response) => response)
                    .then((response) => {
                        setFourSetpSubmit(false);
                        const res = response.data;
                        if (res.status === 200) {
                            setLoader(false);
                            navigte("/customer/invite?id=" + res.data.assessment_id);
                        }
                    });
            } catch (error) {
                setLoader(false);
                setFourSetpSubmit(false);
                console.error("Error fetching data:", error);
            }
        }
    }
    const handleReloadPage = () => {
        if (activeStep === 1) {
            setFinalCode(preDefineQuestion);
            if (preDefineQuestion.length > 0) {
                createAssessmentStepTwo(preDefineQuestion, activeStep);
            } else {
                navigte("/customer/assessment");
            }
        } else if (activeStep === 2) {
            const combineQuestionDataWithoutQualifying = [...preDefineQuestion, ...customSelected];
            createAssessmentStepTwo(combineQuestionDataWithoutQualifying, activeStep);
            setFinalCode(combineQuestionDataWithoutQualifying);
        } else if (activeStep === 3) {
            const combineQuestionDataWithQualifying = [...combineQuestion, ...qualifyingQuestion];
            setFinalCode(combineQuestionDataWithQualifying);
            createAssessmentStepTwo(combineQuestionDataWithQualifying, activeStep);
        } else{
            navigte("/customer/assessment"); //for exit on activestep 0
        }

    };


    const searchCustomHandleFun = (e) => {
        setSearchedData(e.target.value);
        const eventValue = e.target.value;
        if (eventValue === "") {
            setCustomQuestionList(customQuestionListCopy);
            setCustomQuestionCount(customQuestionListCopy.length);
            return false;
        } else {
            const filteredArray = customQuestionListCopy.filter(
                (item) => item.question.toLowerCase().includes(eventValue.toLowerCase()) || item.title.toLowerCase().includes(eventValue.toLowerCase())
            );
            setCustomQuestionList(filteredArray);
            setCustomQuestionCount(filteredArray.length);
        }
    };

    const fetchCustomQuestionFilterData = async (data) => {
        setCustomqLoader(true);
        try {
            const queryString = Object.entries(data)
                .map(([key, value]) => `question_types[]=${key}`)
                .join("&");
            const headers = getHeaders();
            await axios
                .get(getCustomFilterQuestion + "?" + queryString, {
                    headers,
                })
                .then((response) => response)
                .then((response) => {
                    const res = response.data;
                    if (res.status === 200) {
                        setCustomQuestionList(res.data.question);
                        setCustomQuestionCount(res.data.question.length);
                        setCustomQuestionTypeCount(res.data.counts);
                        setCustomqLoader(false);
                    }
                });
        } catch (error) {
            console.error("Error fetching data:", error);
            setCustomqLoader(false);
        }
    };

    const handelQuestionType = (e, index) => {
        let senddata = "";
        if (e.target.checked) {
            const setData = { ...questionType };
            setData[index] = index;
            senddata = setData;
            setQuestionType(setData);
        } else {
            const updatedData = Object.keys(questionType)
                .filter((key) => key !== index)
                .reduce((obj, key) => {
                    obj[key] = questionType[key];
                    return obj;
                }, {});
            setQuestionType(updatedData);
            senddata = updatedData;
        }
        fetchCustomQuestionFilterData(senddata);
    };

    const handleDeadlineChange = (data) => {
        setFormFinalData({ ...formFinalData, deadline: data });
    };

    const nextAnyPoint = (index) => {
        if (activeStep === 0) {
            if (formData?.name_of_assessment.trimStart() === "" || formData?.job_role_id === "" || formData?.lang_id === "" || formData?.work_arrangement_id === "") {
                setErrorMessage({
                    ...errorMessage,
                    name_of_assessment: formData?.name_of_assessment.trimStart() === "" ? "Please fill above field" : "",
                    job_role_id: formData?.job_role_id === "" ? "Please select Job Role" : "",
                    lang_id: formData?.lang_id === "" ? "Please select Language" : "",
                    work_arrangement_id: formData?.work_arrangement_id === "" ? "Please select Work Arrangement" : "",
                });
                return false;
            }
            createAssessment();
        } else {
            setActiveStep((prevActiveStep) => index);
        }
    };

    const handleIntroFileChange = async (event) => {
        setintroFileNameError(false);
        const file = event.target.files[0];
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        if (file.size > maxSize) {
            setintroFileNameError(true);
            setintroFileName("File size exceeds the maximum limit of 25 MB.");
            console.log("File size exceeds the maximum limit");
            return false;
        }
        const answer_array = file?.type.split("/");
        const isMp3Present = Object.values(file_extensions).includes(answer_array[1]);
        if (isMp3Present === false) {
            setintroFileNameError(true);
            setintroFileName(answer_array[1] + " file extension not allowed! ");
            console.log("file does not allowed");
            return false;
        }
        setVideoUploading(true);
        setIntroVideoUploading(true);
        setIntroSource(file);
        setintroFileName(file.name);
        try {
            const formData = new FormData();
            const headers = getFileHeaders();
            formData.append("assessment_id", assessmentId.id);
            formData.append("video", file);
            await axios
                .post(assessmentCreateDataStepTwoVideo, formData, { headers })
                .then((response1) => response1)
                .then((response1) => {
                    const customdata = response1.data;
                    setVideoUploading(false);
                    setIntroVideoUploading(false);
                    if (customdata.status === 200) {
                        setIntroVideoUrl(customdata.data.video_path);
                    }
                });
            // setIntroFileExtension(answer_array[1]);
        } catch (error) {
            console.log(error);
        }
    };

    const handleIntroChoose = (event) => {
        inputRef.current.click();
    };

    const handleOutroFileChange = async (event) => {
        setoutroFileNameError(false);
        const file = event.target.files[0];
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        if (file.size > maxSize) {
            setoutroFileNameError(true);
            setoutroFileName("File size exceeds the maximum limit of 25 MB.");
            console.log("File size exceeds the maximum limit");
            return false;
        }
        const answer_array = file?.type.split("/");
        const isMp3Present = Object.values(file_extensions).includes(answer_array[1]);
        if (isMp3Present === false) {
            setoutroFileNameError(true);
            setoutroFileName(answer_array[1] + " file extension not allowed! ");
            console.log("file does not allowed");
            return false;
        }
        setVideoUploading(true);
        setOutroVideoUploading(true);
        setOutroSource(file);
        // setOutroFileExtension(answer_array[1]);
        setoutroFileName(file.name);
        try {
            const formData = new FormData();
            const headers = getFileHeaders();
            formData.append("assessment_id", assessmentId.id);
            formData.append("video", file);
            await axios
                .post(assessmentCreateDataStepTwoVideo, formData, { headers })
                .then((response1) => response1)
                .then((response1) => {
                    const customdata = response1.data;
                    setVideoUploading(false);
                    setOutroVideoUploading(false);
                    if (customdata.status === 200) {
                        //outroVideoName = customdata.data.video_path;
                        setOutroVideoUrl(customdata.data.video_path);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleOutroChoose = (event) => {
        inputRef.current.click();
    };
    useEffect(() => {
        if (Object.keys(questionType).length == 0) {
            fetchCustomQuestionFilterData({});
        }
    }, [questionType]);

    const stepContent = [
        <NameAssessment loader={loader} handleChange={handleOnChange} setErrorMessage={setErrorMessage} errorMessage={errorMessage} formData={formData} setFormData={setFormData} />,
        <QueryClientProvider client={queryClient}>
            <SelectTest
                mainQuestionValue={preDefineQuestion}
                setMainQuestionValue={setPreDefineQuestion}
                SetCountQuestion={SetCountQuestion}
                countQuestion={countQuestion}
                SetTimeQuestion={SetTimeQuestion}
                timeQuestion={timeQuestion}
            />
        </QueryClientProvider>,
        <>
            <AddCustomQuestions setSelectedQuestion={setCustomSelected} selectedQuestion={customSelected} />
            <AddCustomQuestionsSelect
                searchedData={searchedData}
                searchCustomHandleFun={searchCustomHandleFun}
                handelQuestionType={handelQuestionType}
                customQuestionList={customQuestionList}
                customQuestionTypeCount={customQuestionTypeCount}
                CustomqLoader={customqLoader}
                customQuestionCount={customQuestionCount}
                setSelectedQuestion={setCustomSelected}
                selectedQuestion={customSelected}
                language_id={formData.lang_id}
                SetCountQuestion={SetCountQuestion}
                countQuestion={countQuestion}
                SetTimeQuestion={SetTimeQuestion}
                timeQuestion={timeQuestion}
                setQuestionType={setQuestionType}
                setSearchedData={setSearchedData}
                questionType={questionType}
            />
        </>,
        <ReviewSubmit
            setQualifyingQuestion={setQualifyingQuestion}
            qualifyingQuestion={qualifyingQuestion}
            language_id={formData.lang_id}
            combineQuestion={combineQuestion}
            setCombineQuestion={setCombineQuestion}
            moveRow={moveRow}
            handleRemoveItem={handleRemoveItem}
            formFinalData={formFinalData}
            setFormFinalData={handleOnChangeFinalCheckbox}
            setText={handleOnChangeFinal}
            setDataTable={setFormFinalData}
            handleDeadlineChange={handleDeadlineChange}
            handleIntroChoose={handleIntroChoose}
            handleIntroFileChange={handleIntroFileChange}
            introSource={introSource}
            handleOutroChoose={handleOutroChoose}
            handleOutroFileChange={handleOutroFileChange}
            outroSource={outroSource}
            introFileName={introFileName}
            outroFileName={outroFileName}
            introFileNameError={introFileNameError}
            outroFileNameError={outroFileNameError}
            loaderstepfour={fourSetpSubmit}
            loader={loader}
            introVideoUploading={introVideoUploading}
            outroVideoUploading={outroVideoUploading}
        />,
    ];
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#c8d7de",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#c8d7de",
        },
    }));
    return (
        <Box>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Create Assessment - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Grid style={{ borderBottom: "1px solid #E0E0E0", position: "fixed", top: 0, width: "100%", zIndex: 9, background: "white" }}>
                <Container maxWidth="xxl">
                    <Box sx={{ flexGrow: 1, padding: "8px 16px" }}>
                        <Grid container spacing={0}>
                            <Grid item sx={{ display: "flex" }} xs={8} md={6}>
                                {/* <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        display: "flex",
                                    }}
                                >
                                    {" "}
                                    <Button
                                        sx={{
                                            bgcolor: "#ededed",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            m: 1,
                                            borderRadius: "4px",
                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            "&:hover": {
                                                bgcolor: "#ededed",
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={handleReloadPage}
                                    >
                                        {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}
                                    </Button>
                                </Item>   */}

                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                        Start a New Assessment
                                    </Typography>
                                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                                        <BootstrapTooltip arrow title={formData.name_of_assessment === "" ? "Untitled assessment" : formData.name_of_assessment}>
                                            <Typography variant="text" sx={{ fontSize: !isMobile ? "14px" : "12px" }}>
                                                {formData.name_of_assessment === ""
                                                    ? "Untitled assessment"
                                                    : formData.name_of_assessment.length > 20
                                                        ? formData.name_of_assessment.substring(0, 20) + "..."
                                                        : formData.name_of_assessment}
                                            </Typography>
                                        </BootstrapTooltip>
                                        <Typography
                                            sx={{
                                                marginLeft: "20px",
                                                fontSize: !isMobile ? "14px" : "12px",
                                                display: "flex",
                                            }}
                                            variant="text"
                                        >
                                            {<TextSnippetOutlinedIcon sx={{ height: "15px" }}></TextSnippetOutlinedIcon>}
                                            {countQuestion} tests
                                        </Typography>
                                        <Typography
                                            sx={{
                                                marginLeft: "20px",
                                                fontSize: !isMobile ? "14px" : "12px",
                                                display: "flex",
                                            }}
                                            variant="text"
                                        >
                                            {<AlarmIcon sx={{ height: "15px" }}></AlarmIcon>}
                                            {timeQuestion} min
                                        </Typography>
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid item xs={4} md={6}>
                                <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    {" "}
                                  {preDefineQuestion.length>0 ? <Button
                                        sx={{
                                            bgcolor: "unset",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            m: 1,
                                            border:"none",
                                            textTransform: "capitalize",
                                            padding: "10px 30px",
                                            "&:hover": {
                                                bgcolor: "#ededed",
                                                border:"none",
                                            },
                                        }}
                                        variant="outlined"
                                        onClick={handlePreviewOpen}
                                    >
                                        {/* {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}*/}
                                        <RemoveRedEyeOutlinedIcon sx={{color:"var(--button-bg-color-green)",mr:1}}/>
                                        <Typography variant="h6" sx={{color:"var(--button-bg-color-green)", fontSize:"16px", fontWeight:"500"}}>Preview tests</Typography>
                                    </Button>:"" }
                                    {previewTestsOpen && <QuestionPreviewTest handleClose={handlePreviewClose} open={previewTestsOpen} preDefineQuestion={preDefineQuestion}/>}
                                    <Button
                                        sx={{
                                            bgcolor: "#ededed",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            m: 1,
                                            borderRadius: "4px",
                                            color: "var(--text-color-black)",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            padding: "10px 30px",
                                            "&:hover": {
                                                bgcolor: "#e0e0e0",
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={handleReloadPage}
                                    >
                                        {/* {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}*/}
                                        {activeStep > 0 ? "Save and exit" : "Exit"}
                                    </Button>
                                </Item>
                                {/* <Item
                                    style={{
                                        boxShadow: "unset",
                                        borderRadius: "none",
                                        background: "none",
                                        display: "flex",
                                        justifyContent: BtnPosition,
                                    }}
                                >
                                    {" "}
                                    {activeStep > 0 && (
                                        <Button
                                            sx={{
                                                bgcolor: "#ededed",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                mx: 1,
                                                borderRadius: "4px",
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                "&:hover": {
                                                    boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                    bgcolor: "#ededed",
                                                },
                                            }}
                                            variant="contained"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>}
                                        </Button>
                                    )}
                                    <Button
                                        sx={{
                                            ...btnStyle,
                                            bgcolor: "var(--button-bg-color-green)",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            textTransform: "capitalize",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                bgcolor: "var(--button-bg-color-green)",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={() => handleNext()}
                                        disabled={videoUploading}
                                    >
                                        <Typography variant="h6" fontWeight={500} sx={{ fontSize: "14px" }}>
                                            {activeStep === steps.length - 1 ? (fourSetpSubmit ? "Submitting..." : videoUploading ? "video uploading..." : "Finish") : "Next Step"}
                                        </Typography>
                                    </Button>
                                </Item> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Grid>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    zIndex: 999,
                    width: "100%",
                    background: "white",
                    p: "8px 16px",
                    boxShadow: "0 -2px 1px -1px #8883,0 -1px 1px #88888824,0 -1px 3px #8888881f",
                }}
            >
                <Container maxWidth="xxl">
                    <Grid container spacing={0}>
                        <Grid xs={12}>
                            <Item
                                style={{
                                    boxShadow: "unset",
                                    borderRadius: "none",
                                    background: "none",
                                    display: "flex",
                                    justifyContent: activeStep > 0 ? "space-between" : "end",
                                }}
                            >
                                {" "}
                                {activeStep > 0 && (
                                    <Button
                                        sx={{
                                            bgcolor: "#ededed",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            mx: 1,
                                            borderRadius: "4px",
                                            color: "var(--text-color-black)",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                            boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                            "&:hover": {
                                                boxShadow: "0 0 #0003, 0 0 #00000024, 0 0 #0000001f",
                                                bgcolor: "#e0e0e0",
                                            },
                                        }}
                                        variant="contained"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        {<KeyboardArrowLeftIcon sx={{ color: "var(--text-color-black)" }}></KeyboardArrowLeftIcon>} Back
                                    </Button>
                                )}
                                <Button
                                    sx={{
                                        ...btnStyle,
                                        bgcolor: "var(--button-bg-color-green)",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "capitalize",
                                        borderRadius: "4px",
                                        "&:hover": {
                                            bgcolor: "var(--button-hover-bg-color-LightGreen)",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={() => handleNext()}
                                    disabled={videoUploading}
                                >
                                    <Typography variant="h6" fontWeight={500} sx={{ fontSize: "14px" }}>
                                        {activeStep === steps.length - 1 ? (fourSetpSubmit ? "Submitting..." : videoUploading ? "video uploading..." : "Finish") : "Next Step"}
                                    </Typography>

                                    {activeStep === steps.length - 1 ? fourSetpSubmit ? "" : videoUploading ? "" : "" : <NavigateNextIcon />}
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{ width: "100%", mt: "120px", px: !isMobile ? "48px" : "0px" }}>
                <Container maxWidth="">
                    <Stepper
                        activeStep={activeStep}
                        orientation={isMobile ? "vertical" : "horizontal"}
                        sx={{
                            "& .MuiStepConnector-line": {
                                borderTopWidth: "3px",
                            },
                            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line, .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                                borderColor: "var(--button-bg-color-green)",
                            },
                            "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line": {
                                borderColor: "#cad8db",
                            },
                            "& .MuiStepConnector-root": {
                                position: "absolute",
                                top: "16px",
                            },
                            // px:StepperPading
                        }}
                        alternativeLabel={!isMobile}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-completed, .MuiStepLabel-root .Mui-active": {
                                            fontWeight: "bold",
                                        },
                                        "& .MuiStepLabel-root .Mui-completed .MuiSvgIcon-root, .MuiStepLabel-root .Mui-active .MuiSvgIcon-root": {
                                            color: "var(--button-bg-color-green)", // circle color (COMPLETED)
                                            border: "2px solid var(--button-bg-color-green)",
                                            borderRadius: "19px",
                                            padding: "2px",
                                            fontSize: "35px",
                                        },
                                        "& .MuiStepLabel-root .Mui-disabled .MuiSvgIcon-root": {
                                            color: "#cad8db", // disable circle color
                                            border: "2px solid #cad8db",
                                            borderRadius: "19px",
                                            padding: "2px",
                                            fontSize: "35px",
                                        },
                                        "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text": {
                                            fill: "#000", // circle's number (Disable)
                                            fontWeight: "bold",
                                        },
                                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                            fill: "var(--text-color-OffWhite)", // circle's number (ACTIVE)
                                        },
                                    }}
                                >
                                    <StepLabel {...labelProps} onClick={() => nextAnyPoint(index)} style={{ cursor: "pointer" }}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        <Alert
                            onClose={handleNotificationClose}
                            severity="error"
                            className="error_msg"
                            sx={{
                                width: "500px",
                                justifyContent: "center",
                                background: "#cb7b7a",
                                color: "var(--text-color-OffWhite)",
                            }}
                        >
                            Question is Required!!!
                        </Alert>
                    </Snackbar>
                </Container>
                <Typography variant="text" sx={{ mt: 2, mb: 1 }}>
                    {stepContent[activeStep]}
                </Typography>
                {loader && <PageNotFound loading={loader} />}
            </Box>
        </Box>
    );
}
