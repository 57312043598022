import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Container, Typography, Snackbar, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Logo from "../../../../../../Assests/Images/LOGO.png"
import axios from "axios";
import Alerts from "../../../../../Common/Alerts";
import MuiAlert from "@mui/material/Alert";
import { RadioButtonChecked as RadioButtonCheckedIcon, Save as SaveIcon, ReportProblemOutlined as ReportProblemOutlinedIcon } from '@mui/icons-material';

import { getHeaders, getVideoQuestion } from "../../../../../../Constants/ConstantsUrl";

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PreviewVideoQuestions() {
    const handleCloseTab = () => {
        window.close();
    };
    const { main_question_id } = useParams();
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [questionData, setQuestionData] = useState({
        id: 0,
        question_title: null,
        options: []
    });
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const childVideoRef = useRef(null);
    const childMediaRecorder = useRef(null);
    const startTime = useRef(null);
    const [videoPrams, setVideoPrams] = useState({
        isConnecting: true,
        videoDuration: 0,
        videoUrl: null,
        isRecording: false,
        isRecordingComplete: false,
        recordingDuration: 0,
        videoBlob: null,
        isCameraOn: false,
        recordedChunks: [],
        isUploading: false,
        isUploaded: false,
        timeLimit: 120,
        timeLimitTimeout: null,
        thereWasAnError: false,
    });
    useEffect(() => {
        if (main_question_id > 0) {
            fetchMainCustomQuestion();
        }
    }, []);

    const startChildRecording = async () => {
        try {
            let childVideo;
            const childMediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            childVideo = childVideoRef.current;
            childVideo.srcObject = childMediaStream;
            childVideo.play();
        } catch (error) {
            console.error('Error accessing camera', error);
        }
    }

    const checkCamera = async () => {
        try {
            // Check if mediaDevices is supported in the browser
            if ("mediaDevices" in navigator) {
                // Try to access the camera
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                // If successful, the camera is available                 
                startChildRecording(); //start recording   
                stream.getTracks().forEach((track) => track.stop());

                setVideoPrams((prev) => ({
                    ...prev,
                    isCameraOn: true,
                    thereWasAnError: false,
                }))
            } else {
                // mediaDevices not supported, camera availability is unknown
                setVideoPrams((prev) => ({
                    ...prev,
                    thereWasAnError: true,
                }))
            }

            setVideoPrams((prev) => ({
                ...prev,
                isConnecting: false,
            }))
        } catch (error) {
            // Permission denied or camera not available            
            setVideoPrams((prev) => ({
                ...prev,
                isConnecting: false,
                thereWasAnError: true,
            }))
        }
    };

    useEffect(() => {
        checkCamera();
    }, []);


    const captureRecording = () => {
        if (childVideoRef.current) {
            if (childVideoRef?.current?.srcObject) {
                setVideoPrams((prev) => ({
                    ...prev,
                    isRecording: true,
                }));

                const stream = childVideoRef.current.srcObject;
                const options = { mimeType: "video/webm; codecs=vp9" };
                const childRecorder = new MediaRecorder(stream, options);

                childRecorder.ondataavailable = (e) => {
                    setVideoPrams((prev) => ({
                        ...prev,
                        recordedChunks: [
                            ...prev.recordedChunks,
                            e.data,
                        ],
                        videoDuration: prev.videoDuration + 1,
                    }))
                };

                childRecorder.onstop = async () => {
                    setVideoPrams((prev) => ({
                        ...prev,
                        videoDuration: 0,
                        recordingDuration: 0,
                        isRecordingComplete: true,
                    }))
                };

                childRecorder.start(1000);
                childMediaRecorder.current = childRecorder;
                startTime.current = new Date().getTime();

                const timeOut = setTimeout(() => {
                    handleStopRecording();
                    setAlertNotification({
                        status: true,
                        type: "error",
                        message: "Recording Timeout!!",
                    })
                }, videoPrams.timeLimit * 1000);

                setVideoPrams((prev) => ({
                    ...prev,
                    timeLimitTimeout: timeOut,
                }))
            }
        }
    }

    const handleStopRecording = () => {
        if (childMediaRecorder.current) {
            clearTimeout(videoPrams?.timeLimitTimeout);
            childMediaRecorder.current.stop();
        }
        setVideoPrams((prev) => ({
            ...prev,
            videoDuration: 0,
            isRecording: false,
            recordingDuration: 0,
            isRecordingComplete: true,
        }));

    }

    const fetchMainCustomQuestion = async () => {
        const headers = getHeaders();
        const data = {
            'custom_main_question_id': main_question_id
        }
        try {
            await axios.post(getVideoQuestion, data, { headers })
                .then((response) => response)
                .then((response) => {
                    const res = response.data.data.custom_question;
                    if (response.data.status === 200) {
                        setQuestionData({
                            id: res.id,
                            question_title: res.question_title,
                            options: res.question_data.question
                        })
                    }
                })
        } catch (error) {
            if (error?.response.data.status === 404) {
                setErrorMsg(error?.response.data.data.message);
                setNotificationOpen(true);
                setTimeout(function () {
                    //handleCloseTab();
                }, 5000);
            }
        }
    }
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const formatSecondsToMinutes = (timeInSeconds) => {
        let minutes = Math.floor(timeInSeconds / 60);
        let seconds = Math.floor(timeInSeconds % 60);

        if (isNaN(minutes)) {
            minutes = 0;
        }

        if (isNaN(seconds)) {
            seconds = 0;
        }

        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    return (
        <Box sx={{ background: "#f5f6f6", height: "100vh" }}>
            <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid>
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="Company logo"
                    />
                </Grid>

                <Grid sx={{ padding: "20px" }}>
                    <Button
                        onClick={handleCloseTab}
                        sx={{
                            ...btnStyle,
                            bgcolor: "var(--button-bg-color-assessment)",
                            minWidth: "48px",
                            minHeight: "48px",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            "&:hover": {
                                bgcolor:
                                    "var(--button-hover-bg-color-LightGreen)",
                            },
                        }}
                        variant="contained"
                    >
                        {'Go back to tests'}
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    height: "90vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Container
                    maxWidth="lg"
                    style={{
                        padding: "0px",
                        borderRadius: "12px",
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow:
                                "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Question
                                </Typography>
                                <Grid>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: "18px", fontWeight: 500, mt: 2 }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: questionData.options }} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {videoPrams?.isConnecting && <CircularProgress sx={{ color: "var(--text-color-black)" }} />}
                                <Grid sx={{ position: "relative" }}>
                                    {videoPrams?.isCameraOn === true &&
                                        <>
                                            {
                                                videoPrams?.isRecording && <Grid sx={{ position: "absolute", top: 10, right: 10, width: "100%", textAlign: "end", }}>
                                                    <RadioButtonCheckedIcon sx={{ color: "red" }} />
                                                </Grid>
                                            }
                                            <Grid>
                                                <video ref={childVideoRef} autoPlay muted style={{ width: "100%", maxWidth: "500px" }}></video>
                                            </Grid>
                                            <Grid sx={{ height: "25px", position: "absolute", bottom: "8px", width: "100%", textAlign: "center", backdropFilter: "brightness(0.5)", color: "white" }}>
                                                <span style={{ fontSize: "13px" }}>
                                                    {formatSecondsToMinutes(videoPrams.videoDuration)} / {formatSecondsToMinutes(videoPrams?.timeLimit)}
                                                </span>
                                            </Grid>

                                        </>
                                    }

                                    {videoPrams.thereWasAnError === true && <>
                                        <Grid className="cameraSetupWarning" container spacing={0}>
                                            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={1}>
                                                <ReportProblemOutlinedIcon />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                                    It seems you don't have a camera or mic connected to your computer or they are blocked. To enable the camera and mic, click on the camera blocked
                                                    icon in your browser's address bar. If you don't enable a camera, you can skip the question.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>}
                                </Grid>
                            </Grid>

                            {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}

                            <Grid item style={{ padding: "0px 24px" }} xs={12} md={12}>
                                <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "10px" }}>
                                    {
                                        videoPrams.thereWasAnError === false ? (

                                            !videoPrams?.isRecording ? (
                                                <Button
                                                    sx={{
                                                        boxShadow: "none",
                                                        bgcolor: "var(--button-bg-color-assessment)",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "capitalize",
                                                        mt: 2,
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "var(--button-hover-bg-color-assessment)",
                                                        },
                                                    }}
                                                    disabled={videoPrams?.isConnecting}
                                                    onClick={() => {
                                                        captureRecording()
                                                    }}
                                                    variant="contained"
                                                >
                                                    Start Recording
                                                </Button>
                                            ) : (
                                                <Button
                                                    sx={{
                                                        boxShadow: "none",
                                                        bgcolor: "#276678",
                                                        minWidth: "48px",
                                                        minHeight: "48px",
                                                        textTransform: "capitalize",
                                                        mt: 2,
                                                        mb: 2,
                                                        "&:hover": {
                                                            bgcolor: "#276678",
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleStopRecording()
                                                    }}
                                                    variant="contained"
                                                >
                                                    Stop Recording
                                                </Button>

                                            )

                                        ) : (<>
                                        </>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <Alert
                        onClose={handleNotificationClose}
                        severity="error"
                        className="error_msg"
                        sx={{
                            width: "500px",
                            justifyContent: "center",
                            background: "#cb7b7a",
                            color: "var(--text-color-OffWhite)",
                        }}
                    >
                        {errorMsg}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}
